import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { LoadingIndicator, StyledText } from 'src/components';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { LoginFlowTemplate } from 'src/features/auth/components';
import { useIsSignedIn, useUserInfo } from 'src/features/auth/hooks';
import { showNotification } from 'src/helpers';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { typography } from 'src/styles';

import { GroupInvitationForm, GroupInvitationRegisterForm } from '../components';
import { useGroupInvitationValidation } from '../hooks';

type Props = ScreenProps<Route.GroupInvitation>;

export const GroupInvitationScreen: React.FC<Props> = ({ route, navigation }) => {
  const { encryptedEmail, memberId } = route.params;
  const isSignedIn = useIsSignedIn();
  const { email: userEmail } = useUserInfo();
  const {
    data: validationData,
    isLoading,
    error,
  } = useGroupInvitationValidation({
    email: encryptedEmail,
    memberId,
  });

  useEffect(() => {
    if (isSignedIn && validationData && validationData.email !== userEmail) {
      showNotification({ type: 'error', title: i18n.t('groupInvitation:tokenNotValid') });
      navigation.navigate(Route.Home);
    }
  }, [validationData, userEmail, isSignedIn, navigation]);

  const detailError = (error as any)?.response?.data.detail;

  return isLoading ? (
    <LoadingIndicator style={styles.loader} displayStandbyText />
  ) : error ? (
    <LoginFlowTemplate
      headerTitle={detailError ? getErrorMessageForApiError(detailError, 'detailHeader') : ''}
      testID="join-group-box"
    >
      <StyledText style={typography.body3}>
        {detailError ? getErrorMessageForApiError(detailError, 'detail') : i18n.t('unknownError')}
      </StyledText>
    </LoginFlowTemplate>
  ) : validationData ? (
    validationData.isNew ? (
      <GroupInvitationRegisterForm email={validationData.email} memberId={memberId} />
    ) : (
      <GroupInvitationForm
        email={validationData.email}
        isInvitedAsSupportStaff={validationData.isInvitedAsSupportStaff}
        groupName={validationData.groupName}
        memberId={memberId}
      />
    )
  ) : null;
};

const styles = StyleSheet.create({
  loader: {
    marginVertical: 20,
  },
});
