import { client } from 'src/api/client';
import { RecurlyStudentPlan } from 'src/features/subscription/types';

import {
  CurrentStudentVerificationResponse,
  StudentDocumentParams,
  StudentEmailLoopVerificationParams,
  StudentSubscriptionEligibilityBase,
  StudentSubscriptionEligibilityPayload,
  StudentVerificationDetailsResponse,
  StudentVerificationParams,
  StudentVerificationResponse,
  StudentsCountriesResponse,
  StudentsOrganizationsSearchParams,
  StudentsOrganizationsSearchResponse,
} from './types';

export const fetchStudentsCountries = async (planCode: RecurlyStudentPlan) => {
  const response = await client.request<StudentsCountriesResponse>({
    method: 'GET',
    url: `/students/organization/${planCode}/countries/`,
    omitAuth: true,
  });

  return response.data;
};

export const searchStudentsOrganizations = async (params: StudentsOrganizationsSearchParams) => {
  const { country, planCode, query } = params;
  const response = await client.request<StudentsOrganizationsSearchResponse>({
    method: 'GET',
    url: `/students/organization/${planCode}/search/`,
    params: {
      country,
      query,
    },
    omitAuth: true,
  });

  return response.data;
};

export const verifyStudentStatus = async (params: StudentVerificationParams, isSignedIn: boolean) => {
  const response = await client.request<StudentVerificationResponse>({
    method: 'POST',
    url: '/students/verification/submit/',
    data: params,
    omitAuth: !isSignedIn,
  });

  return response.data;
};

export const uploadStudentDocuments = async (params: StudentDocumentParams, isSignedIn: boolean) => {
  const formData = new FormData();
  Array.from(params.files).forEach((file) => formData.append('documents', file));

  const response = await client.request({
    method: 'POST',
    url: `/students/verification/${params.verificationId}/upload-documents/`,
    data: formData,
    omitAuth: !isSignedIn,
  });

  return response.data;
};

export const getStudentVerificationDetails = async (verificationId: string) => {
  const response = await client.request<StudentVerificationDetailsResponse>({
    method: 'GET',
    url: `/students/verification/${verificationId}/details/`,
    omitAuth: true,
  });

  return response.data;
};

export const getCurrentUserStudentVerificationDetails = async () => {
  const response = await client.request<CurrentStudentVerificationResponse>({
    method: 'GET',
    url: '/students/user/verification/',
  });

  return response.data;
};

export const skipSSOStep = async (verificationId: string, isSignedIn: boolean) => {
  const response = await client.request({
    method: 'DELETE',
    url: `students/verification/${verificationId}/sso/`,
    omitAuth: !isSignedIn,
  });

  return response.data;
};

export const fetchStudentSubscriptionEligibility = async () => {
  const response = await client.request<StudentSubscriptionEligibilityPayload>({
    method: 'GET',
    url: '/students/data/details/',
  });

  return response.data;
};

export const editStudentSubscriptionEligibility = async (data: StudentSubscriptionEligibilityBase) => {
  const response = await client.request<StudentSubscriptionEligibilityPayload>({
    url: '/students/data/details/',
    method: 'POST',
    data,
  });

  return response.data;
};

export const verifyEmailLoopToken = async (
  params: StudentEmailLoopVerificationParams,
  isSignedIn: boolean,
) => {
  const response = await client.request({
    url: `students/verification/${params.verificationId}/email-loop/`,
    method: 'POST',
    data: {
      emailToken: params.token,
    },
    omitAuth: !isSignedIn,
  });

  return response.data;
};

export const skipEmailLoop = async (verificationId: string, isSignedIn: boolean) => {
  const response = await client.request({
    url: `students/verification/${verificationId}/email-loop/`,
    method: 'DELETE',
    omitAuth: !isSignedIn,
  });

  return response.data;
};

export const resendEmailLoopToken = async (verificationId: string, isSignedIn: boolean) => {
  const response = await client.request({
    url: `students/verification/${verificationId}/resend-loop/`,
    method: 'GET',
    omitAuth: !isSignedIn,
  });

  return response.data;
};
