import React, { CSSProperties, RefObject, useCallback, useEffect, useRef } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { GradientShadow } from 'src/components/GradientShadow.web';
import { NavigationDropdownPortal } from 'src/contexts/portals';
import { BRANDS_PANEL_HEIGHT, NAVBAR_HEIGHT } from 'src/styles';

interface Props {
  triggerRef: RefObject<HTMLDivElement>;
  style?: StyleProp<ViewStyle>;
  align?: 'left' | 'center' | 'right';
  alignTarget?: 'parent' | 'window';
  isOpen: boolean;
  toggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavigationDropdownWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  triggerRef,
  align = 'left',
  alignTarget = 'parent',
  style,
  isOpen,
  toggleOpen,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = useCallback(() => {
    toggleOpen(true);
  }, [toggleOpen]);

  const handleMouseLeave = useCallback(
    (ev: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const inDropdown = [triggerRef.current, wrapperRef.current].some((element) => {
        const isNode = !!(ev.relatedTarget as Node)?.parentNode;
        return isNode ? element?.contains(ev.relatedTarget as Node) : false;
      });
      if (!inDropdown) {
        toggleOpen(false);
      }
    },
    [toggleOpen, triggerRef],
  );

  useEffect(() => {
    const triggerElement = triggerRef.current;

    triggerElement?.addEventListener('mouseenter', handleMouseEnter);
    triggerElement?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      triggerElement?.removeEventListener('mouseenter', handleMouseEnter);
      triggerElement?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [triggerRef, handleMouseLeave, handleMouseEnter]);

  const getPositionStyles = (): CSSProperties => {
    if (!triggerRef.current) {
      return { left: 0 };
    }
    const { left, width } = triggerRef.current.getBoundingClientRect();
    return align === 'left'
      ? {
          left: alignTarget === 'parent' ? left : 0,
        }
      : align === 'center'
      ? {
          left: alignTarget === 'parent' ? left + width / 2 : '50%',
          transform: 'translateX(-50%)',
        }
      : {
          right: alignTarget === 'parent' ? `calc(100% - ${left + width}px)` : 0,
          left: 'auto',
        };
  };

  return isOpen ? (
    <NavigationDropdownPortal>
      <div
        style={Object.assign(
          {},
          styles.wrapper,
          { left: triggerRef.current?.getBoundingClientRect().x },
          getPositionStyles(),
          StyleSheet.flatten(style),
        )}
        ref={wrapperRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
        <GradientShadow style={stylesNative.shadow} />
      </div>
    </NavigationDropdownPortal>
  ) : null;
};

const styles: Record<string, CSSProperties> = {
  wrapper: {
    position: 'fixed',
    top: NAVBAR_HEIGHT + BRANDS_PANEL_HEIGHT,
    zIndex: -1,
  },
};

const stylesNative = StyleSheet.create({
  shadow: {
    top: '0',
  },
});
