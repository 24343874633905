import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { BaseModal, LoadingIndicator, StyledText } from 'src/components';
import { ModalPortal } from 'src/contexts/portals';
import { palette, typography } from 'src/styles';

interface Props {
  asModal?: boolean;
}

export const DocumentsVerificationWindow: React.FC<Props> = ({ asModal = true }) => {
  const { t } = useTranslation('subscriptionProcess');

  const Content = (
    <>
      <StyledText style={styles.title}>{t('documentsProcessingHeader')}</StyledText>
      <StyledText style={styles.text}>{t('documentsProcessingInfo')}</StyledText>
      <LoadingIndicator size={50} style={styles.loader} />
    </>
  );

  return asModal ? (
    <ModalPortal>
      <BaseModal fitContents testID="reviewing-docs-modal">
        {Content}
      </BaseModal>
    </ModalPortal>
  ) : (
    Content
  );
};

const styles = StyleSheet.create({
  title: {
    ...typography.body5Bold,
    color: palette.blue,
    textAlign: 'center',
    marginBottom: 8,
  },
  text: {
    ...typography.body3,
    textAlign: 'center',
  },
  loader: {
    marginTop: 30,
    marginBottom: 10,
  },
});
