import { UseQueryResult, onlineManager } from '@tanstack/react-query';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { isServerIssueError } from 'src/api/utils';

import { Error404Banner } from './Error404Banner';
import { Error500Banner } from './Error500Banner';
import { ErrorMessage } from './ErrorMessage';
import { InternetConnectionErrorBanner } from './InternetConnectionErrorBanner';
import { ServerIssueBanner } from './ServerIssueBanner';

interface Props extends Pick<UseQueryResult<any>, 'fetchStatus' | 'error'> {
  /** If query data is available, we don't display any errors, even in case of internet connection issues */
  isDataAvailable: boolean;
  errorComponentType?: 'banner' | 'message';
  centered?: boolean;
  style?: StyleProp<ViewStyle>;
  messageTextStyle?: StyleProp<TextStyle>;
}

/** Component takes data returned by useQuery and displays a proper error banner if necessary */
export const QueryErrorBanner: React.FC<Props> = ({
  isDataAvailable,
  fetchStatus,
  error,
  errorComponentType = 'banner',
  centered = true,
  style,
  messageTextStyle,
}) => {
  if (isDataAvailable) {
    return null;
  }

  const isBanner = errorComponentType === 'banner';
  const messageStyles = [messageTextStyle, centered ? styles.messageCentered : null];

  if (fetchStatus === 'paused' && !onlineManager.isOnline()) {
    return isBanner ? (
      <InternetConnectionErrorBanner />
    ) : (
      <ErrorMessage style={style} errorType="internet-connection" textStyle={messageStyles} />
    );
  }

  if (error) {
    if (isServerIssueError(error)) {
      return isBanner ? (
        <ServerIssueBanner />
      ) : (
        <ErrorMessage errorType="server-issue" textStyle={messageStyles} style={style} />
      );
    }

    if (error.response?.status === 404) {
      return isBanner ? (
        <Error404Banner />
      ) : (
        <ErrorMessage errorType="not-found" textStyle={messageStyles} style={style} />
      );
    }

    return isBanner ? (
      <Error500Banner />
    ) : (
      <ErrorMessage errorType="common" textStyle={messageStyles} style={style} />
    );
  }

  return null;
};

const styles = StyleSheet.create({
  messageCentered: {
    textAlign: 'center',
  },
});
