import { StyleSheet } from 'react-native';

import { StyledText } from 'src/components';
import { typography } from 'src/styles';

import { DDxDetails } from '../types';

interface Props extends Pick<DDxDetails, 'introCopy'> {}

export const DDxHeaderDescription: React.FC<Props> = ({ introCopy }) => {
  if (!introCopy) return null;

  return <StyledText style={styles.introCopy}>{introCopy}</StyledText>;
};

const styles = StyleSheet.create({
  introCopy: {
    marginTop: 18,
    ...typography.body3,
  },
});
