import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';
import { ArrowContainer, Popover as RTPopopver, PopoverPosition } from 'react-tiny-popover';

import { StyledText } from 'src/components';
import { MODAL_WRAPPER_ID } from 'src/constants/constants';
import { getShadow, palette, typography, zindex } from 'src/styles';

import { Popover as BaseComponent } from './Popover';

type Props = React.ComponentProps<typeof BaseComponent>;

export const Popover: React.FC<Props> = ({
  children,
  content,
  openOnHover = true,
  openOnPress,
  contentWrapperStyles,
  arrowSize,
  position = 'vertical',
  backgroundColor = palette.opaqueBlack,
  textColor = palette.white,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isInModal, setInModal] = useState(false);
  const timeout = useRef<number>();
  const wrapper = useRef<HTMLSpanElement>(null);
  const [contentWrapperWidth, setContentWrapperWidth] = useState(0);

  useLayoutEffect(() => {
    if (wrapper.current) {
      setInModal(!!document.getElementById(MODAL_WRAPPER_ID)?.contains(wrapper.current));
    } else {
      setInModal(false);
    }
  }, []);

  const handleTargetMouseEnter = useCallback(() => {
    if (openOnHover) {
      setOpen(true);
      clearTimeout(timeout.current);
    }
  }, [openOnHover]);

  const handleMouseLeave = useCallback(() => {
    if (openOnHover) {
      const timeoutId = setTimeout(() => {
        setOpen(false);
      }, 100);
      timeout.current = timeoutId as unknown as number;
    }
  }, [openOnHover]);

  const handlePopoverMouseEnter = useCallback(() => {
    clearTimeout(timeout.current);
  }, []);

  const handlePress: React.MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      if (openOnPress) {
        event.stopPropagation();
        setOpen((state) => !state);
      }
    },
    [openOnPress],
  );

  const getAlignValue = (): React.ComponentProps<typeof RTPopopver>['align'] => {
    const wrapperElement = wrapper.current;
    if (wrapperElement && contentWrapperWidth) {
      if (position === 'horizontal' || position === 'left' || position === 'right') {
        // Theres no need to calculate precisely for horizontal popovers for now as we display only small ones
        return 'center';
      }

      const windowWidth = window.document.body.getBoundingClientRect().width;
      const wrapperBoundingClientRect = wrapperElement.getBoundingClientRect();
      const wrapperCenter = wrapperBoundingClientRect.x + wrapperBoundingClientRect.width / 2;

      const doesContentAlignedToTheWrapperCenterWouldEndOutsideTheWindow =
        wrapperCenter + contentWrapperWidth / 2 > windowWidth;

      if (doesContentAlignedToTheWrapperCenterWouldEndOutsideTheWindow) {
        return 'end';
      }

      const doesContentAlignedToTheWrapperCenterWouldStartOutsideTheWindow =
        wrapperCenter - contentWrapperWidth / 2 < 0;
      const doesContentAlignedToTheWrapperStartWouldFitInTheWindow =
        wrapperBoundingClientRect.x + contentWrapperWidth < windowWidth;

      if (
        doesContentAlignedToTheWrapperCenterWouldStartOutsideTheWindow &&
        doesContentAlignedToTheWrapperStartWouldFitInTheWindow
      ) {
        return 'start';
      }
    }
    return 'center';
  };

  const positions = ((): PopoverPosition[] => {
    if (position === 'horizontal') return ['left', 'right'];
    if (position === 'vertical') return ['top', 'bottom'];

    return [position];
  })();

  const marginStyles = {
    marginLeft: position === 'horizontal' || position === 'right' ? 5 : 0,
    marginRight: position === 'horizontal' || position === 'left' ? 5 : 0,
    marginTop: position === 'vertical' || position === 'bottom' ? 5 : 0,
    marginBottom: position === 'vertical' || position === 'top' ? 5 : 0,
  };

  return (
    <span ref={wrapper}>
      <RTPopopver
        isOpen={isOpen}
        containerStyle={containerStyles}
        positions={positions}
        align={getAlignValue()}
        containerParent={document.getElementById(isInModal ? MODAL_WRAPPER_ID : 'wrapper')!}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={backgroundColor}
            arrowSize={arrowSize || 5}
            arrowStyle={{ ...arrowStyle, ...marginStyles }}
          >
            <div onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handleMouseLeave}>
              <View
                onLayout={(e: LayoutChangeEvent) => {
                  setContentWrapperWidth(e.nativeEvent.layout.width);
                }}
                style={[styles.contentWrapper, marginStyles, { backgroundColor }, contentWrapperStyles]}
              >
                {typeof content === 'string' ? (
                  <StyledText style={[typography.body2, { color: textColor }]}>{content}</StyledText>
                ) : (
                  content
                )}
              </View>
            </div>
          </ArrowContainer>
        )}
      >
        <span onClick={handlePress} onMouseEnter={handleTargetMouseEnter} onMouseLeave={handleMouseLeave}>
          {children}
        </span>
      </RTPopopver>
    </span>
  );
};

const containerStyles = {
  zIndex: zindex.popover.toString(),
};

const arrowStyle: React.CSSProperties = {
  zIndex: 1,
};

const styles = StyleSheet.create({
  contentWrapper: {
    backgroundColor: palette.white,
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 4,
    ...getShadow(4, 0.32),
  },
});
