import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Box, Container, StyledText, FullScreenTemplate } from 'src/components';
import { isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { palette, typography } from 'src/styles';

interface Props {
  headerTitle: string;
  shouldDisplayBackButton?: boolean;
  testID?: string;
}

export const LoginFlowTemplate: React.FC<React.PropsWithChildren<Props>> = ({
  headerTitle,
  children,
  shouldDisplayBackButton,
  testID,
}) => {
  const { isDesktop } = useDeviceInfo();

  return isWeb ? (
    <FullScreenTemplate>
      <View style={[styles.container, isDesktop ? styles.containerDesktop : styles.containerMobile]}>
        <Box narrow testID={testID}>
          <View style={styles.topBar} />
          <View style={styles.formContainer}>
            <StyledText style={[typography.header1Bold, styles.inlineTitle]}>{headerTitle}</StyledText>
            {children}
          </View>
        </Box>
      </View>
    </FullScreenTemplate>
  ) : (
    <FullScreenTemplate
      contentContainerStyle={styles.screenContainerNative}
      keyboardAvoiding
      showBackButton={shouldDisplayBackButton}
    >
      <Container style={styles.formContainerNative}>{children}</Container>
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  screenContainerNative: {
    backgroundColor: palette.white,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerMobile: {
    paddingHorizontal: '3.5%',
    paddingVertical: '6.5%',
  },
  containerDesktop: {
    padding: 60,
  },
  formContainer: {
    paddingVertical: 32,
    paddingHorizontal: '5%',
  },
  formContainerNative: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '8%',
    paddingBottom: '12%',
    backgroundColor: palette.white,
    flex: 1,
  },
  inlineTitle: {
    marginBottom: 20,
    color: palette.navy,
  },
  topBar: {
    height: 10,
    backgroundColor: palette.navy,
  },
});
