import { StyleSheet, View, ViewProps } from 'react-native';

import { palette } from 'src/styles';

import { StyledText } from './StyledText';

interface Props extends ViewProps {
  text: string | number;
  backgroundColor?: string;
  color?: string;
}

export const NumberIndicator: React.FC<Props> = ({
  text,
  style,
  backgroundColor = palette.navy,
  color = palette.white,
  ...rest
}) => {
  return (
    <View style={[styles.wrapper, { backgroundColor }, style]} {...rest}>
      <StyledText style={[styles.text, { color }]}>{text}</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 12,
    paddingHorizontal: 8,
  },
  text: {
    fontSize: 15,
    lineHeight: 24,
    fontWeight: 'bold',
  },
});
