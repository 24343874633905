import { ReactElement } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { TilesSelectorOption } from 'src/constants/types';
import { ifWeb } from 'src/styles';

import { Tile } from './Tile';

type SelectorProps<T> = {
  onPress?: (value: T) => void;
  selected: T;
  options: TilesSelectorOption<T>[];
  disabled?: boolean;
  wrapperStyle?: StyleProp<ViewStyle>;
  hideShadow?: boolean;
  fullWidth?: boolean;
};

export const TilesSelector = <T extends string>({
  onPress,
  selected,
  options,
  disabled,
  wrapperStyle,
  hideShadow,
  fullWidth,
}: SelectorProps<T>): ReactElement<any, any> | null => {
  const filteredOptions = disabled ? options.filter(({ value }) => value === selected) : options;

  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      <View style={styles.optionsSection}>
        {filteredOptions.map((option, idx, array) => (
          <Tile
            title={option.label}
            active={selected === option.value}
            first={idx === 0}
            last={array.length === idx + 1}
            prevToActive={array.length > idx + 1 && array[idx + 1].value === selected}
            nextToActive={idx > 0 && array[idx - 1].value === selected}
            disabled={disabled}
            onPress={() => onPress?.(option.value)}
            key={option.label}
            hideShadow={hideShadow}
            fullWidth={fullWidth}
            description={!disabled ? option.description : undefined}
            testID={selected === option.value ? option.testID + '-active' : option.testID + '-not-active'}
            badge={option.badge}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  optionsSection: {
    flexDirection: 'row',
    margin: 3,
  },
  wrapper: {
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
});
