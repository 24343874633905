import { SavedCredentialsService as ISavedCredentialsService } from '../types';

/**
 * This feature is implemented on native apps only.
 */
class SavedCredentials implements ISavedCredentialsService {
  save = async () => {
    return;
  };

  load = async () => {
    return false;
  };

  reset = async () => {
    return false;
  };
}

export const SavedCredentialsService = new SavedCredentials();
