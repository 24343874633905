import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { client } from 'src/api/client';
import { Country, SelectOption } from 'src/constants/types/';

interface Options
  extends Omit<UseQueryOptions<Country[], unknown, Country[], string[]>, 'queryKey' | 'queryFn'> {}

export const useCountriesList = (options: Options = {}) => {
  const queryData = useQuery({
    queryKey: ['countries-list'],
    queryFn: async () => {
      const response = await client.request<Country[]>({
        method: 'GET',
        url: '/accounts/countries-states/',
        omitAuth: true,
      });
      return response.data;
    },
    ...options,
  });

  const countriesOptions: SelectOption[] = useMemo(
    () =>
      queryData.data?.map((country) => ({
        value: country.code,
        label: country.name,
      })) || [],
    [queryData.data],
  );

  const getStatesOptions = useCallback(
    (countryCode: string): SelectOption[] => {
      const country = queryData.data?.find((country) => country.code === countryCode);
      return (
        country?.states?.map((state) => ({
          value: state.code,
          label: state.name,
        })) || []
      );
    },
    [queryData.data],
  );

  const getCountryFullName = useCallback(
    (countryCode: string) => {
      return queryData.data?.find((country) => country.code === countryCode)?.name || countryCode;
    },
    [queryData.data],
  );

  const getStateFullName = useCallback(
    (countryCode: string, stateCode: string) => {
      const states = getStatesOptions(countryCode);
      return states.find((state) => state.value === stateCode)?.label || stateCode;
    },
    [getStatesOptions],
  );

  return {
    ...queryData,
    countriesOptions,
    getStatesOptions,
    getCountryFullName,
    getStateFullName,
  };
};
