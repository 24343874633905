import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { FullScreenTemplate, BottomBar, Container, SearchInput } from 'src/components';
import { OlyticsService } from 'src/features/tracking';
import { isWeb } from 'src/helpers';
import { useContainer, useDeviceInfo } from 'src/hooks';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { palette, CONTAINER_PADDING_H_MOBILE } from 'src/styles';

import { DICVoidSearchBox, SelectedDrugsList, InteractionResults, DICDisclaimer } from '../components';
import { ClassificationKey } from '../components/ClassificationKey';
import { InteractionResultsRef } from '../components/InteractionResults/InteractionResults';
import { InteractionsSearchResults } from '../components/InteractionsSearchResults';
import { useInteractionsDrugs } from '../hooks/useInteractionsDrugs';

type Props = ScreenProps<Route.DIC>;

export const DICScreen: React.FC<Props> = ({ route }) => {
  const { all, mode, source } = route.params || {};
  const drugs = useInteractionsDrugs();

  const listRef = useRef<InteractionResultsRef>(null);
  const { isTablet } = useDeviceInfo();
  const containerStyle = useContainer({
    fullWidthOnMobile: true,
    verticalPadding: isWeb,
    maxWidth: 'narrow',
  });

  const [inputValue, setInputValue] = useState('');
  const [query, setQuery] = useDebouncedValue(inputValue, 350);

  useEffect(() => {
    OlyticsService.fire();
  }, []);

  const clearQuery = () => {
    setInputValue('');
    setQuery('');
    setQuery.flush();
  };

  const scrollToTop = () => {
    if (isWeb) {
      window.scrollTo({ top: 0 });
    } else {
      listRef.current?.scrollToTop();
    }
  };

  const areSearchResultsDisplayed = query.length > 1;

  return (
    <FullScreenTemplate
      title={i18n.t('screens:interactionChecker')}
      noScroll={!isWeb}
      showBackButton
      noHeaderBorder={!isWeb}
      stickyContent={
        isWeb ? (
          <Container maxWidth="narrow">
            <SearchInput
              placeholder={i18n.t('dic:searchInput', { context: drugs.length ? 'anotherDrug' : '' })}
              outlined
              value={inputValue}
              onChangeText={setInputValue}
              containerStyle={styles.searchInput}
              onClearButtonPress={clearQuery}
              testID="dic-search-input"
            />
          </Container>
        ) : (
          <DICVoidSearchBox selectedDrugs={drugs} source={route.params?.source} />
        )
      }
    >
      <View style={[containerStyle, styles.container, areSearchResultsDisplayed && styles.resultsHidden]}>
        <InteractionResults
          ref={listRef}
          drugs={drugs}
          all={all}
          mode={mode}
          Header={<SelectedDrugsList style={styles.selectedDrugs} />}
          Footer={
            <>
              <View style={[styles.divider, isTablet && styles.dividerTablet]} />
              <ClassificationKey />
              <DICDisclaimer
                style={[styles.disclaimerWrapper, isTablet && styles.disclaimerWrapperTablet]}
              />
            </>
          }
          source={source}
        />
      </View>
      {areSearchResultsDisplayed && (
        <View>
          <InteractionsSearchResults
            query={query}
            selectedDrugs={route.params?.drugs ?? []}
            onItemPress={clearQuery}
          />
        </View>
      )}
      <BottomBar showScrollToTop scrollToTop={scrollToTop} />
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  searchInput: {
    marginBottom: 20,
  },
  selectedDrugs: {
    marginBottom: 30,
  },
  disclaimerWrapper: {
    marginHorizontal: CONTAINER_PADDING_H_MOBILE,
    marginTop: 30,
  },
  disclaimerWrapperTablet: {
    marginHorizontal: 0,
  },
  divider: {
    height: 1,
    backgroundColor: palette.grey2,
    marginBottom: 30,
    marginTop: 30,
    marginHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  dividerTablet: {
    marginHorizontal: 0,
  },
  resultsHidden: {
    display: 'none',
  },
});
