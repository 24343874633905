import MarkdownIt from 'markdown-it';

import { createMarkdownItPlugin } from './createMarkdownItPlugin';
import { MARKDOWN_TAGS } from '../tags';

export const ITALICS_TAG = 'em';
export const BOLD_TAG = 'strong';

// changes ** from bold into italics and _ from italics to bolds
export function emphasisPlugin(md: MarkdownIt) {
  createMarkdownItPlugin(ITALICS_TAG, MARKDOWN_TAGS.italic)(md);
  createMarkdownItPlugin(BOLD_TAG, MARKDOWN_TAGS.bold)(md);
  md.inline.ruler2.disable('emphasis');
}
