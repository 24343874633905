import { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { ifWeb, palette } from 'src/styles';

import { BaseButtonProps } from '.';
import { SecondaryButton } from './Buttons/SecondaryButton';

interface Props extends BaseButtonProps {
  isSelected?: boolean;
  checkboxSize?: number;
}

export const SelectButton: React.FC<Props> = ({ isSelected, checkboxSize = 14, disabled, ...rest }) => {
  const [isHovered, setHover] = useState(false);

  const handleHover = useCallback(() => setHover(true), []);
  const handleHoverOut = useCallback(() => setHover(false), []);

  const innerBoxSize = Math.ceil(checkboxSize * 0.54);
  const innerBoxRadius = Math.ceil(innerBoxSize / 10);
  return (
    <SecondaryButton
      size="tiny"
      disabled={disabled}
      {...rest}
      onHover={handleHover}
      onHoverOut={handleHoverOut}
    >
      <View
        style={[
          styles.checkboxOuter,
          disabled && styles.checkboxOuterDisabled,
          { width: checkboxSize, height: checkboxSize },
        ]}
      >
        <View
          style={[
            styles.checkboxInner,
            isHovered && !disabled && styles.checkboxInnerHover,
            isSelected && styles.checkboxInnerSelected,
            { width: innerBoxSize, height: innerBoxSize, borderRadius: innerBoxRadius },
          ]}
        />
      </View>
    </SecondaryButton>
  );
};

const styles = StyleSheet.create({
  checkboxOuter: {
    borderWidth: 1,
    borderColor: palette.blue,
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  checkboxOuterDisabled: {
    borderColor: palette.grey2,
  },
  checkboxInner: {
    opacity: 0,
    backgroundColor: palette.blue,
    ...ifWeb({
      transitionProperty: 'opacity',
    }),
  },
  checkboxInnerHover: {
    opacity: 0.3,
  },
  checkboxInnerSelected: {
    opacity: 1,
  },
});
