import { client } from 'src/api/client';

import type { PromoboxesResponse, PromoboxActionPayload } from './types';

export const sendPromoboxAction = async (params: PromoboxActionPayload) => {
  const response = await client.request({
    method: 'PUT',
    url: '/accounts/user/promobox/',
    data: params,
  });

  return response.data;
};

export const fetchPromoboxes = async () => {
  const response = await client.request<PromoboxesResponse>({
    method: 'GET',
    url: '/accounts/user/promobox/',
  });

  return response.data;
};
