import React from 'react';
import { View, StyleSheet } from 'react-native';

import { palette, typography } from 'src/styles';

import { StyledText } from './StyledText';

interface Props {
  text: string;
  status?: 'normal' | 'warning' | 'error';
}

export const Label: React.FC<Props> = ({ text, status = 'warning' }) => (
  <View
    style={[
      styles.wrapper,
      status === 'warning' && styles.wrapperWarning,
      status === 'error' && styles.wrapperError,
    ]}
  >
    <StyledText
      style={[
        typography.body1ShortSemiBold,
        status === 'warning' && styles.textWarning,
        status === 'error' && styles.textError,
      ]}
    >
      {text}
    </StyledText>
  </View>
);

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderRadius: 12,
    height: 24,
    paddingHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperWarning: {
    borderColor: palette.yellow3,
    backgroundColor: palette.yellow2,
  },
  textWarning: {
    color: palette.darkYellow,
  },
  wrapperError: {
    borderColor: palette.red,
    backgroundColor: palette.lightRed,
  },
  textError: {
    color: palette.red,
  },
});
