import { pull } from 'lodash-es';

import { ContentAccordionProps } from 'src/components/ContentItemScreen/ContentAccordion';
import { MAIN_NOTE_SECTION_ID } from 'src/features/notes';
import { useNotesListContext } from 'src/features/notes/context/NotesListContext';

interface accordionToggleProps
  extends Pick<
    ContentAccordionProps,
    'notes' | 'accordionRef' | 'isAnyItemExpanded' | 'setRemoveNoteConfirmModal'
  > {
  onCloseAllPress: () => void;
  onOpenAllPress: () => void;
}

export const useAccordionToggle = ({
  notes,
  accordionRef,
  isAnyItemExpanded,
  setRemoveNoteConfirmModal,
  onCloseAllPress,
  onOpenAllPress,
}: accordionToggleProps) => {
  const { unsavedNotes } = useNotesListContext();

  const collapseAllItems = () => {
    onCloseAllPress();
    accordionRef.current?.collapseAllItems();
  };

  const handleToggleAllSectionsPress = () => {
    if (!isAnyItemExpanded) {
      onOpenAllPress();
      accordionRef.current?.openAllItems();
      return;
    }
    const titleNoteId = notes?.[MAIN_NOTE_SECTION_ID]?.id || MAIN_NOTE_SECTION_ID;
    // Display warning if any note (except the title note) remains unsaved
    if (pull(unsavedNotes, titleNoteId).length) {
      setRemoveNoteConfirmModal({
        confirm: () => {
          setRemoveNoteConfirmModal(null);
          collapseAllItems();
        },
        cancel: () => {
          setRemoveNoteConfirmModal(null);
        },
        type: 'navigation',
      });
      return;
    }
    collapseAllItems();
  };
  return {
    handleToggleAllSectionsPress,
  };
};
