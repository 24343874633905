import React from 'react';

import { ConfirmModal } from 'src/components';
import { i18n } from 'src/locale';

import { useRemoveGroupMember } from '../../hooks';

interface Props {
  close: () => void;
  memberId: string;
}

export const RemoveFromGroupModal: React.FC<Props> = ({ close, memberId }) => {
  const { mutate: removeMember, isPending } = useRemoveGroupMember(memberId);

  const handleSubmit = () => {
    removeMember(undefined, {
      onSuccess: () => {
        close();
      },
    });
  };

  return (
    <ConfirmModal
      title={i18n.t('areYouSure')}
      description={i18n.t('adminDashboard:removeUserWarning')}
      cancelMessage={i18n.t('cancel')}
      confirmMessage={i18n.t('remove')}
      cancel={close}
      confirm={handleSubmit}
      testID="remove-user-modal"
      loading={isPending}
    />
  );
};
