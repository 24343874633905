import { InfiniteData, QueryFilters, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { QueryFnPaginationResponse, QueryKeys } from 'src/constants/types';
import { contentQueryKeys } from 'src/features/content/constants';
import { ContentItemBase } from 'src/features/content/types';
import { setDataInQueries, showNotification } from 'src/helpers';

import { addToFavorites, fetchFavoritesList, fetchFavoritesIds, removeFromFavorites } from '../api';
import { FavoritesFilter } from '../config/filters';
import { favoritesQueryKeys } from '../constants';
import { FavoritesIds } from '../types';

const contentListFilters: QueryFilters = {
  queryKey: contentQueryKeys.allLists,
  predicate: (query) => {
    const queryKey = query.queryKey as QueryKeys<typeof contentQueryKeys>['list'];
    const params = queryKey[2];
    return !!params?.isFavorite;
  },
};

export const useFavoritesList = (filter: FavoritesFilter) =>
  useQuery({
    queryKey: favoritesQueryKeys.list(filter.id),
    queryFn: () => fetchFavoritesList(filter.contentTypeForBackend),
    refetchOnMount: true,
  });

export const useFavoritesIds = () =>
  useQuery({
    queryKey: favoritesQueryKeys.ids,
    queryFn: () => fetchFavoritesIds(),
  });

export const useAddFavorite = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addToFavorites,
    onMutate: async (addedId) => {
      const previousQueries = queryClient.getQueriesData<FavoritesIds>({
        queryKey: favoritesQueryKeys.ids,
      });

      queryClient.setQueryData<FavoritesIds>(favoritesQueryKeys.ids, (ids) =>
        ids ? [...ids, addedId] : undefined,
      );

      return { previousQueries };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: favoritesQueryKeys.ids });
      queryClient.invalidateQueries({ queryKey: favoritesQueryKeys.allLists });
      queryClient.invalidateQueries(contentListFilters);
    },
    onError: (_error, _addedId, context) => {
      setDataInQueries(queryClient, context!.previousQueries);
      showNotification({ type: 'error' });
    },
  });

  return mutation;
};

export const useRemoveFavorite = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: removeFromFavorites,
    onMutate: async (removedId) => {
      const previousQueries = [
        ...queryClient.getQueriesData<FavoritesIds>({ queryKey: favoritesQueryKeys.ids }),
        ...queryClient.getQueriesData<InfiniteData<QueryFnPaginationResponse<ContentItemBase>>>(
          contentListFilters,
        ),
      ];

      queryClient.setQueryData<FavoritesIds>(favoritesQueryKeys.ids, (ids) =>
        ids?.filter((id) => id !== removedId),
      );

      queryClient.setQueriesData<InfiniteData<QueryFnPaginationResponse<ContentItemBase>>>(
        contentListFilters,
        (data) =>
          data
            ? {
                ...data,
                pages: data.pages.map((page) => {
                  const newResults = page.results.filter((item) => item.id !== removedId);
                  return {
                    ...page,
                    results: newResults,
                    count: newResults.length,
                  };
                }),
              }
            : undefined,
      );

      return { previousQueries };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: favoritesQueryKeys.ids });
      queryClient.invalidateQueries({ queryKey: favoritesQueryKeys.allLists });
      queryClient.invalidateQueries(contentListFilters);
    },
    onError: (err, _removedId, context) => {
      if (axios.isAxiosError(err) && err.response?.status === 404) {
        /** 404 means there's no such item in favorites which can be caused by removing this item
         * on different device. No need to display any error to users.
         */
        return;
      }

      setDataInQueries(queryClient, context!.previousQueries);
      showNotification({ type: 'error' });
    },
  });

  return mutation;
};
