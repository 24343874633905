import React from 'react';
import { StyleSheet, View } from 'react-native';

import { palette, typography } from 'src/styles';

import { Icon } from './Icon/Icon';
import { IconButton } from './IconButton';
import { StyledText } from './StyledText';

interface Props {
  title: string;
  description?: string;
  close: () => void;
  testID?: string;
}

export const ConfirmationMessageModal: React.FC<Props> = ({ title, description, close, testID }) => (
  <View style={styles.wrapper} testID={testID}>
    <IconButton
      name="close"
      onPress={close}
      color={palette.blue}
      width={11}
      containerStyle={styles.closeButton}
      testID="modal-close-btn"
      hitSlop={20}
    />
    <Icon name="checkmark-circle" width={53} />
    <StyledText style={[styles.title, typography.body3SemiBold]}>{title}</StyledText>
    {description && <StyledText style={styles.description}>{description}</StyledText>}
  </View>
);

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
  closeButton: {
    flex: 1,
    alignSelf: 'flex-end',
    paddingRight: 16,
    paddingTop: 24,
  },
  title: {
    marginTop: 24,
    textAlign: 'center',
    color: palette.grey8,
  },
  description: {
    marginTop: 16,
    textAlign: 'center',
    color: palette.grey8,
  },
});
