import React, { useEffect, useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';

import { MarkdownContent, StyledText, ContentItemScreen } from 'src/components';
import { useContentNotes } from 'src/features/notes';
import {
  OlyticsService,
  MixpanelService,
  getMixpanelAppendixLocation,
  MixpanelEvent,
} from 'src/features/tracking';
import { markDownToPlainText } from 'src/helpers';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { typography } from 'src/styles';

import { useAppendixItem } from '../hooks';

export interface Props extends ScreenProps<Route.AppendixItem> {}

export const AppendixItemScreen: React.FC<Props> = ({ route, navigation }) => {
  const { id, source, searchQuery } = route.params;
  const { data, fetchStatus, error } = useAppendixItem(id);
  const notes = useContentNotes(id);

  useEffect(() => {
    if (data) {
      MixpanelService.track(MixpanelEvent.AppendixView, {
        'Appendix ID': data.id,
        'Appendix Title': data.title,
        'Search Term Before Click Through': source === 'search' ? searchQuery : undefined,
        ...getMixpanelAppendixLocation(source),
      });
    }

    OlyticsService.fire();
  }, [source, data, searchQuery]);

  useLayoutEffect(() => {
    data?.title && navigation.setOptions({ title: markDownToPlainText(data.title) });
  }, [data?.title, navigation]);

  const content = data && (
    <>
      <MarkdownContent style={styles.content}>{data.content}</MarkdownContent>
      {!!data.publicationDetails && (
        <>
          <StyledText style={[typography.body3SemiBold, styles.publicationDetailsTitle]}>
            {i18n.t('contents:publicationDetails')}
          </StyledText>
          <MarkdownContent style={styles.content} limited>
            {data.publicationDetails}
          </MarkdownContent>
        </>
      )}
    </>
  );

  return (
    <ContentItemScreen
      item={data}
      title={i18n.t('appendix:header')}
      fetchStatus={fetchStatus}
      error={error}
      oneSectionContent={content}
      notes={notes}
    />
  );
};

const styles = StyleSheet.create({
  publicationDetailsTitle: {
    marginTop: 20,
  },
  content: {
    ...typography.body2,
  },
});
