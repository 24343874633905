import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { BaseModal, PrimaryButton, Icon, Pressable, StyledText, TextArea } from 'src/components';
import type { ApiErrorMessages } from 'src/constants/types';
import { ModalPortal } from 'src/contexts/portals';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { showNotification } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { palette, typography } from 'src/styles';

import { useUserNotificationAction } from '../hooks';
import { CSATNotification, CSATNotificationActionDetails } from '../types';

interface Props {
  data: CSATNotification;
}

interface Errors extends ApiErrorMessages {
  feedback?: string;
}

export const CSATModal: React.FC<Props> = ({ data }) => {
  const [rate, setRate] = useState<number>(0);
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState('');

  const { isTablet } = useDeviceInfo();
  const { t } = useTranslation('surveys');
  const { mutate: closeUserNotification } = useUserNotificationAction(data.id, 'close');
  const { mutate: submit, isPending } = useUserNotificationAction(data.id, 'submit');

  const handleSubmit = () => {
    setError('');
    const data: CSATNotificationActionDetails = {
      rate,
      feedback: feedback || undefined,
    };
    submit(data, {
      onSuccess: () => {
        MixpanelService.track(MixpanelEvent.CSATComplete, {
          'CSAT Rate': rate,
          'CSAT Feedback': feedback,
        });
      },
      onError: (error: any) => {
        const { feedback, detail } = getMessagesFromErrorResponse<Errors>(error, 'details');
        if (feedback) {
          setError(feedback);
        } else {
          showNotification({ type: 'error', title: detail });
          closeUserNotification();
        }
      },
    });
  };

  const handleClose = () => {
    MixpanelService.track(MixpanelEvent.CSATClose);
    closeUserNotification();
  };

  return (
    <ModalPortal>
      <BaseModal style={styles.modal} close={handleClose}>
        <View style={styles.wrapper}>
          <StyledText style={styles.header}>{t('csatHeader')}</StyledText>
          <View style={styles.starsWrapper}>
            {Array.from(Array(5)).map((_v, index) => (
              <Pressable onPress={() => setRate(index + 1)} key={index}>
                <View style={styles.star}>
                  <Icon
                    name={rate > index ? 'star' : 'star-outline'}
                    color={palette.blue}
                    width={28}
                    strokeWidth={2.1}
                  />
                </View>
              </Pressable>
            ))}
          </View>
          <View style={styles.textAreaWrapper}>
            <TextArea
              value={feedback}
              onChangeText={setFeedback}
              placeholder={t('csatFeedback')}
              height={150}
              error={error}
            />
          </View>
          <PrimaryButton
            title={t('common:submit')}
            onPress={handleSubmit}
            disabled={!rate}
            loading={isPending}
            containerStyle={[styles.button, isTablet && styles.buttonTablet]}
            size="medium"
          />
        </View>
      </BaseModal>
    </ModalPortal>
  );
};

const styles = StyleSheet.create({
  modal: {
    maxWidth: 520,
  },
  wrapper: {
    alignItems: 'center',
  },
  header: {
    ...typography.body5Bold,
    color: palette.navy,
    marginBottom: 24,
  },
  starsWrapper: {
    flexDirection: 'row',
    marginBottom: 30,
  },
  star: {
    marginLeft: 3,
    marginRight: 3,
  },
  textAreaWrapper: {
    marginBottom: 32,
    alignSelf: 'stretch',
    marginHorizontal: 2,
  },
  button: {
    width: '100%',
  },
  buttonTablet: {
    width: 280,
  },
});
