import { StyleSheet, View } from 'react-native';

import {
  CircleIconButton,
  Container,
  InfiniteScrollList,
  ListItem,
  MobileContentHeader,
} from 'src/components';
import { QueryErrorBanner } from 'src/errorHandling/components';
import { DrugSuggestionsItem } from 'src/features/drugs';
import { MixpanelEvent, MixpanelService, getMixpanelDICLocation } from 'src/features/tracking';
import { useContainer, useDeviceInfo } from 'src/hooks';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks/useNavigation';
import { FLASH_LIST_ESTIMATED_ITEM_SIZE } from 'src/styles';

import { useDrugSuggestionsList } from '../hooks';
import { DICSource } from '../types';

interface Props {
  query: string;
  selectedDrugs: string[];
  source?: DICSource;
  onItemPress?(): void;
}

export const InteractionsSearchResults: React.FC<Props> = ({
  query,
  selectedDrugs,
  source,
  onItemPress,
}) => {
  const containerStyle = useContainer({ fullWidthOnMobile: true, maxWidth: 'narrow' });
  const { navigate } = useNavigation();
  const { isTablet } = useDeviceInfo();

  const { data, hasNextPage, fetchNextPage, isFetching, allFetched, error, fetchStatus } =
    useDrugSuggestionsList(query, selectedDrugs);

  const handleItemPress = (item: DrugSuggestionsItem) => {
    const updatedDrugsList = [...selectedDrugs, item.id];

    MixpanelService.track(MixpanelEvent.DICDrugAdded, {
      'Drug Name': item.title,
      'Number of Drugs Added': updatedDrugsList.length,
      Location: getMixpanelDICLocation(source),
    });

    navigate(Route.DIC, {
      drugs: updatedDrugsList,
      source: source,
    });

    onItemPress?.();
  };

  return (
    <>
      <QueryErrorBanner isDataAvailable={!!data} error={error} fetchStatus={fetchStatus} />
      <InfiniteScrollList
        keyboardShouldPersistTaps="always"
        data={data?.items ?? []}
        allFetched={allFetched}
        fetchNextPage={() => (hasNextPage ? fetchNextPage() : null)}
        onEndReachedThreshold={0.9}
        loading={isFetching}
        renderItem={({ item, index }) => (
          <View style={containerStyle} testID="result-item">
            <ListItem
              title={item.title}
              topBorder={index === 0}
              onPress={() => handleItemPress(item)}
              rightColumn={<CircleIconButton name="plus" outline containerSize={28} width={12} />}
              sideBorder={isTablet}
              roundedTopBorder={isTablet && index === 0}
              roundedBottomBorder={isTablet && data && index === data?.items.length - 1}
            />
          </View>
        )}
        ListHeaderComponent={() =>
          query && !isFetching && data ? (
            <Container maxWidth="narrow" style={styles.listHeader} testID="results-count">
              <MobileContentHeader>{i18n.t('search:results', { count: data?.count })}</MobileContentHeader>
            </Container>
          ) : null
        }
        keyExtractor={(item) => item.id}
        estimatedItemSize={FLASH_LIST_ESTIMATED_ITEM_SIZE}
      />
    </>
  );
};

const styles = StyleSheet.create({
  listHeader: {
    marginTop: 25,
    marginBottom: 12,
  },
});
