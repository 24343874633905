import { ScreenOptionsMap, DumbScreenOptionsMap } from 'src/navigation/types';

import { Route } from '../routes';

const options: ScreenOptionsMap = {
  [Route.DrugItem]: {
    getId: ({ params }) => params.id,
  },
  [Route.DxTxItem]: {
    getId: ({ params }) => params.id,
  },
  [Route.AppendixItem]: {
    getId: ({ params }) => params.id,
  },
  [Route.AlgorithmItem]: {
    getId: ({ params }) => params.id,
  },
  [Route.Note]: {
    getId: ({ params }) => ('id' in params ? params.id : 'new_note'),
  },
};

export const screenOptions = options as DumbScreenOptionsMap;
