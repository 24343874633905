import React from 'react';
import { StyleSheet, View } from 'react-native';

import { BaseCard, Box, StyledText } from 'src/components';
import { GroupRole } from 'src/features/auth/enums';
import { formatDateWithMonths } from 'src/helpers';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { GroupMemberActions } from './GroupMemberActions';
import { InvitationStatus } from './InvitationStatus';
import { getUserRoleTranslationKey, getMemberLicenseText } from '../../helpers';
import { GroupMembersComponentProps, GroupMemberStatus } from '../../types';

interface Props extends GroupMembersComponentProps {}

export const GroupMembersCards: React.FC<Props> = ({ data }) => {
  const renderInfoRow = (key: string, value: React.ReactNode) => (
    <View style={styles.infoRow}>
      <StyledText style={typography.body1ShortSemiBold}>{key}</StyledText>
      {typeof value === 'string' ? (
        <StyledText style={[typography.body1, styles.value]}>{value}</StyledText>
      ) : (
        value
      )}
    </View>
  );

  return (
    <>
      {data.map((member) => (
        <Box key={member.email} style={styles.box}>
          <BaseCard style={styles.card}>
            {renderInfoRow(i18n.t('adminDashboard:userName'), member.fullName)}
            {renderInfoRow(
              i18n.t('adminDashboard:role'),
              <StyledText style={typography.body1}>{i18n.t(getUserRoleTranslationKey(member))}</StyledText>,
            )}
            {renderInfoRow(i18n.t('adminDashboard:license'), getMemberLicenseText(member))}
            {renderInfoRow(i18n.t('emailAddress'), member.email)}
            {renderInfoRow(
              i18n.t('adminDashboard:dateCreatedOrStatus'),
              member.status === GroupMemberStatus.Confirmed ? (
                formatDateWithMonths(member.createdAt || '')
              ) : (
                <InvitationStatus status={member.status} emailStatus={member.emailStatus} />
              ),
            )}
            {member.role !== GroupRole.ADMIN && (
              <>
                <View style={styles.divider} />
                <GroupMemberActions member={member} />
              </>
            )}
          </BaseCard>
        </Box>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  box: {
    marginBottom: 20,
  },
  card: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  value: {
    textAlign: 'right',
    minWidth: 0,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: palette.grey2,
    marginBottom: 15,
  },
});
