import { RefObject, createRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { AccordionLegacy, StyledText } from 'src/components';
import { AccordionRef } from 'src/constants/types/accordion';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { CONTAINER_PADDING_H_MOBILE, palette, typography } from 'src/styles';

import { NotesListItem, NotesListItemRef } from './NotesListItem';
import { RemoveNoteConfirmModal } from './RemoveNoteConfirmModal';
import { NEW_GENERAL_NOTE_PLACEHOLDER_ID } from '../constants';
import { useNotesListContext } from '../context/NotesListContext';
import { ContentNote, ContentNotesGroup, GeneralNotesGroup, Note, NotesGroupType } from '../types';

type NoteProps =
  | {
      groupType: NotesGroupType.General;
      group: GeneralNotesGroup;
    }
  | {
      groupType: Exclude<NotesGroupType, NotesGroupType.General>;
      group: ContentNotesGroup[];
    };

type Props = {
  title: string;
  displayEmptyContentMessage?: boolean;
} & NoteProps;

interface ListProps {
  notes: (Note | ContentNote)[];
  inAccordion?: boolean;
}

interface ListRef {
  close(): void;
}

export interface NotesGroupRef {
  closeAccordion(): void;
}

export const NotesGroup = forwardRef<NotesGroupRef, Props>(
  ({ title, group, groupType, displayEmptyContentMessage }, ref) => {
    const accordionRef = useRef<AccordionRef>(null);
    const listRef = useRef<ListRef>(null);
    const { isTablet } = useDeviceInfo();
    const { unsavedNotes, isAddingNewGeneralNote } = useNotesListContext();

    const [removeNoteConfirmModalProps, setRemoveNoteConfirmModal] = useState<null | React.ComponentProps<
      typeof RemoveNoteConfirmModal
    >>(null);

    useImperativeHandle(ref, () => ({
      closeAccordion: () => {
        accordionRef.current?.collapseAllItems();
        listRef.current?.close();
      },
    }));

    const beforeAccordionSectionToggle = async (id: string, isClosing: boolean) => {
      if (!isClosing) return true;

      const notes = (group as ContentNotesGroup[]).find((group) => group.contentId === id)?.notes || [];
      if (unsavedNotes.some((noteId) => notes.find((note) => note.id === noteId))) {
        return await new Promise<boolean>((finalize) => {
          setRemoveNoteConfirmModal({
            confirm: () => {
              setRemoveNoteConfirmModal(null);
              finalize(true);
            },
            cancel: () => {
              setRemoveNoteConfirmModal(null);
              finalize(false);
            },
            type: 'navigation',
          });
        });
      }
      return true;
    };

    const getContent = () => {
      if (groupType === NotesGroupType.General) {
        const notes = [...group];
        if (isAddingNewGeneralNote) {
          notes.unshift({
            createdAt: '',
            updatedAt: '',
            id: NEW_GENERAL_NOTE_PLACEHOLDER_ID,
            note: '',
            title: '',
          });
        }

        return <NotesList notes={notes} ref={listRef} />;
      }

      if (groupType === NotesGroupType.Appendix) {
        // Every appendix can have only 1 note, so no need to display it as Accordion
        const notes = group.flatMap((contentGroup) => contentGroup.notes);

        return <NotesList notes={notes} ref={listRef} />;
      }

      return (
        <AccordionLegacy
          fullWidthMode={!isTablet}
          ref={accordionRef}
          items={group.map((contentGroup) => ({
            id: contentGroup.contentId,
            Label: contentGroup.contentTitle,
            Content: <NotesList notes={contentGroup.notes} inAccordion />,
          }))}
          beforeItemToggle={beforeAccordionSectionToggle}
        />
      );
    };

    const isContentEmpty = group.length === 0;

    return (
      <View testID="notes-group" dataSet={{ groupTitle: title }}>
        {getContent()}
        {isContentEmpty && displayEmptyContentMessage && !isAddingNewGeneralNote && (
          <StyledText
            style={[styles.emptyContentMessage, isTablet && styles.emptyContentMessageTablet]}
            testID="no-notes-message"
          >
            {i18n.t('notes:noNotes')}
          </StyledText>
        )}
        {removeNoteConfirmModalProps && <RemoveNoteConfirmModal {...removeNoteConfirmModalProps} />}
      </View>
    );
  },
);

const NotesList = forwardRef<ListRef, ListProps>(({ notes, inAccordion }, ref) => {
  const { isTablet } = useDeviceInfo();

  const refs: RefObject<NotesListItemRef>[] = [];

  useImperativeHandle(ref, () => ({
    close: () => {
      refs.forEach((item) => item.current?.close());
    },
  }));

  const createItemRef = () => {
    const ref = createRef<NotesListItemRef>();
    refs.push(ref);
    return ref;
  };

  if (!notes.length) {
    return null;
  }

  return (
    <View
      style={!inAccordion && [styles.notesListStandalone, isTablet && styles.notesListStandaloneRounded]}
    >
      {notes.map((item, index) => (
        <NotesListItem
          note={item}
          key={item.id}
          bottomBorder={index !== notes.length - 1}
          ref={createItemRef()}
          openByDefault={!!inAccordion}
          testID="note-section-title"
        />
      ))}
    </View>
  );
});

const styles = StyleSheet.create({
  notesListStandalone: {
    borderWidth: 1,
    borderColor: palette.grey2,
  },
  notesListStandaloneRounded: {
    borderRadius: 5,
  },
  emptyContentMessage: {
    ...typography.body2,
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  emptyContentMessageTablet: {
    paddingHorizontal: 0,
  },
});
