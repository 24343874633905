import { LoadingIndicator } from 'src/components';

import { StudentDataForm } from './StudentDataForm';
import { StudentDocumentsForm } from './StudentDocumentsForm';
import { StudentEmailLoopMessage } from './StudentEmailLoopMessage';
import { StudentSSOWindow } from './StudentSSOWindow';
import { VerifiedStudentMessage } from './VerifiedStudentMessage';
import { useStudentVerificationContext } from '../context/StudentVerificationContext';

interface Props {
  email: string;
}

export const StudentVerification: React.FC<Props> = ({ email }) => {
  const { currentStep, isFetching } = useStudentVerificationContext();

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {!currentStep ? null : currentStep === 'verified' ? (
        <VerifiedStudentMessage />
      ) : currentStep === 'sso' || currentStep === 'sso-pending' ? (
        <StudentSSOWindow />
      ) : currentStep === 'documents' || currentStep === 'pending' ? (
        <StudentDocumentsForm />
      ) : currentStep === 'email-loop' ? (
        <StudentEmailLoopMessage />
      ) : (
        <StudentDataForm email={email} />
      )}
    </>
  );
};
