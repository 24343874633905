// the import below will be transformed into polyfill imports (from core-js) by Babel
// polyfills are selected based on browserslist (defined in package.json)
import 'react-app-polyfill/stable';
import './helpers/polyfills';

import { AppRegistry } from 'react-native';
import './styles/web.css';
import './styles/recurly.css';

import { App } from './App';

AppRegistry.registerComponent('Plumb', () => App);

AppRegistry.runApplication('Plumb', {
  rootTag: document.getElementById('root'),
});
