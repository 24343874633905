import { useMemo, useState } from 'react';

interface MinimalAccordionItem {
  id: string;
}

export const useAccordionState = (items: MinimalAccordionItem[], initialExpanded: string[] = []) => {
  const [expandedItemsMap, setExpandedItemsMap] = useState<Record<string, boolean>>(
    items.reduce(
      (obj, current) => ({
        ...obj,
        [current.id]: initialExpanded.includes(current.id),
      }),
      {},
    ),
  );

  const [animatedItemsMap, setAnimatedItemsMap] = useState<Record<string, boolean>>(
    items.reduce(
      (obj, current) => ({
        ...obj,
        [current.id]: false,
      }),
      {},
    ),
  );

  const accordionState = useMemo(
    () => ({
      expandedItemsMap,
      setExpandedItemsMap,
      animatedItemsMap,
      setAnimatedItemsMap,
    }),
    [expandedItemsMap, setExpandedItemsMap, animatedItemsMap, setAnimatedItemsMap],
  );

  return accordionState;
};

export type AccordionState = ReturnType<typeof useAccordionState>;
