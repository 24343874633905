import type { ApiError, PaginationResponse } from 'src/constants/types';
import { GroupRole } from 'src/features/auth/enums';

export type AdminDashboardTab = 'my-group' | 'practice-location' | 'subscription-details';

export type SubscriptionFormSource = 'plan-type' | 'plan-benefits';
export interface AdminDashboardParams {
  tab: AdminDashboardTab;
  formSource?: SubscriptionFormSource;
}

export enum GroupMemberStatus {
  Confirmed = 'confirmed',
  Pending = 'pending',
  Expired = 'expired',
}

export enum GroupMemberEmailStatus {
  Reject = 'reject',
  Complaint = 'complaint',
  BounceNoEmail = 'bounce_no_email',
  BounceMailboxFull = 'bounce_mailbox_full',
  BounceOther = 'bounce_other',
}

export enum GroupMemberAccessLevel {
  Basic = 'basic',
  Pro = 'pro',
  ProLimited = 'pro-limited',
}

export interface GroupMember {
  fullName?: string | null;
  email: string;
  createdAt: string | null;
  status: GroupMemberStatus;
  emailStatus?: GroupMemberEmailStatus;
  id: string;
  role: GroupRole;
  accessLevel: GroupMemberAccessLevel;
}

export type PracticeLocation = {
  city: string | null;
  address1: string | null;
  address2?: string | null;
  country: string | null;
  state: string | null;
  postalCode: string | null;
  practice: string | null;
};

export interface PracticeLocationParams {
  location: Omit<PracticeLocation, 'practice'>;
  practice: string;
}

export interface FetchGroupMembersResponse {
  members: PaginationResponse<GroupMember>;
  name: string;
}

export interface AdminGroupInfo {
  members: GroupMember[];
  name: string;
  hasSupportStaffRole: boolean;
  supportStaffName: string | null;
}

export interface EditGroupNameResponse {
  name: string;
}

export interface FetchPracticeLocationResponse {
  location?: Omit<PracticeLocation, 'practice'>;
  practice: string;
}

export type EditPracticeLocationResponse = FetchPracticeLocationResponse;

export interface EditPracticeLocationErrors {
  practice?: ApiError | ApiError[];
  location?: Record<string, ApiError | ApiError[]>;
}

export interface InviteNewMembersParams {
  emails: string[];
  role: GroupRole.MEMBER | GroupRole.SUPPORT_STAFF;
  /** Required in mixed groups with Pro and Basic licences */
  accessLevel?: GroupMemberAccessLevel;
  confirmSupportStaffInvitation?: boolean;
}

export interface InviteNewMembersResponse {
  invitedEmails: {
    email: string;
    id: string;
  }[];
  memberEmails: string[];
}

export interface ResendInvitationResponse {
  email: string;
  id: string;
}

export interface CancelInvitationResponse {
  membershipId: string;
}

export interface RegularGroupLicencesResponse {
  numberOfLicences: number;
  numberOfAvailableLicences: number;
}

export interface MixedGroupLicencesResponse {
  numberOfAvailableBasicLicences: number;
  numberOfAvailableProLicences: number;
  numberOfBasicLicences: number;
  numberOfProLicences: number;
}

export type NumberOfLicencesResponse = RegularGroupLicencesResponse | MixedGroupLicencesResponse;

export interface Licences {
  total: number;
  available: number;
}

export interface MixedLicences {
  pro: Licences;
  basic: Licences;
}

interface GroupLicencesBase {
  licences: Licences | MixedLicences;
  isMixedGroup: boolean;
}

export interface MixedGroupLicences extends GroupLicencesBase {
  licences: MixedLicences;
  isMixedGroup: true;
  areLicencesAvailable: boolean;
}

export interface RegularGroupLicences extends GroupLicencesBase {
  licences: Licences;
  isMixedGroup: false;
  areLicencesAvailable: boolean;
}

export type GroupLicences = RegularGroupLicences | MixedGroupLicences;

export interface GroupMembersComponentProps {
  data: GroupMember[];
  isMixedGroup: boolean;
}

export interface EditGroupRolePayload {
  memberId: string;
  role: GroupRole.MEMBER | GroupRole.SUPPORT_STAFF;
  /** Required only for mixed groups and GroupRole.MEMBER */
  accessLevel?: GroupMemberAccessLevel;
}

export interface EditGroupRoleResponse {
  role: GroupRole;
  accessLevel: GroupMemberAccessLevel;
}
