import { useEffect } from 'react';

import { ContentItemScreen } from 'src/components';
import {
  MixpanelEvent,
  MixpanelService,
  OlyticsService,
  getMixpanelDrugHandoutLocation,
  getMixpanelClinicalHandoutLocation,
} from 'src/features/tracking';
import { isWeb } from 'src/helpers/common';
import { isDrugAdministrationHandout, isDrugHandout } from 'src/helpers/content';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { HandoutDescription } from '../components';
import { useHandoutItem } from '../hooks';

type Props = ScreenProps<Route.DrugHandoutItem | Route.ClinicalHandoutItem>;

export const HandoutItemScreen: React.FC<Props> = ({ route }) => {
  const { id, source, searchQuery, section, subsection } = route.params;

  const { data, error, fetchStatus } = useHandoutItem(id);

  useEffect(() => {
    if (data) {
      if (isWeb) {
        OlyticsService.fire({
          PetOwnerGuidesDescription: data.title,
        });
      }
      const query = source === 'search' ? searchQuery : undefined;

      if (isDrugHandout(data)) {
        const location = getMixpanelDrugHandoutLocation(source);
        if (isDrugAdministrationHandout(data)) {
          MixpanelService.track(MixpanelEvent.DrugAdministrationHandoutView, {
            'Drug Administration Handout ID': data.id,
            'Drug Administration Handout Title': data.title,
            'Search Term Before Click Through': query,
            ...location,
          });
        } else {
          MixpanelService.track(MixpanelEvent.DrugHandoutView, {
            'VMG ID': data.id,
            'VMG Title': data.title,
            'Search Term Before Click Through': query,
            ...location,
          });
        }
      } else {
        MixpanelService.track(MixpanelEvent.ClinicalHandoutView, {
          'Patient Guide ID': data.id,
          'Patient Guide Title': data.title,
          'Search Term Before Click Through': query,
          ...getMixpanelClinicalHandoutLocation(source),
        });
      }
    }
  }, [data, source, searchQuery]);

  return (
    <ContentItemScreen
      item={data}
      title={i18n.t('screens:handout')}
      fetchStatus={fetchStatus}
      error={error}
      headerDescription={data && <HandoutDescription data={data} />}
      sectionToScrollTo={section}
      subsectionToScrollTo={subsection}
    />
  );
};
