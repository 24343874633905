import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Host } from 'react-native-portalize';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';

import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { palette, TD_WIDTH_HORIZONTAL, TH_WIDTH_HORIZONTAL } from 'src/styles';

const FADE_GRADIENT_WIDTH = 36;
const FIXED_COLUMN_SHADOW_WIDTH = 40;
const TABLE_PADDING_BOTTOM_HORIZONTAL = 10;

interface Props {
  horizontalTableRightPadding: number;
}

interface GradientProps {
  height: number;
  shadowOffset: number;
  fadeOffset: number;
}

export const Table: React.FC<React.PropsWithChildren<Props>> = ({
  horizontalTableRightPadding,
  children,
}) => {
  const [gradientProps, setGradientProps] = useState<GradientProps>({
    height: 0,
    shadowOffset: 0,
    fadeOffset: 0,
  });
  const [width, setWidth] = useState<number>(0);
  const { isTablet } = useDeviceInfo();
  const isTableHorizontal = !isTablet;
  // sometimes onScroll is called after component has been unmounted  which results in error in the console when we try to call setState then
  const safeSetGradientProps = useRef(setGradientProps);
  const safeSetWidth = useRef(setWidth);

  useEffect(() => {
    safeSetGradientProps.current = setGradientProps;
    return () => {
      safeSetGradientProps.current = () => null;
    };
  }, [setGradientProps]);

  useEffect(() => {
    safeSetWidth.current = setWidth;
    return () => {
      safeSetWidth.current = () => null;
    };
  }, [setWidth]);

  let reorderedChildren: ReactNode[][] = children as ReactNode[][];
  const rowsNumber = reorderedChildren[1].length;

  // on mobile we need to transform rows to columns, we could do that using CSS prop `order` but it won't work on native apps
  if (isTableHorizontal) {
    // @ts-ignore
    reorderedChildren = children![0]![0].map((header: ReactNode) => [header]);
    // @ts-ignore
    const rows: ReactNode[][] = children![1] as ReactNode[][];
    rows.forEach((row: ReactNode[]) => {
      row.forEach((cell: ReactNode, index: number) => {
        reorderedChildren[index]!.push(cell);
      });
    });
  }

  const mainTableStyles = StyleSheet.create({
    tableWrapper: {
      position: 'relative',
      marginVertical: 8,
    },
    contentWrapper: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      ...(isTableHorizontal
        ? { width: TD_WIDTH_HORIZONTAL * rowsNumber + TH_WIDTH_HORIZONTAL + horizontalTableRightPadding }
        : { maxWidth: '100%' }),
    },
    contentContainerHorizontal: {
      paddingBottom: TABLE_PADDING_BOTTOM_HORIZONTAL,
      paddingRight: horizontalTableRightPadding,
    },
    gradientsWrapper: {
      zIndex: 1,
      pointerEvents: 'none',
    },
    shadow: {
      zIndex: 1,
      left: TH_WIDTH_HORIZONTAL - FIXED_COLUMN_SHADOW_WIDTH + gradientProps.shadowOffset,
    },
    fade: {
      zIndex: 1,
      left: undefined,
      right: -horizontalTableRightPadding,
    },
    table: {
      borderRadius: 0,
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderColor: palette.grey2,
      ...(isTableHorizontal
        ? {
            borderLeftWidth: 0,
            flexDirection: 'row',
            width: width + horizontalTableRightPadding - 1,
            display: width ? 'flex' : 'none',
          }
        : {
            borderLeftWidth: 1,
            flexDirection: 'column',
            width: '100%',
            marginBottom: 20,
          }),
    },
  });

  return (
    <View
      style={mainTableStyles.tableWrapper}
      onLayout={(event) => {
        safeSetWidth.current(event.nativeEvent.layout.width);
      }}
    >
      <Host>
        {/* copies of the header cells will be moved here to create a fixed column in horizontal layout */}
        {isTableHorizontal && (
          <View style={[StyleSheet.absoluteFill, mainTableStyles.gradientsWrapper]}>
            <Svg
              height="100%"
              width={FIXED_COLUMN_SHADOW_WIDTH}
              style={[StyleSheet.absoluteFill, mainTableStyles.shadow]}
            >
              <Defs>
                <LinearGradient id="shadow">
                  <Stop offset="0" stopColor={palette.black} stopOpacity="0.08" />
                  <Stop offset="1" stopColor={palette.black} stopOpacity="0" />
                </LinearGradient>
              </Defs>
              <Rect x="0" y="0" width="100%" height={gradientProps.height} fill="url(#shadow)" />
            </Svg>
            <Svg
              height="100%"
              width={FADE_GRADIENT_WIDTH}
              style={[StyleSheet.absoluteFill, mainTableStyles.fade]}
            >
              <Defs>
                <LinearGradient id="fade">
                  <Stop offset="0" stopColor={palette.white} stopOpacity="0" />
                  <Stop offset="1" stopColor={palette.white} stopOpacity="1" />
                </LinearGradient>
              </Defs>
              <Rect x={gradientProps.fadeOffset} y="0" width="100%" height="100%" fill="url(#fade)" />
            </Svg>
          </View>
        )}
        <ScrollView
          onScroll={({ nativeEvent: { contentOffset, contentSize, layoutMeasurement } }) => {
            const distanceToEnd = contentSize.width - contentOffset.x - layoutMeasurement.width;
            // sliding out shadow on scrolling
            const shadowOffset = Math.min(FIXED_COLUMN_SHADOW_WIDTH, Math.max(contentOffset.x, 0));
            // hiding fade gradient at the scroll end
            const fadeOffset =
              FADE_GRADIENT_WIDTH -
              horizontalTableRightPadding -
              Math.min(distanceToEnd, FADE_GRADIENT_WIDTH - horizontalTableRightPadding);
            if (shadowOffset !== gradientProps.shadowOffset || fadeOffset !== gradientProps.fadeOffset) {
              safeSetGradientProps.current((state) => ({ ...state, shadowOffset, fadeOffset }));
            }
          }}
          scrollEventThrottle={16}
          style={mainTableStyles.table}
          horizontal={isTableHorizontal}
          contentContainerStyle={[isTableHorizontal && mainTableStyles.contentContainerHorizontal]}
          bounces={false}
        >
          <View
            style={[mainTableStyles.contentWrapper]}
            onLayout={({
              nativeEvent: {
                layout: { height },
              },
            }) => {
              if (height !== gradientProps.height) {
                safeSetGradientProps.current((state) => ({ ...state, height }));
              }
            }}
          >
            {reorderedChildren}
          </View>
        </ScrollView>
      </Host>
    </View>
  );
};
