import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { ifWeb, palette } from 'src/styles';

export interface MainSectionProps {
  title: string;
  subtitleContent?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  isLoading?: boolean;
  testID?: string;
}
interface AdditionalStylesProp {
  style?: StyleProp<ViewStyle>;
  testID?: string;
}
interface SubComponentProps extends AdditionalStylesProp {
  breakpoint?: 'tablet' | 'desktop' | false;
}

type SubComponent<T = unknown> = React.FC<React.PropsWithChildren<T>>;

export interface SubComponents {
  Form: SubComponent<SubComponentProps>;
  Row: SubComponent<SubComponentProps>;
  ButtonsWrapper: SubComponent<AdditionalStylesProp>;
  Item: SubComponent<SubComponentProps>;
  Footer: SubComponent;
}

export const Form: SubComponent<SubComponentProps> = ({
  breakpoint = 'tablet',
  children,
  style,
  testID,
}) => {
  const { isTablet, isDesktop } = useDeviceInfo();
  const isWide = (breakpoint === 'tablet' && isTablet) || (breakpoint === 'desktop' && isDesktop);
  return (
    <View style={[styles.form, styles.zIndexUnset, isWide && styles.formWide, style]} testID={testID}>
      {children}
    </View>
  );
};

export const Row: SubComponent<SubComponentProps> = ({ breakpoint = 'tablet', children, style }) => {
  const { isTablet, isDesktop } = useDeviceInfo();
  const isNarrow = (breakpoint === 'tablet' && isTablet) || (breakpoint === 'desktop' && isDesktop);
  return (
    <View style={[styles.row, styles.zIndexUnset, isNarrow && styles.rowNarrow, style]}>{children}</View>
  );
};
export const Item: SubComponent<AdditionalStylesProp> = ({ children, style, testID }) =>
  children ? (
    <View style={[styles.item, styles.zIndexUnset, style]} testID={testID}>
      {children}
    </View>
  ) : null;

export const ButtonsWrapper: SubComponent<SubComponentProps> = ({
  breakpoint = 'tablet',
  children,
  style,
}) => {
  const { isTablet, isDesktop } = useDeviceInfo();
  const isWide = (breakpoint === 'tablet' && isTablet) || (breakpoint === 'desktop' && isDesktop);
  const childrenLength = React.Children.toArray(children).length;
  return (
    <View
      style={[
        styles.buttonsWrapper,
        styles.zIndexUnset,
        isWide && styles.buttonsWrapperWide,
        childrenLength > 1 && styles.spaceBetween,
        style,
      ]}
    >
      {children}
    </View>
  );
};

export const Footer: SubComponent = ({ children }) => <View style={styles.footer}>{children}</View>;

const styles = StyleSheet.create({
  zIndexUnset: {
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
  form: {
    flexDirection: 'column',
  },
  formWide: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  row: {
    width: '100%',
  },
  rowNarrow: {
    width: '48%',
  },
  item: {
    marginBottom: 20,
  },
  buttonsWrapper: {
    marginTop: 8,
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  buttonsWrapperWide: {
    marginTop: 32,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  footer: {
    borderTopWidth: 1,
    borderTopColor: palette.grey2,
    marginTop: 32,
    paddingTop: 32,
    alignItems: 'center',
  },
});
