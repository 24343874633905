import React from 'react';
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native';

import { StyledText } from 'src/components';
import { ifWeb, palette, typography } from 'src/styles';

type RadioTileProps = {
  title: string;
  active: boolean;
  first: boolean;
  last: boolean;
  prevToActive?: boolean;
  nextToActive?: boolean;
  textStyle?: ViewStyle;
  customStyle?: ViewStyle;
  onPress: () => void;
  disabled?: boolean;
  hideShadow?: boolean;
  fullWidth?: boolean;
  description?: string;
  badge?: string;
  testID?: string;
};

export const Tile: React.FC<RadioTileProps> = ({
  customStyle,
  title,
  active,
  first,
  last,
  prevToActive,
  nextToActive,
  textStyle,
  onPress,
  disabled,
  hideShadow,
  fullWidth,
  description,
  badge,
  testID,
}) => {
  return (
    <Pressable
      style={[
        styles.tileWrapper,
        fullWidth && styles.tileWrapperFullWidth,
        last && styles.last,
        first && styles.first,
        prevToActive && [fullWidth ? styles.prevToActiveFullWidth : styles.prevToActive],
        nextToActive && [fullWidth ? styles.nextToActiveFullWidth : styles.nextToActive],
        customStyle,
        active && [styles.active, !hideShadow && styles.shadow, fullWidth && styles.activeFullWidth],
      ]}
      onPress={onPress}
      disabled={disabled}
      testID={testID}
    >
      <View style={styles.textWrapper} testID="tile-name">
        <StyledText
          ellipsizeMode="tail"
          singleLine
          style={[
            styles.text,
            active && styles.activeText,
            !!description && styles.textWithDescription,
            textStyle,
          ]}
        >
          {title}
        </StyledText>
        {!!badge && (
          <View style={styles.badge}>
            <StyledText style={[typography.body1Bold, styles.badgeText]}>{badge.toUpperCase()}</StyledText>
          </View>
        )}
      </View>
      {!!description && (
        <StyledText style={[styles.description, typography.body1, active && styles.descriptionActive]}>
          {description}
        </StyledText>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  tileWrapper: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderColor: palette.grey2,
    backgroundColor: palette.white,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 6,
    paddingHorizontal: 12,
    zIndex: 1,
  },
  tileWrapperFullWidth: {
    flex: 1,
    paddingVertical: 16,
    paddingHorizontal: 0,
  },
  prevToActive: {
    paddingRight: 18,
    marginRight: -9,
  },
  nextToActive: {
    paddingLeft: 18,
    marginLeft: -9,
  },
  prevToActiveFullWidth: {
    paddingRight: 15,
    marginRight: -15,
  },
  nextToActiveFullWidth: {
    paddingLeft: 15,
    marginLeft: -15,
  },
  last: {
    borderRightWidth: 1,
    borderBottomRightRadius: 26,
    borderTopRightRadius: 26,
  },
  first: {
    borderBottomLeftRadius: 26,
    borderTopLeftRadius: 26,
  },
  active: {
    backgroundColor: palette.blue,
    paddingVertical: 6,
    marginHorizontal: -3,
    borderBottomRightRadius: 29,
    borderTopRightRadius: 29,
    borderBottomLeftRadius: 29,
    borderTopLeftRadius: 29,
    zIndex: 2,
  },
  activeFullWidth: {
    paddingVertical: 19,
    paddingHorizontal: 3,
    marginVertical: -3,
  },
  shadow: {
    ...ifWeb(
      {
        boxShadow: `0px 0px 25px ${palette.blue}66`,
      },
      {
        shadowColor: palette.blue,
        shadowRadius: 25,
        shadowOpacity: 0.4,
      },
    ),
  },
  activeText: {
    color: palette.white,
  },
  textWrapper: {
    flexDirection: 'row',
  },
  text: {
    color: palette.grey4,
    paddingHorizontal: 5,
    ...typography.text2,
    ...typography.weightBold,
  },
  textWithDescription: {
    bottom: 7,
    position: 'relative',
  },
  description: {
    color: palette.grey4,
    position: 'absolute',
    top: '50%',
  },
  descriptionActive: {
    color: palette.white,
  },
  badge: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: palette.navy,
    marginLeft: 8,
    borderRadius: 50,
  },
  badgeText: {
    color: palette.grey1,
    lineHeight: 12,
  },
});
