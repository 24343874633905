import * as R from 'ramda';
import React, { useEffect } from 'react';

import { BaseButton } from 'src/components';
import { getErrorMessageForApiError, getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { IntercomService } from 'src/features/intercom/IntercomService';
import { isWeb, showNotification } from 'src/helpers';
import { useUniversalForm } from 'src/hooks/forms/useUniversalForm';
import { i18n } from 'src/locale';

import { AccountInformationSection as Section } from './AccountInformationSection';
import { personalInformationForm, personalInformationEmptyState } from '../forms';
import { parsePersonalInformationErrorsFromBackend } from '../helpers';
import { usePersonalInformation, useUpdatePersonalInformation } from '../hooks';
import type { NullablePersonalInformation, PersonalInformationErrorResponse } from '../types';

export const PersonalInformation: React.FC = () => {
  const { data, error, isLoading } = usePersonalInformation();
  const { mutate: updatePersonalInformation, isPending: isEditing } = useUpdatePersonalInformation();

  const submit = (values: NullablePersonalInformation) => {
    updatePersonalInformation(values, {
      onSuccess: () => {
        if (values.firstName) {
          IntercomService.updateUser({ name: values.firstName });
        }
      },
      onError: (err: any) => {
        if (err?.response?.status === 400) {
          const data = err.response.data as PersonalInformationErrorResponse;
          const parsedErrors = parsePersonalInformationErrorsFromBackend(data);
          setErrors(parsedErrors);
        } else {
          const { detail } = getMessagesFromErrorResponse(err);
          showNotification({ type: 'error', title: detail });
        }
      },
    });
  };

  const { renderField, handleSubmit, setErrors } = useUniversalForm(
    data || personalInformationEmptyState,
    submit,
    personalInformationForm,
  );

  useEffect(() => {
    if (error && typeof error !== 'boolean') {
      const errorMessages = R.mapObjIndexed(getErrorMessageForApiError, error);
      setErrors(errorMessages);
    }
  }, [error, setErrors]);

  const content = (
    <>
      <Section.Form>
        <Section.Row>
          <Section.Item>{renderField('firstName')}</Section.Item>
          <Section.Item>{renderField('lastName')}</Section.Item>
          <Section.Item>{renderField('phone')}</Section.Item>
          <Section.Item>{renderField('bornYear')}</Section.Item>
          <Section.Item>{renderField('country')}</Section.Item>
          <Section.Item>{renderField('state')}</Section.Item>
        </Section.Row>
        <Section.Row>
          <Section.Item>{renderField('city')}</Section.Item>
          <Section.Item>{renderField('postalCode')}</Section.Item>
          <Section.Item>{renderField('address1')}</Section.Item>
          <Section.Item>{renderField('address2')}</Section.Item>
          <Section.Item>{renderField('practice')}</Section.Item>
        </Section.Row>
      </Section.Form>
      <Section.ButtonsWrapper>
        <BaseButton
          title={i18n.t('saveChanges')}
          onPress={handleSubmit}
          loading={isEditing}
          testID="submit-btn"
          variant="gradient"
        />
      </Section.ButtonsWrapper>
    </>
  );

  return isWeb ? (
    <Section
      title={i18n.t('accountInformation:personalInformation')}
      isLoading={isLoading}
      testID="section-personal-information"
    >
      {content}
    </Section>
  ) : (
    content
  );
};
