import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { useIsSignedIn } from 'src/features/auth/hooks';
import { refreshAccessToken, setAuthTokens } from 'src/features/auth/state';
import {
  parsePersonalInformationForBackend,
  PersonalInformationPayload,
  parseOccupationDetailsForBackend,
} from 'src/features/profile';
import { MixpanelService, MixpanelEvent } from 'src/features/tracking';
import { userNotificationsQueryKeys } from 'src/features/userNotifications/constants';
import { UserNotificationsResponse } from 'src/features/userNotifications/types';
import { showNotification } from 'src/helpers';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks/useNavigation';
import { ApplicationState } from 'src/state';
import { AppDispatch } from 'src/state/store';

import {
  validateGroupInvitation,
  acceptGroupInvitation,
  registerAsGroupMember,
  fetchGroupConversionData,
  convertManagedGroup,
} from '../../api';
import { SUBSCRIPTION_INFO_QUERY_KEY } from '../../constants';
import { getBasePlanSetup } from '../../helpers';
import {
  GroupInvitationValidationPayload,
  AcceptGroupInvitationPayload,
  RegisterData,
  AcceptGroupInvitationResponse,
} from '../../types';

export const useGroupInvitationValidation = (data: GroupInvitationValidationPayload) => {
  return useQuery({
    queryKey: ['group-invitation-validation'],
    queryFn: () => validateGroupInvitation(data),
  });
};

export const useAcceptGroupInvitation = (
  params: { onSuccess?: (data: AcceptGroupInvitationResponse) => void } = {},
) => {
  const isSignedIn = useIsSignedIn();
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  const { t } = useTranslation('groupInvitation');
  const { onSuccess } = params;

  return useMutation({
    mutationFn: (params: AcceptGroupInvitationPayload) => acceptGroupInvitation(isSignedIn, params),
    onSuccess: (data) => {
      showNotification({ type: 'success', title: t('successMessage') });
      // onSuccess method is passed because using dipsatch directly in useMutation
      // results in the onSuccess method not being called in mutate in the component
      onSuccess?.(data);
      if (isSignedIn) {
        dispatch(setAuthTokens(data));
        queryClient.setQueryData(
          userNotificationsQueryKeys.list,
          (data: UserNotificationsResponse | undefined) =>
            data
              ? {
                  notifications: data.notifications.filter((item) => item.type !== 'group-invitation'),
                }
              : undefined,
        );
      }
    },
  });
};

export const useRegisterAsGroupMember = () => {
  return useMutation({
    mutationFn: (params: { data: RegisterData; membershipId: string }) => {
      const {
        createAccount: { email, password },
        completeYourProfile,
      } = params.data;

      const personalInformation = parsePersonalInformationForBackend(
        completeYourProfile.information,
      ) as PersonalInformationPayload;

      const payload = {
        email,
        password,
        membershipId: params.membershipId,
        ...personalInformation,
        demographics: {
          ...personalInformation.demographics,
          ...parseOccupationDetailsForBackend(completeYourProfile.occupation),
        },
      };

      return registerAsGroupMember(payload);
    },
    onSuccess: (data) => {
      const { id } = data;
      MixpanelService.identify(id);
      MixpanelService.track(MixpanelEvent.Subscribe);
      MixpanelService.clearUser();
    },
  });
};

export const useGroupConversionData = (id: string) => {
  return useQuery({
    queryKey: ['group-conversion', id],
    queryFn: async () => {
      const data = await fetchGroupConversionData(id);

      return {
        ...getBasePlanSetup(data.plan),
        quantity: data.quantity,
        discountCode: data.couponCode || '',
      };
    },
  });
};

export const useConvertManagedGroup = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigation();
  const queryClient = useQueryClient();

  const {
    tokens: { refresh },
  } = useSelector((state: ApplicationState) => state.auth);

  const { t } = useTranslation('groupConversion');

  return useMutation({
    mutationFn: convertManagedGroup,
    onSuccess: async () => {
      showNotification({ type: 'success' });
      await dispatch(refreshAccessToken(refresh));
      queryClient.refetchQueries({ queryKey: [SUBSCRIPTION_INFO_QUERY_KEY] });
      navigation.navigate(Route.SubscriptionDetails);
    },
    onError: (error: any) => {
      const status = error?.response?.status;
      if (status === 404) {
        // conversion group process was cancelled after user entered the form page
        showNotification({
          type: 'error',
          title: t('notFound'),
          autoHide: false,
        });

        return;
      }

      const { detail } = getMessagesFromErrorResponse<{ detail?: string }>(error);
      showNotification({ type: 'error', title: detail });
    },
  });
};
