import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { BaseModal, TilesSelector } from 'src/components';
import { TilesSelectorOption } from 'src/constants/types';
import { useUserInfo } from 'src/features/auth/hooks';

import { InviteMembers } from './InviteMembers';
import { InviteSupportStaff } from './InviteSupportStaff';

interface Props {
  close: () => void;
}

type Tab = 'member' | 'support-staff';

export const InviteNewUsersModal: React.FC<Props> = ({ close }) => {
  const {
    permissions: { hasSupportStaffFeatureEnabled },
  } = useUserInfo();

  const [activeTab, setActiveTab] = useState<Tab>('member');
  const { t } = useTranslation('adminDashboard');

  const getTiles = () => {
    const tiles: TilesSelectorOption<Tab>[] = [
      {
        label: t('member'),
        value: 'member',
        testID: 'professional-members-tile',
      },
    ];

    if (hasSupportStaffFeatureEnabled) {
      tiles.push({
        label: t('supportStaff'),
        value: 'support-staff',
        testID: 'support-staff-tile',
      });
    }

    return tiles;
  };

  const tiles = getTiles();

  return (
    <BaseModal close={close} header={t('inviteHeader')} testID="invite-new-user-modal">
      {tiles.length > 1 && (
        <TilesSelector
          options={tiles}
          selected={activeTab}
          onPress={setActiveTab}
          hideShadow
          wrapperStyle={styles.tiles}
        />
      )}

      {activeTab === 'member' && <InviteMembers close={close} />}
      {activeTab === 'support-staff' && <InviteSupportStaff close={close} />}
    </BaseModal>
  );
};

const styles = StyleSheet.create({
  tiles: {
    marginBottom: 20,
  },
});
