import { Permission } from 'src/features/auth/enums';
import { getPermissionsByContentType } from 'src/features/auth/helpers';
import {
  ScreenConfigAuthState,
  ScreenConfigSubscriptionStatus,
  ScreensAccessConfig,
} from 'src/navigation/types';

import { Route } from '../routes';

const SIGNED_IN: ScreenConfigAuthState[] = ['signed-in'];
const SIGNED_OUT: ScreenConfigAuthState[] = ['signed-out'];
const VALID_SUBSCRIPTION: ScreenConfigSubscriptionStatus[] = ['valid'];

export const screensAccess: ScreensAccessConfig = {
  [Route.SignIn]: {
    auth: SIGNED_OUT,
  },
  [Route.AccountInformation]: {
    auth: SIGNED_IN,
    permission: Permission.AccessIndividualAccountFeatures,
  },
  [Route.Auth]: {
    auth: SIGNED_OUT,
  },
  [Route.Home]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
  },
  [Route.DIC]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: Permission.FullAccessBasicContentTypes,
  },
  [Route.Algorithms]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('algorithm').display,
  },
  [Route.AlgorithmItem]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('algorithm').access,
  },
  [Route.DDxList]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('ddx').display,
  },
  [Route.DDxItem]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('ddx').access,
  },
  [Route.DxTxList]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('dx-tx').display,
  },
  [Route.DxTxItem]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('dx-tx').access,
  },
  [Route.DrugHandoutsList]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('drug-handout').display,
  },
  [Route.DrugHandoutItem]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('drug-handout').access,
  },
  [Route.ClinicalHandoutsList]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('clinical-handout').display,
  },
  [Route.ClinicalHandoutItem]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('clinical-handout').access,
  },
  [Route.SelectedHandouts]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
  },
  [Route.ShareHandout]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
  },
  [Route.SignUp]: {
    auth: SIGNED_OUT,
  },
  [Route.SubscriptionInvitation]: {
    auth: SIGNED_OUT,
  },
  [Route.ResetPassword]: {
    auth: SIGNED_OUT,
  },
  [Route.PasswordRecovery]: {
    auth: SIGNED_OUT,
  },
  [Route.SubscriptionDetails]: {
    auth: SIGNED_IN,
    permission: Permission.ManageSubscription,
  },
  [Route.AdminDashboard]: {
    auth: SIGNED_IN,
    permission: [Permission.ManageGroup, Permission.hasNoAccessToAdminDashboardAfterConversion],
  },
  [Route.FindDrugByName]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
  },
  [Route.DrugsList]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('drug').display,
  },
  [Route.DrugItem]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('drug').access,
  },
  [Route.AppendixList]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('appendix').display,
  },
  [Route.AppendixItem]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: getPermissionsByContentType('appendix').access,
  },
  [Route.SearchWeb]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
  },
  [Route.GroupConversion]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: Permission.ManageGroup,
  },
  [Route.Features]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
  },
  [Route.Notes]: {
    auth: SIGNED_IN,
    subscriptionStatus: VALID_SUBSCRIPTION,
    permission: Permission.AccessIndividualAccountFeatures,
  },
  [Route.StudentReverification]: {
    auth: SIGNED_IN,
    permission: Permission.AccessToStudentRenewals,
  },
};
