import React from 'react';
import { StyleSheet, View } from 'react-native';

import { BaseButton } from 'src/components/Buttons/BaseButton';
import { Container } from 'src/components/Container';
import { FullScreenTemplate } from 'src/components/FullScreenTemplate';
import { StyledText } from 'src/components/StyledText';
import { IconName } from 'src/constants/types';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';
import { palette, typography } from 'src/styles';

import { Icon } from './Icon/Icon';

interface Props {
  icon?: IconName;
  title: string;
  body: string;
  email: string;
}

export const ConfirmationScreen: React.FC<Props> = ({ icon = 'checkmark', title, body, email }) => {
  const { isSmallestMobile, isTablet } = useDeviceInfo();
  const navigation = useNavigation();

  return (
    <FullScreenTemplate>
      <Container
        verticalPadding
        style={[
          styles.container,
          isTablet && styles.containerTablet,
          isSmallestMobile && styles.containerSmallestMobile,
        ]}
        testID="thank-you-page"
      >
        <View style={styles.iconWrapper} testID="dollar-icon">
          <Icon name={icon} width={52} />
        </View>
        <StyledText style={[styles.header, typography.alt7Bold]}>{title}</StyledText>
        <StyledText style={[styles.text, typography.body3]}>{body}</StyledText>
        <BaseButton
          title={i18n.t('signIn:signIn')}
          onPress={() => navigation.navigate(Route.SignIn, { email })}
          testID="sign-in-btn"
          variant="gradient"
        />
      </Container>
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingVertical: 50,
  },
  containerTablet: {
    paddingVertical: 100,
  },
  containerSmallestMobile: {
    wordBreak: 'break-word',
  },
  header: {
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 24,
  },
  iconWrapper: {
    width: 114,
    height: 114,
    borderWidth: 1,
    borderColor: palette.grey3,
    borderRadius: 57,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    marginBottom: 24,
    maxWidth: 400,
  },
});
