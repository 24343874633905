import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Keyboard, Platform, StyleSheet, View } from 'react-native';

import {
  InputItem,
  ConfirmationMessageModal,
  BaseModal,
  SecondaryButton,
  BaseButton,
} from 'src/components';
import { ModalPortal } from 'src/contexts/portals';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { IntercomService } from 'src/features/intercom/IntercomService';
import { isWeb, showNotification } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';

import { useChangeEmail } from '../../../hooks';
import { AccountInformationSection as Section } from '../../AccountInformationSection';

interface Props {
  close: () => void;
}

const initialValues = {
  email: '',
  emailConfirmation: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('validation:enterValidEmail'))
    .required(i18n.t('validation:pleaseEnterNewEmail')),
  emailConfirmation: Yup.string()
    .oneOf([Yup.ref('email')], i18n.t('validation:emailShouldMatch'))
    .required(i18n.t('validation:pleaseEnterNewEmail')),
});

export const EmailChangeForm: React.FC<Props> = ({ close }) => {
  const { isTablet, isTabletApp } = useDeviceInfo();
  const [modalOpen, setModalOpen] = useState(false);
  const { mutate: changeEmail, isPending } = useChangeEmail();

  const openConfirmationModal = () => {
    setModalOpen(true);
  };
  const closeConfirmationModal = () => {
    setModalOpen(false);
    close();
  };

  const submitForm = async (values: { email: string }) => {
    changeEmail(values.email, {
      onSuccess: () => {
        !isWeb && Keyboard.dismiss();
        IntercomService.updateUser({ email: values.email });
        openConfirmationModal();
      },
      onError: (error: any) => {
        const { email, detail } = getMessagesFromErrorResponse<{ email: string }>(error);
        if (email) {
          setErrors({ email: email });
        } else {
          showNotification({ type: 'error', title: detail });
        }
      },
    });
  };

  const { handleChange, handleBlur, handleSubmit, setErrors, values, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitForm,
  });

  const handleEditingSubmit = Platform.OS === 'web' ? () => handleSubmit() : undefined;

  return (
    <>
      <Section.Item>
        <InputItem
          value={values.email}
          error={errors.email}
          touched={touched.email}
          onBlur={handleBlur('email') || undefined}
          onChangeText={handleChange('email') || undefined}
          onSubmitEditing={handleEditingSubmit}
          label={i18n.t('newEmail')}
          placeholder={i18n.t('enterNewEmail')}
          autoCompleteType="email"
          inputMode="email"
          testID="new-email-input"
        />
      </Section.Item>
      <Section.Item style={isTablet && !isTabletApp && styles.buttonLast}>
        <InputItem
          value={values.emailConfirmation}
          error={errors.emailConfirmation}
          touched={touched.emailConfirmation}
          onBlur={handleBlur('emailConfirmation') || undefined}
          onChangeText={handleChange('emailConfirmation') || undefined}
          onSubmitEditing={handleEditingSubmit}
          label={i18n.t('newEmailConfirmation')}
          placeholder={i18n.t('enterNewEmailConfirmation')}
          autoCompleteType="email"
          inputMode="email"
          testID="new-email-confirmation-input"
        />
      </Section.Item>
      <View style={[isTablet && styles.buttonsWrapper, isTabletApp && styles.buttonsWrapperTablet]}>
        <BaseButton
          title={i18n.t('saveChanges')}
          onPress={handleSubmit}
          containerStyle={[isTablet && styles.button, isTabletApp && styles.buttonTablet]}
          loading={isPending}
          testID="save-new-email-btn"
          variant="gradient"
        />
        <SecondaryButton
          containerStyle={!isTablet && !isTabletApp && styles.buttonSecondary}
          title={i18n.t('cancel')}
          disabled={isPending}
          onPress={close}
        />
      </View>
      {modalOpen && (
        <ModalPortal>
          <BaseModal testID="modal-verify-new-email">
            <ConfirmationMessageModal
              close={closeConfirmationModal}
              title={i18n.t('accountInformation:emailChangeConfirmTitle')}
              description={i18n.t('accountInformation:emailChangeConfirmDesc')}
            />
          </BaseModal>
        </ModalPortal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  buttonsWrapper: {
    flexDirection: 'row-reverse',
    marginTop: 32,
  },
  buttonsWrapperTablet: {
    width: '100%',
    flexDirection: 'column',
  },
  button: {
    marginBottom: 0,
    marginLeft: 24,
  },
  buttonSecondary: {
    marginTop: 10,
  },
  buttonTablet: {
    marginBottom: 10,
    marginLeft: 0,
  },
  buttonLast: {
    marginBottom: 0,
  },
});
