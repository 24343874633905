import { useContentItemQuery } from 'src/hooks/queries/contentItems';

import { AlgorithmDetails } from '../types';

export const useAlgorithmItem = (id: string) =>
  useContentItemQuery<AlgorithmDetails>({
    key: ['algorithm', id],
    url: `/contents/algorithm/${id}/`,
    requestConfig: { apiVersion: 2 },
  });
