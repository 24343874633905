import * as React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { palette } from 'src/styles';

export interface DividerProps {
  style?: ViewStyle;
}

export const Divider: React.FC<DividerProps> = ({ style }) => {
  return <View style={[styles.divider, style]} />;
};

const styles = StyleSheet.create({
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: palette.grey2,
    marginVertical: 26,
  },
});
