import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { BaseButton, InputItem } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';

import { PasswordChangeForm } from './PasswordChangeForm';
import { AccountInformationSection as Section } from '../../AccountInformationSection';

export const PasswordChange: React.FC = () => {
  const { isTablet } = useDeviceInfo();
  const [editMode, setEditMode] = useState(false);

  return editMode ? (
    <PasswordChangeForm close={() => setEditMode(false)} />
  ) : (
    <>
      <Section.Item>
        <InputItem
          label={i18n.t('password')}
          readOnly
          placeholder="•••••••"
          testID="current-password-input"
        />
      </Section.Item>
      <BaseButton
        title={i18n.t('accountInformation:changePassword')}
        onPress={() => setEditMode(true)}
        containerStyle={[styles.button, isTablet && styles.buttonTablet]}
        testID="change-pass-btn"
        variant="gradient"
      />
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 10,
  },
  buttonTablet: {
    alignSelf: 'flex-end',
    marginTop: 32,
  },
});
