import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { StyledText, InfoModal, PrimaryButton } from 'src/components';
import { palette, typography } from 'src/styles';

import { TermsAndConditionsCheck } from '../forms/TermsAndConditionsCheck';

interface Props extends React.PropsWithChildren {
  onSubmit?(): void;
  onTermsChange: Dispatch<SetStateAction<boolean>>;
  groupName: string;
  termsAccepted: boolean;
  invitedAsSupportStaff?: boolean;
  isLoading?: boolean;
}

export const GroupInvitationMessage: React.FC<Props> = ({
  children,
  groupName,
  invitedAsSupportStaff,
  isLoading,
  termsAccepted,
  onTermsChange,
  onSubmit,
}) => {
  const { t } = useTranslation('groupInvitation');

  return (
    <InfoModal.Body header={t('modalHeader')} icon="group" iconWidth={40} iconHeight={90}>
      <StyledText style={styles.subheader} testID="modal-subheader">
        {groupName ? t('modalSubheader', { groupName }) : t('modalSubheaderNoGroupName')}
      </StyledText>
      <StyledText style={styles.description} testID="modal-description">
        {t(invitedAsSupportStaff ? 'modalDescriptionStaff' : 'modalDescription')}
      </StyledText>
      {children}
      <View style={styles.termsWrapper}>
        <TermsAndConditionsCheck isChecked={termsAccepted} onChange={onTermsChange} />
      </View>
      <PrimaryButton
        title={t('joinGroup')}
        size="medium"
        onPress={onSubmit}
        loading={isLoading}
        testID="modal-submit-btn"
        disabled={!termsAccepted}
      />
    </InfoModal.Body>
  );
};

const styles = StyleSheet.create({
  subheader: {
    ...typography.body4,
    color: palette.blue,
    textAlign: 'center',
    marginBottom: 24,
  },
  description: {
    ...typography.body2,
    textAlign: 'center',
    marginBottom: 32,
  },
  termsWrapper: {
    alignSelf: 'flex-start',
    marginBottom: 30,
  },
});
