import { Defs, LinearGradient, Stop } from 'react-native-svg';

import { palette } from 'src/styles/palette';

const rotateGradientRelativeToCenter = (degrees: number) => {
  // https://stackoverflow.com/a/40134902
  const radians = degrees * (Math.PI / 180);
  return {
    x1: `${Math.round(50 + Math.sin(radians) * 50)}%`,
    y1: `${Math.round(50 + Math.cos(radians) * 50)}%`,
    x2: `${Math.round(50 + Math.sin(radians + Math.PI) * 50)}%`,
    y2: `${Math.round(50 + Math.cos(radians + Math.PI) * 50)}%`,
  };
};

export const definitions = {
  plumbsGradient: (id: string) => (
    <Defs>
      <LinearGradient id={id} gradientUnits="userSpaceOnUse">
        <Stop stopColor={palette.blue} offset="0" />
        <Stop stopColor={palette.brightBlue} offset="1" />
      </LinearGradient>
    </Defs>
  ),
  proGradient: (id: string) => (
    <Defs>
      <LinearGradient id={id} x1="1" x2="0" y2="1">
        <Stop offset="0%" stopColor={palette.brightBlue} />
        <Stop offset="37%" stopColor={palette.blue6} />
        <Stop offset="100%" stopColor={palette.darkBlue} />
      </LinearGradient>
    </Defs>
  ),
  pvdGradient: (id: string) => (
    <Defs>
      <LinearGradient id={id}>
        <Stop offset="0%" stopColor={palette.navy} />
        <Stop offset="100%" stopColor="#184089" />
      </LinearGradient>
    </Defs>
  ),
  pvdVerticalGradient: (id: string) => (
    <Defs>
      <LinearGradient id={id} gradientTransform="rotate(-90 0.5 0.5)">
        <Stop offset="0%" stopColor={palette.navy} />
        <Stop offset="100%" stopColor="#184089" />
      </LinearGradient>
    </Defs>
  ),
  proSideBarGradient: (id: string) => (
    <Defs>
      <LinearGradient id={id} {...rotateGradientRelativeToCenter(163)}>
        <Stop offset="0%" stopColor={palette.brightBlue} />
        <Stop offset="37.75%" stopColor={palette.blue6} />
        <Stop offset="100%" stopColor={palette.darkBlue} />
      </LinearGradient>
    </Defs>
  ),
  proMobileDrawer: (id: string) => (
    <Defs>
      <LinearGradient id={id} {...rotateGradientRelativeToCenter(197)}>
        <Stop offset="0%" stopColor="#00C7E2" />
        <Stop offset="37%" stopColor="#00A2E2" />
        <Stop offset="100%" stopColor="#1851A8" />
      </LinearGradient>
    </Defs>
  ),
  pvdMobileDrawer: (id: string) => (
    <Defs>
      <LinearGradient id={id} {...rotateGradientRelativeToCenter(7)}>
        <Stop offset="0%" stopColor="#17285E" />
        <Stop offset="100%" stopColor="#184089" />
      </LinearGradient>
    </Defs>
  ),
  brandsGradient: (id: string) => (
    <Defs>
      <LinearGradient id={id}>
        <Stop offset="0%" stopColor="#17285E" />
        <Stop offset="100%" stopColor="#081747" />
      </LinearGradient>
    </Defs>
  ),
  proBottomNavigationTabActive: (id: string) => (
    <Defs>
      <LinearGradient id={id} x1="1" x2="0" y2="1">
        <Stop offset="0%" stopColor={palette.brightBlue} />
        <Stop offset="37%" stopColor={palette.blue6} />
        <Stop offset="100%" stopColor={palette.darkBlue} />
      </LinearGradient>
    </Defs>
  ),
  pvdBottomNavigationTabActive: (id: string) => (
    <Defs>
      <LinearGradient id={id} x1="1" x2="0" y2="1">
        <Stop offset="0%" stopColor={palette.darkBlue} />
        <Stop offset="100%" stopColor={palette.navy} />
      </LinearGradient>
    </Defs>
  ),
};
