import { EnvService } from 'src/services/EnvService';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

class GoogleAnalyticsService {
  isEnabled: boolean;

  constructor() {
    this.isEnabled = !!EnvService.getEnv('GOOGLE_TAG_MANAGER_ID');
  }

  track(eventName: string, data?: Record<string, any>) {
    if (!this.isEnabled) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ecommerce: data,
    });
  }
}

const instance = new GoogleAnalyticsService();

export { instance as GoogleAnalyticsService };
