export const APP_NAME = 'Plumb';
export const MIN_PASSWORD_LENGTH = 8;
export const CONTENT_LIST_ITEMS_PER_PAGE = 10;
export const CONTENT_LIST_ITEMS_PER_PAGE_NATIVE = 20;
export const USER_OTHER_VALUE = 'other';
export const UNDEFINED_FILTER_VALUE = '';
export const LETTER_PARAM = 'letter';
export const AUTOLOGIN_TOKEN_SITES = ['banfield'] as const;
export const DEFAULT_MAX_FONT_SIZE_MULTIPLIER = 1.25;
export const SECTION_WITH_CALCULATOR = 'doses';
export const CANCEL_REQUEST_ACTION_TYPE = 'CANCEL_REQUEST_ACTION_TYPE';
export const MAX_SET_TIMEOUT = 2147483647;
export const TRIAL_DURATION_IN_DAYS = 30;
export const MODAL_WRAPPER_ID = 'modal-wrapper';
export const STUDENT_PLAN_PRICE = '19.95';
export const COUNTRIES_WITH_STATES = ['US', 'CA', 'MX'];
export const EXPAND_ANIMATION_DURATION = 300;
export const NUMBER_OF_RATINGS = 10;
export const HEADING_DATASET_KEY = 'heading-id';

export enum SpecialSectionIDs {
  CLINICAL_CHALLENGES = 'clinical_challenges',
  DRUG_INTERACTIONS = 'drug_interactions_intro',
  CLIENT_INFORMATION = 'client_information',
  CLIENT_EDUCATION_HOME_CARE = 'client_education_home_care',
}
