import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Radio } from './Radio';

interface Props<T extends string> {
  items: {
    title: string;
    value: T;
    testID?: string;
  }[];
  value: T;
  onChange: (value: T) => void;
  direction?: 'row' | 'column';
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

export const RadioGroup = <T extends string>({
  items,
  value,
  onChange,
  direction = 'column',
  style,
  disabled,
}: Props<T>) => (
  <View style={[styles.wrapper, direction === 'row' && styles.wrapperRow, style]}>
    {items.map((item) => (
      <Radio
        title={item.title}
        checked={item.value === value}
        onPress={() => onChange(item.value)}
        style={[styles.item, direction === 'row' && styles.itemRow]}
        key={item.value}
        disabled={disabled}
        testID={item.testID}
      />
    ))}
  </View>
);

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
  },
  wrapperRow: {
    flexDirection: 'row',
  },
  item: {
    marginBottom: 12,
    alignSelf: 'flex-start',
  },
  itemRow: {
    marginBottom: 0,
    marginRight: 12,
  },
});
