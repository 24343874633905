import React from 'react';

import { i18n } from 'src/locale';

import { ErrorBannerTemplate } from './ErrorBannerTemplate';

export const Error500Banner: React.FC = () => {
  return (
    <ErrorBannerTemplate
      header={i18n.t('errors:header500')}
      description={i18n.t('errors:description500')}
      icon="oops"
      iconWidth={157}
      iconHeight={82}
      testID="500-oops-screen"
    />
  );
};
