import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { typography } from 'src/styles';

type ErrorType = 'common' | 'internet-connection' | 'not-found' | 'server-issue';

interface Props {
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  errorType?: ErrorType;
}

const messages: Record<ErrorType, Record<'header' | 'description', string>> = {
  common: {
    header: 'commonErrorHeader',
    description: 'commonErrorMessage',
  },
  'internet-connection': {
    description: 'internetConnectionMessage',
    header: 'internetConnectionHeader',
  },
  'not-found': {
    description: 'notFoundMessageDescription',
    header: 'notFoundMessageHeader',
  },
  'server-issue': {
    header: 'commonErrorHeader',
    description: 'serverIssueMessage',
  },
};

export const ErrorMessage: React.FC<Props> = ({ textStyle, style, errorType = 'common' }) => {
  const { t } = useTranslation('errors');

  return (
    <View style={style}>
      <StyledText style={[styles.header, textStyle]}>{t(messages[errorType].header)}</StyledText>
      <StyledText style={[styles.message, textStyle]}>{t(messages[errorType].description)}</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    ...typography.body3SemiBold,
    marginBottom: 5,
  },
  message: {
    ...typography.body3,
  },
});
