import React, { useRef, useState } from 'react';
import {
  TextInput,
  StyleSheet,
  ScrollView,
  View,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  ViewStyle,
} from 'react-native';

import { DEFAULT_MAX_FONT_SIZE_MULTIPLIER } from 'src/constants/constants';
import { getEmailsFromString, isWeb } from 'src/helpers';
import { ifWeb, palette, typography } from 'src/styles';

import { EmailChip } from './EmailChip';
import { InputBase } from '../InputBase';
import { Pressable } from '../Pressable';

interface Props {
  value?: string[];
  onChange?: (value: string[]) => void;
  error?: string;
  label?: string;
  placeholder?: string;
}

export const EmailsInput: React.FC<Props> = ({ value = [], onChange, label, placeholder, error }) => {
  const [isFocused, setFocus] = useState(false);
  const [rawValue, setRawValue] = useState('');

  const inputRef = useRef<TextInput>(null);

  const handleInputChange = (text: string) => {
    setRawValue(text);
    const lastChar = text[text.length - 1];
    if (lastChar === ',' || lastChar === ' ') {
      setEmailsFromInput();
    }
  };

  const setEmailsFromInput = () => {
    const emails = getEmailsFromString(rawValue);
    const uniqueEmails = [...new Set([...value, ...emails])];
    if (uniqueEmails.length !== value.length) {
      onChange?.(uniqueEmails);
      setRawValue('');
    }
  };

  const handleKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (event.nativeEvent.key === 'Enter') {
      event.preventDefault();
      setEmailsFromInput();
    }
    if (event.nativeEvent.key === 'Tab') {
      setEmailsFromInput();
    }
  };

  const handleEnterPress = () => {
    setEmailsFromInput();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleWrapperPress = () => {
    inputRef.current?.focus();
  };

  const removeEmail = (emailToRemove: string) => {
    onChange?.([...value.filter((email) => email !== emailToRemove)]);
  };

  const getInputWidth = () => {
    return isWeb ? `${rawValue.length + 2}ch` : '100%';
  };

  return (
    <Pressable onPress={handleWrapperPress}>
      <View>
        <InputBase
          focused={isFocused}
          label={label}
          error={error}
          inputContainerStyle={styles.inputContainer}
        >
          <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContent}>
            {value?.map((email) => (
              <EmailChip
                email={email}
                key={email}
                handleRemoveButtonPress={() => removeEmail(email)}
                style={styles.chip}
                testID={`email-chip-${email}`}
              />
            ))}
            <TextInput
              onChangeText={handleInputChange}
              onKeyPress={handleKeyPress}
              value={rawValue}
              onBlur={() => setFocus(false)}
              onFocus={() => setFocus(true)}
              style={[styles.input, typography.body3, { width: getInputWidth() }]}
              ref={inputRef}
              onSubmitEditing={handleEnterPress}
              placeholder={placeholder}
              placeholderTextColor={palette.grey4}
              maxFontSizeMultiplier={DEFAULT_MAX_FONT_SIZE_MULTIPLIER}
              testID="emails-input"
            />
          </ScrollView>
        </InputBase>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    height: 152,
    ...ifWeb({
      cursor: 'text',
    }),
  } as ViewStyle,
  scrollView: {
    height: 152,
    padding: 16,
    paddingBottom: 20,
  },
  scrollViewContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  input: {
    color: palette.grey8,
    minWidth: 150,
    marginTop: 2,
  },
  chip: {
    marginRight: 8,
    marginBottom: 8,
  },
});
