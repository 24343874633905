/*
 * implementation based on existing plugin
 * @see https://github.com/markdown-it/markdown-it-mark
 */

import MarkdownIt from 'markdown-it';
import State from 'markdown-it/lib/rules_inline/state_inline';

export function createMarkdownItPlugin(tag: string, markup: string, closingMarkupOverride?: string) {
  const closingMarkup = closingMarkupOverride ?? markup;

  // Insert each marker as a separate text token, and add it to delimiter list
  function tokenize(state: State, silent: boolean) {
    const startsWithOpenMarkup = state.src.startsWith(markup, state.pos);
    const startsWithCloseMarkup = state.src.startsWith(closingMarkup, state.pos);
    if (silent || !(startsWithOpenMarkup || startsWithCloseMarkup)) {
      return false;
    }

    const scanned = state.scanDelims(state.pos, true);
    let len = scanned.length;

    // requires two markup signs to consider it as a open/close token
    if (len < 2) {
      return false;
    }
    // if found odd number of markups it means that one of them should be treated as a normal text
    if (len % 2) {
      const token = state.push('text', '', 0);
      token.content = markup[0];
      len--;
    }

    for (let i = 0; i < len; i += 2) {
      const token = state.push('text', '', 0);
      token.content = markup[0] + markup[0];

      state.delimiters.push({
        marker: markup.charCodeAt(0),
        length: 0, // disable "rule of 3" length checks meant for emphasis
        jump: i,
        token: state.tokens.length - 1,
        end: -1,
        open: startsWithOpenMarkup,
        close: startsWithCloseMarkup,
      });
    }

    state.pos += scanned.length;

    return true;
  }

  // Walk through delimiter list and replace text tokens with tags
  function postProcess(state: State, delimiters: State['delimiters']) {
    const loneMarkers: number[] = [];
    const max = delimiters.length;

    for (let i = 0; i < max; i++) {
      const startDelimiter = delimiters[i];

      if (startDelimiter.marker !== markup.charCodeAt(0) || startDelimiter.end === -1) {
        continue;
      }

      const endDelimiter = delimiters[startDelimiter.end];

      const openToken = state.tokens[startDelimiter.token];
      openToken.type = `${tag}_open`;
      openToken.tag = tag;
      openToken.nesting = 1;
      openToken.markup = markup;
      openToken.content = '';

      const closeToken = state.tokens[endDelimiter.token];
      closeToken.type = `${tag}_close`;
      closeToken.tag = tag;
      closeToken.nesting = -1;
      closeToken.markup = closingMarkup;
      closeToken.content = '';

      if (
        state.tokens[endDelimiter.token - 1].type === 'text' &&
        state.tokens[endDelimiter.token - 1].content === markup[0]
      ) {
        loneMarkers.push(endDelimiter.token - 1);
      }
    }

    // If a marker sequence has an odd number of characters, it's splitted
    // like this: `~~~~~` -> `~` + `~~` + `~~`, leaving one marker at the
    // start of the sequence.
    //
    // So, we have to move all those markers after subsequent s_close tags.
    //
    while (loneMarkers.length) {
      const loneMakerIndex = Number(loneMarkers.pop());
      let currentTokenIndex = loneMakerIndex + 1;

      while (
        currentTokenIndex < state.tokens.length &&
        state.tokens[currentTokenIndex].type === `${tag}_close`
      ) {
        currentTokenIndex++;
      }

      currentTokenIndex--;

      if (loneMakerIndex !== currentTokenIndex) {
        const token = state.tokens[currentTokenIndex];
        state.tokens[currentTokenIndex] = state.tokens[loneMakerIndex];
        state.tokens[loneMakerIndex] = token;
      }
    }
  }

  return function (md: MarkdownIt) {
    md.inline.ruler.before('emphasis', tag, tokenize);
    md.inline.ruler2.before('emphasis', tag, (state: State) => {
      const tokensMeta = state.tokens_meta || [];
      const max = tokensMeta.length;

      postProcess(state, state.delimiters);

      for (let curr = 0; curr < max; curr++) {
        if (tokensMeta[curr] && tokensMeta[curr]?.delimiters) {
          postProcess(state, tokensMeta[curr]!.delimiters);
        }
      }

      return false;
    });
  };
}
