import { Platform } from 'react-native';

import { isWeb } from 'src/helpers/common';
import { EnvService } from 'src/services/EnvService';

import { SERVER_ISSUE_ERROR_MESSAGE } from './constants';

type PromiseState = 'pending' | 'fulfilled' | 'rejected';

/**
 * Used to avoid firing multiple token refresh requests simultaneously
 */
export const authTokenRefreshLock = {
  _p: Promise.resolve() as Promise<any>,
  state: 'fulfilled' as PromiseState,
  set promise(p) {
    this.state = 'pending';

    this._p = p.then(
      (val) => {
        this.state = 'fulfilled';
        return val;
      },
      (err) => {
        this.state = 'rejected';
        throw err;
      },
    );
  },
  get promise() {
    return this._p;
  },
};

export const getUserAgentForNative = () =>
  `Plumb/${EnvService.getEnv('VERSION_CODE')} ${Platform.OS} ${Platform.Version}`;

export const isServerIssueError = (error: any) =>
  error?.message === SERVER_ISSUE_ERROR_MESSAGE || (!isWeb && error?.message === 'Network Error');
