class EnvService {
  getEnv(name: string): string | undefined {
    return process.env[`REACT_APP_${name}`];
  }
}

declare global {
  interface Window {
    envService?: EnvService;
  }
}

const instance = new EnvService();
if (window.Cypress) {
  window.envService = instance;
}

export { instance as EnvService };
