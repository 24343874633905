import React from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { StyledText } from 'src/components';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { isUserANormalGroupMember, doesUserHaveLimitedProAccess } from 'src/features/auth/helpers';
import { useUserInfo } from 'src/features/auth/hooks';
import type { GroupInfoCurrentMember } from 'src/features/auth/types';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

export const GroupInfoBox: React.FC = () => {
  const { groupInfo } = useUserInfo();

  if (!isUserANormalGroupMember(groupInfo) && !doesUserHaveLimitedProAccess(groupInfo)) return null;
  const { groupName, groupAdminFirstName, groupAdminEmail, groupAdminLastName } =
    groupInfo as GroupInfoCurrentMember;

  const adminName = groupAdminFirstName ? `${groupAdminFirstName} ${groupAdminLastName}` : '';

  const translationComponents = {
    b: <StyledText style={typography.weightBold} />,
    email: (
      <Link to={`mailto:${groupAdminEmail}`} style={styles.link} external newTab={false} wrapper="text" />
    ),
    support: <Link to={SUPPORT_LINK} style={styles.link} external wrapper="text" />,
  };

  return (
    <View style={styles.container} testID="group-info">
      <StyledText style={typography.body2}>
        <Trans
          i18nKey={`accountInformation:${
            groupAdminEmail
              ? adminName
                ? 'groupInfoText'
                : 'groupInfoTextNoAdminName'
              : 'groupInfoTextNoAdmin'
          }`}
          values={{ groupName, adminName, groupAdminEmail }}
          components={translationComponents}
        />
      </StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 30,
  },
  link: {
    color: palette.blue,
  },
});
