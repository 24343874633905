import { client } from 'src/api/client';
import { EmptyResponse } from 'src/constants/types';
import { convertObjectToSnakeCase } from 'src/helpers';

import type { SearchFetchParams, SearchResponse } from './types';

export const search = async (params: SearchFetchParams) => {
  const { signal, ...requestParams } = params;

  const response = await client.request<SearchResponse | EmptyResponse>({
    url: '/search/',
    params: convertObjectToSnakeCase({
      ...requestParams,
      contentTypes: params.contentTypes.join(','),
    }),
    signal,
  });

  return response.data;
};
