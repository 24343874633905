import { forwardRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { MessageComponentProps, hideMessage } from 'react-native-flash-message';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useUserInfo } from 'src/features/auth/hooks';
import { isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { palette, typography } from 'src/styles';

import { IconButton } from './IconButton';
import { MarkdownContent } from './MarkdownContent';

const PADDING_MOBILE = 10;
const PADDING_TABLET = 24;

// Wrapper for notification message.
export const Notification = forwardRef<View, MessageComponentProps>(({ message, icon }, ref) => {
  // @ts-ignore
  const displayIcon = icon?.icon === 'auto';

  const { top: safeAreaTop } = useSafeAreaInsets();
  const { isPro } = useUserInfo();
  const { isTablet } = useDeviceInfo();

  const isLongMessage = message.message.length > 20;

  return (
    <View
      style={[
        styles.wrapper,
        isTablet && styles.wrapperTablet,
        { paddingTop: safeAreaTop },
        message.type === 'danger' && styles.wrapperError,
        message.type === 'success' && [isPro ? styles.wrapperSuccessPro : styles.wrapperSuccess],
      ]}
      ref={ref}
    >
      <View style={[styles.contentContainer, isTablet && styles.contentContainerTablet]}>
        <View style={styles.textWrapper}>
          <MarkdownContent
            style={[styles.text, isLongMessage && styles.textLong]}
            linkStyle={styles.link}
            testID="notification-message"
            inline
          >
            {message.message}
          </MarkdownContent>
        </View>
        {displayIcon && (
          <IconButton
            name="close"
            onPress={hideMessage}
            containerStyle={styles.icon}
            width={isWeb ? 18 : 12}
            color={palette.white}
            onHoverColor={palette.white}
            testID="close-notification-button"
          />
        )}
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  wrapper: {
    minHeight: 50,
  },
  wrapperTablet: {
    minHeight: 120,
  },
  wrapperError: {
    backgroundColor: palette.red,
  },
  wrapperSuccess: {
    backgroundColor: palette.blue,
  },
  wrapperSuccessPro: {
    backgroundColor: palette.navy,
  },
  contentContainer: {
    paddingVertical: PADDING_MOBILE,
    paddingHorizontal: PADDING_MOBILE,
    flexDirection: 'row',
    flex: 1,
  },
  contentContainerTablet: {
    paddingVertical: PADDING_TABLET,
    paddingHorizontal: PADDING_TABLET,
  },
  icon: {
    marginLeft: 10,
  },
  textWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
    color: palette.white,
    maxWidth: 550,
    ...typography.heading3,
  },
  textLong: {
    ...typography.heading2,
  },
  link: {
    color: palette.white,
    textDecorationLine: 'underline',
    ...typography.weightBold,
  },
});
