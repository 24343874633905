import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';

import { palette } from 'src/styles';

type Direction = 'top' | 'down' | 'left' | 'right';

interface Props {
  id: string;
  fadeColor?: string;
  height?: number | string;
  width?: number | string;
  style?: StyleProp<ViewStyle>;
  direction?: Direction;
}

export const FadingBox: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  fadeColor = palette.white,
  height = '20%',
  width = '100%',
  style,
  children,
  direction = 'down',
}) => {
  return (
    <View style={[styles.wrapper, style]}>
      {children}
      <Svg height={height} width={width} style={[StyleSheet.absoluteFill, styles.svg]}>
        <Defs>
          <LinearGradient id={id} {...gradientPropsByDirection[direction]}>
            <Stop offset="0%" stopColor={fadeColor} stopOpacity="0" />
            <Stop offset="100%" stopColor={fadeColor} stopOpacity="1" />
          </LinearGradient>
        </Defs>
        <Rect x="0" y="0" width="100%" height="100%" fill={`url(#${id})`} />
      </Svg>
    </View>
  );
};

const gradientPropsByDirection: Record<Direction, { x1: number; x2: number; y1: number; y2: number }> = {
  left: { x1: 1, x2: 0, y1: 0, y2: 0 },
  right: { x1: 0, x2: 1, y1: 0, y2: 0 },
  down: { x1: 0, x2: 0, y1: 0, y2: 1 },
  top: { x1: 0, x2: 0, y1: 1, y2: 0 },
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  svg: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 'auto',
  },
});
