import React, { ReactNode, RefObject, useEffect, useRef } from 'react';
import { findNodeHandle, StyleSheet, View } from 'react-native';
import Animated, { AnimatedRef, measure, runOnJS, runOnUI, scrollTo } from 'react-native-reanimated';
import { AnimatedScrollView } from 'react-native-reanimated/lib/typescript/component/ScrollView';

import { palette } from 'src/styles';

import { Measurements } from './context/FindOnPageProvider';
import { useFindOnPage } from './hooks/useFindOnPage';

/**
 * Helper function to traverse and process React children.
 * Wraps parts of text matching `searchText` with a highlighted Text component,
 * and logs the measurements of the highlighted child using react-native-reanimated.
 */

const SCROLL_EXTRA_PADDING = 85;
const BOTTOM_OFFSET_THRESHOLD = 300;
interface HighlightedTypes {
  index: number;
  parentRef: AnimatedRef<Animated.View>;
  part: string;
}
export const Highlighted = ({ index, parentRef, part }: HighlightedTypes) => {
  const highlightedRef = useRef<Animated.Text>(null);

  const findOnPage = useFindOnPage(); // Access context to save the ref and measurements

  const saveIt = (measurements: Measurements) => {
    findOnPage?.onSaveTextRef?.({
      measurements,
      parentRef,
      highlightedRef, // Pass the highlightedRef here
    });
  };

  useEffect(() => {
    if (highlightedRef.current) {
      runOnUI(() => {
        const measurements = measure(parentRef); // Measure the highlighted element
        if (measurements) {
          runOnJS(saveIt)(measurements);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedRef, part]);

  return (
    <Animated.Text
      ref={highlightedRef} // Attach the ref to Animated.Text
      key={index}
      style={styles.highlightedText}
    >
      {part}
    </Animated.Text>
  );
};

export function highlightMatchingText(
  children: ReactNode,
  searchText: string | null | undefined,
  parentRef: AnimatedRef<Animated.Text>,
): ReactNode {
  if (!searchText) {
    return children; // If no searchText is provided, return children as is
  }

  const highlightText = (text: string): ReactNode => {
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'));

    return parts.map((part, index) => {
      if (part.toLowerCase() === searchText.toLowerCase()) {
        return <Highlighted key={index} index={index} parentRef={parentRef} part={part} />;
      }
      return part;
    });
  };

  const traverseChildren = (child: ReactNode): ReactNode => {
    if (typeof child === 'string') {
      return highlightText(child);
    }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        children: React.Children.map(child.props.children, traverseChildren),
      });
    }

    if (Array.isArray(child)) {
      return child.map(traverseChildren);
    }

    return child;
  };

  return React.Children.map(children, traverseChildren);
}

export const styles = StyleSheet.create({
  highlightedText: {
    backgroundColor: palette.yellowHighlight,
  },
});

export const onScrollToNative = (
  scrollViewRef: RefObject<View> | AnimatedRef<AnimatedScrollView>,
  scrollOffset: number,
  pageY: number,
) => {
  const scrollToViewRefNow = scrollViewRef as AnimatedRef<AnimatedScrollView>;
  runOnUI(() => {
    'worklet';
    scrollTo(scrollToViewRefNow, 0, pageY - scrollOffset, true);
  })();
};

export const onScrollToWeb = (
  targetNode: HTMLElement,
  scrollViewRef: RefObject<View> | AnimatedRef<AnimatedScrollView>,
  windowHeight: number,
) => {
  const rect = targetNode.getBoundingClientRect();
  const containerNode = scrollViewRef.current
    ? (findNodeHandle(scrollViewRef.current) as unknown as HTMLElement)
    : null;
  const containerRect = containerNode?.getBoundingClientRect();
  const distanceToBottom = containerRect ? containerRect?.bottom - rect.bottom : null;
  // This prevents the TOC from scrolling to the top in some cases when the result is at the bottom fo the scroll view
  const adjustedPosition = window.scrollY + rect.bottom - windowHeight + SCROLL_EXTRA_PADDING;
  const isAtBottomOfContainer = distanceToBottom && distanceToBottom < BOTTOM_OFFSET_THRESHOLD;

  targetNode.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: isAtBottomOfContainer ? 'end' : 'center',
  });
  if (isAtBottomOfContainer) {
    window.scrollTo({
      behavior: 'smooth',
      top: adjustedPosition,
    });
  }
};
