import React from 'react';
import { StyleSheet } from 'react-native';

import { palette } from 'src/styles';

import { BaseButton, BaseButtonProps } from './BaseButton';

const colors = {
  default: { primary: palette.grey1, content: palette.navy },
  active: { primary: palette.grey2, content: palette.navy },
  disabled: { primary: palette.grey1, content: palette.grey2 },
};
export const FlatButton: React.FC<BaseButtonProps> = (props) => (
  <BaseButton
    {...props}
    variant="flat"
    innerContainerStyle={[props.innerContainerStyle, styles.buttonFlat]}
    colors={colors}
    hoverStyle={{
      backgroundColor: colors.active.primary,
    }}
  />
);
export const styles = StyleSheet.create({
  buttonFlat: {
    marginBottom: 0,
    paddingTop: 0,
  },
});
