import { useReducer } from 'react';

import { NullableFormData, RegistrationFormStep, RegistrationStepsData } from './types';

export interface RegistrationFormState<S extends readonly RegistrationFormStep[] = []> {
  data: NullableFormData<S>;
  dirtySteps: S[number][];
  submittedSteps: S[number][];
}

type SubmitStepAction<T extends RegistrationFormStep> = {
  type: 'submit-step';
  step: T;
  data: RegistrationStepsData[T];
  overwrite?: boolean;
};

type SetStepDataAction<T extends RegistrationFormStep> = {
  type: 'set-step-data';
  step: T;
  data: RegistrationStepsData[T];
  overwrite?: boolean;
};

export type RegistrationFormAction<T extends RegistrationFormStep> =
  | SubmitStepAction<T>
  | SetStepDataAction<T>
  | { type: 'set-dirty-step'; step: T; isDirty: boolean };

export function reducer<S extends readonly RegistrationFormStep[]>(
  state: RegistrationFormState<S>,
  action: RegistrationFormAction<S[number]>,
): RegistrationFormState<S> {
  switch (action.type) {
    case 'set-step-data':
    case 'submit-step': {
      const { step, data, overwrite = true, type } = action;

      if (!overwrite && !!state.data[step]) {
        return state;
      }

      let submittedSteps = [...state.submittedSteps];
      if (type === 'submit-step') {
        submittedSteps = Array.from(new Set([...submittedSteps, step]));
      }

      return {
        ...state,
        data: {
          ...state.data,
          [step]: data,
        },
        submittedSteps,
      };
    }
    case 'set-dirty-step': {
      let dirtySteps = [];
      if (action.isDirty) {
        dirtySteps = Array.from(new Set([...state.dirtySteps, action.step]));
      } else {
        dirtySteps = state.dirtySteps.filter((item) => item !== action.step);
      }
      return {
        ...state,
        dirtySteps,
      };
    }
  }
}

/** Reducer to be used in any Registration Form.
 * Keeps track of data submitted by each RegistrationFormStep and if there are any unsaved changes in them. */
export const useRegistrationFormReducer = <S extends readonly RegistrationFormStep[], I = any>(
  initializerArg: I,
  initializer: (arg: I) => RegistrationFormState<S>,
) => {
  return useReducer(reducer<S>, initializerArg, initializer);
};
