import { kebabCase } from 'lodash-es';

export const getHeadingsFromMarkdown = (md: string) => {
  const headingRegex = /^#+\s*(.*)$/gm;
  const headings: string[] = [];
  const matches = md.match(headingRegex);
  matches?.forEach((match) => {
    const heading = match.replace(/^#+\s*/, '').trim();
    if (heading) {
      headings.push(heading);
    }
  });
  return headings;
};

export const createHeadingId = (content: string) => {
  return kebabCase(content);
};
