import React from 'react';
import { StyleSheet } from 'react-native';

import { BaseButton, PrimaryButton, StyledText } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';

import { LoginFlowTemplate } from './LoginFlowTemplate';

interface Props {
  error?: boolean;
  email?: string;
}

export const PasswordRecoveryFinalScreen: React.FC<Props> = ({ error, email }) => {
  const { isTablet } = useDeviceInfo();
  const navigation = useNavigation();

  const content = error ? (
    <>
      <StyledText>{i18n.t('passwordRecovery:linkExpired')}</StyledText>
      <PrimaryButton
        title={i18n.t('passwordRecovery:tryAgain')}
        containerStyle={styles.button}
        onPress={() => navigation.navigate(Route.SignIn, {})}
      />
    </>
  ) : (
    <>
      <StyledText testID="final-screen-password-changed-text">
        {i18n.t('passwordRecovery:passwordChanged')}
      </StyledText>
      <BaseButton
        title={i18n.t('passwordRecovery:backToLoginButton')}
        containerStyle={styles.button}
        fullWidth={!isTablet}
        onPress={() => navigation.navigate(Route.SignIn, { email })}
        testID="final-screen-back-to-login-btn"
        variant="gradient"
      />
    </>
  );

  return <LoginFlowTemplate headerTitle={i18n.t('passwordRecovery:title')}>{content}</LoginFlowTemplate>;
};

const styles = StyleSheet.create({
  button: {
    marginTop: 20,
    alignSelf: 'flex-start',
  },
});
