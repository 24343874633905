import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { BaseModal, PrimaryButton, Icon, SecondaryButton, Select, StyledText } from 'src/components';
import { SelectOption } from 'src/constants/types';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { GroupRole } from 'src/features/auth/enums';
import { useUserInfo } from 'src/features/auth/hooks';
import { i18n } from 'src/locale';
import { getShadow, ifWeb, palette, typography } from 'src/styles';

import { useAdminGroupInfo, useEditGroupRole, useLicencesInfo } from '../../hooks';
import { EditGroupRolePayload, GroupMember, GroupMemberAccessLevel } from '../../types';

interface Props {
  memberId: string;
  close: () => void;
}

type RoleAndAccess = 'support-staff' | 'member' | 'member-basic' | 'member-pro';

export const EditRoleModal: React.FC<Props> = ({ memberId, close }) => {
  const [error, setError] = useState('');
  const { data: licencesData } = useLicencesInfo();
  const { data: group } = useAdminGroupInfo();
  const { mutate: editRole, isPending } = useEditGroupRole(memberId);

  const member = group!.members.find((item) => item.id === memberId) as GroupMember;

  const initialOption = getOptionByMemberData(member, !!licencesData?.isMixedGroup);
  const [selectedOption, setOption] = useState<RoleAndAccess>(initialOption);
  const {
    permissions: { hasSupportStaffFeatureEnabled },
  } = useUserInfo();

  const { t } = useTranslation('adminDashboard');

  const isUserASupportStaff = member.role === GroupRole.SUPPORT_STAFF;

  const selectOptions = (() => {
    const options: (SelectOption<RoleAndAccess> & { isHidden?: boolean })[] = [
      {
        label: t('member'),
        value: 'member',
        isHidden: licencesData?.isMixedGroup,
        isDisabled: licencesData?.isMixedGroup || !licencesData?.licences.available,
      },
      {
        label: t('proGroupMember'),
        value: 'member-pro',
        isHidden: !licencesData?.isMixedGroup,
        isDisabled: !licencesData?.isMixedGroup || !licencesData.licences.pro.available,
      },
      {
        label: t('basicGroupMember'),
        value: 'member-basic',
        isHidden: !licencesData?.isMixedGroup,
        isDisabled: !licencesData?.isMixedGroup || !licencesData.licences.basic.available,
      },
      {
        label: t('supportStaff'),
        value: 'support-staff',
        isHidden: !hasSupportStaffFeatureEnabled,
      },
    ];

    return options.filter((option) => !option.isHidden);
  })();

  const handleSubmit = async () => {
    if (selectedOption === initialOption) {
      return close();
    }
    editRole(getPayloadDataByOption(selectedOption), {
      onSuccess: () => {
        close();
      },
      onError: (error: any) => {
        setError(Object.values(getMessagesFromErrorResponse(error)).join('\n'));
      },
    });
  };

  const additionalInfo =
    isUserASupportStaff && selectedOption === 'support-staff'
      ? null
      : isUserASupportStaff
      ? licencesData?.areLicencesAvailable
        ? t('changeToRegularUserInfo')
        : t('noLicenseAvailableInfo')
      : group?.hasSupportStaffRole && selectedOption === 'support-staff'
      ? t('swapRoleInfo')
      : null;

  const textStyles = [typography.body2, styles.mainInfoText];

  return (
    <BaseModal
      close={close}
      header={t('editUserRole')}
      testID="edit-user-role-modal"
      scrollContainerStyle={styles.modalScrollContainer}
    >
      <View style={styles.wrapper}>
        <StyledText style={textStyles}>{t('editRoleGroupWitSupportStaffInfo')}</StyledText>
        <StyledText style={textStyles}>
          {/* eslint-disable-next-line react-native/no-raw-text */}
          {t('userName')}:{' '}
          <StyledText style={[textStyles, typography.body2Bold]}>{member.fullName}</StyledText>
        </StyledText>
        <View style={styles.selectContainer}>
          <Select
            label={t('userRole')}
            options={selectOptions}
            value={selectedOption}
            onChange={(option: SelectOption<RoleAndAccess>) => setOption(option.value!)}
            error={error}
            touched
            testID="dropdown-user-role"
            dropdownStyle={styles.selectDropdown}
          />
          {additionalInfo && (
            <View style={styles.additionalInfo}>
              <Icon name="info-circle" color={palette.blue} width={18} />
              <StyledText style={styles.additionalInfoText}>{additionalInfo}</StyledText>
            </View>
          )}
        </View>
        <View style={styles.buttons}>
          <SecondaryButton
            title={i18n.t('cancel')}
            onPress={close}
            containerStyle={styles.buttonFirst}
            disabled={isPending}
            testID="modal-cancel-btn"
          />
          <PrimaryButton
            title={i18n.t('save')}
            onPress={handleSubmit}
            loading={isPending}
            disabled={selectedOption === initialOption}
            testID="modal-submit-btn"
          />
        </View>
      </View>
    </BaseModal>
  );
};

const getOptionByMemberData = (member: GroupMember, isMixedGroup: boolean): RoleAndAccess => {
  if (member.accessLevel === GroupMemberAccessLevel.ProLimited) {
    return 'support-staff';
  }
  if (isMixedGroup) {
    return member.accessLevel === GroupMemberAccessLevel.Basic ? 'member-basic' : 'member-pro';
  }
  return 'member';
};

const getPayloadDataByOption = (
  option: RoleAndAccess,
): Pick<EditGroupRolePayload, 'role' | 'accessLevel'> => {
  switch (option) {
    case 'member': {
      return { role: GroupRole.MEMBER };
    }
    case 'member-basic': {
      return { role: GroupRole.MEMBER, accessLevel: GroupMemberAccessLevel.Basic };
    }
    case 'member-pro': {
      return { role: GroupRole.MEMBER, accessLevel: GroupMemberAccessLevel.Pro };
    }
    case 'support-staff': {
      return { role: GroupRole.SUPPORT_STAFF };
    }
  }
};

const styles = StyleSheet.create({
  modalScrollContainer: {
    ...ifWeb({
      overflowX: 'unset',
      overflowY: 'unset',
    }),
  },
  wrapper: {
    paddingHorizontal: 1,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonFirst: {
    marginRight: 16,
  },
  mainInfoText: {
    marginBottom: 16,
  },
  additionalInfo: {
    flexDirection: 'row',
    marginTop: 11,
  },
  additionalInfoText: {
    marginLeft: 10,
  },
  selectContainer: {
    zIndex: 'auto',
    marginBottom: 50,
  },
  selectDropdown: {
    ...getShadow(0, 0, 0),
    borderWidth: 1,
    borderColor: palette.grey2,
  },
});
