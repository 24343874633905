import { Suspense } from 'react';

import { Container, FullScreenTemplate, LoadingIndicator } from 'src/components';
import { Error404Banner } from 'src/errorHandling/components';
import { usePersonalInformation } from 'src/features/profile';
import { StudentVerificationProvider } from 'src/features/studentVerification/context/StudentVerificationContext';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { GroupConversionForm } from '../components/GroupConversionForm';
import { useGroupConversionData } from '../hooks';

type Props = ScreenProps<Route.GroupConversion>;

export const GroupConversionScreen: React.FC<Props> = ({ route }) => {
  const { id } = route.params;
  const { data, isFetching, isError } = useGroupConversionData(id);
  const personalInformation = usePersonalInformation();

  const isLoading = isFetching || personalInformation.isFetching;

  return (
    <FullScreenTemplate isLoading={isLoading} title={i18n.t('groupConversion:header')} displayStandbyText>
      <Container verticalPadding>
        {!isLoading && !isError && data && (
          <Suspense fallback={<LoadingIndicator />}>
            <StudentVerificationProvider>
              <GroupConversionForm planSetup={data} id={id} />
            </StudentVerificationProvider>
          </Suspense>
        )}
        {isError && <Error404Banner />}
      </Container>
    </FullScreenTemplate>
  );
};
