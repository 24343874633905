import * as R from 'ramda';
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { PrimaryButton, InputItem, StyledText, ConfirmModal } from 'src/components';
import { ApiError } from 'src/constants/types';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { useAdminGroupInfo, useInviteGroupMembers } from 'src/features/adminDashboard/hooks';
import { GroupRole } from 'src/features/auth/enums';
import { showNotification } from 'src/helpers';
import { i18n } from 'src/locale';
import { typography } from 'src/styles';

interface Props {
  close: () => void;
}

export const InviteSupportStaff: React.FC<Props> = ({ close }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { data } = useAdminGroupInfo();
  const { mutate: inviteGroupMembers, isPending } = useInviteGroupMembers();

  const submitForm = (confirmSupportStaffInvitation?: boolean) => {
    setError(undefined);
    inviteGroupMembers(
      {
        emails: [email],
        role: GroupRole.SUPPORT_STAFF,
        confirmSupportStaffInvitation,
      },
      {
        onSuccess: () => {
          showNotification({ type: 'success' });
          close();
        },
        onError: (err: any) => {
          const errors = err?.response?.data;
          const status = err?.response?.status;
          if (errors) {
            const nonFieldErrors: ApiError[] | undefined = errors?.nonFieldErrors;
            const emailsErrors: Record<number, ApiError[]> | ApiError[] | undefined = errors?.emails;
            const { role, emails } = R.mapObjIndexed(getErrorMessageForApiError, errors);

            if (nonFieldErrors?.find((error) => error.code === 'invited_support_staff_as_active_user')) {
              setShowConfirmation(true);
            } else if (emails) {
              setError(emails);
            } else if (!emails && emailsErrors && emailsErrors[0]) {
              setError(getErrorMessageForApiError(emailsErrors[0]));
            }
            if (role) {
              showNotification({ type: 'error', title: role });
            }
          } else {
            showNotification({ type: 'error' });
          }
          if (status === 403) {
            // Cannot invite users because group is frozen; closing modal so the frozen group message is visible
            close();
          }
        },
      },
    );
  };

  const closeConfirmation = () => {
    setShowConfirmation(false);
  };

  const submitConfirmation = () => {
    setShowConfirmation(false);
    submitForm(true);
  };

  return (
    <View testID="support-staff-tab">
      {!data?.hasSupportStaffRole ? (
        <>
          <StyledText style={[typography.body2, styles.info]}>
            {i18n.t('adminDashboard:inviteSupportStaffInfo')}
          </StyledText>
          <InputItem
            value={email}
            onChangeText={setEmail}
            label={i18n.t('enterEmailAddress')}
            error={error}
            touched
            onSubmitEditing={() => submitForm()}
            inputMode="email"
            autoCompleteType="email"
            testID="support-staff-email-input"
          />
        </>
      ) : (
        <StyledText style={[typography.body2]}>
          {i18n.t(
            `adminDashboard:${
              data?.supportStaffName ? 'noAvailableSupportStaff' : 'noAvailableSupportStaffUnknownUser'
            }`,
            { username: data?.supportStaffName },
          )}
        </StyledText>
      )}
      <View style={styles.buttonWrapper}>
        <PrimaryButton
          title={i18n.t('adminDashboard:invite')}
          onPress={() => submitForm()}
          loading={isPending}
          disabled={data?.hasSupportStaffRole}
          testID="invite-support-staff-btn"
        />
      </View>
      {showConfirmation && (
        <ConfirmModal
          title={i18n.t('areYouSure')}
          description={i18n.t('groupInvitation:confirmSupportStaffDescription')}
          cancel={closeConfirmation}
          cancelMessage={i18n.t('cancel')}
          confirm={submitConfirmation}
          confirmMessage={i18n.t('adminDashboard:invite')}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  info: {
    marginBottom: 16,
  },
  buttonWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});
