import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { PlumbsLogo } from 'src/assets/images/logos';
import { StyledText, TextButton } from 'src/components';
import { isWeb } from 'src/helpers';
import { Route } from 'src/navigation';
import { palette, typography, ifWeb } from 'src/styles';

import { LoginFlowTemplate } from './LoginFlowTemplate';
import SignInForm from './SignInForm';

interface Props {
  email?: string;
  password?: string;
}

export const RegularLoginTemplate: React.FC<Props> = ({ email, password }) => {
  const { t } = useTranslation('signIn');

  return (
    <LoginFlowTemplate headerTitle={t('signIn')}>
      <View style={styles.wrapper}>
        {!isWeb && (
          <View style={styles.logo}>
            <PlumbsLogo height={30} />
          </View>
        )}
        <SignInForm email={email} password={password} />
        {isWeb && (
          <View style={styles.signUpContainer}>
            <StyledText style={[typography.body2, styles.signUpTip]}>{t('signUpTip')}</StyledText>
            <TextButton to={{ route: Route.SignUp }} testID="subscribe-button">
              {t('subscribe')}
            </TextButton>
          </View>
        )}
      </View>
    </LoginFlowTemplate>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
  },
  logo: {
    marginBottom: 60,
    marginTop: 30,
  },
  signUpContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    borderColor: palette.grey2,
    borderTopWidth: 1,
    marginTop: 28,
    paddingTop: 24,
    ...ifWeb({
      justifyContent: 'flex-start',
    }),
  },
  signUpTip: {
    color: palette.grey8,
    marginRight: 10,
  },
});
