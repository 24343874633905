import { createAction, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

import { WebSocketCloseCode } from 'src/constants/enums';
import { ThunkAction } from 'src/constants/types/store';
import { IntercomService } from 'src/features/intercom/IntercomService';
import { AppcuesService } from 'src/features/tracking/services/AppcuesService';
import { MixpanelService } from 'src/features/tracking/services/MixpanelService';
import { SentryService } from 'src/features/tracking/services/SentryService';
import { getJWTPayload, isJWTValid } from 'src/helpers/functions';
import { WebSocketService } from 'src/services';
import type { ApplicationState } from 'src/state/reducers';
import type { AppDispatch } from 'src/state/store';

import { refreshAccessToken as refreshTokenApi } from '../api';
import type { IPLoginActionPayload, SignInResponse, TokensResponse } from '../types';

export const signOut = (): ThunkAction => async (dispatch) => {
  WebSocketService.close(WebSocketCloseCode.Normal);
  MixpanelService.clearUser();
  AppcuesService.clearUser();
  SentryService.clearUser();
  IntercomService.clearUser();
  dispatch(signOutSuccess());
};

export const signOutSuccess = createAction('auth/signOut');
export const setAuthTokens = createAction<TokensResponse>('auth/setTokens');
export const openInactiveSharedAccountWarning = createAction('auth/openInactiveSharedAccountWarning');
export const closeInactiveSharedAccountWarning = createAction('auth/closeInactiveSharedAccountWarning');
export const openCanadianDisclaimer = createAction('auth/openCanadianDisclaimer');
export const closeCanadianDisclaimer = createAction('auth/closeCanadianDisclaimer');
export const closeInactiveAccountModal = createAction('auth/closeInactiveAccountModal');
export const fulfillSignIn = createAction<SignInResponse>('auth/fulfillSignIn');
export const fulfillIPLogin = createAction<IPLoginActionPayload>('auth/fulfillIPLogin');

export const signInAndSignOutMatcher = isAnyOf(signOutSuccess);

const refreshAccessToken = createAsyncThunk<
  TokensResponse,
  string,
  {
    dispatch: AppDispatch;
    state: ApplicationState;
  }
>('auth/refreshToken', async (token, { dispatch }) => {
  try {
    const { access, refresh } = await refreshTokenApi(token);
    const payload = getJWTPayload(access);

    if (!payload) {
      dispatch(signOut());
      throw null;
    }

    return { access, refresh };
  } catch (err: any) {
    if (err?.response?.data?.expired) {
      dispatch(openInactiveSharedAccountWarning());
    }
    throw null;
  }
});

export const handleInvalidAccessToken =
  (refreshToken: string): ThunkAction =>
  async (dispatch) => {
    if (isJWTValid(refreshToken)) {
      return dispatch(refreshAccessToken(refreshToken));
    }
    dispatch(signOut());
    return false;
  };

export const verifyAuthTokens = (): ThunkAction<any> => async (dispatch, getState) => {
  const {
    auth: {
      tokens: { access: accessToken, refresh: refreshToken },
    },
  } = getState();
  if (isJWTValid(accessToken)) {
    return true;
  }
  return dispatch(handleInvalidAccessToken(refreshToken));
};

export { refreshAccessToken };
