import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { BaseModal, PrimaryButton, Pressable, StyledText, TextArea } from 'src/components';
import { NUMBER_OF_RATINGS } from 'src/constants/constants';
import type { ApiErrorMessages } from 'src/constants/types';
import { ModalPortal } from 'src/contexts/portals';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { showNotification } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { getShadow, palette, typography } from 'src/styles';

import { useUserNotificationAction } from '../hooks';
import { NPSNotification, NPSNotificationActionDetails } from '../types';

interface Props {
  data: NPSNotification;
}

interface Errors extends ApiErrorMessages {
  feedback?: string;
}

export const NPSModal: React.FC<Props> = ({ data }) => {
  const [rating, setRating] = useState<number | null>(null);
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState('');

  const { t } = useTranslation('surveys');
  const { mutate: closeUserNotification } = useUserNotificationAction(data.id, 'close');
  const { mutate: submit, isPending } = useUserNotificationAction(data.id, 'submit');
  const { isTablet } = useDeviceInfo();

  const handleSubmit = () => {
    setError('');
    const data: NPSNotificationActionDetails = {
      rate: rating,
      feedback: feedback || undefined,
    };
    submit(data, {
      onSuccess: () => {
        MixpanelService.track(MixpanelEvent.NPSComplete, {
          'NPS Rate': rating,
          'NPS Feedback': feedback,
        });
      },
      onError: (error: any) => {
        const { feedback, detail } = getMessagesFromErrorResponse<Errors>(error, 'details');
        if (feedback) {
          setError(feedback);
        } else {
          showNotification({ type: 'error', title: detail });
          closeUserNotification();
        }
      },
    });
  };

  const handleClose = () => {
    MixpanelService.track(MixpanelEvent.NPSClose);
    closeUserNotification();
  };

  return (
    <ModalPortal>
      <BaseModal style={styles.modal} close={handleClose} scroll={false} padded={false}>
        <View style={styles.wrapper}>
          <StyledText style={styles.header}>{t('npsHeader')}</StyledText>
          <View style={styles.rateHelperWrapper}>
            <StyledText style={styles.rateHelper}>{t('npsNegative')}</StyledText>
            <StyledText style={styles.rateHelper}>{t('npsPositive')}</StyledText>
          </View>
          <View style={styles.scoreWrapper}>
            {Array.from(Array(NUMBER_OF_RATINGS)).map((_v, index) => (
              <Pressable onPress={() => setRating(index + 1)} key={index}>
                <StyledText
                  style={[
                    styles.number,
                    index === NUMBER_OF_RATINGS - 1 && styles.lastNumber,
                    index + 1 === rating && styles.selectedNumber,
                  ]}
                >
                  {index + 1}
                </StyledText>
              </Pressable>
            ))}
          </View>
          <View style={styles.textAreaWrapper}>
            <TextArea
              value={feedback}
              onChangeText={setFeedback}
              placeholder={t('npsFeedback')}
              height={150}
              error={error}
            />
          </View>
          <PrimaryButton
            title={t('common:submit')}
            onPress={handleSubmit}
            disabled={rating === null}
            loading={isPending}
            containerStyle={[styles.button, isTablet && styles.buttonTablet]}
            size="medium"
          />
        </View>
      </BaseModal>
    </ModalPortal>
  );
};

const styles = StyleSheet.create({
  modal: {
    maxWidth: 520,
  },
  wrapper: {
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 20,
  },
  header: {
    ...typography.body5Bold,
    color: palette.navy,
    marginBottom: 24,
    textAlign: 'center',
    paddingHorizontal: 20,
    padding: 12,
  },
  rateHelperWrapper: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  rateHelper: {
    color: palette.grey5,
  },
  scoreWrapper: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 30,
    marginTop: 10,
    borderRadius: 5,
    overflow: 'hidden',
    ...getShadow(0, 0.7, 20),
  },
  number: {
    width: '10%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightWidth: 1,
    borderColor: palette.grey2,
    ...typography.body4Bold,
  },
  lastNumber: {
    borderRightWidth: 0,
  },
  selectedNumber: {
    color: palette.white,
    backgroundColor: palette.blue,
  },
  textAreaWrapper: {
    marginBottom: 32,
    alignSelf: 'stretch',
  },
  button: {
    width: '100%',
  },
  buttonTablet: {
    width: 280,
  },
});
