export enum RecentlyViewedFilter {
  Drug = 'monograph',
  Appendix = 'appendix',
  Guide = 'guide',
  Algorithm = 'algorithm',
  DxTx = 'clinical-brief',
  All = '',
  DDx = 'differential-diagnosis',
}

export enum ViewedSortOrder {
  TopViewed = 'count',
  RecentlyViewed = 'viewed_at',
}
