import { ContentType } from '../constants';
import { ContentTypeSimplified } from '../types';
import { Config, UserData } from './types';

export class ContentConfig<C extends ContentType = ContentType> {
  readonly contentTypes: readonly C[];
  readonly contentTypeSimplified: ContentTypeSimplified;
  readonly isPro: boolean;
  readonly hasNotes: boolean;
  readonly routes: Config<C>['routes'];
  private accessConfig: Config<C>['additionalAccessConfig'];

  constructor(config: Config<C>) {
    this.isPro = config.isPro;
    this.contentTypeSimplified = config.contentTypeSimplified;
    this.contentTypes = config.contentTypes;
    this.hasNotes = config.hasNotes;
    this.routes = config.routes;
    this.accessConfig = config.additionalAccessConfig;
  }

  includesType(type: ContentType | ContentTypeSimplified) {
    return this.contentTypeSimplified === type || this.contentTypes.includes(type as C);
  }

  /**
   * Checks if user has full access to this content type based on user's plan type and feature flags.
   * Doesn't check user if user's subscription is valid and active.
   */
  canBeAccessed(userData: UserData) {
    const availableByPlan = !this.isPro || userData.isProUser;
    const availableByLimitedPlan =
      this.accessConfig?.enabledForLimitedProUsers || !userData.isLimitedProUser;
    const availableByFlag =
      this.accessConfig?.featureFlag && userData.hasAccessToFlaggedFeature(this.accessConfig.featureFlag);

    return (availableByPlan && availableByLimitedPlan) || !!availableByFlag;
  }

  /**
   * Checks if user can display list of this content type based on user plan type and feature flags.
   * Doesn't check user if user's subscription is valid and active.
   * This check doesn't indicate if user can actually open the content page with all details.
   */
  canBeDisplayed(userData: UserData) {
    const availableByLimitedPlan =
      this.accessConfig?.enabledForLimitedProUsers || !userData.isLimitedProUser;
    const availableBySharedAccountType = !this.isPro || !userData.isBasicSharedAccount;

    const canBeFullyAccessed = this.canBeAccessed(userData);

    return (availableByLimitedPlan && availableBySharedAccountType) || canBeFullyAccessed;
  }
}

export function createContentsObject(contents: readonly ContentConfig[]) {
  return Object.freeze({
    getContentConfig: (type: ContentType | ContentTypeSimplified) => {
      const content = contents.find((c) => c.includesType(type));
      if (!content) {
        throw new Error(`Couldn't match any content with ${type} type`);
      }
      return content;
    },
    getSimplifiedType: (contentType: ContentType | ContentTypeSimplified) => {
      const content = contents.find((c) => c.includesType(contentType));
      if (!content) {
        throw new Error(`Couldn't match any content with ${contentType} type`);
      }
      return content.contentTypeSimplified;
    },
  });
}
