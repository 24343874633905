import { mapValues } from 'lodash-es';

import { client } from 'src/api/client';
import { convertKeysToCamelCase } from 'src/helpers';

import { getNotesApiUrl } from './helpers';
import {
  ContentNote,
  ContentNotes,
  GetContentNotesResponse,
  GroupedNotesListResponse,
  MutateNoteParams,
  Note,
} from './types';

export const getContentNotes = async (contentId: string) => {
  const resp = await client.request<GetContentNotesResponse>({
    url: getNotesApiUrl({ contentId }),
  });

  return mapValues(resp.data, convertKeysToCamelCase) as ContentNotes;
};

export const getGroupedNotesList = async () => {
  const response = await client.request<GroupedNotesListResponse>({
    method: 'GET',
    url: getNotesApiUrl(),
    apiVersion: 2,
  });

  return response.data;
};

export const getSingleNote = async (id: string) => {
  const response = await client.request<Note | ContentNote>({
    url: getNotesApiUrl({ noteId: id }),
  });

  return response.data;
};

export const saveNote = async ({ note }: MutateNoteParams) => {
  const isEditing = 'id' in note;
  const isContentNote = 'contentId' in note;

  const payload = isContentNote
    ? {
        note: note.note,
        section: note.section,
      }
    : {
        note: note.note,
        title: note.title,
      };

  const response = await client.request<Note | ContentNote>({
    method: isEditing ? 'PATCH' : 'POST',
    url: getNotesApiUrl({
      contentId: isContentNote && note.contentId,
      noteId: isEditing && note.id,
    }),
    data: payload,
  });

  return response.data;
};

export const removeNote = async (note: Note | ContentNote) => {
  const isContentNote = 'contentId' in note;

  await client.request({
    method: 'DELETE',
    url: getNotesApiUrl({
      contentId: isContentNote && note.contentId,
      noteId: note.id,
    }),
  });
};
