import React from 'react';
import { StyleSheet, View } from 'react-native';

import { MarkdownContent } from 'src/components/MarkdownContent/MarkdownContent';
import { convertToMarkdown } from 'src/helpers';
import { i18n } from 'src/locale';
import { typography } from 'src/styles/typography';

interface Props {
  commercialNames: string[] | undefined;
  drugClass: string[] | undefined;
}

export const DrugHeaderDescription = React.memo<Props>(({ commercialNames, drugClass }) => {
  const textStyle = typography.body3;

  const hasCommercialNames = !!commercialNames?.length;
  const hasDrugClass = !!drugClass?.length;

  return (
    <View style={(hasCommercialNames || hasDrugClass) && styles.descriptionWrapper}>
      {hasCommercialNames && (
        <View style={styles.descriptionTop}>
          <MarkdownContent
            style={textStyle}
            inline
            limited
            testID="monograph-card-tradename"
            headerLevel={3}
          >
            {`${convertToMarkdown(i18n.t('drugs:tradeName'), 'bold')}: ` + commercialNames?.join(', ')}
          </MarkdownContent>
        </View>
      )}
      {hasDrugClass && (
        <View style={styles.descriptionLast}>
          <MarkdownContent
            style={textStyle}
            inline
            limited
            testID="monograph-card-drug-class"
            headerLevel={3}
          >
            {`${convertToMarkdown(i18n.t('drugs:drugClass'), 'bold')}: ` + drugClass?.join(', ')}
          </MarkdownContent>
        </View>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  cardNative: {
    paddingBottom: 0,
  },
  descriptionWrapper: {
    marginTop: 18,
  },
  descriptionTop: {
    marginBottom: 2,
  },
  descriptionLast: {
    marginBottom: 8,
  },
});
