import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { Icon, StyledText } from 'src/components';
import { i18n } from 'src/locale';
import { palette } from 'src/styles';

import { useFindOnPage } from '../hooks/useFindOnPage';

export const FindOnPageResult: React.FC = () => {
  const findOnPage = useFindOnPage();
  if (!findOnPage) return null;

  const { totalFound, highlightIndex, onNextHighlight, onPrevHighlight } = findOnPage;
  const currentResult = totalFound > 0 ? (highlightIndex % totalFound) + 1 : 0;
  const arrowsDisabled = totalFound <= 1;
  const iconColor = arrowsDisabled ? palette.grey5 : palette.navy;

  return (
    <View testID={totalFound >= 1 ? 'fop-results-found' : 'fop-results-not-found'} style={styles.container}>
      <View style={styles.textContainer}>
        <StyledText testID="fop-results">
          {i18n.t('findOnPage:results', { currentResult, totalFound })}
        </StyledText>
      </View>
      <View style={styles.iconContainer}>
        <TouchableOpacity
          disabled={arrowsDisabled}
          onPress={onPrevHighlight}
          style={styles.iconButton}
          testID="fop-prev-button"
        >
          <Icon name="chevron-up" color={iconColor} width={10} />
        </TouchableOpacity>
        <TouchableOpacity
          disabled={arrowsDisabled}
          onPress={onNextHighlight}
          style={styles.iconButton}
          testID="fop-next-button"
        >
          <Icon name="chevron-down" color={iconColor} width={10} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainer: {
    flex: 1,
  },
  iconContainer: {
    flexDirection: 'row',
    marginLeft: 10,
  },
  iconButton: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 2,
  },
});
