import { useCallback, useEffect, useRef } from 'react';
import { View } from 'react-native';

export type Key =
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'Enter'
  | 'Delete'
  | 'Escape'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '.'
  | ','
  | '/'
  | '*'
  | '-'
  | '+'
  | '%'
  | '='
  | ' ';

type Falsy = false | undefined | null;

type Params = Falsy | Partial<Record<Key, (key: Key, ev: KeyboardEvent) => void>>;

export const useKeyboardPress = (params?: Params, element: HTMLElement | View | null = document.body) => {
  const paramsRef = useRef<Params>();

  useEffect(() => {
    paramsRef.current = params;
  });

  const handleKeyDown = useCallback((ev: KeyboardEvent) => {
    const params = paramsRef.current;
    if (!params) return;

    const { key } = ev as KeyboardEvent & { key: Key };
    params[key]?.(key, ev);
  }, []);

  useEffect(() => {
    if (!element) return;
    const elem = element as unknown as HTMLElement;

    elem.addEventListener('keydown', handleKeyDown);

    return () => {
      elem.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, element]);
};
