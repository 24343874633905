import { View, StyleSheet } from 'react-native';
import { unstable_createElement, WrappedComponent } from 'react-native-web';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import { StyledText, Pressable } from 'src/components';
import { isRenderProp } from 'src/helpers/typeGuards';
import { NavigationParams, LinkedRoute } from 'src/navigation/types';
import { routeToPath } from 'src/navigation/utils';
import { ifWeb } from 'src/styles';

import { Props } from './Link';

const WrappedRouterLink: WrappedComponent<LinkProps> = (props) => unstable_createElement(RouterLink, props);

export function Link<R extends LinkedRoute, P extends NavigationParams[R]>({
  disabled,
  onPress,
  testID,
  wrapperStyle,
  to,
  external,
  style,
  newTab = external,
  hoverStyles,
  children,
  wrapper = 'view',
  onHover,
  onHoverOut,
  accessibilityLabel,
}: Props<R, P>): React.JSX.Element | null {
  if (!children) return null;

  const getChildren = (hovered: boolean, pressed: boolean, accessibilityLabelChildren?: any) =>
    isRenderProp(children) ? children(hovered, pressed, accessibilityLabelChildren) : children;

  const target = newTab ? '_blank' : undefined;
  const href = typeof to === 'string' ? to : routeToPath(to.route, to.params);

  const Wrapper = wrapper === 'view' ? View : StyledText;

  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      testID={testID}
      onHover={onHover}
      onHoverOut={onHoverOut}
      focusable={false}
      accessibilityLabel={accessibilityLabel}
    >
      {(hovered, pressed) => (
        <Wrapper style={wrapperStyle}>
          {!disabled ? (
            !external ? (
              <WrappedRouterLink
                style={[styles.link, style, hovered && hoverStyles]}
                to={href}
                target={target}
                aria-label={accessibilityLabel}
              >
                {getChildren(hovered, pressed, accessibilityLabel)}
              </WrappedRouterLink>
            ) : (
              <Wrapper style={[style, hovered && hoverStyles]} href={href} hrefAttrs={{ target }}>
                {getChildren(hovered, pressed, accessibilityLabel)}
              </Wrapper>
            )
          ) : (
            <View style={[style, hovered && hoverStyles]}>
              {getChildren(hovered, pressed, accessibilityLabel)}
            </View>
          )}
        </Wrapper>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'none',
    borderStyle: 'solid',
    borderWidth: 0,
    fontSize: 14,
    fontFamily: 'System',
    ...ifWeb({
      boxSizing: 'border-box',
    }),
  },
});
