import React, { useEffect, useMemo, useState } from 'react';

import { BaseSelect } from 'src/components/Select/BaseSelect';
import { SelectOption, SelectOptions } from 'src/constants/types';
import { isSelectOptionsGroup } from 'src/helpers';

interface Props extends Omit<React.ComponentProps<typeof BaseSelect>, 'inputValue' | 'setInputValue'> {
  onInputChange?(value: string): void;
}

export const Select: React.FC<Props> = ({ options = [], onInputChange, ...props }: Props) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    onInputChange?.(inputValue);
  }, [onInputChange, inputValue]);

  const filteredOptions = useMemo(() => {
    const filtered: SelectOptions = [];
    const value = inputValue.toLowerCase();
    if (!value || props.searchType !== 'internal') {
      return options;
    }

    const filterOption = (option: SelectOption) =>
      option.label.toLocaleLowerCase().includes(value) || option.value?.toLowerCase().includes(value);

    options.forEach((option) => {
      if (isSelectOptionsGroup(option)) {
        const _option = { ...option };
        _option.options = _option.options.filter(filterOption);
        filtered.push(_option);
      } else if (filterOption(option)) {
        filtered.push(option);
      }
    });
    return filtered;
  }, [options, inputValue, props.searchType]);

  return (
    <BaseSelect
      {...props}
      options={filteredOptions}
      inputValue={inputValue}
      setInputValue={setInputValue}
    />
  );
};
