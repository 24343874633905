import { type ClinicalHandoutConfig, type DrugHandoutConfig } from 'src/features/content/config';
import { ContentType } from 'src/features/content/constants';
import type {
  ContentItemBase,
  ContentItemSource,
  ContentItemWithSectionsDetailsBase,
  ContentWithSectionsItemParams,
} from 'src/features/content/types';

export type ClinicalHandoutContentType = ClinicalHandoutConfig['contentTypes'][number];
export type DrugHandoutContentType = DrugHandoutConfig['contentTypes'][number];

export enum HandoutsLanguageType {
  English = 'english',
  Spanish = 'spanish',
  SpanishEnglish = 'spanish-english',
}
interface ClinicalHandoutBase {
  alsoCalled: string[];
  pdfUrl: string;
  hasSpanishVersion: boolean;
  language: HandoutsLanguageType;
}

export interface ClinicalHandout extends ContentItemBase, ClinicalHandoutBase {
  contentType: ClinicalHandoutContentType;
}

export interface ClinicalHandoutDetails extends ContentItemWithSectionsDetailsBase, ClinicalHandoutBase {
  contentType: ClinicalHandoutContentType;
  intro?: string;
}

interface DrugHandoutBase {
  pronunciation: string;
  description: string;
  hasSpanishVersion: boolean;
  otherNamesForThisMedication?: string;
  otherCommonNames?: string;
  pdfUrl: string | null;
  commonDosageForms?: string;
}

export interface DrugHandout extends ContentItemBase, DrugHandoutBase {
  contentType: DrugHandoutContentType;
}

export interface DrugHandoutDetails extends ContentItemWithSectionsDetailsBase, DrugHandoutBase {
  contentType: DrugHandoutContentType;
  intro?: string;
}

export type Handout = DrugHandout | ClinicalHandout;

export interface HandoutToPrintOrEmail {
  id: string;
  title: string;
  contentType: ContentType;
  language: HandoutsLanguageType;
}

export interface EmailHandoutsParams {
  emailsTo: string;
  emailsCc: string;
  emailsBcc: string;
  emailContent: string;
  additionalNotes: string;
  guideIds: string[];
  subject: string;
  language: HandoutsLanguageType;
}

export interface GenerateHandoutsPDFParams {
  additionalNotes: string;
  guideIds: string[];
  language: HandoutsLanguageType;
}

export type GenerateHandoutsPDFResponse = string;

export type HandoutItemSource = ContentItemSource | 'drug-page' | 'dx-tx-page';

export interface PrintableHandoutsListParams {
  items: Handout[];
}

export interface EmailHandoutsFormValues {
  to?: string;
  confirmTo?: string;
  cc?: string;
  confirmCC?: string;
  bcc?: string;
  confirmBCC?: string;
  emailNote: string;
  subject: string;
  ccCheckbox: boolean;
  bccCheckbox: boolean;
}

export type HandoutItemParams = Omit<ContentWithSectionsItemParams, 'source'> & {
  source?: HandoutItemSource;
};

export interface ShareHandoutScreenParams {
  handout: string;
}

export interface ShareHandoutsFormProps {
  singleHandoutData?: Handout;
  onShareSuccess?(): void;
  LanguageSelector: React.ReactElement;
  language: HandoutsLanguageType;
}

export interface SelectedHandout
  extends Pick<Handout, 'id' | 'title' | 'contentType' | 'hasSpanishVersion'> {}
