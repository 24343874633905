import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsSignedIn } from 'src/features/auth/hooks';
import {
  refreshAccessToken,
  CANADA_CODE,
  openCanadianDisclaimer,
  setAuthTokens,
} from 'src/features/auth/state';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { isJWTValid, showNotification } from 'src/helpers';
import { ApplicationState } from 'src/state';
import { AppDispatch } from 'src/state/store';

import {
  fetchOccupationDetails,
  editOccupationDetails,
  fetchPersonalInformation,
  editPersonalInformation,
  changeEmail,
  validateEmailChangeToken,
  changePassword,
} from '../api';
import { profileQueryKeys } from '../constants';
import {
  parseOccupationDetailsForBackend,
  parseOccupationDetailsFromBackend,
  parsePersonalInformationForBackend,
  parsePersonalInformationFromBackend,
} from '../helpers';
import { EmailChangeValidationParams, NullablePersonalInformation, OccupationDetails } from '../types';

export const useOccupationDetails = () => {
  return useQuery({
    queryKey: profileQueryKeys.occupationDetails,
    queryFn: async () => {
      const data = await fetchOccupationDetails();
      return parseOccupationDetailsFromBackend(data);
    },
  });
};

export const useUpdateOccupationDetails = () => {
  const queryClient = useQueryClient();
  const { data: currentData } = useOccupationDetails();

  return useMutation({
    mutationFn: async (data: OccupationDetails) => {
      const parsedData = parseOccupationDetailsForBackend(data);
      const newData = await editOccupationDetails(parsedData);
      return parseOccupationDetailsFromBackend(newData);
    },
    onSuccess: (data) => {
      const dataAdded = !currentData || Object.values(currentData).every((value) => !value);
      MixpanelService.track(dataAdded ? MixpanelEvent.UserDataAdded : MixpanelEvent.UserDataEdited);
      showNotification({ type: 'success' });

      queryClient.setQueryData(profileQueryKeys.occupationDetails, data);
    },
    onError: () => {
      showNotification({ type: 'error' });
    },
  });
};

export const usePersonalInformation = (enabled = true) => {
  const isSignedIn = useIsSignedIn();

  return useQuery({
    queryKey: profileQueryKeys.personalInformation,
    queryFn: async () => {
      const data = await fetchPersonalInformation();
      const parsedData = parsePersonalInformationFromBackend(data);

      return parsedData;
    },
    enabled: isSignedIn && enabled,
  });
};

export const useUpdatePersonalInformation = () => {
  const queryClient = useQueryClient();
  const { data: currentData } = usePersonalInformation();
  const dispatch = useDispatch<AppDispatch>();

  return useMutation({
    mutationFn: async (data: NullablePersonalInformation) => {
      const parsedData = parsePersonalInformationForBackend(data);
      const newData = await editPersonalInformation(parsedData);
      return parsePersonalInformationFromBackend(newData);
    },

    onSuccess: (data) => {
      const dataAdded = !currentData || Object.values(currentData).every((value) => !value);
      MixpanelService.track(dataAdded ? MixpanelEvent.UserDataAdded : MixpanelEvent.UserDataEdited);

      showNotification({ type: 'success' });
      if (currentData?.country !== data.country && data.country === CANADA_CODE) {
        dispatch(openCanadianDisclaimer());
      }

      queryClient.setQueryData(profileQueryKeys.personalInformation, data);
    },
    onError: () => {
      showNotification({ type: 'error' });
    },
  });
};

export const useChangeEmail = () => {
  return useMutation({ mutationFn: changeEmail });
};

export const useEmailChangeTokenValidation = (params: EmailChangeValidationParams) => {
  const { refresh: refreshToken } = useSelector((state: ApplicationState) => state.auth.tokens);
  const dispatch = useDispatch<AppDispatch>();

  const queryResult = useQuery({
    queryKey: ['email-change-validation', ...Object.values(params)],
    queryFn: async () => {
      await validateEmailChangeToken(params);
      return true;
    },
  });

  useEffect(() => {
    if (queryResult.isSuccess && refreshToken && isJWTValid(refreshToken)) {
      dispatch(refreshAccessToken(refreshToken));
    }
  }, [dispatch, queryResult.isSuccess, refreshToken]);

  return queryResult;
};

export const useChangePassword = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useMutation({
    mutationFn: changePassword,
    onSuccess: (data) => {
      dispatch(setAuthTokens(data));
    },
  });
};
