import * as R from 'ramda';
import { useCallback } from 'react';

import { useIsSignedIn } from 'src/features/auth/hooks/useIsSignedIn';
import { useUserInfo } from 'src/features/auth/hooks/useUserInfo';
import { screensAccess } from 'src/navigation/config/screensAccess';
import { Route } from 'src/navigation/routes';
import { ScreenAccess } from 'src/navigation/types';

export const useScreenAccess = () => {
  const isSignedIn = useIsSignedIn();
  const { expiredSubscription, permissions } = useUserInfo();

  const isScreenAccessibleBySubscriptionStatus = useCallback(
    (access?: ScreenAccess) =>
      !access?.subscriptionStatus
        ? true
        : !expiredSubscription
        ? access.subscriptionStatus.includes('valid')
        : false,
    [expiredSubscription],
  );

  const isScreenAccessibleBySignInState = useCallback(
    (access?: ScreenAccess) => {
      return !access?.auth || access.auth.includes(isSignedIn ? 'signed-in' : 'signed-out');
    },
    [isSignedIn],
  );

  const isScreenAccessibleByPermissions = useCallback(
    (access?: ScreenAccess): boolean =>
      !access?.permission || [access.permission].flat().every((permission) => permissions[permission]),
    [permissions],
  );

  const isScreenAccessible = useCallback(
    (route: Route) => {
      const screenConfig = screensAccess[route];
      return R.allPass([
        isScreenAccessibleBySignInState,
        isScreenAccessibleByPermissions,
        isScreenAccessibleBySubscriptionStatus,
      ])(screenConfig);
    },
    [
      isScreenAccessibleBySubscriptionStatus,
      isScreenAccessibleBySignInState,
      isScreenAccessibleByPermissions,
    ],
  );

  return {
    isScreenAccessible,
    isScreenAccessibleBySubscriptionStatus,
    isScreenAccessibleBySignInState,
    isScreenAccessibleByPermissions,
  };
};
