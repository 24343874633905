import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { IconButton, IconTextButton, LoadingIndicator } from 'src/components';
import { IconName } from 'src/constants/types';
import { ModalPortal } from 'src/contexts/portals';
import { GroupRole } from 'src/features/auth/enums';
import { usePasswordResetEmail, useUserInfo } from 'src/features/auth/hooks';
import { showNotification } from 'src/helpers';
import { palette, typography } from 'src/styles';

import { CancelInvitationModal } from './CancelInvitationModal';
import { EditRoleModal } from './EditRoleModal';
import { RemoveFromGroupModal } from './RemoveFromGroupModal';
import { useLicencesInfo, useResendGroupInvitation } from '../../hooks';
import { GroupMember, GroupMemberStatus } from '../../types';

interface Props {
  member: GroupMember;
  inTable?: boolean;
}

interface ButtonProps {
  onPress(): void;
  isLoading?: boolean;
  text: string;
  testID?: string;
  iconName: IconName;
  iconWidth?: number;
  iconSizeInTable?: number;
  inTable?: boolean;
  disabled?: boolean;
}

export const GroupMemberActions: React.FC<Props> = ({ member, inTable }) => {
  const [cancelInvitationModal, setCancelInvitationModal] = useState(false);
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [removeFromGroupModal, setRemoveFromGroupModal] = useState(false);

  const { data: licencesData } = useLicencesInfo();
  const { mutate: resendInvitation, isPending: isResendInvitationLoading } = useResendGroupInvitation(
    member.id,
  );
  const resetPasswordMutation = usePasswordResetEmail();

  const { t } = useTranslation('adminDashboard');

  const {
    permissions: { hasSupportStaffFeatureEnabled },
  } = useUserInfo();

  const isMemberRoleEditButtonDisplayed = (() => {
    const { role, status } = member;

    if (role === GroupRole.ADMIN || status !== GroupMemberStatus.Confirmed) return false;
    return licencesData?.isMixedGroup || hasSupportStaffFeatureEnabled;
  })();

  const isMemberRoleEditEnabled = (() => {
    const { role, status } = member;

    if (role === GroupRole.ADMIN || status !== GroupMemberStatus.Confirmed) return false;
    if (role === GroupRole.SUPPORT_STAFF) {
      return !!licencesData?.areLicencesAvailable;
    }

    const isPro = !!licencesData?.isMixedGroup && role === GroupRole.MEMBER;
    return (
      hasSupportStaffFeatureEnabled ||
      (licencesData?.isMixedGroup
        ? isPro
          ? !!licencesData.licences.basic.available
          : !!licencesData?.licences.pro.available
        : false)
    );
  })();

  const handlePasswordResetPress = () => {
    resetPasswordMutation.mutate(member.email, {
      onSuccess: () => {
        showNotification({ type: 'success' });
      },
      onError: () => {
        showNotification({ type: 'error' });
      },
    });
  };

  if (member.role === GroupRole.ADMIN) {
    return null;
  }

  return (
    <>
      {member.status !== GroupMemberStatus.Confirmed ? (
        <View style={[styles.actionsWrapper]}>
          <ActionButton
            text={t('resendInvitation')}
            iconName="envelope"
            isLoading={isResendInvitationLoading}
            onPress={resendInvitation}
            testID="resend-invitation-btn"
            iconWidth={16}
            iconSizeInTable={16}
            inTable={inTable}
          />
          <ActionButton
            text={t('cancelInvitation')}
            iconName="close"
            iconWidth={11}
            iconSizeInTable={13}
            onPress={() => setCancelInvitationModal(true)}
            testID="cancel-invitation-btn"
            inTable={inTable}
          />
        </View>
      ) : (
        <View style={[styles.actionsWrapper, !inTable && styles.actionsWrapperCard]}>
          {isMemberRoleEditButtonDisplayed && (
            <ActionButton
              text={t('editRole')}
              iconName="pencil"
              iconWidth={14}
              iconSizeInTable={15}
              onPress={() => setEditRoleModal(true)}
              testID="edit-role-btn"
              disabled={!isMemberRoleEditEnabled}
              inTable={inTable}
            />
          )}
          <ActionButton
            text={t('passwordReset')}
            isLoading={resetPasswordMutation.isPending}
            iconName="padlock"
            onPress={handlePasswordResetPress}
            testID="password-reset-btn"
            inTable={inTable}
            iconWidth={18}
          />
          <ActionButton
            text={t('removeFromGroup')}
            iconName="delete"
            onPress={() => setRemoveFromGroupModal(true)}
            testID="remove-from-group-btn"
            iconWidth={21}
            iconSizeInTable={22}
            inTable={inTable}
          />
        </View>
      )}
      {cancelInvitationModal && (
        <CancelInvitationModal memberId={member.id} close={() => setCancelInvitationModal(false)} />
      )}
      {editRoleModal && (
        <ModalPortal>
          <EditRoleModal memberId={member.id} close={() => setEditRoleModal(false)} />
        </ModalPortal>
      )}
      {removeFromGroupModal && (
        <RemoveFromGroupModal close={() => setRemoveFromGroupModal(false)} memberId={member.id} />
      )}
    </>
  );
};

const ActionButton: React.FC<ButtonProps> = ({
  text,
  onPress,
  iconName,
  isLoading,
  testID,
  iconWidth,
  iconSizeInTable,
  inTable,
  disabled,
}) => {
  return inTable ? (
    <View style={styles.iconButtonWrapper}>
      {isLoading ? (
        <View style={styles.iconButtonWrapper}>
          <LoadingIndicator size="small" />
        </View>
      ) : (
        <IconButton
          tooltip={text}
          name={iconName}
          width={iconSizeInTable}
          onPress={onPress}
          testID={testID}
          color={palette.navy}
          disabled={disabled}
        />
      )}
    </View>
  ) : (
    <IconTextButton
      text={text}
      icon={iconName}
      iconWidth={iconWidth}
      onPress={onPress}
      testID={testID}
      disabled={disabled}
      style={styles.textButton}
      isLoading={isLoading}
      iconPosition="left"
      iconColor={palette.navy}
      underlineAtHover
      textStyle={typography.body1ShortSemiBold}
    />
  );
};

const styles = StyleSheet.create({
  actionsWrapper: {
    flexDirection: 'row',
  },
  actionsWrapperCard: {
    flexWrap: 'wrap',
    margin: -4,
  },
  iconButtonWrapper: {
    justifyContent: 'center',
    width: 40,
  },
  textButton: {
    margin: 4,
  },
});
