import React, { createContext, useContext, useState, useLayoutEffect, Fragment, ReactNode } from 'react';

import { generateId } from './utils';

type Portal = { id: string; children: ReactNode };

type PortalContext = {
  portals: Portal[];
  mount: (id: string, children: ReactNode) => void;
  update: (id: string, children: ReactNode) => void;
  unmount: (id: string) => void;
};

interface PortalComponent extends React.FC<React.PropsWithChildren> {
  Outlet: React.FC<React.PropsWithChildren>;
  Provider: React.FC<React.PropsWithChildren>;
}

export const createPortal = () => {
  const Context = createContext({
    portals: [],
    mount: () => null,
    update: () => null,
    unmount: () => null,
  } as PortalContext);

  const Provider: React.FC<React.PropsWithChildren> = (props) => {
    const [portals, setPortals] = useState<Portal[]>([]);

    const mount = (id: string, children: ReactNode) => {
      setPortals((portals) => [...portals, { id, children }]);
    };

    const update = (id: string, children: ReactNode) => {
      setPortals((portals) => portals.map((item) => (item.id === id ? { id, children } : item)));
    };

    const unmount = (id: string) => {
      setPortals((portals) => portals.filter((item) => item.id !== id));
    };

    return (
      <Context.Provider
        value={{
          portals,
          mount,
          update,
          unmount,
        }}
      >
        {props.children}
      </Context.Provider>
    );
  };

  const Portal: PortalComponent = (props) => {
    const { portals, mount, update, unmount } = useContext(Context);
    const [id] = useState(generateId);

    useLayoutEffect(() => {
      mount(id, props.children);

      return () => {
        unmount(id);
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useLayoutEffect(() => {
      const portal = portals.find((item) => item.id === id);

      if (portal && portal.children !== props.children) {
        update(id, props.children);
      }
    });

    return null;
  };

  const Outlet: React.FC = () => {
    const { portals } = useContext(Context);

    return (
      <>
        {portals.map(({ id, children }) => (
          <Fragment key={id}>{children}</Fragment>
        ))}
      </>
    );
  };

  Portal.Outlet = Outlet;
  Portal.Provider = Provider;

  return Portal;
};
