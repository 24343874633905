import React, { createContext, useCallback, useContext, useState } from 'react';
import type { TokenPayload } from 'recurly__recurly-js';

import type { StudentVerificationParams } from 'src/features/studentVerification/types';
import { GoogleAnalyticsService } from 'src/features/tracking';
import { convertObjectToSnakeCase, isObjectNotEmpty, Nullable, showNotification } from 'src/helpers';
import { EventEmitterService } from 'src/services';

import {
  getErrorMessagesForSubscriptionForm,
  getPlanDetailsFromPlanSetup,
  getPlanSetupFromPlanDetails,
} from '../helpers';
import { usePromotion, usePurchaseSubscription } from '../hooks';
import type { PlanSetup } from '../types';
import { getGAEventData, getProfileDataFromStudentVerification } from './helpers';
import { useRegistrationFormReducer, RegistrationFormState } from './state';
import {
  type RegistrationFormContext,
  RegistrationFormEvent,
  RegistrationFormStep,
  type RegistrationStepsData,
  RegistrationStepWithData,
} from './types';

const STEPS = [
  RegistrationFormStep.CreateAnAccount,
  RegistrationFormStep.ReviewPlanDetails,
  RegistrationFormStep.CompleteYourProfile,
  RegistrationFormStep.PaymentDetails,
] as const;
type Steps = typeof STEPS;

interface IRegularRegistrationFormContext extends RegistrationFormContext<Steps> {
  /** Allows filling student data after completing the student status verification process */
  setStudentData(data: StudentVerificationParams): void;
  setStudentVerificationId(verificationId?: string): void;
}
interface Props extends React.PropsWithChildren {
  initialPlanSetup: PlanSetup;
  getRecaptchaToken(): Promise<string>;
  getRecurlyTokenPayload(): Promise<TokenPayload>;
  subscriptionInvitationId?: string;
  isTrial?: boolean;
}

const Emitter = new EventEmitterService<RegistrationFormEvent>();

export const RegularRegistrationFormContext = createContext<IRegularRegistrationFormContext | null>(null);

/** Contains logic for regular registration form. Built upon RegistrationFormContext interface */
export const RegularRegistrationContextProvider: React.FC<Props> = ({
  children,
  initialPlanSetup,
  getRecaptchaToken,
  getRecurlyTokenPayload,
  isTrial,
  subscriptionInvitationId,
}) => {
  const [state, dispatch] = useRegistrationFormReducer(
    initialPlanSetup,
    getInitialRegularSubscriptionFormState,
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const [studentVerificationId, setStudentVerificationId] = useState<string>();

  const { mutate: purchaseSubscription } = usePurchaseSubscription();
  const { data: promotion } = usePromotion();

  const getStepData = <T extends RegistrationFormStep>(step: T): RegistrationStepsData[T] | null => {
    return state.data[step];
  };

  const isStepSubmitted: IRegularRegistrationFormContext['isStepSubmitted'] = (step) => {
    return state.submittedSteps.includes(step);
  };

  const isStepActive: IRegularRegistrationFormContext['isStepActive'] = (step) => {
    switch (step) {
      case RegistrationFormStep.CreateAnAccount:
        return true;
      case RegistrationFormStep.ReviewPlanDetails:
        return isStepSubmitted(RegistrationFormStep.CreateAnAccount);
      case RegistrationFormStep.CompleteYourProfile:
        return isStepSubmitted(RegistrationFormStep.ReviewPlanDetails);
      case RegistrationFormStep.PaymentDetails:
        return isStepSubmitted(RegistrationFormStep.CompleteYourProfile);
    }
  };

  const onDirtyChange: IRegularRegistrationFormContext['onDirtyChange'] = (step, isDirty) => {
    dispatch({ type: 'set-dirty-step', step, isDirty });
  };

  const submitStep: IRegularRegistrationFormContext['submitStep'] = (step, data) => {
    dispatch({ type: 'submit-step', step, data });
    Emitter.emit('step-submitted', { step });
    trackGoogleAnalyticsEvent({ step, data } as RegistrationStepWithData, state.data);
  };

  const getNextStep: IRegularRegistrationFormContext['getNextStep'] = (step) => {
    const index = STEPS.findIndex((s) => s === step);
    return STEPS[index + 1] || null;
  };

  const setStudentData: IRegularRegistrationFormContext['setStudentData'] = useCallback(
    (data) => {
      const profileData = getProfileDataFromStudentVerification(data);
      dispatch({
        type: 'set-step-data',
        step: RegistrationFormStep.CompleteYourProfile,
        data: profileData,
        overwrite: false,
      });
      dispatch({
        type: 'set-step-data',
        step: RegistrationFormStep.CreateAnAccount,
        data: {
          password: '',
          email: data.email,
        },
        overwrite: false,
      });
    },
    [dispatch],
  );

  const submit = async () => {
    const captchaToken = await getRecaptchaToken();
    // user may need to solve a captcha challenge, so we start loading after it resolves
    setSubmitting(true);
    const billingInfoToken = await getRecurlyTokenPayload();
    purchaseSubscription(
      {
        formData: state.data as RegistrationStepsData,
        billingInfoToken,
        captchaToken,
        promotionParams: promotion?.params,
        isTrial,
        studentVerificationId,
        subscriptionInvitationId,
      },
      {
        onError: (error: any) => {
          const errors = error?.response?.data;

          if (!errors) {
            showNotification({ type: 'error', autoHide: false });
            return;
          }

          const { accountErrors, demographicsErrors, otherErrors, planErrors, profileErrors } =
            getErrorMessagesForSubscriptionForm(errors);

          if (isObjectNotEmpty(accountErrors)) {
            Emitter.emit('step-error', {
              step: RegistrationFormStep.CreateAnAccount,
              errors: accountErrors,
            });
          }
          if (isObjectNotEmpty(planErrors)) {
            Emitter.emit('step-error', {
              step: RegistrationFormStep.ReviewPlanDetails,
              errors: planErrors,
            });
          }
          if (isObjectNotEmpty(profileErrors) || isObjectNotEmpty(demographicsErrors)) {
            Emitter.emit('step-error', {
              step: RegistrationFormStep.CompleteYourProfile,
              errors: { ...profileErrors, ...demographicsErrors },
            });
          }
          if (isObjectNotEmpty(otherErrors)) {
            const messages = Object.values(otherErrors).join('\n');
            showNotification({ type: 'error', autoHide: false, title: messages || '' });
          }
        },
        onSuccess: () => {
          Emitter.emit('form-submitted', undefined);
        },
        onSettled: () => {
          setSubmitting(false);
        },
      },
    );
  };

  const email = getStepData(RegistrationFormStep.CreateAnAccount)?.email ?? '';
  const areAllStepsSubmitted = !STEPS.map(isStepSubmitted).some((isSubmitted) => !isSubmitted);

  return (
    <RegularRegistrationFormContext.Provider
      value={{
        submitStep,
        getStepData,
        isStepActive,
        isStepSubmitted,
        onDirtyChange,
        setStudentData,
        submit,
        email,
        isDirty: !!state.dirtySteps.length,
        isSubmitting,
        areAllStepsSubmitted,
        formData: state.data,
        on: Emitter.subscribe.bind(Emitter),
        setStudentVerificationId,
        getNextStep,
        steps: STEPS,
      }}
    >
      {children}
    </RegularRegistrationFormContext.Provider>
  );
};

const getInitialRegularSubscriptionFormState = (
  initialPlanSetup: PlanSetup,
): RegistrationFormState<Steps> => ({
  data: {
    [RegistrationFormStep.CreateAnAccount]: null,
    [RegistrationFormStep.CompleteYourProfile]: null,
    [RegistrationFormStep.PaymentDetails]: null,
    [RegistrationFormStep.ReviewPlanDetails]: {
      ...getPlanDetailsFromPlanSetup(initialPlanSetup),
      dueToday: 0,
    },
  },
  dirtySteps: [],
  submittedSteps: [],
});

export const useRegularRegistrationFormContext = () => {
  const context = useContext(RegularRegistrationFormContext);

  if (!context) {
    throw new Error('Hook cannot be used outside of RegularRegistrationFormContext');
  }

  return context;
};

function trackGoogleAnalyticsEvent(
  { step, data }: RegistrationStepWithData,
  formData: Nullable<RegistrationStepsData>,
) {
  // TODO: Remove the ! operator once types are improved; this step is always defined
  const { dueToday, ...planDetails } = formData[RegistrationFormStep.ReviewPlanDetails]!;
  const planSetup = getPlanSetupFromPlanDetails(planDetails);
  switch (step) {
    case RegistrationFormStep.CreateAnAccount: {
      GoogleAnalyticsService.track('signup');
      break;
    }
    case RegistrationFormStep.ReviewPlanDetails: {
      const planSetup = getPlanSetupFromPlanDetails(data);
      GoogleAnalyticsService.track('add_to_cart', getGAEventData(planSetup, data.dueToday));
      break;
    }
    case RegistrationFormStep.CompleteYourProfile: {
      GoogleAnalyticsService.track('begin_checkout', getGAEventData(planSetup, dueToday));
      break;
    }
    case RegistrationFormStep.PaymentDetails: {
      const eventData = convertObjectToSnakeCase({
        ...getGAEventData(planSetup, dueToday),
        coupon: planSetup.discountCode,
        paymentType: data.creditCardInformation.cardType,
      });
      GoogleAnalyticsService.track('add_payment_info', eventData);
    }
  }
}
