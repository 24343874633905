import { useMemo } from 'react';
import throttle from 'throttleit';

import { MixpanelService, MixpanelEvent, MixpanelFavoriteLocation } from 'src/features/tracking';

import { useAddFavorite, useFavoritesIds, useRemoveFavorite } from './queries';

interface Params {
  id: string;
  title: string;
  location: MixpanelFavoriteLocation;
}

interface FavoritesManagement {
  isLoading: boolean;
  isFavorite: boolean;
  toggleFavorite: () => void;
}

export function useFavorites({ id, title, location }: Params): FavoritesManagement {
  const { data: favorites = [] } = useFavoritesIds();
  const { mutate: addToFavorites, isPending: isAdding } = useAddFavorite();
  const { mutate: removeFromFavorites, isPending: isRemoving } = useRemoveFavorite();

  const isLoading = isAdding || isRemoving;
  const isFavorite = favorites.includes(id);

  const toggleFavorite = useMemo(
    () =>
      throttle(() => {
        if (isFavorite) {
          removeFromFavorites(id);
        } else {
          addToFavorites(id, {
            onSuccess: () => {
              MixpanelService.track(MixpanelEvent.FavoritesItemAdded, {
                'Drug ID': id,
                'Drug Title': title,
                Location: location,
              });
            },
          });
        }
      }, 2000),
    [isFavorite, id, location, title, addToFavorites, removeFromFavorites],
  );

  return {
    isLoading,
    isFavorite,
    toggleFavorite,
  };
}
