import { MAIN_NOTE_SECTION_ID, NEW_GENERAL_NOTE_PLACEHOLDER_ID } from './constants';
import {
  ContentNote,
  Note,
  NotesUrlConfig,
  NoteEditorProps,
  ContentNoteEditorProps,
  PartialContentNote,
  PartialNote,
  ExisitingNoteCardProps,
  NewNoteCardProps,
} from './types';
import { Contents } from '../content/config';
import { MixpanelNoteParams } from '../tracking';

export function isSavedNote(
  note: PartialContentNote | ContentNote | PartialNote | Note,
): note is ContentNote | Note {
  return 'id' in note;
}

export function isContentNote(note: Note | ContentNote): note is ContentNote {
  return (note as ContentNote).contentId !== undefined;
}

export const isContentNoteEditorProps = (props: NoteEditorProps): props is ContentNoteEditorProps =>
  'contentId' in props;

export const getNotesApiUrl = ({ contentId, noteId }: NotesUrlConfig = {}) =>
  ['/notes', contentId && `/contents/${contentId}`, noteId && `/${noteId}`, '/'].filter(Boolean).join('');

export const isNewGeneralNotePlaceholder = (note: Note | ContentNote) =>
  note.id === NEW_GENERAL_NOTE_PLACEHOLDER_ID;

export const isExistingNoteProps = (
  props: ExisitingNoteCardProps | NewNoteCardProps,
): props is ExisitingNoteCardProps => props.type === 'existing';

/**
 * @throws Error can be thrown if there are no Mixpanel properties defined for given content type
 */
export const getMixpanelNoteParams = (
  contentNote?: Pick<ContentNote, 'contentId' | 'contentTitle' | 'title' | 'section' | 'contentType'>,
): MixpanelNoteParams => {
  if (!contentNote) {
    return {
      'Note Location': 'General',
    };
  }

  const { contentType, contentId, contentTitle, title, section } = contentNote;
  const location = section !== MAIN_NOTE_SECTION_ID ? title : 'General';

  switch (Contents.getSimplifiedType(contentType)) {
    case 'drug': {
      return {
        'Drug ID': contentId,
        'Drug Title': contentTitle,
        'Note Location': location,
      };
    }

    case 'appendix': {
      return {
        'Appendix ID': contentId,
        'Appendix Title': contentTitle,
        'Note Location': location,
      };
    }

    case 'ddx': {
      return {
        'DDx ID': contentId,
        'DDx Title': contentTitle,
        'Note Location': location,
      };
    }

    case 'dx-tx': {
      return {
        'Dx & Tx ID': contentId,
        'Dx & Tx Title': contentTitle,
        'Note Location': location,
      };
    }
    default: {
      throw new Error(
        `Couldn't define Mixpanel properties for content note with content type ${contentType}`,
      );
    }
  }
};
