import { client } from 'src/api/client';

import { AppcuesLaunchpadResponse } from './types';

export const fetchAppcuesLaunchpadFlows = async () => {
  const response = await client.request<AppcuesLaunchpadResponse>({
    method: 'GET',
    url: '/accounts/user/viewed/appcues-launchpad/',
  });

  return response.data;
};

export const updateUserSeenFlows = async (flowId: string) => {
  const response = await client.request({
    method: 'PATCH',
    url: '/accounts/user/viewed/appcues-launchpad/',
    data: {
      id: flowId,
    },
  });

  return response.data;
};
