import { Suspense, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { LoadingIndicator, PrimaryButton, SecondaryButton } from 'src/components';

import { useNotesListContext, useNotesListItem } from '../../context/NotesListContext';
import { isContentNote, isExistingNoteProps, isNewGeneralNotePlaceholder } from '../../helpers';
import { useRemoveNote, useSaveNote } from '../../hooks';
import { ExisitingNoteCardProps, MutateNoteParams, NewNoteCardProps } from '../../types';
import { Editor } from '../NoteEditor/Editor';
import { RemoveNoteConfirmModal } from '../RemoveNoteConfirmModal';

interface CommonProps {
  close(): void;
}

interface ExisitingNoteProps extends CommonProps, ExisitingNoteCardProps {}

interface NewNoteProps extends CommonProps, NewNoteCardProps {}

type Warning = 'remove' | 'cancel';

export const NoteCardEditor: React.FC<ExisitingNoteProps | NewNoteProps> = (props) => {
  const { close } = props;
  const isExistingNote = isExistingNoteProps(props);

  const initialNoteText = isExistingNote ? props.note.note : '';
  const initialNoteTitle = isExistingNote ? props.note.title : '';
  const [content, setContent] = useState(initialNoteText);
  const [title, setTitle] = useState(initialNoteTitle);
  const [warning, setWarning] = useState<Warning>();

  const { t } = useTranslation();
  const saveNote = useSaveNote();
  const removeNote = useRemoveNote();
  const { setAddingNewGeneralNote } = useNotesListContext();
  const { onDirtyChange } = useNotesListItem(isExistingNote ? props.note.id : props.contentData.sectionId);

  const isContentDirty = content.trim() !== initialNoteText || title.trim() !== initialNoteTitle;

  const handleChange = useCallback((params: { text?: string; title?: string }) => {
    if (params.text) {
      setContent(params.text);
    }
    if (params.title !== undefined) {
      setTitle(params.title);
    }
  }, []);

  useEffect(() => {
    onDirtyChange(isContentDirty);
  }, [isContentDirty, onDirtyChange]);

  const closeEditor = () => {
    if (isExistingNote && isNewGeneralNotePlaceholder(props.note)) {
      setAddingNewGeneralNote(false);
    }
    close();
  };

  const handleRemoveNotePress = () => {
    setWarning('remove');
  };

  const handleNoteRemove = () => {
    isExistingNote && removeNote(props.note);
    closeEditor();
  };

  const handleCancelPress = () => {
    if (isContentDirty) {
      setWarning('cancel');
    } else {
      closeEditor();
    }
  };

  const handleSavePress = () => {
    let params: MutateNoteParams;

    if (!isExistingNote) {
      // new content note
      params = {
        note: {
          ...props.contentData,
          title: props.contentData.sectionTitle,
          section: props.contentData.sectionId,
          note: content,
        },
      };
    } else if (isNewGeneralNotePlaceholder(props.note)) {
      // new general note
      params = {
        note: {
          note: content,
          title: title,
        },
      };
    } else {
      // editing existing note
      params = {
        note: {
          ...props.note,
          note: content,
          title: title,
        },
      };
    }

    saveNote(params, {
      onSuccess: () => {
        closeEditor();
      },
    });
  };

  const isGeneralNote = isExistingNote && !isContentNote(props.note);
  const isSaveButtonDisabled =
    content.trim() === '' || (isExistingNote && !isContentNote(props.note) && title.trim() === '');
  const displayRemoveButton = isExistingNote && !isNewGeneralNotePlaceholder(props.note);

  return (
    <>
      <View style={styles.editorWrapper}>
        <Suspense fallback={<LoadingIndicator />}>
          <Editor
            onChange={handleChange}
            initialValue={isExistingNote ? props.note.note : undefined}
            title={title}
            showTitle={isGeneralNote}
          />
        </Suspense>
      </View>
      <View style={styles.buttonsRow}>
        {displayRemoveButton ? (
          <SecondaryButton
            title={t('delete')}
            size="tiny"
            onPress={handleRemoveNotePress}
            testID="note-editor-delete-btn"
          />
        ) : (
          <View />
        )}
        <View style={styles.buttonsRow}>
          <SecondaryButton
            title={t('cancel')}
            size="tiny"
            onPress={handleCancelPress}
            testID="note-editor-cancel-btn"
          />
          <PrimaryButton
            title={t('save')}
            size="tiny"
            onPress={handleSavePress}
            containerStyle={styles.saveButton}
            disabled={isSaveButtonDisabled}
            testID="note-editor-save-btn"
          />
        </View>
      </View>
      {!!warning && (
        <RemoveNoteConfirmModal
          cancel={() => setWarning(undefined)}
          type={warning}
          confirm={warning === 'cancel' ? closeEditor : handleNoteRemove}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  editorWrapper: {
    marginBottom: 12,
  },
  buttonsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  saveButton: {
    marginLeft: 8,
  },
});
