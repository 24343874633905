import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import {
  BaseButtonProps,
  ContentListItem,
  SelectButton,
  SecondaryButton,
  StyledText,
} from 'src/components';
import { quickLinkButtonColors, ContentStatus } from 'src/features/content/constants';
import { ContentListItemCommonProps } from 'src/features/content/types';
import { isDrugHandout } from 'src/helpers/content';
import { usePaywallInContentCard } from 'src/hooks';
import { palette, typography } from 'src/styles';

import { getSelectHandoutMixpanelParams } from '../helpers';
import { useSelectedHandout } from '../hooks/useSelectedHandout';
import type { Handout } from '../types';

type Props = ContentListItemCommonProps<Handout>;

export const HandoutCard: React.FC<Props> = React.memo<Props>(({ item, ...rest }) => {
  const { t } = useTranslation();

  const { isSelected, toggleSelected, handleSharePress, shareButtonTitle } = useSelectedHandout(item, () =>
    getSelectHandoutMixpanelParams(rest.location, item.contentType),
  );

  const { isContentBlocked } = usePaywallInContentCard({
    contentType: item.contentType,
  });

  const buttonProps: Partial<BaseButtonProps> = {
    variant: 'secondary',
    size: 'tiny',
    height: 30,
    containerStyle: styles.button,
    colors: quickLinkButtonColors,
    titleStyle: typography.body1ShortSemiBold,
    disabled: isContentBlocked,
  };

  const otherNames = isDrugHandout(item)
    ? item.otherNamesForThisMedication ?? item.otherCommonNames
    : item.alsoCalled?.join(', ');
  const description = otherNames && t('handouts:otherNamesDescription', { value: otherNames });

  return (
    <ContentListItem data={item} description={description} {...rest}>
      {item.status !== ContentStatus.InDevelopment && (
        <View style={styles.buttonsWrapper}>
          <SelectButton
            title={t('common:select')}
            isSelected={isSelected}
            onPress={toggleSelected}
            testID={isSelected ? 'deselect' : 'select' + '-handout-btn'}
            {...buttonProps}
          />
          <SecondaryButton
            title={shareButtonTitle}
            onPress={handleSharePress}
            testID="share-handout-btn"
            {...buttonProps}
          />
          {item.hasSpanishVersion && (
            <StyledText style={styles.spanishInfo} testID="spanish-info">
              {t('handouts:availableInSpanish')}
            </StyledText>
          )}
        </View>
      )}
    </ContentListItem>
  );
});

const styles = StyleSheet.create({
  buttonsWrapper: {
    flexDirection: 'row',
    zIndex: 1,
    alignSelf: 'flex-start',
    marginTop: 12,
  },
  spanishInfo: {
    alignSelf: 'center',
    color: palette.navy,
  },
  button: {
    marginRight: 8,
  },
});
