import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { palette, TD_WIDTH_HORIZONTAL, typography } from 'src/styles';

interface Props {
  isNodeFirst: boolean;
  columnsNumber: number;
  isRowEven: boolean;
}

export const Td: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isNodeFirst,
  columnsNumber,
  isRowEven,
}) => {
  const { isTablet } = useDeviceInfo();
  const isTableHorizontal = !isTablet;
  return (
    <View
      style={[
        styles.tdThCommonStyle,
        isTablet ? styles.tablet : styles.defaultPadding,
        isRowEven && styles.tdEvenRow,
        { width: isTableHorizontal ? TD_WIDTH_HORIZONTAL : `${100 / columnsNumber}%` },
        isTableHorizontal && isNodeFirst && styles.tdThTopRowStyle,
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  tdThCommonStyle: {
    ...typography.body1Short,
    borderColor: palette.grey2,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    ...typography.weightBold,
    backgroundColor: palette.white,
  },
  tablet: {
    borderLeftWidth: 0,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  defaultPadding: {
    padding: 8,
  },
  tdEvenRow: {
    backgroundColor: palette.brightBlue2,
  },
  tdThTopRowStyle: {
    borderTopWidth: 1,
  },
});
