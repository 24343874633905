import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { MarkdownContent, ContentItemScreen, LoadingIndicator } from 'src/components';
import { PDFViewer } from 'src/features/pdfViewer/components';
import {
  OlyticsService,
  MixpanelService,
  MixpanelEvent,
  getMixpanelAlgorithmLocation,
} from 'src/features/tracking';
import { isAndroid, markDownToPlainText } from 'src/helpers';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { ifWeb } from 'src/styles';

import { useAlgorithmItem } from '../hooks/queries';
import { useAlgorithmMixpanelEvents } from '../hooks/useAlgorithmMixpanelEvents';

export interface Props extends ScreenProps<Route.AlgorithmItem> {}

export const AlgorithmItemScreen: React.FC<Props> = ({ route, navigation }) => {
  const { id, source, searchQuery } = route.params;
  const { data, fetchStatus, error } = useAlgorithmItem(id);
  const [shouldPageScroll, setPageScroll] = useState(true);
  const pdfViewerHandlers = useAlgorithmMixpanelEvents();

  const onScrollInPdf = (status: 'start' | 'stop') => {
    isAndroid && setPageScroll(status === 'stop');
  };

  useEffect(() => {
    if (data) {
      MixpanelService.track(MixpanelEvent.AlgorithmView, {
        'Algorithm ID': data.id,
        'Algorithm Title': data.title,
        'Search Term Before Click Through': source === 'search-list' ? searchQuery : undefined,
        ...getMixpanelAlgorithmLocation(source),
      });
    }

    OlyticsService.fire();
  }, [source, data, searchQuery]);

  useLayoutEffect(() => {
    data?.title && navigation.setOptions({ title: markDownToPlainText(data.title) });
  }, [data?.title, navigation]);

  const algorithmMetadata = data && <MarkdownContent limited>{data.authorInformation}</MarkdownContent>;

  return (
    <ContentItemScreen
      item={data}
      title={i18n.t('screens:algorithm')}
      fetchStatus={fetchStatus}
      error={error}
      oneSectionContent={
        <View style={styles.contentWrapper} testID="algorithm-pdf-viewer">
          {data && (
            <Suspense fallback={<LoadingIndicator />}>
              <PDFViewer {...pdfViewerHandlers} pdfUrl={data.pdfUrl} onScrollInViewer={onScrollInPdf} />
            </Suspense>
          )}
        </View>
      }
      headerDescription={algorithmMetadata}
      noFooter
      blockScroll={!shouldPageScroll}
    />
  );
};

const styles = StyleSheet.create({
  contentWrapper: {
    ...ifWeb({}, { marginTop: 23 }),
  },
});
