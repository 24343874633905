import { InfiniteScrollList } from 'src/components';
import { InfiniteScrollListLegacy } from 'src/components/InfiniteScrollListLegacy';
import { isWeb } from 'src/helpers';

import { InteractionCard } from './InteractionCard';
import { useInteractions } from '../../hooks';
import { DICClassificationKey, DICMode } from '../../types';

interface Props {
  drugs: string[];
  classification: DICClassificationKey;
  mode?: DICMode;
}

/** Used when list of interactions with given classification type needs to be fetched after user opens the accordion */
export const InteractionsPaginatedList: React.FC<Props> = ({ drugs, classification, mode }) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInteractions({
    drugs,
    params: {
      classification,
      mode,
    },
  });

  const ListComponent = isWeb ? InfiniteScrollList : InfiniteScrollListLegacy;

  return (
    <ListComponent
      data={data?.items}
      loading={isLoading || isFetchingNextPage}
      allFetched={!hasNextPage}
      fetchNextPage={fetchNextPage}
      renderItem={({ item, index }) => (
        <InteractionCard interaction={item} bottomBorder={index !== data!.items.length - 1} />
      )}
      keyExtractor={(item) => item.drugs.map((drug) => drug.id).join()}
    />
  );
};
