import 'intersection-observer';
import ResizeObserver from 'resize-observer-polyfill';
// needed by pdfjs on Chrome 56
import 'geometry-polyfill';
import '@netless/canvas-polyfill';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'; /** Needed on Chrome 56 */

if (!HTMLElement.prototype.scrollTo) {
  HTMLElement.prototype.scrollTo = function (optionsOrLeft, top) {
    if (typeof optionsOrLeft === 'number' || typeof optionsOrLeft === 'string') {
      this.scrollTop = top;
      this.scrollLeft = optionsOrLeft;
    } else {
      if (optionsOrLeft.left !== undefined) {
        this.scrollLeft = optionsOrLeft.left;
      }
      if (optionsOrLeft.top !== undefined) {
        this.scrollTop = optionsOrLeft.top;
      }
    }
  };

  const originalScrollTo = window.scrollTo;
  window.scrollTo = function (...args) {
    if (typeof args[0] === 'object') {
      // `behavior` option ignored
      const { left, top } = args[0];
      originalScrollTo(left, top);
    } else {
      originalScrollTo(...args);
    }
  };
}

if (!navigator.languages) {
  navigator.languages = ['en-US'];
}

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

if (!Blob.prototype.text) {
  Blob.prototype.text = function () {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(this);
    });
  };
}
