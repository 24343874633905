import React from 'react';

import { useIsSignedIn, useUserInfo } from 'src/features/auth/hooks';

import { ActiveDevicesModal } from './ActiveDevicesModal';

export const DevicesLimitWarning: React.FC = () => {
  const isSignedIn = useIsSignedIn();
  const { unlimitedSessions, expiredSubscription } = useUserInfo();

  if (isSignedIn && !unlimitedSessions && !expiredSubscription) {
    return <ActiveDevicesModal />;
  }

  return null;
};
