import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { ContentListItem, FullScreenTemplate, MobileContentHeader } from 'src/components';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { palette } from 'src/styles';

type Props = ScreenProps<Route.PrintableHandoutsList>;

export const PrintableHandoutsListScreen: React.FC<Props> = ({ route }) => {
  const { items } = route.params;
  const { t } = useTranslation('handouts');

  return (
    <FullScreenTemplate title={t('optionsHeader')} showBackButton contentContainerStyle={styles.wrapper}>
      <MobileContentHeader style={styles.title}>{t('select')}</MobileContentHeader>
      {items.map((item, index) => (
        <ContentListItem key={item.id} data={item} location="content-item" topBorder={index === 0} />
      ))}
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: palette.white,
  },
  title: {
    marginHorizontal: 16,
    marginTop: 25,
    marginBottom: 12,
  },
});
