import React, { useCallback, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';

import { BaseModal, LoadingIndicator, MarkdownContent, StyledText, Video } from 'src/components';
import { palette, typography } from 'src/styles';

import type { DxTxImage } from '../types';

interface Props {
  close: () => void;
  item: DxTxImage;
  autoPlay: boolean;
}

export const DxTxVideoModal: React.FC<Props> = ({ close, item, autoPlay }) => {
  const { videoUrl } = item;
  const isLoading = !videoUrl;
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const wrapperOnLayoutHandler = useCallback((e: LayoutChangeEvent) => {
    setWrapperWidth(e.nativeEvent.layout.width);
  }, []);
  return (
    <BaseModal
      close={close}
      header={
        item && <StyledText style={[styles.title, typography.body5Bold]}>{item.figureTitle}</StyledText>
      }
    >
      {isLoading && <LoadingIndicator style={styles.loader} />}
      {item && (
        <View onLayout={wrapperOnLayoutHandler}>
          <MarkdownContent style={styles.description}>{item.figureLegend}</MarkdownContent>
          <Video
            videoUrl={videoUrl}
            shouldShowControls
            style={{ width: wrapperWidth }}
            autoPlay={autoPlay}
          />
        </View>
      )}
    </BaseModal>
  );
};

const styles = StyleSheet.create({
  title: {
    color: palette.navy,
    marginBottom: 16,
  },
  description: {
    marginBottom: 16,
  },
  loader: {
    marginVertical: 40,
  },
});
