import { useFormik } from 'formik';
import { mapValues } from 'lodash-es';
import { useEffect, useState } from 'react';

import { BaseButton, Form } from 'src/components';
import { InputItem } from 'src/components/InputItem';
import { Subheader } from 'src/components/Subheader';
import { passwordValidationRules } from 'src/constants/forms';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { SentryService, MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { isWeb, showNotification } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';

import { SubscriptionSection as Section } from './SubscriptionSections';
import { validateEmailAndPassword } from '../../api';
import { useRegistrationFormStep } from '../../forms/hooks';
import { RegistrationFormStep } from '../../forms/types';
import { CreateAccount, SubscriptionBaseProps } from '../../types';

interface FormValues extends CreateAccount {
  confirmEmail: string;
  confirmPassword: string;
}

interface Props extends SubscriptionBaseProps {
  groupInvitationForm?: boolean;
  initialEmail?: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  password: passwordValidationRules,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], i18n.t('validation:passwords_dont_match'))
    .required(),
  email: Yup.string().email(i18n.t('validation:enterValidEmail')).required(),
  confirmEmail: Yup.string()
    .email(i18n.t('validation:enterValidEmail'))
    .oneOf([Yup.ref('email')], i18n.t('validation:emailShouldMatch'))
    .required(),
});

const initialData = {
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
};

export const CreateAnAccount: React.FC<Props> = ({ stepNo, groupInvitationForm, initialEmail = '' }) => {
  const { isTablet } = useDeviceInfo();
  const [loading, setLoading] = useState(false);
  const emailReadOnly = !!groupInvitationForm;

  const {
    submit,
    isSubmitted,
    isActive,
    onDirtyChange,
    data: submittedData,
  } = useRegistrationFormStep(RegistrationFormStep.CreateAnAccount, {
    onError: (errors) => {
      setTouched(
        mapValues(errors, (v) => !!v),
        false,
      );
      setErrors(errors);
    },
  });
  const submitHandler = async (values: FormValues) => {
    setLoading(true);
    try {
      await validateEmailAndPassword({ ...values, isNewSubscriber: !groupInvitationForm });
      const date = new Date().toISOString();
      MixpanelService.track(
        MixpanelEvent.CreateAnAccount,
        {
          Email: values.email,
          'Account Created Date': date,
        },
        { enableAsAnonymous: true },
      );
      SentryService.setUser(values.email);
      submit(values);
    } catch (err: any) {
      if (err.response?.data) {
        const emailApiError = err.response.data.email;
        if (emailApiError) {
          setFieldError('email', getErrorMessageForApiError(emailApiError));
        }
        const passwordApiError = err.response.data.password;
        if (passwordApiError) {
          setFieldError('password', getErrorMessageForApiError(passwordApiError));
        }
        if (emailApiError || passwordApiError) {
          return;
        }
      }
      showNotification({ type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    setFieldError,
    setErrors,
    setFieldTouched,
    dirty,
    setTouched,
  } = useFormik({
    initialValues: getValuesFromSubmittedData(submittedData) || { ...initialData, email: initialEmail },
    onSubmit: submitHandler,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    onDirtyChange?.(dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  const handleEditingSubmit = isWeb ? () => handleSubmit() : undefined;

  const renderField = (
    field: keyof FormValues,
    options: { testID?: string; i18nKeyPrefix?: string } = {},
  ) => {
    const isPassword = field === 'password' || field === 'confirmPassword';
    const isEmail = !isPassword;
    const { testID, i18nKeyPrefix = 'subscriptionProcess' } = options;

    const handleValueChange = (_value: string) => {
      const value = isEmail ? _value.replace(/\s/g, '') : _value;
      handleChange(field)!(value);
    };

    return (
      <InputItem
        label={
          i18n.exists(`${i18nKeyPrefix}:${field}Label`) ? i18n.t(`${i18nKeyPrefix}:${field}Label`) : ''
        }
        placeholder={
          i18n.exists(`${i18nKeyPrefix}:${field}Placeholder`)
            ? i18n.t(`${i18nKeyPrefix}:${field}Placeholder`)
            : ''
        }
        description={
          i18n.exists(`${i18nKeyPrefix}:${field}Description`)
            ? i18n.t(`${i18nKeyPrefix}:${field}Description`)
            : ''
        }
        onChangeText={handleValueChange}
        onBlur={() => setFieldTouched(field)}
        blurOnSubmit
        autoCapitalize="none"
        autoCompleteType={isPassword ? 'new-password' : 'email'}
        inputMode={isPassword ? undefined : 'email'}
        value={values[field]}
        error={errors[field]}
        touched={touched[field]}
        secureTextEntry={isPassword}
        onSubmitEditing={handleEditingSubmit}
        readOnly={!isPassword && emailReadOnly}
        testID={testID}
      />
    );
  };

  const getButtonLabel = () => (isSubmitted ? 'saveChanges' : 'next');

  return (
    <Section
      title={i18n.t('subscriptionProcess:createAnAccount')}
      active={isActive}
      checked={isSubmitted}
      stepNo={stepNo}
      testID="create-account-section"
    >
      <Form>
        <Section.Form>
          <Section.Row>
            <Subheader title={i18n.t('subscriptionProcess:enterYourEmail')} />
            <Section.Item>{renderField('email', { testID: 'create-account-email-input' })}</Section.Item>
            <Section.Item>
              {renderField('confirmEmail', {
                testID: 'create-account-confirm-email-input',
                i18nKeyPrefix: groupInvitationForm ? 'groupInvitation' : undefined,
              })}
            </Section.Item>
          </Section.Row>
          <Section.Row>
            <Subheader title={i18n.t('subscriptionProcess:createAPassword')} spreader={!isTablet} />
            <Section.Item>
              {renderField('password', { testID: 'create-account-password-input' })}
            </Section.Item>
            <Section.Item>
              {renderField('confirmPassword', { testID: 'create-account-confirm-password-input' })}
            </Section.Item>
          </Section.Row>
        </Section.Form>
        <Section.ButtonsWrapper>
          <BaseButton
            disabled={isSubmitted && !dirty}
            title={i18n.t(getButtonLabel())}
            onPress={handleSubmit}
            loading={loading}
            testID="create-account-next-btn"
            variant="gradient"
          />
        </Section.ButtonsWrapper>
      </Form>
    </Section>
  );
};

function getValuesFromSubmittedData(data: CreateAccount | null): FormValues | null {
  if (!data) return null;

  return {
    email: data.email,
    confirmEmail: data.email,
    password: data.password,
    confirmPassword: data.password,
  };
}
