import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { PrimaryButton, CheckboxInput, StyledText, SecondaryButton } from 'src/components';
import { signOut, closeCanadianDisclaimer } from 'src/features/auth/state';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { AppDispatch } from 'src/state/store';
import { palette, typography } from 'src/styles';

export const CanadianDisclaimer: React.FC = () => {
  const [agreed, setAgreed] = useState(false);
  const { isTablet } = useDeviceInfo();
  const dispatch: AppDispatch = useDispatch();

  const accept = () => {
    dispatch(closeCanadianDisclaimer());
  };

  const cancel = () => {
    dispatch(signOut());
  };

  return (
    <View testID="canadian-disclaimer-window">
      <StyledText style={[typography.alt7Bold, styles.title]}>
        {i18n.t('canadianDisclaimer:title')}
      </StyledText>
      <StyledText style={[typography.body2, styles.content]}>
        {i18n.t('canadianDisclaimer:content')}
      </StyledText>
      <CheckboxInput
        testID="canadian-disclaimer-checkbox"
        checked={agreed}
        onPress={() => setAgreed(!agreed)}
        title={i18n.t('canadianDisclaimer:agree')}
      />
      <View style={[styles.buttonsWrapper, isTablet && styles.buttonsWrapperTablet]}>
        <PrimaryButton
          testID="canadian-disclaimer-submit-btn"
          title={i18n.t('acknowledge')}
          disabled={!agreed}
          onPress={accept}
          containerStyle={[styles.button, isTablet && styles.buttonTabletFirst]}
        />
        <SecondaryButton
          testID="canadian-disclaimer-cancel-btn"
          title={i18n.t('cancel')}
          onPress={cancel}
          containerStyle={styles.button}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    color: palette.navy,
    marginBottom: 12,
    textTransform: 'uppercase',
  },
  content: {
    color: palette.grey8,
    marginBottom: 18,
  },
  buttonsWrapper: {
    marginTop: 18,
  },
  buttonsWrapperTablet: {
    flexDirection: 'row-reverse',
    justifyContent: 'center',
  },
  button: {
    marginTop: 10,
  },
  buttonTabletFirst: {
    marginLeft: 24,
  },
});
