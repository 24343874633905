import React, { useEffect } from 'react';

import { ContentListScreen, ProInfoBanner } from 'src/components';
import { DxTxConfig } from 'src/features/content/config';
import { ContentListType } from 'src/features/content/constants';
import { SearchContentTypeFilter } from 'src/features/search/enums';
import { OlyticsService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { DxTxCard } from '../components';

type Props = ScreenProps<Route.DxTxList>;

export const DxTxListScreen: React.FC<Props> = ({ route, navigation }) => {
  useEffect(() => {
    OlyticsService.fire();
  }, []);

  return (
    <ContentListScreen
      title={i18n.t('screens:dxTx')}
      titleInUppercase={false}
      route={route}
      navigation={navigation}
      searchFilter={SearchContentTypeFilter.DxTx}
      contentListType={ContentListType.DxTx}
      ContentCardComponent={DxTxCard}
    >
      <ProInfoBanner contentTypes={DxTxConfig.contentTypes} />
    </ContentListScreen>
  );
};
