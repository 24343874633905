import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { FeatureButtonProps, FeatureButton } from './FeatureButton';

interface Props {
  buttonItems: FeatureButtonProps[];
  containerStyle?: StyleProp<ViewStyle>;
}

export const FeatureButtons: React.FC<Props> = ({ buttonItems, containerStyle }) => {
  return (
    <View style={containerStyle}>
      {buttonItems
        .filter((item) => !item.hidden)
        .map((props, index, arr) => (
          <View
            key={props.text}
            style={[styles.buttonWrapper, index === arr.length - 1 && styles.lastButtonWrapper]}
          >
            <FeatureButton {...props} />
          </View>
        ))}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonWrapper: {
    marginBottom: 10,
  },
  lastButtonWrapper: {
    marginBottom: 0,
  },
});
