import axios from 'axios';
import { mapValues, isEmpty } from 'lodash-es';
import React from 'react';

import { PrimaryButton } from 'src/components';
import { locationInformationForm } from 'src/constants/forms';
import { Error500Banner } from 'src/errorHandling/components';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { locationInformationEmptyState } from 'src/features/profile';
import { showNotification } from 'src/helpers';
import { useUniversalForm } from 'src/hooks/forms/useUniversalForm';
import { i18n } from 'src/locale';

import { PracticeLocation as Section } from './PracticeLocationSection';
import { useEditPracticeLocation, usePracticeLocation } from '../../hooks';
import type { EditPracticeLocationErrors, PracticeLocation, PracticeLocationParams } from '../../types';

interface Props {}

export const PracticeLocationForm: React.FC<Props> = () => {
  const { data, error, isFetching } = usePracticeLocation();
  const { mutate: editPracticeLocation, isPending: isEditLoading } = useEditPracticeLocation();

  const {
    renderField: renderFieldInformation,
    handleSubmit: submitForm,
    setErrors,
    dirty,
  } = useUniversalForm(
    data || locationInformationEmptyState,
    handleSaveLocationData,
    locationInformationForm,
  );

  async function handleSaveLocationData({ practice, ...rest }: PracticeLocation) {
    const locationData: PracticeLocationParams = { practice: practice ?? '', location: { ...rest } };
    editPracticeLocation(locationData, {
      onError: (err: any) => {
        if (axios.isAxiosError(err) && err.response?.data) {
          const errors = err.response?.data as EditPracticeLocationErrors;
          const locationErrors = mapValues(errors.location || {}, getErrorMessageForApiError);
          const practiceError = errors.practice && getErrorMessageForApiError(errors.practice);
          if (!isEmpty(locationErrors) || practiceError) {
            setErrors({
              ...locationErrors,
              practice: practiceError,
            });
          } else {
            showNotification({ type: 'error' });
          }
        } else {
          showNotification({ type: 'error' });
        }
      },
    });
  }

  return (
    <>
      {!error ? (
        <Section
          title={i18n.t('adminDashboard:practice-location')}
          isLoading={isFetching}
          testID="practice-location-container"
        >
          <Section.Form>
            <Section.Row>
              <Section.Item>{renderFieldInformation('practice')}</Section.Item>
              <Section.Item>{renderFieldInformation('address1')}</Section.Item>
              <Section.Item>{renderFieldInformation('address2')}</Section.Item>
              <Section.Item>{renderFieldInformation('city')}</Section.Item>
            </Section.Row>
            <Section.Row>
              <Section.Item>{renderFieldInformation('country')}</Section.Item>
              <Section.Item>{renderFieldInformation('postalCode')}</Section.Item>
              <Section.Item>{renderFieldInformation('state')}</Section.Item>
            </Section.Row>
          </Section.Form>
          <Section.ButtonsWrapper>
            <PrimaryButton
              onPress={submitForm}
              title={i18n.t('saveChanges')}
              disabled={!dirty || isEditLoading}
              loading={isEditLoading}
              testID="form-submit-button"
            />
          </Section.ButtonsWrapper>
        </Section>
      ) : (
        <Error500Banner />
      )}
    </>
  );
};
