export enum StudentSubscriptionStatus {
  VerificationInProgress = 'verification_in_progress',
  Verified = 'verified',
  VerificationFailed = 'verification_failed',
  VerificationExpired = 'verification_expired',
  ReverificationSuccessful = 'reverification_successful',
  ReverificationStarted = 'reverification_started',
  ReverificationInfoNeeded = 'reverification_info_needed',
  ReverificationFailed = 'reverification_failed',
  RenewalOptOut = 'renewal_opt_out',
  Graduate = 'graduate',
  UpForRenewal = 'up_for_renewal',
  ActiveStudent = 'active_student',
  RenewalFailed = 'renewal_failed',
}

export enum StudentSubscriptionEligibilityStatus {
  VerificationInProgress = 'verification_in_progress',
  Verified = 'verified',
  VerificationFailed = 'verification_failed',
  VerificationExpired = 'verification_expired',
  ReverificationSuccessful = 'reverification_successful',
  ReverificationStarted = 'reverification_started',
  ReverificationInfoNeeded = 'reverification_info_needed',
  ReverificationFailed = 'reverification_failed',
  RenewalOptOut = 'renewal_opt_out',
  Graduate = 'graduate',
}

export const studentVerificationQueryKeys = {
  studentSubscriptionEligibility: ['student-subscription-eligibility'] as const,
};
