import { InteractionCard } from './InteractionCard';
import { DrugInteraction } from '../../types';

interface Props {
  data: DrugInteraction[];
}

export const InteractionsList: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data.map((interaction, index) => (
        <InteractionCard
          interaction={interaction}
          key={interaction.drugs.map((drug) => drug.id).join()}
          bottomBorder={index !== data.length - 1}
        />
      ))}
    </>
  );
};
