import * as R from 'ramda';
import React, { Suspense } from 'react';

import { FullScreenTemplate, LoadingIndicator } from 'src/components';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { RegistrationForm } from '../components';
import { DEFAULT_PLAN_VALUES } from '../constants';
import { getBasePlanSetup } from '../helpers';
import { useSubscriptionInvitationVerification } from '../hooks/queries/subscription';

type Props = ScreenProps<Route.SubscriptionInvitation>;

export const SubscriptionInvitationScreen: React.FC<Props> = ({ route: { params } }) => {
  const { invitationId } = params ?? {};

  const { data, isLoading } = useSubscriptionInvitationVerification(invitationId);

  if (isLoading) {
    return <FullScreenTemplate isLoading />;
  }

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <RegistrationForm
        initialPlanSetup={
          data
            ? R.mergeWith(R.defaultTo, DEFAULT_PLAN_VALUES, {
                ...getBasePlanSetup(data.plan),
                quantity: data.quantity,
              })
            : DEFAULT_PLAN_VALUES
        }
        subscriptionInvitationId={data ? invitationId : undefined}
      />
    </Suspense>
  );
};
