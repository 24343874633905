import React from 'react';
import { StyleSheet, View, Platform, StyleProp, ViewStyle } from 'react-native';

import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { getShadow, ifWeb, palette } from 'src/styles';

interface Props {
  narrow?: boolean;
  padded?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  dataSet?: Record<string, string | number | boolean | undefined>;
}

export const Box: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const additionalStyles = Platform.select({
    web: props.narrow ? styles.boxNarrow : styles.boxWide,
  });

  const { isTablet } = useDeviceInfo();

  return (
    <View
      testID={props.testID}
      style={[
        styles.box,
        additionalStyles,
        props.padded && styles.padding,
        props.padded && isTablet && styles.paddingTablet,
        props.style,
      ]}
      dataSet={props.dataSet}
    >
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  box: {
    backgroundColor: palette.white,
    borderRadius: 5,
    width: '100%',
    ...ifWeb({
      ...getShadow(),
      zIndex: 'auto',
    }),
  },
  boxWide: {
    maxWidth: 1280,
  },
  boxNarrow: {
    maxWidth: 620,
  },
  padding: {
    paddingVertical: 20,
    paddingHorizontal: 24,
  },
  paddingTablet: {
    paddingHorizontal: 32,
    paddingVertical: 28,
  },
});
