import { AnchorProvider } from '@nandorojo/anchor';
import NetInfo from '@react-native-community/netinfo';
import * as Sentry from '@sentry/react';
import { QueryClientProvider, onlineManager } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import { Platform, UIManager } from 'react-native';
import { Host as PortalHost } from 'react-native-portalize';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import { queryClient } from 'src/api/queryClient';
import { RootComponent } from 'src/components/RootComponent';
import { ServiceGate } from 'src/components/ServiceGate';
import {
  ModalPortal,
  BottomFixedElementsPortal,
  BottomStickyElementsPortal,
  AccountExpirationWarningPortal,
  TopPanelLeftActionPortal,
  TopPanelTitlePortal,
} from 'src/contexts/portals';
import { SentryService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { store } from 'src/state/store';

import { ReactQueryDevtools } from './components';
import GestureHandlerRootView from './components/GestureHandlerRootView';

SentryService.initialize();

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

onlineManager.setEventListener((setOnline) => {
  return NetInfo.addEventListener((state) => {
    setOnline(!!state.isConnected);
  });
});

export const App = () => (
  <Sentry.ErrorBoundary>
    <Provider store={store as Store}>
      <SafeAreaProvider>
        <AnchorProvider horizontal={false}>
          <ServiceGate>
            <QueryClientProvider client={queryClient}>
              <I18nextProvider i18n={i18n}>
                <ModalPortal.Provider>
                  <BottomStickyElementsPortal.Provider>
                    <BottomFixedElementsPortal.Provider>
                      <AccountExpirationWarningPortal.Provider>
                        <TopPanelTitlePortal.Provider>
                          <TopPanelLeftActionPortal.Provider>
                            <PortalHost>
                              <GestureHandlerRootView>
                                <RootComponent />
                              </GestureHandlerRootView>
                              <ReactQueryDevtools />
                            </PortalHost>
                          </TopPanelLeftActionPortal.Provider>
                        </TopPanelTitlePortal.Provider>
                      </AccountExpirationWarningPortal.Provider>
                    </BottomFixedElementsPortal.Provider>
                  </BottomStickyElementsPortal.Provider>
                </ModalPortal.Provider>
              </I18nextProvider>
            </QueryClientProvider>
          </ServiceGate>
        </AnchorProvider>
      </SafeAreaProvider>
    </Provider>
  </Sentry.ErrorBoundary>
);
