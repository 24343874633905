import React, { Suspense } from 'react';
import { StyleSheet } from 'react-native';

import { FullScreenTemplate, Container, LoadingIndicator } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { palette } from 'src/styles';

import { Calculator } from '../components';

type Props = ScreenProps<Route.Calculator>;

export const CalculatorScreen: React.FC<Props> = () => {
  const { isTabletApp } = useDeviceInfo();

  return (
    <FullScreenTemplate
      title={i18n.t('screens:calculator')}
      noScroll
      contentContainerStyle={isTabletApp && styles.tabletContentContainerStyle}
      showBackButton
    >
      <Container style={[styles.wrapper]} verticalPadding>
        <Suspense fallback={<LoadingIndicator />}>
          <Calculator />
        </Suspense>
      </Container>
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingHorizontal: '4%',
    paddingVertical: '5%',
    backgroundColor: palette.white,
  },
  tabletContentContainerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
