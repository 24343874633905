import _ from 'lodash-es';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { BaseModal } from 'src/components';
import { isDrugHandout } from 'src/helpers/content';
import { i18n } from 'src/locale';
import { palette } from 'src/styles';

import type { Handout } from '../../types';
import { HandoutCard } from '../HandoutCard';

interface Props {
  close: () => void;
  items: Handout[];
}

export const PrintableHandoutsModal: React.FC<Props> = ({ close, items }) => {
  const isDrugHandouts = isDrugHandout(items[0]);

  return (
    <BaseModal header={i18n.t('handouts:handouts')} close={close} testID="handouts-modal">
      <View style={styles.itemsWrapper}>
        {items.map((item, index) => (
          <HandoutCard
            item={item}
            location={isDrugHandouts ? 'drug-page' : 'dx-tx-page'}
            key={item.id}
            topBorder={index !== 0}
            bottomBorder={false}
          />
        ))}
      </View>
    </BaseModal>
  );
};

const styles = StyleSheet.create({
  itemsWrapper: {
    borderWidth: 1,
    borderColor: palette.grey2,
    borderRadius: 5,
    overflow: 'hidden',
  },
});
