import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useDeviceInfo } from 'src/hooks/useDeviceInfo';

import { Tile } from './Tile';

export interface TilesMenuItem extends React.ComponentProps<typeof Tile> {
  key: string;
}

interface Props {
  items: TilesMenuItem[][];
  testID?: string;
  style?: StyleProp<ViewStyle>;
}

const GAP = 24;

export const TilesMenu: React.FC<Props> = ({ items, testID, style }) => {
  const { isTablet } = useDeviceInfo();

  return (
    <View style={[styles.wrapper, style]} testID={testID}>
      {items.map((row, index) => (
        <View style={[styles.row, isTablet && styles.rowTablet]} key={index}>
          {row.map((item, index) => (
            <Tile
              {...item}
              key={item.key}
              wrapperStyle={[
                !isTablet && styles.itemMobile,
                isTablet && styles.itemTablet,
                index === row.length - 1 && styles.itemLast,
              ]}
              small={!isTablet}
            />
          ))}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: -GAP,
  },
  row: {
    flexDirection: 'column',
  },
  rowTablet: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  itemMobile: {
    width: '100%',
    marginRight: GAP,
    marginBottom: GAP,
  },
  itemTablet: {
    marginBottom: GAP,
    marginRight: GAP,
    flex: 1,
  },
  itemLast: {
    marginRight: 0,
  },
});
