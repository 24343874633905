import React, {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { View } from 'react-native';

import { isWeb } from 'src/helpers';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';

interface ContextValue {
  isSideBarOpen: boolean;
  setSideBarOpen: (state: boolean) => void;
  isSidebarPermanentlyVisible: boolean;
  sidebarRef: MutableRefObject<any>;
  menuTogglerButtonRef: MutableRefObject<any>;
}

const SideBarContext = createContext<ContextValue>({
  isSideBarOpen: false,
  setSideBarOpen: () => undefined,
  isSidebarPermanentlyVisible: false,
  sidebarRef: {
    current: null,
  },
  menuTogglerButtonRef: {
    current: null,
  },
});

export const SidebarContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const sidebarRef = useRef<View>(null);
  const menuTogglerButtonRef = useRef<View>(null);

  const { isSidebarPermanentlyVisible } = useWebAppLayout();

  const [isSideBarOpen, setSideBarOpen] = useState(isSidebarPermanentlyVisible);

  useLayoutEffect(() => {
    setSideBarOpen(isSidebarPermanentlyVisible);
  }, [isSidebarPermanentlyVisible]);

  useEffect(() => {
    if (isWeb) {
      const overflow = !isSidebarPermanentlyVisible && isSideBarOpen ? 'hidden' : 'unset';
      document.documentElement.style.overflowX = overflow;
      document.body.style.overflowX = overflow;
    }
  }, [isSideBarOpen, isSidebarPermanentlyVisible]);

  const contextValue = {
    isSideBarOpen,
    setSideBarOpen,
    isSidebarPermanentlyVisible,
    sidebarRef,
    menuTogglerButtonRef,
  };

  return <SideBarContext.Provider value={contextValue}>{children}</SideBarContext.Provider>;
};

export const useSideBarContext = () => useContext(SideBarContext);
