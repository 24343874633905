import { useQuery, useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { queryClient } from 'src/api/queryClient';
import { SelectOption, Error400Response } from 'src/constants/types';
import { getErrorMessageForApiError, getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { useIsSignedIn } from 'src/features/auth/hooks';
import { isStudentRecurlyPlan } from 'src/features/subscription/helpers';
import { RecurlyStudentPlan, RecurlyPlan } from 'src/features/subscription/types';
import { NotificationParams, showNotification } from 'src/helpers';
import { useCountriesList } from 'src/hooks/queries/countries';

import {
  fetchStudentsCountries,
  searchStudentsOrganizations,
  uploadStudentDocuments,
  verifyStudentStatus,
  getStudentVerificationDetails,
  getCurrentUserStudentVerificationDetails,
  skipSSOStep,
  fetchStudentSubscriptionEligibility,
  editStudentSubscriptionEligibility,
  verifyEmailLoopToken,
  skipEmailLoop,
  resendEmailLoopToken,
} from '../api';
import { studentVerificationQueryKeys } from '../constants';
import { useStudentVerificationContext } from '../context/StudentVerificationContext';
import type {
  StudentDocumentParams,
  StudentVerificationParams,
  StudentVerificationDetailsResponse,
  StudentVerificationDetails,
  StudentSubscriptionEligibilityBase,
  StudentEmailLoopVerificationParams,
} from '../types';

export const useStudentsCountries = (planCode: RecurlyPlan | null) => {
  const { data: countriesList, isLoading: isLoadingCountriesList, countriesOptions } = useCountriesList();
  const queryData = useQuery({
    queryKey: ['students-countries', planCode],
    queryFn: () => fetchStudentsCountries(planCode! as RecurlyStudentPlan),
    enabled: !!planCode && isStudentRecurlyPlan(planCode),
  });

  return {
    ...queryData,
    isLoading: queryData.isLoading || isLoadingCountriesList,
    data: countriesList?.filter(({ code }) => queryData.data?.results.includes(code)),
    countriesOptions: countriesOptions.filter(({ value }) => queryData.data?.results.includes(value)),
  };
};

export const useStudentsOrganizationsSearch = (
  planCode: RecurlyPlan,
  query?: string,
  country?: string | null,
) => {
  const queryData = useQuery({
    queryKey: ['student-organizations', planCode, query, country],
    queryFn: () =>
      searchStudentsOrganizations({
        planCode: planCode as RecurlyStudentPlan,
        country: country!,
        query: query!,
      }),
    enabled: !!country && isStudentRecurlyPlan(planCode),
  });

  const organizationsOptions: SelectOption[] = useMemo(
    () => queryData.data?.results.map(({ id, name }) => ({ value: id, label: name })) || [],
    [queryData.data],
  );

  return { ...queryData, organizationsOptions };
};

export const useStudentDocumentsUpload = () => {
  const isSignedIn = useIsSignedIn();

  return useMutation({
    mutationFn: (params: StudentDocumentParams) => uploadStudentDocuments(params, isSignedIn),
  });
};

export const useVerifyStudentStatus = () => {
  const isSignedIn = useIsSignedIn();
  const { processType } = useStudentVerificationContext();

  return useMutation({
    mutationFn: (params: StudentVerificationParams) =>
      verifyStudentStatus({ ...params, isRenewal: processType === 'reverification' }, isSignedIn),
  });
};

/**
 * Get Student Verficiation details based on verificationId
 */
export const useStudentVerificationDetails = (verificationId: string | undefined) => {
  const isSignedIn = useIsSignedIn();

  const { t } = useTranslation('validation');

  const getErrorMessage = (err: AxiosError<Error400Response<unknown>>): NotificationParams => {
    const responseData = err?.response?.data;
    if (!responseData?.detail || !axios.isAxiosError(err)) {
      return { type: 'error', title: t('studentVerification.not_found') };
    }

    const { code } = responseData.detail;
    // if the user has successfully used this code, then this is not an error
    const type = code === 'student_verification_already_used' ? 'success' : 'error';
    return { type, title: getErrorMessageForApiError(responseData?.detail, 'studentVerification') };
  };

  const queryResult = useQuery<
    StudentVerificationDetailsResponse,
    AxiosError<Error400Response<unknown>>,
    StudentVerificationDetails | null,
    any
  >({
    queryKey: ['student-verification-details', verificationId],
    queryFn: () => getStudentVerificationDetails(verificationId!),
    enabled: !!verificationId && !isSignedIn,
    retryOnMount: false,
    select: (data) => ({
      ...data,
      verificationId: verificationId!,
    }),
  });

  const errorMessage = queryResult.error ? getErrorMessage(queryResult.error) : null;

  useEffect(() => {
    if (errorMessage) {
      showNotification({
        ...errorMessage,
        autoHide: false,
      });
    }
  }, [errorMessage]);

  return queryResult;
};

export const useCurrentUserStudentVerification = () => {
  const isSignedIn = useIsSignedIn();

  return useQuery({
    queryKey: ['current-student-verification'],
    queryFn: getCurrentUserStudentVerificationDetails,
    enabled: isSignedIn,
  });
};

export const useSkipSSOStep = () => {
  const isSignedIn = useIsSignedIn();

  return useMutation({
    mutationFn: (verificationId: string) => skipSSOStep(verificationId, isSignedIn),
  });
};

export const useStudentSubscriptionEligibility = () => {
  return useQuery({
    queryKey: studentVerificationQueryKeys.studentSubscriptionEligibility,
    queryFn: fetchStudentSubscriptionEligibility,
  });
};

export const useUpdateStudentSubscriptionEligibility = () => {
  return useMutation({
    mutationFn: (data: StudentSubscriptionEligibilityBase) => editStudentSubscriptionEligibility(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: studentVerificationQueryKeys.studentSubscriptionEligibility,
      });
      showNotification({ type: 'success' });
    },
  });
};

export const useEmailLoopVerification = () => {
  const isSignedIn = useIsSignedIn();
  const { onEmailTokenVerificationSuccess } = useStudentVerificationContext();

  return useMutation({
    mutationFn: (params: StudentEmailLoopVerificationParams) => verifyEmailLoopToken(params, isSignedIn),
    onSuccess: () => {
      onEmailTokenVerificationSuccess();
    },
  });
};

export const useSkipEmailLoop = () => {
  const isSignedIn = useIsSignedIn();
  const { onEmailTokenVerificationSkip } = useStudentVerificationContext();

  return useMutation({
    mutationFn: (verificationId: string) => skipEmailLoop(verificationId, isSignedIn),
    onSuccess: () => {
      onEmailTokenVerificationSkip();
    },
    onError: (error) => {
      const { detail } = getMessagesFromErrorResponse(error);
      showNotification({ type: 'error', title: detail });
    },
  });
};

export const useEmailLoopTokenResend = () => {
  const isSignedIn = useIsSignedIn();

  return useMutation({
    mutationFn: (verificationId: string) => resendEmailLoopToken(verificationId, isSignedIn),
    onError: (error) => {
      const { detail } = getMessagesFromErrorResponse(error);
      showNotification({ type: 'error', title: detail });
    },
  });
};
