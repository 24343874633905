import { client } from 'src/api/client';
import { CONTENT_LIST_ITEMS_PER_PAGE_NATIVE } from 'src/constants/constants';
import { EmptyResponse } from 'src/constants/types';
import { convertObjectToSnakeCase } from 'src/helpers';

import type {
  DrugSuggestionsListResponse,
  DrugSuggestionListsParams,
  FindDrugByNameResponse,
} from './types';

export const fetchDrugSuggestionsList = async (params: DrugSuggestionListsParams) => {
  const response = await client.request<DrugSuggestionsListResponse | EmptyResponse>({
    url: `/search/monograph/suggestions/`,
    params: convertObjectToSnakeCase({
      ...params,
      selectedDrugs: params.selectedDrugs?.join(','),
      pageSize: CONTENT_LIST_ITEMS_PER_PAGE_NATIVE,
    }),
    apiVersion: 2,
  });

  const { results, count, next } = response.data;

  return {
    results: results || [],
    count,
    nextPage: next ? params.page + 1 : undefined,
  };
};

export const findDrugByName = async (name: string) => {
  const response = await client.request<FindDrugByNameResponse | EmptyResponse>({
    method: 'GET',
    url: `/contents/monograph/find/`,
    params: { name },
  });

  return response.data;
};
