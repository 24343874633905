import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FullScreenTemplate } from 'src/components';
import { useIsSignedIn } from 'src/features/auth/hooks';
import { showNotification } from 'src/helpers/notifications';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { setRedirectTo } from 'src/navigation/utils';

import { isPromotionForExistingSubscribersOnlyError } from '../helpers';
import { usePromotion } from '../hooks';
import { clearPromotionType, setPromotionParams } from '../state';

type Props = ScreenProps<Route.PromotionLanding>;

export const PromotionLandingScreen: React.FC<Props> = ({ route: { params }, navigation }) => {
  const isSignedIn = useIsSignedIn();
  const dispatch = useDispatch();
  const { data, error } = usePromotion(params);

  useEffect(() => {
    if (!params.promoId || !params.userID) {
      navigation.replace(Route.Home);
      showNotification({
        type: 'error',
        title: i18n.t('validation:promotion:not_found'),
        autoHide: false,
      });
      return;
    }

    dispatch(setPromotionParams(params));
    dispatch(clearPromotionType());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      if (isSignedIn) {
        navigation.replace(Route.SubscriptionDetails);
      } else {
        if (isPromotionForExistingSubscribersOnlyError(error)) {
          /**
           * after signing in user will be redirected here again to fetch the promo data
           * as they may not have access to Subscription Details page (and wont be able to see any errors)
           * */
          setRedirectTo(Route.PromotionLanding, params);
        }
        navigation.replace(Route.SignIn, {});
      }
    }

    if (data) {
      if (params.isSubscribed) {
        if (isSignedIn) {
          navigation.replace(Route.SubscriptionDetails);
        } else {
          setRedirectTo(Route.SubscriptionDetails);
          navigation.replace(Route.SignIn, {});
        }
      } else {
        if (isSignedIn) {
          navigation.replace(Route.SubscriptionDetails);
        } else {
          navigation.replace(Route.SignUp, {});
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return <FullScreenTemplate isLoading />;
};
