import { Suspense, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Icon, StyledText, Pressable, LoadingIndicator } from 'src/components';
import { Calculator, CalculatorRef } from 'src/features/calculator';
import { i18n } from 'src/locale';
import { palette, typography, ifWeb } from 'src/styles';

interface Props {
  onOpen?(): void;
}

export const CalculatorItem: React.FC<Props> = ({ onOpen }) => {
  const [isOpen, setOpen] = useState(false);
  const calculatorRef = useRef<CalculatorRef>(null);

  const onPress = () => {
    if (!isOpen) {
      onOpen?.();
    }
    setOpen(!isOpen);
    calculatorRef.current?.focus();
  };

  return (
    <>
      <Pressable onPress={onPress} testID="calculator-item">
        {(hovered) => (
          <View style={[styles.item, styles.itemSeparator]}>
            <View style={styles.itemInnerWrapper}>
              <StyledText style={[styles.itemText, hovered && styles.itemTextHover]}>
                {i18n.t('tools:calculator')}
              </StyledText>
              <View
                style={[
                  { transform: [{ rotate: isOpen ? '-90deg' : '90deg' }] },
                  styles.itemChevronWrapper,
                ]}
              >
                <Icon name="chevron-right" width={10} color={palette.navy} />
              </View>
            </View>
          </View>
        )}
      </Pressable>
      {isOpen && (
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <Suspense fallback={<LoadingIndicator />}>
              <Calculator ref={calculatorRef} />
            </Suspense>
          </View>
        </View>
      )}
    </>
  );
};
const styles = StyleSheet.create({
  itemSeparator: {
    borderTopWidth: 1,
    borderColor: palette.grey2,
  },
  item: {
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  itemInnerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemText: {
    ...typography.body3Bold,
    color: palette.navy,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  itemTextHover: {
    color: palette.blue,
  },
  itemChevronWrapper: {
    transitionProperty: 'transform',
  },
  contentWrapper: {
    overflowY: 'hidden',
  },
  content: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    height: 500,
  },
});
