import { FlaggedFeature } from 'src/features/auth/enums';
import { Route } from 'src/navigation';

import { ContentType } from '../constants';
import { ContentConfig, createContentsObject } from './utils';

export const DrugConfig = new ContentConfig({
  contentTypeSimplified: 'drug',
  contentTypes: [ContentType.Drug],
  hasNotes: true,
  isPro: false,
  routes: {
    item: Route.DrugItem,
    list: Route.DrugsList,
  },
});

export const AppendixConfig = new ContentConfig({
  contentTypeSimplified: 'appendix',
  contentTypes: [ContentType.Appendix],
  hasNotes: true,
  isPro: false,
  routes: {
    item: Route.AppendixItem,
    list: Route.AppendixList,
  },
});

export const DrugHandoutConfig = new ContentConfig({
  contentTypeSimplified: 'drug-handout',
  contentTypes: [
    ContentType.DrugHandout,
    ContentType.VeterinaryMedicationGuide,
    ContentType.DrugAdministrationHandouts,
  ],
  hasNotes: false,
  isPro: false,
  routes: {
    item: Route.DrugHandoutItem,
    list: Route.DrugHandoutsList,
  },
  additionalAccessConfig: {
    enabledForLimitedProUsers: true,
  },
});

export const ClinicalHandoutConfig = new ContentConfig({
  contentTypeSimplified: 'clinical-handout',
  contentTypes: [
    ContentType.HandoutProcedure,
    ContentType.HandoutVaccine,
    ContentType.HandoutClinicalConditions,
    ContentType.HandoutMisc,
  ],
  hasNotes: false,
  isPro: true,
  routes: {
    item: Route.ClinicalHandoutItem,
    list: Route.ClinicalHandoutsList,
  },
  additionalAccessConfig: {
    enabledForLimitedProUsers: true,
    featureFlag: FlaggedFeature.PatientGuides,
  },
});

export const DDxConfig = new ContentConfig({
  contentTypes: [ContentType.DDx],
  contentTypeSimplified: 'ddx',
  hasNotes: true,
  isPro: true,
  routes: {
    item: Route.DDxItem,
    list: Route.DDxList,
  },
});

export const DxTxConfig = new ContentConfig({
  contentTypes: [ContentType.DxTxCondition, ContentType.DxTxMisc, ContentType.DxTxSignSituation],
  contentTypeSimplified: 'dx-tx',
  hasNotes: true,
  isPro: true,
  routes: {
    item: Route.DxTxItem,
    list: Route.DxTxList,
  },
  additionalAccessConfig: {
    featureFlag: FlaggedFeature.ClinicalBrief,
  },
});
export type DxTxConfig = typeof DxTxConfig;

export const AlgorithmConfig = new ContentConfig({
  contentTypes: [ContentType.Algorithm],
  contentTypeSimplified: 'algorithm',
  hasNotes: false,
  isPro: true,
  routes: {
    item: Route.AlgorithmItem,
    list: Route.Algorithms,
  },
  additionalAccessConfig: {
    featureFlag: FlaggedFeature.Algorithm,
  },
});

export type DrugConfig = typeof DrugConfig;
export type AlgorithmConfig = typeof AlgorithmConfig;
export type DDxConfig = typeof DDxConfig;
export type ClinicalHandoutConfig = typeof ClinicalHandoutConfig;
export type AppendixConfig = typeof AppendixConfig;
export type DrugHandoutConfig = typeof DrugHandoutConfig;

const contents = [
  DrugConfig,
  AppendixConfig,
  DrugHandoutConfig,
  ClinicalHandoutConfig,
  DDxConfig,
  DxTxConfig,
  AlgorithmConfig,
] as const;

export const Contents = createContentsObject(contents);
export { type ContentConfig };
