import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { BaseCard, Box, PrimaryButton, LoadingIndicator, LoadingIndicatorBox } from 'src/components';
import { ModalPortal } from 'src/contexts/portals';
import { QueryErrorBanner } from 'src/errorHandling/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { palette } from 'src/styles';

import { GroupMembersCards } from './GroupMembersCards';
import { GroupMembersTable } from './GroupMembersTable';
import { GroupName } from './GroupName';
import { InviteNewUsersModal } from './InviteNewUsers/InviteNewUsersModal';
import { LicencesInfo } from './LicencesInfo';
import { useLicencesInfo, useAdminGroupInfo } from '../../hooks';

interface Props {}

export const MyGroup: React.FC<Props> = () => {
  const { isTablet } = useDeviceInfo();
  const { data: groupData, isFetching, error, isLoading, fetchStatus } = useAdminGroupInfo();
  const licences = useLicencesInfo();

  const [inviteNewUsersModal, setInviteNewUsersModal] = useState(false);

  const {
    permissions: { hasSupportStaffFeatureEnabled },
  } = useUserInfo();

  const handleInviteNewUsersPress = () => {
    setInviteNewUsersModal(true);
  };
  const closeInviteNewUsersModal = () => {
    setInviteNewUsersModal(false);
  };

  const shouldDisableInvitingNewUsers =
    licences.isLoading ||
    licences.isError ||
    (!licences.data?.areLicencesAvailable &&
      (!hasSupportStaffFeatureEnabled || groupData?.hasSupportStaffRole));

  const GroupMembersComponent = isTablet ? GroupMembersTable : GroupMembersCards;

  return (
    <Box>
      <BaseCard testID="my-group-container">
        <QueryErrorBanner isDataAvailable={!!groupData} error={error} fetchStatus={fetchStatus} />
        {isFetching && isLoading ? (
          <LoadingIndicator displayStandbyText style={styles.loadingIndicator} />
        ) : groupData ? (
          <>
            <View style={styles.header}>
              <GroupName name={groupData.name} />
            </View>
            <View
              style={[styles.licensesInfoWrapper, isTablet && styles.licensesInfoWrapperTablet]}
              testID="modal-invite-new-users"
            >
              <PrimaryButton
                title={i18n.t('adminDashboard:inviteNewUsers')}
                size="small"
                onPress={handleInviteNewUsersPress}
                disabled={shouldDisableInvitingNewUsers}
                loading={licences.isLoading}
                testID="invite-new-users-btn"
              />
              <View
                style={[styles.licensesInfoColumnRight, isTablet && styles.licensesInfoColumnRightTablet]}
              >
                <LicencesInfo />
              </View>
            </View>
            <LoadingIndicatorBox isLoading={isFetching}>
              <GroupMembersComponent
                data={groupData!.members}
                isMixedGroup={!!licences.data?.isMixedGroup}
              />
            </LoadingIndicatorBox>
          </>
        ) : null}
      </BaseCard>
      {inviteNewUsersModal && (
        <ModalPortal>
          <InviteNewUsersModal close={closeInviteNewUsersModal} />
        </ModalPortal>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  header: {
    borderBottomWidth: 1,
    borderBottomColor: palette.grey2,
    paddingBottom: 20,
    marginBottom: 24,
  },
  licensesInfoWrapper: {
    marginBottom: 20,
    flexDirection: 'column-reverse',
  },
  licensesInfoWrapperTablet: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  licensesInfoColumnRight: {
    flexDirection: 'column',
    marginBottom: 20,
  },
  licensesInfoColumnRightTablet: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 0,
    flex: 1,
    justifyContent: 'flex-end',
    marginLeft: 10,
  },
  loadingIndicator: {
    height: 200,
    justifyContent: 'center',
  },
});
