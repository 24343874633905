import React from 'react';
import { StyleSheet, View } from 'react-native';

import { GradientShadow } from 'src/components/GradientShadow.web';
import { NavigationMenuItem } from 'src/navigation/types';
import { ifWeb, NAVBAR_HEIGHT, palette } from 'src/styles';

import { MenuDesktop } from './MenuDesktop';
import { MenuLogo } from '../../../../MenuLogo';

interface Props {
  items: NavigationMenuItem[];
}

export const NavigationDesktop: React.FC<Props> = ({ items }) => (
  <View style={styles.container}>
    <GradientShadow style={styles.shadow} inverted />
    <MenuLogo />
    <View style={styles.rightColumn} testID="navigation-menu">
      <MenuDesktop items={items} />
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    height: NAVBAR_HEIGHT,
    backgroundColor: palette.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    ...ifWeb({
      position: 'static',
      zIndex: 'auto',
    }),
  },
  rightColumn: {
    flexDirection: 'row',
    height: '100%',
    ...ifWeb({
      position: 'static',
      zIndex: 'auto',
    }),
  },
  shadow: {
    top: '17%',
  },
});
