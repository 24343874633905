import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Icon } from 'src/components/Icon/Icon';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { Pressable } from 'src/components/Pressable';
import { StyledText } from 'src/components/StyledText';
import { BlockMessage } from 'src/constants/types';
import { isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { WebSocketService } from 'src/services';
import { palette, typography } from 'src/styles';

import { Device } from '../types';

type Props = {
  sessionInfo: Device;
  disabled: boolean;
  onPress: () => void;
};

export const DeviceCard: React.FC<Props> = ({ sessionInfo, disabled, onPress }) => {
  const { isSmallMobile } = useDeviceInfo();
  const [isLoading, setLoading] = useState(false);

  const handleBlockDevice = () => {
    onPress();
    setLoading(true);
    const message: BlockMessage = { action: 'block', authId: sessionInfo.id };
    WebSocketService.send(message);
  };

  return (
    <Pressable onPress={handleBlockDevice} disabled={disabled}>
      {(hovered) => (
        <View style={[styles.row, styles.container, hovered && !disabled && styles.cardHovered]}>
          <View style={styles.row}>
            <Icon
              style={styles.deviceIcon}
              name={sessionInfo.browserName === 'App' ? 'devicePhone' : 'deviceComputer'}
              width={52}
            />
            <View style={(!isSmallMobile || !isWeb) && styles.row}>
              <StyledText style={[typography.weightSemiBold]}>{`${sessionInfo.systemName} `}</StyledText>
              <StyledText singleLine ellipsizeMode="clip">
                {`-  ${sessionInfo.browserName}`}
              </StyledText>
            </View>
          </View>
          {!isLoading ? (
            <Icon name="pause" width={24} color={disabled ? palette.grey2 : palette.blue} />
          ) : (
            <LoadingIndicator size="small" />
          )}
        </View>
      )}
    </Pressable>
  );
};

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    height: 80,
    backgroundColor: palette.white,
    borderWidth: 1,
    borderColor: palette.grey2,
    marginTop: 20,
    padding: 16,
    justifyContent: 'space-between',
  },
  cardHovered: {
    borderColor: palette.blue,
  },
  deviceIcon: {
    marginRight: 20,
  },
});
