import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { useIsSignedIn } from 'src/features/auth/hooks';
import { isWeb } from 'src/helpers';
import { Route } from 'src/navigation';
import { useRoute, useNavigation } from 'src/navigation/hooks';
import { RouteProp } from 'src/navigation/types';
import { zindex } from 'src/styles';

import { SearchBox } from './SearchBox';
import { webScreensWithSearchBar, MIN_SEARCH_QUERY_LENGTH } from '../constants';
import { SearchContentTypeFilter } from '../enums';
import { useSearchParams } from '../hooks';
import { setMainFilter } from '../state';

interface Props {
  outlined?: boolean;
}

export const MainSearchBox: React.FC<Props> = ({ outlined }) => {
  const dispatch = useDispatch();
  const route = useRoute();
  const navigation = useNavigation();
  const isSignedIn = useIsSignedIn();
  const [prevRouteName, setPrevRouteName] = useState<Route>(route.name as Route);

  useEffect(() => {
    setPrevRouteName((prevValue) => (route.name !== Route.SearchWeb ? (route.name as Route) : prevValue));
  }, [route.name]);

  const { mainFilter, query, placeholder } = useSearchParams();

  const initialFilter =
    route.name === Route.Search
      ? (route as RouteProp<Route.Search>).params.initialFilter
      : SearchContentTypeFilter.All;

  useEffect(() => {
    if (!isWeb) return;
    const isDisplayed = isSignedIn && webScreensWithSearchBar.includes(route.name as Route);
    isDisplayed && dispatch(setMainFilter(initialFilter));
  }, [route.name, dispatch, isSignedIn, initialFilter]);

  useEffect(() => {
    if (!isSignedIn) return;
    if (query.length >= MIN_SEARCH_QUERY_LENGTH) {
      if (isWeb) {
        navigation.navigate(Route.SearchWeb, {
          contentType: mainFilter,
          query,
          route: prevRouteName,
        });
      }
    }
  }, [query, dispatch, mainFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <View style={[styles.wrapper]}>
      <SearchBox placeholder={placeholder} isExpanded={false} isSearchDisplayed outlined={outlined} />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    zIndex: zindex.searchBox,
    alignItems: 'center',
  },
});
