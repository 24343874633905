import { SelectOptions } from 'src/constants/types';
import { i18n } from 'src/locale';

import { MIN_USER_AGE } from '../../constants';

export const bornYear: SelectOptions = (function () {
  const currentYear = new Date().getFullYear();
  const minAge = MIN_USER_AGE;
  const numOfOptions = 74;
  const yearOptions = Array.from(Array(numOfOptions)).map((_, i) => {
    const year = String(currentYear - minAge - i);
    return { label: year, value: year };
  });
  return [{ label: i18n.t('user:bornYearNull'), value: 'NULL' }, ...yearOptions];
})();
