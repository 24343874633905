import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { RadioGroup, StyledText } from 'src/components';
import { FlaggedFeature } from 'src/features/auth/enums';
import { useUserInfo } from 'src/features/auth/hooks';
import { palette, typography } from 'src/styles';

import { HandoutsLanguageType } from '../types';

interface Props {
  value: HandoutsLanguageType;
  onChange(value: HandoutsLanguageType): void;
  disabled?: boolean;
}

export const HandoutsLanguageSelector: React.FC<Props> = ({ value, onChange, disabled }) => {
  const { t } = useTranslation('handouts');
  const { hasAccessToFlaggedFeature } = useUserInfo();

  if (!hasAccessToFlaggedFeature(FlaggedFeature.SpanishHandouts)) {
    return null;
  }

  return (
    <View style={styles.wrapper} testID="language-selector">
      <StyledText style={styles.header}>{t('handoutLanguage')}</StyledText>
      <StyledText style={styles.disclaimer}>{t('handoutLanguageDisclaimer')}</StyledText>
      <RadioGroup
        disabled={disabled}
        items={[
          {
            title: t('handoutsLanguage.english'),
            value: HandoutsLanguageType.English,
            testID: 'english-handout-radio',
          },
          {
            title: t('handoutsLanguage.spanish'),
            value: HandoutsLanguageType.Spanish,
            testID: 'spanish-handout-radio',
          },
          {
            title: t('handoutsLanguage.both'),
            value: HandoutsLanguageType.SpanishEnglish,
            testID: 'spanish-english-handout-radio',
          },
        ]}
        value={value}
        onChange={onChange}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginTop: 20,
  },
  header: {
    ...typography.body2SemiBold,
    color: palette.navy,
    marginBottom: 2,
  },
  disclaimer: {
    color: palette.navy,
    fontSize: 12,
    marginBottom: 10,
  },
});
