import { useTranslation } from 'react-i18next';
import { Animated, StyleSheet } from 'react-native';

import { BaseButton, TextArea } from 'src/components';

import { useHandoutsPrintAndDownload } from '../hooks';
import { useHandoutMarginAnimation } from '../hooks/useHandoutMarginAnimation';
import type { ShareHandoutsFormProps } from '../types';

interface Props extends ShareHandoutsFormProps {}

export const PrintHandouts: React.FC<Props> = ({ onShareSuccess, LanguageSelector, language }) => {
  const { t } = useTranslation('handouts');
  const { generateAndPrint, loading, noteValue, selectedHandouts, setNoteValue } =
    useHandoutsPrintAndDownload({ action: 'print', onSuccess: onShareSuccess, language });
  const marginAnimation = useHandoutMarginAnimation();

  return (
    <Animated.View style={{ marginBottom: marginAnimation }} testID="print-handouts-form">
      <TextArea
        label={t('addInstructions')}
        placeholder={t('addInstructionsPlaceholder')}
        readOnly={loading}
        onChangeText={(note: string) => setNoteValue(note)}
        value={noteValue}
        testID="input-print-content"
      />
      {LanguageSelector}
      <BaseButton
        title={t('print', { count: selectedHandouts.length })}
        variant="gradient"
        icon="chevron-right-circle"
        containerStyle={styles.submitButton}
        titleStyle={styles.submitButtonTitle}
        loading={loading}
        onPress={generateAndPrint}
        testID="print-handout-btn"
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  submitButton: {
    alignSelf: 'flex-start',
    marginTop: 28,
  },
  submitButtonTitle: {
    textTransform: 'none',
  },
});
