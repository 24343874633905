import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { FullScreenTemplate, Container } from 'src/components';
import {
  AlgorithmConfig,
  AppendixConfig,
  ClinicalHandoutConfig,
  ContentConfig,
  DDxConfig,
  DrugConfig,
  DrugHandoutConfig,
  DxTxConfig,
} from 'src/features/content/config';
import { MixpanelEvent, type MixpanelFeatureButton, MixpanelService } from 'src/features/tracking';
import { useDeviceInfo, useScreenAccess } from 'src/hooks';
import { Route } from 'src/navigation';
import { ScreenProps, MobileNavMenuItemProps } from 'src/navigation/types';

import { MobileNavMenuItem } from '../components';

interface Props extends ScreenProps<Route.Features> {}

export const FeaturesScreen: React.FC<Props> = () => {
  const { t } = useTranslation('features');
  const { isScreenAccessible } = useScreenAccess();
  const { isTabletApp } = useDeviceInfo();

  const trackMixpanelEvent = (tabName: MixpanelFeatureButton) => {
    MixpanelService.track(MixpanelEvent.FeatureButtonClicked, { 'Feature Name': tabName });
  };

  const items: MobileNavMenuItemProps[] = [
    {
      title: t('dxTx'),
      description: t('clinicalBriefsDescription'),
      ...getContentItemProps(DxTxConfig),
      titleStyle: styles.transformNone,
      icon: 'virus',
      onPress: () => trackMixpanelEvent('Dx & Tx'),
    },
    {
      title: t('ddx'),
      description: t('ddxDescription'),
      ...getContentItemProps(DDxConfig),
      titleStyle: styles.transformNone,
      icon: 'ddx',
      onPress: () => trackMixpanelEvent('DDx'),
    },
    {
      title: t('drugs'),
      description: t('drugsDescription'),
      ...getContentItemProps(DrugConfig),
      icon: 'drug-monograph',
      onPress: () => trackMixpanelEvent('Drugs'),
    },
    {
      title: t('dic'),
      description: t('dicDescription'),
      route: Route.DIC,
      icon: 'dic',
      onPress: () => trackMixpanelEvent('Interaction Checker'),
    },
    {
      title: t('algorithms'),
      description: t('algorithmsDescription'),
      ...getContentItemProps(AlgorithmConfig),
      icon: 'algorithm',
      onPress: () => trackMixpanelEvent('Algorithms'),
    },
    {
      title: t('clinicalHandouts'),
      description: t('clinicalHandoutsDescription'),
      ...getContentItemProps(ClinicalHandoutConfig),
      icon: 'stethoscope-text',
      onPress: () => trackMixpanelEvent('Clinical Handouts'),
    },
    {
      title: t('drugHandouts'),
      description: t('drugHandoutsDescription'),
      ...getContentItemProps(DrugHandoutConfig),
      icon: 'medication-guide',
      onPress: () => trackMixpanelEvent('Drug Handouts'),
    },
    {
      title: t('appendix'),
      description: t('appendixDescription'),
      ...getContentItemProps(AppendixConfig),
      icon: 'document-attachment',
      onPress: () => trackMixpanelEvent('Appendix'),
    },
  ];

  return (
    <FullScreenTemplate title={t('header')}>
      <Container verticalPadding={isTabletApp} fullWidthOnMobile>
        {items
          .filter((item) => isScreenAccessible(item.route))
          .map((item) => (
            <MobileNavMenuItem {...item} key={item.title} />
          ))}
      </Container>
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  transformNone: {
    textTransform: 'none',
  },
});

function getContentItemProps(
  contentConfig: ContentConfig,
): Pick<MobileNavMenuItemProps, 'route' | 'isPro'> {
  return {
    route: contentConfig.routes.list,
    isPro: contentConfig.isPro,
  };
}
