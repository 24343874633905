import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { MenuTogglerButton } from 'src/components';
import { GradientShadow } from 'src/components/GradientShadow.web';
import { TopPanelLeftActionPortal, TopPanelTitlePortal } from 'src/contexts/portals';
import { LaunchpadMenuDropdown } from 'src/features/launchpad/components';
import { MainSearchBox } from 'src/features/search/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import { useSideBarContext } from 'src/navigation/contexts';
import { TOP_NAV_PANEL_HEIGHT, palette, SIDEBAR_WIDTH } from 'src/styles';

import { AccountMenuDropdown } from '../AccountMenuDropdown';
import { MenuLogo } from '../MenuLogo';
import { ProPromoButton } from '../SideBar/ProPromoButton';

const LOGO_CONTAINER_HORIZONTAL_PADDING = 18;
const MOBILE_PADDING_HORIZONTAL = 16;
const TABLET_PADDING_HORIZONTAL = 24;

export const UserTopPanel: React.FC = () => {
  const [isLaunchpadDropdownOpen, setLaunchpadDropdownOpen] = useState(false);
  const [isAccountDropdownOpen, setAccountDropdownOpen] = useState(false);

  const { isSideBarOpen, setSideBarOpen } = useSideBarContext();
  const { isTopSearchBarDisplayed, isSidebarPermanentlyVisible, isSidebarAvailable } = useWebAppLayout();
  const { isTablet, isDesktop } = useDeviceInfo();

  const toggleLaunchpadDropdown = () => {
    setLaunchpadDropdownOpen((prevState) => !prevState);
    setAccountDropdownOpen(false);
  };

  const toggleAccountDropdown = () => {
    setAccountDropdownOpen((prevState) => !prevState);
    setLaunchpadDropdownOpen(false);
  };

  if (isDesktop) {
    return (
      <View style={[styles.wrapper, styles.desktopWrapper]} testID="page-header">
        <GradientShadow style={styles.topUserPanelShadow} inverted />
        {isSidebarPermanentlyVisible && (
          <View style={styles.logoContainer}>
            <MenuLogo />
            <ProPromoButton shadow style={styles.proButton} />
            <GradientShadow style={styles.bottomUserPanelShadow} />
          </View>
        )}

        <View style={styles.titleBar}>
          <View style={styles.titleBarSideSlot}>
            {isSidebarPermanentlyVisible ? <TopPanelLeftActionPortal.Outlet /> : <MenuLogo />}
          </View>
          <View style={styles.titlePortal}>
            <TopPanelTitlePortal.Outlet />
          </View>
          <View style={[styles.titleBarSideSlot, styles.rightSlot]}>
            <View style={styles.launchpadWrapper}>
              <LaunchpadMenuDropdown
                isDropdownOpen={isLaunchpadDropdownOpen}
                setDropdownOpen={setLaunchpadDropdownOpen}
                toggleDropdown={toggleLaunchpadDropdown}
              />
            </View>
            <AccountMenuDropdown
              isDropdownOpen={isAccountDropdownOpen}
              setDropdownOpen={setAccountDropdownOpen}
              toggleDropdown={toggleAccountDropdown}
            />
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.wrapper, styles.mobileWrapper, isTablet && styles.tabletWrapper]}>
      <View style={styles.upperRow}>
        <View style={styles.titleBarSideSlot}>
          {isSidebarAvailable && (
            <MenuTogglerButton
              isOpen={isSideBarOpen}
              onPress={() => setSideBarOpen(!isSideBarOpen)}
              accessibilityLabel={isSideBarOpen ? 'Close Menu' : 'Open Menu'}
            />
          )}
        </View>
        <MenuLogo />
        <View style={[styles.titleBarSideSlot, styles.rightSlot]}>
          <View style={styles.launchpadWrapperMobile}>
            <LaunchpadMenuDropdown
              isDropdownOpen={isLaunchpadDropdownOpen}
              setDropdownOpen={setLaunchpadDropdownOpen}
              toggleDropdown={toggleLaunchpadDropdown}
            />
          </View>
          <AccountMenuDropdown
            isDropdownOpen={isAccountDropdownOpen}
            setDropdownOpen={setAccountDropdownOpen}
            toggleDropdown={toggleAccountDropdown}
          />
        </View>
      </View>
      {isTopSearchBarDisplayed && (
        <View style={styles.searchBoxWrapper}>
          <MainSearchBox outlined />
        </View>
      )}
      {isSideBarOpen && (
        <GradientShadow
          style={[styles.bottomUserPanelShadowMobile, isTablet && styles.bottomUserPanelShadowTablet]}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: palette.white,
  },
  mobileWrapper: {
    backgroundColor: palette.white,
    paddingHorizontal: MOBILE_PADDING_HORIZONTAL,
    zIndex: 1,
  },
  tabletWrapper: {
    paddingHorizontal: TABLET_PADDING_HORIZONTAL,
  },
  upperRow: {
    height: TOP_NAV_PANEL_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1,
  },
  searchBoxWrapper: {
    paddingBottom: 12,
  },
  desktopWrapper: {
    flexDirection: 'row',
  },
  logoContainer: {
    width: SIDEBAR_WIDTH,
    borderRightWidth: 1,
    borderRightColor: palette.grey2,
    paddingHorizontal: LOGO_CONTAINER_HORIZONTAL_PADDING,
    paddingVertical: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  proButton: {
    height: 40,
  },
  titleBar: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    height: TOP_NAV_PANEL_HEIGHT,
    paddingHorizontal: 24,
    zIndex: 2,
  },
  titlePortal: {
    flex: 1,
    minWidth: 490,
    alignItems: 'center',
  },
  titleBarSideSlot: {
    flex: 1,
    flexDirection: 'row',
  },
  rightSlot: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  launchpadWrapper: {
    marginRight: 12,
  },
  launchpadWrapperMobile: {
    marginRight: 8,
  },
  topUserPanelShadow: {
    top: '-25%',
  },
  bottomUserPanelShadow: {
    width: SIDEBAR_WIDTH,
    marginHorizontal: -LOGO_CONTAINER_HORIZONTAL_PADDING,
  },
  bottomUserPanelShadowMobile: {
    width: SIDEBAR_WIDTH,
    marginHorizontal: -MOBILE_PADDING_HORIZONTAL,
  },
  bottomUserPanelShadowTablet: {
    width: SIDEBAR_WIDTH,
    marginHorizontal: -TABLET_PADDING_HORIZONTAL,
  },
});
