import dayjs from 'dayjs';
import { FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import { useEffect, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { InputMessage, Select } from 'src/components';
import { SelectOption } from 'src/constants/types';
import { MIN_USER_AGE } from 'src/features/profile/constants';
import { getNumberOfDaysInMonth } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { i18n } from 'src/locale';
import { ifWeb } from 'src/styles';

export interface DateInputFormValues {
  birthDate: number | null;
  birthMonth: number | null;
  birthYear: number | null;
}

export const dateInputInitialValues: DateInputFormValues = {
  birthDate: null,
  birthMonth: null,
  birthYear: null,
};

interface Props extends Pick<FormikHelpers<DateInputFormValues>, 'setFieldTouched' | 'setFieldValue'> {
  values: DateInputFormValues;
  touched: FormikTouched<DateInputFormValues>;
  errors: FormikErrors<DateInputFormValues>;
  readOnly?: boolean;
}

export const dateInputValidators = {
  birthDate: Yup.number().typeError(i18n.t('validation:thisFieldIsRequired')).required(),
  birthMonth: Yup.number().typeError(i18n.t('validation:thisFieldIsRequired')).required(),
  birthYear: Yup.number().typeError(i18n.t('validation:thisFieldIsRequired')).required(),
};

export const DateInput: React.FC<Props> = ({
  values,
  touched,
  errors,
  readOnly,
  setFieldValue,
  setFieldTouched,
}) => {
  const birthDateError = errors.birthDate || errors.birthMonth || errors.birthYear;
  const birthDateFullyTouched = touched.birthDate && touched.birthMonth && touched.birthYear;

  // If the backend marks any of the fields with some error, which will be different
  // then our generic one, then we need to show it even if all the fields are untouched
  const isBackendError = birthDateError !== i18n.t('validation:thisFieldIsRequired');

  const availableDaysToSelect = useMemo(() => {
    // We default to January 2000, if the date is not complete yet.
    // It is a 31 day long month in a leap year, so it is the most permissive option.
    return getDayOptions((values.birthMonth || 0) + 1, values.birthYear || 2000);
  }, [values.birthMonth, values.birthYear]);

  useEffect(() => {
    const validDays = availableDaysToSelect.map((option) => option.value);

    if (validDays.includes(values.birthDate?.toString())) {
      return;
    }
    // If the day the user selected (e.g 30th of february) is not valid, then
    // we clear the field
    setFieldValue('birthDate', null);
  }, [availableDaysToSelect, values.birthDate, setFieldValue]);

  const handleDateChange = (key: keyof DateInputFormValues) => (option: SelectOption) => {
    setFieldTouched(key);
    // change (decrement) index of birthMonth to match the format expected by the backend
    setFieldValue(key, key === 'birthMonth' ? parseInt(option.value) - 1 : parseInt(option.value));
  };

  return (
    <>
      <View style={styles.dateWrapper}>
        <View style={styles.dateInputWrapper}>
          <Select
            label={i18n.t('user:birthDateLabel')}
            options={monthOptions}
            placeholder={i18n.t('user:month')}
            // increment index of birthMonth to display correct monthOption
            value={values.birthMonth !== null ? (values.birthMonth + 1).toString() : null}
            error={!!errors.birthMonth}
            onChange={handleDateChange('birthMonth')}
            touched={touched.birthMonth}
            readOnly={readOnly}
            searchType="internal"
            testID="birth-date-month-select"
          />
        </View>
        <View style={styles.dateInputWrapper}>
          <Select
            label=" "
            options={availableDaysToSelect}
            placeholder={i18n.t('user:day')}
            value={values.birthDate?.toString()}
            error={!!errors.birthDate}
            onChange={handleDateChange('birthDate')}
            touched={touched.birthDate}
            readOnly={readOnly}
            searchType="internal"
            testID="birth-date-day-select"
          />
        </View>
        <View style={styles.dateInputWrapper}>
          <Select
            label=" "
            options={yearOptions}
            placeholder={i18n.t('user:year')}
            value={values.birthYear?.toString()}
            error={!!errors.birthYear}
            onChange={handleDateChange('birthYear')}
            touched={touched.birthYear}
            readOnly={readOnly}
            searchType="internal"
            testID="birth-date-year-select"
          />
        </View>
      </View>
      {birthDateError && (birthDateFullyTouched || isBackendError) && (
        <InputMessage type="error" message={birthDateError} />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  dateWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
  dateInputWrapper: {
    width: '30%',
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
});

const getNumberOptions = (start: number, end: number): SelectOption[] => {
  const options: SelectOption[] = [];
  for (let i = start; i <= end; i++) {
    options.push({
      label: i.toString(),
      value: i.toString(),
    });
  }
  return options;
};

const getDayOptions = (month: number, year: number) => {
  const numberOfDays = getNumberOfDaysInMonth(month, year);
  return getNumberOptions(1, numberOfDays);
};

const yearOptions = getNumberOptions(1900, dayjs().subtract(MIN_USER_AGE, 'year').year()).reverse();

const monthOptions: SelectOption[] = Array(12)
  .fill(null)
  .map((_, index) => ({
    value: (index + 1).toString(),
    label: dayjs(`1900-${index + 1}-01`).format('MMMM'), // just to get month name
  }));
