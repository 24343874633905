import { StyleSheet } from 'react-native';

import { BaseModal } from 'src/components';
import { FeatureButtons } from 'src/components/FeatureButtons';
import { ModalPortal } from 'src/contexts/portals';
import { i18n } from 'src/locale';

import { ConsensusStatementLink } from '../types';

interface Props {
  close(): void;
  links: ConsensusStatementLink[];
  onLinkPress?(): void;
}

export const ConsensusStatementModal: React.FC<Props> = ({ close, links, onLinkPress }) => {
  const handleLinkPress = () => {
    onLinkPress?.();
    close();
  };

  return (
    <ModalPortal>
      <BaseModal close={close} header={i18n.t('dxTx:consensusStatement')}>
        <FeatureButtons
          buttonItems={links.map((item) => ({
            icon: 'chevron-right',
            text: item.title,
            linkProps: {
              to: item.link,
              external: true,
              onPress: handleLinkPress,
            },
          }))}
          containerStyle={styles.buttonsWrapper}
        />
      </BaseModal>
    </ModalPortal>
  );
};

const styles = StyleSheet.create({
  buttonsWrapper: {
    marginVertical: 20,
  },
});
