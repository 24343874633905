import { StyleProp, StyleSheet, TextStyle, View } from 'react-native';

import { IconName } from 'src/constants/types';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { typography } from 'src/styles';

import { Icon } from '../../components/Icon/Icon';
import { StyledText } from '../../components/StyledText';

interface Props {
  header: string;
  description: React.ReactNode;
  icon: IconName;
  testID?: string;
  iconWidth?: number;
  iconHeight?: number;
  descriptionStyle?: StyleProp<TextStyle>;
}

export const ErrorBannerTemplate: React.FC<Props> = ({
  header,
  description,
  icon,
  testID,
  iconWidth = 100,
  iconHeight = iconWidth,
  descriptionStyle,
}) => {
  const { isTablet } = useDeviceInfo();

  return (
    <View style={[styles.wrapper, isTablet && styles.wrapperTablet]} testID={testID}>
      <Icon name={icon} color="plumbsGradient" width={iconWidth} height={iconHeight} style={styles.icon} />
      <StyledText style={styles.header}>{header}</StyledText>
      <StyledText style={[styles.description, descriptionStyle]}>{description}</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 100,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '90%',
    alignSelf: 'center',
  },
  wrapperTablet: {
    paddingVertical: 140,
  },
  icon: {
    marginBottom: 20,
  },
  header: {
    ...typography.heading3,
    textAlign: 'center',
  },
  description: {
    marginTop: 8,
    maxWidth: 280,
    textAlign: 'center',
    ...typography.body2,
  },
});
