import React, { forwardRef, PropsWithChildren, ReactElement, Ref, RefAttributes, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  FlatList,
  FlatListProps as FlatListBaseProps,
  SectionList,
  SectionListProps as SectionListBaseProps,
  StyleSheet,
  View,
  VirtualizedListWithoutRenderItemProps,
} from 'react-native';

import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { isWeb } from 'src/helpers';
import { ifWeb } from 'src/styles';

interface CommonProps {
  fetchNextPage: () => void;
  displayStandbyText?: boolean;
}

type InfiniteScrollProps<ItemT, ListT extends VirtualizedListWithoutRenderItemProps<ItemT>> = CommonProps &
  Omit<ListT, 'onEndReached'> & {
    loading: boolean;
    allFetched: boolean;
  };

type FlatListProps<ItemT> = PropsWithChildren<InfiniteScrollProps<ItemT, FlatListBaseProps<ItemT>>>;
type SectionListProps<ItemT> = PropsWithChildren<InfiniteScrollProps<ItemT, SectionListBaseProps<ItemT>>>;

const VisibilitySensor: React.FC<CommonProps> = ({ fetchNextPage }) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return isWeb ? <div style={StyleSheet.flatten(styles.visibilitySensor)} ref={ref} /> : null;
};

const getListProps = (props: (FlatListProps<any> | SectionListProps<any>) & CommonProps) => ({
  onEndReached: !isWeb && !props.loading ? props.fetchNextPage : null,
  onEndReachedThreshold: props.onEndReachedThreshold ?? 1.5,
  style: [styles.list, props.style],
  ListFooterComponent: () => {
    const shouldReserveSpaceForSpinner = props.ListFooterComponent ? false : !props.allFetched;

    return (
      <>
        {!props.loading && <VisibilitySensor fetchNextPage={props.fetchNextPage} />}
        <View style={shouldReserveSpaceForSpinner && styles.loaderHeight}>
          {!!props.loading && (
            <LoadingIndicator style={styles.loaderHeight} displayStandbyText={props.displayStandbyText} />
          )}
        </View>
        {props.ListFooterComponent}
      </>
    );
  },
});

export const InfiniteScrollListLegacy = forwardRef(
  <ItemT,>(props: FlatListProps<ItemT>, ref: Ref<FlatList>): ReactElement<any, any> | null => {
    return <FlatList {...props} ref={ref} {...getListProps(props)} />;
  },
) as <ItemT>(props: FlatListProps<ItemT> & RefAttributes<FlatList>) => ReactElement<any, any> | null;

export const InfiniteSectionList = forwardRef(
  <ItemT,>(props: SectionListProps<ItemT>, ref: Ref<SectionList>): ReactElement<any, any> | null => {
    return <SectionList {...props} ref={ref} {...getListProps(props)} />;
  },
) as <ItemT>(props: SectionListProps<ItemT> & RefAttributes<SectionList>) => ReactElement<any, any> | null;

const styles = StyleSheet.create({
  list: {
    width: '100%',
    ...ifWeb({
      overflowX: 'visible',
      overflowY: 'visible',
    }),
  },
  loaderHeight: {
    height: 120,
    justifyContent: 'center',
  },
  visibilitySensor: {
    paddingTop: 1,
    width: '100%',
  },
});
