import { StyleSheet, View } from 'react-native';

import { GradientBar } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { FavoritesList } from 'src/features/favorites';
import { MainSearchBox } from 'src/features/search/components';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import { ifWeb } from 'src/styles';

import { FeaturesMenu } from './FeaturesMenu';
import { ToolsMenu } from './ToolsMenu';

export const SideBar: React.FC = () => {
  const { isMobileViewMode } = useWebAppLayout();

  const {
    isPro,
    permissions: { canAccessIndividualAccountFeatures },
  } = useUserInfo();

  const gradientType = isPro ? 'proSideBarGradient' : 'pvdVerticalGradient';

  return (
    <View style={styles.wrapper} testID="sidebar-wrapper">
      <GradientBar gradientType={gradientType} style={StyleSheet.absoluteFill} />
      <View
        style={[styles.scrollWrapper, isMobileViewMode && styles.scrollWrapperMobile]}
        dataSet={{ id: 'sidebar-scroll-wrapper' }}
      >
        {!isMobileViewMode && (
          <View style={styles.sectionMargin}>
            <MainSearchBox />
          </View>
        )}
        <FeaturesMenu style={styles.sectionMargin} />
        <ToolsMenu style={styles.sectionMargin} />
        {canAccessIndividualAccountFeatures && (
          <View style={styles.favoritesList}>
            <FavoritesList
              contentStyle={styles.favoritesItemsWrapper}
              headerStyle={styles.sectionHeader}
              whiteText
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  scrollWrapper: {
    flexDirection: 'column',
    alignContent: 'center',
    padding: 16,
    flex: 1,
    overflowY: 'auto',
    ...ifWeb({
      'overscroll-behavior-y': 'none',
    }),
  },
  scrollWrapperMobile: {
    // we need to add padding bottom to prevent Safari browser bar
    // from overlapping the list content on mobile devices
    paddingBottom: 100,
  },
  sectionMargin: {
    marginBottom: 16,
  },
  sectionHeader: {
    marginBottom: 12,
  },
  favoritesList: {
    minHeight: 370,
  },
  favoritesItemsWrapper: {
    borderRadius: 5,
    overflow: 'hidden',
  },
});
