import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import throttle from 'throttleit';

import { isWeb } from 'src/helpers/common';
import { FILTER_BAR_HEIGHT, SEARCH_BOX_HEIGHT, NAVBAR_HEIGHT } from 'src/styles';

interface Options {
  /** Used for isAtTop calculations */
  topThreshold?: number;
}

export function useScrollListener({ topThreshold = 0 }: Options = {}) {
  const [isScrollingTop, setIsScrollingTop] = useState<boolean>(true);
  const [isAtTop, setIsAtTop] = useState<boolean>(true);
  const currentPosition = useRef(isWeb ? NAVBAR_HEIGHT + FILTER_BAR_HEIGHT : SEARCH_BOX_HEIGHT);

  const scrollHandlerForNative = (event: NativeSyntheticEvent<NativeScrollEvent>): void => {
    const {
      contentOffset: { y: newPosition },
      layoutMeasurement: { height: layoutHeight },
      contentSize: { height: contentHeight },
    } = event.nativeEvent;
    const availableScrollPosition = contentHeight - layoutHeight;
    if (newPosition < 70) return setIsScrollingTop(true);
    setDirection(Math.min(newPosition, availableScrollPosition));
  };

  const setDirection = useCallback(
    (newPosition: number) => {
      const isScrollingTop = currentPosition.current > newPosition;
      setIsScrollingTop(isScrollingTop);
      currentPosition.current = newPosition;
      setIsAtTop(newPosition <= topThreshold);
    },
    [topThreshold],
  );

  const scrollHandlerForWeb = useCallback(() => {
    const newPosition = window.scrollY;
    setDirection(newPosition);
  }, [setDirection]);

  const scrollWebListener = useMemo(() => throttle(scrollHandlerForWeb, 100), [scrollHandlerForWeb]);

  useEffect(() => {
    if (isWeb) {
      document.addEventListener('scroll', scrollWebListener);

      return () => {
        document.removeEventListener('scroll', scrollWebListener);
      };
    }
  }, [scrollWebListener]);

  return { isScrollingTop, scrollHandlerForNative, isAtTop };
}
