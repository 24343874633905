import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { palette } from 'src/styles';

import { BaseButton } from '.';
import { SecondaryButton } from './Buttons/SecondaryButton';

interface Props {
  title: string;
  onPress?: () => void;
  noLines?: boolean;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  testID?: string;
  buttonSize?: React.ComponentProps<typeof BaseButton>['size'];
}

export const DividerButton: React.FC<Props> = ({
  title,
  onPress,
  noLines,
  style,
  disabled,
  testID,
  buttonSize = 'tiny',
}) => {
  return (
    <View style={[styles.wrapper, style]}>
      {!noLines && <View style={styles.line} />}
      <SecondaryButton
        title={title}
        onPress={onPress}
        disabled={disabled}
        size={buttonSize}
        containerStyle={styles.button}
        testID={testID}
      />
      {!noLines && <View style={styles.line} />}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  line: {
    height: 1,
    backgroundColor: palette.grey2,
    width: '50%',
    flexShrink: 1,
  },
  button: {
    marginHorizontal: 28,
  },
});
