import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { InfoBox, MarkdownContent } from 'src/components';
import {
  isHandoutClinicalConditions,
  isHandoutProcedure,
  isHandoutVaccine,
  isDrugHandout,
  isVeterinaryMedicationGuide,
} from 'src/helpers/content';
import { convertToMarkdown } from 'src/helpers/functions';
import { palette, typography } from 'src/styles';

import { ShareHandoutButtons } from './ShareHandoutButtons';
import { ClinicalHandoutDetails, DrugHandoutDetails } from '../types';

type Item = ClinicalHandoutDetails | DrugHandoutDetails;

interface Props {
  data: Item;
  hideShareButtons?: boolean;
}

export const HandoutDescription: React.FC<Props> = ({ data, hideShareButtons }) => {
  const { t } = useTranslation('handouts');

  const itemIsDrugHandout = isDrugHandout<DrugHandoutDetails>(data);

  const itemIsVeterinaryMedicationGuide = isVeterinaryMedicationGuide(data);

  const otherNames = itemIsDrugHandout
    ? data.otherNamesForThisMedication ?? data.otherCommonNames
    : data.alsoCalled?.join(', ');
  const otherNamesLabelKey = (() => {
    if (itemIsDrugHandout) {
      return 'otherNamesForThisMedication';
    }
    if (isHandoutClinicalConditions(data)) {
      return 'otherNamesForCondition';
    }
    if (isHandoutProcedure(data)) {
      return 'otherNamesForProcedure';
    }
    if (isHandoutVaccine(data)) {
      return 'otherNamesForVaccine';
    }
    return 'otherNames';
  })();

  return (
    <>
      {itemIsDrugHandout && !!data.pronunciation && (
        <MarkdownContent style={styles.pronunciation} inline>
          {data.pronunciation}
        </MarkdownContent>
      )}
      {itemIsVeterinaryMedicationGuide && !!data.description && (
        <MarkdownContent style={styles.headerCopyText}>
          {`${convertToMarkdown(t('description'), 'bold')}: ` + data.description}
        </MarkdownContent>
      )}
      {!!otherNames && (
        <MarkdownContent style={styles.headerCopyText}>
          {`${convertToMarkdown(t(otherNamesLabelKey), 'bold')}: ` + otherNames}
        </MarkdownContent>
      )}
      {itemIsDrugHandout && !!data.commonDosageForms && (
        <MarkdownContent style={styles.headerCopyText}>
          {`${convertToMarkdown(t('commonDosageForms'), 'bold')}: ` + data.commonDosageForms}
        </MarkdownContent>
      )}
      {!hideShareButtons && (
        <ShareHandoutButtons
          data={data}
          mixpanelParams={{ location: itemIsDrugHandout ? 'VMG Page' : 'Clinical Handout Page' }}
        />
      )}
      {data.intro && <InfoBox style={styles.introWrapper}>{data.intro}</InfoBox>}
    </>
  );
};

const styles = StyleSheet.create({
  pronunciation: {
    color: palette.grey5,
    ...typography.body3,
  },
  headerCopyText: {
    ...typography.body3,
  },
  introWrapper: {
    marginTop: 26,
  },
});
