import { ContentListType } from 'src/features/content/constants';
import {
  MixpanelContentListAccordionLocation,
  MixpanelEvent,
  MixpanelService,
} from 'src/features/tracking';

/** Tracks Mixpanel evetns related to accordion events on content list screens */
export const useContentListAccordionEvents = (contentListType: ContentListType) => {
  const location = getLocation(contentListType);

  const onItemOpen = (name: string) => {
    MixpanelService.track(MixpanelEvent.AccordionOpen, {
      'Accordion Location': location,
      'Accordion Name': name,
    });
  };

  const onItemClose = (name: string) => {
    MixpanelService.track(MixpanelEvent.AccordionClose, {
      'Accordion Location': location,
      'Accordion Name': name,
    });
  };

  const onCloseAllPress = () => {
    MixpanelService.track(MixpanelEvent.AccordionCloseAll, {
      'Accordion Location': location,
    });
  };

  return {
    onItemClose,
    onItemOpen,
    onCloseAllPress,
  };
};

const getLocation = (contentListType: ContentListType): MixpanelContentListAccordionLocation => {
  switch (contentListType) {
    case ContentListType.Algorithms:
      return 'Algorithms Index';
    case ContentListType.Appendix:
      return 'Appendix Index';
    case ContentListType.ClinicalHandouts:
      return 'Patient Guide Index';
    case ContentListType.DrugHandouts:
      return 'VMG Index';
    case ContentListType.Drugs:
      return 'Drug Monograph Index';
    case ContentListType.DxTx:
      return 'Dx&Tx Index';
    case ContentListType.DDx:
      return 'DDx Index';
  }
};
