import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Orientation from 'react-native-orientation-locker';
import { useDispatch, useSelector } from 'react-redux';

import { useInternetConnectionNotification } from 'src/errorHandling/hooks';
import { CanadianDisclaimer } from 'src/features/auth/components';
import { isUserAGroupMember } from 'src/features/auth/helpers';
import { useIsSignedIn, useUserInfo } from 'src/features/auth/hooks';
import { verifyAuthTokens } from 'src/features/auth/state';
import { DevicesLimitWarning } from 'src/features/devicesLimit/components';
import { IntercomService } from 'src/features/intercom/IntercomService';
import { usePersonalInformation } from 'src/features/profile';
import {
  AppcuesService,
  OlyticsService,
  SentryService,
  identifyUserInMixpanel,
} from 'src/features/tracking';
import { delay, isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import RootNavigator from 'src/navigation/navigators/RootNavigator';
import { EnvService } from 'src/services';
import { ApplicationState } from 'src/state';
import { AppDispatch } from 'src/state/store';

import { BaseModal } from './BaseModal';

export const RootComponent: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const shouldDisplayCanadianDisclaimer = useSelector(
    (state: ApplicationState) => state.auth.shouldDisplayCanadianDisclaimer,
  );
  const isSignedIn = useIsSignedIn();
  const {
    expiredSubscription,
    createdAt,
    ip,
    id,
    email,
    isSharedAccount,
    groupInfo,
    institution,
    planCode,
    mixpanelProperties,
    omedaCustomerId,
  } = useUserInfo();
  const { data: personalInformation } = usePersonalInformation(!isLoading);

  const dispatch: AppDispatch = useDispatch();
  const { isTabletApp } = useDeviceInfo();
  useInternetConnectionNotification();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isSignedIn) {
      queryClient.removeQueries();
    }
  }, [isSignedIn, queryClient]);

  useEffect(() => {
    !isTabletApp && Orientation.lockToPortrait();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSignedIn && id && email) {
      identifyUserInMixpanel({
        isSharedAccount,
        ip,
        id,
        mixpanelProperties,
        createdAt,
      });
      SentryService.setUser(email, id);
    }
  }, [isSignedIn, id, email, isSharedAccount, ip, mixpanelProperties, createdAt]);

  useEffect(() => {
    if (isSignedIn && omedaCustomerId) {
      OlyticsService.identifyUser(omedaCustomerId);
    }
  }, [isSignedIn, omedaCustomerId]);

  useEffect(() => {
    async function init() {
      await Promise.all([
        isSignedIn && dispatch(verifyAuthTokens()),
        // always show the native loading screen with various trademarks for at least a second
        !isWeb && delay(1000),
      ]);

      setLoading(false);
    }

    init();
  }, [dispatch, expiredSubscription, isSignedIn]);

  useEffect(() => {
    if (isSignedIn && personalInformation) {
      AppcuesService.identify(id, {
        createdAt: createdAt,
        planTier: planCode,
        role: isUserAGroupMember(groupInfo) ? groupInfo.role : null,
        accountId: id,
        firstName: personalInformation?.firstName,
        email: email,
        environment: EnvService.getEnv('ENVIRONMENT'),
        country: personalInformation?.country,
        practice: personalInformation?.practice,
        institution: institution,
      });

      IntercomService.initialize({
        user_id: id,
        name: personalInformation?.firstName ?? undefined,
        created_at: dayjs(createdAt).unix(),
        email,
      });
    }
  }, [isSignedIn, personalInformation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!isLoading && <DevicesLimitWarning />}
      <RootNavigator loading={isLoading} />
      {isWeb && shouldDisplayCanadianDisclaimer && (
        // rendered without a portal to display it above any other modal
        <BaseModal>
          <CanadianDisclaimer />
        </BaseModal>
      )}
    </>
  );
};
