import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import type { BaseButtonProps } from 'src/components/';

import { BaseModal } from './BaseModal';
import { InfoModal } from './InfoModal';

interface Props extends React.ComponentProps<typeof BaseModal> {
  title?: string;
  description?: string;
  cancelMessage: string;
  confirmMessage: string;
  confirm?: () => void;
  cancel?: () => void;
  confirmButtonProps?: Partial<BaseButtonProps>;
  cancelButtonProps?: Partial<BaseButtonProps>;
  centered?: boolean;
  loading?: boolean;
  testID?: string;
  contentContainerStyle?: StyleProp<ViewStyle>;
}

const cancelBtnTestID = 'modal-cancel-btn';
const confirmBtnTestID = 'modal-submit-btn';

// Template for a simple modal content with title, message and 2 buttons (secondary and regular)
export const ConfirmModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  description,
  cancelMessage,
  confirmMessage,
  confirm,
  cancel,
  confirmButtonProps,
  cancelButtonProps,
  loading,
  testID,
}) => {
  return (
    <InfoModal header={title} testID={testID}>
      {!!description && <InfoModal.Content>{description}</InfoModal.Content>}
      {children}
      <InfoModal.Buttons direction="row">
        <InfoModal.Button
          variant="secondary"
          title={cancelMessage}
          onPress={cancel}
          testID={cancelBtnTestID}
          {...cancelButtonProps}
        />
        <InfoModal.Button
          title={confirmMessage}
          onPress={confirm}
          loading={loading}
          testID={confirmBtnTestID}
          {...confirmButtonProps}
        />
      </InfoModal.Buttons>
    </InfoModal>
  );
};
