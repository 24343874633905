import React, { CSSProperties, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';

import { palette, typography } from 'src/styles';

import { GradientButton as BaseComponent } from './GradientButton';
import { Icon } from '../Icon/Icon';

interface Props extends React.ComponentProps<typeof BaseComponent> {}

export const GradientButton: React.FC<Props> = ({
  title,
  titleStyle,
  onPress,
  variant = 'primary',
  testID,
  paddingHorizontal,
  height,
  fullWidth,
  icon,
  overlayBackgroundColor = palette.white,
  borderRadius = 4,
  iconStyle,
  style,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isSecondary = variant === 'secondary';
  const stylesFromProps = StyleSheet.flatten([style]);
  const iconStylesFromProps = typeof iconStyle === 'function' ? iconStyle(isHovered) : iconStyle;

  return (
    <button
      style={Object.assign(
        {},
        styles.button,
        paddingHorizontal && { padding: `0 ${paddingHorizontal}px` },
        height && { height: `${height}px` },
        fullWidth && styles.buttonFullWidth,
        { borderRadius },
        stylesFromProps,
      )}
      data-testid={testID}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onPress}
      ref={buttonRef}
    >
      <div style={Object.assign({}, styles.overlay, isHovered && styles.overlayHover, { borderRadius })} />
      {isSecondary && (
        <div
          style={Object.assign({}, styles.secondaryCover, {
            backgroundColor: overlayBackgroundColor,
            borderRadius: borderRadius / 2,
          })}
        />
      )}
      <span
        style={Object.assign(
          {},
          styles.text,
          isSecondary && styles.textSecondary,
          StyleSheet.flatten([typography.primaryButton, titleStyle]),
        )}
      >
        {title}
      </span>
      {icon && <Icon name={icon} color={palette.white} style={[RNStyles.icon, iconStylesFromProps]} />}
      {isSecondary && (
        <span
          style={Object.assign(
            {},
            styles.text,
            styles.textSecondary,
            styles.textSecondaryOverlay,
            isHovered && styles.textSecondaryOverlayHover,
            StyleSheet.flatten([typography.primaryButton, titleStyle]),
          )}
        >
          {title}
        </span>
      )}
    </button>
  );
};

const styles: Record<string, CSSProperties> = {
  button: {
    border: 'none',
    background: `linear-gradient(90deg, ${palette.blue}, ${palette.brightBlue})`,
    boxSizing: 'border-box',
    height: 52,
    padding: '0 28px',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonFullWidth: {
    width: '100%',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    opacity: 0,
    background: `linear-gradient(90deg, ${palette.blue4}, ${palette.blue}, ${palette.blue3})`,
    transitionProperty: 'opacity',
  },
  overlayHover: {
    opacity: 1,
  },
  text: {
    position: 'relative',
    color: palette.white,
    textTransform: 'uppercase',
  },
  textSecondary: {
    color: palette.blue,
    backgroundImage: `linear-gradient(-90deg, ${palette.brightBlue}, ${palette.blue})`,
    backgroundClip: 'text',
    WebkitTextFillColor: palette.transparent,
    WebkitBackgroundClip: 'text',
    position: 'absolute',
  },
  textSecondaryOverlay: {
    backgroundImage: `linear-gradient(90deg, ${palette.blue4}, ${palette.blue}, ${palette.blue3})`,
    opacity: 0,
    transitionProperty: 'opacity',
    position: 'relative',
  },
  textSecondaryOverlayHover: {
    opacity: 1,
  },
  secondaryCover: {
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 4px)',
    position: 'absolute',
    left: 2,
    top: 2,
  },
};

const RNStyles = StyleSheet.create({
  icon: {
    marginLeft: 10,
  },
});
