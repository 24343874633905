import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { WebScreenContext } from 'src/contexts/webScreenContext';
import { LinkedRoute, WebHistoryState } from 'src/navigation/types';
import { routeToPath } from 'src/navigation/utils';

import { useRoute } from './useRoute';
import { Route } from '../routes';

type NavigatePositionalArgs = [LinkedRoute] | [LinkedRoute, Record<string, unknown>];

function isPositionalArg(args: any[]): args is NavigatePositionalArgs {
  return typeof args[0] === 'string';
}

const historyLenghtAtStartup = history.length;

export const useNavigation = () => {
  const history = useHistory();
  const route = useRoute();

  const navigate = useCallback(
    (
      ...args:
        | NavigatePositionalArgs
        | [{ name: LinkedRoute; params?: Record<string, unknown>; merge?: boolean }]
    ) => {
      let name, params, merge;

      if (isPositionalArg(args)) {
        [name, params] = args;
      } else {
        [{ name, params, merge }] = args;
      }

      if (name === route.name) {
        history.replace(
          routeToPath(name, merge ? { ...route.params, ...params } : params),
          history.location.state,
        );
      } else {
        history.push(routeToPath(name, params), {
          previousUrl: window.location.pathname + window.location.search,
        } as WebHistoryState);
      }
    },
    [history, route],
  );

  const goBack = useCallback(() => {
    if (historyLenghtAtStartup === history.length) {
      // The previous page is outside our website, so we go to Home
      navigate(Route.Home);
    } else {
      history.goBack();
    }
  }, [history, navigate]);

  const replace = useCallback(
    (name: LinkedRoute, params?: Record<string, unknown>) => {
      history.replace(routeToPath(name, params));
    },
    [history],
  );

  const setParams = useCallback(
    (params: Record<string, any>) => {
      history.replace(routeToPath(route.name as LinkedRoute, { ...route.params, ...params }));
    },
    [history, route],
  );

  const [, setOptions] = useContext(WebScreenContext);

  return useMemo(
    () => ({
      goBack,
      navigate,
      replace,
      setParams,
      setOptions,
    }),
    [goBack, navigate, replace, setParams, setOptions],
  );
};
