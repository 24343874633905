import { useQuery } from '@tanstack/react-query';

import { useContentItemQuery } from 'src/hooks/queries/contentItems';

import { fetchDxTxImage } from '../api';
import type { DxTxDetails } from '../types';

export const useDxTxItem = (id: string) =>
  useContentItemQuery<DxTxDetails>({
    key: ['dx-tx', id],
    url: `/contents/clinical-brief/${id}/`,
  });

export const useDxTxImage = (id: string) =>
  useQuery({
    queryKey: ['dx-tx-image', id],
    queryFn: () => fetchDxTxImage(id),
  });
