import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { Container, FullScreenTemplate } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { GeneralNotesBox } from 'src/features/notes';
import { NotesListProvider } from 'src/features/notes/context/NotesListContext';
import { PromoBoxCarousel } from 'src/features/promobox';
import { ViewedContentBox } from 'src/features/recentlyViewed/components/ViewedContentBox';
import { OlyticsService, MixpanelService, MixpanelEvent } from 'src/features/tracking';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { CONTAINER_PADDING_H_MOBILE } from 'src/styles';

import { HomeScreenSharedAccount } from './HomeScreenSharedAccount';
import { HomeScreenContentBox } from '../components/HomeScreenContentBox';

type Props = ScreenProps<Route.Home>;

export const HomeScreen: React.FC<Props> = () => {
  const { isDesktop, isTablet } = useDeviceInfo();
  const {
    permissions: { canAccessIndividualAccountFeatures },
  } = useUserInfo();

  useEffect(() => {
    MixpanelService.track(MixpanelEvent.HomeScreenView);
    OlyticsService.fire();
  }, []);

  const boxStyles = [styles.section, isTablet && styles.sectionTablet, isDesktop && styles.sectionDesktop];

  return (
    <FullScreenTemplate title={i18n.t('screens:home')}>
      {canAccessIndividualAccountFeatures ? (
        <Container verticalPadding fullWidthOnMobile maxWidth="narrow">
          <PromoBoxCarousel style={[boxStyles, styles.promobox, isTablet && styles.promoboxTablet]} />

          <ViewedContentBox HomeScreenContentBox={HomeScreenContentBox} />
          <NotesListProvider>
            <GeneralNotesBox HomeScreenContentBox={HomeScreenContentBox} />
          </NotesListProvider>
        </Container>
      ) : (
        <HomeScreenSharedAccount />
      )}
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 20,
    zIndex: 1,
  },
  sectionTablet: {
    marginBottom: 28,
  },
  sectionDesktop: {
    marginBottom: 40,
  },
  promobox: {
    marginHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  promoboxTablet: {
    marginHorizontal: 0,
  },
});
