import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'src/components';
import { isCurrentHostDifferentThanDefault } from 'src/helpers';
import { EnvService } from 'src/services';

export interface IPSignOutConfirmModal {
  close(): void;
  signOut(): void;
}

export const IPSignOutConfirmModal: React.FC<IPSignOutConfirmModal> = ({ close, signOut }) => {
  const { t } = useTranslation('ipLoginUser');

  return (
    <ConfirmModal
      testID="logout-confirmation-modal"
      close={close}
      cancel={close}
      title={t('accessYourAccount')}
      description={t('accessYourAccountDescription')}
      cancelMessage={t('common:goBack')}
      confirmMessage={t('common:continue')}
      confirm={() => {
        !isCurrentHostDifferentThanDefault
          ? signOut()
          : window.open(`${EnvService.getEnv('SCHEME')}://${EnvService.getEnv('DOMAIN')}`, '_blank');
        close();
      }}
    />
  );
};
