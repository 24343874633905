import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocales } from 'react-native-localize';

import { formatDateWithMonths } from 'src/helpers/timeFormatters';

import en from './en';

const languageDetector = {
  type: 'languageDetector',
  async: false,
  detect: () => getLocales()[0].languageCode,
  init: () => null,
  cacheUserLanguage: () => null,
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  // @ts-ignore
  .use(languageDetector)
  // @ts-ignore
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    resources: {
      en,
    },
    ns: ['common', 'signIn', 'signUp'],
    defaultNS: 'common',
    debug: process.env.NODE_ENV === 'development',
    returnNull: false,
    interpolation: {
      skipOnVariables: false,
      escapeValue: false,
      format: function (value, format, lng) {
        switch (format) {
          case 'uppercase':
            return value.toUpperCase();
          case 'capitalize':
            return value.charAt(0).toUpperCase() + value.slice(1);
          case 'price':
            return Intl.NumberFormat(lng, {
              style: 'currency',
              currency: 'USD',
            }).format(value);
          case 'dateMonthName':
            return formatDateWithMonths(value);
          default:
            return value;
        }
      },
    },
  });

export default i18n;
