import { useFormik } from 'formik';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import { BaseButton, Form, InputItem, SecondaryButton } from 'src/components';
import { passwordValidationRules } from 'src/constants/forms';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { showNotification } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';

import { useChangePassword } from '../../../hooks';
import { PasswordChangeParams } from '../../../types';
import { AccountInformationSection as Section } from '../../AccountInformationSection';

interface Props {
  close: () => void;
}

const validationSchema: Yup.SchemaOf<PasswordChangeParams> = Yup.object().shape({
  currentPassword: Yup.string().required(),
  password: passwordValidationRules,
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password')], i18n.t('validation:passwords_dont_match'))
    .required(),
});

const initialValues: PasswordChangeParams = {
  currentPassword: '',
  password: '',
  passwordConfirm: '',
};

export const PasswordChangeForm: React.FC<Props> = ({ close }) => {
  const { isTablet, isTabletApp } = useDeviceInfo();
  const { mutate: changePassword, isPending } = useChangePassword();

  const submitForm = async (values: PasswordChangeParams) => {
    changePassword(values, {
      onSuccess: () => {
        showNotification({ type: 'success' });
        close();
      },
      onError: (error) => {
        const { currentPassword, password, passwordConfirm, detail } =
          getMessagesFromErrorResponse<PasswordChangeParams>(error);
        if (currentPassword || password || passwordConfirm) {
          setErrors({
            currentPassword,
            password,
            passwordConfirm,
          });
        } else {
          showNotification({ type: 'error', title: detail });
        }
      },
    });
  };

  const { handleChange, handleBlur, handleSubmit, setErrors, values, errors, touched } = useFormik({
    initialValues,
    onSubmit: submitForm,
    validationSchema,
  });

  const handleEditingSubmit = Platform.OS === 'web' ? () => handleSubmit() : undefined;

  const renderField = (
    key: keyof PasswordChangeParams,
    label: string,
    placeholder: string,
    testID: string,
  ) => (
    <Section.Item>
      <InputItem
        onChangeText={handleChange(key) || undefined}
        onBlur={handleBlur(key) || undefined}
        value={values[key]}
        error={errors[key]}
        touched={touched[key]}
        label={label}
        secureTextEntry
        autoCapitalize="none"
        autoCompleteType="password"
        blurOnSubmit
        onSubmitEditing={handleEditingSubmit}
        placeholder={placeholder}
        testID={testID}
      />
    </Section.Item>
  );

  return (
    <Form>
      {renderField(
        'currentPassword',
        i18n.t('password'),
        i18n.t('enterCurrentPassword'),
        'current-password-input',
      )}
      {renderField('password', i18n.t('passwordNew'), i18n.t('enterNewPassword'), 'new-password-input')}
      {renderField(
        'passwordConfirm',
        i18n.t('passwordConfirm'),
        i18n.t('passwordConfirm'),
        'new-password-confirm-input',
      )}
      <View style={[isTablet && styles.buttonsWrapper, isTabletApp && styles.buttonsWrapperTablet]}>
        <BaseButton
          title={i18n.t('saveChanges')}
          onPress={handleSubmit}
          containerStyle={[isTablet && styles.button, isTabletApp && styles.buttonTablet]}
          loading={isPending}
          testID="save-new-password-btn"
          variant="gradient"
        />
        <SecondaryButton
          containerStyle={!isTablet && !isTabletApp && styles.buttonSecondary}
          title={i18n.t('cancel')}
          disabled={isPending}
          onPress={close}
        />
      </View>
    </Form>
  );
};

const styles = StyleSheet.create({
  buttonsWrapper: {
    flexDirection: 'row-reverse',
    marginTop: 32,
  },
  buttonsWrapperTablet: {
    width: '100%',
    flexDirection: 'column',
  },
  button: {
    marginBottom: 0,
    marginLeft: 24,
  },
  buttonSecondary: {
    marginTop: 10,
  },
  buttonTablet: {
    marginBottom: 10,
    marginLeft: 0,
  },
});
