import { useTranslation } from 'react-i18next';

import { Popover, Label } from 'src/components';

import { GroupMemberEmailStatus, GroupMemberStatus } from '../../types';

type Status = Exclude<GroupMemberStatus, GroupMemberStatus.Confirmed>;

interface Props {
  status: Status;
  emailStatus?: GroupMemberEmailStatus;
}

const statusTranslationKeys: Record<Status, string> = {
  [GroupMemberStatus.Expired]: 'expired',
  [GroupMemberStatus.Pending]: 'pending',
};

const infoTranslationKeys: Record<GroupMemberEmailStatus | Status, string> = {
  [GroupMemberEmailStatus.BounceMailboxFull]: 'mailboxFullInfo',
  [GroupMemberEmailStatus.BounceNoEmail]: 'noEmailInfo',
  [GroupMemberEmailStatus.BounceOther]: 'bounceOtherInfo',
  [GroupMemberEmailStatus.Complaint]: 'complaintInfo',
  [GroupMemberEmailStatus.Reject]: 'rejectedInfo',
  [GroupMemberStatus.Pending]: 'pendingInfo',
  [GroupMemberStatus.Expired]: 'expiredInfo',
};

export const InvitationStatus: React.FC<Props> = ({ status, emailStatus }) => {
  const isFailed = !!emailStatus;
  const { t } = useTranslation('adminDashboard');

  return (
    <Popover content={t(infoTranslationKeys[emailStatus || status])}>
      <Label
        status={isFailed || status === GroupMemberStatus.Expired ? 'error' : 'warning'}
        text={isFailed ? t('failed') : t(statusTranslationKeys[status])}
      />
    </Popover>
  );
};
