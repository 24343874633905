import { useState } from 'react';

import { AcceptGroupInvitationResponse } from 'src/features/subscription';
import { useAcceptGroupInvitation } from 'src/features/subscription/hooks';

export function useGroupInvitation(
  params: { onSuccess?: (data: AcceptGroupInvitationResponse) => void } = {},
) {
  const [termsAccepted, setTerms] = useState(false);
  const { mutate: acceptGroupInvitation, isPending } = useAcceptGroupInvitation(params);

  return {
    termsAccepted,
    setTerms,
    acceptGroupInvitation,
    isAcceptGroupInvitationPending: isPending,
  };
}
