import { StyleSheet, View } from 'react-native';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';

import { IconButton, StyledText } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { NAVBAR_HEIGHT, palette, typography } from 'src/styles';

interface Props {
  close(): void;
  text: React.ReactNode;
}

const NAVY_GRADIENT_ID = 'navy-gradient';

export const InfoBar: React.FC<Props> = ({ text, close }) => {
  const { isTablet } = useDeviceInfo();

  return (
    <View>
      <Svg height="100%" width="100%" style={StyleSheet.absoluteFill}>
        <Defs>
          <LinearGradient id={NAVY_GRADIENT_ID}>
            <Stop offset="0" stopColor={palette.navy} />
            <Stop offset="1" stopColor={palette.darkBlue} />
          </LinearGradient>
        </Defs>
        <Rect x="0" y="0" width="100%" height="100%" fill={`url(#${NAVY_GRADIENT_ID})`} />
      </Svg>
      <View style={[styles.contentWrapper, isTablet && styles.wrapperWide]}>
        <View style={styles.textWrapper}>
          {typeof text === 'string' ? (
            <StyledText style={[typography.body2, styles.text]}>{text}</StyledText>
          ) : (
            text
          )}
        </View>
        <IconButton
          name="close"
          onPress={close}
          color={palette.white}
          onHoverColor={palette.white}
          containerStyle={isTablet ? styles.closeCentered : styles.close}
          nonScalingStroke
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  contentWrapper: {
    minHeight: NAVBAR_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 24,
    paddingHorizontal: 48,
    flexDirection: 'row',
  },
  wrapperWide: {
    paddingVertical: 20,
  },
  textWrapper: {
    flexShrink: 1,
    maxWidth: 730,
    zIndex: 1,
  },
  text: {
    color: palette.white,
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  closeCentered: {
    position: 'absolute',
    right: 20,
  },
});
