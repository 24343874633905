import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { useContainer, Params } from 'src/hooks/useContainer';

interface Props extends Params {
  style?: StyleProp<ViewStyle>;
  testID?: string;
}

export const Container: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  style,
  testID,
  ...params
}) => {
  const containerStyles = useContainer(params);

  return (
    <View style={[containerStyles, style]} testID={testID}>
      {children}
    </View>
  );
};
