import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface Props {
  inverted?: boolean;
  style?: StyleProp<ViewStyle>;
}

const BOTTOM_TO_TOP_GRADIENT = 'linear-gradient(to top, rgba(23, 40, 94, .03) 0%, transparent 100%)';
const TOP_TO_BOTTOM_GRADIENT = 'linear-gradient(rgba(23, 40, 94, .03) 0%, transparent 100%)';

export const GradientShadow: React.FC<Props> = ({ inverted, style }) => {
  const gradientBackground = inverted ? BOTTOM_TO_TOP_GRADIENT : TOP_TO_BOTTOM_GRADIENT;

  return <View style={[{ backgroundImage: gradientBackground }, styles.gradient, style]} />;
};

const styles = StyleSheet.create({
  gradient: {
    height: 20,
    width: '100%',
    top: '100%',
    zIndex: 1,
    pointerEvents: 'none',
    position: 'absolute',
  },
});
