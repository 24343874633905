import { useRef } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';

import { CircleIconButton, IconButton } from 'src/components';
import { useAndroidInputHack } from 'src/hooks/useAndroidInputHack';
import { fonts, getShadowNative, CONTAINER_PADDING_H_MOBILE, palette, typography } from 'src/styles';

interface Props {
  value: string;
  onChange?(value: string): void;
  placeholder?: string;
  onBackButtonPress?(): void;
}

export const MobileSearchInput: React.FC<Props> = ({ value, onChange, placeholder, onBackButtonPress }) => {
  const inputRef = useRef<TextInput>(null);

  const androidHackMargin = useAndroidInputHack(value);

  const handleClearButtonPress = () => {
    onChange?.('');
    inputRef.current?.focus();
  };

  return (
    <View style={styles.wrapper}>
      <IconButton
        name="back"
        onPress={onBackButtonPress}
        color={palette.navy}
        width={18}
        hitSlop={20}
        preserveAspectRatio="xMinYMid"
      />
      <View style={styles.inputWrapper}>
        <TextInput
          autoFocus
          value={value}
          onChangeText={onChange}
          ref={inputRef}
          style={[
            styles.input,
            { marginRight: androidHackMargin },
            value ? typography.text3 : typography.text2,
          ]}
          autoCapitalize="none"
          autoCompleteType="off"
          autoCorrect={false}
          placeholder={placeholder}
          placeholderTextColor={palette.grey4}
        />
      </View>
      {value.length > 0 && (
        <CircleIconButton
          name="close"
          outline
          backgroundColor={palette.navy}
          onPress={handleClearButtonPress}
          containerSize={28}
          width={10}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: palette.white,
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    ...getShadowNative(80, 0.15, 20, 0.06),
    zIndex: 1,
    height: 60,
  },
  inputWrapper: {
    flex: 1,
    marginHorizontal: 10,
  },
  input: {
    fontFamily: fonts.sourceSans,
    height: '100%',
    color: palette.grey8,
  },
});
