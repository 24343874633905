import Intercom, { shutdown, show, update } from '@intercom/messenger-js-sdk';
import { UserArgs } from '@intercom/messenger-js-sdk/dist/types';

import { EnvService } from 'src/services';

class IntercomService {
  private appId: string | undefined;

  constructor() {
    this.appId = EnvService.getEnv('INTERCOM_APP_ID');
  }

  public initialize(user: UserArgs): void {
    if (this.appId) {
      Intercom({
        app_id: this.appId,
        user_id: user.id,
        name: user.name,
        email: user.email,
        created_at: user.created_at || undefined,
        hide_default_launcher: true,
      });
    }
  }

  public clearUser(): void {
    shutdown();
  }

  public showMessenger(): void {
    show();
  }

  public updateUser(user: UserArgs): void {
    update(user);
  }
}

const instance = new IntercomService();

export { instance as IntercomService };
