import { useCallback, useEffect } from 'react';

import { WebSocketCloseCode } from 'src/constants/enums';
import { StudentVerificationResponseMessage } from 'src/constants/types';
import { WebSocketService } from 'src/services';

interface Params {
  verificationId: string;
  onSuccessMessage?(): void;
  onFailureMessage?(): void;
  onMessage?(message: StudentVerificationResponseMessage): void;
}

export const useStudentVerificationWebsocket = ({
  verificationId,
  onSuccessMessage,
  onFailureMessage,
  onMessage,
}: Params) => {
  const handleMessage = useCallback((event: MessageEvent) => {
    const data = JSON.parse(event.data) as StudentVerificationResponseMessage;
    if (data.action === 'student_verification_response') {
      if (data.status === 'success') {
        WebSocketService.close();
        onSuccessMessage?.();
      }
      if (data.status === 'failed') {
        onFailureMessage?.();
      }
      onMessage?.(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWebsocketConnectionOpen = useCallback(() => {
    WebSocketService.send({
      action: 'student_verification',
      verificationId,
    });
  }, [verificationId]);

  useEffect(() => {
    const subscriptions = [
      WebSocketService.subscribe('message', handleMessage),
      WebSocketService.subscribe('open', handleWebsocketConnectionOpen),
    ];

    return () => subscriptions.forEach((sub) => sub.remove());
  }, [handleMessage, handleWebsocketConnectionOpen]);

  useEffect(() => {
    WebSocketService.connect();

    return () => {
      WebSocketService.close(WebSocketCloseCode.Normal);
    };
  }, [handleMessage, handleWebsocketConnectionOpen]);
};
