import { default as React } from 'react';

import { Container, FullScreenTemplate, SectionSeparator } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import {
  AccountDetails,
  OccupationDetails,
  PersonalInformation,
  StudentSubscriptionEligibility,
} from '../components';

type Props = ScreenProps<Route.AccountInformation>;

export const AccountInformationScreen: React.FC<Props> = () => {
  const {
    isStudent,
    permissions: { hasAccessToStudentRenewals },
  } = useUserInfo();
  return (
    <FullScreenTemplate title={i18n.t('screens:accountInformation')} showBackButton>
      <Container verticalPadding>
        <AccountDetails />
        <SectionSeparator />
        <PersonalInformation />
        {isStudent && hasAccessToStudentRenewals && (
          <>
            <SectionSeparator />
            <StudentSubscriptionEligibility />
          </>
        )}
        <SectionSeparator />
        <OccupationDetails />
      </Container>
    </FullScreenTemplate>
  );
};
