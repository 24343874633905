import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { getShadow, ifWeb, palette } from 'src/styles';

interface Props {
  small?: boolean;
  style?: StyleProp<ViewStyle>;
  disableBottomShadow?: boolean;
  testID?: string;
}

export const BaseCard: React.FC<React.PropsWithChildren<Props>> = ({
  small,
  style,
  children,
  disableBottomShadow,
  testID,
}) => {
  const { isTablet } = useDeviceInfo();

  return (
    <View
      style={[
        styles.wrapper,
        small && styles.wrapperSmall,
        isTablet && styles.wrapperTablet,
        style,
        !disableBottomShadow && styles.shadow,
      ]}
      testID={testID || 'content-card-tile'}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 32,
    paddingBottom: 24,
    paddingHorizontal: 16,
    backgroundColor: palette.white,
  },
  wrapperTablet: {
    paddingHorizontal: 24,
  },
  wrapperSmall: {
    paddingTop: 32,
    paddingBottom: 16,
  },
  shadow: {
    ...ifWeb(getShadow(), getShadow(4, 0.4, 10)),
  },
});
