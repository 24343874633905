import React from 'react';
import { StyleSheet, View } from 'react-native';

import { GradientButton } from 'src/components';
import { Link } from 'src/navigation/components';
import { NavigationExtendedDropdown } from 'src/navigation/types';
import { ifWeb, typography } from 'src/styles';

type Props = NonNullable<NavigationExtendedDropdown['button']>;

export const ExtendedDropdownButton: React.FC<Props> = ({ title, to, external }) => {
  const button = (
    <View style={styles.wrapper}>
      <GradientButton
        title={title}
        height={70}
        style={styles.button}
        titleStyle={[typography.body2SemiBold, styles.title]}
        borderRadius={0}
        icon="arrow-right-thin"
        iconStyle={(isHovered) => [styles.icon, isHovered && styles.iconHovered]}
      />
    </View>
  );

  return to ? (
    <Link to={to} external={external}>
      {button}
    </Link>
  ) : (
    button
  );
};

const styles = StyleSheet.create({
  wrapper: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: 'hidden',
  },
  button: {
    justifyContent: 'flex-start',
    borderRadius: 0,
    paddingLeft: 60,
  },
  title: {
    textTransform: 'none',
  },
  icon: {
    ...ifWeb({
      transitionProperty: 'margin',
    }),
  },
  iconHovered: {
    marginLeft: 20,
  },
});
