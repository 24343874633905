import React, { useState } from 'react';

import { FullScreenTemplate } from 'src/components';
import { MobileSearchInput } from 'src/features/search/components';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { InteractionsSearchResults } from '../components/InteractionsSearchResults';

type Props = ScreenProps<Route.DICSearch>;

export const DICSearchScreen: React.FC<Props> = ({ route, navigation }) => {
  const [inputValue, setInputValue] = useState('');
  const [query] = useDebouncedValue(inputValue, 350);

  const handleGoBackButtonPress = () => {
    navigation.goBack();
  };

  return (
    <FullScreenTemplate
      title={i18n.t('screens:interactionChecker')}
      keyboardAvoiding
      noHeaderBorder
      noScroll
    >
      <MobileSearchInput
        value={inputValue}
        onChange={setInputValue}
        placeholder={i18n.t('search:placeholder:dic')}
        onBackButtonPress={handleGoBackButtonPress}
      />
      <InteractionsSearchResults
        query={query}
        selectedDrugs={route.params?.selectedDrugs ?? []}
        source={route.params?.source}
      />
    </FullScreenTemplate>
  );
};
