import { useContext, useEffect } from 'react';

import { GroupConversionFormContext } from './groupConversionForm';
import { GroupInvitationRegistrationFormContext } from './groupInvitationRegistrationForm';
import { RegularRegistrationFormContext } from './regularRegistrationForm';
import { RegistrationFormContext, RegistrationFormStep, RegistrationStepsData } from './types';

interface HookResult<T extends RegistrationFormStep> {
  /** Data submitted by this Step. `null` if Step hasn't been submitted yet. */
  data: RegistrationStepsData[T] | null;
  /** Indicates if Step has already been submitted */
  isSubmitted: boolean;
  /** Indicates if Step can be displayed and filled */
  isActive: boolean;
  /** Submits the Step. Assumes that provided `data` is already validated */
  submit(data: RegistrationStepsData[T]): void;
  onDirtyChange(isDirty: boolean): void;
}

interface Config {
  /** Callback invoked when registration form submit function catches errors related to provided FormStep */
  onError?: (errors: Record<string, string>) => void;
}

/**
 * Intender for use inside a single Registration Form Step.
 * Allows the Step form to communicate with Context.
 * Works in every Registration Form.
 */
export const useRegistrationFormStep = <T extends RegistrationFormStep>(
  step: T,
  { onError }: Config = {},
): HookResult<T> => {
  const { getStepData, isStepActive, onDirtyChange, submitStep, isStepSubmitted, on, steps } =
    useRegistrationForm();

  if (!steps.includes) {
    throw new Error('Step is not handled within this form');
  }

  const data = getStepData(step);

  useEffect(() => {
    /** Subscribes to `step-error` event and calls `onError` callback if errors are assigned to provided FormStep */
    if (onError) {
      return on('step-error', ({ step: stepWithErrors, errors }) => {
        if (stepWithErrors === step) {
          onError?.(errors);
        }
      });
    }
  }, [onError, on, step]);

  return {
    data,
    isSubmitted: isStepSubmitted(step),
    isActive: isStepActive(step),
    submit: (data: RegistrationStepsData[T]) => submitStep(step, data),
    onDirtyChange: (isDirty: boolean) => onDirtyChange(step, isDirty),
  };
};

/**
 * Returns a Registration Form Context that's currently in use.
 * Intended for use where you don't know/ care which Registration Form is in use.
 * Allows to access data and methods common for all Registration Forms, such as handling a specific Step.
 */
export const useRegistrationForm = () => {
  const regularSubscriptionContext = useContext(RegularRegistrationFormContext);
  const groupInvitationRegistrationContext = useContext(GroupInvitationRegistrationFormContext);
  const groupConversionFormContext = useContext(GroupConversionFormContext);

  const context =
    regularSubscriptionContext || groupInvitationRegistrationContext || groupConversionFormContext;

  if (!context) {
    throw new Error('Hook can be used only inside a Registration Form context');
  }

  return context as unknown as RegistrationFormContext<
    [
      RegistrationFormStep.CompleteYourProfile,
      RegistrationFormStep.CreateAnAccount,
      RegistrationFormStep.PaymentDetails,
      RegistrationFormStep.ReviewPlanDetails,
    ]
  >;
};
