import { debounce, difference, isEqual } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';

import { MixpanelEvent, MixpanelService, getMixpanelDICLocation } from 'src/features/tracking';

import { DICSource } from '../types';

const EVENT_DELAY = 5000;

/**
 * Hook keeps track of drugs selected in Interaction Checker and sends them in Mixpanel event
 * @param drugs List of drug names
 * @param source
 */
export const useInteractionsMixpanelEvent = (drugs: string[] = [], source?: DICSource) => {
  const [drugsToTrack, setDrugsToTrack] = useState(drugs);
  const trackedDrugs = useRef<string[]>([]);

  const trackEvent = useMemo(
    () =>
      debounce((drugs: string[]) => {
        const newDrugs = difference(drugs, trackedDrugs.current);
        if (drugs.length > 1 && newDrugs.length > 0) {
          const pairs = makePairs(drugs, newDrugs);
          MixpanelService.track(MixpanelEvent.DrugInteractions, {
            'Drugs Names': pairs,
            Location: getMixpanelDICLocation(source),
          });
          trackedDrugs.current = drugs;
        }
      }, EVENT_DELAY),
    [source],
  );

  useEffect(() => {
    if (!isEqual(drugs, drugsToTrack)) {
      setDrugsToTrack(drugs);
    }
  }, [drugs, drugsToTrack, setDrugsToTrack]);

  useEffect(() => {
    trackEvent(drugsToTrack);
  }, [drugsToTrack, trackEvent]);
};

const makePairs = (allDrugs: string[], newDrugs: string[]): string[] => {
  return allDrugs.flatMap((drug1, index) =>
    allDrugs
      .slice(index + 1)
      .filter((drug2) => newDrugs.includes(drug1) || newDrugs.includes(drug2))
      .map((drug2) => `${drug1}, ${drug2}`),
  );
};
