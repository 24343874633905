import MarkdownIt from 'markdown-it';

import { emphasisPlugin } from './plugins/emphasis';
import { MarkdownItWithPlain, plainTextPlugin } from './plugins/plainText';
import { SUB_TAG, subPlugin } from './plugins/subscript';
import { SUP_TAG, supPlugin } from './plugins/superscript';
import { textPlugin } from './plugins/text';
import { UNDERLINE_TAG, underlinePlugin } from './plugins/underline';
import { UPDATED_CONTENT_TAG, updatedContentPlugin } from './plugins/updatedContent';

export const markdownItInstance = MarkdownIt('zero', { typographer: false }) // typographer disabled to prevent converting ,, to ,
  .enable([
    'table',
    'list',
    'heading',
    'lheading',
    'paragraph',
    'text',
    'newline',
    'strikethrough',
    'emphasis',
    'link',
    'hr',
    'blockquote',
  ])
  .use(supPlugin)
  .use(subPlugin)
  .use(underlinePlugin)
  .use(updatedContentPlugin)
  .use(emphasisPlugin)
  .use(textPlugin)
  .use(plainTextPlugin) as MarkdownItWithPlain;

export { SUB_TAG, SUP_TAG, UNDERLINE_TAG, UPDATED_CONTENT_TAG };

export const limitedMarkupMarkdownItInstance = MarkdownIt('zero', { typographer: false }) // typographer disabled to prevent converting ,, to ,
  .enable(['paragraph', 'text', 'newline', 'emphasis'])
  .use(supPlugin)
  .use(subPlugin)
  .use(underlinePlugin)
  .use(emphasisPlugin)
  .use(textPlugin);
