import { useIsSignedIn, useUserInfo } from 'src/features/auth/hooks';
import { Route } from 'src/navigation';
import { useRoute } from 'src/navigation/hooks';
import { BRANDS_PANEL_HEIGHT, SEARCH_BAR_HEIGHT, TOP_NAV_PANEL_HEIGHT } from 'src/styles';

import { useDeviceInfo } from './useDeviceInfo';

const routesWithoutSearch = [Route.DIC];

export const useWebAppLayout = () => {
  const { isSmallMobile, isDesktop } = useDeviceInfo();
  const isSignedIn = useIsSignedIn();
  const route = useRoute();
  const { expiredSubscription } = useUserInfo();

  /** Sidebar is hideable, search box is displayed at top */
  const isMobileViewMode = !isDesktop;

  const isActiveUser = isSignedIn && !expiredSubscription;
  const isSidebarAvailable = isActiveUser;

  const isBrandsPanelDisplayed = !isSmallMobile;
  const isTopSearchBarDisplayed =
    isActiveUser && !isDesktop && !routesWithoutSearch.includes(route.name as Route);
  const isSidebarPermanentlyVisible = isSidebarAvailable && !isMobileViewMode;

  const topPanelHeight =
    (isBrandsPanelDisplayed ? BRANDS_PANEL_HEIGHT : 0) +
    TOP_NAV_PANEL_HEIGHT +
    (isTopSearchBarDisplayed ? SEARCH_BAR_HEIGHT : 0);

  return {
    topPanelHeight,
    isSidebarAvailable,
    isSidebarPermanentlyVisible,
    isBrandsPanelDisplayed,
    isTopSearchBarDisplayed,
    isMobileViewMode,
  };
};
