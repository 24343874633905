import { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { PlumbsLogo } from 'src/assets/images/logos';
import { StyledText } from 'src/components';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { isWeb, showNotification } from 'src/helpers';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import type { ScreenProps } from 'src/navigation/types';
import { palette, typography } from 'src/styles';

import { ResetPasswordConfirmationScreen, ResetPasswordForm, LoginFlowTemplate } from '../components';
import { usePasswordResetEmail } from '../hooks';
import type { ResetPasswordFormData, RessetPasswordErrors } from '../types';

type ResetPasswordScreenProps = ScreenProps<Route.ResetPassword>;

export const ResetPasswordScreen: React.FC<ResetPasswordScreenProps> = ({ route }) => {
  const { email } = route.params ?? {};
  const [submittedEmail, setSubmittedEmail] = useState('');
  const { mutate: sendResetPasswordEmail } = usePasswordResetEmail();

  const onSubmit = (values: ResetPasswordFormData, formikHelpers: FormikHelpers<ResetPasswordFormData>) => {
    sendResetPasswordEmail(values.email, {
      onSuccess: () => {
        setSubmittedEmail(values.email);
      },
      onError: (err: any) => {
        const { email } = getMessagesFromErrorResponse<RessetPasswordErrors>(err);
        if (email) {
          formikHelpers.setErrors({ email });
        } else {
          showNotification({ type: 'error' });
        }
      },
      onSettled: () => {
        formikHelpers.setSubmitting(false);
      },
    });
  };

  const title = i18n.t('resetPassword:resetPassword');

  return (
    <LoginFlowTemplate headerTitle={title} shouldDisplayBackButton>
      <View style={styles.wrapper}>
        {!isWeb && (
          <>
            <View style={styles.imageWrapper}>
              <PlumbsLogo height={30} />
            </View>
            <StyledText style={[typography.header1Bold, styles.title]}>{title}</StyledText>
          </>
        )}
        {submittedEmail ? (
          <ResetPasswordConfirmationScreen
            emailValue={submittedEmail}
            goBack={() => setSubmittedEmail('')}
          />
        ) : (
          <ResetPasswordForm email={email} onSubmit={onSubmit} />
        )}
      </View>
    </LoginFlowTemplate>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
  },
  imageWrapper: {
    marginBottom: 40,
  },
  title: {
    marginBottom: 30,
    color: palette.navy,
    textAlign: 'center',
  },
});
