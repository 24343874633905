const translations = {
  common: {
    all: 'All',
    cancel: 'Cancel',
    ok: 'OK',
    okay: 'Okay',
    email: 'Email',
    confirm: 'Confirm',
    emailAddress: 'Email Address',
    newEmail: 'New Email Address',
    newEmailConfirmation: 'Confirm New Email Address',
    enterNewEmail: 'Enter New Email Address',
    enterNewEmailConfirmation: 'Retype New Email Address',
    enterEmail: 'Enter Your Email',
    enterEmailAddress: 'Enter email address',
    password: 'Password',
    enterPassword: 'Enter Your Password',
    enterCurrentPassword: 'Enter Current Password',
    passwordNew: 'New Password',
    enterNewPassword: 'Enter New Password',
    passwordConfirm: 'Confirm New Password',
    error: 'Error',
    success: 'Success',
    unknownError: 'Unknown error occurred',
    tryAgain: 'Try again',
    createNew: 'Create New',
    noInternetConnection: 'An internet connection is required to log in.',
    commonErrorHeader: 'Oops! Something went wrong.',
    commonErrorMessage: 'Please check your internet connection or reload the page to try again.',
    retry: 'Retry',
    saveChanges: 'Save changes',
    cancelChanges: 'Cancel changes',
    goBack: 'Go back',
    exit: 'Exit',
    delete: 'Delete',
    showMore: 'Show more',
    showAll: 'Show all',
    save: 'Save',
    select: 'Select',
    print: 'Print',
    clearAll: 'Clear All',
    appHeader: `Plumb's Veterinary Drugs`,
    next: 'Next',
    acknowledge: 'Acknowledge',
    areYouSure: 'Are you sure?',
    remove: 'Remove',
    edit: 'Edit',
    pending: 'Pending',
    date: 'Date',
    needSubscription: 'A subscription is required to view the drug index.',
    register: 'Register',
    continue: 'Continue',
    helpCenter: 'Help Center',
    filter: 'Filter',
    sort: 'Sort',
    submit: 'Submit',
    pro: 'Pro',
    basic: 'Basic',
    editDetails: 'Edit Details',
    contactUs: 'Contact us',
    comingSoon: 'Coming Soon',
    new: 'New',
    updated: 'Updated',
    beta: 'Beta',
    rememberMe: 'Remember Me',
    startTypingToSearch: 'Start typing to search',
    viewAll: 'View All',
    backToTop: 'Back to Top',
    closeAll: 'Close All',
    openAll: 'Open All',
    done: 'Done',
    selectFiles: 'Select files',
    share: 'Share',
    download: 'Download',
    pdfFileLoadError: 'An error occurred while loading the file. Try again later.',
  },
  validation: {
    formikEmail: 'Not a valid email address',
    notAllEmailsAreValid: 'Not all emails are valid',
    emailShouldMatch: 'Emails must be the same',
    thisFieldIsRequired: 'Please fill out this field.',
    invalidField: 'Please enter a valid value',
    passwords_dont_match: `Entered passwords don't match.`,
    password_too_short: 'This password is too short. It must contain at least 8 characters.',
    password_too_common: 'This password is too common.',
    password_entirely_numeric: 'This password is entirely numeric.',
    password_no_upper: 'Your password must contain at least 1 uppercase letter, A-Z.',
    acceptRequired: 'You need to accept our Acceptable Use Policy',
    maxTextLength: 'This value must be at most {{max}} characters.',
    emailRequired: 'Please enter the email address you use to sign in.',
    enterValidEmail: `Please enter a valid email address (name@example.com).`,
    pleaseEnterEmail: `Please enter your email.`,
    pleaseEnterPassword: `Please enter your password.`,
    pleaseEnterNewEmail: `Please enter a new email address.`,
    pleaseSelectOption: `Please select an option.`,
    passSecondAttempt: `Sorry, that password isn't right. We can help you reset it.`,
    documents: {
      file_too_big:
        'One or more files you have uploaded exceeds the maximum file size. Please upload a file that is smaller than 5MB.',
    },
    shared_account_native: `App access is not available for users on custom accounts (most common large corporate practice groups and universities). Please login at app.plumbs.com.`,
    section: {
      cannot_match_section: `Can't match section name to any existing field.`,
      note_already_exists: 'Note for this section already exists.',
    },
    email: {
      invalid:
        'We do not recognize that email and password combination. Please try again, or reset your password.',
      email_not_changed: 'This is the same as your current email address.',
      email_user_already_exists:
        'This email address is unavailable. Please try another or contact us for help.',
    },
    emails: {
      users_already_associated_with_group:
        'These users are already associated with a group. Please try different email addresses.',
    },
    state: {
      state_empty: 'Please select your state.',
    },
    postalCode: {
      state_empty: 'Please select your state.',
    },
    quantity: {
      not_increased_number_of_licences: 'Can only increase number of licences.',
      single_plan_wants_multiple_licences: 'This plan can have only one licence.',
      practice_plan_wants_one_lincence: 'This plan must have more than one licence.',
    },
    plan_code: {
      not_accepted_plan_change: 'Cannot change a plan.',
    },
    discount_code: {
      invalid_coupon_code: 'Coupon code is invalid.',
    },
    detail: {
      repeat_password_limit: 'Too many unsuccessful login attempts. Please try again in 15 minutes.',
      no_active_account:
        'We do not recognize that email and password combination. Please try again, or reset your password.',
      no_active_user: 'The account is inactive.',
      inactive_account: 'This account is inactive.',
      email_not_changed: 'This is the same as your current email address.',
      email_user_already_exists:
        'This email address is unavailable. Please try another or contact us for help.',
      cannot_change_section: "Can't change a section.",
      invalid_email: 'This email is not a valid email address.',
      not_valid_subscription_state: 'Not valid subscription state.',
      no_subscription: 'There is no active subscription for this user.',
      invalid_password_reset_token: 'The password reset token was invalid.',
      invalid_email_token: 'The email change token was invalid.',
      invalid_group_token:
        'It looks like this link is invalid. Please ask your group admin to resend the invitation.',
      expired_invitation_link:
        'It looks like this link has expired (invitation links are valid for 7 days). Please ask your group admin to resend the invitation.',
      invalid_id: 'The ID is invalid.',
      admin_removing_himself_not_allowed: 'Not Allowed. Practice admin cannot remove himself.',
      invalid_membership_email:
        'The group invitation link is not valid. Please check your email again or contact the administrator.',
    },
    detailHeader: {
      invalid_membership_email: 'Your invitation link is invalid',
      invalid_group_token: 'Your invitation link is invalid',
      expired_invitation_link: 'Your invitation link is no longer active',
    },
    promotion: {
      not_found:
        "We do not recognize this URL. Please return to your group's registration page to resubmit the form.",
      promotion_expired:
        'This promotion is no longer active. Please contact your membership organization for more information.',
      promotion_used:
        'This promotion is no longer active. Please contact your membership organization for more information.',
      unique:
        "We didn't recognize your membership username as entered. Please try again or contact your membership organization.",
    },
    studentVerification: {
      not_found: 'We do not recognize this URL. Please resubmit the form.',
      student_verification_already_used: 'No further action needed, your registration is complete!',
      student_verification_different_user:
        "You don't have permission for this verification. To continue, you must re-verify your student status from the beginning",
      student_verification_link_has_expired:
        'The student verification has expired. To continue, you must re-verify your student status from the beginning.',
    },
  },
  errors: {
    header404: 'Page Not Found.',
    description404:
      '<p>Please check the URL or <homeLink>click here</homeLink> to\nbe redirected to the home page.</p>',
    header500: 'Something went wrong.',
    description500: 'Please check your internet connection\nor reload the page to try again.',
    commonErrorHeader: 'Oops! Something went wrong.',
    commonErrorMessage: 'Please check your internet connection or reload the page to try again.',
    internetConnectionHeader: "There's a problem with your internet connection.",
    internetConnectionMessage: 'This content will load once your connection is restored.',
    notFoundMessageHeader: 'Page not found.',
    notFoundMessageDescription: 'Please check the URL or go back to the home page.',
    noInternetNotification:
      'No internet connection.\nPlease restore your connection to continue using Plumb’s.',
    internetRestoredNotification: 'Your internet connection is back on!',
    serverIssueDescription:
      '<p>We’re having some issues with our servers. Please refresh the page to try again.\nIf this issue persists, please <contact>contact us</contact>.</p>',
    serverIssueMessage:
      'We’re having some issues with our servers. Please refresh the page to try again. If this issue persists, please contact us.',
  },
  signIn: {
    signIn: 'Sign In',
    signOut: 'Log out',
    signInButton: 'Sign In',
    signUpTip: 'New to Plumb’s™? Choose your plan now.',
    subscribe: 'Subscribe',
    forgotPassword: 'Forgot your password?',
    autologinErrorHeader: 'We don’t recognize this computer.',
    autologinErrorDescBanfield:
      "Please go to your PetWare software and click on the Plumb's link to authenticate this device and regain access to Plumb's.",
    expiredAccount: {
      yourAccountIsNotActive: 'Your group account is no longer active',
      restoreInfo:
        '<p>Your group administrator can restore access by <sales>contacting our sales team</sales>. If you believe you’re receiving this message in error, please <support>contact our support team</support>.</p>',
      needOwnAccountInfo:
        '<p><b>Need your own account?</b>\nChoose your own individual or multi-user subscription today for continued access to Plumb’s.</p>',
      createAnAccount: 'Create an account',
      signInWithAnotherAccount: 'Sign in with another account',
      multiUserPlanInfo:
        '<p>Don’t worry—we kept all of your notes and favorites safe for you. Your group administrator can restore access by <sales>contacting our sales team</sales>.</p>',
      seeMyOptions: 'See my options',
    },
  },
  signUp: {
    signUp: 'Sign Up',
    signUpButton: 'Sign Up',
    accept: 'I accept ',
    termsOfUse: 'Acceptable Use Policy',
    subscription: 'Subscription',
  },
  resetPassword: {
    resetPassword: 'Password recovery',
    resetPasswordButtonLabel: 'Reset my password',
    userNotFound: 'No user found for provided email. Make sure to provide email you registered.',
    resetPasswordSuccess:
      'Email with password reset link has been sent. After setting up new password, sign in with newly set password at the login screen.',
    backToLogin: 'Back to login',
  },
  changeEmail: {
    title: 'Email address ',
    tokenExpired: 'Sorry, your email change link has expired',
    tokenNotValid: 'Sorry, your email change link is not valid',
  },
  passwordRecovery: {
    title: 'Password Recovery',
    confirmationTitle: `If an account with this email address exists, a password reset link will be emailed.`,
    resendConfirmation:
      'We’ve resent you an email. If You have not received it again please go back and check Your email address',
    backToLoginButton: 'Back to log in',
    iDidntReceiveEmail: `Didn't receive an email?`,
    resend: 'Resend',
    sendAgain: 'Send again.',
    submit: 'Create New Password',
    resetPasswordConfirm: 'Your password has been changed',
    resetPasswordConfirmButton: 'back to log in',
    tokenExpired: 'Sorry, your password reset link has expired',
    tokenNotValid: 'Sorry, your password reset link is not valid',
    passwordChanged: 'Your password has been changed',
    linkExpired: 'Sorry, your password reset link has expired',
    tryAgain: 'Try again',
    passwordResent: 'Reset password email has been resent',
  },
  settings: {
    settings: 'Settings',
    signOutTitle: 'Sign out',
    signOutSubtitle: 'After you sign out, you will need to sign in again.',
    signOutAction: 'Sign out',
    signOutDialogTitle: 'Sign out',
    signOutDialogDescription: 'Are you sure you want to sign out?',
  },
  screens: {
    search: `Search`,
    interactionChecker: 'Interaction Checker',
    signIn: 'Sign In',
    signUp: 'Subscribe',
    subscription: 'Subscription',
    resetPassword: 'Reset Your Password',
    settings: 'Settings',
    accountInformation: 'Profile',
    subscriptionDetails: 'Subscription Details',
    calculator: 'Calculator',
    favorites: 'Favorites',
    notes: 'Notes',
    note: 'Note',
    home: 'Home',
    passwordRecovery: 'Password Recovery',
    about: 'About',
    academic: 'Academic',
    veterinary: 'Veterinary',
    appendix: 'Appendix',
    drugs: 'Drugs',
    drug: 'Drug',
    algorithms: 'Algorithms',
    algorithm: 'Algorithm',
    dxTx: 'Dx & Tx',
    ddx: 'DDx',
    noInternet: 'No Internet Connection',
    adminDashboard: 'Admin Dashboard',
    groupInvitation: 'Join Your Group',
    groupInvitationRegister: 'Register',
    groupConversion: 'Group Conversion',
    drugHandouts: 'Drug Handouts',
    clinicalHandouts: 'Clinical Handouts',
    handout: 'Handout',
    selectedHandouts: 'Selected Handouts',
    shareHandout: 'Share Handout',
    studentReverification: 'Student Reverification',
  },
  footer: {
    copyright: '© {{year}} VetMedux™, a division of ',
    company: 'Instinct',
    quickLinks: 'Quick Links',
    myAccount: 'My Account',
    aboutUs: 'About Us',
    product: 'Product',
    solutions: 'Solutions',
    resources: 'Resources',
    about: 'About',
    customerSupport: 'Customer Support',
    helpCenter: 'Help Center',
    plumbsTips: `Plumb's™ Tips`,
    ourStory: 'Our Story',
    blog: 'Blog',
    likeOnFacebook: 'Like on Facebook',
    followOnTwitter: 'Follow on Twitter',
    subscribe: 'Subscribe',
    features: 'Features',
    upgradeToPlumbsPro: `Upgrade to Plumb's Pro™`,
    plumbsDemo: `Plumb's™ Demo`,
    veterinaryProfessionals: 'Veterinary Professionals',
    veterinaryPractices: 'Veterinary Practices',
    pharmacists: 'Pharmacists',
    students: 'Students',
    universities: 'Universities',
    ourTeam: 'Our Team',
    handbookErrata: 'Handbook Errata',
    buyTheHandbook: 'Buy The Handbook',
    pricing: 'Pricing',
    contact: 'Contact Us',
    privacyPolicy: 'Privacy Policy',
    terms: 'Acceptable Use Policy',
    termsAndConditions: 'Terms & Conditions',
    signOut: 'Sign Out',
  },
  navigation: {
    getStarted: 'Get started',
    drugs: 'Drugs',
    appendix: 'Appendix',
    interactionChecker: 'Interaction Checker',
    quickTools: 'Quick Tools',
    clinicalHandouts: 'Clinical Handouts',
    drugHandouts: 'Drug Handouts',
    clinicalTools: 'Clinical tools',
    dxTx: 'Dx & Tx',
    algorithms: 'Algorithms',
    ddx: 'DDx',
    myAccount: 'My Account',
    notesAndFavorites: 'Notes & Favorites',
    profile: 'Profile',
    subscriptionDetails: 'Subscription Details',
    adminDashboard: 'Admin Dashboard',
    plumbsTips: `Plumb's™ Tips`,
    helpCenter: 'Help Center',
    contactSupport: 'Contact Support',
    signOut: 'Sign out',
    pro: 'Pro',
    goToTheApp: 'Go to the app',
    whyPlumbs: "Why Plumb's",
    solutions: 'Solutions',
    ourSolutions: 'Our Solutions',
    ourSolutionsDescription:
      'No matter which solution you choose, you can count on clear, actionable insights for every case.',
    pvd: 'Plumb’s Veterinary Drugs®',
    pvdDescription:
      'Easy-to-use digital veterinary drug reference with pet owner medication guides and a drug interaction checker',
    plumbsPro: 'Plumb’s Pro™',
    plumbsProDescription:
      'A full suite of clinical decision support, pet owner education, and trusted drug information and tools',
    compareSolutions: 'Compare Solutions',
    howPlumbsHelps: 'How Plumb’s™ Helps You',
    howPlumbsHelpsDescription:
      "There's a Plumb’s™ plan for everyone. Select the option that best describes you to learn more.",
    veterinarians: 'Veterinarian',
    veterinaryPractices: 'Veterinary Practice',
    students: 'Student',
    pharmacists: 'Pharmacist',
    academicInstitutions: 'Academic Institution',
    learnMore: 'Learn More',
    about: 'About',
    blog: 'Blog',
    pricingAndPlans: 'Pricing',
    help: 'Help',
    watchDemo: 'Watch a Demo',
    features: 'Features',
    seeAllFeatures: 'See All Features',
    drugsDescription: 'Find continually updated information on drugs used in animals',
    drugHandoutsDescription: 'Answer common pet prescription questions with easy-to-understand guides',
    interactionCheckerDescription: 'Check for potential drug-to-drug interactions in dogs and cats',
    quickToolsDescription: 'Save time with helpful tools like notes favorites and calculator',
    algorithmsDescription: 'Get step-by-step case support with clinical flowcharts',
    clinicalBriefsDescription: 'Review diagnostic and treatment information on critical clinical topics',
    clinicalHandoutsDescription: 'Help pet owners understand common conditions, procedures, and more',
    ddxDescription: 'Scan peer-reviewed lists of differentials to guide patient workup',
    powerOfPlumbs: 'The Power of Plumb’s™',
    prescribing: 'Make prescribing safer and simpler',
    prescribingDescription: 'Powered by Drug Monographs + Interaction Checker',
    caseWorkup: 'Find everything you need for case workup',
    caseWorkupDescription: 'Powered by Dx & Tx + Algorithms',
    trustedInformation: 'Send clients home with trusted information',
    trustedInformationDescription: 'Powered by Clinical Handouts + Drug Handouts',
    clinicalNotebook: 'Build your ultimate clinical notebook',
    clinicalNotebookDescription: 'Powered by Notes + Favorites',
    privacyPolicy: 'Privacy Policy',
    licenseAgreement: 'License Agreement',
    discoverPro_pro: 'Tour',
    discoverPro: 'Go',
    copyright: '© {{year}} VetMedux™, a division of Instinct',
  },
  contents: {
    clinicalHandout: 'Clinical Handout',
    contents: 'Contents',
    allContent: 'All Content',
    drugHandout: 'Drug Handout',
    hideAllNotes: 'Hide All Notes',
    notesHeader: '{{contentTitle}} Notes',
    overview: 'Overview',
    publicationDetails: 'Publication Details',
    showAllNotes: 'Show All Notes',
    new: 'New',
    updated: ' Updated',
  },
  drugs: {
    header: 'Drugs',
    tradeName: 'Trade name',
    tradeNameDescription: 'Trade name: {{value}}',
    drugClass: 'Drug class',
    noNotesHeader: "You haven't created any notes yet",
    noNotesText:
      'To create a note, hover over the relevant section of the monograph and click on the note icon.',
  },
  subscriptionDetails: {
    planDetails: 'Plan Details',
    planDetailsSubtitle:
      '<p>Need to make additional changes to your account? <support>Please contact customer support</support>.</p>',
    planType: 'Plan Type',
    billingCycle: 'Billing Cycle',
    annual: 'Annual',
    monthly: 'Monthly',
    users: 'Users',
    addMoreUsers: 'Add More Users',
    subscriptionTerm: 'Subscription Term',
    accountStatus: 'Account Status',
    active: 'Active',
    canceled: 'Canceled Renewal',
    inactive: 'Inactive',
    yourSubscriptionIsInactive: 'Your subscription is currently inactive.',
    inactiveSubscriptionFormerGroupMemberDetails:
      'Your account is no longer part of {{groupName}} group. For continued access to Plumb’s, you can choose your own plan now.',
    inactiveSubscriptionExpiredManagedGroup:
      'The {{groupName}} account has expired. For continued access to Plumb’s, choose your own plan now.',
    inactiveSubscriptionReactivate:
      '<p>Click <b>Reactivate Subscription</b> to preview your options and regain access to Plumb’s.</p>',
    subscriptionWillRenew: 'Your current subscription term will renew on <b>{{date}}</b>.',
    subscriptionWillExpire: 'Your current subscription will expire on <b>{{date}}</b>.',
    trialWillRenew:
      'Your free trial will end and your card will be charged the subscription fee on <b>{{date}}</b>.',
    trialWillExpire: 'Your free trial will end on <b>{{date}}</b>.',
    reactivateRenewal: 'Reactivate Renewal',
    reactivateSubscription: 'Reactivate Subscription',
    subscriptionWillExpireWarning:
      'Your current subscription will expire on <b>{{date}}</b>. Please <u>reactivate renewal</u> to retain access to Plumb’s.',
    usersReductionInfo: '<p>Contact <support>customer support</support> to reduce the number of users.</p>',
    upgradeToPro: 'Upgrade to Pro',
    discoverPro: '<p>Get support for your whole case. <goPro>Discover Plumb’s Pro</goPro>.</p>',
    contactSalesToUpgrade: '<p><sales>Contact sales</sales> to upgrade to Plumb’s Pro.</p>',
    contactToUpgradePromotional:
      "<p>If you'd like to use your {{campaignName}} discount to upgrade to Plumb's Pro, please contact the <customerService>Plumb's customer service team</customerService>.</p>",
    paymentsRemaining: '<b>{{remaining}} of {{total}}</b> payments remaining.',
    numMonths: '{{count}} month',
    numMonths_plural: '{{count}} months',
    paymentDetails: 'Payment Details',
    paymentDetailsSubtitle: 'Valid credit card must be on file at all times.',
    paymentHistory: 'Payment History',
    totalPaid: 'Total Paid',
    invoiceNumber: 'Invoice Number',
    generatePDF: 'Generate PDF',
    openPDF: 'Open PDF',
    actions: 'Actions',
    lookingForOlderInvoices:
      '<p>Looking for invoices before {{date}}?\nPlease reach out to <support>customer support.</support></p>',
    reactivateRenewalConfirm:
      'By clicking “Reactivate Renewal” below, you agree that your subscription will automatically renew and your card on file will be charged {{amount, price}} for the next renewal period on {{renewalDate, dateMonthName}}. The price does not include taxes, coupons or discounts.',
    comparePlans: 'Compare Plans',
    hidePlans: 'Hide Plans',
    pvdDescription: 'A complete, searchable veterinary drug reference with practical prescribing tools',
    proDescription:
      'A single point-of-care resource with tools for every step of the veterinary patient experience—diagnosis, treatment, and even pet owner education',
    includes: 'Includes:',
    pvdList:
      '<li>Continually updated drug monographs</li><li>First-of-its-kind drug interaction checker</li><li>Printable medication guides for pet owners</li><li>Conversion calculator</li><li>Personalization with notes and favorites</li>',
    proList:
      '<li>All the tools and content in Plumb’s Veterinary Drugs</li><li>Continually updated monographs on clinical conditions</li><li>Algorithms offering step-by-step diagnostic and treatment support</li><li>Easy-to-understand pet owner guides on clinical signs, diagnoses, procedures, and more</li>',
    explorePvd: 'Explore Plumb’s Veterinary Drugs',
    explorePro: 'Explore Plumb’s Pro',
    newPlanDetails: 'New Plan Details',
    promoPlanInfo: '{{plan}} ({{intro}})',
    pricePerUser: '{{count}} user at {{price}}/user/{{period}}',
    pricePerUser_plural: '{{count}} users at {{price}}/user/{{period}}',
    accountCredit: 'Account credit',
    annualDiscount: '{{value}}% annual billing discount',
    amountDue: 'Amount Due',
    nextAnnualPayment: 'Next annual payment ({{date}})',
    nextMonthlyPayment: 'Next monthly payment ({{date}})',
    dueToday: 'Due today',
    accountCreditExplainer:
      'Your unused credit from your previous subscription has been applied to the cost of your new subscription.',
    accountCreditExplainerWithBalance:
      'Your unused credit from your previous subscription has been applied to the cost of your new subscription. Your remaining balance of {{balance}} will be applied to your next payment.',
    promotionRefund:
      'The credit card currently on file will be refunded <b>{{amount, price}}</b>, and your renewal date will change to <b>{{date, dateMonthName}}</b>.',
    creditCardInformation: 'Credit Card Information',
    billingAddress: 'Billing Address',
    address: 'Address',
    city: 'City',
    zipCode: 'Zip Code',
    country: 'Country',
    state: 'State',
    editPaymentInformation: 'Edit Payment Information',
    iAgreeToTerms: 'I agree to the <terms>Terms of Use</terms> and <privacy>Privacy Policy.</privacy>',
    confirmPurchase: 'Confirm Purchase',
    subscriptionPurchaseDisclaimer:
      'By clicking Purchase Subscription, You agree that Your subscription will automatically renew on its anniversary date for a successive term and the payment method You provided will be charged the applicable subscription fee. You may remove the automatic renewal feature anytime by visiting the Subscription Details page.',
    thisIsYourCurrentPlan: 'This is your current plan. Select other options to preview changes.',
    needPlumbsForMoreUsers:
      "Need Plumb’s for a large practice or a group of practices?\nWe'll build you a custom plan. <support>Let's talk!</support>",
    contactSupport: 'Contact Support',
    contactToReduceUsers:
      '<support>Contact customer support</support> to reduce the number of users or get a custom plan for your needs.',
    yourRole: 'Your Role/Occupation',
    enterBillingInformationToReactivate:
      'To reactivate renewal, please enter your billing information below',
    applyChanges: 'Apply changes',
    billingAddressTaxInfo: 'Changes to billing address may affect sales tax and amount due.',
    billingAddressTaxInfoRenewal:
      'Changes to billing address may affect sales tax and amount due at renewal.',
  },
  cancelRenewal: {
    cancelRenewal: 'Cancel renewal',
    cancelYourRenewal: 'Cancel your renewal?',
    cancelTrial: 'Cancel trial',
    cancelYourTrial: 'Cancel your trial?',

    whenYouCancelAnnual:
      'When you cancel your renewal, <b>your account will remain active until your subscription term ends on {{termEnd, dateMonthName}}</b>.',
    whenYouCancelMonthly:
      'When you cancel your renewal, <b>your account will remain active and your card will continue to be charged until your subscription term ends on {{termEnd, dateMonthName}}</b>.',
    whenYouCancelTrial:
      '<p>When you cancel your trial, <b>your account will remain active until your trial ends on {{periodEnd, dateMonthName}}</b>.',
    userNoAccess: "After that, you won't be able to access any of the information or tools in Plumb’s.",
    groupNoAccess:
      'After that, your subscription will expire and <b>all invited users on your account will lose access</b> to the information and tools in Plumb’s. If your team members would like to keep their accounts, they can sign in and purchase their own plans.',
    contentUsageProhibited:
      'Please note that using any Plumb’s content after a subscription has expired is strictly prohibited.',
    textSingleAnnual: `<p>$t(whenYouCancelAnnual) $t(userNoAccess) $t(contentUsageProhibited)</p>`,
    textMultiAnnual: `<p>$t(whenYouCancelAnnual) $t(groupNoAccess)</p>
    <p>$t(contentUsageProhibited)</p>`,
    textSingleMonthly: `<p>$t(whenYouCancelMonthly) $t(userNoAccess) $t(contentUsageProhibited)</p>`,
    textMultiMonthly: `<p>$t(whenYouCancelMonthly) $t(groupNoAccess)</p>
    <p>$t(contentUsageProhibited)</p>`,
    textSingleTrial: `<p>$t(whenYouCancelTrial) $t(userNoAccess) $t(contentUsageProhibited)</p>`,
    textMultiTrial: `<p>$t(whenYouCancelTrial) $t(groupNoAccess)</p>
    <p>$t(contentUsageProhibited)</p>`,

    areYouSureToCancel: 'Are you sure you want to cancel?',
    areYouSure: `<h2>Are you sure you want to cancel?</h2>`,
    basicFeatures: `From continually updated information to new drug monographs, fresh features, and the growing library of medication guides, we're always adding something new to better support you in practice.`,
    proFeatures: `From continually updated diagnostic and treatment information to new drug monographs, fresh features, and the growing library of pet owner guides, we're always adding something new to better support you in practice.`,

    features_basic: `From continually updated information to new drug monographs, fresh features, and the growing library of medication guides, we're always adding something new to better support you in practice.`,
    features_pro: `From continually updated diagnostic and treatment information to new drug monographs, fresh features, and the growing library of pet owner guides, we're always adding something new to better support you in practice.`,
    keepYourSubscription: 'Keep your subscription to stay up to date!',
    subscribeToStay: 'Subscribe to stay up to date!',
    whatYouWillMissBasic: `$t(areYouSure)
    <p>$t(basicFeatures) $t(keepYourSubscription)</p>`,
    whatYouWillMissPro: `$t(areYouSure)
    <p>$t(proFeatures) $t(keepYourSubscription)</p>`,
    whatYouWillMissBasicTrial: `$t(areYouSure)
    <p>$t(basicFeatures) $t(subscribeToStay)</p>`,
    whatYouWillMissProTrial: `$t(areYouSure)
    <p>$t(proFeatures) $t(subscribeToStay)</p>`,

    havingIssues: `<p><b>Having technical issues?</b> <support>Please contact us—we're happy to help.</support></p>`,
    weCanHelp: `<support>We can help with that.</support>`,
    contactSupportMultiPro: `$t(havingIssues)
    <p><b>Need to change your plan or remove licenses?</b> $t(weCanHelp)</p>`,
    contactSupportMultiBasic: `$t(havingIssues)
    <p><b>Need to upgrade your plan or remove licenses?</b> $t(weCanHelp)</p>`,
    contactSupportSinglePro: `$t(havingIssues)
    <p><b>Need to change your plan type or billing cycle?</b> $t(weCanHelp)</p>`,
    contactSupportSingleProTrial: `$t(havingIssues)
    <p><b>Need to make changes to your account?</b> $t(weCanHelp)</p>`,
    contactSupportSingleBasic: `$t(havingIssues)
    <p><b>Need to change your plan or upgrade?</b> $t(weCanHelp)</p>`,
  },
  search: {
    header: "Search Plumb's",
    all: 'All',
    drugs: 'Drugs',
    handouts: 'Handouts',
    ddx: 'DDx',
    placeholder: {
      handouts: 'Search by condition or drug',
      algorithms: 'Search by topic or keyword',
      all: 'Search all content',
      appendix: 'Search appendix',
      basicHandouts: 'Search by drug or trade name',
      dic: 'Search by generic or trade name',
      drugs: 'Search drugs by generic or trade name',
      dxtx: 'Search by disease or clinical sign',
      ddx: 'Search by clinical sign',
    },
    contentType: 'Content type',
    appendix: 'Appendix',
    resultsFound: '{{count}} result found in ',
    resultsFound_plural: '{{count}} results found in ',
    results: '{{count}} result',
    results_plural: '{{count}} results',
    filterBy: 'Filter by',
    filter: 'Filter',
    noResultsTitle: 'No results',
    noResultsFiltersInfo:
      'We couldn’t find any results matching these criteria. Try adjusting your filters.',
    algorithm: 'Algorithms',
    'clinical-briefs': 'Dx & Tx',
    headerAll: 'Search all',
    headerDrugs: 'Search drugs',
    headerHandouts: 'Search handouts',
    headerClinicalBriefs: 'SEARCH Dx & Tx',
    headerAlgorithms: 'Search Algorithms',
    headerAppendix: 'Search Appendix',
    headerDDx: 'Search DDx',
    noResults: "We couldn't find anything that matches your search. Please try a different search term.",
  },
  dic: {
    subtitle:
      'Start typing a drug name and select a match from the dropdown list below. Add as many drugs as needed. Once your list is complete, review veterinary-specific guidance on potential drug-to-drug interactions. New drug information is continually being added, and some drugs may not yet be available.',
    searchPlaceholder: 'Enter a drug name then click to select from list',
    disclaimer:
      '<p1>Results generated by the interaction checker represent one-to-one drug interactions only and are intended to supplement the knowledge and clinical judgment of veterinarians using this tool.</p1><p>Information is limited to dogs and cats and does not include potential interactions with supplements, herbal therapies, or nutraceuticals. The drug interaction checker does NOT include physical and chemical compatibility of injectable drug formulations. For compatibility information, see the <bi>Compounding/Compatibility Considerations</bi> section of the respective drug monograph.</p><p>VetMedux™ assumes no responsibility or liability for errors or omissions of any kind in the content.</p>',
    classificationKey: 'Classification Key',
    drugClassificationLegend: 'Interaction Classifications',
    useWithCaution: 'Use With Caution',
    contraindicateDesc:
      'Data demonstrate that the compared drugs are likely to interact with each other and present a significant clinical concern. Use together is contraindicated. In cases of absolute clinical necessity, use of this drug combination should only be undertaken when no other options exist and with clear, unmistakable communication between all medical personnel and pet owners about risks and what adverse effects warrant immediate action.',
    moderateDesc:
      'Data demonstrate that the compared drugs may interact with each other and present a significant clinical concern. Patients should be assessed to determine if the benefits of this drug combination outweigh the risks. Patients should be closely monitored for development of adverse effects; dose adjustments of either drug or use of alternative therapies may be necessary.',
    majorDesc:
      'Data demonstrate that the compared drugs may interact with each other and present a significant clinical concern. Concurrent use should be avoided if possible; alternate therapies should be considered. If concurrent use cannot be avoided, patients should be assessed to determine if the benefits of this drug combination outweigh the risks. Patients should be closely monitored to minimize any potential adverse effects anticipated with this drug combination; dose adjustments of either drug may be required.',
    minorDesc:
      'Data demonstrate that the compared drugs may interact with each other and present a clinical concern. The benefits of this drug combination often outweigh the risks. Patients should be monitored for the presence of adverse effects; dose adjustments of either drug may be necessary.',
    noDataDesc:
      'This drug combination has either not been evaluated to be included in the Interaction Checker or there are no data available for the combined use of these drugs. Please reference the monograph details for each drug and use clinical judgment.',
    noKnownDesc: 'Data demonstrate that these drugs do not interact.',
    Contraindicated: 'Contraindicated',
    major: 'Major',
    filterMajor: 'Use with caution (major)',
    minor: 'Minor',
    filterMinor: 'Use with caution (minor)',
    moderate: 'Moderate',
    filterModerate: 'Use with caution (moderate)',
    noKnown: 'No known interaction',
    noData: 'No Data',
    noResults: 'No results. Check your spelling or use a different search term.',
    showAllKnownInteractionsFor: 'Show all known interactions for {{name}}',
    showAllKnownInteractionsDisclaimer: 'To see specific interactions, add more drugs to your list.',
    noInteractionsFound: 'No interactions were found under this classification.',
    selectedDrugs: 'Selected Drugs',
    noDrugsSelected: 'Search above to add drugs you would like to check for interactions',
    searchAndSelect: 'Search above and select one or more drugs to check for interactions.',
    interactionResults: 'Interaction results',
    interactionsNoDrugsSelected: "Results will appear here once you've selected one or more drugs.",
    seeInteractionsForDrug:
      '<p><button>See interactions for {{name}}</button> or select more drugs to check for drug-to-drug interactions.</p>',
    noInteractionsForThisDrug: 'No interactions were found for this drug.',
    searchInput: 'Enter a drug name',
    searchInput_anotherDrug: 'Enter another drug name',
  },
  drawerRoutes: {
    myAccount: 'My Account',
    userGuide: 'User Guide',
    helpCenter: 'Help Center',
    privacyPolicy: 'Privacy Policy',
    license: 'License Agreement',
    pricing: 'Pricing',
    plumbsTips: `Plumb’s Tips`,
    profile: 'Profile',
    signOut: 'Sign Out',
    support: 'Support',
  },
  main: {
    whatsNew: 'WHAT’S NEW IN PLUMBS’S',
    savePrecious: 'Save Precious Time with Plumb’s Newest Features',
    findOutMore: 'Find out more about the new and upcoming features!',
    learnMore: 'Learn More',
    myFavorites: 'My Favorites',
    myDrugNotes: 'My Drug Notes',
  },
  favorites: {
    favorites: 'Favorites',
    addToFavorites: 'Add to Favorites',
    removeFromFavorites: 'Remove from Favorites',
    noFavoritesTitle: 'You don’t have any favorites yet.',
    noFavoritesInfo: 'Click on the star icon to save content here for quick access.',
    noFavoritesMessage:
      'You haven’t added any favorites yet. Click on the star icon to save content here for quick access.',
    all: 'All Favorites',
    drugs: 'Favorite Drugs',
    appendix: 'Favorite Appendices',
    drugHandouts: 'Favorite Drug Handouts',
    clinicalHandouts: 'Favorite Clinical Handouts',
    dxTx: 'FAVORITE Dx & Tx',
    ddx: 'FAVORITE DDx',
    ddxDropdown: 'Favorite DDx',
    algorithms: 'Favorite Algorithms',
    clinicalBriefsDropdown: 'Favorite Dx & Tx',
  },
  notes: {
    confirmModal: {
      header: 'Are you sure?',
      header_navigation: 'You haven’t saved your changes',
      content_cancel: "The changes haven't been saved.",
      content_remove: 'Your note will be permanently deleted. This cannot be undone.',
      content_navigation:
        'If you navigate away from your note without saving, your changes will be lost. Are you sure?',
      cancelButton: 'Cancel',
      cancelButton_navigation: 'Go Back',
      actionButton_remove: 'Delete',
      actionButton_cancel: 'Exit',
      actionButton_navigation: 'Discard Changes',
    },
    placeholder: 'Start typing here...',
    unsavedWarningHeader: 'Are you sure you want to exit?',
    removeWarningHeader: 'Are you sure?',
    unsavedWarningContent: "The changes haven't been saved.",
    removeWarningContent: 'Your note will be permanently deleted. This cannot be undone.',
    myNotes: 'My Notes',
    generalNotes: 'General Notes',
    title: 'Title',
    generalNote: 'General Note',
    newNote: 'New Note',
    filterText: 'Filter by:',
    filterAll: 'All',
    filterGeneral: 'General',
    filterDrugMonograph: 'Drugs',
    filterAppendix: 'Appendix',
    filterClinicalBriefs: 'Dx & Tx',
    editNote: 'Edit Note',
    addNote: 'Add Note',
    deleteNote: 'Delete note',
    noNotes:
      'You haven’t created any general notes yet. Click Add Note above to save tips and preferred protocols here for easy access later.',
    noTitleError: 'Please enter a title.',
    noContentError: 'Please enter a note.',
    general: 'General',
    drugs: 'Drugs',
    appendix: 'Appendix',
    dxTx: 'Dx & Tx',
    ddx: 'DDx',
  },
  accountInformation: {
    accountDetails: 'Account Details',
    personalInformation: 'Personal Information',
    studentSubscriptionEligibility: 'Student Subscription Eligibility',
    studentSubscriptionEligibilityLink: 'Please use this link to complete your verification process',
    occupationDetails: 'Occupation Details',
    changeEmail: 'Change email',
    changePassword: 'Change password',
    emailChangeConfirmTitle: 'Verify your new email address',
    emailChangeConfirmDesc:
      'We’ve sent you an email!\n Please click the link inside to verify your new email address.',
    groupInfoText: `You are a member of the <b>{{groupName}}</b> group.\nIf you have questions please contact your group administrator <b>{{adminName}}</b> at <email>{{groupAdminEmail}}</email> or reach out to our support team.`,
    groupInfoTextNoAdminName: `You are a member of the <b>{{groupName}}</b> group.\nIf you have questions please contact your group administrator at <email>{{groupAdminEmail}}</email> or reach out to our support team.`,
    groupInfoTextNoAdmin:
      'You are a member of the <b>{{groupName}}</b> group.\nIf you have questions please contact our <support>support team</support>.',
  },
  appendix: {
    header: 'Appendix',
    notFound: "We couldn't find an appendix with given id",
  },
  drugHandouts: {
    header: 'Drug Handouts',
  },
  clinicalHandouts: {
    header: 'Clinical Handouts',
  },
  handouts: {
    optionsHeader: 'Handout Options',
    selectedHandoutsHeader: 'Selected Handouts',
    shareHandoutHeader: 'Share Handout',
    handouts: 'Handouts',
    handoutsLanguage: {
      english: 'English',
      spanish: 'Spanish',
      both: 'Both',
    },
    handout: 'Handout',
    handout_plural: 'Handouts',
    otherNames: 'Other names',
    otherNamesDescription: 'Other names: {{value}}',
    otherNamesForThisMedication: 'Other names for this medication',
    otherNamesForCondition: 'Other names for this condition',
    otherNamesForProcedure: 'Other names for this procedure',
    otherNamesForVaccine: 'Other names for this vaccine',
    description: 'Description',
    commonDosageForms: 'Common dosage forms',
    download: 'Download Handout',
    download_plural: 'Download Handouts',
    print: 'Print Handout',
    print_plural: 'Print Handouts',
    email: 'Email Handout',
    email_plural: 'Email Handouts',
    subject: 'Subject',
    from: 'From',
    subjectValue: 'Your Handout(s)',
    emailLinkNote: `Your veterinarian has sent the attached handout(s) directly to you through Plumb’s, a trusted veterinary resource.

This information is for educational purposes only and should not replace veterinary care.

Please contact your veterinarian directly if you have any questions or concerns related to your pet's health or if you have difficulty accessing your handout(s).`,
    select: 'Select Handout',
    emailTo: 'Email To',
    confirmEmailTo: 'Confirm Email To',
    emailMessage: 'Email Message',
    sendingFrom: 'Sending From',
    cc: 'CC',
    bcc: 'BCC',
    confirmCC: 'Confirm CC',
    confirmBCC: 'Confirm BCC',
    addInstructions: 'Add Instructions',
    addInstructionsPlaceholder: 'Add personalized notes to be included at the end of the handout(s).',
    shareSelectedHandouts: 'Share Selected Handouts',
    noSelectedHandouts:
      "<p>You don't have any handouts selected yet. Search for handouts and tap <b>Select</b> to add them here for easy sharing with pet owners.</p>",
    selectMoreHandouts:
      'Need to share more than one handout? Search for the handouts you need and tap <b>Select</b> to add them here for easy sharing.',
    downloadDisclaimer:
      'Please note: Pet owner handouts should not be saved offline or on external platforms. Please download handouts at time of use to ensure you are distributing the most current information to pet owners. <terms>See terms of use</terms>.',
    availableInSpanish: 'Available in Spanish',
    handoutLanguage: 'Handout Language',
    handoutLanguageDisclaimer:
      'Any selected handouts not available in Spanish will be shared in English instead',
  },
  user: {
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    phoneLabel: 'Phone Number',
    bornYearLabel: 'What year were you born?',
    bornYearPlaceholder: 'Select...',
    bornYearNull: 'Prefer Not to Answer',
    address1Label: 'Address 1',
    address2Label: 'Address 2 (Optional)',
    practiceLabel: 'Practice or University Name',
    birthDateLabel: 'Birth Date',
    day: 'Day',
    month: 'Month',
    year: 'Year',
    cityLabel: 'City',
    postalCodeLabel: 'Zip Code',
    countryLabel: 'Country',
    countryPlaceholder: 'Select...',
    stateLabel: 'State',
    statePlaceholder: 'Select...',
    stateRequiredError: 'Please select your state.',
    activityLabel: 'Which category best describes your business/professional activity?',
    activityPlaceholder: 'Select...',
    activity: {
      'exclusive-small-animal-practice': 'Exclusive Small Animal Practice',
      'over-50-small-animal-practice': 'Over 50% Small Animal Practice',
      'mixed-animal-practice': 'Mixed Animal Practice',
      'exclusive-feline-practice': 'Exclusive Feline Practice',
      'exclusive-large-animal-practice': 'Exclusive Large Animal Practice',
      'exclusive-equine-practice': 'Exclusive Equine Practice',
      government: 'Government',
      'academic-teaching-library-research': 'Academic, Teaching, Library, Research',
      other: 'Other',
      pharmacy: 'Pharmacy',
      'student-veterinary-medical': 'Student: Veterinary Medical',
      'student-veterinary-technician': 'Student: Veterinary Technician',
      'student-pharmacy': 'Student: Pharmacy',
    },
    activityOtherLabel: 'Other (Please specify)',
    pharmacyLabel: 'What is your professional focus?',
    pharmacy: {
      'academic-teaching-library-research': 'Academic, Teaching, Library, Research',
      retail: 'Retail (Independent/Chain)',
      'specialty-compounding': 'Specialty/Compounding',
      'veterinary-hospital': 'Veterinary Hospital',
      government: 'Government',
      other: 'Other',
    },
    pharmacyOtherLabel: 'Other (Please specify)',
    graduationYearLabel: 'Anticipated Graduation Date',
    graduationYearRequiredError: `Please select your graduation date.`,
    graduationYearPlaceholder: 'Select...',
    schoolAttendingLabel: 'School Attending',
    schoolAttendingRequiredError: 'Please select your school.',
    schoolAttendingPlaceholder: 'Select...',
    schoolAttendingOtherLabel: 'Other School (Please specify)',
    schoolGraduatedLabel: 'What veterinary school did you graduate from? (Optional)',
    schoolGraduatedPlaceholder: 'Select...',
    schoolGraduatedOtherLabel: 'Other School (Please specify)',
    roleLabel: 'What best describes your position?',
    rolePlaceholder: 'Select...',
    roleOtherLabel: 'Other position (please specify)',
    other: 'Other',
    role: {
      'owner-partner': 'Owner/Partner',
      'multiple-practice-owner': 'Multiple Practice Owner',
      associate: 'Associate',
      'other-veterinarian': 'Other Veterinarian',
      'other-non-veterinarian': 'Other',
      'certified-veterinary-technician-veterinary-nurse':
        'Certified Veterinary Technician/Veterinary Nurse',
      'non-certified-veterinary-technician-veterinary-nurse':
        'Non-Certified Veterinary Technician/Veterinary Nurse',
      'practice-manager-office-manager': 'Practice Manager/Office Manager',
      'veterinary-assistant': 'Veterinary Assistant',
      receptionist: 'Receptionist',
      'clinic-owner': 'Clinic Owner',
      professor: 'Professor',
    },
    veterinarian: 'Veterinarian',
    nonVeterinarian: 'Non-Veterinarian',
    yearsOfPracticeLabel: 'Number of Years in Practice',
    yearsOfPractice: {
      '0-4': 'Less Than 5 Years',
      '5-10': '5-10 Years',
      '11-15': '11-15 Years',
      '16-20': '16-20 Years',
      '20-and-more': '20+ Years',
      'not-applicable': 'Not Applicable',
    },
    yearsOfPracticePlaceholder: 'Select...',
    numberOfVeterinariansPlaceholder: 'Select...',
    numberOfVeterinariansLabel: 'How many full-time equivalent veterinarians do you have in your location?',
    numberOfVeterinarians: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10-and-more': '10 or more',
      'not-applicable': 'Not Applicable',
    },
    practiceTypeLabel: 'Which best indicates your practice type?',
    practiceTypePlaceholder: 'Select...',
    practiceType: {
      'general-practice': 'General Practice',
      'emergency-practice': 'Emergency Practice',
      'specialty-practice': 'Specialty Practice',
      'teaching-hospital': 'Teaching Hospital',
      'mobile-practice': 'Mobile Practice',
      shelter: 'Shelter',
      other: 'Other',
    },
    practiceTypeOtherLabel: 'Other Practice Type (please specify)',
    practiceOwnership: {
      'private-practice': 'Private Practice',
      'corporate-practice': 'Corporate Practice',
      'not-applicable': 'Not Applicable / Not Sure',
    },
    practiceOwnershipLabel: 'Is your practice privately held or part of a corporate group?',
    practiceOwnershipPlaceholder: 'Select...',
  },
  subscriptionProcess: {
    subscription: 'Subscription',
    thankYouForSubscribing: 'Thank You for subscribing!',
    subscriptionConfirmed:
      "Your subscription is confirmed and we've sent your receipt to {{email}}.\nYou're ready to start using Plumb's!",
    createAnAccount: 'Create an Account',
    reviewPlanDetails: 'Review Plan Details',
    completeYourProfile: 'Complete Your Profile',
    paymentDetails: 'Payment Details',
    reviewSubscription: 'Review Your Subscription',
    enterYourEmail: 'Enter Your Email Address',
    createAPassword: 'Create a Password',
    yourRole: 'Your Role/Occupation',
    yourPlumbPlan: `Your Plumb's Plan`,
    numOfUsers: `Your Plan's Number of Users`,
    viewPlanLabel: `View Plan Details`,
    addDiscountLabel: `Add Discount Code`,
    addressInformation: `Address Information`,
    creditCardInformation: `Credit Card Information`,
    billingAddress: `Billing Address`,
    enterDiscountCode: 'Enter a Discount Code',
    applyDiscount: `Apply Discount`,
    clear: 'Clear',
    annual: 'Annual',
    monthly: 'Monthly',
    userDiscount: '{{count}}+ user discount',
    user: 'user',
    user_plural: 'users',
    pricePerUser: '{{value, price}}/$t(subscriptionProcess:user)',
    pricePerUserMultipleUsers:
      '$t(subscriptionProcess:pricePerUser) x $t(subscriptionProcess:numUsers, { "count": {{users}} })',
    pricePerUsers: '{{count}} user at {{value, price}}/user/{{period}}',
    pricePerUsers_plural: '{{count}} users at {{value, price}}/user/{{period}}',
    savePricePerUser: 'Save $t(subscriptionProcess:pricePerUserMultipleUsers)',
    pricePerUserPerPeriod: '$t(subscriptionProcess:pricePerUser)/{{period}}',
    pricePerPeriod: '{{value, price}}/{{period}}',
    price: '{{value, price}}',
    subtotal: 'Subtotal',
    totalDue: 'Total Due',
    dueToday: 'Due Today',
    dueAtRenewal: 'Due at Renewal',
    dueAfterTrial: 'Total after free trial',
    perPeriod: 'Per {{period}}',
    discounts: 'Discounts',
    credits: 'Credits',
    discountAndCredits: 'Discounts & Credits',
    salesTax: 'Sales tax',
    estimatedSalesTax: 'Estimated sales tax',
    calculatedAtCheckout: 'Calculated at checkout',
    amountDueToday: 'Amount Due Today',
    estimatedDueAtRenewal: 'Estimated Amount Due at Renewal',
    estimatedDueAtRenewalInfo:
      'This estimate is subject to change. Actual amount due will be provided 14 days prior to your renewal.',
    year: 'year',
    month: 'month',
    month_plural: 'months',
    useAboveAddress: 'Use the above address.',
    accountStatus: 'Account Status',
    discountCode: 'Discount Code',
    studentDiscountOff: 'Student discount <color>({{amount, price}} off)</color>',
    discountValueOff: 'Discount Code <b>{{code}}</b> <color>({{amount, price}} off)</color>',
    promotionDiscountValueOff: '{{campaign}} discount <color>({{amount, price}} off)</color>',
    promotionCalculationBoxIntro: '{{plan}} ({{intro}})',
    emailLabel: 'Email Address',
    emailPlaceholder: 'Email Address',
    confirmEmailLabel: 'Confirm Email',
    confirmEmailPlaceholder: 'Confirm Email',
    confirmEmailDescription: `You'll use this email to sign in and to receive renewal reminders.`,
    studentEmailDescription:
      'A student email address is preferred. This email address is used for verification purposes only.',
    passwordLabel: 'Password',
    passwordPlaceholder: 'Password',
    confirmPasswordLabel: 'Confirm Password',
    confirmPasswordPlaceholder: 'Confirm Password',
    studentDisclaimerHeader: `Enjoy the benefits of Plumb's for just {{value, price}}/year`,
    studentProDisclaimerHeader: `Enjoy the benefits of Plumb's Pro for just {{value, price}}/year`,
    studentDisclaimerContent: `If you're a veterinary student attending an accredited veterinary medicine program, you're eligible for a discounted subscription. To request your discount, please fill in the form.`,
    studentRenewal: {
      studentRenewalSuccessHeader: 'Your student status has been confirmed!',
      studentRenewalSuccessDescription:
        'You’re eligible for another year of Plumb’s Pro™ at the student rate. If you confirm your renewal, your card on file will be charged <b>{{studentPlansPrice}} USD</b> on <b>{{subscriptionExpirationDate}}</b>. If you cancel your renewal, your card will not be charged, and your access will expire on <b>{{subscriptionExpirationDate}}</b>.',
      studentRenewalNoInstantSuccessHeader: 'Action needed to confirm your student status',
      studentRenewalNoInstantSuccessDescription:
        'We were unable to verify your student status with the enrollment information provided. This can happen if your student documents are out of date or missing information. To prevent your Plumb’s Pro™ subscription from expiring on <b>{{subscriptionExpirationDate}}</b>, please upload new documents to reverify your status.',
      studentRenewalRemind: 'Remind me later',
      confirmRenewalLabel: 'Confirm Renewal',
      cancelRenewalLabel: 'Cancel Renewal',
      reverifyLabel: 'Re-verify',
      graduatedLabel: "I've graduated",
    },
    verifyMyStudentStatus: 'Verify my student status',
    purchaseSubscription: 'Purchase Subscription',
    planInformation: 'Plan Information',
    documentsProcessingHeader: 'Reviewing your documents...',
    documentsProcessingInfo:
      "Hang tight! We're uploading and reviewing your documents.\nYou can wait here or we'll email you when this step is complete.",
    documentsError: 'We were unable to confirm your eligibility. Please try uploading other documents.',
    documentsLimitReachedMessage:
      'You have exceeded the document review limit. Fill in the form to try again.',
    documentsUploadHeader: 'Upload Documentation',
    documentsUploadSubheader:
      'Documents will be reviewed in a few minutes by staff at SheerID, a trusted partner.',
    documentsUploadDescription: `<p>Upload your school-issued document that shows:</p>
      <li>Your full name</li>
      <li>Your school</li>
      <li>Date proving enrollment for the current term (or within the last 3 months)</li>
      <p>Acceptable examples include:</p>
      <li>School ID card with expiration date</li>
      <li>Class schedule</li>
      <li>Tuition receipt</li>
      `,
    documentsUploadFilesRequirements: 'File must be .jpg .jpeg .png .pdf .gif or .bmp',
    studentSSOHeader: 'Verify using your school credentials',
    studentSSODescription: `Please sign in to your school's web portal. It will open in a new tab.`,
    signInToMySchool: 'Sign in to my school',
    ssoCantSignIn: `Can't sign in to your email?`,
    uploadProofOfEnrollment: 'Upload Proof of Enrollment',
    university: 'University',
    dontSeeYourSchool: "<p>Don't see your school? <requestLink>Request to have it added</requestLink>.</p>",
    verifiredStudentMessage: 'Your student status has been successfully verified.',
    studentVerificationError:
      'Something went wrong with your verification. Please [contact our support team]({{supportLink}}).',
    plan: 'Plan',
    users: 'Users',
    billingCycle: 'Billing Cycle',
    contractTerm: 'Contract Term',
    numMonths: '{{count}} months',
    numUsers: '{{count}} $t(subscriptionProcess:user)',
    numUsers_plural: '{{count}} $t(subscriptionProcess:user)',
    accountInformation: 'Account Information',
    fullName: 'Full Name',
    address: 'Address',
    country: 'Country',
    phoneNumber: 'Phone Number',
    cardInformation: 'Credit Card Information',
    nameOnCard: 'Name on Card',
    cardNumber: 'Card Number',
    expiration: 'Expiration',
    brand: 'Brand',
    cvv: 'CVV',
    emailConfirmation: 'You will receive an email confirmation at <b>{{email}}</b>.',
    termsAgree:
      '<p>I agree to the <terms>Terms & Conditions</terms>, <usePolicy>Acceptable Use Policy</usePolicy> and <privacyPolicy>Privacy Policy</privacyPolicy>.</p>',
    disclaimer: {
      student:
        'By clicking Purchase Subscription, You agree that Your payment method will be charged the total due and Your subscription will automatically expire at the end of the account term. To regain access to Your account, reverify Your eligibility through SheerID and purchase a new discounted subscription.',
      vetOrPharmacist:
        'By clicking Purchase Subscription, You agree that Your subscription will automatically renew on its anniversary date for a successive term and the payment method You provided will be charged the applicable subscription fee. You may remove the automatic renewal feature anytime by visiting the Subscription Details page.',
    },
    needFewerLicenses: 'Need fewer licenses? ',
    contact: 'Contact us.',
    payAnnually: 'Pay Annually',
    payMonthly: 'Pay Monthly',
    bestValue: 'Best value',
    savePercent: 'Save {{count}}%',
    annualDiscount: '{{value}}% annual billing discount',
    needMoreLicenses:
      '<p>Need access for more users? <support>Contact our customer support team</support>.</p>',
    wantToChangeYourPlan: '<p>Want to change your plan type? <contact>Contact us</contact>.</p>',
    proTrialTitle: 'Start Your Free 30‑Day Trial of Plumb’s Pro',
    trialTitle: 'Start Your Free 30‑Day Trial',
    proTrialSubtitle:
      'Sign up now and for 30 days you’ll have access to the full suite of tools in Plumb’s Pro, so you can make a treatment plan, confirm dosages, check drug interactions, and provide pet owners with all the information they need—all with a few clicks. See for yourself why veterinarians love Plumb’s Pro.',
    trialSubtitle:
      'Sign up now and for 30 days you’ll have full access to the continually updated prescribing information and practical tools in Plumb’s Veterinary Drugs—from any device.',
    proTrialInfo:
      'Create an account and enter your payment details to start using Plumb’s Pro. You won’t be charged anything today, and you can cancel anytime before the end of your free trial to avoid being charged.',
    trialInfo:
      'Create an account and enter your payment details to start using Plumb’s Veterinary Drugs. You won’t be charged anything today, and you can cancel anytime before the end of your free trial to avoid being charged.',
    trialProPlanDetailsHeader: 'Plumb’s Pro',
    trialProDetails:
      'A single point-of-care resource with tools for every step of the veterinary patient experience—diagnosis, treatment, and even pet owner education.​​',
    trialProListHeader: 'Your free trial includes access to:',
    trialProList:
      '- All the tools and content in Plumb’s Veterinary Drugs\n- Continually updated monographs on clinical conditions\n- Algorithms offering step-by-step diagnostic and treatment support\n- Easy-to-understand pet owner guides on clinical signs, diagnoses, procedures, and more',
    trialPlanDetailsHeader: 'Plumb’s Veterinary Drugs',
    trialDetails: 'A complete, searchable veterinary drug reference with practical prescribing tools',
    trialListHeader: 'Your free trial includes access to:',
    trialList:
      '- Continually updated monographs on 800+ drugs\n- Drug interaction checker\n- Printable medication guides for pet owners\n- Conversion calculator\n- Personalization with notes and favorites',
    trialBillingCycleHeader: 'Your Billing Cycle',
    trialBillingCycleDetails: 'Select annual billing to save 10% on your subscription.',
    trialFooter: `Your free trial begins on <highlight1>{{start}}</highlight1> and will end on <highlight2>{{end}}</highlight2>. You can cancel anytime before <highlight3>{{end}}</highlight3> to avoid being charged the subscription fee.
    We'll send an email reminder 5 days before your trial ends.`,
    paymentDetailsTrialSubtitle: 'Why do we need this for a free trial? ',
    paymentDetailsTrialPopoverHeader: "You won't be charged until your free trial ends.",
    paymentDetailsTrialPopoverText:
      "We ask for your payment information to provide a seamless subscription experience. You can cancel anytime before {{date}} to avoid being charged, and we'll send you an email reminder 5 days before your free trial ends.",
    faq: 'Frequently Asked Questions',
    faqWillIBeChargedQ: 'Will I be charged during my free trial?',
    faqWillIBeChargedA:
      "No, you won't be charged during your free trial, which begins on <highlight>{{start}}</highlight> and ends on <highlight>{{end}}</highlight>. You can cancel anytime before <highlight>{{end}}</highlight> to avoid being charged.",
    faqHowCancelQ: 'How can I cancel during my free trial?',
    faqHowCancelA:
      "Once you're signed up, you can cancel your subscription from the Subscription Details page under the My Account menu. <help>Learn more about how to cancel on our Help Center.</help>",
    faqWhatAfterQ: 'What happens after the free trial period?',
    faqWhatAfterA:
      "If you do not cancel before your free trial period ends on <highlight>{{date}}</highlight>, you will be automatically charged for your subscription and will be able to continue accessing Plumb's until you cancel.",
    accountCredit: 'Account Credit',
    accountCreditExplainer:
      'Your unused credit from your previous subscription has been applied to the cost of your new subscription.',
    accountCreditExplainerWithBalance:
      'Your unused credit from your previous subscription has been applied to the cost of your new subscription. Your remaining balance of {{balance}} will be applied to your next payment.',
    subscriptionInvitationInvalid: 'Invitation link is invalid! Please, contact our sales representative.',
    planDetails: 'Plan Details',
  },
  studentVerification: {
    emailLoopHeader: 'Check your email',
    emailLoopDescription:
      'An email has been sent to your email account with a personalized link to complete the verification process.\nPlease check for an email from us for all the details.',
    emailLoopSkip: '<p>Unable to retrieve this email? Click <button>here</button> to proceed.</p>',
    resend: 'Resend',
    emailLoopInvalidToken:
      'URL is incorrect. Please check your email again or click Resend button to generate a new token.',
    emailResent: 'Email resent successfully',
  },
  occupationVariants: {
    vetPro: 'Veterinary Professional',
    vetStudent: 'Veterinary Student',
    pharmacist: 'Pharmacist',
    pharmacyStudent: 'Pharmacy Student',
  },
  planVariants: {
    normal: `Plumb's Veterinary Drugs`,
    pro: `Plumb's Pro`,
  },
  recurly: {
    nameLabel: 'First Name on Card',
    namePlaceholder: 'First Name',
    surnameLabel: 'Last Name on Card',
    surnamePlaceholder: 'Last Name',
    address1Label: 'Address 1',
    address1Placeholder: 'Address',
    address2Label: 'Address 2 (Optional)',
    address2Placeholder: 'Additional Address',
    cityLabel: 'City',
    cityPlaceholder: 'City Name',
    countryLabel: 'Country',
    countryPlaceholder: 'Select...',
    postalCodeLabel: 'Zip Code',
    postalCodePlaceholder: 'Zip Code',
    stateLabel: 'State',
    statePlaceholder: 'Select...',
    cardLabel: 'Credit Card Number',
    cardPlaceholder: 'credit Card Number',
    lastFourError: 'Invalid Credit Card Number.',
    monthLabel: 'Expiration Date (MM)',
    monthPlaceholder: 'MM',
    expMonthError: 'Invalid value.',
    yearLabel: 'Expiration Date (YY)',
    yearPlaceholder: 'YY',
    expYearError: 'Invalid value.',
    cvvLabel: 'Security Code (CVV)',
    cvvPlaceholder: 'CVV',
    cvvError: 'Invalid CVV.',
    apiError: 'Something went wrong with connection to server. Please try again later.',
    isInvalid: 'Invalid value.',
    mustBeThreeDigits: 'CVV must be three digits.',
    mustBeFourDigits: 'CVV must be four digits.',
  },
  contentType: {
    appendix: 'Appendix',
    algorithm: 'Algorithm',
    'clinical-brief-condition': 'Dx & Tx',
    'clinical-brief-sign-situation': 'Dx & Tx',
  },
  contentTypeLabel: {
    appendix: 'Appendix',
    algorithm: 'Algorithm',
    dxTx: 'Dx & Tx',
    ddx: 'DDx',
    pro: 'pro',
    drug: 'Drug',
    handout: 'Handout',
  },
  calculator: {
    calculator: 'Calculator',
    infinity: 'Infinity',
    clear: 'Clear',
    unit: {
      lbs: 'lbs',
      kg: 'kg',
      oz: 'oz',
      g: 'g',
      mg: 'mg',
      ug: 'µg',
      ml: 'ml',
      floz: 'fl oz',
      degF: '°F',
      degC: '°C',
    },
  },
  adminDashboard: {
    'my-group': 'My Group',
    'practice-location': 'Practice Location',
    'subscription-details': 'Subscription Details',
    groupName: 'Group Name',
    createGroupName: 'Create Group Name',
    inviteNewUsers: 'Invite New Users',
    addMore: 'Add More',
    licencesInfo: 'You are using {{used}} of {{total}} licenses.',
    mixedGroupLicencesInfo:
      'You are using {{usedBasic}} of {{totalBasic}} Basic licenses and {{usedPro}} of {{totalPro}} Pro licenses.',
    proLicencesInfo: 'You are using {{usedPro}} of {{totalPro}} Pro licenses.',
    basicLicencesInfo: 'You are using {{usedBasic}} of {{totalBasic}} Basic licenses.',
    resendInvitation: 'Resend invitation',
    cancelInvitation: 'Cancel invitation',
    passwordReset: 'Password reset',
    removeFromGroup: 'Remove from group',
    admin: 'Admin',
    userName: 'User Name',
    member: 'Member',
    dateCreatedOrStatus: 'Date Created/Status',
    actions: 'Actions',
    removeUserWarning: `When you remove a user from the group, they will no longer have access to any Plumb's content.`,
    cancelInvitationWarning: `When you cancel a user's invitation, they will no longer be able to join your group.`,
    inviteHeader: 'Invite New Users',
    inviteDescription:
      '<p>You have <highlighted>{{count}} license</highlighted> available. Invite new users by entering their email addresses below. Please make sure the addresses are correct before sending the invitations.</p>',
    inviteDescription_plural:
      '<p>You have <highlighted>{{count}} licenses</highlighted> available. Invite new users by entering their email addresses below. Please make sure the addresses are correct before sending the invitations.</p>',
    needMoreLicenses: 'Need more licenses?',
    upgradePlan: 'Upgrade your plan.',
    invite: 'Invite',
    tooManyInvitationsError: 'You have only {{count}} license left',
    tooManyInvitationsError_plural: 'You have only {{count}} licenses left',
    invitationCancelWarning: 'This user will no longer be able to join your group.',
    emailsInputLabel: 'Enter email addresses separated by a space or comma',
    membersOfOtherGroupWarningHeader: 'Sorry, this user is already associated with a group.',
    membersOfOtherGroupWarningHeader_plural: 'Sorry, these users are already associated with a group.',
    membersOfOtherGroupWarningInfo:
      'Try another email address or ask the user to contact their current group administrator. Once the user is no longer associated with another group, you can resend the invitation.',
    membersOfOtherGroupWarningInfo_plural:
      'Try another email address or ask the users to contact their current group administrator. Once the user is no longer associated with another group, you can resend the invitation.',
    noAvailableLicenses: 'All {{role}} licenses are in use.',
    groupMember: 'Group member',
    basicGroupMember: 'Basic member',
    proGroupMember: 'Pro member',
    supportStaff: 'Support staff',
    groupAdmin: 'Group admin',
    inviteSupportStaffInfo:
      'Your plan includes one support staff license. This limited access role gives one user permissions to view, print, and email pet owner guides only. Filling this role does not affect your available full access licenses.',
    noAvailableSupportStaffUnknownUser:
      "Your group's support staff license is in use.\nIf you would like to transfer this role to another user, please reassign the current user to another role first.",
    noAvailableSupportStaff:
      "Your group's support staff license is in use by {{username}}.\nIf you would like to transfer this role to another user, please reassign the current user to another role first.",
    role: 'Role',
    userAlreadyHasASubscription: 'This user already has a subscription.',
    editRole: 'Edit role',
    editUserRole: 'Edit User Role',
    userRole: 'User Role',
    editRoleGroupWitSupportStaffInfo:
      'Use the drop-down below to adjust this user’s role. You can select Veterinarian or Support staff. Your account includes one support staff license, which limits the user’s permissions to view, print, and email pet owner guides.',
    swapRoleInfo: 'This role will be swapped with current Support staff role.',
    changeToRegularUserInfo:
      'This action will use 1 group member license, and you will be able to assign the support staff role to another user.',
    noLicenseAvailableInfo:
      "All licenses are currently in use. To upgrade this user's role, please add more licenses to your plan.",
    failed: 'Failed',
    pending: 'Pending',
    expired: 'Expired',
    pendingInfo: 'Invitation has been delivered. Awaiting response.',
    expiredInfo: 'No response from the recipient. Please resend invitation.',
    rejectedInfo: 'We were unable to deliver the invitation. Please check the email address and try again.',
    complaintInfo:
      'We were unable to deliver the invitation. Please check the email address and try again.',
    noEmailInfo:
      'Invitation could not be delivered because the email address does not exist. Please cancel the invitation and try again.',
    mailboxFullInfo:
      'Invitation could not be delivered because the recipient’s mailbox is full. Please try again later or contact our support.',
    bounceOtherInfo: 'We were unable to deliver the invitation email. Please contact our support.',
    license: 'License',
    basicLicense: 'Basic license',
    proLicense: 'Pro license',
  },
  canadianDisclaimer: {
    title: 'Disclaimer',
    content:
      'The approved claims, doses, and withdrawal periods on Canadian labels could be different than those provided here. Drugs should be administered to animals according to the regulatory approval in the user’s country. If a product is used in a food-producing species in an extra-label manner in Canada, it is the veterinarian’s responsibility to determine a withdrawal period adequate to ensure that no residues are present in meat or milk.',
    agree: 'I acknowledge that I have read and will adhere to Canadian regulations.',
  },
  notifications: {
    success: 'Success!',
    error: 'Something went wrong. Please try again.',
  },
  groupInvitation: {
    confirmSupportStaffDescription:
      'This user already has an active subscription and may lose access to some features. Are you sure you want to send an invitation?',
    groupInvitation: 'Join Group',
    tokenNotValid:
      'The group invitation link is not valid. Please check your email again or contact the administrator.',
    successMessage: 'You have joined the group.',
    thankYouForRegistering: 'Thank You for registering!',
    registrationConfirmed: "You're ready to start using Plumb's!",
    confirmEmailLabel: 'Confirm Email',
    confirmEmailDescription: `You'll use this email to sign in.`,
    modalHeader: 'Join Your Group',
    modalSubheader: `You've been invited to join the\n{{groupName}} group account.`,
    modalSubheaderNoGroupName: `You've been invited to join a group account.`,
    modalDescription:
      'Your existing account (including your notes and favorites) will be merged with your group, and you will be refunded for any remaining service term on your account. To confirm this change, click Join Group.',
    modalDescriptionStaff:
      'Your existing account will be merged with your group, and you will be refunded for any remaining service term on your existing subscription. Your new group account will allow you to view, print, and email pet owner guides only. To confirm this change, click Join Group.',
    joinGroup: 'Join Group',
    noThanks: 'No, thanks',
    invitationInvalid: 'The grup invitation is not valid. Please contact the administrator.',
  },
  sessionsLimit: {
    activeSessions: 'Active Sessions',
    pause: 'Pause Session',
    needMoreSessions: 'Need more sessions?',
    activeSessionsDisclaimer:
      "Your Plumb's account is already in use on two devices. Please pause a session to continue.",
    upgradePlan: 'Upgrade your plan',
    unknownOS: 'Unknown System',
    unknownBrowser: 'Unknown Browser',
    logOutFromAllDevices: 'Sign out of all sessions',
  },
  proxyLoginErrors: {
    header: "We don't recognize this IP address.",
    disclaimerUniversity:
      'Please contact your library resources coordinator for assistance.\nThis is the IP address we are able to detect: {{ip}}',
    disclaimerCorporate:
      'Please contact your network administrator or IT support.\nThis is the IP address we are able to detect: {{ip}}',
    couldntDetectIP: "We couldn't detect your IP address",
  },
  ipLoginUser: {
    signInHere: 'Sign in here.',
    needToAccessToPersonal: 'Need to access your personal account?',
    accessYourAccount: 'Access your account',
    accessYourAccountDescription:
      'You will be redirected to sign in to your account. If you are using a public computer, be sure to sign out when you are done.',
    drugs: 'Drugs',
    drugsDescription: 'Find dosages, dosage forms, indications, and more for 800+ drugs.',
    dic: 'Interaction Checker',
    dicDescription: 'Check for potential drug-to-drug interactions in dogs and cats.',
    pogBasicDescription: 'Search the library of medication guides and share with clients.',
    pogProDescription: 'Search the library of patient and medication guides and share with clients.',
    clinicalHandouts: 'Clinical Handouts',
    clinicalHandoutsDescription:
      "Help pet owners understand their pet's clinical conditions, procedures, and preventive care.",
    drugHandouts: 'Drug Handouts',
    drugHandoutsDescription:
      'Answer common pet prescription questions with easy to understand handouts on the drugs you prescribe.',
    appendix: 'Appendix',
    appendixDescription:
      'Review practical information on toxins, blood products, overdose treatments, and more.',
    clinicalBriefsDescription:
      'Get clear guidance on diagnosis and treatment for clinical conditions and signs.',
    algorithmsDescription: 'Get step-by-step case support with practical flowcharts.',
    helpTitle: 'Need Help?',
    helpDescription: 'Get quick tips and answers to your FAQs in our training center.',
  },
  accountExpiration: {
    thisAccountWillExpireOn: 'This group account will expire on {{date, dateMonthName}}',
    pleaseReachOut:
      'Please reach out to your IT department or practice admin with any questions. Once the account expires, you can purchase your own subscription to regain access.',
    warningBar: '$t(thisAccountWillExpireOn). $t(pleaseReachOut)',
  },
  dxTx: {
    consensusStatement: 'Consensus Statement',
    clinicalChallengesQuestion: 'Have a clinical challenge that isn’t addressed here?',
    clinicalChallengesAction: 'Submit it to our team for possible inclusion in a future update.',
    noClinicalChallengesQuestion: 'Have you experienced an issue or curious encounter with this condition?',
    noClinicalChallengesAction:
      'Tell us about it. Submit details here for potential inclusion in a future update.',
    clinicalChallengesActionNative:
      'Share your challenge with our team for possible inclusion in a future update.',
    clinicalChallengesSubmit: 'Submit a Clinical Challenge here',
    nthOf: '{{nth}} of {{count}}',
    videoDidNotLoad: 'Your browser does not support this file type',
  },
  tabNavigator: {
    home: 'Home',
    notes: 'Notes',
    features: 'Features',
    tools: 'Tools',
  },
  contentStatusDescriptions: {
    inDevelopment:
      'Great content takes time. Our team is working on this topic, which will be available in the coming months.',
    fallback: 'Description currently unavailable',
  },
  paywall: {
    header: 'Is it time to go Pro?',
    description:
      'Unlock access to in-the-workflow clinical content and essential tools by upgrading to Plumb’s Pro.',
    maybeLater: 'Maybe Later',
    tellMeMore: 'Tell Me More',
    bannerHeader: `You're previewing the Pro experience`,
    bannerDescription: `<p>Scroll this page to explore the full list of topics or try a keyword search to get a sneak peek at what you can access when you upgrade to Plumb’s Pro. Interested in all Pro has to offer? <goPro>Learn more here</goPro>.</p>`,
    bannerDescriptionNative: `<p>Curious what you get when you upgrade to Plumb's Pro?\nExpand each letter to get a sneak peek at the benefits of <goPro>going Pro</goPro>.</p>`,
  },
  planBenefits: {
    title: 'Plan Benefits',
    helpTitle: "We're here to help",
    helpText:
      "The Plumb's support team is on hand when you need us.\nSend us an email and let us know how we can help!",
    helpButton: 'Contact Support',
    proTipsTitle: 'See what you can do with Pro',
    proTipsText:
      "From a demo video and self-guided tour to quick-start guides and FAQs, you'll find everything you need to get the most out of your subscription on the Plumb’s Tips page.",
    proTipsButton: 'See All the Tips',
    tipsTitle: 'See what you can do with Plumb’s',
    tipsText:
      "From a demo video and self-guided tour to quick-start guides and FAQs, you'll find everything you need to get the most out of your subscription on the Plumb’s Tips page.",
    tipsButton: 'See All the Tips',
    questionsTitle: 'Questions?',
    questionsText:
      'The Help Center is always open. Search by topic or keyword to find a vast array of answers to common questions about Plumb’s.',
    questionsButton: 'Explore the Help Center',
    goBeyondTitle: 'Go beyond prescribing with Plumb’s Pro',
    goBeyondText:
      "Did you know there's more to Plumb's than drug information? See what you can do with the full suite of clinical decision support in Plumb's Pro.",
    goBeyondButton: 'Learn More',
    clinicalMonographs:
      'Peer-reviewed clinical monographs covering presentation, diagnosis, treatment, and more for hundreds of clinical conditions and signs',
    algorithms:
      'An extensive library of diagnostic and management algorithms for step-by-step case support',
    petOwnerGuides: 'Pet owner guides covering procedures, conditions, medications, and more',
    calculatorNotesFavorites: 'Built-in conversion calculator plus notes and favorites',
    drugReference: 'Full drug reference covering dosages, adverse effects, and more on 800+ drugs',
    dic: 'Animal-specific drug interaction checker',
    calculator: 'Built-in conversion calculator',
    notesFavorites: 'Notes and favorites',
    medicationGuides: 'Easy-to-share medication guides for counseling pet owners',
  },
  groupConversion: {
    header: 'Activate Subscription',
    frozenGroupTitle: 'Your account is currently frozen',
    frozenGroupDescription:
      '<p>To regain access, please click below to fill out your new subscription form and reactivate your account. If you have questions, <contact>please contact us</contact>.</p>',
    activateSubscription: 'Activate Subscription',
    notFound: 'Something went wrong. Please contact the administrator.',
  },
  features: {
    header: 'Features',
    drugs: 'Drugs',
    drugsDescription: 'Find dosages, dosage forms, indications, and more for 800+ drugs.',
    dic: 'Interaction Checker',
    dicDescription: 'Check for potential drug-to-drug interactions in dogs and cats.',
    calculator: 'Calculator',
    calculatorDescription: 'Convert common units and calculate doses.',
    appendix: 'Appendix',
    appendixDescription:
      'Review practical information on toxins, blood products, overdose treatments, and more.',
    dxTx: 'Dx & Tx',
    clinicalBriefsDescription:
      'Get clear guidance on diagnosis and treatment for clinical conditions and signs.',
    algorithms: 'Algorithms',
    algorithmsDescription: 'Get step-by-step case support with practical flowcharts.',
    clinicalHandouts: 'Clinical Handouts',
    clinicalHandoutsDescription:
      "Help pet owners understand their pet's clinical conditions, procedures, and preventive care.",
    drugHandouts: 'Drug Handouts',
    drugHandoutsDescription:
      'Answer common pet prescription questions with easy to understand handouts on the drugs you prescribe.',
    ddx: 'DDx',
    ddxDescription: 'Search by clinical sign to get a list of differential diagnoses.',
  },
  tools: {
    header: 'Tools',
    calculator: 'Calculator',
    calculatorDescription: 'Convert common units and calculate doses.',
    notes: 'Notes',
    notesDescription: 'Save your tips and preferred protocols for easy access anytime.',
    selectedHandouts: 'Selected Handouts',
    selectedHandoutsDescription: 'Select multiple handouts to package and share with clients all at once.',
  },
  recentlyViewed: {
    recentlyViewed: 'Recently Viewed',
    topViewed: 'Top Viewed',
    noItems: "You haven't viewed any content yet. Try a search.",
  },
  featureButtons: {
    dic: 'Interaction Checker',
    addNote: 'Add Note',
    editNote: 'View/Edit Note',
    addToList: 'Add to list',
    viewClinicalHandouts: 'View Clinical Handout',
    viewClinicalHandouts_plural: 'View Clinical Handouts',
    viewDrugHandouts: 'View Drug Handout',
    viewDrugHandouts_plural: 'View Drug Handouts',
  },
  ieWarning: {
    header: 'Time for a browser upgrade!',
    modalContent:
      '<p>Microsoft has officially retired Internet Explorer, and Plumb’s will no longer be supported on this browser after <b>January 4, 2023</b>. To ensure uninterrupted access to your Plumb’s subscription, please upgrade to a supported browser.</p>',
    seeSupportedBrowsers: 'See Supported Browsers',
    barContent:
      '<p>As of January 4, 2023, Plumb’s will no longer be supported on Internet Explorer.\nPlease upgrade to a <browsers>supported browser</browsers> to avoid interruptions in service.</p>',
  },
  loading: {
    thisIsTakingLonger: 'This is taking longer than usual...',
    standBy: 'Stand by...',
  },
  surveys: {
    csatHeader: 'How satisfied are you with this product?',
    csatFeedback: 'Please provide any additional feedback you may have.',
    npsHeader: 'On a scale of 1-10, how likely are you to recommend Plumb’s to a friend or colleague?',
    npsNegative: 'Not at all Likely',
    npsPositive: 'Extremely Likely',
    npsFeedback: 'Please provide any additional feedback you may have.',
  },
  findOnPage: {
    input: 'Find in {{contentTitle}}',
    results: '{{currentResult}} of {{totalFound}} results found',
  },
};

export default translations;
