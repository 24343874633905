import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Animated, StyleSheet, View } from 'react-native';

import { PromoBoxCarousel as BaseComponent } from './PromoBoxCarousel';
import { PromoBoxCarouselDots } from './PromoBoxCarouselDots';
import { PromoBoxCarouselItem } from './PromoBoxCarouselItem';
import { usePromoboxes } from '../hooks';

type Props = React.ComponentProps<typeof BaseComponent>;

const PROMOBOX_DATA_ATTR = 'promobox';

export const PromoBoxCarousel: React.FC<Props> = ({ style }) => {
  const { data } = usePromoboxes();
  const [activeItem, setActiveItem] = useState(0);
  const [height, setHeight] = useState(0);
  const itemsChangeIntervalId = useRef<number | undefined>();

  const setItemsChangeInterval = useCallback(() => {
    if (!data?.promoboxes || data.promoboxes.length < 2) return;

    const intervalId = window.setInterval(() => {
      setActiveItem((current) => {
        const nextIndex = current === data.promoboxes.length - 1 ? 0 : current + 1;
        return nextIndex;
      });
    }, 6000);
    itemsChangeIntervalId.current = intervalId;
  }, [data?.promoboxes]);

  const adjustHeight = useCallback(() => {
    // Adjusting container height to match the biggest promobox
    const elements = document.querySelectorAll(`[data-${PROMOBOX_DATA_ATTR}]`);
    if (elements.length) {
      const maxHeight = Math.max(...Array.from(elements).map((element) => element.clientHeight));
      setHeight(maxHeight);
    }
  }, []);

  const onRemove = () => {
    setActiveItem((state) => Math.max(state - 1, 0));
  };

  const handleNavDotPress = (index: number) => {
    setActiveItem(index);
    window.clearInterval(itemsChangeIntervalId.current);
    setItemsChangeInterval();
  };

  useLayoutEffect(() => {
    adjustHeight();
    window.addEventListener('resize', adjustHeight);

    return () => window.removeEventListener('resize', adjustHeight);
  }, [adjustHeight]);

  useEffect(() => {
    setItemsChangeInterval();
    return () => {
      window.clearInterval(itemsChangeIntervalId.current);
    };
  }, [setItemsChangeInterval]);

  if (!data?.promoboxes.length) {
    return null;
  }

  return (
    <View style={style}>
      <View style={[styles.items, { height }]}>
        {data.promoboxes.map((item, index) => (
          <ItemWrapper key={item.slug} isActive={index === activeItem} onLayout={adjustHeight}>
            <PromoBoxCarouselItem config={item} onRemove={onRemove} key={item.slug} />
          </ItemWrapper>
        ))}
      </View>

      {data.promoboxes.length > 1 && (
        <PromoBoxCarouselDots
          count={data.promoboxes.length}
          current={activeItem}
          onItemPress={handleNavDotPress}
        />
      )}
    </View>
  );
};

interface ItemProps extends React.PropsWithChildren {
  isActive: boolean;
  onLayout?(): void;
}

const ItemWrapper: React.FC<ItemProps> = ({ children, isActive, onLayout }) => {
  const fadeAnim = useRef(new Animated.Value(0));
  const wrapper = useRef<HTMLDivElement>(null);

  const ANIMATION_DURATION = 350;

  const fadeIn = useCallback(() => {
    Animated.timing(fadeAnim.current, {
      toValue: 1,
      duration: ANIMATION_DURATION,
      useNativeDriver: true,
    }).start(() => {
      wrapper.current?.style.removeProperty('pointer-events');
    });
  }, []);

  const fadeOut = useCallback(() => {
    Animated.timing(fadeAnim.current, {
      toValue: 0,
      duration: ANIMATION_DURATION,
      useNativeDriver: true,
    }).start(() => {
      if (wrapper.current) wrapper.current.style.pointerEvents = 'none';
    });
  }, []);

  useEffect(() => {
    if (isActive) {
      fadeIn();
    } else {
      fadeOut();
    }
  }, [isActive, fadeIn, fadeOut]);

  return (
    <Animated.View
      style={[
        {
          opacity: fadeAnim.current,
        },
        styles.itemWrapper,
      ]}
      dataSet={{
        [PROMOBOX_DATA_ATTR]: '',
      }}
      onLayout={() => onLayout?.()}
      // @ts-ignore
      ref={wrapper}
    >
      {children}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  items: {
    width: '100%',
    minHeight: 194,
    marginBottom: 16,
  },
  itemWrapper: {
    width: '100%',
    position: 'absolute',
  },
});
