import { StyleProp, ViewStyle, StyleSheet, View } from 'react-native';

import { IconName } from 'src/constants/types';
import { i18n } from 'src/locale';
import { palette } from 'src/styles';

import { Icon } from './Icon/Icon';
import { IconTextButton } from './IconTextButton';
import { Pressable } from './Pressable';
import { StyledText } from './StyledText';

interface AccordionsToggleProps {
  isAnyItemExpanded?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  toc?: boolean;
  openTestID: string;
  closeTestID: string;
}

export const AccordionsToggle: React.FC<AccordionsToggleProps> = ({
  isAnyItemExpanded,
  onPress,
  style,
  toc,
  openTestID,
  closeTestID,
}) => {
  const text = isAnyItemExpanded ? i18n.t('closeAll') : i18n.t('openAll');
  const icon: IconName = isAnyItemExpanded ? 'close-all' : 'open-all';
  const testID = isAnyItemExpanded ? closeTestID : openTestID;

  return (
    <>
      {toc ? (
        <Pressable testID={testID} onPress={onPress}>
          <View style={styles.toggleWrapper}>
            <StyledText style={styles.toggleText}>{text}</StyledText>
            <Icon name={icon} color={palette.blue} width={16} />
          </View>
        </Pressable>
      ) : (
        <IconTextButton
          text={text}
          onPress={onPress}
          icon={icon}
          iconPosition="right"
          circleIcon
          iconWidth={16}
          testID={testID}
          style={style}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  toggleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggleText: {
    color: palette.blue,
    marginRight: 5,
  },
});
