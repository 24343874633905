import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';

import { QueryFnPaginationResponse } from 'src/constants/types';

export const getDataFromInfiniteQuery = <T>(queryData?: InfiniteData<QueryFnPaginationResponse<T>>) => {
  const items = queryData?.pages.flatMap((page) => page.results) ?? [];
  const count = queryData?.pages[0].count ?? 0;
  const page = queryData?.pages.length;

  return { items, count, page };
};

export const setDataInQueries = (queryClient: QueryClient, queries: [QueryKey, unknown][]) => {
  for (const [queryKey, data] of queries) {
    queryClient.setQueryData(queryKey, data);
  }
};
