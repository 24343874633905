import React, { useRef, useState } from 'react';
import { StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native';
import { Defs, LinearGradient, Rect, Stop, Svg } from 'react-native-svg';

import { CircleIconButton } from 'src/components';
import { Icon } from 'src/components/Icon/Icon';
import { DEFAULT_MAX_FONT_SIZE_MULTIPLIER } from 'src/constants/constants';
import { useAndroidInputHack } from 'src/hooks';
import { palette, typography, fonts } from 'src/styles';

interface Props extends React.ComponentProps<typeof TextInput> {
  outlined?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  onClearButtonPress?(): void;
  onFocus?(): void;
  onBlur?(): void;
}
const gradientId = 'search-input-gradient';

export const SearchInput: React.FC<Props> = ({
  outlined,
  containerStyle,
  style,
  onClearButtonPress,
  onFocus,
  onBlur,
  ...rest
}) => {
  const inputRef = useRef<TextInput>(null);
  const [focused, setFocused] = useState(false);

  const androidHackMargin = useAndroidInputHack(rest.value);

  const handleClearButtonPress = () => {
    inputRef.current?.focus();
    onClearButtonPress?.();
  };

  const handleInputFocus = () => {
    onFocus?.();
    setFocused(true);
  };

  const handleInputBlur = () => {
    onBlur?.();
    setFocused(false);
  };

  const textInput = (
    <>
      <View style={styles.magnifyIcon}>
        <Icon name="magnify" color={palette.navy} width={14} />
      </View>
      <TextInput
        ref={inputRef}
        placeholderTextColor={palette.grey5}
        style={[styles.input, { marginRight: androidHackMargin }, style, focused && styles.inputFocus]}
        autoCapitalize="none"
        enterKeyHint="done"
        rows={1}
        maxFontSizeMultiplier={DEFAULT_MAX_FONT_SIZE_MULTIPLIER}
        inputMode="search"
        {...rest}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      {!!rest.value && onClearButtonPress && (
        <View style={styles.clearButton}>
          <CircleIconButton
            name="close"
            outline
            backgroundColor={palette.navy}
            onPress={handleClearButtonPress}
            containerSize={28}
            width={10}
            testID="clear-search-btn"
          />
        </View>
      )}
    </>
  );

  if (outlined) {
    return (
      <View style={[styles.gradientWrapper, containerStyle]}>
        <Svg height={50} width="100%" style={[StyleSheet.absoluteFill]}>
          <Defs>
            <LinearGradient id={gradientId}>
              <Stop offset="0%" stopColor="#1851A8" stopOpacity="1" />
              <Stop offset="61%" stopColor="#00A2E2" stopOpacity="1" />
              <Stop offset="100%" stopColor="#00C7E2" stopOpacity="1" />
            </LinearGradient>
          </Defs>
          <Rect x="0" y="0" width="100%" height={50} fill={`url(#${gradientId})`} />
        </Svg>
        <View style={styles.inputContainer}>{textInput}</View>
      </View>
    );
  }

  return <View style={[styles.inputContainer, containerStyle]}>{textInput}</View>;
};

const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
  },
  input: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: palette.white,
    height: 48,
    borderRadius: 24,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: palette.white,
    width: '100%',
    color: palette.grey8,
    minWidth: 0,
    paddingLeft: 35,
    paddingRight: 50,
    fontFamily: fonts.sourceSans,
    pointerEvents: 'auto',
    ...typography.text2,
  },
  inputFocus: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: palette.blue,
  },
  magnifyIcon: {
    position: 'absolute',
    left: 13,
    height: '100%',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  clearButton: {
    position: 'absolute',
    right: 13,
    height: '100%',
    justifyContent: 'center',
  },
  gradientWrapper: {
    height: 50,
    borderRadius: 25,
    overflow: 'hidden',
    ...typography.text2,
    fontFamily: fonts.sourceSans,
    pointerEvents: 'auto',
    width: '100%',
    justifyContent: 'center',
    paddingHorizontal: 1,
  },
});
