import { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

import { Icon, StyledText, GradientBar, ProBadge } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { useSideBarContext } from 'src/navigation/contexts/SideBarContext';
import { useRoute } from 'src/navigation/hooks/useRoute';
import { Route } from 'src/navigation/routes';
import { palette, typography, ifWeb } from 'src/styles';

import { Link } from '../Link';

interface Props<R extends Route = any> {
  isFirst?: boolean;
  disabled?: boolean;
  pro?: boolean;
  text: string;
  to: { route: R };
  testID?: string;
  children?: (selected: boolean) => ReactNode;
  onPress?: () => void;
}

export const SideBarLink: React.FC<Props> = ({ children, pro, text, isFirst, onPress, ...item }) => {
  const { name: currentRoute } = useRoute();
  const { setSideBarOpen, isSidebarPermanentlyVisible } = useSideBarContext();
  const { isPro } = useUserInfo();

  // When the user is PRO we use the PVD gradient and the same thing for
  // PVD users
  const gradientType = isPro ? 'pvdGradient' : 'proGradient';

  const isSelected = currentRoute === item.to.route;

  const showWhiteBadge = !isPro && isSelected;

  const handleLinkPress = () => {
    onPress?.();
    if (!isSidebarPermanentlyVisible) {
      setSideBarOpen(false);
    }
  };

  return (
    <Link {...item} onPress={handleLinkPress}>
      {(hovered) => (
        <GradientBar
          gradientType={gradientType}
          hideGradient={!isSelected}
          style={[styles.item, !isFirst && styles.itemSeparator]}
        >
          <View style={styles.itemInnerWrapper}>
            <View style={styles.itemTextWrapper}>
              <StyledText
                style={[
                  styles.itemText,
                  hovered && styles.itemTextHover,
                  isSelected && styles.itemTextSelected,
                ]}
              >
                {text}
              </StyledText>
              {pro && <ProBadge variant={showWhiteBadge ? 'inverted' : 'gradient'} />}
              {children?.(isSelected)}
            </View>
            <Icon name="chevron-right" width={10} color={isSelected ? palette.white : palette.navy} />
          </View>
        </GradientBar>
      )}
    </Link>
  );
};
const styles = StyleSheet.create({
  itemSeparator: {
    borderTopWidth: 1,
    borderColor: palette.grey2,
  },
  item: {
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  itemInnerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemTextWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemText: {
    ...typography.body3Bold,
    color: palette.navy,
    paddingRight: 8,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  itemTextHover: {
    color: palette.blue,
  },
  itemTextSelected: {
    color: palette.white,
  },
});
