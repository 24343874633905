import type { NullablePersonalInformation, NullablePracticeLocation } from '../types';

export const locationInformationEmptyState: NullablePracticeLocation = {
  address1: null,
  country: null,
  postalCode: null,
  state: null,
  address2: null,
  city: null,
  practice: null,
};

export const personalInformationEmptyState: NullablePersonalInformation = {
  bornYear: null,
  firstName: null,
  lastName: null,
  phone: null,
  ...locationInformationEmptyState,
};
