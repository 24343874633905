import { StyleSheet } from 'react-native';

import { VoidSearchBox } from 'src/features/search/components';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks/useNavigation';

import { DICSource } from '../types';

interface Props {
  selectedDrugs?: string[];
  source?: DICSource;
}

export const DICVoidSearchBox: React.FC<Props> = ({ selectedDrugs, source }) => {
  const navigation = useNavigation();

  const handlePress = () => {
    navigation.navigate(Route.DICSearch, {
      selectedDrugs,
      source,
    });
  };

  return (
    <VoidSearchBox
      placeholder={i18n.t('search:placeholder:dic')}
      onPress={handlePress}
      wrapperStyle={styles.wrapperStyle}
    />
  );
};

const styles = StyleSheet.create({
  wrapperStyle: {
    height: 68,
    paddingBottom: 8,
  },
});
