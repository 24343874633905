import React, {
  createContext,
  useState,
  ReactNode,
  RefObject,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useWindowDimensions, View } from 'react-native';
import Animated, { AnimatedRef, runOnJS } from 'react-native-reanimated';
import { AnimatedScrollView } from 'react-native-reanimated/lib/typescript/component/ScrollView';

import { AccordionRef } from 'src/constants/types/accordion';
import { isWeb } from 'src/helpers';
import { AccordionState } from 'src/hooks/useAccordionState';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import { BOTTOM_BAR_HEIGHT, MOBILE_TOC_HEIGHT, palette } from 'src/styles';

import { onScrollToNative, onScrollToWeb } from '../helper';

interface FindOnPageProviderProps {
  children: ReactNode;
  contentTitle: string;
  accordionRef: RefObject<AccordionRef>;
  scrollViewRef: RefObject<View> | AnimatedRef<AnimatedScrollView>;
  accordionState: AccordionState;
}

export interface Measurements {
  width: number;
  height: number;
  pageX: number;
  pageY: number;
}

interface TextRefWithMeasurements {
  parentRef: AnimatedRef<Animated.View>;
  measurements: Measurements;
  highlightedRef: React.RefObject<Animated.Text>; // Add highlightedRef
}

export interface FindOnPageContextTypes {
  totalFound: number;
  highlightIndex: number;
  searchText: string;
  contentTitle?: string;
  scrollViewRef: RefObject<View> | AnimatedRef<AnimatedScrollView>;
  onNextHighlight?: () => void;
  onPrevHighlight?: () => void;
  setTotalFound: React.Dispatch<React.SetStateAction<number>>;
  onSetSearchText?: (_text: string) => void;
  onSaveTextRef?: (data: TextRefWithMeasurements) => void; // Type for onSaveTextRef
  onResetResults?: () => void;
}

export const FindOnPageContext = createContext<FindOnPageContextTypes | undefined>(undefined);

export const FindOnPageProvider: React.FC<FindOnPageProviderProps> = ({
  children,
  contentTitle,
  accordionRef,
  scrollViewRef,
}) => {
  const [totalFound, setTotalFound] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [isAccordionClosed, setIsAccordionClosed] = useState<boolean>(true);
  const [highlightIndex, setHighlightIndex] = useState<number>(0);
  const highlightedTextsRefs = useRef<Set<TextRefWithMeasurements>>(new Set());
  const { topPanelHeight } = useWebAppLayout();
  const { height: windowHeight } = useWindowDimensions();
  const scrollOffset = MOBILE_TOC_HEIGHT + topPanelHeight + BOTTOM_BAR_HEIGHT;

  // Function to save text refs and their measurements
  const onSaveTextRef = useCallback((props: TextRefWithMeasurements) => {
    // Add the ref and measurements to the Set
    highlightedTextsRefs.current.add(props);
    // Update the total found count by the size of the Set
    runOnJS(() => {
      setTotalFound(highlightedTextsRefs.current.size);
      setHighlightIndex(0); // Ensure highlightIndex stays reset
    })();
  }, []);

  const isElementVisible = useCallback(
    (node: HTMLElement) => {
      if (!isWeb) return true;
      const { top, bottom, left, right } = node.getBoundingClientRect();
      const bottomVisible = window.innerHeight - BOTTOM_BAR_HEIGHT;
      return top >= topPanelHeight && bottom <= bottomVisible && left >= 0 && right <= window.innerWidth;
    },
    [topPanelHeight],
  );

  const onResetResults = useCallback(() => {
    highlightedTextsRefs.current.clear();
    setSearchText('');
    setTotalFound(0);
    setHighlightIndex(0);
  }, []);

  const onNextHighlight = useCallback(() => {
    if (totalFound > 0) {
      setHighlightIndex((prevIndex) => (prevIndex + 1) % totalFound);
    }
  }, [totalFound]);

  const onPrevHighlight = useCallback(() => {
    if (totalFound > 0) {
      setHighlightIndex((prevIndex) => (prevIndex - 1 + totalFound) % totalFound);
    }
  }, [totalFound]);

  const onScroll = useCallback(
    (item: TextRefWithMeasurements) => {
      if (isWeb) {
        const selectedRef = item.highlightedRef.current as unknown as HTMLElement;
        if (selectedRef && !isElementVisible(selectedRef)) {
          onScrollToWeb(selectedRef, scrollViewRef, windowHeight);
        }
      } else {
        onScrollToNative(scrollViewRef, scrollOffset, item.measurements.pageY);
      }
    },
    [scrollViewRef, scrollOffset, windowHeight, isElementVisible],
  );

  const onHighlight = useCallback((item: TextRefWithMeasurements, type: 'current' | 'rest') => {
    const { selectedHighlight, yellowHighlight } = palette;
    const backgroundColor = type === 'current' ? selectedHighlight : yellowHighlight;
    if (isWeb) {
      const selectedRef = item.highlightedRef.current as unknown as HTMLElement;
      selectedRef.style.backgroundColor = backgroundColor;
    } else {
      item.highlightedRef.current?.setNativeProps({ style: { backgroundColor: backgroundColor } });
    }
  }, []);

  const onHighlightScroll = useCallback(
    (currentIndex: number) => {
      const highlightedRefsArray = Array.from(highlightedTextsRefs.current);
      highlightedRefsArray.forEach((item, index) => {
        if (index === currentIndex) {
          onScroll(item);
          onHighlight(item, 'current');
        } else {
          onHighlight(item, 'rest');
        }
      });
    },
    [onHighlight, onScroll],
  );

  useEffect(() => {
    if (searchText.length) {
      onHighlightScroll(highlightIndex);
    }
  }, [searchText, highlightIndex, onHighlightScroll]);

  useEffect(() => {
    if (highlightedTextsRefs.current.size > 0) {
      if (highlightIndex === 0) {
        onHighlightScroll(0);
      }
    }
  }, [highlightedTextsRefs.current.size, highlightIndex, onHighlightScroll]);

  const onSetSearchText = useCallback(
    (text: string) => {
      // Open all accodion for the first time
      if (isAccordionClosed) {
        accordionRef?.current?.openAllItems();
        setIsAccordionClosed(false);
        // Open all accodion if accodion was opened once before but had a section closed
      } else if (!isAccordionClosed && !accordionRef.current?.areAllItemsExpanded()) {
        accordionRef?.current?.openAllItems();
      }
      highlightedTextsRefs.current.clear(); // Clear all previous refs
      setTotalFound(0); // Reset total count
      setHighlightIndex(0); // Reset index for new search
      setSearchText(text);
    },
    [accordionRef, isAccordionClosed],
  );

  return (
    <FindOnPageContext.Provider
      value={{
        totalFound,
        searchText,
        highlightIndex,
        scrollViewRef,
        contentTitle,
        onSaveTextRef,
        onSetSearchText,
        setTotalFound,
        onPrevHighlight,
        onNextHighlight,
        onResetResults,
      }}
    >
      {children}
    </FindOnPageContext.Provider>
  );
};
