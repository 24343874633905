import { client } from 'src/api/client';
import { pdfRequestConfig } from 'src/helpers/pdf';

import type { GenerateHandoutsPDFResponse, EmailHandoutsParams, GenerateHandoutsPDFParams } from './types';

export const generateHandoutsPdf = async (params: GenerateHandoutsPDFParams) => {
  const response = await client.request<GenerateHandoutsPDFResponse>({
    url: '/medication-guides/generate-pdf/',
    method: 'POST',
    apiVersion: 2,
    ...pdfRequestConfig,
    data: params,
  });

  return response.data;
};

export const emailHandouts = async (params: EmailHandoutsParams) => {
  await client.request({
    url: '/medication-guides/send-email/',
    data: params,
    method: 'POST',
  });
};
