import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Pressable } from 'src/components';
import { palette } from 'src/styles';

interface Props {
  count: number;
  current: number;
  containerStyle?: StyleProp<ViewStyle>;
  onItemPress?(index: number): void;
}

export const PromoBoxCarouselDots: React.FC<Props> = ({ count, current, containerStyle, onItemPress }) => {
  return (
    <View style={[styles.wrapper, containerStyle]}>
      {Array.from({ length: count }).map((_, index) => (
        <Pressable key={index} onPress={() => onItemPress?.(index)}>
          <View style={[styles.dot, index === current && styles.dotSelected]} />
        </Pressable>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dot: {
    borderRadius: 4,
    width: 8,
    height: 8,
    backgroundColor: palette.grey2,
    marginHorizontal: 5,
  },
  dotSelected: {
    borderRadius: 5,
    width: 10,
    height: 10,
    backgroundColor: palette.blue,
  },
});
