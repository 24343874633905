import { useFormik } from 'formik';

import { COUNTRIES_WITH_STATES } from 'src/constants/constants';
import { FormField } from 'src/constants/types';
import { Yup } from 'src/helpers/validation';

import { renderFormField } from './common';
import { useCountriesList } from '../queries/countries';

export function useUniversalForm<T extends Record<string, string | null | undefined>>(
  initialValues: T,
  onSubmit: (values: T) => void,
  fieldsObject: Record<keyof T, FormField>,
) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    setErrors,
    setFieldValue,
    setFieldTouched,
    dirty,
    setTouched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema: Yup.object().shape(
      Object.keys(fieldsObject).reduce(
        (obj, key) => ({
          ...obj,
          [key]: (fieldsObject[key] as FormField).validation,
        }),
        {},
      ),
    ),
  });

  const { countriesOptions, getStatesOptions } = useCountriesList({
    enabled: 'country' in fieldsObject,
  });

  const renderField = (key: keyof T) => {
    const field = fieldsObject[key];

    if (key === 'state' && !COUNTRIES_WITH_STATES.includes((values.country as string) || '')) {
      // We don't display 'state' field unless a 'country' value is one of mentioned in COUNTRIES_WITH_STATES const
      return null;
    }

    // 'country' and 'state' options are fetched from backend; others are defined in personalInformationForm
    const options =
      key === 'country'
        ? countriesOptions
        : key === 'state'
        ? getStatesOptions(values.country!)
        : field.options;

    const onChange = (value: string | undefined) => {
      if (key === 'country' && value !== values.country) {
        // Removing state value when user changes the country
        setFieldValue('state', '');
      }
      // @ts-ignore
      handleChange(key)(value);
    };

    const onBlur = async (e: any) => {
      if (field.type === 'select') {
        setTimeout(() => {
          setFieldTouched(key as string, true);
        }, 0);
      } else {
        // @ts-ignore
        handleBlur(key)(e);
      }
    };

    return renderFormField({
      ...field,
      key: key as string,
      options,
      value: values[key as string] || '',
      error: errors[key] as string,
      touched: !!touched[key],
      // @ts-ignore
      handleBlur: onBlur,
      handleChange: onChange,
      handleSubmit,
    });
  };

  const handleSetErors: typeof setErrors = (errors) => {
    // setting 'touched' to display errors provided after submitting
    setTouched(
      Object.entries(errors).reduce((prev, [key, error]) => ({ ...prev, [key]: !!error }), {}),
      false,
    );
    setErrors(errors);
  };

  return {
    renderField,
    handleSubmit,
    setErrors: handleSetErors,
    errors,
    touched,
    dirty,
  };
}
