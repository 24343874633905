import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { SecondaryButton, InputItem, StyledText } from 'src/components';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { showNotification } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { useOutsideClickDetector } from 'src/hooks';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { useEditGroupName } from '../../hooks';

interface Props {
  name: string | null;
}

interface Form {
  name: string;
}

const validationSchema: Yup.SchemaOf<Form> = Yup.object().shape({
  name: Yup.string().max(256).required(),
});

export const GroupName: React.FC<Props> = ({ name }) => {
  const [editMode, setEditMode] = useState(false);
  const inputWrapperRef = useRef<View>(null);

  const { mutate: editGroupName, isPending } = useEditGroupName();

  useOutsideClickDetector(inputWrapperRef, () => {
    if (editMode && !isPending) {
      setEditMode(false);
    }
  });

  const submit = (values: Form) => {
    editGroupName(values.name, {
      onSuccess: () => {
        setEditMode(false);
      },
      onError: (err: any) => {
        const { detail, name } = getMessagesFromErrorResponse<{ name?: string; detail?: string }>(err);
        if (name) {
          setFieldError('name', name);
        } else {
          showNotification({ type: 'error', title: detail });
        }
      },
    });
  };

  const { values, errors, handleChange, handleSubmit, touched, setFieldError, setFieldTouched } = useFormik(
    {
      initialValues: { name: name || '' },
      onSubmit: submit,
      validationSchema,
      validateOnBlur: false,
    },
  );

  useEffect(() => {
    setFieldError('name', undefined);
  }, [editMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditNamePress = () => setEditMode(true);

  return (
    <>
      <StyledText style={[typography.text1, styles.groupNameLabel]}>
        {i18n.t('adminDashboard:groupName')}
      </StyledText>
      {editMode ? (
        <View ref={inputWrapperRef} style={styles.inputWrapper}>
          <InputItem
            label=""
            value={values.name}
            touched={touched.name}
            onChangeText={handleChange('name') as (value: string) => void}
            error={errors.name}
            onBlur={() => setFieldTouched('name')}
            onSubmitEditing={() => handleSubmit()}
            selectTextOnFocus
            autoFocus
            containerStyle={styles.inputContainer}
            button={
              isPending
                ? undefined
                : {
                    title: i18n.t('save'),
                    onPress: handleSubmit,
                    testID: 'save-group-name-btn',
                  }
            }
            isLoading={isPending}
            testID="group-name-input"
          />
        </View>
      ) : (
        <View style={styles.groupNameWrapper}>
          {!!name && (
            <StyledText
              style={[typography.body5Bold, styles.groupName]}
              testID="group-name-label"
              headerLevel={2}
            >
              {name}
            </StyledText>
          )}
          <SecondaryButton
            title={i18n.t(name ? 'edit' : 'adminDashboard:createGroupName')}
            size="tiny"
            onPress={handleEditNamePress}
            testID="edit-group-name-btn"
          />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  groupNameLabel: {
    color: palette.grey5,
  },
  groupNameWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
  groupName: {
    color: palette.navy,
    marginRight: 12,
  },
  inputWrapper: {
    alignSelf: 'flex-start',
  },
  inputContainer: {
    maxWidth: 400,
  },
});
