import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BrowserRouter as Router,
  Switch,
  Route as RouterRoute,
  matchPath,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';

import { LoadingIndicator } from 'src/components';
import { ModalPortal } from 'src/contexts/portals';
import { Error404Banner } from 'src/errorHandling/components';
import { useIsSignedIn } from 'src/features/auth/hooks';
import { UserNotifications } from 'src/features/userNotifications/components';
import { useScreenAccess } from 'src/hooks';

import { Props } from './RootNavigator';
import { getRouterRoutesForScreens } from './utils';
import { SideBarDrawer } from '../components/SideBar/SideBarDrawer';
import { TopPanel } from '../components/TopPanel/TopPanel';
import { linkingConfig, orderedWebRoutes } from '../config/linkingConfig';
import { screenMap } from '../config/screenMap';
import { screenOptions } from '../config/screenOptions';
import { SidebarContextProvider } from '../contexts';
import { Route } from '../routes';
import { getRedirectTo } from '../utils';

const webScreensList = orderedWebRoutes.map((name) => ({
  name,
  component: screenMap[name],
  ...linkingConfig[name],
  ...screenOptions[name],
}));

let initialized = false;

/**
 * This is called when no route has matched the current url. It means it's either:
 * - invalid (404)
 * - not available because of the current state, eg.
 *   - user opens a direct link to some content, but they are not logged in
 *   - user clicks "Sign In", which makes the Sign In page no longer available
 */
const routeNotMatched = (
  props: RouteComponentProps,
  isSignedIn: boolean,
  availableScreens: typeof webScreensList,
) => {
  const url = props.match.url;
  const routeExists = webScreensList.some(
    ({ path, exact, alternativePath }) =>
      matchPath(url, { path, exact }) || matchPath(url, { path: alternativePath, exact }),
  );

  if (routeExists) {
    if (!initialized && !isSignedIn && url !== '/') {
      initialized = true;
      return (
        <Redirect
          to={{
            pathname: linkingConfig[Route.SignIn].path,
            search: new URLSearchParams({
              redirectTo: props.location.pathname + props.location.search,
            }).toString(),
          }}
        />
      );
    }

    const redirectTo = new URLSearchParams(props.location.search).get('redirectTo') || getRedirectTo();
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return <Redirect to={availableScreens[0].path} />;
  }

  return <Error404Banner />;
};

export const RootNavigator: React.FC<Props> = ({ loading }) => {
  const isSignedIn = useIsSignedIn();

  const { isScreenAccessible } = useScreenAccess();

  const availableScreens = webScreensList.filter((screen) => isScreenAccessible(screen.name));

  if (loading) {
    return <LoadingIndicator style={styles.loading} />;
  }

  return (
    <Router>
      <SidebarContextProvider>
        <TopPanel />
        <div id="wrapper">
          <ModalPortal.Outlet />

          <SideBarDrawer>
            <View style={styles.wrapper}>
              <Switch>
                {getRouterRoutesForScreens(availableScreens)}
                <RouterRoute
                  path="*"
                  render={(props) => routeNotMatched(props, isSignedIn, availableScreens)}
                />
              </Switch>
            </View>
          </SideBarDrawer>
        </div>
      </SidebarContextProvider>
      <UserNotifications />
    </Router>
  );
};

export default React.memo(RootNavigator);

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: 'center',
  },
  wrapper: {
    zIndex: 1,
  },
});
