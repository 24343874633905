import { StyleSheet } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { ifWeb, palette, typography } from 'src/styles';

interface Props extends React.ComponentProps<typeof StyledText> {}

export const MobileContentHeader: React.FC<Props> = (props) => (
  <StyledText {...props} style={[styles.text, typography.body3Bold, props.style]} headerLevel={2} />
);

const styles = StyleSheet.create({
  text: {
    textTransform: 'uppercase',
    color: palette.navy,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
});
