import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { client } from 'src/api/client';

interface ContentItemQueryOptions {
  key: string[];
  url: string;
  options?: Pick<UseQueryOptions, 'enabled'>;
  requestConfig?: AxiosRequestConfig;
}

export const useContentItemQuery = <T>({ key, url, options, requestConfig }: ContentItemQueryOptions) => {
  return useQuery<T>({
    queryKey: key,
    queryFn: async () => {
      const response = await client.request<T>({
        method: 'GET',
        url,
        ...requestConfig,
      });

      return response.data;
    },
    staleTime: 0,
    refetchOnMount: true,
    ...options,
  });
};
