import React from 'react';

import { StyledText } from 'src/components';
import { AUTOLOGIN_TOKEN_SITES } from 'src/constants/constants';
import { getAutologinTokenSiteName } from 'src/helpers';
import { i18n } from 'src/locale';

import { LoginFlowTemplate } from './LoginFlowTemplate';

const errorMessages: Record<(typeof AUTOLOGIN_TOKEN_SITES)[number], string> = {
  banfield: i18n.t('signIn:autologinErrorDescBanfield'),
};

const autologinSite = getAutologinTokenSiteName();

export const AutologinTokenSignedOutMessage: React.FC = () => {
  return (
    <LoginFlowTemplate headerTitle={i18n.t('signIn:autologinErrorHeader')}>
      <StyledText>{autologinSite && errorMessages[autologinSite]}</StyledText>
    </LoginFlowTemplate>
  );
};
