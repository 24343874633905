import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { Container, FullScreenTemplate } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { userNotificationsQueryKeys } from 'src/features/userNotifications/constants';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks/useNavigation';

import { StudentVerification } from '../components/StudentVerification';
import { studentVerificationQueryKeys, StudentSubscriptionEligibilityStatus } from '../constants';
import {
  StudentVerificationProvider,
  useStudentVerificationContext,
} from '../context/StudentVerificationContext';
import { useStudentSubscriptionEligibility } from '../hooks/queries';

const ScreenContent: React.FC = () => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();

  const { data, isLoading } = useStudentSubscriptionEligibility();
  const { currentStep } = useStudentVerificationContext();
  const { email } = useUserInfo();

  useEffect(() => {
    if (
      data?.status &&
      [
        StudentSubscriptionEligibilityStatus.ReverificationSuccessful,
        StudentSubscriptionEligibilityStatus.Verified,
      ].includes(data?.status)
    ) {
      navigation.navigate(Route.Home);
    }
  }, [data?.status, navigation]);

  useEffect(() => {
    if (currentStep === 'verified') {
      queryClient.refetchQueries({ queryKey: studentVerificationQueryKeys.studentSubscriptionEligibility });
      queryClient.refetchQueries({ queryKey: userNotificationsQueryKeys.list });
    }
  }, [currentStep, queryClient]);

  return (
    <FullScreenTemplate title={i18n.t('screens:studentReverification')} isLoading={isLoading}>
      <Container verticalPadding>
        <StudentVerification email={email} />
      </Container>
    </FullScreenTemplate>
  );
};

export const StudentReverificationScreen: React.FC = () => {
  return (
    <StudentVerificationProvider processType="reverification">
      <ScreenContent />
    </StudentVerificationProvider>
  );
};
