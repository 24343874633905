import { View, StyleSheet, StyleProp, TextStyle, ViewStyle } from 'react-native';

import { palette, getShadow, getShadowNative } from 'src/styles';

import { StyledText } from './StyledText';

interface Props {
  badgeText: string;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  testID?: string;
}

export const Badge: React.FC<Props> = ({ badgeText, textStyle, style, testID }) => {
  return (
    <View style={[styles.badge, style]}>
      <StyledText style={[styles.text, textStyle]} testID={testID}>
        {badgeText}
      </StyledText>
    </View>
  );
};

export const styles = StyleSheet.create({
  badge: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    alignItems: 'center',
    backgroundColor: palette.white,
    borderRadius: 11,
    ...getShadow(0, 0.48, 15),
    ...getShadowNative(30, 0.08, 10, 0.12),
  },
  text: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 22,
    fontWeight: 'bold',
  },
});
