export type Listener<T> = (payload: T) => void;

export class EventEmitterService<E extends Record<string, any>> {
  private listeners: { [K in keyof E]?: Listener<E[K]>[] } = {};

  subscribe<K extends keyof E>(eventType: K, listener: Listener<E[K]>): () => void {
    if (!this.listeners[eventType]) {
      this.listeners[eventType] = [];
    }

    const listeners = this.listeners[eventType]!;
    listeners.push(listener);

    // Return a function to allow unsubscribing
    return () => {
      this.listeners[eventType] = listeners.filter((l) => l !== listener);
    };
  }

  emit<K extends keyof E>(eventType: K, payload: E[K]): void {
    const listeners = this.listeners[eventType];
    if (listeners) {
      listeners.forEach((listener) => listener(payload));
    }
  }
}
