import { constants } from 'src/constants/index';
import type { NullablePersonalAddress } from 'src/features/profile';
import { Yup } from 'src/helpers/validation';
import { i18n } from 'src/locale';

import { COUNTRIES_WITH_STATES } from './constants';
import { FormField } from './types';

type PersonalAddressKeys = keyof NullablePersonalAddress;

const requiredString = Yup.string().required().nullable();

export const locationInformationForm: Record<PersonalAddressKeys | 'practice', FormField> = {
  country: {
    type: 'select',
    validation: requiredString,
    options: [], // fetched from backend; added in useUniversalForm
    setCustomPlaceholder: true,
    testID: 'country-select',
  },
  state: {
    type: 'select',
    options: [], // fetched from backend; added in useUniversalForm
    validation: Yup.string()
      .when('country', {
        is: (value: string) => COUNTRIES_WITH_STATES.includes(value),
        then: Yup.string().required(i18n.t('user:stateRequiredError')).nullable(),
      })
      .nullable(),
    setCustomPlaceholder: true,
    testID: 'state-select',
  },
  city: {
    type: 'text',
    validation: requiredString.max(50),
    testID: 'city-input',
  },
  postalCode: {
    type: 'text',
    validation: requiredString.max(20),
    testID: 'postal-code-input',
    autocompleteType: 'postal-code',
  },
  practice: {
    type: 'text',
    validation: requiredString.max(256),
    testID: 'practice-input',
  },
  address1: {
    type: 'text',
    validation: requiredString.max(50),
    testID: 'address1-input',
    autocompleteType: 'street-address',
  },
  address2: {
    type: 'text',
    validation: Yup.string().max(50).nullable(),
    testID: 'address2-input',
  },
};

export const passwordValidationRules = Yup.string()
  .min(constants.MIN_PASSWORD_LENGTH, i18n.t('validation:password_too_short'))
  .required()
  .matches(/\D/, i18n.t('validation:password_entirely_numeric'))
  .matches(/[A-Z]/, i18n.t('validation:password_no_upper'));
