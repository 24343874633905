import React, { useRef, useState } from 'react';
import { StyleSheet, TextInput, View, useWindowDimensions } from 'react-native';

import { Container, Icon, IconButton, Pressable, StyledText } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { OVERVIEW_TOC_ITEM_ID } from 'src/features/content/constants';
import { TableOfContentsItem, TableOfContentsProps } from 'src/features/content/types';
import { FindOnPageInput } from 'src/features/findOnPage/components/FindOnPageInput';
import FindOnPageInputButton from 'src/features/findOnPage/components/FindOnPageInputButton';
import { FindOnPageResult } from 'src/features/findOnPage/components/FindOnPageResult';
import { useFindOnPage } from 'src/features/findOnPage/hooks/useFindOnPage';
import { isWeb } from 'src/helpers';
import { useOutsideClickDetector } from 'src/hooks';
import { useTableOfContentsItems } from 'src/hooks/useTableOfContentsItems';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import {
  BOTTOM_BAR_HEIGHT,
  getShadowNative,
  ifWeb,
  MOBILE_TOC_HEIGHT,
  MOBILE_TOC_FIND_ON_PAGE_RESULTS_HEIGHT,
  palette,
  typography,
} from 'src/styles';

import { TableOfContentsMenu } from './TableOfContensMenu';
import { TableOfContentsMobileOld } from './TableOfContentsMobile.old';
import { ScrollView } from './TableOfContentsScrollView';

export interface TableOfContentsMenuWrapper {
  items: TableOfContentsItem[];
  activeSectionId?: string;
  toggleMenu: () => void;
}

const TableOfContentsMenuWrapper: React.FC<TableOfContentsMenuWrapper> = ({
  items,
  activeSectionId = OVERVIEW_TOC_ITEM_ID,
  toggleMenu,
}) => {
  const { height: windowHeight } = useWindowDimensions();
  const { topPanelHeight } = useWebAppLayout();
  return (
    <ScrollView
      style={[
        styles.menuWrapper,
        {
          maxHeight: isWeb
            ? windowHeight - (MOBILE_TOC_HEIGHT + topPanelHeight + BOTTOM_BAR_HEIGHT)
            : windowHeight * 0.6,
        },
      ]}
      contentContainerStyle={styles.menuContent}
    >
      <Container>
        <TableOfContentsMenu items={items} inDropdown onItemPress={toggleMenu} active={activeSectionId} />
      </Container>
    </ScrollView>
  );
};

export const TableOfContentsMobileNew: React.FC<TableOfContentsProps> = ({
  item,
  onItemPress,
  scrollToTop,
  activeSectionId = OVERVIEW_TOC_ITEM_ID,
}) => {
  const findOnPage = useFindOnPage();

  const inputRef = useRef<TextInput>(null);
  const [isTocOpen, setIsTocOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const wrapperRef = useRef(null);

  const items = useTableOfContentsItems({ item, onItemPress, scrollToTop });

  useOutsideClickDetector(wrapperRef, () => {
    setIsTocOpen(false);
  });

  const getActiveSectionTitle = (): string => {
    return items.find((item) => item.id === activeSectionId)?.title || '';
  };

  const toggleMenu = () => {
    !isSearchOpen && setIsTocOpen(!isTocOpen);
  };
  const onClear = () => {
    inputRef.current?.focus();
    if (inputRef.current) {
      inputRef.current.clear();
      findOnPage?.onResetResults?.();
    }
  };

  const onCloseSearch = () => {
    setIsSearchOpen(false);
    findOnPage?.onResetResults?.();
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const onOpenSearchInput = () => {
    if (isTocOpen) {
      setIsTocOpen(false);
    }
    setIsSearchOpen(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  if (!items.length) {
    return null;
  }

  return (
    <View
      style={[
        styles.wrapper,
        ifWeb({
          height: !isSearchOpen ? MOBILE_TOC_HEIGHT : undefined,
        }),
      ]}
      ref={wrapperRef}
    >
      <Container style={styles.toggleContainer}>
        <Pressable onPress={toggleMenu}>
          <View style={styles.titleWrapperContainer}>
            <View style={styles.titleWrapper}>
              {isSearchOpen ? (
                <>
                  <IconButton
                    name="back"
                    color={palette.navy}
                    style={styles.icon}
                    width={17}
                    onPress={onCloseSearch}
                  />
                  <FindOnPageInput
                    ref={inputRef}
                    totalFound={findOnPage?.totalFound || 0}
                    contentTitle={item.title}
                    onNextHighlight={findOnPage?.onNextHighlight}
                    onPrevHighlight={findOnPage?.onPrevHighlight}
                  />
                </>
              ) : (
                <>
                  <Icon name="table-of-contents" color={palette.navy} style={styles.icon} width={17} />
                  <StyledText singleLine maxFontSizeMultiplier={1} style={styles.activeItem}>
                    {getActiveSectionTitle()}
                  </StyledText>
                </>
              )}
            </View>
          </View>
        </Pressable>
        <FindOnPageInputButton
          iconName={isSearchOpen ? 'close' : 'magnify'}
          disabled={isSearchOpen && findOnPage?.searchText.length === 0}
          onPress={isSearchOpen ? onClear : onOpenSearchInput}
          testID={findOnPage?.searchText ? 'fop-clear-button' : 'fop-submit-button'}
        />
      </Container>
      {isSearchOpen && findOnPage?.searchText && findOnPage.searchText.length >= 1 && (
        <Container style={styles.findOnPageResultContainer}>
          <FindOnPageResult />
        </Container>
      )}
      {isTocOpen && <TableOfContentsMenuWrapper items={items} toggleMenu={toggleMenu} />}
    </View>
  );
};

export const TableOfContentsMobile: React.FC<TableOfContentsProps> = (props) => {
  const {
    permissions: { hasFullAccessToCoreFuncUXUpdates },
  } = useUserInfo();
  const Component = hasFullAccessToCoreFuncUXUpdates ? TableOfContentsMobileNew : TableOfContentsMobileOld;

  return <Component {...props} />;
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: palette.white,
    borderTopWidth: 1,
    borderTopColor: palette.grey2,
    zIndex: 1,
    ...ifWeb(
      {
        height: MOBILE_TOC_HEIGHT,
        cursor: 'pointer',
        position: 'sticky',
      },
      {
        ...getShadowNative(80, 0.15, 20, 0.06),
      },
    ),
  },
  toggleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: MOBILE_TOC_HEIGHT,
  },
  icon: {
    marginRight: 12,
  },
  titleWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  activeItem: {
    color: palette.navy,
    ...ifWeb(
      {},
      {
        marginRight: 30,
      },
    ),
    ...typography.body3,
  },
  menuWrapper: {
    width: '100%',
    backgroundColor: palette.white,
    borderBottomWidth: 1,
    borderColor: palette.grey2,
    borderTopWidth: 1,
    height: 'auto',
    zIndex: 1,
    position: 'absolute',
    top: '100%',
  },
  menuContent: {
    paddingTop: 16,
    paddingBottom: 25,
  },
  findOnPageResultContainer: {
    height: MOBILE_TOC_FIND_ON_PAGE_RESULTS_HEIGHT,
  },
  titleWrapperContainer: {
    flex: 1,
    height: MOBILE_TOC_HEIGHT,
  },
});
