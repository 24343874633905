import React, { useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import { BaseButton, Divider, StyledText, TextButton } from 'src/components';
import { showNotification } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';
import { palette, typography } from 'src/styles';

import { usePasswordResetEmail } from '../hooks';

type Props = {
  emailValue: string;
  goBack: () => void;
};

export const ResetPasswordConfirmationScreen: React.FC<Props> = ({ emailValue, goBack }) => {
  const { isDesktop } = useDeviceInfo();
  const [resent, setResent] = useState(false);
  const navigation = useNavigation();
  const { mutate: sendResetPasswordEmail } = usePasswordResetEmail();

  const isWeb = Platform.OS === 'web';

  const resendPass = () => {
    sendResetPasswordEmail(emailValue, {
      onSuccess: () => {
        setResent(true);
        showNotification({ type: 'success', title: i18n.t('passwordRecovery:passwordResent') });
      },
      onError: () => {
        showNotification({ type: 'error' });
      },
    });
  };

  return (
    <View style={styles.container}>
      <StyledText
        style={[typography.body3, styles.title, isWeb && styles.webDescriptionText]}
        testID="confirmation-text-email-sent"
      >
        {i18n.t(!resent ? 'passwordRecovery:confirmationTitle' : 'passwordRecovery:resendConfirmation')}
      </StyledText>
      <BaseButton
        title={i18n.t('passwordRecovery:backToLoginButton')}
        containerStyle={styles.button}
        fullWidth={!isDesktop}
        onPress={() => navigation.navigate(Route.SignIn, {})}
        testID="confirmation-back-to-login-btn"
        variant="gradient"
      />
      <Divider style={styles.dividerMargin} />
      <View style={[isWeb ? styles.webDescription : styles.description]}>
        <StyledText style={[typography.body3, styles.descriptionText, isWeb && styles.webDescriptionText]}>
          {i18n.t('passwordRecovery:iDidntReceiveEmail')}
        </StyledText>
        <TextButton onPress={!resent ? resendPass : goBack} testID="confirmation-resend-email-btn">
          {i18n.t(!resent ? 'passwordRecovery:sendAgain' : 'goBack')}
        </TextButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  title: {
    color: palette.grey8,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  description: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
  },
  webDescription: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 44,
  },
  descriptionText: {
    textAlign: 'center',
    fontWeight: '400',
    marginRight: 8,
  },
  webDescriptionText: {
    textAlign: 'left',
  },
  button: {
    marginTop: 20,
    alignSelf: 'flex-start',
  },
  dividerMargin: {
    marginBottom: 10,
  },
});
