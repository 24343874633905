import React, { useEffect, useState } from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';

import { i18n } from 'src/locale';
import { typography } from 'src/styles';

import { Spinner } from './Spinner';
import { StyledText } from './StyledText';

type Size = 'large' | 'small';

interface Props {
  /** Displays additional text if spinner is displayed for at least 5 secs */
  displayStandbyText?: boolean;
  size?: number | Size;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  color?: string;
}

const TIMEOUTS: [ms: number, text: string][] = [
  [5000, i18n.t('loading:thisIsTakingLonger')],
  [10000, i18n.t('loading:standBy')],
];

const SIZES: Record<Size, number> = {
  large: 36,
  small: 20,
};

export const LoadingIndicator: React.FC<Props> = ({ size = 'large', displayStandbyText, style, color }) => {
  const [standbyText, setStandbyText] = useState('');
  const widthAndHeight = typeof size === 'string' ? SIZES[size] : size;

  useEffect(() => {
    if (displayStandbyText) {
      const timeoutIds = TIMEOUTS.map(([timeout, text]) =>
        setTimeout(() => {
          setStandbyText(text);
        }, timeout),
      );

      return () => {
        timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
      };
    }
  }, [displayStandbyText]);

  return (
    <View style={[styles.wrapper, style]} testID="loading-indicator">
      <Spinner size={widthAndHeight} color={color} />
      {!!standbyText && (
        <StyledText style={styles.text} testID="spinner-delay-message">
          {standbyText}
        </StyledText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
  text: {
    marginTop: 15,
    ...typography.body2,
  },
});
