import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { LoadingIndicatorBox, PrimaryButton, StyledText } from 'src/components';
import { showNotification } from 'src/helpers';
import { useNavigation } from 'src/navigation/hooks/useNavigation';
import { useRoute } from 'src/navigation/hooks/useRoute';
import { palette, typography } from 'src/styles';

import { useStudentVerificationContext } from '../context/StudentVerificationContext';
import { useEmailLoopTokenResend, useEmailLoopVerification, useSkipEmailLoop } from '../hooks/queries';
import { StudentVerificationScreenParams } from '../types';

const ns = 'studentVerification';
const EMAIL_RESEND_TIMEOUT = 1000 * 60 * 2; // time period in which email cannot be resent again

export const StudentEmailLoopMessage: React.FC = () => {
  const { t } = useTranslation(ns);
  const { emailToken } = (useRoute().params || {}) as StudentVerificationScreenParams;
  const { verificationId } = useStudentVerificationContext();
  const { setParams } = useNavigation();

  const shouldValidateEmailToken = emailToken && verificationId;
  const [hasEmailBeenResent, setEmailResend] = useState(false);

  const { mutate: verifyEmailTokenMutateFn } = useEmailLoopVerification();
  const { mutate: skipEmailLoop, isPending: isSkippingPending } = useSkipEmailLoop();
  const { mutate: resendEmailToken, isPending: isEmailResendPending } = useEmailLoopTokenResend();

  const verifyEmailToken = useCallback(
    (emailToken: string, verificationId: string) => {
      verifyEmailTokenMutateFn(
        { token: emailToken, verificationId },
        {
          onError: () => {
            showNotification({ type: 'error', title: t('emailLoopInvalidToken'), autoHide: false });
            setParams({ emailToken: undefined });
          },
        },
      );
    },
    [verifyEmailTokenMutateFn, t, setParams],
  );

  const handleSkipPress = () => {
    skipEmailLoop(verificationId!);
  };

  const handleResendPress = () => {
    resendEmailToken(verificationId!, {
      onSuccess: () => {
        setEmailResend(true);
        setTimeout(() => {
          setEmailResend(false);
        }, EMAIL_RESEND_TIMEOUT);
      },
    });
  };

  useEffect(() => {
    if (shouldValidateEmailToken) {
      verifyEmailToken(emailToken, verificationId);
    }
  }, [emailToken, verificationId, shouldValidateEmailToken, verifyEmailToken]);

  const isLoading = isSkippingPending || !!shouldValidateEmailToken;

  return (
    <View style={styles.wrapper}>
      <LoadingIndicatorBox isLoading={isLoading} displayStandbyText={false}>
        <StyledText style={styles.title}>{t('emailLoopHeader')}</StyledText>
        <StyledText style={styles.description}>{t('emailLoopDescription')}</StyledText>
        <Trans
          i18nKey="emailLoopSkip"
          ns={ns}
          components={{
            p: <StyledText style={styles.description} />,
            button: <StyledText onPress={handleSkipPress} style={styles.link} />,
          }}
        />
        <PrimaryButton
          title={t('resend')}
          onPress={handleResendPress}
          size="medium"
          containerStyle={styles.button}
          disabled={hasEmailBeenResent}
          loading={isEmailResendPending}
        />
        {hasEmailBeenResent && <StyledText style={styles.resentInfo}>{t('emailResent')}</StyledText>}
      </LoadingIndicatorBox>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'center',
  },
  title: {
    ...typography.body3Bold,
    color: palette.blue,
    marginBottom: 8,
    textAlign: 'center',
  },
  description: {
    ...typography.body2,
    marginBottom: 28,
    textAlign: 'center',
  },
  resentInfo: {
    ...typography.body2,
    color: palette.grey5,
    marginTop: 10,
    textAlign: 'center',
  },
  link: {
    color: palette.blue,
  },
  button: {
    alignSelf: 'center',
  },
});
