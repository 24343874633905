import React from 'react';
import { StyleSheet, View } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { palette, typography } from 'src/styles';

export const SectionSeparator: React.FC<React.PropsWithChildren> = ({ children }) => (
  <View style={styles.wrapper}>
    <View style={styles.line} />
    {children && <StyledText style={[typography.alt7, styles.text]}>{children}</StyledText>}
    <View style={styles.line} />
  </View>
);

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
  line: {
    width: '50%',
    height: 1,
    backgroundColor: palette.grey2,
    flexShrink: 1,
  },
  text: {
    color: palette.blue,
    marginHorizontal: 16,
  },
});
