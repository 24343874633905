import React, { useRef, useState } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { Container, Icon, Pressable, StyledText } from 'src/components';
import { OVERVIEW_TOC_ITEM_ID } from 'src/features/content/constants';
import { TableOfContentsProps } from 'src/features/content/types';
import { isWeb } from 'src/helpers';
import { useOutsideClickDetector } from 'src/hooks';
import { useTableOfContentsItems } from 'src/hooks/useTableOfContentsItems';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import {
  BOTTOM_BAR_HEIGHT,
  getShadowNative,
  ifWeb,
  MOBILE_TOC_HEIGHT,
  palette,
  typography,
} from 'src/styles';

import { TableOfContentsMenu } from './TableOfContensMenu';
import { ScrollView } from './TableOfContentsScrollView';

export const TableOfContentsMobileOld: React.FC<TableOfContentsProps> = ({
  item,
  onItemPress,
  scrollToTop,
  activeSectionId = OVERVIEW_TOC_ITEM_ID,
}) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { topPanelHeight } = useWebAppLayout();
  const { height: windowHeight } = useWindowDimensions();

  const items = useTableOfContentsItems({ item, onItemPress, scrollToTop });

  useOutsideClickDetector(wrapperRef, () => {
    setOpen(false);
  });

  const getActiveSectionTitle = (): string => {
    return items.find((item) => item.id === activeSectionId)?.title || '';
  };

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  if (!items.length) {
    return null;
  }

  return (
    <View style={styles.wrapper} ref={wrapperRef}>
      <Pressable onPress={toggleMenu}>
        <View>
          <Container style={styles.toggleContainer}>
            <View style={styles.titleWrapper}>
              <Icon name="table-of-contents" color={palette.navy} style={styles.icon} width={17} />
              <StyledText singleLine maxFontSizeMultiplier={1} style={styles.activeItem}>
                {getActiveSectionTitle()}
              </StyledText>
            </View>

            <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} color={palette.navy} width={10} />
          </Container>
        </View>
      </Pressable>
      {isOpen && (
        <ScrollView
          style={[
            styles.menuWrapper,
            {
              maxHeight: isWeb
                ? windowHeight - (MOBILE_TOC_HEIGHT + topPanelHeight + BOTTOM_BAR_HEIGHT)
                : windowHeight * 0.6,
            },
          ]}
          contentContainerStyle={styles.menuContent}
        >
          <Container>
            <TableOfContentsMenu
              items={items}
              inDropdown
              onItemPress={toggleMenu}
              active={activeSectionId}
            />
          </Container>
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: palette.white,
    borderTopWidth: 1,
    borderTopColor: palette.grey2,
    zIndex: 1,
    ...ifWeb(
      {
        height: MOBILE_TOC_HEIGHT,
        cursor: 'pointer',
        position: 'sticky',
      },
      {
        ...getShadowNative(80, 0.15, 20, 0.06),
      },
    ),
  },
  toggleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: MOBILE_TOC_HEIGHT,
  },
  icon: {
    marginRight: 12,
  },
  titleWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  activeItem: {
    color: palette.navy,
    ...ifWeb(
      {},
      {
        marginRight: 30,
      },
    ),
    ...typography.body3,
  },
  menuWrapper: {
    width: '100%',
    backgroundColor: palette.white,
    borderBottomWidth: 1,
    borderColor: palette.grey2,
    borderTopWidth: 1,
    height: 'auto',
    zIndex: 1,
    position: 'absolute',
    top: '100%',
  },
  menuContent: {
    paddingTop: 16,
    paddingBottom: 25,
  },
});
