import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { typography } from 'src/styles';

import { SecondaryButton } from './Buttons/SecondaryButton';
import { FileInput as BaseComponent } from './FileInput';
import { StyledText } from './StyledText';

type Props = React.ComponentProps<typeof BaseComponent>;

export const FileInput: React.FC<Props> = ({ id, label, multiple, onChange, accept, testID }) => {
  const [files, setFiles] = useState<FileList>();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.target;
    if (files) {
      setFiles(files);
    }
    onChange?.(files);
  };

  return (
    <View style={styles.wrapper}>
      <input
        type="file"
        style={StyleSheet.flatten(styles.input)}
        id={id}
        onChange={handleChange}
        multiple={multiple}
        accept={accept}
        data-testid={testID}
      />
      <label htmlFor={id}>
        <SecondaryButton title={label} size="small" containerStyle={styles.button} />
      </label>
      {files && (
        <StyledText style={styles.fileName}>
          {Array.from(files)
            .map((file) => file.name)
            .join(', ')}
        </StyledText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignSelf: 'flex-start',
  },
  input: {
    opacity: 0,
    height: 1,
  },
  fileName: {
    fontStyle: 'italic',
    ...typography.body1,
  },
  button: {
    cursor: 'pointer',
  },
});
