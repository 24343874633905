import { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface NotesListContext {
  setNoteState(noteId: string, isUnsaved: boolean): void;
  /** IDs of notes which haven't been saved. If it's a new note and doesn't have it's own ID yet,
   * we store ID of a section which this note is assigned to.  */
  unsavedNotes: string[];
  isAddingNewGeneralNote: boolean;
  setAddingNewGeneralNote(isAdding: boolean): void;
}

const NotesListContext = createContext<NotesListContext>({
  setNoteState: () => undefined,
  unsavedNotes: [],
  setAddingNewGeneralNote: () => undefined,
  isAddingNewGeneralNote: false,
});

/** Tracks if there are unsaved changes in any note rendered within the context */
export const NotesListProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [unsavedNotes, setUnsavedNotes] = useState<string[]>([]);
  const [isAddingNewGeneralNote, setAddingNewGeneralNote] = useState(false);

  const setNoteState: NotesListContext['setNoteState'] = useCallback((noteId, isUnsaved) => {
    if (isUnsaved) {
      setUnsavedNotes((state) => Array.from(new Set([...state, noteId])));
    } else {
      setUnsavedNotes((state) => state.filter((id) => id !== noteId));
    }
  }, []);

  return (
    <NotesListContext.Provider
      value={{ setNoteState, unsavedNotes, isAddingNewGeneralNote, setAddingNewGeneralNote }}
    >
      {children}
    </NotesListContext.Provider>
  );
};

/** To be used by a note component. Tracks if there are unsaved changes in note. For new note without it's own ID, pass section's ID. */
export const useNotesListItem = (noteId?: string) => {
  const { setNoteState } = useNotesListContext();

  const onDirtyChange = useCallback(
    (isUnsaved: boolean) => {
      noteId && setNoteState(noteId, isUnsaved);
    },
    [setNoteState, noteId],
  );

  useEffect(() => {
    return () => {
      noteId && setNoteState(noteId, false);
    };
  }, [setNoteState, noteId]);

  return { onDirtyChange };
};

/** Tracks if there are unsaved changes in any note rendered within the context */
export const useNotesListContext = () => useContext(NotesListContext);
