import { client } from 'src/api/client';

import type { UserNotificationActionPayload, UserNotificationsResponse } from './types';

export const fetchUserNotifications = async () => {
  const response = await client.request<UserNotificationsResponse>({
    url: '/accounts/user/notifications/',
    method: 'GET',
  });

  return response.data;
};

export const sendUserNotificationAction = async (data: UserNotificationActionPayload) => {
  const response = await client.request<UserNotificationsResponse>({
    url: '/accounts/user/notifications/',
    method: 'PUT',
    data,
  });

  return response.data;
};
