import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { FormikValues, useFormik } from 'formik';

import { SelectOption } from 'src/constants/types';
import { RecurlyPlan } from 'src/features/subscription';
import { Yup } from 'src/helpers/validation';

import { useStudentsCountries } from './queries';

dayjs.extend(objectSupport);

export function useStudentForm<T extends FormikValues>(
  initialValues: T,
  planCode: RecurlyPlan,
  validationSchema: Yup.SchemaOf<T>,
  onSubmit: (values: T) => void,
  enableReinitialize = false,
) {
  const { countriesOptions } = useStudentsCountries(planCode!);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    setErrors,
    setFieldValue,
    setFieldTouched,
    dirty,
    setTouched,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize,
    onSubmit,
    validationSchema,
  });

  const handleCountryChange = (option: SelectOption) => {
    setFieldTouched('country');
    setFieldValue('country', option.value);
    setFieldValue('organizationId', 0);
    setFieldValue('organizationName', '');
  };

  const handleOrganizationChange = (organizationId: number, organizationName: string) => {
    setFieldTouched('organizationName');
    setFieldValue('organizationId', organizationId);
    setFieldValue('organizationName', organizationName);
  };

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    setErrors,
    setFieldValue,
    setFieldTouched,
    dirty,
    setTouched,
    resetForm,
    countriesOptions,
    handleCountryChange,
    handleOrganizationChange,
  };
}
