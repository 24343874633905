import { onlineManager } from '@tanstack/react-query';
import { Suspense } from 'react';

import { InternetConnectionErrorBanner } from './InternetConnectionErrorBanner';

type Props = React.ComponentProps<typeof Suspense>;

/**
 * Works like Suspense, but displays an Internet Connection error instead of spinner if necessary
 */
export const NetworkErrorAwareSuspense: React.FC<Props> = (props) => {
  return (
    <Suspense
      {...props}
      fallback={onlineManager.isOnline() ? props.fallback : <InternetConnectionErrorBanner />}
    />
  );
};
