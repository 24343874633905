import { client } from 'src/api/client';
import { convertObjectToSnakeCase } from 'src/helpers';

import type {
  DrugInteractionCheckerResponse,
  DrugInteractionsParams,
  DrugNamesPayload,
  DrugNamesResponse,
  InteractionsCountParams,
  InteractionsCountResponse,
} from './types';

export const getDrugInteractions = async ({
  page,
  pageSize = 10,
  drugs,
  classification,
  mode,
}: DrugInteractionsParams) => {
  const resp = await client.request<DrugInteractionCheckerResponse>({
    url: '/contents/drug-interaction-checker/',
    params: convertObjectToSnakeCase({
      drugs: drugs?.join(','),
      page,
      pageSize,
      classification,
      mode,
    }),
  });

  return resp.data;
};

export const getInteractionsCount = async (params: InteractionsCountParams) => {
  const response = await client.request<InteractionsCountResponse>({
    method: 'GET',
    url: '/contents/drug-interaction-checker/count/',
    params: {
      drugs: params.drugs.join(','),
    },
  });

  return response.data;
};

export const getDrugNames = async (params: DrugNamesPayload) => {
  const response = await client.request<DrugNamesResponse>({
    method: 'GET',
    url: '/contents/drug-interaction-checker/names/',
    params: {
      drugs: params.join(','),
    },
  });

  return response.data;
};
