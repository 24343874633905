import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import objectHash from 'object-hash';
import Delta from 'quill-delta';
import * as R from 'ramda';
import { ReactNode, MouseEvent, isValidElement } from 'react';

import { AUTOLOGIN_TOKEN_SITES } from 'src/constants/constants';
import type { SelectOptions, SelectOption } from 'src/constants/types';
import type { TokenPayload } from 'src/features/auth/types';
import { ContentType } from 'src/features/content/constants';
import type {
  ContentCardLocation,
  ContentItemSource,
  ContentItemSection,
  SpecialAccordionLocation,
} from 'src/features/content/types';
import { markdownItInstance, MARKDOWN_TAGS } from 'src/helpers/markdown';
import { Route } from 'src/navigation';
import { EnvService } from 'src/services/EnvService';

import { isWeb, alphabet } from './common';
import {
  isAlgorithm,
  isAppendix,
  isDxTx,
  isDrug,
  isDrugHandout,
  isClinicalHandout,
  isDDx,
} from './content';
import { isSelectOptionsGroup } from './typeGuards';

export const voidFunction = () => null;

export const isNodeEmpty = (node: ReactNode): boolean => {
  if (Array.isArray(node)) {
    return node.filter((item) => !!item).length === 0;
  }

  return !node;
};

export function getJWTPayload(token: string) {
  if (!token) return null;

  const payload = jwtDecode(token);
  if (!payload || payload instanceof String) {
    return null;
  }

  return convertKeysToCamelCase(payload as { [key: string]: any }) as TokenPayload;
}

export function isJWTValid(token: string) {
  if (!token) return false;

  const payload = jwtDecode(token);
  if (!payload || payload instanceof String) {
    return false;
  }
  const expTimeMs = (payload as { [key: string]: any }).exp * 1000;
  const curTimeMs = new Date().getTime();

  return expTimeMs > curTimeMs;
}

export function camelCaseToSnake(str: string) {
  return str.replace(/([A-Z])/g, (x) => `_${x.toLowerCase()}`);
}

export function snakeCaseToCamel(str: string) {
  return str.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '');
  });
}

export function snakeCaseToNormal(str: string) {
  return str.replaceAll('_', ' ');
}

export function kebabCaseToNormal(str: string) {
  return str.replace(/-/g, ' ');
}

export function convertObjectToSnakeCase(
  obj: { [key: string]: any },
  convertValues = false,
): { [key: string]: any } {
  return Object.entries(obj).reduce((res, entry) => {
    const [key, value] = entry;
    return {
      ...res,
      [camelCaseToSnake(key)]: convertValues && typeof value === 'string' ? camelCaseToSnake(value) : value,
    };
  }, {});
}

export function convertKeysToCamelCase(obj: { [key: string]: any }): { [key: string]: any } {
  return Object.entries(obj).reduce((res, entry) => {
    const [key, value] = entry;
    return {
      ...res,
      [snakeCaseToCamel(key)]: R.when(R.is(Object), convertKeysToCamelCase, value),
    };
  }, {});
}

export function trimText(text: string, maxLength: number) {
  if (text.length > maxLength) return text.slice(0, maxLength) + '...';
  return text;
}

export function capitalizeString(text: string) {
  return text[0].toUpperCase() + text.slice(1);
}

export function capitalizeEveryWord(text: string) {
  return text.split(' ').map(capitalizeString).join(' ');
}

/**
 * Transforms Quill's Delta to markdown
 * Be aware that we handle only a few tags: bold, underline, italic, super- and subscripts
 */
export function deltaToMarkdown(delta: Delta): string {
  let result = '';
  delta.forEach((op) => {
    if (typeof op.insert !== 'string') return;
    let part = op.insert;

    if (!op.attributes) return (result += part);

    ['bold', 'underline', 'italic'].forEach((attr) => {
      if (op.attributes![attr]) {
        const tag = MARKDOWN_TAGS[attr] as string;
        part = `${tag}${tag}${part}${tag}${tag}`;
      }
    });
    if (op.attributes!.script === 'super' || op.attributes!.script === 'sub') {
      const tag = MARKDOWN_TAGS[op.attributes.script] as string;
      part = `${tag}${tag}${part}${tag}${tag}`;
    }
    result += part;
  });

  return result;
}

export const removeCommaAndWhitesSpaces = (str: string | undefined): string => {
  if (!str) return '';
  return str.replace(/[, ]+/g, ' ').trim();
};

//Add additional routes based on contentType
export const getRouteBasedOnContentType = (contentType: ContentType) => {
  if (isDrug({ contentType })) {
    return Route.DrugItem;
  }
  if (isAppendix({ contentType })) {
    return Route.AppendixItem;
  }
  if (isDxTx({ contentType })) {
    return Route.DxTxItem;
  }
  if (isDrugHandout({ contentType })) {
    return Route.DrugHandoutItem;
  }
  if (isClinicalHandout({ contentType })) {
    return Route.ClinicalHandoutItem;
  }
  if (isAlgorithm({ contentType })) {
    return Route.AlgorithmItem;
  }
  if (isDDx({ contentType })) {
    return Route.DDxItem;
  }

  throw new Error(`Couldn't recognize provided content type (${contentType})`);
};

export const isEmailValid = (email: string): boolean => {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

export const getEmailsFromString = (text: string): string[] => {
  const splitted = text.split(/[ ,]+/);
  return splitted.filter((value) => isEmailValid(value));
};

export const convertToMarkdown = (query: string, variant: keyof typeof MARKDOWN_TAGS): string => {
  const sequence = MARKDOWN_TAGS[variant].repeat(2);
  return sequence + query + sequence;
};

export const getAutologinTokenSiteName = (): (typeof AUTOLOGIN_TOKEN_SITES)[number] | undefined => {
  if (!isWeb) return;
  const domainStripped = EnvService.getEnv('DOMAIN')?.replace('app.', '');
  return AUTOLOGIN_TOKEN_SITES.find((site) => `${site}.${domainStripped}` === window.location.host);
};

export const getContentItemSource = (
  contentCardLocation: ContentCardLocation | SpecialAccordionLocation,
): ContentItemSource | undefined => {
  switch (contentCardLocation) {
    case 'search-list':
      return 'search';
    case 'recently-viewed':
    case 'top-viewed':
    case 'content-list-new':
    case 'content-list-updated':
    case 'content-list-favorites':
    case 'drug-page':
    case 'dx-tx-page':
      return contentCardLocation;
    default:
      return undefined;
  }
};

export const isContentCardSmall = (contentCardLocation: ContentCardLocation): boolean => {
  return contentCardLocation !== 'content-item';
};

export const handleImageRightClick = (event: MouseEvent<HTMLImageElement | HTMLVideoElement>) => {
  event.preventDefault();
};

export const markDownToPlainText = (string: string) => {
  const instance = markdownItInstance;

  instance.render(string);
  return instance.plainText;
};

export const getLetterListItemMarker = (itemIndex: number) => {
  const numberOfLetters = Math.floor(itemIndex / alphabet.length) + 1;
  const letterIndex = itemIndex % alphabet.length;
  return alphabet[letterIndex].repeat(numberOfLetters);
};

const romanMatrix = [
  [1000, 'M'],
  [900, 'CM'],
  [500, 'D'],
  [400, 'CD'],
  [100, 'C'],
  [90, 'XC'],
  [50, 'L'],
  [40, 'XL'],
  [10, 'X'],
  [9, 'IX'],
  [5, 'V'],
  [4, 'IV'],
  [1, 'I'],
] as const;

/**
 *
 * @see https://stackoverflow.com/a/37723879
 */
export const convertNumberToRoman = R.memoizeWith(
  (num) => num.toString(),
  (num: number): string => {
    if (num === 0) return '';

    for (let i = 0; i < romanMatrix.length; i++) {
      if (num >= romanMatrix[i][0]) {
        const roman = (romanMatrix[i][1] + convertNumberToRoman(num - romanMatrix[i][0])) as string;
        return roman;
      }
    }
    return '';
  },
);

export const getPublicationDetailsSectionPredecessorIndex = (sections: ContentItemSection[]) => {
  const referencesSections = ['references_suggested_reading', 'references_revisions'];
  const referencesIndex = sections.findIndex((section) => referencesSections.includes(section.id));

  return referencesIndex === -1 ? sections.length - 1 : referencesIndex;
};

export const getSelectOptionsWithoutGroups = (options: SelectOptions): SelectOption[] =>
  R.flatten(options.map((option) => (isSelectOptionsGroup(option) ? option.options : option)));

export const getObjectHash = (object: Record<any, any>): string =>
  objectHash(object, { unorderedArrays: true, unorderedObjects: true, unorderedSets: true });

export const clamp = (min: number, max: number, x: number) => {
  if (x < min) return min;
  if (x > max) return max;
  return x;
};

export const interpolate: (
  inputRange: [minX: number, maxX: number],
  outputRange: [minY: number, maxY: number],
  x: number,
  useClamp?: boolean,
) => number = ([minX, maxX], [minY, maxY], x, useClamp = true) => {
  const slope = (maxY - minY) / (maxX - minX);
  const y = minY + (x - minX) * slope;
  return useClamp ? clamp(minY, maxY, y) : y;
};

export const compareArrays = <T,>(...arrays: T[][]): boolean => {
  return arrays.every((array) => array.every((item) => arrays.every((array) => array.includes(item))));
};

export const getValidComponent = (
  component: React.ComponentType | React.ReactElement | null | undefined,
) => {
  const PassedComponent = component;
  return (
    // @ts-ignore
    (isValidElement(PassedComponent) && PassedComponent) || (PassedComponent && <PassedComponent />) || null
  );
};

export const isObjectNotEmpty = R.pipe<
  Record<string, string | undefined>,
  Record<string, string | undefined>,
  boolean
>(R.reject(R.isNil), R.complement(R.isEmpty));

export const waitFor = (condition: () => boolean, maxTime = 5000) => {
  const interval = 30;
  const maxAttempts = Math.floor(maxTime / interval);
  return new Promise<void>((resolve, reject) => {
    if (condition()) {
      resolve();
    }
    let attempts = 0;
    const intervalid = setInterval(() => {
      if (condition()) {
        clearInterval(intervalid);
        resolve();
      }
      attempts++;
      if (attempts >= maxAttempts) {
        clearInterval(intervalid);
        reject(`Max number of attempts in 'waitFor' function exceeded`);
      }
    }, interval);
  });
};

export const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

export const getNumberOfDaysInMonth = (month: number, year: number) =>
  dayjs(`${year}-${month}-01`).daysInMonth();

export const getScrollDivProperties = (div: HTMLDivElement) => {
  const { scrollWidth, clientWidth, scrollLeft } = div;
  const isScrollDisplayed = clientWidth < scrollWidth;

  const isScrolledToLeft = scrollLeft === 0;
  const isScrolledToRight = scrollLeft + clientWidth === scrollWidth;

  return {
    isScrollDisplayed,
    isScrolledToLeft,
    isScrolledToRight,
  };
};
