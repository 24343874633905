import axios from 'axios';
import type { AxiosError, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { isComponentTesting, isWeb } from 'src/helpers/common';
import { EnvService } from 'src/services/EnvService';

import { SERVER_ISSUE_ERROR_MESSAGE } from './constants';
import { authTokenRefreshLock, getUserAgentForNative } from './utils';

declare module 'axios' {
  export interface AxiosRequestConfig {
    omitAuth?: boolean;
    apiVersion?: number;
    isRetry?: boolean;
    isHealthCheck?: boolean;
  }
}

const scheme = EnvService.getEnv('SCHEME');
const domain = EnvService.getEnv('DOMAIN');

export const getApiPath = (version = 1) => `/api/v${version}`;
const getBaseURL = (apiVersion: number) =>
  isWeb ? getApiPath(apiVersion) : `${scheme}://${domain}${getApiPath(apiVersion)}`;

export const getAuthorizationHeader = (token: string) => `Bearer ${token}`;

export const client = axios.create({
  timeout: 30 * 1000,
  headers: isWeb
    ? {}
    : {
        'User-Agent': getUserAgentForNative(),
      },
});

client.interceptors.request.use((config) => {
  const { apiVersion = 1 } = config;

  return {
    ...config,
    baseURL: getBaseURL(apiVersion),
  };
});

client.interceptors.request.use(async (config): Promise<InternalAxiosRequestConfig> => {
  const { store } = await import('src/state/store');
  if (config.omitAuth || isComponentTesting) return config;

  await authTokenRefreshLock.promise;

  const {
    auth: { tokens },
  } = store.getState();

  if (!tokens.access) {
    throw new Error('No access token available');
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: getAuthorizationHeader(tokens.access),
    } as AxiosRequestHeaders,
  };
});

/** If we're using `responseType: blob` axios handles errors as Blob as well.
 * Here we transform them back to JSON.
 */
client.interceptors.response.use(undefined, async (error) => {
  if (error.response && error.response.data instanceof Blob) {
    try {
      const errorBlob = error.response.data;
      const errorText = await errorBlob.text();
      const errorData = JSON.parse(errorText);
      return Promise.reject({ ...error, response: { ...error.response, data: errorData } });
    } catch {
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
});

client.interceptors.response.use(undefined, async (error) => {
  const { store } = await import('src/state/store');
  const { handleInvalidAccessToken } = await import('src/features/auth/state');

  const isErrorWithResponse = isErrorWithResponseCheck(error);

  if (isErrorWithResponse && error.response.status === 500 && !error.config?.isHealthCheck) {
    try {
      const healthCheck = await client.get('/health', { isHealthCheck: true });
      if (healthCheck.status !== 200) {
        throw new Error(SERVER_ISSUE_ERROR_MESSAGE);
      }
    } catch (err) {
      throw new Error(SERVER_ISSUE_ERROR_MESSAGE);
    }
  }

  if (
    isErrorWithResponse &&
    [401, 403].includes(error.response.status) &&
    !error.config?.omitAuth &&
    !error.config?.isRetry
  ) {
    const {
      auth: { tokens },
    } = store.getState();

    if (authTokenRefreshLock.state !== 'pending') {
      authTokenRefreshLock.promise = store.dispatch(handleInvalidAccessToken(tokens.refresh)) as any;
    }

    if (error.response.status === 401) {
      return client({
        ...error.config,
        isRetry: true,
      });
    }
  }

  throw error;
});

type ErrorWithResponse = AxiosError & { response: AxiosResponse };

const isErrorWithResponseCheck = (error: any): error is ErrorWithResponse =>
  axios.isAxiosError(error) && !!error.response;
