import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { palette } from 'src/styles';

import { LoadingIndicator } from './LoadingIndicator';

interface Props extends React.PropsWithChildren {
  isLoading?: boolean;
  style?: StyleProp<ViewStyle>;
  displayStandbyText?: boolean;
}

export const LoadingIndicatorBox: React.FC<Props> = ({
  isLoading,
  style,
  children,
  displayStandbyText = true,
}) => {
  return (
    <View style={style}>
      {children}
      {isLoading && <LoadingIndicator style={styles.loader} displayStandbyText={displayStandbyText} />}
    </View>
  );
};

const styles = StyleSheet.create({
  loader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: palette.whiteTransparent,
    justifyContent: 'center',
  },
});
