import React, { RefObject } from 'react';
import { StyleSheet, View } from 'react-native';

import { AccordionLegacy, MobileContentHeader, BottomBar } from 'src/components';
import { SpecialSectionIDs } from 'src/constants/constants';
import { AccordionRef } from 'src/constants/types/accordion';
import { useUserInfo } from 'src/features/auth/hooks';
import { ContentType } from 'src/features/content/constants';
import { ContentItemSection } from 'src/features/content/types';
import { ContentNotes, RemoveNoteConfirmModal } from 'src/features/notes';
import { useNotesListContext } from 'src/features/notes/context/NotesListContext';
import { MixpanelEvent, MixpanelService, SentryService } from 'src/features/tracking';
import { getMixpanelParamsForBackToTopInContent } from 'src/helpers';
import { AccordionState } from 'src/hooks/useAccordionState';
import { useAccordionToggle } from 'src/hooks/useAccordionToggle';
import { useContentItemAccordionEvents } from 'src/hooks/useContentItemAccordionEvents';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { CONTAINER_PADDING_H_MOBILE } from 'src/styles';

import { Section } from './Section';
import { AccordionsToggle } from '../AccordionsToggle';
import { FeatureButtonProps } from '../FeatureButton';

export interface ContentAccordionProps {
  accordionRef: RefObject<AccordionRef>;
  onExpandedItemsChange?: () => void;
  sections: ContentItemSection[];
  notes?: ContentNotes;
  sectionsRefs: Record<string, RefObject<View>>;
  contentType: ContentType;
  contentTitle: string;
  contentId: string;
  state: AccordionState;
  sectionsWithButtons?: Record<SpecialSectionIDs, FeatureButtonProps>;
  isAnyItemExpanded?: boolean;
  setIsAnyItemExpanded: (value: boolean) => void;
  removeNoteConfirmModalProps: null | React.ComponentProps<typeof RemoveNoteConfirmModal>;
  setRemoveNoteConfirmModal: React.Dispatch<
    React.SetStateAction<null | React.ComponentProps<typeof RemoveNoteConfirmModal>>
  >;
}

export const ContentAccordion: React.FC<ContentAccordionProps> = ({
  accordionRef,
  contentType,
  contentId,
  contentTitle,
  notes,
  onExpandedItemsChange,
  sections,
  sectionsRefs,
  state,
  sectionsWithButtons,
  isAnyItemExpanded,
  setIsAnyItemExpanded,
  removeNoteConfirmModalProps,
  setRemoveNoteConfirmModal,
}) => {
  const { isTablet, isLargeDesktop } = useDeviceInfo();
  const {
    permissions: { hasFullAccessToCoreFuncUXUpdates },
  } = useUserInfo();

  const { unsavedNotes } = useNotesListContext();
  const { onSectionClose, onSectionOpen, onCloseAllPress, onOpenAllPress } = useContentItemAccordionEvents(
    contentId,
    contentTitle,
    contentType,
    sections,
  );

  const onExpandedItemsChangeCallback = () => {
    setIsAnyItemExpanded(!accordionRef.current?.areAllItemsCollapsed());
    onExpandedItemsChange && onExpandedItemsChange();
  };

  const { handleToggleAllSectionsPress } = useAccordionToggle({
    notes,
    accordionRef,
    isAnyItemExpanded,
    setRemoveNoteConfirmModal,
    onCloseAllPress,
    onOpenAllPress,
  });

  const shouldSectionBeToggled = async (sectionId: string, isExpanded: boolean) => {
    if (!isExpanded) {
      // Sections can always be opened
      return true;
    }
    const noteId = notes?.[sectionId]?.id || sectionId;

    const isNoteCardDirty = noteId && unsavedNotes.includes(noteId);
    if (isNoteCardDirty) {
      // open a modal and ask the user for action
      return await new Promise<boolean>((finalize) => {
        setRemoveNoteConfirmModal({
          confirm: () => {
            setRemoveNoteConfirmModal(null);
            finalize(true);
          },
          cancel: () => {
            setRemoveNoteConfirmModal(null);
            finalize(false);
          },
          type: 'navigation',
        });
      });
    }

    return true;
  };

  const handleSectionOpen = (sectionId: string) => {
    onSectionOpen(sectionId);
  };

  const handleSectionClose = (sectionId: string) => {
    onSectionClose(sectionId);
  };

  const afterScrollToTopPress = () => {
    try {
      const params = getMixpanelParamsForBackToTopInContent(contentId, contentTitle, contentType);
      MixpanelService.track(MixpanelEvent.BackToTopClicked, params);
    } catch (error: any) {
      console.error(error);
      SentryService.captureException(`Couldn't track ${MixpanelEvent.BackToTopClicked} event`, {
        message: error?.message,
        contentId,
        contentTitle,
        contentType,
      });
    }
  };

  return (
    <>
      <View style={styles.accordionHeaderWrapper}>
        <MobileContentHeader
          style={[styles.accordionHeaderElement, isTablet && styles.accordionHeaderElementTablet]}
        >
          {i18n.t('contents:contents')}
        </MobileContentHeader>
        {hasFullAccessToCoreFuncUXUpdates && isLargeDesktop && (
          <AccordionsToggle
            openTestID="button-open-all-content"
            closeTestID="button-close-all-content"
            isAnyItemExpanded={isAnyItemExpanded}
            onPress={handleToggleAllSectionsPress}
            style={[styles.accordionHeaderElement, isTablet && styles.accordionHeaderElementTablet]}
          />
        )}
      </View>
      <AccordionLegacy
        fullWidthMode={!isTablet}
        beforeItemToggle={shouldSectionBeToggled}
        state={state}
        ref={accordionRef}
        onExpandedItemsChange={onExpandedItemsChangeCallback}
        items={sections.map((section) => ({
          Label: section.title,
          Content: (
            <Section
              key={section.id}
              content={section.value}
              sectionId={section.id}
              contentType={contentType}
              contentId={contentId}
              contentTitle={contentTitle}
              title={section.title}
              note={notes?.[section.id]}
              headerButton={sectionsWithButtons?.[section.id as SpecialSectionIDs]}
            />
          ),
          id: section.id,
          itemRef: sectionsRefs[section.id],
          dataSet: {
            sectionid: section.id,
          },
        }))}
        onItemClose={handleSectionClose}
        onItemOpen={handleSectionOpen}
      />
      {removeNoteConfirmModalProps && <RemoveNoteConfirmModal {...removeNoteConfirmModalProps} />}
      <BottomBar
        showScrollToTop
        toggleItemCollapse={handleToggleAllSectionsPress}
        isAnyItemExpanded={isAnyItemExpanded}
        afterScrollToTop={afterScrollToTopPress}
      />
    </>
  );
};

export const styles = StyleSheet.create({
  accordionHeaderWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  accordionHeaderElement: {
    marginTop: 28,
    marginBottom: 12,
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  accordionHeaderElementTablet: {
    paddingHorizontal: 0,
  },
});
