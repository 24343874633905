import { useContext } from 'react';

import { FindOnPageContext, FindOnPageContextTypes } from '../context/FindOnPageProvider';

export const useFindOnPage = (): FindOnPageContextTypes | null => {
  const context = useContext(FindOnPageContext);
  if (!context) {
    // return null - ('useFindOnPage must be used within an FindOnPageProvider');
    return null;
  }
  return context;
};
