import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoModal } from 'src/components/InfoModal';

interface Props {
  confirm: () => void;
  cancel: () => void;
  type: 'remove' | 'cancel' | 'navigation';
}

export const RemoveNoteConfirmModal: React.FC<Props> = ({ confirm, cancel, type }) => {
  const { t } = useTranslation('notes');
  return (
    <InfoModal
      header={t('confirmModal.header', { context: type })}
      icon="trash"
      close={cancel}
      testID="remove-note-modal"
    >
      <InfoModal.Content testID="modal-description">
        {t('confirmModal.content', { context: type })}
      </InfoModal.Content>
      <InfoModal.Buttons direction="row">
        <InfoModal.Button
          variant="secondary"
          title={t('confirmModal.cancelButton', { context: type })}
          onPress={cancel}
          testID="modal-cancel-btn"
        />
        <InfoModal.Button
          title={t('confirmModal.actionButton', { context: type })}
          onPress={confirm}
          testID="modal-submit-btn"
        />
      </InfoModal.Buttons>
    </InfoModal>
  );
};
