import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { Container, FullScreenTemplate, MobileContentHeader, TabsSelector } from 'src/components';
import { QueryErrorBanner } from 'src/errorHandling/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { isDrugHandout } from 'src/helpers';
import { IS_PRINTING_PDF_WITH_IFRAME } from 'src/helpers/pdf';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { AppDispatch } from 'src/state/store';
import { CONTAINER_PADDING_H_MOBILE, palette } from 'src/styles';

import { DownloadHandouts } from '../components/DownloadHandouts';
import { EmailHandouts } from '../components/EmailHandouts';
import { HandoutsLanguageSelector } from '../components/HandoutsLanguageSelector';
import { PrintHandouts } from '../components/PrintHandouts';
import { SelectedHandoutsList } from '../components/SelectedHandoutsList';
import { useHandoutsToShare } from '../hooks';
import { clearHandoutsSelection } from '../state';
import { HandoutsLanguageType, ShareHandoutsFormProps } from '../types';

type Section = 'email' | 'print' | 'download';

type Props = ScreenProps<Route.SelectedHandouts> | ScreenProps<Route.ShareHandout>;
const DEFAULT_LANGUAGE = HandoutsLanguageType.English;

export const SelectedHandoutsScreen: React.FC<Props> = ({ route, navigation }) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('handouts');
  const { isTablet } = useDeviceInfo();
  const { hasAccessToEmailHandouts } = useUserInfo();
  const { error, isLoading, handouts, singleHandoutMode, fetchStatus } = useHandoutsToShare();

  const [activeSection, setSection] = useState<Section>();
  const [language, setLanguage] = useState<HandoutsLanguageType>(DEFAULT_LANGUAGE);

  const onShareSuccess = () => {
    /** Redirecting breaks the printing process when using iFrame solution */
    const shouldRedirect = !IS_PRINTING_PDF_WITH_IFRAME;
    if (route.name === Route.SelectedHandouts) {
      dispatch(clearHandoutsSelection());
    }

    if (shouldRedirect) {
      if (handouts.length === 1) {
        const { id, contentType } = handouts[0];
        navigation.navigate(
          isDrugHandout({ contentType }) ? Route.DrugHandoutItem : Route.ClinicalHandoutItem,
          { id },
        );
      } else {
        navigation.navigate(
          isDrugHandout(handouts[0]) ? Route.DrugHandoutsList : Route.ClinicalHandoutsList,
        );
      }
    }
  };

  const isLanguageSelectorDisabled = !handouts.find((item) => item.hasSpanishVersion);
  const noDataToDisplay = singleHandoutMode && handouts.length === 0;
  const areFormsDisabled = handouts.length === 0;
  const LanguageSelector = (
    <HandoutsLanguageSelector
      value={language}
      onChange={setLanguage}
      disabled={isLanguageSelectorDisabled}
    />
  );
  const formProps: ShareHandoutsFormProps = { onShareSuccess, LanguageSelector, language };

  useEffect(() => {
    if (isLanguageSelectorDisabled) {
      setLanguage(DEFAULT_LANGUAGE);
    }
  }, [isLanguageSelectorDisabled]);

  return (
    <FullScreenTemplate
      title={t(singleHandoutMode ? 'shareHandoutHeader' : 'selectedHandoutsHeader')}
      showBackButton
      contentContainerStyle={styles.screen}
      isLoading={isLoading}
      testID="selected-handouts-screen"
    >
      <QueryErrorBanner isDataAvailable={!noDataToDisplay} error={error} fetchStatus={fetchStatus} />
      {!noDataToDisplay && (
        <Container fullWidthOnMobile verticalPadding maxWidth="narrow">
          <SelectedHandoutsList />

          <View style={[styles.shareContainer, isTablet && styles.shareContainerTablet]}>
            <MobileContentHeader style={styles.header}>{t('common:share')}</MobileContentHeader>
            <TabsSelector
              items={[
                {
                  id: 'email',
                  title: t('common:email'),
                  isHidden: !hasAccessToEmailHandouts,
                  testID: 'email-handouts-tab',
                },
                {
                  id: 'print',
                  title: t('common:print'),
                  testID: 'print-handouts-tab',
                },
                {
                  id: 'download',
                  title: t('common:download'),
                  testID: 'download-handouts-tab',
                },
              ]}
              selected={areFormsDisabled ? undefined : activeSection}
              onPress={(id) => setSection(id)}
              vertical={!isTablet}
              disabled={areFormsDisabled}
            />
            {!areFormsDisabled && (
              <View style={styles.formWrapper}>
                {activeSection === 'email' && <EmailHandouts {...formProps} />}
                {activeSection === 'print' && <PrintHandouts {...formProps} />}
                {activeSection === 'download' && <DownloadHandouts {...formProps} />}
              </View>
            )}
          </View>
        </Container>
      )}
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  screen: {
    backgroundColor: palette.white,
  },
  header: {
    marginBottom: 12,
  },
  shareContainer: {
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    marginTop: 30,
  },
  shareContainerTablet: {
    paddingHorizontal: 0,
  },
  formWrapper: {
    marginTop: 28,
  },
});
