import debounce from 'debounce';
import React, { useEffect, useMemo, useState } from 'react';

import { BaseButton, Subheader } from 'src/components';
import {
  useOccupationDetailsForm,
  personalInformationForm,
  personalInformationEmptyState,
  PersonalInformation,
  ProfileInformation,
  isPersonalInformationSectionFilled,
} from 'src/features/profile';
import { MixpanelService, MixpanelEvent } from 'src/features/tracking';
import { useUniversalForm } from 'src/hooks/forms/useUniversalForm';
import { useCountriesList } from 'src/hooks/queries/countries';
import { i18n } from 'src/locale';

import { SubscriptionSection as Section } from './SubscriptionSections';
import { useRegistrationFormStep } from '../../forms/hooks';
import { RegistrationFormStep } from '../../forms/types';
import { usePromotion } from '../../hooks';
import { SubscriptionBaseProps } from '../../types';

interface Props extends SubscriptionBaseProps {
  submitLabel?: string;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  additionalContent?: React.ReactNode;
}

export const CompleteYourProfileForm: React.FC<Props> = ({
  submitLabel,
  submitLoading,
  submitDisabled,
  additionalContent,
}) => {
  const { data, isSubmitted, onDirtyChange, submit } = useRegistrationFormStep(
    RegistrationFormStep.CompleteYourProfile,
    {
      onError: setErrors,
    },
  );
  const [sectionData, setSectionData] = useState<Partial<ProfileInformation>>(
    data || {
      information: undefined,
      occupation: undefined,
    },
  );
  const { data: countries = [] } = useCountriesList();

  const { data: promotion } = usePromotion();

  const defaultPersonalInfo = promotion
    ? {
        ...personalInformationEmptyState,
        firstName: promotion.params.firstName,
        lastName: promotion.params.lastName,
        country: countries.find((c) => c.name === promotion.params.country)?.code,
      }
    : personalInformationEmptyState;

  const {
    renderField: renderFieldInformation,
    handleSubmit: handleSubmitInformation,
    setErrors: setInformationErrors,
    dirty: personalInformationDirty,
  } = useUniversalForm(
    sectionData.information || defaultPersonalInfo,
    (values) => editPersonalData(values as PersonalInformation, 'information'),
    personalInformationForm,
  );
  const {
    renderField: renderFieldOccupation,
    handleSubmit: handleSubmitOccupation,
    setErrors: setOccupationErrors,
    dirty: occupationDetailsDirty,
  } = useOccupationDetailsForm(sectionData.occupation, (values) => editPersonalData(values, 'occupation'));

  const handleSubmit = useMemo(() => debounce(submit, 200), [submit]);

  useEffect(() => {
    if (isPersonalInformationSectionFilled(sectionData.information) && !!sectionData.occupation) {
      handleSubmit(sectionData as ProfileInformation);
    }
    // If `handleSubmit` is passed to the array, then the form gets submited
    // whenever it changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionData]);

  function editPersonalData<K extends keyof ProfileInformation>(values: ProfileInformation[K], variant: K) {
    setSectionData({ ...sectionData, [variant]: { ...values } });
  }

  function setErrors(errors: Record<string, string>) {
    setInformationErrors(errors);
    setOccupationErrors(errors);
  }

  const submitSection = () => {
    handleSubmitInformation();
    handleSubmitOccupation();
    MixpanelService.track(MixpanelEvent.CompleteYourProfile, undefined, { enableAsAnonymous: true });
  };

  const buttonLabel: string = submitLabel ?? i18n.t(isSubmitted ? 'saveChanges' : 'next');

  const dirty = personalInformationDirty || occupationDetailsDirty;
  useEffect(() => {
    onDirtyChange?.(dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  return (
    <>
      <Subheader title={i18n.t('accountInformation:personalInformation')} />
      <Section.Form>
        <Section.Row>
          <Section.Item>{renderFieldInformation('firstName')}</Section.Item>
          <Section.Item>{renderFieldInformation('lastName')}</Section.Item>
        </Section.Row>
        <Section.Row>
          <Section.Item>{renderFieldInformation('phone')}</Section.Item>
          <Section.Item>{renderFieldInformation('bornYear')}</Section.Item>
        </Section.Row>
      </Section.Form>
      <Subheader title={i18n.t('subscriptionProcess:addressInformation')} spreader />
      <Section.Form>
        <Section.Row>
          <Section.Item>{renderFieldInformation('practice')}</Section.Item>
          <Section.Item>{renderFieldInformation('address1')}</Section.Item>
          <Section.Item>{renderFieldInformation('address2')}</Section.Item>
          <Section.Item>{renderFieldInformation('city')}</Section.Item>
        </Section.Row>
        <Section.Row>
          <Section.Item>{renderFieldInformation('country')}</Section.Item>
          <Section.Item>{renderFieldInformation('postalCode')}</Section.Item>
          <Section.Item>{renderFieldInformation('state')}</Section.Item>
        </Section.Row>
      </Section.Form>
      <Subheader title={i18n.t('accountInformation:occupationDetails')} spreader />
      <Section.Form>
        <Section.Row>
          <Section.Item>{renderFieldOccupation('activity')}</Section.Item>
          <Section.Item>{renderFieldOccupation('activityOther')}</Section.Item>
          <Section.Item>{renderFieldOccupation('pharmacy')}</Section.Item>
          <Section.Item>{renderFieldOccupation('pharmacyOther')}</Section.Item>
          <Section.Item>{renderFieldOccupation('graduationYear')}</Section.Item>
          <Section.Item>{renderFieldOccupation('role')}</Section.Item>
          <Section.Item>{renderFieldOccupation('roleOther')}</Section.Item>
          <Section.Item>{renderFieldOccupation('schoolGraduated')}</Section.Item>
          <Section.Item>{renderFieldOccupation('schoolGraduatedOther')}</Section.Item>
        </Section.Row>
        <Section.Row>
          <Section.Item>{renderFieldOccupation('schoolAttending')}</Section.Item>
          <Section.Item>{renderFieldOccupation('schoolAttendingOther')}</Section.Item>
          <Section.Item>{renderFieldOccupation('yearsOfPractice')}</Section.Item>
          <Section.Item>{renderFieldOccupation('numberOfVeterinarians')}</Section.Item>
          <Section.Item>{renderFieldOccupation('practiceType')}</Section.Item>
          <Section.Item>{renderFieldOccupation('practiceTypeOther')}</Section.Item>
          <Section.Item>{renderFieldOccupation('practiceOwnership')}</Section.Item>
        </Section.Row>
      </Section.Form>
      {additionalContent}
      <Section.ButtonsWrapper>
        <BaseButton
          title={buttonLabel}
          onPress={submitSection}
          disabled={(isSubmitted && !dirty) || submitLoading || submitDisabled}
          loading={submitLoading}
          testID="complete-profile-next-btn"
          variant="gradient"
        />
      </Section.ButtonsWrapper>
    </>
  );
};

export const CompleteYourProfile: React.FC<Props> = (props) => {
  const { isActive, isSubmitted } = useRegistrationFormStep(RegistrationFormStep.CompleteYourProfile);
  return (
    <Section
      title={i18n.t('subscriptionProcess:completeYourProfile')}
      active={isActive}
      checked={isSubmitted}
      stepNo={props.stepNo}
      testID="complete-profile-section"
    >
      {isActive && <CompleteYourProfileForm {...props} />}
    </Section>
  );
};
