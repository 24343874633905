import { useQuery } from '@tanstack/react-query';

import { client } from 'src/api/client';
import { PaginationResponse } from 'src/constants/types';
import { ContentItemBase } from 'src/features/content/types';
import { convertObjectToSnakeCase, isWeb } from 'src/helpers';

import { recentlyViewedQueryKeys } from '../constants';
import { RecentlyViewedFilter, ViewedSortOrder } from '../types';

export const useViewedContent = (
  sortOrder: ViewedSortOrder,
  contentType: RecentlyViewedFilter = RecentlyViewedFilter.All,
) => {
  return useQuery({
    queryKey: recentlyViewedQueryKeys.list(sortOrder, contentType),
    queryFn: async () => {
      const response = await client.request<PaginationResponse<ContentItemBase>>({
        method: 'GET',
        url: '/accounts/user/viewed/',
        params: convertObjectToSnakeCase({
          contentType,
          page: 1,
          pageSize: isWeb ? 6 : 50,
          orderBy: sortOrder,
        }),
      });
      return response.data.results;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
};
