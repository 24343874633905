import React, {
  useRef,
  useContext,
  useImperativeHandle,
  forwardRef,
  createContext,
  ReactNode,
  useEffect,
} from 'react';

import type { ScrollableWrapperRef, SectionProps, ScrollableWrapperType } from './ScrollableWrapper';

interface ISectionRefsContext {
  registerSection: (id: string, ref: HTMLElement | null) => void;
  getSection(id: string): HTMLElement | null;
}

const SectionRefsContext = createContext<ISectionRefsContext | undefined>(undefined);

const SectionRefsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  const registerSection = (id: string, ref: HTMLElement | null) => {
    sectionRefs.current[id] = ref;
  };

  const getSection = (id: string) => {
    return sectionRefs.current[id];
  };

  return (
    <SectionRefsContext.Provider value={{ registerSection, getSection }}>
      {children}
    </SectionRefsContext.Provider>
  );
};

const Wrapper = forwardRef<ScrollableWrapperRef, React.PropsWithChildren>(({ children }, ref) => {
  const context = useContext(SectionRefsContext)!;

  useImperativeHandle(ref, () => ({
    scrollToSection: (sectionId: string) => {
      const section = context.getSection(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }));

  return <>{children}</>;
});

const Section: React.FC<SectionProps> = ({ id, children }) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const context = useContext(SectionRefsContext);

  if (!context) {
    throw new Error('Section must be used within a WrapperComponent');
  }

  useEffect(() => {
    context.registerSection(id, sectionRef.current);
  }, [context, id]);

  return (
    <div ref={sectionRef} id={id}>
      {children}
    </div>
  );
};

export const ScrollableWrapper = forwardRef<ScrollableWrapperRef, React.PropsWithChildren>(
  ({ children }, ref) => {
    return (
      <SectionRefsProvider>
        <Wrapper ref={ref}>{children}</Wrapper>
      </SectionRefsProvider>
    );
  },
) as ScrollableWrapperType;

ScrollableWrapper.Section = Section;
