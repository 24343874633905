import { StyleProp, StyleSheet, TextStyle, View } from 'react-native';

import { MobileContentHeader } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { CONTAINER_PADDING_H_MOBILE } from 'src/styles';

interface Props {
  title: string;
  button?: React.ReactNode;
  titleStyle?: StyleProp<TextStyle>;
  applyTopMargin?: boolean;
}

export const NotesGroupHeader: React.FC<Props> = ({ title, button, applyTopMargin, titleStyle }) => {
  const { isTablet } = useDeviceInfo();
  return (
    <View
      style={[styles.wrapper, isTablet && styles.wrapperTablet, applyTopMargin && styles.wrapperTopMargin]}
    >
      <MobileContentHeader style={titleStyle}>{title}</MobileContentHeader>
      {button}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  wrapperTablet: {
    paddingHorizontal: 0,
  },
  wrapperTopMargin: {
    marginTop: 28,
  },
});
