import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Pressable } from 'src/components/Pressable';
import { palette, typography } from 'src/styles';

import { StyledText } from '../StyledText';

interface Props {
  checked: boolean;
  onPress: (value: boolean) => void;
  title: string;
  children?: React.JSX.Element;
  error?: string;
  testID?: string;
  disabled?: boolean;
}

export class CheckboxInput extends React.PureComponent<Props> {
  onPress = () => {
    const { checked, onPress } = this.props;
    onPress(!checked);
  };

  render() {
    const { checked, children, error, title, testID, disabled } = this.props;
    return (
      <>
        <Pressable onPress={this.onPress} testID={testID} disabled={disabled}>
          {() => (
            <View style={styles.container}>
              <View style={[styles.buttonOuter, disabled && styles.buttonOuterDisabled]}>
                <View
                  style={[
                    styles.buttonInner,
                    disabled && styles.buttonInnerDisabled,
                    checked && styles.buttonInnerChecked,
                  ]}
                />
              </View>
              <View style={styles.contentContainer}>
                <StyledText style={[styles.label, disabled && styles.labelDisabled]}>{title}</StyledText>
                {children}
              </View>
            </View>
          )}
        </Pressable>
        {!!error && (
          <View style={styles.errorContainer} testID="checkbox-error">
            <StyledText style={[typography.text1, styles.error]}>{error}</StyledText>
          </View>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    padding: 2,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    color: palette.blue,
  },
  labelDisabled: {
    color: palette.grey5,
  },
  errorContainer: {
    height: 20,
    justifyContent: 'center',
  },
  error: {
    color: palette.red,
  },
  buttonOuter: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderColor: palette.blue,
    marginRight: 9,
  },
  buttonInner: {
    width: 10,
    height: 10,
    backgroundColor: palette.transparent,
  },
  buttonInnerChecked: {
    backgroundColor: palette.blue,
  },
  buttonOuterDisabled: {
    borderColor: palette.grey5,
  },
  buttonInnerDisabled: {
    backgroundColor: palette.grey5,
  },
});
