import React from 'react';
import { Trans } from 'react-i18next';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { palette, typography } from 'src/styles';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export const DICDisclaimer: React.FC<Props> = ({ style }) => {
  return (
    <View style={[styles.box, style]}>
      <Trans
        i18nKey="dic:disclaimer"
        components={{
          p1: <StyledText style={styles.paragraph} />,
          p: <StyledText style={[styles.paragraph, styles.paragraphMargin]} />,
          bi: <StyledText style={[typography.weightBold, styles.fontItalic]} />,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  box: {
    backgroundColor: palette.grey1,
    borderRadius: 5,
    padding: 16,
  },
  paragraph: {
    ...typography.body2,
  },
  paragraphMargin: {
    marginTop: 16,
  },
  fontItalic: {
    fontStyle: 'italic',
  },
});
