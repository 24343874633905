import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchContentTypeFilter } from '../enums';

export interface GeneralSearchState {
  mainFilter: SearchContentTypeFilter;
  query: string;
}

export const initialState: GeneralSearchState = {
  mainFilter: SearchContentTypeFilter.All,
  query: '',
};

const generalSearchUtilsSlice = createSlice({
  name: 'generalSearchUtils',
  initialState,
  reducers: {
    setMainFilter(state, action: PayloadAction<SearchContentTypeFilter>) {
      state.mainFilter = action.payload;
    },
    setQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
  },
});

export const { setMainFilter, setQuery } = generalSearchUtilsSlice.actions;
export const searchReducer = generalSearchUtilsSlice.reducer;
