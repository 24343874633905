import React from 'react';
import { StyleSheet, Pressable, View, StyleProp, ViewStyle } from 'react-native';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';

import { Icon } from 'src/components/Icon/Icon';
import { StyledText } from 'src/components/StyledText';
import { CONTAINER_PADDING_H_MOBILE, palette, typography } from 'src/styles';

interface Props {
  isScrollingTop?: boolean;
  onPress?(): void;
  placeholder: string;
  wrapperStyle?: StyleProp<ViewStyle>;
}

const gradientId = 'search-input-gradient';

export const VoidSearchBox: React.FC<Props> = ({ onPress, placeholder, wrapperStyle }) => {
  return (
    <Pressable onPress={onPress}>
      <View style={[styles.wrapper, wrapperStyle]}>
        <View style={styles.gradientWrapper}>
          <Svg height={50} width="100%" style={[StyleSheet.absoluteFill]}>
            <Defs>
              <LinearGradient id={gradientId}>
                <Stop offset="0%" stopColor="#1851A8" stopOpacity="1" />
                <Stop offset="61%" stopColor="#00A2E2" stopOpacity="1" />
                <Stop offset="100%" stopColor="#00C7E2" stopOpacity="1" />
              </LinearGradient>
            </Defs>
            <Rect x="0" y="0" width="100%" height={50} fill={`url(#${gradientId})`} />
          </Svg>
          <View style={styles.inner}>
            <Icon name="magnify" color={palette.navy} width={16} />
            <StyledText style={[typography.text2, styles.placeholder]} singleLine>
              {placeholder}
            </StyledText>
          </View>
        </View>
      </View>
    </Pressable>
  );
};
const styles = StyleSheet.create({
  wrapper: {
    height: 60,
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    backgroundColor: palette.white,
    justifyContent: 'center',
  },
  gradientWrapper: {
    height: 50,
    borderRadius: 25,
    overflow: 'hidden',
  },
  inner: {
    backgroundColor: palette.white,
    height: 48,
    marginTop: 1,
    borderRadius: 25,
    marginRight: 1,
    marginLeft: 1,
    flexDirection: 'row',
    paddingHorizontal: 13,
    alignItems: 'center',
  },
  placeholder: {
    color: palette.grey5,
    marginLeft: 8,
  },
});
