import * as R from 'ramda';

import { EnvService } from 'src/services/EnvService';

import type { IAppcuesService, AppcuesIdentifyOptions } from '../types';

interface Appcues {
  identify(userId: string, options?: AppcuesIdentifyOptions): void;
  reset(): void;
  show(flowId: string): void;
}

declare global {
  interface Window {
    Appcues?: Appcues;
  }
}

class AppcuesService implements IAppcuesService {
  private isEnabled: boolean;

  constructor() {
    this.isEnabled = !!EnvService.getEnv('APPCUES_ACCOUNT_ID');
  }

  async initialize() {
    // do nothing
  }

  identify(userId: string, options: AppcuesIdentifyOptions) {
    if (this.isEnabled) {
      window.Appcues?.identify(userId, R.reject(R.isNil, options));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackScreenView(title: string) {
    return;
  }

  clearUser() {
    window.Appcues?.reset();
  }

  displayFlow(flowId: string) {
    window.Appcues?.show(flowId);
  }
}

const instance = new AppcuesService();

export { instance as AppcuesService };
