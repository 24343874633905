import { ReactNode, useCallback, useMemo, useState } from 'react';

import { ModalPortal } from 'src/contexts/portals';
import { useUserInfo } from 'src/features/auth/hooks';
import type { DrugDetails } from 'src/features/drugs';
import type { DxTxDetails } from 'src/features/dxTx';
import { PrintableHandoutsModal } from 'src/features/handouts';
import { isDrug, isDrugHandout, isDxTx } from 'src/helpers';
import { isWeb } from 'src/helpers/common';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';

interface Params {
  item?: DrugDetails | DxTxDetails;
}

interface HandoutsManagement {
  shouldDisplayButton: boolean;
  openPrintableHandoutsList: () => void;
  renderedModal: ReactNode;
  numberOfHandouts: number;
}

/** Hook to handle downloading guides attached to a Drug or Dx&Tx */
export const useContentHandouts = ({ item }: Params): HandoutsManagement => {
  const [isModalOpen, setModal] = useState(false);
  const {
    permissions: { canDisplayClinicalHandouts },
  } = useUserInfo();
  const navigation = useNavigation();

  const handouts = useMemo(
    () => (!item ? [] : isDrug(item) ? item.medicationGuides : item.patientGuides),
    [item],
  );

  const closeModal = useCallback(() => {
    setModal(false);
  }, []);

  const openPrintableHandoutsList = useCallback(() => {
    if (handouts.length === 1) {
      navigation.navigate(isDrugHandout(handouts[0]) ? Route.DrugHandoutItem : Route.ClinicalHandoutItem, {
        id: handouts[0].id,
        source: isDrugHandout(handouts[0]) ? 'drug-page' : 'dx-tx-page',
      });
      return;
    }
    if (isWeb) {
      setModal(true);
    } else if (item) {
      navigation.navigate(Route.PrintableHandoutsList, {
        items: handouts,
      });
    }
  }, [item, handouts, navigation]);

  const renderedModal = useMemo(
    () =>
      isModalOpen && item ? (
        <ModalPortal>
          <PrintableHandoutsModal close={closeModal} items={handouts} />
        </ModalPortal>
      ) : null,
    [isModalOpen, closeModal, handouts, item],
  );

  return {
    shouldDisplayButton: handouts.length > 0 && !(item && isDxTx(item) && !canDisplayClinicalHandouts),
    renderedModal,
    openPrintableHandoutsList,
    numberOfHandouts: handouts.length,
  };
};
