import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { SecondaryButton, LoadingIndicator, StyledText } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { useLicencesInfo } from '../../hooks';

interface Props {}

export const LicencesInfo: React.FC<Props> = () => {
  const { t } = useTranslation('adminDashboard');
  const { isTablet } = useDeviceInfo();
  const licences = useLicencesInfo();
  const {
    permissions: { canManagePlan },
  } = useUserInfo();

  const getLicencesInfoText = () => {
    const { data } = licences;
    if (!data) return '';

    if (data.isMixedGroup) {
      const params = {
        usedBasic: data.licences.basic.total - data.licences.basic.available,
        totalBasic: data.licences.basic.total,
        usedPro: data.licences.pro.total - data.licences.pro.available,
        totalPro: data.licences.pro.total,
      };

      const key =
        params.totalBasic && params.totalPro
          ? 'mixedGroupLicencesInfo'
          : params.totalBasic
          ? 'basicLicencesInfo'
          : params.totalPro
          ? 'proLicencesInfo'
          : '';

      return t(key, params);
    } else {
      const params = {
        used: data.licences.total - data.licences.available,
        total: data.licences.total,
      };

      return t('licencesInfo', params);
    }
  };

  return (
    <>
      {licences.isLoading ? (
        <LoadingIndicator size="small" />
      ) : !licences.data || licences.error ? null : (
        <>
          <StyledText
            style={[styles.licenseInfo, isTablet && styles.licenseInfoTablet]}
            testID="used-licenses-info"
          >
            {getLicencesInfoText()}
          </StyledText>
          {canManagePlan && (
            <Link
              testID="add-more-btn"
              to={{ route: Route.AdminDashboard, params: { tab: 'subscription-details' } }}
              wrapperStyle={[isTablet && styles.addMoreButtonTablet]}
            >
              <SecondaryButton title={t('addMore')} size="small" />
            </Link>
          )}
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  licenseInfo: {
    color: palette.navy,
    marginBottom: 6,
    textAlign: 'right',
    ...typography.body2SemiBold,
  },
  licenseInfoTablet: {
    marginBottom: 0,
  },
  addMoreButtonTablet: {
    marginLeft: 12,
  },
});
