import { LayoutAnimation } from 'react-native';

import { EXPAND_ANIMATION_DURATION } from 'src/constants/constants';

export const toggleAnimation = {
  duration: EXPAND_ANIMATION_DURATION,
  update: {
    duration: EXPAND_ANIMATION_DURATION,
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.opacity,
  },
  delete: {
    duration: EXPAND_ANIMATION_DURATION,
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.opacity,
  },
};
