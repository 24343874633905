import { client } from 'src/api/client';
import { convertObjectToSnakeCase } from 'src/helpers';

import type {
  EditGroupNameResponse,
  EditPracticeLocationResponse,
  FetchGroupMembersResponse,
  FetchPracticeLocationResponse,
  PracticeLocationParams,
  InviteNewMembersResponse,
  CancelInvitationResponse,
  NumberOfLicencesResponse,
  InviteNewMembersParams,
  EditGroupRolePayload,
  ResendInvitationResponse,
  EditGroupRoleResponse,
} from './types';

export const fetchGroupInfo = async () => {
  const response = await client.request<FetchGroupMembersResponse>({
    method: 'GET',
    url: '/groups/members/',
    params: convertObjectToSnakeCase({
      page: 1,
      pageSize: 9999,
    }),
  });

  return response.data;
};

export const editGroupName = async (name: string) => {
  const response = await client.request<EditGroupNameResponse>({
    url: '/groups/edit/',
    method: 'PATCH',
    data: { name },
  });

  return response.data;
};

export const fetchPracticeLocation = async () => {
  const response = await client.request<FetchPracticeLocationResponse>({
    url: '/groups/location/',
    method: 'GET',
  });

  return response.data;
};

export const editPracticeLocation = async (params: PracticeLocationParams) => {
  const response = await client.request<EditPracticeLocationResponse>({
    url: '/groups/location/',
    method: 'PUT',
    data: { ...params },
  });

  return response.data;
};

export const inviteGroupMembers = async (data: InviteNewMembersParams) => {
  const response = await client.request<InviteNewMembersResponse>({
    url: '/groups/invite-members/',
    method: 'POST',
    data,
  });

  return response.data;
};

export const resendGroupInvitation = async (memberId: string) => {
  const response = await client.request<ResendInvitationResponse>({
    url: `/groups/resend-invitation/${memberId}/`,
    method: 'PUT',
  });

  return response.data;
};

export const cancelGroupInvitation = async (memberId: string) => {
  const response = await client.request<CancelInvitationResponse>({
    url: `/groups/cancel-invitation/${memberId}/`,
    method: 'PUT',
  });

  return response.data;
};

export const fetchNumberOfLicences = async () => {
  const response = await client.request<NumberOfLicencesResponse>({
    method: 'GET',
    url: '/groups/licences-number/',
  });

  return response.data;
};

export const editGroupMemberRole = async (params: EditGroupRolePayload) => {
  const { memberId, ...data } = params;
  const response = await client.request<EditGroupRoleResponse>({
    url: `/groups/change-role/${memberId}/`,
    method: 'PUT',
    data,
  });

  return response.data;
};

export const removeGroupMember = async (memberId: string) => {
  await client.request({
    url: `/groups/remove-member/${memberId}/`,
    method: 'PUT',
  });
};
