import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiErrorMessages } from 'src/constants/types';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { useUserInfo } from 'src/features/auth/hooks';
import { MixpanelService, MixpanelEvent, SentryService } from 'src/features/tracking';
import { isWeb, showNotification } from 'src/helpers';

import { getContentNotes, getGroupedNotesList, getSingleNote, removeNote, saveNote } from '../api';
import { CONTENT_NOTES_QUERY_KEY, GROUPED_NOTES_LIST_QUERY_KEY, NOTE_QUERY_KEY } from '../constants';
import { getMixpanelNoteParams } from '../helpers';

export const useGroupedNotesList = () => {
  return useQuery({
    queryKey: [GROUPED_NOTES_LIST_QUERY_KEY],
    queryFn: getGroupedNotesList,
    staleTime: 5 * 60 * 1000,
  });
};

export const useContentNotes = (contentId: string) => {
  const queryClient = useQueryClient();
  const {
    permissions: { canAccessIndividualAccountFeatures },
  } = useUserInfo();

  const { data } = useQuery({
    queryKey: [CONTENT_NOTES_QUERY_KEY, contentId],
    queryFn: async () => {
      const data = await getContentNotes(contentId);

      if (!isWeb) {
        for (const note of Object.values(data)) {
          queryClient.setQueryData([NOTE_QUERY_KEY, note.id], note);
        }
      }

      return data;
    },
    refetchOnMount: true,
    enabled: canAccessIndividualAccountFeatures,
  });

  return data ?? {};
};

export const useNote = (id: string | undefined, { enabled = true }: { enabled?: boolean }) =>
  useQuery({
    queryKey: [NOTE_QUERY_KEY, id],
    queryFn: () => getSingleNote(id!),
    enabled,
  });

export const useSaveNote = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: saveNote,
    onSuccess: (note, params) => {
      const isContentNote = 'contentId' in note;

      if (!isWeb) {
        queryClient.setQueryData([NOTE_QUERY_KEY, note.id], note);
      }

      queryClient.invalidateQueries({ queryKey: [GROUPED_NOTES_LIST_QUERY_KEY] });

      if (isContentNote) {
        queryClient.invalidateQueries({ queryKey: [CONTENT_NOTES_QUERY_KEY, note.contentId] });
      }

      const mixpanelEvent = 'id' in params.note ? MixpanelEvent.NoteEdited : MixpanelEvent.NoteAdded;
      try {
        MixpanelService.track(mixpanelEvent, getMixpanelNoteParams(isContentNote ? note : undefined));
      } catch (e) {
        SentryService.captureException(`Couldn't track ${mixpanelEvent} event`, {
          e,
        });
      }

      if (isWeb) showNotification({ type: 'success' });
    },
    onError: (error: any) => {
      if (isWeb) {
        const { detail } = getMessagesFromErrorResponse<ApiErrorMessages>(error);
        showNotification({ type: 'error', title: detail });
      }
    },
  });

  return mutate;
};

export const useRemoveNote = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: removeNote,
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({ queryKey: [GROUPED_NOTES_LIST_QUERY_KEY] });
      if ('contentId' in params) {
        queryClient.invalidateQueries({ queryKey: [CONTENT_NOTES_QUERY_KEY, params.contentId] });
      }

      if (isWeb) showNotification({ type: 'success' });
    },
    onError: () => {
      if (isWeb) showNotification({ type: 'error' });
    },
  });

  return mutate;
};
