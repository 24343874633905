import { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { LoadingIndicator } from 'src/components';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import type { ScreenProps } from 'src/navigation/types';

import {
  PasswordRecoveryFinalScreen,
  PasswordRecoveryForm,
  LoginFlowTemplate,
  TokenValidationErrorScreen,
} from '../components';
import { usePasswordRecoveryTokenValidation, useSetNewPassword } from '../hooks';
import type { PasswordRecoveryFormData } from '../types';

type Props = ScreenProps<Route.PasswordRecovery>;

export const PasswordRecoveryScreen: React.FC<Props> = (props) => {
  const { token, userId } = props.route.params;
  const { isLoading: isTokenValidationLoading, error: validationErrorType } =
    usePasswordRecoveryTokenValidation(token, userId);
  const {
    mutate: setNewPassword,
    isPending: isSettingNewPassword,
    data: setNewPasswordResponse,
  } = useSetNewPassword();

  const [isTokenExpired, setTokenExpired] = useState<boolean>();

  const onSubmit = async (
    values: PasswordRecoveryFormData,
    formikHelpers: FormikHelpers<PasswordRecoveryFormData>,
  ) => {
    setNewPassword(
      { token, userId, data: values },
      {
        onError: (error) => {
          const { password } = getMessagesFromErrorResponse<PasswordRecoveryFormData>(error);
          if (password) {
            formikHelpers.setErrors({
              password,
            });
          } else {
            setTokenExpired(true);
          }
        },
      },
    );
  };

  if (validationErrorType) {
    return <TokenValidationErrorScreen error={validationErrorType} descriptor="passwordRecovery" />;
  }

  if (setNewPasswordResponse) {
    const { email } = setNewPasswordResponse;
    return <PasswordRecoveryFinalScreen email={email} />;
  }

  if (isTokenExpired) {
    return <PasswordRecoveryFinalScreen error />;
  }

  return (
    <LoginFlowTemplate headerTitle={i18n.t('passwordRecovery:title')}>
      {isTokenValidationLoading ? (
        <LoadingIndicator style={style.loader} />
      ) : (
        <PasswordRecoveryForm onSubmit={onSubmit} loading={isSettingNewPassword} />
      )}
    </LoginFlowTemplate>
  );
};

const style = StyleSheet.create({
  loader: {
    marginVertical: 30,
  },
});
