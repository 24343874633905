import React from 'react';
import { StyleSheet, View } from 'react-native';

import { BoxWithHeader, LoadingIndicator } from 'src/components';
import {
  Item,
  Footer,
  ButtonsWrapper,
  Form,
  Row,
  SubComponents,
  MainSectionProps,
} from 'src/components/Forms';
import { ifWeb } from 'src/styles';

export const PracticeLocation: React.FC<React.PropsWithChildren<MainSectionProps>> & SubComponents = ({
  title,
  isLoading,
  style,
  children,
  testID,
}) => {
  return (
    <View style={[styles.wrapper, styles.zIndexUnset, style]} testID={testID}>
      <BoxWithHeader title={title}>
        {isLoading ? <LoadingIndicator displayStandbyText style={styles.loadingIndicator} /> : children}
      </BoxWithHeader>
    </View>
  );
};

PracticeLocation.Row = Row;
PracticeLocation.Form = Form;
PracticeLocation.Item = Item;
PracticeLocation.Footer = Footer;
PracticeLocation.ButtonsWrapper = ButtonsWrapper;

const styles = StyleSheet.create({
  zIndexUnset: {
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
  wrapper: {
    marginBottom: 40,
  },
  loadingIndicator: {
    height: 200,
    justifyContent: 'center',
  },
});
