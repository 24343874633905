import React, { useEffect } from 'react';

import { FullScreenTemplate, Container } from 'src/components';
import { TabsBar } from 'src/components/TabsBar';
import { useUserInfo } from 'src/features/auth/hooks';
import type { GroupInfoCurrentMember } from 'src/features/auth/types';
import { OlyticsService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { FrozenGroupMessage, MyGroup, PracticeLocationForm, SubscriptionDetails } from '../components';
import { AdminDashboardTab } from '../types';

type Props = ScreenProps<Route.AdminDashboard>;

export const AdminDashboard: React.FC<Props> = ({ navigation, route }) => {
  const { tab: paramsTab } = route.params || {};

  const {
    permissions: { canManageSubscription },
    groupInfo,
  } = useUserInfo();

  const availableTabs = (
    ['my-group', 'practice-location', canManageSubscription && 'subscription-details'] as (
      | AdminDashboardTab
      | false
    )[]
  ).filter<AdminDashboardTab>((item): item is AdminDashboardTab => !!item);

  const activeTab = paramsTab && availableTabs.includes(paramsTab) ? paramsTab : 'my-group';

  useEffect(() => {
    OlyticsService.fire();
  }, []);

  const isTabActiveAndAvailable = (tab: AdminDashboardTab) =>
    activeTab === tab && availableTabs.includes(tab);

  const isGroupFrozen = !!(groupInfo as GroupInfoCurrentMember)?.conversionHash;

  return (
    <FullScreenTemplate
      title={i18n.t('screens:adminDashboard')}
      showBackButton
      stickyContent={
        !isGroupFrozen && (
          <TabsBar
            withShadow
            items={availableTabs.map((tab) => ({
              key: tab,
              text: i18n.t(`adminDashboard:${tab}`),
              isActive: activeTab === tab,
              onPress: () =>
                navigation.navigate({ name: Route.AdminDashboard, params: { tab }, merge: true }),
            }))}
          />
        )
      }
    >
      <Container verticalPadding>
        {isGroupFrozen ? (
          <FrozenGroupMessage conversionId={(groupInfo as GroupInfoCurrentMember).conversionHash!} />
        ) : (
          <>
            {isTabActiveAndAvailable('my-group') && <MyGroup />}
            {isTabActiveAndAvailable('practice-location') && <PracticeLocationForm />}
            {isTabActiveAndAvailable('subscription-details') && <SubscriptionDetails />}
          </>
        )}
      </Container>
    </FullScreenTemplate>
  );
};
