import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, Draft, Reducer } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { showNotification } from 'src/helpers';
import { isTest } from 'src/helpers/common';
import { convertKeysToCamelCase, getJWTPayload } from 'src/helpers/functions';
import { i18n } from 'src/locale';

import {
  signOutSuccess,
  refreshAccessToken,
  setAuthTokens,
  openInactiveSharedAccountWarning,
  closeInactiveSharedAccountWarning,
  openCanadianDisclaimer,
  closeCanadianDisclaimer,
  closeInactiveAccountModal,
  fulfillSignIn,
  fulfillIPLogin,
} from './actions';
import type { TokenMixpanelSuperProperties, User } from '../types';

export const CANADA_CODE = 'CA';

export const emptyUser: User = {
  email: '',
  id: '',
  groupInfo: null,
  country: '',
  expiredSubscription: true,
  isSharedAccount: false,
  isPro: false,
  isStudent: false,
  isTester: false,
  unlimitedSessions: false,
  uuid: '',
  hasRecurlyAccount: false,
  features: [],
  inTrial: false,
  showAllInteractions: false,
  hasAccessToEmailPetOwnerGuides: false,
  createdAt: '',
  planCode: null,
  senderEmail: '',
  omedaCustomerId: null,
};

export interface AuthState {
  isSignedIn: boolean;
  user: User;
  tokens: {
    refresh: string;
    access: string;
  };
  shouldDisplayCanadianDisclaimer: boolean;
  shouldDisplayInactiveAccountModal: boolean;
  shouldDisplayInactiveSharedAccountWarning: boolean;
}

export const initialState: AuthState = {
  isSignedIn: false,
  user: emptyUser,
  tokens: {
    refresh: '',
    access: '',
  },
  shouldDisplayCanadianDisclaimer: false,
  shouldDisplayInactiveAccountModal: false,
  shouldDisplayInactiveSharedAccountWarning: false,
};

const persistConfig: PersistConfig<AuthState> = {
  key: 'auth',
  storage: AsyncStorage,
  whitelist: [
    'isSignedIn',
    'user',
    'tokens',
    'shouldDisplayCanadianDisclaimer',
    'shouldDisplayInactiveAccountModal',
  ],
};

function updateStateOnSignOut(state: Draft<AuthState>) {
  state.isSignedIn = false;
  state.user = emptyUser;
  state.shouldDisplayCanadianDisclaimer = false;
  state.shouldDisplayInactiveAccountModal = false;
  state.tokens = {
    access: '',
    refresh: '',
  };
}

function updateStateOnTokensChange(state: Draft<AuthState>, accessToken: string, refreshToken: string) {
  state.tokens = {
    access: accessToken,
    refresh: refreshToken,
  };

  updateStateFromToken(state, accessToken);
}

function updateStateOnSignIn(state: Draft<AuthState>, accessToken: string, refreshToken: string) {
  updateStateOnTokensChange(state, accessToken, refreshToken);

  if (state.user.country === CANADA_CODE) {
    state.shouldDisplayCanadianDisclaimer = true;
  }
  if (state.user.expiredSubscription) {
    state.shouldDisplayInactiveAccountModal = true;
  }
}

function updateStateFromToken(state: Draft<AuthState>, token: string) {
  const payload = getJWTPayload(token);

  if (!payload) {
    showNotification({ title: i18n.t('auth:invalidToken'), type: 'error' });
    updateStateOnSignOut(state);
    return;
  }

  state.isSignedIn = true;
  state.user.id = payload.userId;
  state.user.email = payload.email;
  state.user.groupInfo = payload.groupInfo;
  state.user.country = payload.country;
  state.user.expiredSubscription = payload.expiredSubscription;
  state.user.isSharedAccount = !!payload.isSharedAccount;
  state.user.unlimitedSessions = isTest ? true : payload.unlimitedSessions;
  state.user.uuid = payload.uuid;
  state.user.isPro = !!payload.isPro;
  state.user.isStudent = !!payload.isStudent;
  state.user.isTester = !!payload.isTester;
  state.user.hasRecurlyAccount = payload.hasRecurlyAccount;
  state.user.features = payload.features ? Object.values(payload.features) : [];
  state.user.inTrial = !!payload.inTrial;
  state.user.institution = payload.institution;
  state.user.showAllInteractions = !!payload.showAllInteractions;
  state.user.planCode = payload.planCode;
  state.user.hasAccessToEmailPetOwnerGuides = !!payload.hasAccessToEmailPetOwnerGuides;
  state.user.createdAt = payload.createdAt;
  state.user.senderEmail = payload.senderEmail;
  state.user.omedaCustomerId = payload.omedaCustomerId;
  state.user.mixpanelProperties =
    payload.mixpanelProperties &&
    (convertKeysToCamelCase(payload.mixpanelProperties) as TokenMixpanelSuperProperties);
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signOutSuccess, (state) => {
        updateStateOnSignOut(state);
      })
      .addCase(setAuthTokens, (state, action) => {
        const { access, refresh } = action.payload;
        updateStateOnTokensChange(state, access, refresh);
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        const { access } = action.payload;
        updateStateFromToken(state, access);
        state.tokens.access = access;
      })
      .addCase(refreshAccessToken.rejected, (state) => {
        updateStateOnSignOut(state);
      })
      .addCase(openInactiveSharedAccountWarning, (state) => {
        state.shouldDisplayInactiveSharedAccountWarning = true;
      })
      .addCase(closeInactiveSharedAccountWarning, (state) => {
        state.shouldDisplayInactiveSharedAccountWarning = false;
      })
      .addCase(openCanadianDisclaimer, (state) => {
        state.shouldDisplayCanadianDisclaimer = true;
      })
      .addCase(closeCanadianDisclaimer, (state) => {
        state.shouldDisplayCanadianDisclaimer = false;
      })
      .addCase(closeInactiveAccountModal, (state) => {
        state.shouldDisplayInactiveAccountModal = false;
      })
      .addCase(fulfillSignIn, (state, action) => {
        const { access, refresh } = action.payload;
        updateStateOnSignIn(state, access, refresh);
      })
      .addCase(fulfillIPLogin, (state, action) => {
        const { access, refresh, ip } = action.payload;
        updateStateOnSignIn(state, access, refresh);
        state.user.ip = ip;
      });
  },
});

export const authReducer = persistReducer(
  persistConfig,
  authSlice.reducer,
) as unknown as Reducer<AuthState>;
