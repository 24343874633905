import { SelectOption, SelectOptionsGroup } from 'src/constants/types';

export function isSelectOptionsGroup(
  option: SelectOption | SelectOptionsGroup,
): option is SelectOptionsGroup {
  return !!(option as SelectOptionsGroup)?.options;
}

export function isRenderProp<T extends Array<any>>(
  prop: React.ReactElement | ((...params: T) => React.ReactElement),
): prop is (...params: T) => React.ReactElement {
  return typeof prop === 'function';
}
