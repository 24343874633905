import React, { useState } from 'react';
import { StyleProp, StyleSheet, TextInput, TextInputProps, ViewStyle } from 'react-native';

import { DEFAULT_MAX_FONT_SIZE_MULTIPLIER } from 'src/constants/constants';
import { fonts, palette, typography } from 'src/styles';

import { InputBase } from './InputBase';

interface Props extends TextInputProps {
  label?: string;
  error?: boolean | string;
  containerStyle?: StyleProp<ViewStyle>;
  height?: number;
}

export const TextArea: React.FC<Props> = ({
  style,
  label,
  rows = 6,
  height = 230,
  error,
  containerStyle,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  const onFocus: Props['onFocus'] = (e) => {
    rest.onFocus?.(e);
    setFocused(true);
  };

  const onBlur: Props['onBlur'] = (e) => {
    rest.onBlur?.(e);
    setFocused(false);
  };

  return (
    <InputBase
      label={label}
      error={error}
      inputContainerStyle={{ height }}
      readOnly={rest.readOnly}
      focused={focused}
      containerStyle={containerStyle}
    >
      <TextInput
        multiline
        rows={rows}
        maxFontSizeMultiplier={DEFAULT_MAX_FONT_SIZE_MULTIPLIER}
        style={[styles.textArea, typography.body2, rest.readOnly && styles.disabledStyle, style]}
        placeholderTextColor={palette.grey5}
        {...rest}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </InputBase>
  );
};

const styles = StyleSheet.create({
  textArea: {
    // padding doesn't properly work on iOS if set differently ¯\_(ツ)_/¯
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 16,
    flex: 1,
    color: palette.grey8,
    verticalAlign: 'top',
    fontFamily: fonts.sourceSans,
  },
  disabledStyle: {
    backgroundColor: palette.grey2,
  },
});
