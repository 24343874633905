import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Container, FullScreenTemplate } from 'src/components';
import { handoutsSelector } from 'src/features/handouts';
import { MixpanelEvent, MixpanelService, MixpanelToolsButton } from 'src/features/tracking';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Route } from 'src/navigation';
import { ScreenProps, MobileNavMenuItemProps } from 'src/navigation/types';

import { MobileNavMenuItem } from '../components';

interface Props extends ScreenProps<Route.Tools> {}

export const ToolsScreen: React.FC<Props> = () => {
  const { t } = useTranslation('tools');
  const { isTabletApp } = useDeviceInfo();
  const { selectedHandouts } = useSelector(handoutsSelector);

  const trackMixpanelEvent = (tabName: MixpanelToolsButton) => {
    MixpanelService.track(MixpanelEvent.ToolsButtonClicked, { 'Tool Name': tabName });
  };

  const items: MobileNavMenuItemProps[] = [
    {
      title: t('selectedHandouts'),
      description: t('selectedHandoutsDescription'),
      route: Route.SelectedHandouts,
      icon: 'documents',
      counter: selectedHandouts.length,
      onPress: () => trackMixpanelEvent('Selected Handouts'),
    },
    {
      title: t('notes'),
      description: t('notesDescription'),
      route: Route.Notes,
      icon: 'note',
      iconWidth: 47,
      onPress: () => trackMixpanelEvent('Notes'),
    },
    {
      title: t('calculator'),
      description: t('calculatorDescription'),
      route: Route.Calculator,
      icon: 'calculator-thin',
      onPress: () => trackMixpanelEvent('Calculator'),
    },
  ];

  return (
    <FullScreenTemplate title={t('header')}>
      <Container verticalPadding={isTabletApp} fullWidthOnMobile>
        {items.map((item) => (
          <MobileNavMenuItem {...item} key={item.title} />
        ))}
      </Container>
    </FullScreenTemplate>
  );
};
