import React from 'react';

import { ConfirmModal } from 'src/components';
import { i18n } from 'src/locale';

import { useCancelGroupInvitation } from '../../hooks';

interface Props {
  close: () => void;
  memberId: string;
}

export const CancelInvitationModal: React.FC<Props> = ({ close, memberId }) => {
  const { mutate: cancelInvitation, isPending } = useCancelGroupInvitation(memberId);

  const handleSubmit = () => {
    cancelInvitation(undefined, {
      onSettled: close,
    });
  };

  return (
    <ConfirmModal
      title={i18n.t('areYouSure')}
      description={i18n.t('adminDashboard:cancelInvitationWarning')}
      cancelMessage={i18n.t('goBack')}
      confirmMessage={i18n.t('adminDashboard:cancelInvitation')}
      cancel={close}
      confirm={handleSubmit}
      testID="cancel-invitation-modal"
      loading={isPending}
    />
  );
};
