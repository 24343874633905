import { isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';

import { ContentListItemCardExtended } from './ContentListItemCardExtended';
import { ContentListItemCardSimplified } from './ContentListItemCardSimplified';

interface Props extends React.ComponentProps<typeof ContentListItemCardExtended> {}

export const ContentListItemCard: React.FC<Props> = (props) => {
  const { isTablet } = useDeviceInfo();

  return isWeb && isTablet ? (
    <ContentListItemCardExtended {...props} />
  ) : (
    <ContentListItemCardSimplified {...props} />
  );
};
