import type { ProfileInformation } from 'src/features/profile';
import { Nullable } from 'src/helpers';

import type { BillingInformation, CreateAccount, PlanDetails } from '../types';

/** Steps that can be used inside a Registration Form */
export enum RegistrationFormStep {
  CreateAnAccount = 'CreateAnAccount',
  CompleteYourProfile = 'CompleteYourProfile',
  ReviewPlanDetails = 'ReviewPlanDetails',
  PaymentDetails = 'PaymentDetails',
}

/** Data handled by each Registration Form Step */
export type RegistrationStepsData = {
  [RegistrationFormStep.CreateAnAccount]: CreateAccount;
  [RegistrationFormStep.CompleteYourProfile]: ProfileInformation;
  [RegistrationFormStep.ReviewPlanDetails]: PlanDetails & { dueToday: number };
  [RegistrationFormStep.PaymentDetails]: BillingInformation;
};

export type RegistrationStepWithData = {
  [K in keyof RegistrationStepsData]: { step: K; data: RegistrationStepsData[K] };
}[keyof RegistrationStepsData];

/** Events emitted by a Registration Form context */
export type RegistrationFormEvent = {
  'step-error': {
    step: RegistrationFormStep;
    errors: Record<string, string>;
  };
  'form-submitted': undefined;
  'step-submitted': {
    step: RegistrationFormStep;
  };
};

export type NullableFormData<S extends readonly RegistrationFormStep[] = []> = Nullable<FormData<S>>;

export type FormData<S extends readonly RegistrationFormStep[] = []> = Pick<
  RegistrationStepsData,
  S[number]
>;

/** Each Registration Form context needs to extends this interface */
export interface RegistrationFormContext<S extends readonly RegistrationFormStep[] = []> {
  /** Returns data submitted by given Step. Can return `null` if Step hasn't been submitted yet */
  getStepData<T extends S[number]>(step: T): RegistrationStepsData[T] | null;
  /** Submits a Step. Data needs to be already validated; won't be checked inside a context */
  submitStep<T extends S[number]>(step: T, data: RegistrationStepsData[T]): void;
  /** Returns a Step following a given Step or `null` if given Step is the last one */
  getNextStep(step: S[number]): S[number] | null;
  onDirtyChange(step: S[number], isDirty: boolean): void;
  /** Indicates if given Step is active, which means that all preceding steps have been submitted */
  isStepActive(step: S[number]): boolean;
  /** Indicates if given Step has been already submitted */
  isStepSubmitted(step: S[number]): boolean;
  /** Submits the form */
  submit(): Promise<void>;
  /** Allows subscribing to event emitter which sends events described with RegistrationFormEvent */
  on<T extends keyof RegistrationFormEvent>(
    type: T,
    callback: (payload: RegistrationFormEvent[T]) => void,
  ): () => void;
  /** Indicates if any Step has unsaved changes */
  isDirty: boolean;
  /** Indicates if all the required Steps has been submitted */
  areAllStepsSubmitted: boolean;
  isSubmitting: boolean;
  formData: NullableFormData<S>;
  email: string;
  steps: S;
}
