import { clamp } from 'ramda';
import { Dimensions, Platform, StatusBar, ViewStyle } from 'react-native';

import { BOTTOM_TAB_NAVIGATION_HEIGHT } from 'src/styles/constants';

import { palette } from './palette';

export const checkIsLandscape = () => Dimensions.get('window').width > Dimensions.get('window').height;

export const isIos = () => Platform.OS === 'ios';

export function ifWeb<T>(webStyle: T, regularStyle?: T) {
  return Platform.OS === 'web' ? webStyle : regularStyle;
}

export const getStatusBarHeight = (): number | undefined =>
  Platform.select({
    ios: 20,
    android: StatusBar.currentHeight,
  });

export const getBottomNavigationHeight = () => BOTTOM_TAB_NAVIGATION_HEIGHT;

export const headerHeight = Platform.select({
  ios: 64,
  default: 56,
});

const percentToHex = (num: number) =>
  Math.round(clamp(0, 255, num) * 255)
    .toString(16)
    .padStart(2, '0');

const hexToRgba = (hex: string): string => {
  if (!/^#([A-Fa-f0-9]{8})$/.test(hex)) {
    throw new Error('Invalid hex format. Expected #RRGGBBAA');
  }

  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 24) & 255;
  const g = (bigint >> 16) & 255;
  const b = (bigint >> 8) & 255;
  const a = bigint & 255;

  const alpha = (a / 255).toFixed(2);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getShadow = (height = 4, shadowIntensity = 0.2, shadowRadius = 24): ViewStyle => {
  const shadowOpacityHex = percentToHex(shadowIntensity * 0.25);
  const shadowColor = palette.shadow + shadowOpacityHex;

  if (Platform.OS === 'web') {
    const colorInRgba = hexToRgba(shadowColor);
    return {
      boxShadow: `${colorInRgba} 0px ${height}px ${shadowRadius}px`,
    };
  }

  return {
    shadowColor,
    shadowOffset: { width: 0, height },
    shadowRadius,
    shadowOpacity: 1,
  };
};

export const getShadowNative = (
  androidSize = 30,
  androidOpacity = 0.15,
  iosSize = 5,
  iosOpacity = 0.15,
): ViewStyle | undefined => {
  if (Platform.OS === 'android') {
    /**
     * Since we're overriding the default shadow behavior on Android 9+ (API 28), `elevation`
     * value can mean different things depending on the version. Additionally, Android versions
     * below 9 don't support changing the color of the shadow. So even though it will look different
     * from the original designs, we hardcode the value to provide at least *some* visual separation
     */
    if (Platform.Version < 28)
      return {
        elevation: 5,
      };

    return {
      elevation: androidSize,
      shadowColor: `${palette.shadow}${percentToHex(androidOpacity)}`,
    };
  } else if (Platform.OS === 'ios') {
    return {
      shadowRadius: iosSize,
      shadowOffset: { height: 0, width: 0 },
      shadowOpacity: iosOpacity,
      shadowColor: palette.shadow,
    };
  }
};
