import { StyleSheet, View } from 'react-native';

import { GradientBar } from 'src/components';
import { getShadow, getShadowNative, palette, typography } from 'src/styles';

import { Pressable } from './Pressable';
import { StyledText } from './StyledText';

interface Item<T extends string> {
  title: string;
  id: T;
  isHidden?: boolean;
  testID?: string;
}

interface Props<T extends string> {
  items: Item<T>[];
  selected?: T;
  disabled?: boolean;
  onPress(id: T): void;
  vertical?: boolean;
}

export const TabsSelector = <T extends string>({
  items,
  selected,
  disabled,
  onPress,
  vertical,
}: Props<T>) => {
  const isSelected = (item: Item<T>) => selected === item.id;
  const filteredItems = items.filter((item) => !item.isHidden);

  return (
    <View style={styles.shadow}>
      <View style={[styles.wrapper, vertical && styles.wrapperVertical]}>
        {filteredItems.map((item, index) => (
          <Pressable disabled={disabled} key={item.id} onPress={() => onPress(item.id)}>
            {(isHovered) => (
              <View
                style={[
                  styles.tab,
                  vertical && styles.tabVertical,
                  index === 0 && [styles.tabFirst, vertical && styles.tabVerticalFirst],
                ]}
                testID={item.testID}
                dataSet={{
                  selected: isSelected(item),
                }}
              >
                {isSelected(item) && (
                  <GradientBar gradientType="proGradient" style={StyleSheet.absoluteFill} />
                )}
                <StyledText
                  style={[
                    styles.text,
                    isSelected(item) && styles.textSelected,
                    disabled && styles.textDisabled,
                    isHovered && !disabled && !isSelected(item) && styles.textHovered,
                  ]}
                >
                  {item.title}
                </StyledText>
              </View>
            )}
          </Pressable>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  // needs to be a separate container, because overflow: hidden removes the shadow on iOS ¯\_(ツ)_/¯
  shadow: {
    backgroundColor: palette.white,
    ...getShadow(0, 0.7, 24),
    ...getShadowNative(30, 0.12, 10, 0.12),
    borderRadius: 5,
  },
  wrapper: {
    flexDirection: 'row',
    width: '100%',
    borderRadius: 5,
    overflow: 'hidden',
  },
  wrapperVertical: {
    flexDirection: 'column',
  },
  tab: {
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    borderLeftWidth: 1,
    borderColor: palette.grey2,
  },
  tabFirst: {
    borderLeftWidth: 0,
  },
  tabVertical: {
    borderLeftWidth: 0,
    borderTopWidth: 1,
  },
  tabVerticalFirst: {
    borderTopWidth: 0,
  },
  text: {
    ...typography.body3Bold,
    color: palette.navy,
  },
  textSelected: {
    color: palette.white,
  },
  textDisabled: {
    color: palette.grey4,
  },
  textHovered: {
    color: palette.blue,
    transitionProperty: 'color',
  },
});
