import { StyleSheet } from 'react-native';

import { StyledText } from 'src/components';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

interface Props {}

export const VerifiedStudentMessage: React.FC<Props> = () => {
  return (
    <StyledText style={[styles.text, typography.body3Bold]}>
      {i18n.t('subscriptionProcess:verifiredStudentMessage')}
    </StyledText>
  );
};

const styles = StyleSheet.create({
  text: {
    color: palette.blue,
    marginBottom: 30,
  },
});
