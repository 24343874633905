import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { fetchPromoboxes, sendPromoboxAction } from '../api';
import { PromoboxesResponse } from '../types';

const PROMOBOXES_QUERY_KEY = ['promoboxes'];

export const usePromoboxUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendPromoboxAction,
    onMutate: (payload) => {
      queryClient.setQueryData<PromoboxesResponse>(PROMOBOXES_QUERY_KEY, (data) =>
        data ? { promoboxes: data.promoboxes.filter((item) => item.slug !== payload.slug) } : undefined,
      );
    },
  });
};

export const usePromoboxes = () => {
  return useQuery({
    queryKey: PROMOBOXES_QUERY_KEY,
    queryFn: fetchPromoboxes,
  });
};
