import { StyleSheet } from 'react-native';

import {
  CONTAINER_WIDTH_MOBILE,
  CONTAINER_WIDTH_TABLET,
  CONTAINER_PADDING_V_MOBILE,
  CONTAINER_PADDING_V_TABLET,
  CONTAINER_PADDING_V_DESKTOP_LARGE,
  CONTAINER_MAX_WIDTH,
} from 'src/styles';

import type { Params } from './useContainer';
import { useDeviceInfo } from './useDeviceInfo';

export const useContainer = (params: Params = {}) => {
  const { verticalPadding, fullWidthOnMobile, maxWidth = 'wide' } = params;
  const { isLargeDesktop, isTablet } = useDeviceInfo();

  const contentWidth = {
    wide: styles.contentWide,
    narrow: styles.contentNarrow,
  }[maxWidth];

  return [
    styles.content,
    contentWidth,
    fullWidthOnMobile && styles.contentFullWidthMobile,
    isTablet && styles.contentTablet,
    verticalPadding && styles.verticalPadding,
    verticalPadding && isLargeDesktop && styles.verticalPaddingDesktop,
  ];
};

const styles = StyleSheet.create({
  content: {
    justifyContent: 'center',
    width: CONTAINER_WIDTH_MOBILE,
    marginHorizontal: 'auto',
  },
  contentWide: {
    maxWidth: CONTAINER_MAX_WIDTH.wide,
  },
  contentNarrow: {
    maxWidth: CONTAINER_MAX_WIDTH.narrow,
  },
  contentFullWidthMobile: {
    width: '100%',
  },
  contentTablet: {
    width: CONTAINER_WIDTH_TABLET,
  },
  verticalPadding: {
    paddingVertical: CONTAINER_PADDING_V_MOBILE,
  },
  verticalPaddingTablet: {
    paddingVertical: CONTAINER_PADDING_V_TABLET,
  },
  verticalPaddingDesktop: {
    paddingVertical: CONTAINER_PADDING_V_DESKTOP_LARGE,
  },
});
