import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import {
  DIAGONISTIC_TREATMENT_INFORMATION_LINK,
  DRUG_INFORMATION_LINK,
  PERSONALIZATION_TOOLS_LINK,
  PET_OWNER_EDUCATION_LINK,
} from 'src/constants/externalLinks';
import { ExtendedDropdownProps, NavigationExtendedDropdown } from 'src/navigation/types';

import { ExtendedDropdown } from './ExtendedDropdown';

interface Props extends ExtendedDropdownProps {}

export const WhyPlumbsExtendedDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation('navigation');

  const data: NavigationExtendedDropdown = {
    content: {
      title: t('powerOfPlumbs'),
      items: [
        {
          title: t('prescribing'),
          description: t('prescribingDescription'),
          external: true,
          to: DRUG_INFORMATION_LINK,
          icon: 'drug-magnifier',
        },
        {
          title: t('caseWorkup'),
          description: t('caseWorkupDescription'),
          external: true,
          to: DIAGONISTIC_TREATMENT_INFORMATION_LINK,
          icon: 'magnify-text',
        },
        {
          title: t('trustedInformation'),
          description: t('trustedInformationDescription'),
          external: true,
          to: PET_OWNER_EDUCATION_LINK,
          icon: 'printer-gradient',
        },
        {
          title: t('clinicalNotebook'),
          description: t('clinicalNotebookDescription'),
          external: true,
          to: PERSONALIZATION_TOOLS_LINK,
          icon: 'text-star',
        },
      ],
    },
  };

  return (
    <ExtendedDropdown
      wrapperStyle={[!props.inMobileDropdown && styles.wrapperDesktop]}
      {...data}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  wrapperDesktop: {
    width: 600,
  },
});
