import React from 'react';
import { StyleSheet } from 'react-native';

import { BaseButton } from 'src/components/Buttons/BaseButton';
import { StyledText } from 'src/components/StyledText';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';

import { LoginFlowTemplate } from './LoginFlowTemplate';
import { TokenValidationError } from '../enums';

export type Descriptor = 'changeEmail' | 'passwordRecovery';
interface Props {
  error: TokenValidationError;
  descriptor: Descriptor;
}

export const TokenValidationErrorScreen: React.FC<Props> = ({ error, descriptor }) => {
  const { isDesktop } = useDeviceInfo();
  const navigation = useNavigation();

  const title = i18n.t(
    error === TokenValidationError.Expired ? `${descriptor}:tokenExpired` : `${descriptor}:tokenNotValid`,
  );

  return (
    <LoginFlowTemplate headerTitle={i18n.t(`${descriptor}:title`)}>
      <StyledText>{title}</StyledText>
      <BaseButton
        title={i18n.t(descriptor === 'passwordRecovery' ? 'common:tryAgain' : 'common:goBack')}
        containerStyle={styles.button}
        fullWidth={!isDesktop}
        onPress={() => navigation.navigate(Route.SignIn, {})}
        variant="gradient"
      />
    </LoginFlowTemplate>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 32,
    alignSelf: 'flex-start',
  },
});
