import { type Location } from 'history';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

import { NavigationBlocker as BaseComponent, NavigationBlockerRef } from './NavigationBlocker';

type Props = React.ComponentProps<typeof BaseComponent>;

export const NavigationBlocker = forwardRef<NavigationBlockerRef, Props>(({ isBlocked, children }, ref) => {
  const [isWarningDisplayed, setWarning] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location>();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();
  const currentLocation = useLocation();

  const confirmNavigation = () => {
    if (lastLocation) {
      setWarning(false);
      setConfirmedNavigation(true);
    }
  };

  const cancelNavigation = () => {
    setWarning(false);
  };

  useImperativeHandle(ref, () => ({
    confirmNavigation,
    cancelNavigation,
  }));

  useEffect(() => {
    if (lastLocation && confirmedNavigation) {
      history.push(lastLocation.pathname + lastLocation.search);
    }
  }, [history, lastLocation, confirmedNavigation]);

  return (
    <>
      <Prompt
        when={isBlocked}
        message={(location) => {
          if (isBlocked && !confirmedNavigation && location.pathname !== currentLocation.pathname) {
            setWarning(true);
            setLastLocation(location);
            return false; // Prevent navigation
          }
          return true;
        }}
      />
      {isWarningDisplayed && children}
    </>
  );
});
