import { useTranslation } from 'react-i18next';

import { InfoBar, InfoModal } from 'src/components';
import { AccountExpirationWarningPortal } from 'src/contexts/portals';
import { i18n } from 'src/locale';

import { useUserNotificationAction } from '../hooks';
import { AccountExpirationWarningNotification } from '../types';

interface Props {
  data: AccountExpirationWarningNotification;
}

export const AccountExpirationNotification: React.FC<Props> = ({
  data: {
    id,
    details: { expirationDate, shownBefore },
  },
}) => {
  const { t } = useTranslation('accountExpiration');

  const { mutate: closeUserNotification } = useUserNotificationAction(id, 'close');

  const close = () => {
    closeUserNotification();
  };

  return shownBefore ? (
    <AccountExpirationWarningPortal>
      <InfoBar close={close} text={t('warningBar', { date: expirationDate })} />
    </AccountExpirationWarningPortal>
  ) : (
    <InfoModal
      icon="clock-exclamation-mark"
      header={t('thisAccountWillExpireOn', { date: expirationDate })}
      close={close}
      testID="modal-expire-on"
    >
      <InfoModal.Content>{t('pleaseReachOut')}</InfoModal.Content>
      <InfoModal.Buttons>
        <InfoModal.Button title={i18n.t('okay')} onPress={close} />
      </InfoModal.Buttons>
    </InfoModal>
  );
};
