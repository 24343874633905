import { combineReducers } from '@reduxjs/toolkit';

import { authReducer } from 'src/features/auth/state';
import { handoutsReducer } from 'src/features/handouts/state';
import { searchReducer } from 'src/features/search/state';
import { promotionReducer } from 'src/features/subscription/state';

const rootReducer = combineReducers({
  auth: authReducer,
  generalSearch: searchReducer,
  handouts: handoutsReducer,
  promotion: promotionReducer,
});

export type ApplicationState = ReturnType<typeof rootReducer>;

export default rootReducer;
