import * as Yup from 'yup';

import { i18n } from 'src/locale';

Yup.setLocale({
  // setting default error messages
  mixed: {
    required: i18n.t('validation:thisFieldIsRequired'),
  },
  string: {
    max: ({ max }) => i18n.t('validation:maxTextLength', { max }),
  },
});

export { Yup };
