import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Pressable } from 'src/components';
import { useSideBarContext } from 'src/navigation/contexts';
import { ifWeb, palette } from 'src/styles';

interface Props {
  isOpen: boolean;
  onPress: () => void;
  accessibilityLabel?: string;
}

export const MenuTogglerButton: React.FC<Props> = ({ isOpen, onPress, accessibilityLabel }) => {
  const { menuTogglerButtonRef } = useSideBarContext();
  return (
    <View ref={menuTogglerButtonRef}>
      <Pressable onPress={onPress} accessibilityLabel={accessibilityLabel}>
        <View style={styles.wrapper}>
          <View style={styles.barsWrapper}>
            <View style={[styles.bar, styles.first, isOpen && [styles.firstOpen, styles.barOpen]]} />
            <View style={[styles.bar, styles.second, isOpen && [styles.secondOpen, styles.barOpen]]} />
            <View style={[styles.bar, styles.third, isOpen && [styles.thirdOpen, styles.barOpen]]} />
          </View>
        </View>
      </Pressable>
    </View>
  );
};
const TRANSITION_TIME = '0.250s';

const styles = StyleSheet.create({
  wrapper: {
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  barsWrapper: {
    width: 22,
    height: 14,
  },
  bar: {
    position: 'absolute',
    left: 0,
    width: 22,
    height: 2,
    backgroundColor: palette.navy,
    opacity: 1,
    transform: [
      {
        rotate: '0deg',
      },
    ],
    ...ifWeb({
      transition: `opacity ${TRANSITION_TIME} ease  ${TRANSITION_TIME}, top ${TRANSITION_TIME} ease  ${TRANSITION_TIME}, transform ${TRANSITION_TIME} ease`,
    }),
  },
  barOpen: {
    left: 0,
    ...ifWeb({
      transition: `opacity ${TRANSITION_TIME} ease, top ${TRANSITION_TIME} ease, transform ${TRANSITION_TIME} ease ${TRANSITION_TIME}`,
    }),
  },
  first: {
    top: 0,
  },
  firstOpen: {
    top: 5,
    transform: [
      {
        rotate: '45deg',
      },
    ],
  },
  second: {
    top: 5,
  },
  secondOpen: {
    opacity: 0,
  },
  third: {
    top: 10,
  },
  thirdOpen: {
    top: 5,
    transform: [
      {
        rotate: '-45deg',
      },
    ],
  },
});
