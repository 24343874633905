import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Icon, MarkdownContent } from 'src/components';
import { palette, typography } from 'src/styles';

import type { DxTxDetails } from '../types';

interface Props {
  item: DxTxDetails;
}

export const DxTxHeaderDescription: React.FC<Props> = React.memo<Props>(({ item }) => {
  if (!item.clarification) return null;

  return (
    <View style={styles.clarificationWrapper}>
      <Icon name="info-circle" color={palette.blue} style={styles.clarificationIcon} width={15} />
      <View style={styles.clarificationTextWrapper}>
        <MarkdownContent style={typography.body2} limited>
          {item.clarification}
        </MarkdownContent>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  clarificationWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  clarificationIcon: {
    marginRight: 10,
  },
  clarificationTextWrapper: {
    flex: 1,
  },
});
