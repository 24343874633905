import { StyleSheet, View } from 'react-native';

import { palette, typography } from 'src/styles';

import { StyledText } from './StyledText';

interface InfoBox extends React.ComponentProps<typeof View> {}

export const InfoBox: React.FC<InfoBox> = ({ style, children, ...rest }) => {
  return (
    <View style={[styles.box, style]} {...rest}>
      <StyledText style={styles.text}>{children}</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  box: {
    backgroundColor: palette.grey1,
    borderRadius: 5,
    padding: 16,
  },
  text: {
    ...typography.body2,
  },
});
