import { Trans } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { StyledText, Switch } from 'src/components';
import {
  ACCEPTABLE_USE_POLICY_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_AND_CONDITIONS_LINK,
} from 'src/constants/externalLinks';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Link } from 'src/navigation/components';
import { palette } from 'src/styles';

interface Props {
  isChecked: boolean;
  onChange(checked: boolean): void;
}

export const TermsAndConditionsCheck: React.FC<Props> = ({ isChecked, onChange }) => {
  const { isTablet } = useDeviceInfo();

  return (
    <View style={[styles.termsLabel, !isTablet && styles.termsLabelSpacing]}>
      <Switch checked={isChecked} onChange={onChange} testID="terms-conditions-switch" />
      <Trans
        i18nKey="subscriptionProcess:termsAgree"
        components={{
          p: <StyledText style={styles.termsText} />,
          terms: <Link to={TERMS_AND_CONDITIONS_LINK} external wrapper="text" style={styles.link} />,
          usePolicy: <Link to={ACCEPTABLE_USE_POLICY_LINK} external wrapper="text" style={styles.link} />,
          privacyPolicy: <Link to={PRIVACY_POLICY_LINK} external wrapper="text" style={styles.link} />,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  link: {
    color: palette.blue,
  },
  termsText: {
    marginLeft: 10,
  },
  termsLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  termsLabelSpacing: {
    marginVertical: 12,
  },
});
