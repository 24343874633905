import { useEffect, useMemo } from 'react';

import { AlgorithmCard } from 'src/features/algorithms/components';
import { AppendixCard } from 'src/features/appendix/components';
import { Contents } from 'src/features/content/config';
import { ContentItemBase, ContentListItemCommonProps } from 'src/features/content/types';
import { DDxCard } from 'src/features/ddx/components/DDxCard';
import { DrugCard } from 'src/features/drugs/components';
import { DxTxCard } from 'src/features/dxTx/components';
import { HandoutCard } from 'src/features/handouts/components';
import { SentryService } from 'src/features/tracking';

const getComponent = (
  item: ContentItemBase,
):
  | React.NamedExoticComponent<ContentListItemCommonProps<any>>
  | React.FC<ContentListItemCommonProps<any>> => {
  switch (Contents.getSimplifiedType(item.contentType)) {
    case 'drug': {
      return DrugCard;
    }
    case 'clinical-handout':
    case 'drug-handout': {
      return HandoutCard;
    }
    case 'appendix': {
      return AppendixCard;
    }
    case 'algorithm': {
      return AlgorithmCard;
    }
    case 'dx-tx': {
      return DxTxCard;
    }
    case 'ddx': {
      return DDxCard;
    }
  }
};

export function CardStrategy<T extends ContentItemBase>(props: ContentListItemCommonProps<T>) {
  const { item } = props;

  const Component = useMemo(() => getComponent(item), [item]);

  useEffect(() => {
    if (!Component) {
      SentryService.captureException("Couldn't find a card comopnent for given content type", {
        providedElement: item,
      });
    }
  }, [Component, item]);

  return Component ? <Component {...props} /> : null;
}
