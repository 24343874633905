import { StyleSheet, View } from 'react-native';
import FlashMessage from 'react-native-flash-message';

import { Notification } from 'src/components/Notification';
import { useIsSignedIn } from 'src/features/auth/hooks';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import { zindex } from 'src/styles';

import { BrandsPanel } from './BrandsPanel';
import { SignedOutPanel } from './SignedOutPanel/SignedOutPanel';
import { UserTopPanel } from './UserTopPanel';

export const TopPanel: React.FC = () => {
  const isSignedIn = useIsSignedIn();
  const { isBrandsPanelDisplayed } = useWebAppLayout();

  return (
    <View style={styles.wrapper}>
      <View style={styles.notificationWrapper}>
        <FlashMessage position="top" MessageComponent={Notification} />
      </View>
      {isBrandsPanelDisplayed && <BrandsPanel />}
      {isSignedIn ? <UserTopPanel /> : <SignedOutPanel />}
    </View>
  );
};

const styles = StyleSheet.create({
  notificationWrapper: {
    zIndex: zindex.notifications,
  },
  wrapper: {
    position: 'sticky',
    top: 0,
    zIndex: zindex.appHeader,
    width: '100%',
  },
});
