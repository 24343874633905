import { Trans, useTranslation } from 'react-i18next';
import { Linking } from 'react-native';

import { queryClient } from 'src/api/queryClient';
import { InfoModal, StyledText } from 'src/components';
import { STUDENTS_SOLUTIONS_LINK } from 'src/constants/externalLinks';
import {
  StudentSubscriptionStatus,
  studentVerificationQueryKeys,
} from 'src/features/studentVerification/constants';
import { formatDateWithMonths } from 'src/helpers';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';
import { typography } from 'src/styles';

import { useUserNotificationAction } from '../hooks';
import { StudentRenewalInfoNeededNotification } from '../types';

interface Props {
  data: StudentRenewalInfoNeededNotification;
}

export const StudentNoInstantSuccessNotification: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('subscriptionProcess');
  const { navigate } = useNavigation();
  const {
    id: notificationId,
    details: { subscriptionExpirationDate },
  } = data;
  const subscriptionExpirationDateFormatted = formatDateWithMonths(subscriptionExpirationDate);

  const { mutate: closeUserNotification } = useUserNotificationAction(notificationId, 'close');
  const { mutate: confirmUserNotification } = useUserNotificationAction(notificationId, 'submit');

  const handleReverify = () => {
    closeUserNotification();
    navigate(Route.StudentReverification);
  };

  const handleGraduated = () => {
    confirmUserNotification(
      { studentStatus: StudentSubscriptionStatus.Graduate },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: studentVerificationQueryKeys.studentSubscriptionEligibility,
          });
        },
      },
    );
    Linking.openURL(STUDENTS_SOLUTIONS_LINK);
  };

  const handleCancelRenewal = () => {
    confirmUserNotification(
      { studentStatus: StudentSubscriptionStatus.RenewalOptOut },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: studentVerificationQueryKeys.studentSubscriptionEligibility,
          });
        },
      },
    );
  };

  return (
    <InfoModal testID="student-no-instant-success-notification">
      <InfoModal.Body
        header={t('studentRenewal.studentRenewalNoInstantSuccessHeader')}
        icon="clock-exclamation-mark"
        iconWidth={60}
      >
        <InfoModal.Content testID="modal-description">
          <Trans
            i18nKey="subscriptionProcess:studentRenewal:studentRenewalNoInstantSuccessDescription"
            components={{
              b: <StyledText style={typography.weightBold} />,
            }}
            values={{ subscriptionExpirationDate: subscriptionExpirationDateFormatted }}
          />
        </InfoModal.Content>
        <InfoModal.Buttons direction="row">
          <InfoModal.Button
            title={t('studentRenewal.reverifyLabel')}
            onPress={handleReverify}
            testID="modal-submit-btn"
          />
        </InfoModal.Buttons>
        <InfoModal.Buttons direction="row">
          <InfoModal.Button
            title={t('studentRenewal.graduatedLabel')}
            onPress={handleGraduated}
            testID="modal-graduated-btn"
            variant="secondary"
          />
          <InfoModal.Button
            title={t('studentRenewal.cancelRenewalLabel')}
            onPress={handleCancelRenewal}
            testID="modal-cancel-btn"
            variant="secondary"
          />
        </InfoModal.Buttons>
      </InfoModal.Body>
      <InfoModal.TextButton
        title={t('studentRenewal.studentRenewalRemind')}
        testID="modal-remind-btn"
        onPress={() => closeUserNotification()}
      />
    </InfoModal>
  );
};
