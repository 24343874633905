import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { DropdownItem } from 'src/constants/types';
import { getShadowNative, ifWeb, palette, typography } from 'src/styles';

import { Pressable } from './Pressable';
import { ProBadge } from './ProBadge';
import { StyledText } from './StyledText';

export interface Props<T> {
  items: DropdownItem<T>[];
  value: string;
  onChange?: (item: DropdownItem<T>) => void;
  style?: StyleProp<ViewStyle>;
}

export const Dropdown = <T,>({ items, value, onChange, style }: Props<T>) => {
  const handleItemPress = (item: DropdownItem<T>) => {
    onChange?.(item);
  };

  const visibleItems = items.filter((item) => !item.isHidden);

  return (
    <View style={[styles.itemsWrapper, style]} testID="filters-list">
      {visibleItems.map((item) => {
        const isActive = item.value === value;
        return (
          <Pressable key={item.label} onPress={() => handleItemPress(item)}>
            {(isHovered, isPressed) => (
              <View style={[styles.item, isActive && styles.itemActive]} testID={item.testID}>
                <StyledText
                  style={[
                    styles.itemText,
                    isActive && styles.itemActiveText,
                    (isHovered || isPressed) && !isActive && styles.itemHoveredText,
                  ]}
                >
                  {item.listLabel ? item.listLabel : item.label}
                </StyledText>
                {item.isPro && (
                  <ProBadge style={styles.badge} variant={isActive ? 'inverted' : 'gradient'} />
                )}
              </View>
            )}
          </Pressable>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  itemsWrapper: {
    backgroundColor: palette.white,
    padding: 4,
    borderRadius: 8,
    alignSelf: 'flex-start',
    ...getShadowNative(),
  },
  item: {
    padding: 8,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemActive: {
    backgroundColor: palette.blue,
  },
  itemText: {
    color: palette.navy,
    ...typography.body3SemiBold,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  itemActiveText: {
    color: palette.white,
  },
  itemHoveredText: {
    color: palette.blue,
  },
  badge: {
    marginLeft: 8,
  },
});
