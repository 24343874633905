import * as R from 'ramda';
import { generatePath } from 'react-router-dom';

import { LinkedRoute, LinkingConfig } from 'src/navigation/types';

import { linkingConfig } from './config/linkingConfig';
import { NotFoundRoute } from './routes';

const pathParamRegex = new RegExp(':[A-Za-z0-9_]+', 'g');

export const routeToPath = (route: LinkedRoute, params?: Record<string, any>) => {
  const path = linkingConfig[route].path;

  const pathParams = path.match(pathParamRegex);

  // generatePath ignores extraneous params
  const pathname = generatePath(path, params);

  if (params) {
    const queryParams = Object.entries(params).filter(
      ([name, value]) => !pathParams?.includes(`:${name}`) && !!value,
    );

    return `${pathname}?${new URLSearchParams(queryParams)}`;
  }

  return pathname;
};

const REDIRECT_TO_KEY = 'redirectTo';

export const setRedirectTo = (route: LinkedRoute, params?: Record<string, any>) => {
  try {
    localStorage.setItem(REDIRECT_TO_KEY, routeToPath(route, params));
  } catch {}
};

export const clearRedirectTo = () => {
  try {
    localStorage.removeItem(REDIRECT_TO_KEY);
  } catch {}
};

export const getRedirectTo = () => {
  try {
    const value = localStorage.getItem(REDIRECT_TO_KEY);
    clearRedirectTo();
    return value;
  } catch {
    return null;
  }
};

export const getLinkingConfigWithNativeRedirects = (linkingConfig: LinkingConfig): LinkingConfig => {
  const routesThatNeedRedirects: Partial<LinkingConfig> = R.pickBy(
    (value) => !!value.alternativePath,
    linkingConfig,
  );
  const routesWithRedirectedPaths = Object.entries(routesThatNeedRedirects).map(([screenName, value]) => [
    screenName,
    { ...value, path: value.alternativePath, originalScreen: screenName },
  ]);
  return Object.fromEntries(routesWithRedirectedPaths);
};

export const getNavigatorConfig = (linkingConfig: LinkingConfig) => ({
  screens: R.pipe(
    // there's no 404 page on mobile, but this prevents errors when a bad url is passed to <Link to="">
    R.assoc(NotFoundRoute, { path: '*' }),
    R.mapObjIndexed(R.pick(['path', 'exact', 'parse'])),
  )(linkingConfig),
});
