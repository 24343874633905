import { createStateSyncMiddleware } from 'redux-state-sync';

import { signOutSuccess } from 'src/features/auth/state';

const whitelistedActions: string[] = [String(signOutSuccess)];

export const getStateSyncMiddleware = () =>
  createStateSyncMiddleware({
    predicate: (action) => typeof action !== 'function' && whitelistedActions.includes(action.type),
    broadcastChannelOption: {
      // uses localStorage fallback (instead of IndexedDB) in browsers without BroadcastChannel support
      webWorkerSupport: false,
    },
  });
