import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAuthorizationHeader } from 'src/api/client';
import { WebSocketCloseCode } from 'src/constants/enums';
import { DevicesAuthMessage } from 'src/constants/types/websocket';
import { refreshAccessToken, signOut } from 'src/features/auth/state';
import { convertKeysToCamelCase } from 'src/helpers/functions';
import { useInterval } from 'src/hooks/useInterval';
import { WebSocketService } from 'src/services';
import { ApplicationState } from 'src/state';
import { AppDispatch } from 'src/state/store';

import { getSystemInfo } from '../helpers';
import { Device, DevicesList } from '../types';

export const useDevicesListFromWebSocket = () => {
  const {
    tokens: { access, refresh },
    user: { uuid },
  } = useSelector((state: ApplicationState) => state.auth);
  const [devicesList, setDevicesList] = useState<null | DevicesList>(null);
  const [isCurrentActive, setIsCurrentActive] = useState(true);
  const isConnected = useRef<boolean>(false);

  const dispatch: AppDispatch = useDispatch();

  useInterval(
    () => {
      WebSocketService.send({ action: 'ping' });
    },
    isConnected.current ? 3 * 60 * 1000 : null,
  );

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const parsed = JSON.parse(event.data);

      if (parsed.message === 'Internal server error') {
        WebSocketService.close();
      }
      if (parsed.action === 'auth' && parsed.status_code === 401) {
        dispatch(refreshAccessToken(refresh));
      }
      if (parsed.action === 'devices_list') {
        const { devices, logged_out: loggedOut } = parsed;
        if (loggedOut.some((el: string) => el === uuid)) {
          dispatch(signOut());
          return;
        }
        const devicesArray = devices.map((device: Device) => convertKeysToCamelCase(device));
        setDevicesList(devicesArray);
      }
      if (parsed.action === 'logout') {
        dispatch(signOut());
      }
    },
    [dispatch, refresh, uuid],
  );

  const handleOpen = useCallback(() => {
    isConnected.current = true;
    WebSocketService.send(getAuthMessage(access));
  }, [access]);

  const handleClose = useCallback((e: CloseEvent) => {
    isConnected.current = false;

    if (e.code === WebSocketCloseCode.Normal) return;

    setTimeout(() => {
      WebSocketService.connect();
    }, 3000);
  }, []);

  useEffect(() => {
    const subscriptions = [
      WebSocketService.subscribe('open', handleOpen),
      WebSocketService.subscribe('message', handleMessage),
      WebSocketService.subscribe('close', handleClose),
    ];

    return () => subscriptions.forEach((sub) => sub.remove());
  }, [handleClose, handleMessage, handleOpen]);

  useEffect(() => {
    if (devicesList) {
      setIsCurrentActive(devicesList.some((el) => el.id === uuid));
    }
  }, [devicesList, uuid]);

  useEffect(() => {
    WebSocketService.connect();
    return () => {
      // Cleanup callbacks are not called when reloading the bundle during development, which unfortunately means
      // that .close() below won't run and there may be multiple connections open until you restart the app.
      // Shouldn't brake anything, but worth noting.
      WebSocketService.close(WebSocketCloseCode.Normal);
    };
  }, []);

  return { devicesList, isCurrentActive };
};

const getAuthMessage = (accessToken: string): DevicesAuthMessage => {
  const { browserName, systemName } = getSystemInfo();
  return {
    action: 'auth',
    token: getAuthorizationHeader(accessToken),
    browserName,
    systemName,
  };
};
