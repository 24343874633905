import React, { useRef, useState, CSSProperties } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';

import { LoadingIndicator, StyledText, Pressable } from 'src/components';
import { handleImageRightClick } from 'src/helpers/functions';
import { i18n } from 'src/locale';
import { palette } from 'src/styles';

interface VideoProps extends React.ComponentProps<typeof View> {
  videoUrl: string;
  shouldShowControls?: boolean;
  autoPlay?: boolean;
  onIconPress?: () => void;
}

export const Video: React.FC<VideoProps> = ({
  videoUrl,
  style,
  shouldShowControls = false,
  onIconPress,
  autoPlay = false,
}) => {
  const [isLoading, toggleIsLoading] = useState<boolean>(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { height: windowHeight } = useWindowDimensions();

  const wrapperMaxHeight = { maxHeight: windowHeight * 0.8 };
  const cssStyles: CSSProperties = {
    visibility: isLoading ? 'hidden' : 'visible',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '100%',
    display: 'block',
  };

  return (
    <Pressable onPress={onIconPress}>
      <View style={[styles.wrapper, wrapperMaxHeight, style]}>
        {isLoading && <LoadingIndicator />}
        {!!onIconPress && <View style={styles.playIcon} />}
        <video
          controls={shouldShowControls}
          controlsList="nodownload"
          style={cssStyles}
          onLoadedMetadata={(_) => toggleIsLoading(false)}
          autoPlay={autoPlay}
          ref={videoRef}
          onContextMenu={handleImageRightClick}
          disablePictureInPicture
        >
          <source src={videoUrl} />
          <StyledText>{i18n.t('dxTx:videoDidNotLoad')}</StyledText>
        </video>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    height: 'auto',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playIcon: {
    borderTopWidth: 20,
    borderRightWidth: 0,
    borderBottomWidth: 20,
    borderLeftWidth: 30,
    borderTopColor: palette.transparent,
    borderRightColor: palette.transparent,
    borderBottomColor: palette.transparent,
    borderLeftColor: palette.white,
    position: 'absolute',
  },
});
