import React from 'react';
import { StyleSheet } from 'react-native';

import { ModalPortal } from 'src/contexts/portals';

import { BaseModal } from './BaseModal';
import { Body, Buttons, Content, ModalButton, TextButton } from './InfoModalSubcomponents';

interface Props extends React.ComponentProps<typeof Body> {
  close?(): void;
  testID?: string;
}

interface Subcomponents {
  Body: typeof Body;
  Buttons: typeof Buttons;
  Button: typeof ModalButton;
  Content: typeof Content;
  TextButton: typeof TextButton;
}

const InfoModal: React.FC<React.PropsWithChildren<Props>> & Subcomponents = ({
  close,
  children,
  testID,
  ...contentProps
}) => {
  return (
    <ModalPortal>
      <BaseModal close={close} style={styles.modal} testID={testID}>
        <Body {...contentProps}>{children}</Body>
      </BaseModal>
    </ModalPortal>
  );
};

InfoModal.Body = Body;
InfoModal.Buttons = Buttons;
InfoModal.Content = Content;
InfoModal.Button = ModalButton;
InfoModal.TextButton = TextButton;

export { InfoModal };

const styles = StyleSheet.create({
  modal: {
    maxWidth: 480,
  },
});
