import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { ViewedSortOrder } from 'src/features/recentlyViewed';
import { recentlyViewedQueryKeys } from 'src/features/recentlyViewed/constants';

export const useContentItemVisit = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: recentlyViewedQueryKeys.list(ViewedSortOrder.RecentlyViewed),
    });
  }, [queryClient]);
};
