import React, { useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FlatList, StyleSheet, View } from 'react-native';

import { AccordionItemParams, AccordionRef } from 'src/constants/types/accordion';
import { NetworkErrorAwareSuspense, QueryErrorBanner } from 'src/errorHandling/components';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { getMixpanelParamsForBackToTopInList } from 'src/helpers';
import { useContainer, useDeviceInfo } from 'src/hooks';
import { useContentLetters } from 'src/hooks/queries/letters';
import { useContentListAccordionEvents } from 'src/hooks/useContentListAccordionEvents';
import { i18n } from 'src/locale';
import { CONTAINER_PADDING_H_MOBILE, DESKTOP_CONTENT_WIDTH } from 'src/styles';

import { AccordionLegacy } from './AccordionLegacy';
import { BottomBar } from './BottomBar';
import { ContentLetterList } from './ContentLetterList';
import { ContentListScreen as ContentListScreenNative } from './ContentListScreen';
import { FullScreenTemplate } from './FullScreenTemplate';
import { LoadingIndicator } from './LoadingIndicator';
import { MobileContentHeader } from './MobileContentHeader';
import { SpecialContentListAccordions } from './SpecialContentListAccordions';

type Props = Omit<React.ComponentProps<typeof ContentListScreenNative>, 'letters'>;

export const ContentListScreen = ({
  title,
  titleInUppercase,
  children,
  contentListType,
  ContentCardComponent,
}: Props) => {
  const specialContentAccordionRef = useRef<AccordionRef>(null);
  const containerStyle = useContainer({ fullWidthOnMobile: true, verticalPadding: true });
  const { isTablet } = useDeviceInfo();

  const afterScrollToTopPress = () => {
    const mixpanelParams = getMixpanelParamsForBackToTopInList(contentListType);
    MixpanelService.track(MixpanelEvent.BackToTopClicked, mixpanelParams);
  };

  return (
    <FullScreenTemplate title={title} titleInUppercase={titleInUppercase} showBackButton>
      <View style={[containerStyle, styles.container]}>
        <ErrorBoundary
          fallbackRender={({ error }) => (
            <QueryErrorBanner error={error} fetchStatus="idle" isDataAvailable={false} />
          )}
        >
          <NetworkErrorAwareSuspense fallback={<LoadingIndicator style={styles.loading} />}>
            <SpecialContentListAccordions
              contentListType={contentListType}
              ContentCardComponent={ContentCardComponent}
              accordionRef={specialContentAccordionRef}
            />
            <MobileContentHeader style={[styles.allContentLabel, isTablet && styles.allContentLabelTablet]}>
              {i18n.t('contents:allContent')}
            </MobileContentHeader>
            <AllContentAccordion
              ContentCardComponent={ContentCardComponent}
              contentListType={contentListType}
            />
            {children}
            <BottomBar showScrollToTop afterScrollToTop={afterScrollToTopPress} />
          </NetworkErrorAwareSuspense>
        </ErrorBoundary>
      </View>
    </FullScreenTemplate>
  );
};

const AllContentAccordion = ({
  contentListType,
  ContentCardComponent,
}: Pick<Props, 'contentListType' | 'ContentCardComponent'>) => {
  const scrollRef = useRef<FlatList>(null);
  const accordionRef = useRef<AccordionRef>(null);
  const { isTablet } = useDeviceInfo();
  const { onItemClose, onItemOpen } = useContentListAccordionEvents(contentListType);

  const { data: letters } = useContentLetters(contentListType);

  if (letters === undefined) {
    return null;
  }

  const accordionItems: AccordionItemParams[] = letters
    .filter(({ hasItems }) => hasItems)
    .map(({ letter }) => ({
      Label: letter.toUpperCase(),
      Content: (
        <ErrorBoundary
          fallbackRender={({ error }) => (
            <QueryErrorBanner error={error} fetchStatus="idle" isDataAvailable={false} />
          )}
        >
          <NetworkErrorAwareSuspense
            fallback={
              <LoadingIndicator style={[styles.loading, styles.loadingInAccordion]} displayStandbyText />
            }
          >
            <ContentLetterList
              contentListType={contentListType}
              letter={letter}
              ContentCardComponent={ContentCardComponent}
            />
          </NetworkErrorAwareSuspense>
        </ErrorBoundary>
      ),
      id: letter,
    }));

  const handleItemOpen = (id: string) => {
    onItemOpen(id.toUpperCase());
  };

  const handleItemClose = (id: string) => {
    onItemClose(id.toUpperCase());
  };

  return (
    <AccordionLegacy
      testID="content-letters-accordion"
      items={accordionItems}
      ref={accordionRef}
      listRef={scrollRef}
      fullWidthMode={!isTablet}
      onItemOpen={handleItemOpen}
      onItemClose={handleItemClose}
    />
  );
};

const styles = StyleSheet.create({
  loading: {
    flex: 1,
  },
  loadingInAccordion: {
    marginVertical: 20,
  },
  container: {
    maxWidth: DESKTOP_CONTENT_WIDTH,
  },
  allContentLabel: {
    marginBottom: 12,
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  allContentLabelTablet: {
    paddingHorizontal: 0,
  },
});
