import { StyleSheet, View } from 'react-native';

import { StyledText } from 'src/components';
import { CardStrategy } from 'src/components/CardStrategy';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { CONTAINER_PADDING_H_MOBILE, palette, typography } from 'src/styles';

import { type Props } from './ViewedContentList';

export const ViewedContentList: React.FC<Props> = ({ content, location }) => {
  const { isTablet } = useDeviceInfo();

  if (content.length === 0) {
    return (
      <StyledText style={[styles.text, isTablet && styles.textTablet]}>
        {i18n.t('recentlyViewed:noItems')}
      </StyledText>
    );
  }

  return (
    <View style={[styles.wrapper, isTablet && styles.wrapperRounded]}>
      {content.map((item, index) => (
        <CardStrategy
          item={item}
          location={location}
          key={item.id}
          bottomBorder={index !== content.length - 1}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    borderColor: palette.grey2,
    borderWidth: 1,
    overflow: 'hidden',
  },
  wrapperRounded: {
    borderRadius: 5,
  },
  text: {
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    ...typography.body2,
  },
  textTablet: {
    paddingHorizontal: 0,
  },
});
