import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { BaseButton, InputItem } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { ApplicationState } from 'src/state';
import { ifWeb } from 'src/styles';

import { EmailChangeForm } from './EmailChangeForm';
import { AccountInformationSection as Section } from '../../AccountInformationSection';

export const EmailChange: React.FC = () => {
  const email = useSelector((state: ApplicationState) => state.auth.user.email);
  const { isTablet } = useDeviceInfo();
  const [editMode, setEditMode] = useState(false);

  return (
    <>
      <Section.Item>
        <InputItem
          label={i18n.t('emailAddress')}
          readOnly
          placeholder={email}
          testID="current-email-input"
        />
      </Section.Item>
      {editMode && <EmailChangeForm close={() => setEditMode(false)} />}
      {!editMode && (
        <BaseButton
          title={i18n.t('accountInformation:changeEmail')}
          onPress={() => setEditMode(true)}
          containerStyle={[styles.button, isTablet && styles.buttonTablet]}
          testID="change-email-btn"
          variant="gradient"
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 10,
    marginBottom: 8,
    ...ifWeb({
      marginBottom: 0,
    }),
  },
  buttonTablet: {
    alignSelf: 'flex-end',
    marginTop: 32,
  },
});
