import React, { useRef } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';

import { Divider } from 'src/components';

import { FindOnPageInput } from './FindOnPageInput';
import FindOnPageInputButton from './FindOnPageInputButton';
import { FindOnPageResult } from './FindOnPageResult';
import { useFindOnPage } from '../hooks/useFindOnPage';

export const FindOnPageContainer: React.FC = () => {
  const findOnPage = useFindOnPage();
  const inputRef = useRef<TextInput>(null);

  if (!findOnPage) return null;

  const { totalFound, searchText, contentTitle, onNextHighlight, onPrevHighlight } = findOnPage;
  const hasSearchText = Boolean(searchText?.length);

  const onClear = () => {
    inputRef.current?.focus();
    inputRef.current?.clear();
    findOnPage?.onResetResults?.();
  };

  return (
    <View style={styles.wrapper}>
      <View style={styles.inputContainer}>
        <FindOnPageInput
          ref={inputRef}
          totalFound={totalFound || 0}
          contentTitle={contentTitle || ''}
          onNextHighlight={onNextHighlight}
          onPrevHighlight={onPrevHighlight}
        />
        <FindOnPageInputButton
          iconName={searchText ? 'close' : 'magnify'}
          disabled={!hasSearchText}
          onPress={onClear}
          testID={searchText ? 'fop-clear-button' : 'fop-submit-button'}
        />
      </View>
      {hasSearchText && (
        <View style={styles.findOnPageResultContainer}>
          <FindOnPageResult />
        </View>
      )}
      <Divider style={styles.divider} />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    paddingLeft: 24,
    position: 'sticky',
    alignSelf: 'flex-start',
  },
  findOnPageResultContainer: { padding: 5, paddingRight: 5, paddingBottom: 0, paddingLeft: 0 },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 0,
    paddingRight: 5,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingTop: 5,
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
});
