import { client } from 'src/api/client';
import { AccordionOpenParams, LettersResponse } from 'src/features/content/types';

export const fetchFirstLetters = async () => {
  const response = await client.request<LettersResponse>({
    method: 'GET',
    url: '/contents/first-letters/',
  });
  return response.data;
};

export const markContentListAsSeen = async (data: AccordionOpenParams) => {
  await client.request({
    method: 'PUT',
    url: '/accounts/user/accordions/',
    data,
  });
};
