import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { showNotification } from 'src/helpers';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks/useNavigation';
import { AppDispatch } from 'src/state/store';

import {
  autologin,
  ipLogin,
  sendResetPasswordEmail,
  setNewPassword,
  signIn,
  validatePasswordRecoveryToken,
} from '../api';
import { TokenValidationError } from '../enums';
import { SavedCredentialsService } from '../services/SavedCredentialsService';
import { fulfillIPLogin, fulfillSignIn, openInactiveSharedAccountWarning } from '../state';
import type { IPLoginResponse, IPLoginErrorResponse } from '../types';

export const useSignIn = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useMutation({
    mutationKey: ['sign-in'],
    mutationFn: signIn,
    onSuccess: (responseData, inputData) => {
      MixpanelService.track(MixpanelEvent.SignIn);
      dispatch(fulfillSignIn(responseData));

      if (inputData.remember) {
        SavedCredentialsService.save(inputData);
      } else {
        SavedCredentialsService.reset();
      }
    },
    onError: (error) => {
      if (error.response?.data.expired) {
        dispatch(openInactiveSharedAccountWarning());
      }
    },
  });
};

export const usePasswordResetEmail = () => {
  return useMutation({ mutationFn: sendResetPasswordEmail });
};

export const usePasswordRecoveryTokenValidation = (token?: string, userId?: string) => {
  return useQuery<boolean | undefined, TokenValidationError>({
    queryKey: ['password-recovery-token', token, userId],
    queryFn: async () => {
      try {
        await validatePasswordRecoveryToken(token!, userId!);
        return true;
      } catch (error: any) {
        if (axios.isAxiosError(error) && error.response) {
          const { status } = error.response;
          if (status === 404) {
            throw TokenValidationError.NotFound;
          } else if (status === 400) {
            throw TokenValidationError.Expired;
          }
        }
        throw TokenValidationError.Default;
      }
    },
    enabled: !!token && !!userId,
  });
};

export const useSetNewPassword = () => {
  return useMutation({ mutationFn: setNewPassword });
};

export const useIPLogin = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigation();

  const loginMutation = useMutation<AxiosResponse<IPLoginResponse>, AxiosError<IPLoginErrorResponse>>({
    mutationFn: ipLogin,

    onSuccess: ({ data }) => {
      dispatch(fulfillIPLogin(data));
    },
    onError: (error) => {
      if (error.response?.data.expired) {
        dispatch(openInactiveSharedAccountWarning());
        navigation.navigate(Route.SignIn, {});
      }
    },
  });

  const errorData = useMemo(() => {
    if (loginMutation.error) {
      return loginMutation.error?.response?.data;
    }
  }, [loginMutation.error]);

  return { ...loginMutation, errorData };
};

export const useAutologin = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useMutation({
    mutationKey: ['autologin'],
    mutationFn: autologin,
    onSuccess: (data) => {
      dispatch(fulfillSignIn(data));
    },
    onError: (error) => {
      const { nonFieldErrors } = getMessagesFromErrorResponse<{ nonFieldErrors?: string }>(error);
      showNotification({ type: 'error', title: nonFieldErrors });
    },
  });
};
