import { USER_OTHER_VALUE } from 'src/constants/constants';
import { SelectOptions } from 'src/constants/types';
import { i18n } from 'src/locale';

import universitiesData from './universities.json';
import type { ActivityValue, NonVeterinarianRoleValue, VeterinarianRoleValue } from '../../types';

export const activity: { value: ActivityValue; label: string }[] = (function () {
  const fields: ActivityValue[] = [
    'exclusive-small-animal-practice',
    'over-50-small-animal-practice',
    'mixed-animal-practice',
    'exclusive-feline-practice',
    'exclusive-large-animal-practice',
    'exclusive-equine-practice',
    'government',
    'academic-teaching-library-research',
    'student-veterinary-medical',
    'student-veterinary-technician',
    'student-pharmacy',
    'pharmacy',
    USER_OTHER_VALUE,
  ];

  return fields.map((value) => ({
    label: i18n.t(`user:activity:${value}`),
    value,
  }));
})();

export const pharmacy: SelectOptions = (function () {
  const fields = [
    'retail',
    'specialty-compounding',
    'academic-teaching-library-research',
    'veterinary-hospital',
    'government',
    USER_OTHER_VALUE,
  ] as const;

  return fields.map((value) => ({
    label: i18n.t(`user:pharmacy:${value}`),
    value,
  }));
})();

/** Years from current to current + 21 */
export const graduationYear: SelectOptions = (function () {
  const currentYear = new Date().getFullYear();
  const allowedYears = 21;
  return Array.from(Array(allowedYears)).map((_, i) => ({
    label: (currentYear + i).toString(),
    value: (currentYear + i).toString(),
  }));
})();

export const yearsOfPractice: SelectOptions = [
  '0-4',
  '5-10',
  '11-15',
  '16-20',
  '20-and-more',
  'not-applicable',
].map((value) => ({ value, label: i18n.t(`user:yearsOfPractice:${value}`) }));

export const role: SelectOptions = (function () {
  const veterinarian: VeterinarianRoleValue[] = [
    'owner-partner',
    'multiple-practice-owner',
    'associate',
    'other-veterinarian',
  ];
  const nonVeterinarian: NonVeterinarianRoleValue[] = [
    'certified-veterinary-technician-veterinary-nurse',
    'non-certified-veterinary-technician-veterinary-nurse',
    'practice-manager-office-manager',
    'veterinary-assistant',
    'receptionist',
    'clinic-owner',
    'professor',
    'other-non-veterinarian',
  ];

  return [
    {
      label: i18n.t('user:veterinarian'),
      options: veterinarian.map((value) => ({
        label: i18n.t(`user:role:${value}`),
        value,
      })),
    },
    {
      label: i18n.t('user:nonVeterinarian'),
      options: nonVeterinarian.map((value) => ({
        label: i18n.t(`user:role:${value}`),
        value,
      })),
    },
  ];
})();

export const numberOfVeterinarians: SelectOptions = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10-and-more',
  'not-applicable',
].map((value) => ({ value, label: i18n.t(`user:numberOfVeterinarians:${value}`) }));

export const practiceType: SelectOptions = [
  'general-practice',
  'emergency-practice',
  'specialty-practice',
  'teaching-hospital',
  'mobile-practice',
  'shelter',
  'other',
].map((value) => ({ value, label: i18n.t(`user:practiceType:${value}`) }));

export const schools: SelectOptions = [
  ...universitiesData.map((item) => ({
    value: item.id,
    label: item.name,
  })),
  {
    value: USER_OTHER_VALUE,
    label: i18n.t('user:other'),
  },
];

export const practiceOwnership: SelectOptions = [
  'private-practice',
  'corporate-practice',
  'not-applicable',
].map((value) => ({
  label: i18n.t(`user:practiceOwnership:${value}`),
  value,
}));
