import { StyleSheet, View } from 'react-native';

import { MarkdownContent } from 'src/components';
import { palette, typography } from 'src/styles';

interface Props {
  noteContent: string;
}

export const NoteCardPreview: React.FC<Props> = ({ noteContent }) => (
  <View style={styles.wrapper} testID="note-preview">
    <MarkdownContent style={styles.noteText} limited>
      {noteContent}
    </MarkdownContent>
  </View>
);

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderColor: palette.grey2,
    borderRadius: 5,
    padding: 16,
  },
  noteText: {
    ...typography.body2,
  },
});
