import React, { Suspense } from 'react';

import { LoadingIndicator } from 'src/components';
import { SubscriptionDetails as SubscriptionDetailsContent } from 'src/features/subscription/components/SubscriptionDetails';

export const SubscriptionDetails: React.FC = () => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <SubscriptionDetailsContent />
    </Suspense>
  );
};
