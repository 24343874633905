import { type InputLabelProps } from './InputLabel';
import { StyledText } from './StyledText';

export const InputLabel: React.FC<InputLabelProps> = ({ htmlFor, ...rest }) => {
  return (
    <label htmlFor={htmlFor}>
      <StyledText {...rest} />
    </label>
  );
};
