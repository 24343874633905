/**
 * Try to always use following typography in your components.
 *
 * fontFamily is omitted in most of the styles here,
 * since the default is already set in <StyledText>.
 */
import { StyleSheet } from 'react-native';

import { fonts } from './fonts';
import { ifWeb } from './helpers';

const baseValues = {
  size1: 14,
  size2: 16,
  size3: 18,
  size4: 20,
  size5: 22,
  size6: 24,
  size7: 26,
  size8: 28,
  size9: 30,
  size10: 32,
} as const;

// *short* variant means lower value of lineHeight than non-short variant.
export const typography = StyleSheet.create({
  weightExtraLight: {
    fontWeight: '200',
  },
  weightLight: {
    fontWeight: '300',
  },
  weightRegular: {
    fontWeight: '400',
  },
  weightSemiBold: {
    fontWeight: '600',
  },
  weightBold: {
    fontWeight: 'bold',
  },
  text1: {
    fontSize: baseValues.size1,
  },
  text2: {
    fontSize: baseValues.size2,
  },
  text3: {
    fontSize: baseValues.size3,
  },
  text4: {
    fontSize: baseValues.size4,
  },
  text5: {
    fontSize: baseValues.size5,
  },
  text6: {
    fontSize: baseValues.size6,
  },
  text7: {
    fontSize: baseValues.size7,
  },
  text8: {
    fontSize: baseValues.size8,
  },
  text9: {
    fontSize: baseValues.size9,
  },
  text10: {
    fontSize: baseValues.size10,
  },
  body1: {
    fontSize: baseValues.size1,
    lineHeight: 21,
    fontFamily: fonts.sourceSans,
  },
  body1SemiBold: {
    fontSize: baseValues.size1,
    lineHeight: 21,
    fontWeight: '600',
    fontFamily: fonts.sourceSans,
  },
  body1Bold: {
    fontSize: baseValues.size1,
    lineHeight: 21,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
  },
  body1Short: {
    fontSize: baseValues.size1,
    lineHeight: 16,
    fontFamily: fonts.sourceSans,
  },
  body1ShortSemiBold: {
    fontSize: baseValues.size1,
    lineHeight: 16,
    fontWeight: '600',
    fontFamily: fonts.sourceSans,
  },
  body1ShortBold: {
    fontSize: baseValues.size1,
    lineHeight: 16,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
  },
  body2: {
    fontSize: baseValues.size2,
    lineHeight: 24,
    fontFamily: fonts.sourceSans,
  },
  body2SemiBold: {
    fontSize: baseValues.size2,
    lineHeight: 24,
    fontWeight: '600',
    fontFamily: fonts.sourceSans,
  },
  body2Bold: {
    fontSize: baseValues.size2,
    lineHeight: 24,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
  },
  body2Short: {
    fontSize: baseValues.size2,
    lineHeight: 16,
    fontFamily: fonts.sourceSans,
  },
  body2ShortSemiBold: {
    fontSize: baseValues.size2,
    lineHeight: 16,
    fontWeight: '600',
    fontFamily: fonts.sourceSans,
  },
  body2ShortBold: {
    fontSize: baseValues.size2,
    lineHeight: 16,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
  },
  body2Light: {
    fontSize: baseValues.size2,
    fontFamily: fonts.sourceSans,
    lineHeight: 24,
    fontWeight: '300',
  },
  body3: {
    fontSize: baseValues.size3,
    lineHeight: 25,
    fontFamily: fonts.sourceSans,
  },
  body3SemiBold: {
    fontSize: baseValues.size3,
    lineHeight: 25,
    fontWeight: '600',
    fontFamily: fonts.sourceSans,
  },
  body3Bold: {
    fontSize: baseValues.size3,
    lineHeight: 25,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
  },
  body4: {
    fontSize: baseValues.size4,
    lineHeight: 24,
    fontFamily: fonts.sourceSans,
  },
  body4SemiBold: {
    fontSize: baseValues.size4,
    lineHeight: 24,
    fontFamily: fonts.sourceSans,
    fontWeight: '600',
  },
  body4Bold: {
    fontSize: baseValues.size4,
    lineHeight: 24,
    fontFamily: fonts.sourceSans,
    fontWeight: 'bold',
  },
  body5: {
    fontSize: baseValues.size5,
    lineHeight: 28,
    fontFamily: fonts.sourceSans,
  },
  body5SemiBold: {
    fontSize: baseValues.size5,
    lineHeight: 28,
    fontWeight: '600',
    fontFamily: fonts.sourceSans,
  },
  body5Bold: {
    fontSize: baseValues.size5,
    lineHeight: 28,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
  },
  alt4Short: {
    fontSize: baseValues.size4,
    lineHeight: 20,
    fontFamily: fonts.din,
  },
  body6: {
    fontSize: baseValues.size6,
    lineHeight: 36,
    fontFamily: fonts.sourceSans,
  },
  body6SemiBold: {
    fontSize: baseValues.size6,
    lineHeight: 36,
    fontWeight: '600',
    fontFamily: fonts.sourceSans,
  },
  body6Bold: {
    fontSize: baseValues.size6,
    lineHeight: 36,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
  },
  body6ExtraLight: {
    fontSize: baseValues.size6,
    fontFamily: fonts.sourceSans,
    fontWeight: '200',
  },
  heading1: {
    fontSize: 16,
    lineHeight: 19,
    fontWeight: 'bold',
  },
  heading2: {
    fontSize: 18,
    lineHeight: 22,
    fontWeight: 'bold',
  },
  heading3: {
    fontSize: 24,
    lineHeight: 29,
    fontWeight: 'bold',
  },
  alt6: {
    fontSize: baseValues.size6,
    lineHeight: 36,
    fontFamily: fonts.din,
  },
  alt6Bold: {
    fontSize: baseValues.size6,
    lineHeight: 36,
    fontWeight: 'bold',
    fontFamily: fonts.din,
  },
  body7: {
    fontSize: baseValues.size7,
    lineHeight: 36,
    fontFamily: fonts.sourceSans,
  },
  body7SemiBold: {
    fontSize: baseValues.size7,
    lineHeight: 36,
    fontWeight: '600',
    fontFamily: fonts.sourceSans,
  },
  body7Bold: {
    fontSize: baseValues.size7,
    lineHeight: baseValues.size7 * 1.2,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
  },
  alt7: {
    fontSize: baseValues.size7,
    lineHeight: 36,
    fontFamily: fonts.din,
  },
  alt7Bold: {
    fontSize: baseValues.size7,
    lineHeight: 36,
    fontWeight: 'bold',
    fontFamily: fonts.din,
  },
  header1Bold: {
    fontSize: 32,
    lineHeight: 44,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
  },
  header2: {
    fontSize: 52,
    lineHeight: 60,
    fontFamily: fonts.sourceSans,
  },
  primaryButton: {
    fontSize: baseValues.size2,
    fontWeight: 'bold',
    fontFamily: fonts.sourceSans,
    ...ifWeb(
      {},
      {
        textTransform: 'uppercase',
      },
    ),
  },
});
