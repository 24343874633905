import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';

import { LoadingIndicator, StyledText } from 'src/components';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useRoute } from 'src/navigation/hooks';
import { typography } from 'src/styles';

import { LoginFlowTemplate } from './LoginFlowTemplate';
import { useIPLogin } from '../hooks';

export const IPLogin: React.FC = () => {
  const route = useRoute();

  const { mutate: login, errorData: error, isPending } = useIPLogin();

  useEffect(() => {
    login();
  }, [login]);

  return (
    <LoginFlowTemplate headerTitle={error?.ip ? i18n.t('proxyLoginErrors:header') : ''}>
      {isPending ? (
        <LoadingIndicator />
      ) : error ? (
        <StyledText style={typography.body3}>
          <Trans
            i18nKey={
              error.ip
                ? `proxyLoginErrors:disclaimer${route.name === Route.Auth ? 'Corporate' : 'University'}`
                : error.ip === null
                ? 'proxyLoginErrors:couldntDetectIP'
                : 'notifications:error'
            }
            components={{
              p: <StyledText style={typography.body3} />,
            }}
            values={{ ip: error.ip }}
          />
        </StyledText>
      ) : null}
    </LoginFlowTemplate>
  );
};
