import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useUserInfo } from 'src/features/auth/hooks';
import { ContentStatus } from 'src/features/content/constants';
import { ContentCardLocation } from 'src/features/content/types';
import { useFavorites } from 'src/features/favorites';
import { getMixpanelFavoritesLocation } from 'src/features/tracking';
import { usePaywallInContentCard } from 'src/hooks';
import { Link } from 'src/navigation/components';
import { ifWeb, palette, typography } from 'src/styles';

import { ContentListItemCardSimplified } from './ContentListItemCardSimplified';
import { QuickLink, QuickLinks } from './QuickLinks';
import { ContentTypeBadge } from '../ContentTypeBadge';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton';
import { MarkdownContent } from '../MarkdownContent';
import { Pressable } from '../Pressable';

interface Props
  extends React.ComponentProps<typeof ContentListItemCardSimplified>,
    React.PropsWithChildren {
  description?: string;
  id: string;
  quickLinks?: QuickLink[];
  location: ContentCardLocation;
}

export const ContentListItemCardExtended: React.FC<Props> = ({
  title,
  description,
  id,
  linkProps,
  onPress,
  contentType,
  inDevelopment,
  quickLinks,
  topBorder,
  bottomBorder,
  sideBorder,
  roundedTopBorder,
  roundedBottomBorder,
  children,
  location,
  isNew,
}) => {
  const [isHovered, setHover] = useState(false);

  const { isFavorite, toggleFavorite } = useFavorites({
    id: id,
    location: getMixpanelFavoritesLocation(location),
    title: title,
  });
  const {
    permissions: { canAccessIndividualAccountFeatures },
  } = useUserInfo();
  const { isContentBlocked } = usePaywallInContentCard({
    contentType,
  });

  const linkOverlayComponent = linkProps ? (
    <Link
      {...linkProps}
      style={styles.linkOverlay}
      wrapperStyle={styles.linkOverlayWrapper}
      onHover={() => setHover(true)}
      onHoverOut={() => setHover(false)}
      accessibilityLabel={title}
    >
      <></>
    </Link>
  ) : onPress ? (
    <Pressable onPress={onPress}>
      <View style={styles.linkOverlayWrapper} />
    </Pressable>
  ) : null;

  return (
    <View
      style={[
        styles.wrapper,
        bottomBorder && styles.wrapperBottomBorder,
        topBorder && styles.wrapperTopBorder,
        sideBorder && styles.wrapperSideBorder,
        roundedTopBorder && styles.roundedTopBorder,
        roundedBottomBorder && styles.roundedBottomBorder,
      ]}
      testID="accordion-item"
    >
      <View style={styles.leftColumn}>
        <View style={styles.headerWrapper}>
          {isNew && <View style={styles.newContentIndicator} testID="new-item-indicator" />}
          <MarkdownContent
            style={[
              styles.title,
              inDevelopment && styles.titleInDevelopment,
              isHovered && !inDevelopment && styles.titleHover,
            ]}
            singleLine
            inline
            limited
            testID="accordion-item-name"
            headerLevel={3}
          >
            {title}
          </MarkdownContent>
          <ContentTypeBadge
            contentType={contentType}
            statusType={inDevelopment ? ContentStatus.InDevelopment : undefined}
            showBoth
            contentListSpacing
          />
        </View>
        {!!description && (
          <MarkdownContent style={styles.description} inline limited headerLevel={4}>
            {description}
          </MarkdownContent>
        )}
        {!!quickLinks?.length && <QuickLinks quickLinks={quickLinks} wrapperStyle={styles.quickLinks} />}
        {children}
      </View>
      {!inDevelopment && !isContentBlocked && (
        <View style={styles.buttonsWrapper}>
          {canAccessIndividualAccountFeatures && (
            <IconButton
              name={isFavorite ? 'star' : 'star-outline'}
              onPress={toggleFavorite}
              style={styles.favoriteButton}
              containerStyle={styles.favoriteButtonContainer}
              testID={(isFavorite ? 'remove-from' : 'add-to') + '-favorites-btn'}
              accessibilityLabel="Favorite"
            />
          )}
          <Icon name="chevron-right" color={palette.navy} width={10} />
        </View>
      )}
      {linkOverlayComponent}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: palette.white,
    borderColor: palette.grey2,
    padding: 16,
    zIndex: 'auto',
  },
  wrapperSideBorder: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  roundedTopBorder: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  roundedBottomBorder: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  wrapperBottomBorder: {
    borderBottomWidth: 1,
  },
  wrapperTopBorder: {
    borderTopWidth: 1,
  },
  leftColumn: {
    flex: 1,
    zIndex: 'auto',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 'auto',
  },
  newContentIndicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: palette.blue,
    marginRight: 8,
  },
  title: {
    color: palette.navy,
    marginRight: 8,
    ...typography.body3SemiBold,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  titleInDevelopment: {
    color: palette.grey4,
  },
  titleHover: {
    color: palette.blue,
  },
  description: {
    ...typography.body2,
    color: palette.navy,
    marginTop: 8,
  },
  quickLinks: {
    marginTop: 12,
  },
  favoriteButtonContainer: {
    marginRight: 16,
    zIndex: 'auto',
  },
  favoriteButton: {
    zIndex: 1,
  },
  buttonsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 'auto',
    marginLeft: 16,
  },
  linkOverlay: {
    width: '100%',
    height: '100%',
  },
  linkOverlayWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
});
