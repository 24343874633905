import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from 'src/api/queryClient';

import { fetchAppcuesLaunchpadFlows, updateUserSeenFlows } from '../api';
import { APPCUES_LAUNCHPAD_QUERY_KEY } from '../constants';

export const useAppcuesLaunchpad = () => {
  return useQuery({
    queryKey: [APPCUES_LAUNCHPAD_QUERY_KEY],
    queryFn: async () => {
      const data = await fetchAppcuesLaunchpadFlows();
      return data.launchpadList;
    },
  });
};

export const useUpdateUserSeenFlows = () => {
  return useMutation({
    mutationFn: (flowId: string) => updateUserSeenFlows(flowId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [APPCUES_LAUNCHPAD_QUERY_KEY] });
    },
  });
};
