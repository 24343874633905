import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { FeatureButton } from 'src/components/FeatureButton';
import { isWeb } from 'src/helpers';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks/useNavigation';

import { NoteCardEditor } from './NoteCardEditor';
import { NoteCardPreview } from './NoteCardPreview';
import { isExistingNoteProps, isNewGeneralNotePlaceholder } from '../../helpers';
import { ExisitingNoteCardProps, NewNoteCardProps } from '../../types';

interface CommonProps {
  style?: StyleProp<ViewStyle>;
}
interface ExisitingNoteProps extends CommonProps, ExisitingNoteCardProps {}

interface NewNoteProps extends CommonProps, NewNoteCardProps {}

export function NoteCard(props: ExisitingNoteProps | NewNoteProps) {
  const { style } = props;
  const isExistingNote = isExistingNoteProps(props);

  const [isInEditMode, setEditMode] = useState(isExistingNote && isNewGeneralNotePlaceholder(props.note));
  const { navigate } = useNavigation();
  const { t } = useTranslation('notes');

  const handleEditButtonPress = () => {
    if (isWeb) {
      setEditMode(true);
    } else {
      if (isExistingNote) {
        navigate(Route.Note, { id: props.note.id });
      } else {
        navigate(Route.Note, {
          ...props.contentData,
          section: props.contentData.sectionId,
        });
      }
    }
  };

  const isPreviewDisplayed = isExistingNote && !isInEditMode;

  return (
    <FeatureButton
      icon={isExistingNote ? 'pencil' : 'plus'}
      iconWidth={isExistingNote ? 16 : undefined}
      text={isExistingNote ? t('editNote') : t('addNote')}
      onPress={handleEditButtonPress}
      testID="notes-editor-wrapper"
      style={[(isPreviewDisplayed || isInEditMode) && styles.featureButtonWithContent, style]}
    >
      {isPreviewDisplayed && <NoteCardPreview noteContent={props.note.note} key={props.note.updatedAt} />}
      {isInEditMode && (
        <NoteCardEditor
          close={() => {
            setEditMode(false);
          }}
          {...props}
        />
      )}
    </FeatureButton>
  );
}

const styles = StyleSheet.create({
  featureButtonWithContent: {
    paddingBottom: 16,
  },
});
