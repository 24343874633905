import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { StyledText, Li, FileInput, PrimaryButton } from 'src/components';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { STUDENT_DOC_LIMIT_EXCEEDED_CODE } from 'src/features/subscription/constants';
import { closeNotification, showNotification } from 'src/helpers';
import { palette, typography } from 'src/styles';

import { DocumentsVerificationWindow } from './DocumentsVerificationWindow';
import { useStudentVerificationContext } from '../context/StudentVerificationContext';
import { useStudentDocumentsUpload } from '../hooks/queries';
import { useStudentVerificationWebsocket } from '../hooks/useStudentVerificationWebsocket';
import type { StudentDocumentsErrorMessages } from '../types';

interface Props {}

export const StudentDocumentsForm: React.FC<Props> = () => {
  const { t } = useTranslation('subscriptionProcess');

  const {
    onDocumentsLimitReached,
    onDocumentsUploadSuccess,
    onDocumentsUpload,
    onDocumentsUploadFailure,
    verificationId,
    currentStep,
    processType,
  } = useStudentVerificationContext();
  const [files, setFiles] = useState<FileList | null>(null);
  const { mutate: uploadStudentDocuments, isPending } = useStudentDocumentsUpload();
  const displayPendingWindowAsModal = processType !== 'reverification';
  const isPendingStatus = currentStep === 'pending';

  useStudentVerificationWebsocket({
    verificationId: verificationId!,
    onSuccessMessage: onDocumentsUploadSuccess,
    onFailureMessage: () => {
      showNotification({
        type: 'error',
        title: t('documentsError'),
        autoHide: false,
      });
      onDocumentsUploadFailure();
    },
  });

  const handleSubmit = async () => {
    if (files) {
      uploadStudentDocuments(
        { files, verificationId: verificationId! },
        {
          onSuccess() {
            closeNotification();
            onDocumentsUpload();
          },
          onError(err: any) {
            if (err?.response?.data?.detail?.code === STUDENT_DOC_LIMIT_EXCEEDED_CODE) {
              // user has sent too many docuemnts
              showNotification({
                type: 'error',
                title: t('documentsLimitReachedMessage'),
                autoHide: false,
              });
              onDocumentsLimitReached();
            } else {
              const { detail, documents } =
                getMessagesFromErrorResponse<StudentDocumentsErrorMessages>(err);

              showNotification({ type: 'error', title: documents || detail, autoHide: false });
            }
          },
        },
      );
    }
  };

  const isSubmitDisabled = !files || isPending;

  return (
    <>
      {isPendingStatus && <DocumentsVerificationWindow asModal={displayPendingWindowAsModal} />}
      {(!isPendingStatus || displayPendingWindowAsModal) && (
        <>
          <StyledText style={styles.title}>{t('documentsUploadHeader')}</StyledText>
          <StyledText style={styles.description}>{t('documentsUploadSubheader')}</StyledText>

          <Trans
            i18nKey="subscriptionProcess:documentsUploadDescription"
            components={{
              p: <StyledText style={styles.header} />,
              li: <Li />,
            }}
          />
          <StyledText style={styles.fileInfo}>{t('documentsUploadFilesRequirements')}</StyledText>
          <FileInput
            id="student-file"
            label={t('selectFiles', { ns: 'common' })}
            onChange={setFiles}
            multiple
            accept=".jpg,.jpeg,.png,.pdf,.gif,.bmp"
            testID="student-file-input"
          />

          <PrimaryButton
            title={t('continue', { ns: 'common' })}
            disabled={isSubmitDisabled}
            containerStyle={styles.submitButton}
            onPress={handleSubmit}
            loading={isPending}
            testID="continue-button"
          />
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    ...typography.body3Bold,
    color: palette.blue,
    marginBottom: 8,
  },
  description: {
    ...typography.body2,
    marginBottom: 8,
  },
  header: {
    ...typography.body2SemiBold,
    marginBottom: 8,
  },
  submitButton: {
    alignSelf: 'flex-end',
    marginTop: 20,
  },
  fileInfo: {
    ...typography.body1,
    color: palette.grey5,
  },
});
