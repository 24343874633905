import React, { useRef, useState } from 'react';
import { View, StyleSheet, Platform, ViewStyle, TextStyle } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import { StyledText, Icon, Pressable } from 'src/components';
import { useOutsideClickDetector } from 'src/hooks';
import { Link } from 'src/navigation/components';
import { NavigationMenuItem } from 'src/navigation/types';
import { fonts, ifWeb, NAVBAR_HEIGHT, palette } from 'src/styles';

import { NavigationDropdownWrapper } from './NavigationDropdownWrapper';

interface Props extends NavigationMenuItem {
  highlightOnHover?: boolean;
  isLastItem?: boolean;
  testID?: string;
}

export const MenuDesktopItem: React.FC<Props> = ({
  text,
  to,
  external,
  disabled,
  highlightOnHover,
  ExtendedDropdownComponent,
  testID,
}) => {
  const [isSubmenuOpen, setOpened] = useState<boolean>(false);
  const itemRef = useRef(null);

  const isLargeScreen = useMediaQuery({ minWidth: 1350 });

  useOutsideClickDetector(itemRef, () => setOpened(false));

  const getItemsStyles = (isHovered: boolean) => [
    styles.item,
    (isSubmenuOpen || isHovered) && highlightOnHover && styles.itemOpen,
    disabled && styles.unavailable,
  ];
  const getTextStyles = (isHovered: boolean) => [
    styles.text,
    !disabled ? (isSubmenuOpen || isHovered) && styles.textActive : styles.unavailableText,
  ];

  return (
    <Pressable>
      {(isHovered) => (
        <View testID="top-nav-item">
          {to ? (
            <Link to={to} external={external} disabled={disabled} testID={testID}>
              <View style={getItemsStyles(isHovered)}>
                <StyledText style={getTextStyles(isHovered)}>{text}</StyledText>
              </View>
            </Link>
          ) : ExtendedDropdownComponent ? (
            <div
              ref={itemRef}
              style={
                StyleSheet.flatten([
                  styles.itemWithSubmenuWrapper,
                  disabled && styles.unavailable,
                ]) as React.CSSProperties
              }
            >
              <View style={getItemsStyles(isHovered)} testID={testID}>
                <StyledText style={getTextStyles(isHovered)}>
                  {text}
                  <Icon
                    width={10}
                    color={isSubmenuOpen || isHovered ? palette.blue : palette.navy}
                    style={[styles.chevron, isSubmenuOpen && styles.chevronRotated]}
                    name="chevron-down"
                  />
                </StyledText>
              </View>
              {!!ExtendedDropdownComponent && (
                <NavigationDropdownWrapper
                  triggerRef={itemRef}
                  style={[styles.extendendDropdown]}
                  align="center"
                  alignTarget={isLargeScreen ? 'parent' : 'window'}
                  isOpen={isSubmenuOpen}
                  toggleOpen={setOpened}
                >
                  <ExtendedDropdownComponent />
                </NavigationDropdownWrapper>
              )}
            </div>
          ) : (
            <View style={getItemsStyles(isHovered)}>
              <StyledText style={getTextStyles(isHovered)}>{text}</StyledText>
            </View>
          )}
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  itemWithSubmenuWrapper: {
    width: '100%',
    height: '100%',
  },
  unavailable: {
    backgroundColor: palette.grey1,
    ...Platform.select({
      web: {
        cursor: 'default',
      },
    }),
  } as ViewStyle,
  unavailableText: {
    ...Platform.select({
      web: {
        cursor: 'default',
      },
    }),
  } as TextStyle,
  item: {
    height: NAVBAR_HEIGHT,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 1,
    ...ifWeb({
      cursor: 'pointer',
      userSelect: 'none',
      transitionProperty: 'background-color',
    }),
  },
  itemOpen: {
    backgroundColor: palette.grey1,
  },
  text: {
    color: palette.navy,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 15,
    fontFamily: fonts.din,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  textActive: {
    color: palette.blue,
  },
  chevron: {
    marginLeft: 11,
    ...ifWeb({
      transition: 'all 250ms',
    }),
  },
  chevronRotated: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  extendendDropdown: {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
});
