import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Pressable, Icon, StyledText, ProBadge } from 'src/components';
import { Link } from 'src/navigation/components';
import { NavigationExtendedDropdownItem } from 'src/navigation/types';
import { ifWeb, palette, typography } from 'src/styles';

interface Props extends NavigationExtendedDropdownItem {}

export const ExtendedDropdownItem: React.FC<Props> = ({ title, description, icon, to, external, pro }) => {
  const getContent = (isHovered: boolean) => (
    <View style={[styles.wrapper, isHovered && styles.wrapperHover]}>
      <Icon name={icon} width={50} style={styles.icon} strokeWidth={1.7} color="plumbsGradient" />
      <View style={styles.textWrapper}>
        <View style={styles.titleWrapper}>
          <StyledText style={[styles.title, isHovered && styles.titleHover]}>{title}</StyledText>
          {!!pro && <ProBadge style={styles.badgeWrapper} />}
        </View>
        {!!description && <StyledText style={styles.description}>{description}</StyledText>}
      </View>
    </View>
  );

  return to ? (
    <Link to={to} external={external}>
      {getContent}
    </Link>
  ) : (
    <Pressable>{getContent}</Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 18,
    flex: 1,
    ...ifWeb({
      cursor: 'pointer',
      transitionProperty: 'background-color',
    }),
  },
  wrapperHover: {
    backgroundColor: palette.grey1,
  },
  icon: {
    marginRight: 20,
  },
  textWrapper: {
    flex: 1,
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  title: {
    color: palette.navy,
    ...typography.body2SemiBold,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  titleHover: {
    color: palette.blue,
  },
  badgeWrapper: {
    marginLeft: 10,
  },
  description: {
    color: palette.grey6,
    ...typography.body1,
    ...typography.weightLight,
  },
});
