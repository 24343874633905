import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { typography } from 'src/styles';

export interface QuickLink {
  key: string;
  content: string;
  onPress: () => void;
}

export interface Props {
  quickLinks?: QuickLink[];
  style?: StyleProp<ViewStyle>;
}

export const QuickLinks: React.FC<Props> = ({ quickLinks, style }) => {
  const { isMobile } = useDeviceInfo();

  const rows = (() => {
    if (!quickLinks) return [];
    if (quickLinks.length < 4) return [quickLinks];
    return [quickLinks.slice(0, 2), quickLinks.slice(2)];
  })();

  return (
    <View style={[styles.wrapper, style]}>
      {rows.map((row, index) => (
        <View key={index} style={[styles.row, isMobile && styles.rowMobile]}>
          {row.map((item) => (
            <View key={item.key} style={[styles.buttonContainer, isMobile && styles.buttonContainerMobile]}>
              <PrimaryButton
                title={item.content}
                innerContainerStyle={styles.button}
                titleStyle={typography.body3Bold}
                titleWrapperStyle={styles.buttonTitleWrapper}
                height={40}
                onPress={item.onPress}
                testID="quick-link"
              />
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 8,
    margin: -4,
  },
  row: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  rowMobile: {
    flexWrap: 'nowrap',
  },
  buttonContainer: {
    margin: 4,
    flex: 1,
    minWidth: '40%',
  },
  buttonContainerMobile: {
    minWidth: 0,
  },
  button: {
    paddingHorizontal: 0,
  },
  buttonTitleWrapper: {
    paddingHorizontal: 12,
  },
});
