import * as R from 'ramda';
import React, { useMemo, useState } from 'react';

import { TableColumn, SortOrder, SortParams } from 'src/constants/types';

import { TableView } from './TableView';

interface Props<D extends Record<string, any>> {
  data: D[];
  columns: TableColumn<D>[];
  initialSortOrder?: SortOrder;
  initialSortBy?: string;
  onSortChange?: (params: SortParams) => void;
  minWidth?: number;
  columnsWidths?: (number | string)[];
  simplified?: boolean;
  maxNumberOfRowsOnFirstPage?: number;
  onViewAllPress?(): void;
  disableLocalSorting?: boolean;
  isLoading?: boolean;
}

const getOtherSortType = (type: SortOrder): SortOrder => (type === 'asc' ? 'desc' : 'asc');

export function Table<D extends Record<string, any>>({
  columns,
  data,
  initialSortOrder,
  initialSortBy,
  minWidth,
  columnsWidths = [],
  simplified,
  maxNumberOfRowsOnFirstPage,
  onViewAllPress,
  onSortChange,
  disableLocalSorting,
  isLoading,
}: Props<D>): React.ReactElement {
  const [numOfRowsDisplayed, setNumOfRows] = useState(maxNumberOfRowsOnFirstPage ?? 99999);

  const [sortParams, setSortParams] = useState<SortParams>({
    sortBy: initialSortBy || columns[0].key.toString(),
    order: initialSortOrder || 'asc',
  });

  const handleHeaderPress = (key: string) => {
    if (!columns.find((column) => column.key === key)?.sort || isLoading) return;
    const updatedSortParams: SortParams = {
      sortBy: key,
      order: sortParams.sortBy === key ? getOtherSortType(sortParams.order) : 'asc',
    };
    onSortChange?.(updatedSortParams);
    setSortParams(updatedSortParams);
  };

  const sortedData = useMemo(() => {
    const { order, sortBy } = sortParams;
    const sort = columns.find((item) => item.key === sortBy)?.sort;

    if (!sort || disableLocalSorting) return data;

    const sortedAsc = [...data].sort(sort === true ? R.ascend(R.prop(sortBy)) : sort);
    return order === 'asc' ? sortedAsc : sortedAsc.reverse();
  }, [sortParams, data, columns, disableLocalSorting]);

  const paginatedData = useMemo(() => {
    return sortedData.slice(0, numOfRowsDisplayed);
  }, [sortedData, numOfRowsDisplayed]);

  const handleViewAllPress = () => {
    setNumOfRows(99999);
    onViewAllPress?.();
  };

  return (
    <TableView
      columns={columns}
      data={paginatedData}
      sortParams={sortParams}
      onHeaderPress={handleHeaderPress}
      columnsWidths={columnsWidths}
      minWidth={minWidth}
      simplified={simplified}
      displayViewAllButton={paginatedData.length < data.length}
      onViewAllPress={handleViewAllPress}
      isLoading={isLoading}
    />
  );
}
