import dayjs from 'dayjs';

import { isPersonalInformationSectionFilled } from 'src/features/profile/helpers';
import { StudentVerificationParams } from 'src/features/studentVerification/types';
import { GoogleAnalyticsService } from 'src/features/tracking';
import { convertObjectToSnakeCase, kebabCaseToNormal } from 'src/helpers';

import { getBasePlanSetup } from '../helpers';
import { BillingPeriod, Plan, PlanSetup, Role } from '../types';
import { NullableFormData, RegistrationFormStep, type RegistrationStepsData, FormData } from './types';

export const getProfileDataFromStudentVerification = (
  data: StudentVerificationParams,
): RegistrationStepsData[RegistrationFormStep.CompleteYourProfile] => {
  const { firstName, country, lastName, organizationName, birthDate, planCode } = data;
  const { role } = getBasePlanSetup(planCode);
  const year = dayjs(birthDate).get('year');

  return {
    information: {
      firstName,
      lastName,
      country,
      bornYear: year.toString(),
      address1: '',
      address2: '',
      city: '',
      phone: '',
      postalCode: '',
      practice: '',
      state: '',
    },
    occupation: {
      activity: role === Role.PHARMACY_STUDENT ? 'student-pharmacy' : 'student-veterinary-medical',
      schoolAttending: 'other',
      schoolAttendingOther: organizationName,
      graduationYear: null,
      activityOther: null,
      numberOfVeterinarians: null,
      pharmacy: null,
      pharmacyOther: null,
      practiceOwnership: null,
      practiceType: null,
      practiceTypeOther: null,
      role: null,
      roleOther: null,
      schoolGraduated: null,
      schoolGraduatedOther: null,
      yearsOfPractice: null,
    },
  };
};

export const isCreateAccountDataFilled = (
  data: RegistrationStepsData[RegistrationFormStep.CreateAnAccount] | null,
) => !!data?.email && !!data?.password;

export const isCompleteYourProfileDataFilled = (
  data: RegistrationStepsData[RegistrationFormStep.CompleteYourProfile] | null,
) => isPersonalInformationSectionFilled(data?.information) && !!data?.occupation;

export const trackPurchaseGAEvent = (planSetup: PlanSetup, dueToday: number, invoiceNumber: string) => {
  GoogleAnalyticsService.track(
    'purchase',
    convertObjectToSnakeCase({
      ...getGAEventData(planSetup, dueToday),
      transactionId: invoiceNumber,
      coupon: planSetup.discountCode,
    }),
  );
};

export const getGAEventData = (planSetup: PlanSetup, dueToday: number) => {
  return {
    currency: 'USD',
    value: dueToday,
    items: [
      convertObjectToSnakeCase({
        itemId: planSetup
          ? planSetup.plan === Plan.NORMAL
            ? 'plumbs-veterinary-drugs'
            : 'plumbs-pro'
          : undefined,
        itemName: planSetup
          ? planSetup.plan === Plan.NORMAL
            ? "Plumb's Veterinary Drugs"
            : "Plumb's Pro"
          : undefined,
        price: dueToday,
        quantity: planSetup?.quantity,
        itemCategory: planSetup ? kebabCaseToNormal(planSetup.role) : undefined,
        itemVariant: planSetup
          ? planSetup.billingPeriod === BillingPeriod.ANNUAL
            ? 'Bill Annualy'
            : 'Bill Monthly'
          : undefined,
      }),
    ],
  };
};

export const isRegistrationFormDataFilled = <S extends readonly RegistrationFormStep[]>(
  data: NullableFormData<S>,
): data is FormData<S> => {
  return (Object.keys(data) as (keyof typeof data)[]).every((key) => data[key] !== null);
};
