import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { ifWeb, palette, typography } from 'src/styles';

import { LoadingIndicator } from './LoadingIndicator';
import { MarkdownContent } from './MarkdownContent';

type CommonProps = {
  style?: StyleProp<ViewStyle>;
  rightColumnContent?: React.ReactNode;
  subtitleContent?: React.ReactNode;
  isLoading?: boolean;
  testID?: string;
  titleStyle?: StyleProp<ViewStyle>;
};

type MarkdownProps = {
  asMarkdownContent: true;
  children?: string;
} & CommonProps;

type TextProps = {
  asMarkdownContent?: false;
  children?: React.ReactNode;
} & CommonProps;

type Props = TextProps | MarkdownProps;

export const ContentHeader: React.FC<Props> = ({
  style,
  rightColumnContent,
  subtitleContent,
  isLoading,
  testID,
  ...props
}) => {
  const textStyle = [typography.body3Bold, styles.header];

  return (
    <View style={[styles.wrapper, style]} testID={testID}>
      <View style={styles.leftColumn}>
        <View style={styles.titleWrapper}>
          {props.asMarkdownContent ? (
            <MarkdownContent inline style={textStyle} limited headerLevel={2}>
              {props.children}
            </MarkdownContent>
          ) : typeof props.children === 'string' ? (
            <StyledText style={[typography.body5Bold, styles.header, textStyle]} headerLevel={2}>
              {props.children}
            </StyledText>
          ) : (
            props.children
          )}
          {isLoading && <LoadingIndicator style={styles.loader} size="small" />}
        </View>
        {!!subtitleContent && <View style={styles.subtitleWrapper}>{subtitleContent}</View>}
      </View>
      {!!rightColumnContent && <View style={styles.rightColumn}>{rightColumnContent}</View>}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleWrapper: {
    flexShrink: 1,
    maxWidth: '100%',
    flexDirection: 'row',
  },
  subtitleWrapper: {
    marginTop: 8,
    alignSelf: 'flex-start',
  },
  header: {
    textTransform: 'uppercase',
    color: palette.navy,
  },
  leftColumn: {
    flexShrink: 1,
    justifyContent: 'center',
  },
  rightColumn: {
    marginLeft: 10,
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
  loader: {
    marginLeft: 15,
  },
});
