import { Trans, useTranslation } from 'react-i18next';

import { InfoModal, StyledText } from 'src/components';
import { StudentSubscriptionStatus } from 'src/features/studentVerification/constants';
import { formatDateWithMonths } from 'src/helpers';
import { typography } from 'src/styles';

import { useUserNotificationAction } from '../hooks';
import { StudentRenewalConfirmNotification } from '../types';

interface Props {
  data: StudentRenewalConfirmNotification;
}

export const StudentRenewalSuccessNotification: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('subscriptionProcess');
  const {
    id: notificationId,
    details: { subscriptionExpirationDate, studentPlansPrice },
  } = data;
  const subscriptionExpirationDateFormatted = formatDateWithMonths(subscriptionExpirationDate);

  const { mutate: closeUserNotification } = useUserNotificationAction(notificationId, 'close');
  const { mutate: confirmUserNotification } = useUserNotificationAction(notificationId, 'submit');

  return (
    <InfoModal testID="student-renewal-success-notification">
      <InfoModal.Body
        header={t('studentRenewal.studentRenewalSuccessHeader')}
        icon="checkmark-circle-2"
        iconWidth={65}
        strokeWidth={1}
      >
        <InfoModal.Content testID="modal-description">
          <Trans
            i18nKey="subscriptionProcess:studentRenewal:studentRenewalSuccessDescription"
            components={{
              b: <StyledText style={typography.weightBold} />,
            }}
            values={{ subscriptionExpirationDate: subscriptionExpirationDateFormatted, studentPlansPrice }}
          />
        </InfoModal.Content>
        <InfoModal.Buttons direction="row">
          <InfoModal.Button
            title={t('studentRenewal.confirmRenewalLabel')}
            onPress={() =>
              confirmUserNotification({ studentStatus: StudentSubscriptionStatus.UpForRenewal })
            }
            testID="modal-submit-btn"
          />
          <InfoModal.Button
            title={t('studentRenewal.cancelRenewalLabel')}
            onPress={() =>
              confirmUserNotification({ studentStatus: StudentSubscriptionStatus.RenewalOptOut })
            }
            testID="modal-submit-btn"
            variant="secondary"
          />
        </InfoModal.Buttons>
      </InfoModal.Body>

      <InfoModal.TextButton
        title={t('studentRenewal.studentRenewalRemind')}
        testID="modal-remind-btn"
        onPress={() => closeUserNotification()}
      />
    </InfoModal>
  );
};
