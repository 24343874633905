import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchInput } from 'src/components';
import { ApplicationState } from 'src/state';
import { AppDispatch } from 'src/state/store';

import { setQuery } from '../state';

interface Props {
  placeholder: string;
  isExpanded?: boolean;
  onInputFocus?: () => void;
  onInputBlur?: () => void;
  isSearchDisplayed?: boolean;
  outlined?: boolean;
}

export const SearchBox: React.FC<Props> = ({
  placeholder,
  onInputBlur,
  onInputFocus,
  isSearchDisplayed,
  outlined,
}) => {
  const { query } = useSelector((state: ApplicationState) => state.generalSearch);
  const dispatch: AppDispatch = useDispatch();

  const onFocus = useCallback(() => {
    onInputFocus?.();
  }, [onInputFocus]);

  const onBlur = useCallback(() => {
    onInputBlur?.();
  }, [onInputBlur]);

  const textChange = (text: string) => {
    dispatch(setQuery(text));
  };

  return (
    <SearchInput
      readOnly={!isSearchDisplayed}
      placeholder={placeholder}
      value={query}
      onChangeText={textChange}
      onClearButtonPress={() => textChange('')}
      onFocus={onFocus}
      onBlur={onBlur}
      rows={1}
      testID="search-input"
      outlined={outlined}
    />
  );
};
