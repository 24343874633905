import React, { useLayoutEffect, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { palette } from 'src/styles';

import { Box } from './Box';
import { ContentHeader } from './ContentHeader';
import { Icon } from './Icon/Icon';
import { Pressable } from './Pressable';

interface Props {
  title?: React.ReactNode;
  expandable?: boolean;
  expanded?: boolean;
  onHeaderPress?: () => void;
  disabled?: boolean; // Works only with expandable = true;
  initiallyExpanded?: boolean;
  style?: StyleProp<ViewStyle>;
  contentHeaderStyle?: StyleProp<ViewStyle>;
  rightColumnHeaderContent?: React.ReactNode;
  subtitleContent?: React.ReactNode;
  testID?: string;
  isLoading?: boolean;
  arrowColor?: string;
}

export const BoxWithHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children,
  expandable,
  expanded,
  onHeaderPress,
  initiallyExpanded,
  rightColumnHeaderContent,
  disabled,
  style,
  contentHeaderStyle,
  testID,
  isLoading,
  arrowColor = palette.navy2,
  subtitleContent,
}) => {
  const [isExpanded, setExpanded] = useState(expandable ? initiallyExpanded ?? true : true);

  useLayoutEffect(() => {
    if (expanded !== undefined) setExpanded(expanded);
  }, [expanded]);

  const shouldDisplayContent = isExpanded && !disabled;

  const contentHeader = (
    <ContentHeader
      style={[!shouldDisplayContent && styles.contentHeaderCollapsed, contentHeaderStyle]}
      rightColumnContent={
        rightColumnHeaderContent ||
        (expandable ? (
          <Icon
            name={!expanded ? 'chevron-down' : 'chevron-up'}
            width={10}
            color={disabled ? palette.grey4 : arrowColor}
          />
        ) : undefined)
      }
      isLoading={isLoading}
      subtitleContent={shouldDisplayContent && subtitleContent}
    >
      {title}
    </ContentHeader>
  );

  const handleHeaderPress = () => {
    onHeaderPress?.();
    if (expanded === undefined) {
      setExpanded((state) => !state);
    }
  };

  return (
    <Box style={style} padded testID={testID} dataSet={{ expanded }}>
      {expandable && !disabled ? (
        <Pressable onPress={handleHeaderPress}>
          <View>{contentHeader}</View>
        </Pressable>
      ) : (
        contentHeader
      )}
      {shouldDisplayContent && children}
    </Box>
  );
};

const styles = StyleSheet.create({
  contentHeaderCollapsed: {
    paddingBottom: 0,
    marginBottom: 0,
    borderBottomWidth: 0,
  },
});
