import React, { useCallback, useState } from 'react';
import { LayoutChangeEvent, Pressable, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets, useSafeAreaFrame } from 'react-native-safe-area-context';
import { SwiperFlatList } from 'react-native-swiper-flatlist';

import {
  FadingBox,
  FullScreenTemplate,
  Image,
  LoadingIndicator,
  MarkdownContent,
  StyledText,
} from 'src/components';
import { useVideoManager } from 'src/hooks/useVideoManager';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { BOTTOM_TAB_NAVIGATION_HEIGHT, HEADER_HEIGHT, palette, typography } from 'src/styles';

import { useDxTxImage } from '../hooks';

type Props = ScreenProps<Route.DxTxMedia>;

export const DxTxMediaScreen: React.FC<Props> = ({ route }) => {
  const { id } = route.params;
  const [currentImgIdx, setCurrentImgIdx] = useState<number>(0);
  const { data, isLoading } = useDxTxImage(id);
  const { height } = useSafeAreaFrame();
  const { bottom, top } = useSafeAreaInsets();
  const [wrapperWidth, setWrapperWidth] = useState<number>(0);
  const [expanded, setExpanded] = useState<boolean>(false);

  const VERTICAL_MARGIN = 20;
  const DESC_BOTTOM_MARGIN = 20;
  const FREE_SPACE =
    height -
    BOTTOM_TAB_NAVIGATION_HEIGHT -
    HEADER_HEIGHT -
    top -
    bottom -
    DESC_BOTTOM_MARGIN -
    VERTICAL_MARGIN;
  const TOP_SECTION = FREE_SPACE / 6;
  const BOTTOM_SECTION = TOP_SECTION * 5;

  const videoPlayer = useVideoManager(data?.videoUrl, [
    styles.backgroundVideo,
    { height: BOTTOM_SECTION - VERTICAL_MARGIN * 2 },
  ]);

  const wrapperOnLayoutHandler = useCallback((e: LayoutChangeEvent) => {
    setWrapperWidth(e.nativeEvent.layout.width);
  }, []);

  const expandedHeightStyles = (_height: number) => ({
    height: !expanded ? _height : undefined,
    flex: expanded ? 1 : undefined,
  });

  const expandedPadding = { paddingBottom: expanded ? 0 : 20 };

  const renderImagesGallery = () => (
    <>
      <SwiperFlatList
        index={0}
        data={data?.imagesUrls || []}
        renderItem={({ item }) => (
          <View style={{ width: wrapperWidth }}>
            <Image
              resizeMode="contain"
              source={{ uri: item }}
              style={[styles.image, { width: wrapperWidth }]}
            />
          </View>
        )}
        onChangeIndex={({ index }) => setCurrentImgIdx(index)}
        showPagination
        paginationStyle={styles.pagination}
        paginationStyleItem={styles.paginationItem}
        paginationDefaultColor={palette.brightBlue3}
        paginationActiveColor={palette.blue}
        contentContainerStyle={styles.imageWrapper}
      />
      <StyledText style={[typography.body2, styles.multipleImagesIndicator]}>
        {i18n.t(`dxTx:nthOf`, { nth: currentImgIdx + 1, count: data?.imagesUrls?.length })}
      </StyledText>
    </>
  );

  return (
    <FullScreenTemplate
      showBackButton
      title={i18n.t('screens:dxTx')}
      isLoading={isLoading}
      contentContainerStyle={styles.container}
      titleInUppercase={false}
    >
      {isLoading && <LoadingIndicator style={styles.loader} />}
      {data && (
        <View
          onLayout={wrapperOnLayoutHandler}
          style={[
            styles.contentWrapper,
            expandedHeightStyles(FREE_SPACE),
            {
              marginVertical: VERTICAL_MARGIN,
            },
          ]}
        >
          <View
            style={[
              expandedHeightStyles(TOP_SECTION),
              {
                minHeight: TOP_SECTION,
              },
            ]}
          >
            <StyledText style={[styles.title]}>{data?.figureTitle}</StyledText>
            <Pressable onPress={() => setExpanded(!expanded)}>
              <FadingBox
                id="figureLegend"
                height={expanded ? 0 : 40}
                style={[expandedHeightStyles(TOP_SECTION), expandedPadding]}
              >
                <MarkdownContent>{data?.figureLegend}</MarkdownContent>
              </FadingBox>
            </Pressable>
          </View>
          <View
            style={{
              height: BOTTOM_SECTION,
            }}
          >
            <View style={styles.divider} />
            {data.videoUrl ? videoPlayer : renderImagesGallery()}
          </View>
        </View>
      )}
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  contentWrapper: {
    marginHorizontal: 25,
  },
  title: {
    color: palette.navy,
    marginBottom: 16,
    ...typography.body4Bold,
  },
  imageWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    marginVertical: 40,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: palette.grey2,
    marginVertical: 20,
  },
  pagination: {
    position: 'relative',
    marginTop: 17,
    marginBottom: 0,
    height: 10,
  },
  paginationItem: {
    height: 10,
    width: 10,
    marginHorizontal: 2,
  },
  container: {
    backgroundColor: palette.white,
    paddingHorizontal: 0,
  },
  multipleImagesIndicator: {
    position: 'absolute',
    right: 0,
    bottom: -10,
    marginBottom: 10,
    marginRight: 16,
  },
  image: {
    height: '100%',
  },
  backgroundVideo: {
    width: '100%',
  },
});
