import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconTextButton } from 'src/components';
import { type HomeScreenContentBox as IHomeScreenContentBox } from 'src/features/home/components/HomeScreenContentBox';
import { type NavigationBlockerRef, NavigationBlocker } from 'src/navigation/components/NavigationBlocker';

import { NotesGroup } from './NotesGroup';
import { NotesGroupHeader } from './NotesGroupHeader';
import { RemoveNoteConfirmModal } from './RemoveNoteConfirmModal';
import { useNotesListContext } from '../context/NotesListContext';
import { useGroupedNotesList } from '../hooks';
import { NotesGroupType } from '../types';

interface Props {
  /** Passed in props to avoid circular imports */
  HomeScreenContentBox: React.ComponentType<IHomeScreenContentBox>;
}

export const GeneralNotesBox: React.FC<Props> = ({ HomeScreenContentBox }) => {
  const navigationBlockerRef = useRef<NavigationBlockerRef>(null);

  const { t } = useTranslation('notes');
  const { data, error, fetchStatus } = useGroupedNotesList();
  const { setAddingNewGeneralNote, unsavedNotes } = useNotesListContext();

  const title = t('generalNotes');

  return (
    <>
      <HomeScreenContentBox
        error={error}
        fetchStatus={fetchStatus}
        Header={
          <NotesGroupHeader
            title={title}
            button={
              !!data && (
                <IconTextButton
                  icon="plus"
                  text={t('addNote')}
                  circleIcon
                  iconPosition="right"
                  iconWidth={12}
                  onPress={() => setAddingNewGeneralNote(true)}
                  testID="add-note-btn"
                />
              )
            }
          />
        }
      >
        {data && !error && (
          <NotesGroup
            title={title}
            group={data[NotesGroupType.General]}
            groupType={NotesGroupType.General}
            displayEmptyContentMessage
          />
        )}
      </HomeScreenContentBox>
      <NavigationBlocker isBlocked={!!unsavedNotes.length} ref={navigationBlockerRef}>
        <RemoveNoteConfirmModal
          cancel={() => navigationBlockerRef.current?.cancelNavigation()}
          confirm={() => navigationBlockerRef.current?.confirmNavigation()}
          type="navigation"
        />
      </NavigationBlocker>
    </>
  );
};
