import { Reducer, useReducer } from 'react';

import { getBasePlanSetup, isStudentRole } from 'src/features/subscription/helpers';
import { Role, StudentRole } from 'src/features/subscription/types';
import { SentryService } from 'src/features/tracking';

import type {
  StudentVerificationParams,
  StudentVerificationStep,
  StudentDataSubmitParams,
  StudentVerficationStatus,
  StudentVerificationDetails,
  CurrentStudentVerificationResponse,
} from '../types';

export interface StudentVerificationReducerStateForRole {
  currentStep: StudentVerificationStep;
  verificationId?: string;
  studentData?: StudentVerificationParams | null;
  ssoLoginUrl?: string | null;
}

export interface StudentVerificationReducerState {
  formData: {
    [Role.PHARMACY_STUDENT]: StudentVerificationReducerStateForRole;
    [Role.VETERINARY_STUDENT]: StudentVerificationReducerStateForRole;
  };
  role: Role;
}

export type StudentVerificationAction =
  | { type: 'student-data-submit'; payload: StudentDataSubmitParams }
  | { type: 'doc-upload' }
  | { type: 'doc-upload-success' }
  | { type: 'doc-upload-failure' }
  | { type: 'doc-upload-limit-reached' }
  | { type: 'sso-login-started' }
  | { type: 'sso-login-success' }
  | { type: 'sso-login-skip' }
  | { type: 'email-token-verification-success' }
  | { type: 'email-token-verification-skip' }
  | { type: 'set-role'; payload: Role }
  | {
      type: 'set-verification-details';
      payload: { verificationDetails: StudentVerificationDetails; verificationId: string };
    }
  | { type: 'set-verification-details-for-both-roles'; payload: CurrentStudentVerificationResponse };

const getInitialState = (initialRole: StudentRole): StudentVerificationReducerState => ({
  role: initialRole,
  formData: {
    [Role.PHARMACY_STUDENT]: {
      currentStep: 'student-data',
      ssoLoginUrl: null,
      studentData: null,
      verificationId: undefined,
    },
    [Role.VETERINARY_STUDENT]: {
      currentStep: 'student-data',
      ssoLoginUrl: null,
      studentData: null,
      verificationId: undefined,
    },
  },
});

const reducer: Reducer<StudentVerificationReducerState, StudentVerificationAction> = (
  state: StudentVerificationReducerState,
  action: StudentVerificationAction,
) => {
  switch (action.type) {
    case 'doc-upload': {
      return applyFormDataForCurrentRole(state, { currentStep: 'pending' });
    }

    case 'doc-upload-success': {
      return applyFormDataForCurrentRole(state, { currentStep: 'verified' });
    }

    case 'doc-upload-failure': {
      return applyFormDataForCurrentRole(state, { currentStep: 'documents' });
    }

    case 'doc-upload-limit-reached': {
      return applyFormDataForCurrentRole(state, {
        studentData: null,
        currentStep: 'student-data',
        verificationId: undefined,
      });
    }

    case 'email-token-verification-skip': {
      return applyFormDataForCurrentRole(state, { currentStep: 'documents' });
    }

    case 'email-token-verification-success': {
      return applyFormDataForCurrentRole(state, { currentStep: 'verified' });
    }

    case 'sso-login-skip': {
      return applyFormDataForCurrentRole(state, { currentStep: 'documents' });
    }

    case 'sso-login-started': {
      return applyFormDataForCurrentRole(state, { currentStep: 'sso-pending' });
    }

    case 'sso-login-success': {
      return applyFormDataForCurrentRole(state, { currentStep: 'verified' });
    }

    case 'set-role': {
      return { ...state, role: action.payload };
    }

    case 'student-data-submit': {
      const { status, data, ssoLoginUrl, verificationId } = action.payload;

      return applyFormDataForCurrentRole(state, {
        studentData: data,
        ssoLoginUrl,
        verificationId,
        currentStep: getStepByVerificationStatus(status),
      });
    }

    case 'set-verification-details': {
      const { status, loginUrl, ...studentData } = action.payload.verificationDetails;
      const role = getBasePlanSetup(studentData.planCode).role;

      if (!isStudentRole(role)) {
        throw new Error(`Cannot set student verification details for ${role} role`);
      }

      return {
        ...state,
        formData: {
          ...state.formData,
          [role]: {
            studentData,
            ssoLoginUrl: loginUrl,
            verificationId: action.payload.verificationId,
            currentStep: getStepByVerificationStatus(status),
          },
        },
      };
    }

    case 'set-verification-details-for-both-roles': {
      const { pharmacyBasicStudent, veterinaryProStudentAnnual } = action.payload;

      const {
        status: statusPharmacy,
        loginUrl: loginUrlPharmacy,
        ...studentDataPharmacy
      } = pharmacyBasicStudent.data;
      const {
        status: statusVeterinary,
        loginUrl: loginUrlVeterinary,
        ...studentDataVeterinary
      } = veterinaryProStudentAnnual.data;

      return {
        ...state,
        formData: {
          [Role.PHARMACY_STUDENT]: {
            currentStep: getStepByVerificationStatus(statusPharmacy),
            ssoLoginUrl: loginUrlPharmacy,
            studentData: studentDataPharmacy,
            verificationId: pharmacyBasicStudent.verificationId,
          },
          [Role.VETERINARY_STUDENT]: {
            currentStep: getStepByVerificationStatus(statusVeterinary),
            ssoLoginUrl: loginUrlVeterinary,
            studentData: studentDataVeterinary,
            verificationId: veterinaryProStudentAnnual.verificationId,
          },
        },
      };
    }
  }
};

const applyFormDataForCurrentRole = (
  state: StudentVerificationReducerState,
  change: Partial<StudentVerificationReducerStateForRole>,
): StudentVerificationReducerState => {
  const currentRole = state.role;
  if (!isStudentRole(currentRole)) {
    throw new Error(`Cannot dispatch this action with 'role' set to ${currentRole}.`);
  }

  return {
    ...state,
    formData: {
      ...state.formData,
      [currentRole]: {
        ...state.formData[currentRole],
        ...change,
      },
    },
  };
};

const getStepByVerificationStatus = (status?: StudentVerficationStatus): StudentVerificationStep => {
  switch (status) {
    case 'docUpload':
      return 'documents';
    case 'success':
      return 'verified';
    case 'sso':
      return 'sso';
    case 'pending':
      return 'pending';
    case 'ssoPending':
      return 'sso-pending';
    case 'emailLoop':
      return 'email-loop';
    case 'started':
    case 'failed':
    case 'expired':
      return 'student-data';
    default: {
      if (status) {
        SentryService.captureException("Couldn't recognize student verification status", {
          status,
        });
      }
      return 'student-data';
    }
  }
};

export const useStudentVerificationReducer = (initialRole: StudentRole) =>
  useReducer(reducer, initialRole, getInitialState);
