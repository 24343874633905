import React from 'react';
import { Route as RouterRoute, Redirect } from 'react-router-dom';

import { WebScreen } from '../components';
import { LinkedRoute, ScreenConfig, ScreenOptions } from '../types';
import { routeToPath } from '../utils';

interface RouterScreenConfig extends ScreenConfig, ScreenOptions {
  name: LinkedRoute;
  component: React.ComponentType<any>;
}

export const getRouterRoutesForScreens = (screens: RouterScreenConfig[]) =>
  screens.map(({ path, component, exact, alternativePath, ...rest }) => [
    <RouterRoute key={path} path={path} exact={exact}>
      <WebScreen Component={component} {...rest} />
    </RouterRoute>,
    alternativePath ? (
      <RouterRoute
        key={alternativePath}
        path={alternativePath}
        render={(props) => (
          <Redirect
            to={routeToPath(rest.name, {
              ...props.match.params,
              ...Object.fromEntries(new URLSearchParams(props.location.search)),
            })}
          />
        )}
      />
    ) : null,
  ]);
