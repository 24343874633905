import React from 'react';
import { StyleSheet, View } from 'react-native';

import { LoadingIndicator, MobileContentHeader } from 'src/components';
import {
  Item,
  Footer,
  ButtonsWrapper,
  Form,
  Row,
  SubComponents,
  MainSectionProps,
} from 'src/components/Forms';
import { ifWeb } from 'src/styles';

export const AccountInformationSection: React.FC<React.PropsWithChildren<MainSectionProps>> &
  SubComponents = ({ title, isLoading, style, children, testID }) => {
  return (
    <View style={[styles.wrapper, styles.zIndexUnset, style]} testID={testID}>
      <MobileContentHeader style={styles.header}>{title}</MobileContentHeader>
      {isLoading ? <LoadingIndicator /> : children}
    </View>
  );
};

AccountInformationSection.Row = Row;
AccountInformationSection.Form = Form;
AccountInformationSection.Item = Item;
AccountInformationSection.Footer = Footer;
AccountInformationSection.ButtonsWrapper = ButtonsWrapper;

const styles = StyleSheet.create({
  header: {
    marginBottom: 12,
  },
  zIndexUnset: {
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
  wrapper: {
    marginBottom: 40,
  },
});
