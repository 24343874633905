import { useInfiniteQuery } from '@tanstack/react-query';

import { CONTENT_LIST_ITEMS_PER_PAGE, CONTENT_LIST_ITEMS_PER_PAGE_NATIVE } from 'src/constants/constants';
import { getDataFromInfiniteQuery, isWeb } from 'src/helpers';

import { search } from '../api';
import type { SearchQueryParams } from '../types';

export const useSearch = (params: SearchQueryParams) => {
  return useInfiniteQuery({
    queryKey: ['search', params],
    queryFn: async ({ pageParam, signal }) => {
      const pageSize = isWeb ? CONTENT_LIST_ITEMS_PER_PAGE : CONTENT_LIST_ITEMS_PER_PAGE_NATIVE;
      const data = await search({
        ...params,
        page: pageParam,
        pageSize,
        signal,
      });

      const { count = 0, results = [] } = data;
      const numberOfResults = (pageParam - 1) * pageSize + results.length;
      const allFetched = numberOfResults === count;

      return {
        count,
        results,
        nextPage: allFetched ? undefined : pageParam + 1,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (data) => data.nextPage,
    select: getDataFromInfiniteQuery,
    enabled: !!params.query,
  });
};
