import { useTranslation } from 'react-i18next';
import { Animated, StyleSheet } from 'react-native';

import { BaseButton } from 'src/components';

import { EmailHandoutsForm } from './EmailHandoutsForm';
import { useHandoutsEmailing } from '../hooks';
import { useHandoutMarginAnimation } from '../hooks/useHandoutMarginAnimation';
import type { ShareHandoutsFormProps } from '../types';

interface Props extends ShareHandoutsFormProps {}

export const EmailHandouts: React.FC<Props> = ({ onShareSuccess, LanguageSelector, language }) => {
  const { t } = useTranslation('handouts');
  const {
    errors,
    handleOnBlur,
    handleOnChange,
    handleSubmit,
    loading,
    touched,
    values,
    dirty,
    isValid,
    selectedHandouts,
    setValues,
  } = useHandoutsEmailing({ onSuccess: onShareSuccess, language });
  const marginAnimation = useHandoutMarginAnimation();

  return (
    <Animated.View style={{ marginBottom: marginAnimation }} testID="email-handouts-form">
      <EmailHandoutsForm
        errors={errors}
        handleOnBlur={handleOnBlur}
        handleOnChange={handleOnChange}
        touched={touched}
        values={values}
        setValues={setValues}
      />
      {LanguageSelector}
      <BaseButton
        title={t('email', { count: selectedHandouts.length })}
        variant="gradient"
        icon="chevron-right-circle"
        containerStyle={styles.submitButton}
        titleStyle={styles.submitButtonTitle}
        loading={loading}
        disabled={!dirty || !isValid || loading}
        onPress={handleSubmit}
        testID="email-handout-btn"
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  submitButton: {
    alignSelf: 'flex-start',
    marginTop: 28,
  },
  submitButtonTitle: {
    textTransform: 'none',
  },
});
