import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAutologin, useIsSignedIn } from 'src/features/auth/hooks';
import { ExpiredSharedAccountModal } from 'src/features/subscription';
import { OlyticsService } from 'src/features/tracking';
import { getAutologinTokenSiteName, isCurrentHostDifferentThanDefault } from 'src/helpers';
import { Route, ScreenProps } from 'src/navigation';
import { ApplicationState } from 'src/state';

import { IPLogin, RegularLoginTemplate, AutologinTokenSignedOutMessage } from '../components';
import { closeInactiveSharedAccountWarning } from '../state';

interface Props extends ScreenProps<Route.SignIn | Route.Auth> {}

export const SignInScreen: React.FC<Props> = ({ route, navigation }) => {
  const isSignedIn = useIsSignedIn();
  const dispatch = useDispatch();
  const { shouldDisplayInactiveSharedAccountWarning } = useSelector(
    (state: ApplicationState) => state.auth,
  );
  const { userId, autologinToken, email } = route.params || {};

  const { mutate: autologin } = useAutologin();

  const handleCloseWarningPress = () => {
    dispatch(closeInactiveSharedAccountWarning());
  };

  useEffect(() => {
    OlyticsService.fire();
  }, []);

  useEffect(() => {
    if (autologinToken && userId) {
      autologin(
        { autologinToken, userId },
        {
          onError: () => {
            navigation.setParams({ autologinToken: undefined, userId: undefined });
          },
        },
      );
    }
  }, [isSignedIn, autologinToken, userId, autologin, navigation]);

  return (
    <>
      {route.name === Route.Auth || isCurrentHostDifferentThanDefault ? (
        getAutologinTokenSiteName() ? (
          <AutologinTokenSignedOutMessage />
        ) : (
          <IPLogin />
        )
      ) : (
        <RegularLoginTemplate email={email} />
      )}
      {shouldDisplayInactiveSharedAccountWarning && (
        <ExpiredSharedAccountModal close={handleCloseWarningPress} />
      )}
    </>
  );
};

export default SignInScreen;
