import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FullScreenTemplate } from 'src/components';
import { Route } from 'src/navigation';
import { ScreenProps, WebHistoryState } from 'src/navigation/types';
import { AppDispatch } from 'src/state/store';

import { SearchResults } from '../components';
import { SearchFiltersBar } from '../components/SearchFiltersBar';
import { MIN_SEARCH_QUERY_LENGTH } from '../constants';
import { useSearchParams } from '../hooks';
import { setMainFilter, setQuery } from '../state';

type Props = ScreenProps<Route.SearchWeb>;

export const SearchScreen: React.FC<Props> = ({ route }) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('search');

  const [syncedWithState, setSyncedWithState] = useState(false);

  const { query: stateQuery, availableSearchFilters } = useSearchParams();
  const { query: paramsQuery = '' } = route.params;
  const initialQuery = useRef(paramsQuery).current;

  const isQueryEmpty = useMemo(() => {
    if (!syncedWithState) {
      return false;
    }
    return stateQuery.length < 1;
  }, [stateQuery, syncedWithState]);

  useEffect(() => {
    return () => {
      dispatch(setQuery(''));
    };
  }, [dispatch]);

  useEffect(() => {
    // Saving query and filter values from URL params
    const { params } = route;

    const validatedParams = {
      ...params,
      contentType:
        params.contentType && availableSearchFilters.find((filter) => filter.id === params.contentType)
          ? params.contentType
          : availableSearchFilters[0].id,
    };

    validatedParams.query && dispatch(setQuery(validatedParams.query));
    dispatch(setMainFilter(validatedParams.contentType));
    setSyncedWithState(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!(initialQuery?.length >= MIN_SEARCH_QUERY_LENGTH)) {
      history.replace('/');
    }
  }, [initialQuery, history]);

  useEffect(() => {
    if (isQueryEmpty) {
      const state = history.location.state as WebHistoryState;
      history.push(state?.previousUrl || '/');
    }
  }, [isQueryEmpty, history]);

  return (
    <FullScreenTemplate title={t('header')} stickyContent={<SearchFiltersBar />}>
      <SearchResults />
    </FullScreenTemplate>
  );
};
