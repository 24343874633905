import React, { useEffect } from 'react';

import { ContentItemScreen } from 'src/components/ContentItemScreen/ContentItemScreen';
import { useContentNotes } from 'src/features/notes';
import { getMixpanelDDxLocation, MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { DDxHeaderDescription } from '../components/DDxHeaderDescription';
import { useDDxItem } from '../hooks/queries';

export interface Props extends ScreenProps<Route.DDxItem> {}

export const DDxItemScreen: React.FC<Props> = ({ route }) => {
  const { id, section, subsection, source, searchQuery } = route.params;
  const { data, error, fetchStatus } = useDDxItem(id);

  const notes = useContentNotes(id);

  useEffect(() => {
    if (data) {
      MixpanelService.track(MixpanelEvent.DDxView, {
        'DDx ID': data.id,
        'DDx Title': data.title,
        'Search Term Before Click Through': source === 'search' ? searchQuery : undefined,
        ...getMixpanelDDxLocation(source),
      });
    }
  }, [data, source, searchQuery]);

  return (
    <>
      <ContentItemScreen
        title={i18n.t('contentTypeLabel:ddx')}
        titleInUppercase={false}
        item={data}
        notes={notes}
        error={error}
        sectionToScrollTo={section}
        subsectionToScrollTo={subsection}
        sectionOpenByDefault="differentials"
        headerDescription={data && <DDxHeaderDescription introCopy={data.introCopy} />}
        fetchStatus={fetchStatus}
      />
    </>
  );
};
