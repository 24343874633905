import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showNotificationForErrors } from 'src/errorHandling/utils';
import { MixpanelService, MixpanelEvent } from 'src/features/tracking';
import { isClinicalHandout, isDrugHandout } from 'src/helpers/content';
import { compareArrays, isEmailValid, removeCommaAndWhitesSpaces } from 'src/helpers/functions';
import { showNotification } from 'src/helpers/notifications';
import { Yup } from 'src/helpers/validation';
import { i18n } from 'src/locale';

import { useHandoutsToShare } from './useHandoutsToShare';
import { emailHandouts } from '../api';
import { getHandoutsMixpanelLanguage } from '../helpers';
import type { EmailHandoutsFormValues, EmailHandoutsParams, HandoutsLanguageType } from '../types';

interface Params {
  onSuccess?(): void;
  language: HandoutsLanguageType;
}

const confirmFieldValidation = (fieldToCompare: string) =>
  emailsListValidation.test('test', i18n.t('validation:emailShouldMatch'), function (value) {
    const { parent } = this;
    if (!value) {
      return false;
    }
    const emails = removeCommaAndWhitesSpaces(value).split(' ');
    const emailsToCompare = removeCommaAndWhitesSpaces(parent[fieldToCompare]).split(' ');
    return compareArrays(emailsToCompare, emails);
  });

const emailsListValidation = Yup.string().test(
  'emails',
  i18n.t('validation:notAllEmailsAreValid'),
  (value) => {
    const trimmedValue = removeCommaAndWhitesSpaces(value);
    if (!trimmedValue) {
      return true;
    }
    const emails = trimmedValue.split(' ');
    const invalidEmails = emails.filter((email) => !isEmailValid(email));
    return !invalidEmails.length;
  },
);

const validationSchema = Yup.object().shape({
  ccCheckbox: Yup.boolean(),
  bccCheckbox: Yup.boolean(),
  to: emailsListValidation.required(),
  confirmTo: confirmFieldValidation('to'),
  cc: Yup.string()
    .nullable()
    .when('ccCheckbox', {
      is: (ccCheckbox: boolean) => ccCheckbox,
      then: emailsListValidation,
    }),
  confirmCC: Yup.string()
    .nullable()
    .when('cc', {
      is: (cc: string) => {
        return !!cc?.length;
      },
      then: confirmFieldValidation('cc'),
    }),
  bcc: Yup.string()
    .nullable()
    .when('bccCheckbox', {
      is: (bccCheckbox: boolean) => bccCheckbox,
      then: emailsListValidation,
    }),
  confirmBCC: Yup.string()
    .nullable()
    .when('bcc', {
      is: (bcc: string[]) => {
        return !!bcc?.length;
      },
      then: confirmFieldValidation('bcc'),
    }),
  firstNote: Yup.string(),
  secondNote: Yup.string(),
  subject: Yup.string().required().max(100),
});

export const useHandoutsEmailing = ({ onSuccess, language }: Params) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { handouts } = useHandoutsToShare();

  const { t } = useTranslation('handouts');

  const initialValues: EmailHandoutsFormValues = {
    to: undefined,
    confirmTo: undefined,
    cc: undefined,
    confirmCC: undefined,
    bcc: undefined,
    confirmBCC: undefined,
    emailNote: t('emailLinkNote'),
    subject: t('subjectValue'),
    ccCheckbox: false,
    bccCheckbox: false,
  };

  const handleSubmit = () => {
    const isCorrectTo = doEmailsMatch('confirmTo', values.to, values.confirmTo);

    if (!isValid || !isCorrectTo) return;

    setLoading(true);
    return sendEmail();
  };

  const doEmailsMatch = (fieldId: string, value?: string, confirmValue?: string): boolean => {
    if (!value) return !confirmValue;
    if (!confirmValue) {
      return false;
    }

    const isValid = removeCommaAndWhitesSpaces(value) === removeCommaAndWhitesSpaces(confirmValue);
    !isValid && setFieldError(fieldId, i18n.t('validation:emailShouldMatch'));

    return isValid;
  };

  const {
    values,
    errors,
    handleChange,
    touched,
    isValid,
    dirty,
    setFieldTouched,
    setFieldError,
    setValues,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: true,
  });

  const trackMixpanelAction = () => {
    if (handouts.length === 1) {
      const singleHandout = handouts[0];
      const titleKey = isDrugHandout(singleHandout) ? 'VMG Title' : 'Patient Guide Title';
      const idKey = isDrugHandout(singleHandout) ? 'VMG ID' : 'Patient Guide ID';
      MixpanelService.track(MixpanelEvent.HandoutEmailed, {
        [titleKey]: singleHandout.title,
        [idKey]: singleHandout.id,
        'Email Subject': values.subject,
        'Handouts Language': getHandoutsMixpanelLanguage(language),
      });
    } else {
      const drugHandouts = handouts.filter(isDrugHandout);
      const clinicalHandouts = handouts.filter(isClinicalHandout);
      MixpanelService.track(MixpanelEvent.MultipleHandoutsEmailed, {
        'VMG Titles': drugHandouts.map((item) => item.title),
        'Clinical Guide Titles': clinicalHandouts.map((item) => item.title),
        'VMG ID': drugHandouts.map((item) => item.id),
        'Clinical Guide ID': clinicalHandouts.map((item) => item.id),
        'Email Subject': values.subject,
        'Handouts Language': getHandoutsMixpanelLanguage(language),
      });
    }
  };

  const sendEmail = async () => {
    try {
      const params: EmailHandoutsParams = {
        emailsTo: removeCommaAndWhitesSpaces(values.to),
        emailsCc: values.ccCheckbox ? removeCommaAndWhitesSpaces(values.cc) : '',
        emailsBcc: values.bccCheckbox ? removeCommaAndWhitesSpaces(values.bcc) : '',
        emailContent: values.emailNote ? values.emailNote : t('emailLinkNote'),
        additionalNotes: '',
        subject: values.subject,
        guideIds: handouts.map((handout) => handout.id),
        language,
      };

      await emailHandouts(params);
      trackMixpanelAction();
      setLoading(false);
      showNotification({ type: 'success' });
      onSuccess?.();
    } catch (err: any) {
      showNotificationForErrors(err?.response?.data);
      setLoading(false);
    }
  };

  const handleOnChange = (id: string, value: string) => {
    // @ts-ignore
    handleChange(id)(value);
  };

  const handleOnBlur = (id: string) => {
    setFieldTouched(id);
  };

  return {
    loading,
    dirty,
    isValid,
    handleOnChange,
    handleSubmit,
    handleOnBlur,
    errors,
    touched,
    values,
    selectedHandouts: handouts,
    setValues,
  };
};
