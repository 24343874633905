import React, { FocusEventHandler, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { unstable_createElement, WrappedComponent } from 'react-native-web';

import { ifWeb, palette, typography } from 'src/styles';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  isOptionSelected?: boolean;
}

export interface SelectInputRef {
  focus: () => void;
}

type WrappedInputT = WrappedComponent<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>;

const WrappedInput = forwardRef<SelectInputRef, React.ComponentProps<WrappedInputT>>((props, ref) => {
  return unstable_createElement('input', { ...props, ref });
});

export const SelectInput = forwardRef<SelectInputRef, Props>(
  ({ value, onChange, onBlur, onFocus, isOptionSelected }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focus() {
        inputRef.current?.focus();
      },
    }));

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange(e.target.value);
    };

    const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        const { key } = e;
        if (key === ' ' && !value) {
          // Making sure the dropdown is opening after pressing the Spacebar key
          e.preventDefault();
        }
      },
      [value],
    );

    return (
      <WrappedInput
        style={[styles.input, isOptionSelected && !value && styles.inputHiddenCaret]}
        value={value}
        onChange={handleChange}
        onKeyDown={handleInputKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        // This property should prevent browsers from displaying autofill window which covers the select's dropdown
        autoComplete="new-password"
        ref={inputRef}
      />
    );
  },
);

const styles = StyleSheet.create({
  input: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    paddingLeft: 16,
    paddingRight: 32,
    borderWidth: 0,
    backgroundColor: palette.transparent,
    color: palette.grey8,
    ...typography.body3,
    ...ifWeb({
      boxSizing: 'border-box',
    }),
  },
  inputHiddenCaret: {
    ...ifWeb({
      caretColor: palette.transparent,
    }),
  },
});
