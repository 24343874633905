import MarkdownIt from 'markdown-it';
import StateCore from 'markdown-it/lib/rules_core/state_core';
import Token from 'markdown-it/lib/token';

export interface MarkdownItWithPlain extends MarkdownIt {
  plainText: string;
}

const typesWithoutSpace = ['strong', 'sup', 'sub', 'em', 'u'].map((type) => type + '_close');

// This plugin is 99% taken from this package -> https://www.npmjs.com/package/markdown-it-plain-text

export function plainTextPlugin(md: MarkdownIt) {
  function plainTextRule(state: StateCore) {
    const text = scan(state.tokens);
    // remove redundant white spaces
    (md as any).plainText = text.replace(/\s+/g, ' ').trim();

    return true;
  }

  function scan(tokens: Token[]) {
    let text = '';
    tokens.forEach((token) => {
      if (token.children !== null) {
        text += scan(token.children);
      } else {
        if (
          token.type === 'text' ||
          token.type === 'fence' ||
          token.type === 'html_block' ||
          token.type === 'code_block' ||
          token.type === 'html_inline' ||
          token.type === 'emoji'
        ) {
          text += token.content;
        } else if (
          token.type === 'softbreak' ||
          token.type === 'hardbreak' ||
          (/[a-zA-Z]+_close/.test(token.type) && !typesWithoutSpace.includes(token.type))
        ) {
          // prevent words from sticking together
          text += ' ';
        }
      }
    });

    return text;
  }

  md.core.ruler.push('plainText', plainTextRule);
}
