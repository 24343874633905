import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, TouchableWithoutFeedbackProps } from 'react-native';

import type { RenderPropComponentProps } from 'src/constants/types';
import isHoverEnabled from 'src/helpers/isHoverEnabled';
import { isRenderProp } from 'src/helpers/typeGuards';
import { ifWeb } from 'src/styles';

type RenderPropParams = [boolean, boolean, boolean?];

interface Props
  extends Pick<
      TouchableWithoutFeedbackProps,
      'onPress' | 'hitSlop' | 'testID' | 'disabled' | 'focusable' | 'accessibilityLabel'
    >,
    RenderPropComponentProps<RenderPropParams> {
  onHover?(): void;
  onHoverOut?(): void;
}

export const Pressable: React.FC<Props> = ({
  onPress,
  children,
  testID,
  onHover,
  onHoverOut,
  accessibilityLabel,
  ...props
}) => {
  const [hovered, setHovered] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [focused, setFocused] = useState(false);

  const onMouseEnter = useCallback(() => {
    if (isHoverEnabled()) {
      setHovered(true);
      onHover?.();
    }
  }, [onHover]);

  const onMouseLeave = useCallback(() => {
    setHovered(false);
    onHoverOut?.();
  }, [onHoverOut]);

  const onPressIn = useCallback(() => {
    setPressed(true);
  }, []);

  const onPressOut = useCallback(() => {
    setPressed(false);
  }, []);

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocused(false);
  }, []);

  if (!children) return null;

  const childElement = isRenderProp(children) ? children(hovered, pressed, focused) : children;

  const element = React.cloneElement(
    childElement,
    R.reject(R.isNil, {
      onMouseEnter,
      onMouseLeave,
      testID,
      'aria-disabled': props.disabled,
      style: [!!onPress && !props.disabled && styles.pressableChild, childElement.props.style],
    }),
  );

  /**
   * Touchable___ and Pressable components prevent event bubbling by design, so we don't use them if not needed
   * to avoid swallowing events in situations where Pressable is nested, eg. <Button> inside <Link>
   */
  return onPress ? (
    <TouchableWithoutFeedback
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      onFocus={onFocus}
      onBlur={onBlur}
      accessibilityLabel={accessibilityLabel}
      {...props}
    >
      {element}
    </TouchableWithoutFeedback>
  ) : (
    element
  );
};

const styles = StyleSheet.create({
  pressableChild: {
    ...ifWeb({
      cursor: 'pointer',
    }),
  },
});
