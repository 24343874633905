import { StyleSheet } from 'react-native';

import { MarkdownContent, StyledText } from 'src/components';
import { palette, typography } from 'src/styles';

import { DrugHeaderDescription } from '.';
import { INTRO_NOTES_SECTION } from '../constants';
import { DrugDetails } from '../types';

interface Props {
  data: DrugDetails;
}

export const DrugItemIntro: React.FC<Props> = ({ data }) => {
  const introNoteSection = data?.sections.find((section) => section.id === INTRO_NOTES_SECTION);

  return (
    <>
      {data.aliases?.length ? (
        <StyledText style={styles.secondTitle}>{data.aliases.join(', ')}</StyledText>
      ) : null}
      {data.pronunciationNew ? (
        <MarkdownContent inline limited style={styles.pronunciation}>
          {data.pronunciationNew}
        </MarkdownContent>
      ) : null}
      <DrugHeaderDescription commercialNames={data.commercialNames} drugClass={data.drugClass} />
      {introNoteSection ? (
        <MarkdownContent inline style={styles.introNote} linkStyle={styles.link}>
          {introNoteSection.value}
        </MarkdownContent>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  secondTitle: {
    color: palette.navy,
    ...typography.body3,
  },
  pronunciation: {
    color: palette.grey5,
    ...typography.body3,
  },
  introNote: {
    marginTop: 26,
    ...typography.body2,
  },
  link: {
    ...typography.body2,
  },
});
