import * as R from 'ramda';
import React, { useEffect } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import {
  CircleIconButton,
  IconTextButton,
  ListItem,
  MobileContentHeader,
  StyledText,
} from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation, useRoute } from 'src/navigation/hooks';
import { NavigationProp, RouteProp } from 'src/navigation/types';
import { CONTAINER_PADDING_H_MOBILE, typography } from 'src/styles';

import { useDrugNames } from '../hooks';
import { useInteractionsMixpanelEvent } from '../hooks/useInteractionsMixpanelEvent';

export interface Props {
  style?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<TextStyle>;
}

export const SelectedDrugsList: React.FC<Props> = ({ style, headerStyle }) => {
  const { isTablet } = useDeviceInfo();
  const navigation = useNavigation<NavigationProp<Route.DIC>>();
  const { params } = useRoute<RouteProp<Route.DIC>>();
  const { drugs = [], source } = params ?? {};

  const { data, isFetching } = useDrugNames(drugs);
  useInteractionsMixpanelEvent(
    data?.drugs.map((item) => item.title),
    source,
  );

  useEffect(() => {
    /** If user passes an invalid drug ID in URL params, it won't be returned from useDrugNames.
     * In that case, drug list in URL and this returned from useDrugNames won't match,
     * so we synchronize them here.
     */
    if (data?.drugs) {
      navigation.setParams({
        drugs: data.drugs.map((item) => item.id),
      });
    }
  }, [navigation, data?.drugs]);

  const removeDrug = (drugId: string) => {
    if (drugs.length) {
      navigation.setParams({
        drugs: drugs.length > 1 ? R.without([drugId], drugs) : undefined,
      });
    }
  };

  const handleClearAllPress = () => {
    navigation.setParams({ drugs: undefined, all: undefined });
  };

  if (isFetching) return null;

  return (
    <View style={style}>
      <View style={[styles.headerWrapper, !isTablet && styles.padding]}>
        <MobileContentHeader style={headerStyle}>{i18n.t('dic:selectedDrugs')}</MobileContentHeader>
        {!!data?.drugs.length && (
          <IconTextButton
            icon="close"
            text={i18n.t('clearAll')}
            circleIcon
            onPress={handleClearAllPress}
            iconWidth={10}
          />
        )}
      </View>

      {data?.drugs.length ? (
        <View>
          {data?.drugs.map((drug, index, arr) => (
            <ListItem
              key={drug.id}
              title={drug.title}
              topBorder
              bottomBorder={arr.length - 1 === index}
              sideBorder={isTablet}
              roundedTopBorder={isTablet && index === 0}
              roundedBottomBorder={isTablet && data && index === data?.drugs.length - 1}
              testID="selected-drug"
              rightColumn={
                <CircleIconButton
                  name="close"
                  width={10}
                  containerSize={28}
                  onPress={() => removeDrug(drug.id)}
                  outline
                  testID="remove-drug-btn"
                />
              }
            />
          ))}
        </View>
      ) : (
        <StyledText style={[styles.text, !isTablet && styles.padding]}>
          {i18n.t('dic:searchAndSelect')}
        </StyledText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  text: {
    ...typography.body2,
  },
  padding: {
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
});
