import { ContentType } from 'src/features/content/constants';

import { GroupRole, Permission } from './enums';
import type { GroupInfo, GroupInfoCurrentMember } from './types';
import { GroupMemberAccessLevel } from '../adminDashboard/types';
import { Contents } from '../content/config';
import { ContentTypeSimplified } from '../content/types';

// note that admins are also group members
export const isUserAGroupMember = (groupInfo: GroupInfo | null): groupInfo is GroupInfoCurrentMember => {
  return !!groupInfo && !groupInfo.removed;
};

export const isUserAGroupAdmin = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.role === GroupRole.ADMIN;
};

export const isUserANormalGroupMember = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.role === GroupRole.MEMBER;
};

export const isUserASelfServeInstantConversion = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.isSelfServeInstantConversion;
};

/** User is a group member with limited access to content */
export const doesUserHaveLimitedProAccess = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.accessLevel === GroupMemberAccessLevel.ProLimited;
};

export const isUserAManagedGroupMember = (
  groupInfo: GroupInfo | null,
): groupInfo is GroupInfoCurrentMember => {
  return isUserAGroupMember(groupInfo) && !!groupInfo.isManagedGroup;
};

export const isUserAManagedGroupAdmin = (groupInfo: GroupInfo | null): boolean => {
  return isUserAManagedGroupMember(groupInfo) && groupInfo.role === GroupRole.ADMIN;
};

export function getPermissionsByContentType(contentType: ContentType | ContentTypeSimplified): {
  access: Permission;
  display: Permission;
} {
  switch (Contents.getSimplifiedType(contentType)) {
    case 'algorithm': {
      return {
        access: Permission.FullAccessAlgorithms,
        display: Permission.DisplayAlgorithms,
      };
    }
    case 'dx-tx': {
      return {
        access: Permission.FullAccessClinicalBriefs,
        display: Permission.DisplayClinicalBriefs,
      };
    }
    case 'clinical-handout': {
      return {
        access: Permission.FullAccessClinicalHandouts,
        display: Permission.DisplayClinicalHandouts,
      };
    }
    case 'drug-handout': {
      return {
        access: Permission.FullAccessDrugHandouts,
        display: Permission.FullAccessDrugHandouts,
      };
    }

    case 'appendix': {
      return {
        access: Permission.FullAccessAppendix,
        display: Permission.DisplayAppendix,
      };
    }

    case 'drug': {
      return {
        access: Permission.FullAccessDrugs,
        display: Permission.DisplayDrugs,
      };
    }

    case 'ddx': {
      return {
        access: Permission.FullAccessDDx,
        display: Permission.DisplayDDx,
      };
    }
  }
}
