import { client } from 'src/api/client';

import type {
  EmailChangeValidationParams,
  NullablePersonalInformationPayload,
  OccupationDetailsPayload,
  PasswordChangeParams,
} from './types';
import type { TokensResponse } from '../auth/types';

export const fetchOccupationDetails = async () => {
  const response = await client.request<OccupationDetailsPayload>({
    method: 'GET',
    url: '/accounts/user/occupation-details/',
  });

  return response.data;
};

export const editOccupationDetails = async (data: OccupationDetailsPayload) => {
  const response = await client.request<OccupationDetailsPayload>({
    method: 'PUT',
    url: '/accounts/user/occupation-details/',
    data,
  });

  return response.data;
};

export const fetchPersonalInformation = async () => {
  const response = await client.request<NullablePersonalInformationPayload>({
    url: '/accounts/user/personal-information/',
    method: 'GET',
  });

  return response.data;
};

export const editPersonalInformation = async (data: NullablePersonalInformationPayload) => {
  const response = await client.request<NullablePersonalInformationPayload>({
    url: '/accounts/user/personal-information/',
    method: 'PUT',
    data,
  });

  return response.data;
};

export const changeEmail = (email: string) => {
  return client.request({
    url: 'accounts/user/email-change/',
    method: 'POST',
    data: {
      email,
    },
  });
};

export const validateEmailChangeToken = (params: EmailChangeValidationParams) => {
  const { token, userId, newEmail } = params;
  return client.request<void>({
    url: `/accounts/user/email-change/verify/${token}/${userId}/${newEmail}/`,
    method: 'GET',
    omitAuth: true,
  });
};

export const changePassword = async (data: PasswordChangeParams) => {
  const response = await client.request<TokensResponse>({
    url: 'accounts/user/password-change/',
    method: 'PUT',
    data,
  });

  return response.data;
};
