import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { DropdownWithHeader } from 'src/components';
import { DropdownItem } from 'src/constants/types';
import { useUserInfo } from 'src/features/auth/hooks';
import { isWeb } from 'src/helpers';
import { i18n } from 'src/locale';
import { ifWeb, palette } from 'src/styles';

import { FavoritesFilters } from '../config/filters';

interface Props {
  selectedFilterId: string;
  setFilter(filter: string): void;
  disabled?: boolean;
}

export const FavoritesFilterDropdown: React.FC<Props> = ({ selectedFilterId, setFilter, disabled }) => {
  const { permissions } = useUserInfo();

  const items: DropdownItem<string>[] = useMemo(
    () =>
      FavoritesFilters.getAvailableFilters(permissions).map<DropdownItem<string>>((filter) => ({
        value: filter.id,
        label: i18n.t(filter.labelI18nKey),
        isPro: filter.isPro,
        testID: filter.id,
        listLabel: filter.labelInListI18nKey && i18n.t(filter.labelInListI18nKey),
        titleStyle: filter.disableTextTransform && styles.transformNone,
      })),
    [permissions],
  );

  const handleFilterChange = (item: DropdownItem<string>) => {
    setFilter(item.value!);
  };

  return (
    <DropdownWithHeader
      items={items}
      value={selectedFilterId}
      onChange={handleFilterChange}
      disabled={disabled}
      getTitle={(item) => (!item || disabled ? i18n.t('favorites:favorites') : item.label)}
      getTitleStyle={(item) => [item?.titleStyle, styles.title]}
      dropdownStyle={styles.dropdown}
      arrowColor={isWeb ? palette.white : undefined}
    />
  );
};

const styles = StyleSheet.create({
  transformNone: {
    textTransform: 'none',
  },
  title: {
    ...ifWeb({
      color: palette.white,
    }),
  },
  dropdown: {
    ...ifWeb({
      width: 275,
    }),
  },
});
