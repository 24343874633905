import { onlineManager } from '@tanstack/react-query';
import { StyleSheet, View } from 'react-native';

import { isServerIssueError } from 'src/api/utils';
import { ErrorMessage } from 'src/errorHandling/components/ErrorMessage';
import { palette } from 'src/styles';

import { HomeScreenContentBox as Props } from './HomeScreenContentBox';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

export const HomeScreenContentBox: React.FC<Props> = ({ children, error, Header, fetchStatus }) => {
  const offlineError = fetchStatus === 'paused' && !onlineManager.isOnline();

  if (fetchStatus === 'idle' && !error) {
    return (
      <View style={styles.wrapper}>
        {Header}
        {children}
      </View>
    );
  }

  return (
    <View style={styles.wrapper}>
      {Header}
      <View style={styles.box}>
        {fetchStatus === 'fetching' && <LoadingIndicator />}
        {(error || offlineError) && (
          <ErrorMessage
            style={styles.errorWrapper}
            textStyle={styles.errorMessage}
            errorType={
              offlineError ? 'internet-connection' : isServerIssueError(error) ? 'server-issue' : 'common'
            }
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 28,
  },
  box: {
    borderRadius: 5,
    borderColor: palette.grey2,
    borderWidth: 1,
    paddingVertical: 28,
  },
  errorWrapper: {
    alignItems: 'center',
  },
  errorMessage: {
    textAlign: 'center',
    maxWidth: 300,
  },
});
