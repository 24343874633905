import React, { useLayoutEffect, useState } from 'react';

import { WebScreenContext } from 'src/contexts/webScreenContext';
import { useNavigation, useRoute } from 'src/navigation/hooks';
import { NavigationProp, ScreenOptions, ScreenProps } from 'src/navigation/types';

import { Route } from '../routes';

interface Props extends ScreenOptions {
  Component: React.ComponentType<ScreenProps<any>>;
  options?: {
    title?: string;
  };
}

const Screen: React.FC<Props> = ({ Component, getId }) => {
  const navigation = useNavigation<NavigationProp<Route>>();
  const route = useRoute();

  const key = getId?.({ params: route.params });

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [key]);

  return <Component key={key} navigation={navigation} route={route} />;
};

export const WebScreen: React.FC<Props> = React.memo(({ options, ...rest }) => {
  const state = useState(options ?? {});

  useLayoutEffect(() => {
    window.document.title = state[0].title || 'Plumb’s™';
  }, [state]);

  return (
    <WebScreenContext.Provider value={state}>
      <Screen {...rest} />
    </WebScreenContext.Provider>
  );
});
