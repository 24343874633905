import mixpanel, { Dict, OverridedMixpanel } from 'mixpanel-browser';

import { isTest } from 'src/helpers/common';
import { EnvService } from 'src/services/EnvService';

import type { IMixpanelService } from '../types';

declare global {
  interface Window {
    mixpanel?: OverridedMixpanel;
  }
}

export default class MixpanelService implements IMixpanelService {
  token: string | undefined;
  isEnabled: boolean;
  isIdentified = false;
  private eventsToTrackAfterIdentify: { eventName: string; params: Dict | undefined }[] = [];

  constructor() {
    this.token = EnvService.getEnv('MIXPANEL_TOKEN');
    this.isEnabled = !!this.token && !isTest;
  }

  async initialize() {
    if (this.isEnabled) {
      mixpanel.init(this.token!);
      // Appcues needs Mixpanel to be available as global variable to track custom events
      window.mixpanel = mixpanel;
    }
  }

  track: IMixpanelService['track'] = (eventName, params, options = {}) => {
    if (!this.isEnabled) {
      return;
    }
    const { enableAsAnonymous } = options;
    if (enableAsAnonymous || this.isIdentified) {
      mixpanel.track(eventName, params);
    } else {
      this.eventsToTrackAfterIdentify.push({ eventName, params });
    }
  };

  identify(id: string) {
    if (this.isEnabled) {
      mixpanel.identify(id);
      this.isIdentified = true;

      this.eventsToTrackAfterIdentify.forEach(({ eventName, params }) => {
        mixpanel.track(eventName, params);
      });
      this.eventsToTrackAfterIdentify = [];
    }
  }

  clearUser() {
    if (this.isEnabled) {
      mixpanel.reset();
      this.isIdentified = false;
    }
  }

  setUserProperties(data: Record<string, any>) {
    this.isEnabled && mixpanel.people.set(data);
  }

  setSuperProperties(data: Record<string, string | undefined>) {
    this.isEnabled && mixpanel.register(data);
  }

  setGroup(groupKey: string, groupId: string) {
    if (this.isEnabled) {
      mixpanel.register({ [groupKey]: groupId });
    }
  }

  unsetGroup(groupKey: string) {
    this.isEnabled && mixpanel.unregister(groupKey);
  }
}

const instance = new MixpanelService();

export { instance as MixpanelService };
