import React from 'react';

import { NavigationDropdownPortal } from 'src/contexts/portals';

import { NavigationPanel } from './NavigationPanel/NavigationPanel';

export const SignedOutPanel: React.FC = () => {
  return (
    <NavigationDropdownPortal.Provider>
      <NavigationPanel />
      <NavigationDropdownPortal.Outlet />
    </NavigationDropdownPortal.Provider>
  );
};
