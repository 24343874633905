import { useContentItemQuery } from 'src/hooks/queries/contentItems';

import type { ClinicalHandoutDetails, DrugHandoutDetails } from '../types';

export const useHandoutItem = (id?: string) =>
  useContentItemQuery<ClinicalHandoutDetails | DrugHandoutDetails>({
    key: ['handout', id as string],
    url: `/contents/pet-owner-guides/${id}/`,
    options: {
      enabled: !!id,
    },
  });
