import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { palette } from 'src/styles';

import { Icon, Props as IconComponentProps } from './Icon/Icon';
import { Popover } from './Popovers/Popover';
import { Pressable } from './Pressable';

const getInsets = (x: number) => ({ left: x, right: x, top: x, bottom: x });

interface Props extends IconComponentProps {
  backgroundColor?: string;
  containerSize?: number;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
  onHoverColor?: string;
  disabled?: boolean;
  hitSlop?: number;
  testID?: string;
  badgeValue?: string | number;
  tooltip?: React.ReactNode | null;
  disabledColor?: string;
  accessibilityLabel?: string;
}
export const IconButton: React.FunctionComponent<Props> = ({
  onPress,
  containerStyle,
  backgroundColor,
  containerSize,
  onHoverColor,
  disabled,
  disabledColor = palette.grey2,
  hitSlop = 10,
  testID,
  badgeValue,
  tooltip,
  accessibilityLabel,
  ...props
}) => {
  const getIcon = (isHovered?: boolean) => (
    <Icon
      {...props}
      color={disabled ? disabledColor : isHovered ? onHoverColor : props.color}
      badgeValue={badgeValue}
    />
  );

  return (
    <Pressable
      hitSlop={getInsets(hitSlop)}
      onPress={onPress}
      testID={testID}
      disabled={disabled}
      accessibilityLabel={accessibilityLabel}
    >
      {(isHovered) => (
        <View
          style={[
            styles.container,
            !!backgroundColor && { backgroundColor },
            !!containerSize && {
              width: containerSize,
              height: containerSize,
              borderRadius: containerSize,
            },
            containerStyle as ViewStyle,
          ]}
        >
          {tooltip ? <Popover content={tooltip}>{getIcon(isHovered)}</Popover> : getIcon(isHovered)}
        </View>
      )}
    </Pressable>
  );
};

IconButton.displayName = 'IconButton';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
