import { ContentListType } from 'src/features/content/constants';
import { ContentInList, ContentListItemCommonProps } from 'src/features/content/types';
import { isWeb } from 'src/helpers';
import { useContentListByContentType } from 'src/hooks/queries/contentLists';

import { InfiniteScrollList } from './InfiniteScrollList';
import { InfiniteScrollListLegacy } from './InfiniteScrollListLegacy';

export interface Props<CT extends ContentListType> {
  contentListType: CT;
  letter: string;
  ContentCardComponent: React.FC<ContentListItemCommonProps<ContentInList[CT]> & any>;
}

export const ContentLetterList = <CT extends ContentListType>({
  contentListType,
  letter,
  ContentCardComponent,
}: Props<CT>) => {
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useContentListByContentType(
    contentListType,
    { startsWithLetter: letter },
  );

  const ListComponent = isWeb ? InfiniteScrollList : InfiniteScrollListLegacy;

  return (
    <ListComponent
      data={data?.items}
      loading={isFetchingNextPage}
      allFetched={!hasNextPage}
      fetchNextPage={fetchNextPage}
      renderItem={({ index, item }) => (
        <ContentCardComponent
          item={item}
          location="content-list"
          bottomBorder={false}
          topBorder={index !== 0}
        />
      )}
      keyExtractor={(item) => item.id}
    />
  );
};
