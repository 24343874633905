import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import {
  ACADEMIC_SOLUTIONS_LINK,
  PHARMACISTS_SOLUTIONS_LINK,
  PRICING_LINK,
  PRO_SOLUTION_LINK,
  PVD_SOLUTION_LINK,
  SOLUTIONS_LINK,
  STUDENTS_SOLUTIONS_LINK,
  VETERINARY_PRACTICES_SOLUTIONS_LINK,
  VETERINARY_PRO_SOLUTIONS_LINK,
} from 'src/constants/externalLinks';
import { ExtendedDropdownProps, NavigationExtendedDropdown } from 'src/navigation/types';

import { ExtendedDropdown } from './ExtendedDropdown';

interface Props extends ExtendedDropdownProps {}

export const SolutionsExtendedDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation('navigation');

  const compareSolutionsButton = {
    title: t('compareSolutions'),
    to: SOLUTIONS_LINK,
    external: true,
  };

  const data: NavigationExtendedDropdown = {
    content: [
      {
        title: t('ourSolutions'),
        description: t('ourSolutionsDescription'),
        items: [
          {
            icon: 'drugs',
            title: t('pvd'),
            description: t('pvdDescription'),
            to: PVD_SOLUTION_LINK,
            external: true,
          },
          {
            icon: 'stethoscope',
            title: t('plumbsPro'),
            description: t('plumbsProDescription'),
            to: PRO_SOLUTION_LINK,
            external: true,
          },
        ],
        button: props.inMobileDropdown ? compareSolutionsButton : undefined,
      },
      {
        title: t('howPlumbsHelps'),
        description: t('howPlumbsHelpsDescription'),
        items: [
          [
            {
              icon: 'veterinarian',
              title: t('veterinarians'),
              to: VETERINARY_PRO_SOLUTIONS_LINK,
              external: true,
            },
            {
              icon: 'practice',
              title: t('veterinaryPractices'),
              to: VETERINARY_PRACTICES_SOLUTIONS_LINK,
              external: true,
            },
            {
              icon: 'student',
              title: t('students'),
              to: STUDENTS_SOLUTIONS_LINK,
              external: true,
            },
          ],
          [
            {
              icon: 'drug-box',
              title: t('pharmacists'),
              to: PHARMACISTS_SOLUTIONS_LINK,
              external: true,
            },
            {
              icon: 'book',
              title: t('academicInstitutions'),
              to: ACADEMIC_SOLUTIONS_LINK,
              external: true,
            },
          ],
        ],
        button: props.inMobileDropdown
          ? {
              title: t('learnMore'),
              to: PRICING_LINK,
              external: true,
            }
          : undefined,
      },
    ],
    button: props.inMobileDropdown ? undefined : compareSolutionsButton,
  };

  return (
    <ExtendedDropdown
      wrapperStyle={[!props.inMobileDropdown && styles.wrapperDesktop]}
      {...data}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  wrapperDesktop: {
    width: 1100,
  },
});
