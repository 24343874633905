import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'src/state/store';

import { useUserInfo } from './useUserInfo';
import { type IPSignOutConfirmModal as ModalProps } from '../components/IPSignOutConfirmModal';
import { signOut } from '../state';

interface Props {
  IPSignOutConfirmModal: React.ComponentType<ModalProps>;
}

export const useSignOut = ({ IPSignOutConfirmModal }: Props) => {
  const [isConfirmModalDisplayed, setConfirmModal] = useState(false);

  const { isSharedAccount } = useUserInfo();
  const dispatch: AppDispatch = useDispatch();

  const confirmSignOut = () => {
    dispatch(signOut());
  };

  const handleSignOutPress = () => {
    if (isSharedAccount) {
      setConfirmModal(true);
    } else {
      confirmSignOut();
    }
  };

  const confirmationModal = isConfirmModalDisplayed ? (
    <IPSignOutConfirmModal close={() => setConfirmModal(false)} signOut={confirmSignOut} />
  ) : null;

  return {
    confirmationModal,
    handleSignOutPress,
  };
};
