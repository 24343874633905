import * as React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { IconName } from 'src/constants/types';
import { isWeb } from 'src/helpers/common';
import { palette } from 'src/styles';

import { iconComponents } from './getIcon';
import { StyledText } from '../StyledText';

export interface Props {
  name: IconName;
  color?: string;
  style?: StyleProp<ViewStyle>;
  expander?: number;
  stroke?: string;
  strokeWidth?: number | string;
  width?: number;
  height?: number;
  badgeValue?: string | number;
  nonScalingStroke?: boolean;
  preserveAspectRatio?: string;
  testID?: string;
}

const Wrapper = isWeb
  ? ({ children }: React.ComponentProps<any>) => <span className="svg-icon-wrapper">{children}</span>
  : React.Fragment;

export const Icon: React.FC<Props> = ({
  name,
  color = palette.blue,
  expander,
  style,
  width = 20,
  height,
  badgeValue,
  nonScalingStroke,
  ...props
}) => {
  const IconComponent = iconComponents[name];

  return (
    <View
      style={[{ width: width, height: width ?? height }, { marginHorizontal: expander }, style]}
      testID={props.testID}
    >
      <Wrapper>
        <IconComponent
          color={color}
          width="100%"
          height="100%"
          pointerEvents="none"
          vectorEffect={nonScalingStroke ? 'non-scaling-stroke' : undefined}
          {...props}
        />
      </Wrapper>
      {badgeValue !== undefined && (
        <View style={styles.badgeWrapper}>
          <StyledText style={styles.badgeValue}>{badgeValue}</StyledText>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  badgeWrapper: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: palette.blue,
    position: 'absolute',
    top: -4,
    left: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeValue: {
    color: palette.white,
    fontSize: 11,
    fontWeight: '600',
    marginLeft: 1,
    alignSelf: 'center',
  },
});
