import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

/**
 * @description Stores state in `AsyncStorage`.
 * @returns `undefined` when the state is loading
 */
export const usePersistentState = <T extends string>(key: string, initialValue: T) => {
  const [state, setState] = useState<T | undefined>(undefined);

  useEffect(() => {
    // Load the initial state
    if (state) {
      return;
    }
    AsyncStorage.getItem(key, (error, value) => {
      if (error || !value) {
        setState(initialValue);
        return;
      }
      // Avoid any race conditions
      setState((state) => state || (value as T));
    });
  }, [key, state, setState, initialValue]);

  useEffect(() => {
    // Save the new state
    if (state !== undefined) {
      AsyncStorage.setItem(key, state);
    }
  }, [key, state]);

  return [state, setState] as const;
};
