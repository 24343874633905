import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { PaginationListFetchParams } from 'src/constants/types';
import { fetchDrugSuggestionsList } from 'src/features/drugs';
import { getDataFromInfiniteQuery } from 'src/helpers';

import { getDrugInteractions, getDrugNames, getInteractionsCount } from '../api';
import { DICClassificationKey, DICMode, DrugNamesPayload } from '../types';

interface UseInteractionsParams {
  drugs: string[];
  params?: {
    classification?: DICClassificationKey;
    mode?: DICMode;
  };
  listParams?: PaginationListFetchParams;
  options?: {
    enabled?: boolean;
  };
}

export const useDrugNames = (drugs: DrugNamesPayload = []) => {
  return useQuery({
    queryKey: ['drug-names', ...drugs],
    queryFn: () => getDrugNames(drugs),
    enabled: drugs.length > 0,
  });
};

export const useDrugSuggestionsList = (term: string, selectedDrugs?: string[]) => {
  const queryData = useInfiniteQuery({
    queryKey: ['drug-suggestions-list', { query: term, selectedDrugs }],
    queryFn: ({ pageParam }) => {
      return fetchDrugSuggestionsList({
        page: pageParam,
        term,
        selectedDrugs,
      });
    },
    select: getDataFromInfiniteQuery,
    initialPageParam: 1,
    getNextPageParam: (page) => page.nextPage,
    enabled: term.length > 1,
  });

  return {
    ...queryData,
    allFetched: !!queryData.data && !queryData.hasNextPage,
  };
};

export const useInteractions = ({ drugs, options, params, listParams }: UseInteractionsParams) => {
  return useInfiniteQuery({
    queryKey: ['interactions', drugs, params],
    queryFn: async ({ pageParam }) => {
      const { results, next, count } = await getDrugInteractions({
        ...params,
        ...listParams,
        page: pageParam,
        drugs,
      });

      return {
        results,
        count,
        nextPage: next ? pageParam + 1 : undefined,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (page) => page.nextPage,
    select: getDataFromInfiniteQuery,
    ...options,
  });
};

export const useInteractionsCount = (drugs: string[], options: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: ['interactions-count', { drugs }],
    queryFn: () => getInteractionsCount({ drugs }),
    ...options,
  });
};
