import { RenderTarget } from '@shopify/flash-list';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { StyleSheet, View } from 'react-native';

import { getValidComponent } from 'src/helpers';

import { InfiniteScrollListRef, Props } from './InfiniteScrollList';
import { LoadingIndicator } from './LoadingIndicator';

export const InfiniteScrollListInner = <T,>(
  {
    data,
    renderItem,
    loading,
    ListFooterComponent,
    ListHeaderComponent,
    ListHeaderComponentStyle,
    ListFooterComponentStyle,
    ListEmptyComponent,
    allFetched,
    fetchNextPage,
    contentContainerStyle,
    keyExtractor,
  }: Props<T>,
  ref: React.ForwardedRef<InfiniteScrollListRef>,
) => {
  const shouldReserveSpaceForSpinner = ListFooterComponent ? false : !allFetched;
  const listRef = useRef<View>(null);

  const scrollToTop = () => {
    const current = listRef.current as unknown as HTMLDivElement;
    current.scrollIntoView();
  };

  useImperativeHandle(ref, () => ({
    scrollToTop,
  }));

  const isListEmpty = !data || data.length === 0;

  return (
    <View style={contentContainerStyle} ref={listRef}>
      {!!ListHeaderComponent && (
        <View style={ListHeaderComponentStyle}>{getValidComponent(ListHeaderComponent)}</View>
      )}
      {data?.map((item, index) => (
        <React.Fragment key={keyExtractor?.(item, index)}>
          {renderItem?.({ index, item, target: '' as RenderTarget })}
        </React.Fragment>
      ))}
      {!!ListEmptyComponent && isListEmpty && getValidComponent(ListEmptyComponent)}
      {!loading && <VisibilitySensor fetchNextPage={fetchNextPage} />}
      <View style={shouldReserveSpaceForSpinner && styles.loaderHeight}>
        {!!loading && <LoadingIndicator style={styles.loaderHeight} displayStandbyText />}
      </View>
      {!!ListFooterComponent && (
        <View style={ListFooterComponentStyle}>{getValidComponent(ListFooterComponent)}</View>
      )}
    </View>
  );
};

const VisibilitySensor: React.FC<Pick<Props<any>, 'fetchNextPage'>> = ({ fetchNextPage }) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div style={StyleSheet.flatten(styles.visibilitySensor)} ref={ref} data-testid="visibility-sensor" />
  );
};

export const InfiniteScrollList = forwardRef(InfiniteScrollListInner) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<InfiniteScrollListRef> },
) => ReturnType<typeof InfiniteScrollListInner>;

const styles = StyleSheet.create({
  loaderHeight: {
    height: 120,
    justifyContent: 'center',
  },
  visibilitySensor: {
    paddingTop: 1,
    width: '100%',
  },
});
