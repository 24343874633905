import { client } from 'src/api/client';

import {
  PasswordRecoveryFormData,
  SignInFormData,
  SignInResponse,
  AutologinResponse,
  TokensResponse,
  SetNewPasswordResponse,
  IPLoginResponse,
  AutologinParams,
} from './types';

export const signIn = async (data: SignInFormData) => {
  const response = await client.request<SignInResponse>({
    method: 'POST',
    url: '/token/',
    data,
    omitAuth: true,
  });

  return response.data;
};

export const sendResetPasswordEmail = async (email: string) => {
  return client.request({
    url: '/accounts/password/reset/',
    method: 'POST',
    data: { email },
    omitAuth: true,
  });
};

export const validatePasswordRecoveryToken = (token: string, userId: string) => {
  return client.request({
    url: `/accounts/password/reset/verify/${token}/${userId}/`,
    method: 'GET',
    omitAuth: true,
  });
};

export const setNewPassword = async (params: {
  token: string;
  userId: string;
  data: PasswordRecoveryFormData;
}) => {
  const { token, userId, data } = params;
  const response = await client.request<SetNewPasswordResponse>({
    url: `/accounts/password/reset/${token}/${userId}/set-password/`,
    method: 'PUT',
    data,
    omitAuth: true,
  });

  return response.data;
};

export const REFRESH_TOKEN_URL = '/token/refresh/';
export const refreshAccessToken = async (refreshToken: string) => {
  const response = await client.request<TokensResponse>({
    url: '/token/refresh/',
    method: 'POST',
    data: { refresh: refreshToken },
    omitAuth: true,
  });

  return response.data;
};

export const autologin = async (params: AutologinParams) => {
  const response = await client.request<AutologinResponse>({
    method: 'POST',
    url: '/auth/token/',
    data: params,
    omitAuth: true,
  });

  return response.data;
};

export const ipLogin = async () => {
  const response = await client.request<IPLoginResponse>({
    url: '/auth/ip/',
    method: 'POST',
    omitAuth: true,
  });

  return response;
};
