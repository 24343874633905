import React, { useEffect } from 'react';
import { useColorScheme } from 'react-native';

import {
  ContentItemScreen,
  FeatureButtonWithSectionId,
} from 'src/components/ContentItemScreen/ContentItemScreen';
import { SpecialSectionIDs } from 'src/constants/constants';
import { useContentNotes } from 'src/features/notes';
import { useRatingPrompt } from 'src/features/reviewRequest/hooks';
import {
  getMixpanelDICLocation,
  MixpanelService,
  getMixpanelDrugLocation,
  MixpanelDisplayMode,
  MixpanelEvent,
  OlyticsService,
} from 'src/features/tracking';
import { useContentHandouts } from 'src/hooks';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { DrugItemIntro } from '../components';
import { useDrugItem } from '../hooks';

export interface Props extends ScreenProps<Route.DrugItem> {}

export const DrugItemScreen: React.FC<Props> = ({ route, navigation }) => {
  const { id, section, subsection, source, searchQuery } = route.params;
  const { data, error, fetchStatus } = useDrugItem(id);

  const notes = useContentNotes(id);

  const colorScheme = useColorScheme();

  useRatingPrompt();

  useEffect(() => {
    if (data) {
      const displayMode: MixpanelDisplayMode = colorScheme === 'light' ? 'Light Mode' : 'Dark Mode';

      MixpanelService.track(MixpanelEvent.DrugView, {
        'Drug Title': data.title,
        'Drug ID': data.id,
        'Search Term Before Click Through': source === 'search' ? searchQuery : undefined,
        'Display Mode': displayMode,
        ...getMixpanelDrugLocation(source),
      });

      OlyticsService.fire({
        MonographData: data.title,
        MonographDrugType: data.drugClass?.join(', '),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, source, searchQuery]);

  const {
    shouldDisplayButton: showHandoutsButton,
    openPrintableHandoutsList,
    renderedModal: handoutsModal,
    numberOfHandouts,
  } = useContentHandouts({
    item: data,
  });

  const featureButtons: FeatureButtonWithSectionId[] = [
    {
      text: i18n.t(`featureButtons:dic`),
      icon: 'chevron-right',
      onPress: () => {
        navigation.navigate(Route.DIC, { drugs: [id], source: 'drug' });
        MixpanelService.track(MixpanelEvent.DICDrugAdded, {
          'Drug Name': data!.title,
          'Number of Drugs Added': 1,
          Location: getMixpanelDICLocation('drug'),
        });
      },
      hidden: !data?.showMonograph,
      sectionWithButton: SpecialSectionIDs.DRUG_INTERACTIONS,
      testID: 'interaction-checker-button',
    },
    {
      text: i18n.t('featureButtons:viewDrugHandouts', { count: numberOfHandouts }),
      icon: 'chevron-right',
      onPress: openPrintableHandoutsList,
      hidden: !showHandoutsButton,
      sectionWithButton: SpecialSectionIDs.CLIENT_INFORMATION,
      testID: 'handout-options-button',
    },
  ];

  const headerDescription = data && <DrugItemIntro data={data} />;

  return (
    <>
      <ContentItemScreen
        title={i18n.t('contentTypeLabel:drug')}
        item={data}
        notes={notes}
        headerDescription={headerDescription}
        featureButtons={featureButtons}
        error={error}
        sectionToScrollTo={section}
        subsectionToScrollTo={subsection}
        fetchStatus={fetchStatus}
      />
      {handoutsModal}
    </>
  );
};
