import { MouseEvent, MutableRefObject, useEffect } from 'react';
import { Platform } from 'react-native';

/**
 * Hook which detect the click outside of given element(s) (for web only)
 * @param refs Ref or list of refs to elements outside from which you want to detect the click
 * @param callback Callback function to be called when a click occurs
 */
export function useOutsideClickDetector(
  refs: MutableRefObject<any> | MutableRefObject<any>[],
  callback: () => any,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const refsList = Array.isArray(refs) ? refs : [refs];
      let isOutside = true;

      refsList.forEach((ref) => {
        if (ref?.current?.contains(event.target)) {
          isOutside = false;
        }
      });

      isOutside && callback();
    }

    if (Platform.OS === 'web') {
      // @ts-ignore
      document.addEventListener('click', handleClickOutside, true);
    }

    return () => {
      if (Platform.OS === 'web') {
        // @ts-ignore
        document.removeEventListener('click', handleClickOutside, true);
      }
    };
  }, [refs, callback]);
}
