import { useMemo, useCallback, useEffect } from 'react';
import { Animated } from 'react-native';

import { useNativeKeyboard } from 'src/hooks/useNativeKeyboard';

/**
 * We need to add extra margin on the bottom to avoid the keyboard overlapping the submit button.
 * This is because of weird behavior of KeyboardAvoidingView on iOS
 * which adds extra white space at the end of the View.
 */
export const useHandoutMarginAnimation = () => {
  const marginAnimation = useMemo(() => new Animated.Value(0), []);
  const { keyboardHeight } = useNativeKeyboard();

  const animateMargin = useCallback(
    (newMargin: number) => {
      Animated.timing(marginAnimation, {
        toValue: newMargin,
        duration: 500,
        useNativeDriver: false,
      }).start();
    },
    [marginAnimation],
  );

  useEffect(() => {
    animateMargin(keyboardHeight);
  }, [animateMargin, keyboardHeight]);

  return marginAnimation;
};
