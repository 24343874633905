import React from 'react';
import { View } from 'react-native';

import { StyledText } from 'src/components';

interface Props {
  verticalOffset: number;
}

export const SupSub: React.FC<React.PropsWithChildren<Props>> = ({ verticalOffset, children }) => (
  <>
    <StyledText>{'\u2060' /* prevent linebreak here */}</StyledText>
    <View style={{ transform: [{ translateY: verticalOffset }] }}>{children}</View>
  </>
);
