import debounce from 'debounce';
import React, { useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';

import { InfiniteScrollList, MobileContentHeader, StyledText } from 'src/components';
import { CardStrategy } from 'src/components/CardStrategy';
import { ContentListItemCommonProps } from 'src/features/content/types';
import {
  OlyticsService,
  MixpanelService,
  MixpanelEvent,
  getMixpanelSearchLocationByFilter,
  getMixpanelSearchLocationByRoute,
  getMixpanelSearchType,
} from 'src/features/tracking';
import { isWeb } from 'src/helpers';
import { useContainer, useDeviceInfo } from 'src/hooks';
import { i18n } from 'src/locale';
import { Route, RouteProp } from 'src/navigation';
import { useRoute } from 'src/navigation/hooks';
import { store } from 'src/state/store';
import {
  ifWeb,
  CONTAINER_PADDING_H_MOBILE,
  palette,
  typography,
  CONTAINER_PADDING_V_MOBILE_APP,
  CONTAINER_PADDING_V_MOBILE,
  CONTAINER_PADDING_V_TABLET,
  CONTAINER_PADDING_V_DESKTOP_LARGE,
  FLASH_LIST_ESTIMATED_ITEM_SIZE,
} from 'src/styles';

import { SearchContentTypeFilter } from '../enums';
import { useSearchParams } from '../hooks';
import type { SearchResultItem } from '../types';

interface Props {
  searchNextPage: () => void;
  data: SearchResultItem[];
  count: number;
  isLoading: boolean;
  isError: boolean;
  page?: number;
}

export const SearchResultsList: React.FC<Props> = ({ searchNextPage, data, count, isLoading, page }) => {
  const { mainFilter } = useSearchParams();
  const { isTablet, isLargeDesktop } = useDeviceInfo();
  const routeName = useRoute<RouteProp<Route.SearchWeb>>().params.route;

  const containerStyle = useContainer({
    fullWidthOnMobile: true,
    maxWidth: 'narrow',
  });

  const renderCardComponent = (item: SearchResultItem, index: number) => {
    const itemProps: Omit<ContentListItemCommonProps<any>, 'item'> = {
      location: 'search-list',
      topBorder: index === 0,
      bottomBorder: true,
      sideBorder: isTablet,
      roundedTopBorder: isTablet && index === 0,
      roundedBottomBorder: isTablet && index === data.length - 1,
    };
    return <CardStrategy item={item} {...itemProps} />;
  };

  const dispatchAnalyticsEvents = useMemo(
    () =>
      debounce((filter: SearchContentTypeFilter, count: number, query: string, routeName: Route) => {
        const searchType = getMixpanelSearchType(filter);
        MixpanelService.track(MixpanelEvent.SearchResultsLoad, {
          'Search Term': query,
          'Search Type': searchType,
          'Search Location': isWeb
            ? getMixpanelSearchLocationByRoute(routeName)
            : getMixpanelSearchLocationByFilter(filter),
          'Number of Results Returned': count,
        });
        OlyticsService.fire({
          SearchTerm: query,
          SearchType: searchType,
        });
      }, 2500),
    [],
  );

  useEffect(() => {
    if (page === 1) {
      const {
        generalSearch: { query, mainFilter },
      } = store.getState();
      dispatchAnalyticsEvents(mainFilter, count, query, routeName);
    }
  }, [page, routeName, dispatchAnalyticsEvents, count]);

  return (
    <>
      <View
        style={[
          styles.container,
          isTablet && styles.containerTablet,
          isLargeDesktop && styles.containerDesktopLarge,
        ]}
        testID="search-results-list"
      >
        {(page || isLoading) && (
          <InfiniteScrollList
            keyboardShouldPersistTaps="handled"
            data={data.length ? data : []}
            ListHeaderComponent={
              page
                ? () => (
                    <View style={[containerStyle, styles.header, isTablet && styles.headerTablet]}>
                      <MobileContentHeader>{i18n.t('search:results', { count })}</MobileContentHeader>
                      {!data.length && (
                        <StyledText style={[typography.body2, styles.noResults]}>
                          {i18n.t('search:noResults')}
                        </StyledText>
                      )}
                    </View>
                  )
                : null
            }
            renderItem={({ item, index }) =>
              !item ? null : (
                <View style={[styles.itemWrapper, containerStyle]} testID="search-results-item">
                  {renderCardComponent(item, index)}
                </View>
              )
            }
            keyExtractor={(item) => item.id}
            key={mainFilter}
            fetchNextPage={searchNextPage}
            loading={isLoading}
            allFetched={!!page && data.length === count}
            estimatedItemSize={FLASH_LIST_ESTIMATED_ITEM_SIZE}
          />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  itemWrapper: {
    overflow: 'hidden',
    borderColor: palette.grey2,
  },
  container: {
    ...ifWeb(
      {
        paddingVertical: CONTAINER_PADDING_V_MOBILE,
      },
      {
        flex: 1,
        paddingVertical: CONTAINER_PADDING_V_MOBILE_APP,
      },
    ),
  },
  containerTablet: {
    ...ifWeb({
      paddingVertical: CONTAINER_PADDING_V_TABLET,
    }),
  },
  containerDesktopLarge: {
    paddingVertical: CONTAINER_PADDING_V_DESKTOP_LARGE,
  },
  header: {
    marginBottom: 12,
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  headerTablet: {
    paddingHorizontal: 0,
  },
  noResults: {
    marginTop: 12,
  },
});
