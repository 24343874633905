import React from 'react';
import { StyleSheet, View } from 'react-native';

import { GradientShadow } from 'src/components/GradientShadow.web';
import { NavigationMenuItem } from 'src/navigation';
import { NAVBAR_HEIGHT, palette, zindex } from 'src/styles';

import { MenuMobile } from './MenuMobile';
import { MenuLogo } from '../../../../MenuLogo';

interface Props {
  items: NavigationMenuItem[];
}

export const NavigationMobile: React.FC<Props> = ({ items }) => (
  <View style={styles.container}>
    <GradientShadow style={styles.shadow} inverted />
    <MenuMobile items={items} />
    <MenuLogo />
  </View>
);

const styles = StyleSheet.create({
  container: {
    height: NAVBAR_HEIGHT,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: zindex.pageHeader,
    backgroundColor: palette.white,
  },
  shadow: {
    top: '75%',
  },
});
