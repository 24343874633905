import { Platform } from 'react-native';

import { EnvService } from 'src/services/EnvService';

interface BaseFireOptions {
  MonographData?: string;
  SearchTerm?: string;
  SearchType?: string;
  MonographDrugType?: string;
  PetOwnerGuidesDescription?: string;
  ClinicalBriefTitle?: string;
  ClinicalBriefClassification?: string;
  AlgorithmType?: string;
}

interface FireOptions extends BaseFireOptions {
  behaviorId: string;
}

interface Olytics {
  fire: (options: FireOptions) => void;
  confirm: (customerId: string) => void;
  load: (params: { oid: string }) => void;
}

declare global {
  interface Window {
    olytics?: Olytics;
  }
}

class OlyticsService {
  private isOlyticsEnabled: boolean;
  private behaviorId = '2894A1347578A0B';

  constructor() {
    const oid = EnvService.getEnv('OMEDA_CONNECTION_KEY');
    this.isOlyticsEnabled = Platform.OS === 'web' && !!oid;
    if (this.isOlyticsEnabled) {
      window.olytics?.load({ oid: oid! });
    }
  }

  identifyUser(customerId: string) {
    this.isOlyticsEnabled && window.olytics?.confirm(customerId);
  }

  fire(options: BaseFireOptions = {}) {
    this.isOlyticsEnabled &&
      window.olytics?.fire({
        ...options,
        behaviorId: this.behaviorId,
      });
  }
}

const instance = new OlyticsService();

export { instance as OlyticsService };
