import { RefObject } from 'react';

import { ContentType } from 'src/features/content/constants';

export interface Note {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  note: string;
}

// describes note assigned to a content like drug monograph or appendix (not a general note)
export interface ContentNote extends Note {
  contentId: string;
  contentType: ContentType;
  contentTitle: string;
  section: string;
}

type SavedNoteOnlyProperties = 'id' | 'createdAt' | 'updatedAt';

export type PartialNote = Omit<Note, SavedNoteOnlyProperties>;
export type PartialContentNote = Omit<ContentNote, SavedNoteOnlyProperties>;

export type ContentNotes = {
  [section: string]: ContentNote;
};

/**
 * Content note keys are in snake_case
 */
export type GetContentNotesResponse = {
  [section: string]: Record<string, string>;
};

interface NewGeneralNoteScreenParams {
  contentId: null;
}

interface NewContentNoteScreenParams {
  contentId: string;
  contentType: ContentType;
  contentTitle: string;
  section: string;
  sectionTitle: string;
}

export interface ExistingNoteScreenParams {
  id: string;
}

export type NoteScreenParams =
  | NewGeneralNoteScreenParams
  | NewContentNoteScreenParams
  | ExistingNoteScreenParams;

export enum NotesGroupType {
  Drug = 'monograph',
  Appendix = 'appendix',
  DDx = 'differential-diagnosis',
  DxTx = 'clinical-brief',
  General = 'general',
}

export interface ContentNotesGroup {
  contentId: string;
  contentTitle: string;
  notes: ContentNote[];
}

export type GeneralNotesGroup = Note[];

export type GroupedNotesListResponse = {
  [K in NotesGroupType]: K extends NotesGroupType.General
    ? GeneralNotesGroup
    : ContentNotesGroup[] | undefined;
};

export interface NoteEditorBaseProps {
  header?: string;
  title: string;
  date?: string;
  initialValue?: string;
  save: (params: { value: string; titleValue?: string }) => void;
  cancel?: () => void;
  remove?: (noteId?: string) => void;
  drugId?: string;
  titleEditable?: boolean;
  editorRef?: RefObject<EditorRef>;
}

export interface ContentNoteEditorProps extends NoteEditorBaseProps {
  contentTitle: string;
  contentId: string;
  contentType: ContentType;
  sectionId: string;
}

export interface GeneralNoteEditorProps extends NoteEditorBaseProps {}

export type NoteEditorProps = ContentNoteEditorProps | GeneralNoteEditorProps;

export type NotesUrlConfig = {
  contentId?: string | false;
  noteId?: string | false;
};

export type MutateNoteParams = {
  note: Note | PartialNote | ContentNote | PartialContentNote;
};

export type EditorRef = {
  blur(): void;
};

export interface ExisitingNoteCardProps {
  type: 'existing';
  note: Note | ContentNote;
}

export interface NewNoteCardProps {
  type: 'new';
  contentData: {
    sectionId: string;
    sectionTitle: string;
    contentId: string;
    contentType: ContentType;
    contentTitle: string;
  };
}
