import React, { useEffect, useRef, useState } from 'react';
import { LayoutRectangle, StyleSheet, View } from 'react-native';
import { Portal } from 'react-native-portalize';

import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { palette, TH_WIDTH_HORIZONTAL } from 'src/styles';

interface Props {
  isNodeFirst: boolean;
  columnsNumber: number;
}

export const Th: React.FC<React.PropsWithChildren<Props>> = ({ children, isNodeFirst, columnsNumber }) => {
  const [dimensions, setDimensions] = useState<LayoutRectangle>({ x: 0, y: 0, width: 0, height: 0 });
  const safeSetDimensions = useRef(setDimensions);
  const { isTablet } = useDeviceInfo();
  const isTableHorizontal = !isTablet;

  useEffect(() => {
    safeSetDimensions.current = setDimensions;
    return () => {
      safeSetDimensions.current = () => null;
    };
  }, [setDimensions]);

  const thStyles = StyleSheet.create({
    sticky: {
      position: 'absolute',
      zIndex: 1,
      width: dimensions.width,
      height: dimensions.height,
      top: dimensions.y,
      left: dimensions.x,
      display: dimensions.width ? 'flex' : 'none',
      pointerEvents: 'none',
    },
    placeholder: {
      opacity: isTableHorizontal ? 0 : 100,
    },
  });

  return (
    <>
      <View
        // sometimes onLayout is called after component has been unmounted  which results in error in the console when we try to call setState then
        onLayout={(event) => {
          safeSetDimensions.current(event.nativeEvent.layout);
        }}
        style={[
          styles.tdThCommonStyle,
          isTablet ? styles.tablet : styles.defaultPadding,
          (!isTableHorizontal || isNodeFirst) && styles.tdThTopRowStyle,
          thStyles.placeholder,
          { width: isTableHorizontal ? TH_WIDTH_HORIZONTAL : `${100 / columnsNumber}%` },
        ]}
      >
        {children}
      </View>
      {isTableHorizontal && (
        <Portal>
          {/* a copy of the original cell to be moved outside the scrolled content to make the column fixed on horizontal layout */}
          <View
            style={[
              styles.tdThCommonStyle,
              styles.defaultPadding,
              isNodeFirst && styles.tdThTopRowStyle,
              thStyles.sticky,
            ]}
          >
            {children}
          </View>
        </Portal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  tdThCommonStyle: {
    borderColor: palette.grey2,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    backgroundColor: palette.grey1,
  },
  tablet: {
    borderLeftWidth: 0,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  defaultPadding: {
    padding: 8,
    borderLeftWidth: 1,
  },
  tdThTopRowStyle: {
    borderTopWidth: 1,
  },
});
