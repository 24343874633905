import * as Sentry from '@sentry/react';

import { isProductionBuild } from 'src/helpers/common';
import { EnvService } from 'src/services/EnvService';

class SentryService {
  environment: string;
  dsn: string;
  enabled: boolean;

  constructor() {
    this.environment = EnvService.getEnv('ENVIRONMENT')!;
    this.dsn = EnvService.getEnv('SENTRY')!;
    this.enabled = !!this.dsn && isProductionBuild;
  }

  initialize() {
    if (this.enabled) {
      const applicationKey = EnvService.getEnv('REACT_APP_SENTRY_ORG') || '';
      Sentry.init({
        dsn: this.dsn,
        environment: this.environment,
        normalizeDepth: 10,
        ignoreErrors: [
          'Object Not Found Matching Id:', // caused by Microsoft Outlook SafeLink crawler
        ],
        integrations: [
          Sentry.thirdPartyErrorFilterIntegration({
            filterKeys: [applicationKey], // make sure it's the same as in sentryWebpackPlugin config in craco.config.js,
            behaviour: 'apply-tag-if-contains-third-party-frames',
          }),
        ],
      });
    }
  }

  setUser(email: string, id?: string) {
    if (this.enabled) {
      Sentry.setUser({ email, id });
    }
  }

  clearUser() {
    if (this.enabled) {
      Sentry.getCurrentScope().setUser(null);
    }
  }

  captureException(exception: any, extra?: Record<string, any>) {
    Sentry.captureException(exception, { extra });
  }

  captureMessage(message: string, extra?: Record<string, any>) {
    Sentry.captureMessage(message, { extra });
  }
}

const instance = new SentryService();

export { instance as SentryService };
