import axios from 'axios';
import dayjs from 'dayjs';
import { useState } from 'react';

import { showNotificationForErrors } from 'src/errorHandling/utils';
import { MixpanelService, MixpanelEvent, SentryService } from 'src/features/tracking';
import { isClinicalHandout, isDrugHandout } from 'src/helpers';
import { download, print } from 'src/helpers/pdf';

import { useHandoutsToShare } from './useHandoutsToShare';
import { generateHandoutsPdf } from '../api';
import { getHandoutsMixpanelLanguage } from '../helpers';
import { HandoutsLanguageType } from '../types';

type Action = 'print' | 'download';

interface Params {
  action: Action;
  onSuccess?(): void;
  language: HandoutsLanguageType;
}

export const useHandoutsPrintAndDownload = ({ onSuccess, action, language }: Params) => {
  const { handouts } = useHandoutsToShare();

  const [noteValue, setNoteValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const trackMixpanelAction = () => {
    if (handouts.length === 1) {
      const singleHandout = handouts[0];
      const titleKey = isDrugHandout(singleHandout) ? 'VMG Title' : 'Patient Guide Title';
      const idKey = isDrugHandout(singleHandout) ? 'VMG ID' : 'Patient Guide ID';
      MixpanelService.track(
        action === 'print' ? MixpanelEvent.HandoutPrinted : MixpanelEvent.HandoutDownloaded,
        {
          [titleKey]: singleHandout.title,
          [idKey]: singleHandout.id,
          'Custom Note': !!noteValue,
          'Handouts Language': getHandoutsMixpanelLanguage(language),
        },
      );
    } else {
      const drugHandouts = handouts.filter(isDrugHandout);
      const clinicalHandouts = handouts.filter(isClinicalHandout);
      MixpanelService.track(
        action === 'print'
          ? MixpanelEvent.MultipleHandoutsPrinted
          : MixpanelEvent.MultipleHandoutsDownloaded,
        {
          'VMG Titles': drugHandouts.map((item) => item.title),
          'VMG ID': drugHandouts.map((item) => item.id),
          'Patient Guide Titles': clinicalHandouts.map((item) => item.title),
          'Patient Guide ID': clinicalHandouts.map((item) => item.id),
          'Custom Note': !!noteValue,
          'Handouts Language': getHandoutsMixpanelLanguage(language),
        },
      );
    }
  };

  const generateAndPrint = async () => {
    try {
      setLoading(true);
      const pdf = await generateHandoutsPdf({
        guideIds: handouts.map((handout) => handout.id),
        additionalNotes: noteValue,
        language,
      });

      if (action === 'print') {
        await print(pdf);
      } else {
        const timestamp = dayjs().format('YYYY-MM-DD_HH-mm-ss');
        const filename = `Handout_${timestamp}.pdf`;
        await download(pdf, filename);
      }

      trackMixpanelAction();
      onSuccess?.();
    } catch (err: any) {
      if (!axios.isAxiosError(err)) {
        SentryService.captureException(err);
      }
      showNotificationForErrors(err?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  return {
    generateAndPrint,
    noteValue,
    setNoteValue,
    loading,
    selectedHandouts: handouts,
  };
};
