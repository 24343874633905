import { ActivityValue, RoleValue } from './types';

export const MIN_USER_AGE = 16; // miminum age that is accepted in user profile forms

export const STUDENTS_ACTIVITIES: ActivityValue[] = [
  'student-pharmacy',
  'student-veterinary-medical',
  'student-veterinary-technician',
];
export const VETERINARIAN_ROLES: RoleValue[] = [
  'associate',
  'multiple-practice-owner',
  'owner-partner',
  'other-veterinarian',
];

export const profileQueryKeys = {
  occupationDetails: ['occupation-details'] as const,
  personalInformation: ['personal-information'] as const,
};
