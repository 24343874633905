/*  eslint-disable import/no-cycle */
import {
  AsyncThunkAction,
  configureStore as configureToolkitStore,
  PayloadAction,
  ThunkAction,
  Dispatch,
  Store as RStore,
} from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';

import { isTest, isWeb } from 'src/helpers';

import { getStateSyncMiddleware } from './middlewares';
import rootReducer from './reducers';

declare global {
  interface Window {
    store?: RStore;
    persistor?: Persistor;
  }
}

export const CURRENT_STORE_VERSION = 1;

function configureStore() {
  const store = configureToolkitStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(...(isWeb ? [getStateSyncMiddleware()] : [])),
  });
  const persistor = persistStore(store as RStore);

  return { store, persistor };
}

export const { store, persistor } = configureStore();
export type AppDispatch = {
  <T>(action: AsyncThunkAction<T, any, any>): Promise<PayloadAction<any, string>>;
  <T>(action: ThunkAction<T, any, any, any>): T;
} & Dispatch;

if (isTest) {
  window.store = store;
  window.persistor = persistor;
}
