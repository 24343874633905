import { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { palette } from 'src/styles';

export const SideBarItems: React.FC<PropsWithChildren> = ({ children }) => {
  return <View style={styles.wrapper}>{children}</View>;
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: palette.white,
    borderRadius: 5,
    overflow: 'hidden',
  },
});
