import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { quickLinkButtonColors } from 'src/features/content/constants';
import { Link } from 'src/navigation/components';
import { palette } from 'src/styles';

import { BaseButtonProps } from '../Buttons/BaseButton';
import { SecondaryButton } from '../Buttons/SecondaryButton';

export interface QuickLink {
  key: string;
  content: string;
  onPress: () => void;
  linkProps?: Omit<React.ComponentProps<typeof Link>, 'children'>;
}

export interface Props {
  quickLinks?: QuickLink[];
  wrapperStyle?: StyleProp<ViewStyle>;
}

export const QuickLinks: React.FC<Props> = ({ quickLinks, wrapperStyle }) => {
  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      {quickLinks?.map((item, index) => {
        const buttonProps: BaseButtonProps = {
          variant: 'secondary',
          size: 'tiny',
          colors: quickLinkButtonColors,
          title: item.content,
          innerContainerStyle: styles.smallButton,
        };

        return (
          <View style={styles.element} key={item.key + index}>
            {item.linkProps ? (
              <Link {...item.linkProps} onPress={item.onPress} testID="quick-link">
                <SecondaryButton {...buttonProps} />
              </Link>
            ) : (
              <SecondaryButton {...buttonProps} disabled testID="quick-link" />
            )}
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginTop: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    zIndex: 1,
    alignSelf: 'flex-start',
  },
  smallButton: {
    backgroundColor: palette.transparent,
  },
  element: {
    marginRight: 8,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1,
  },
});
