import { useEffect, useState } from 'react';
import { Dimensions, ScaledSize } from 'react-native';

export const useFontScale = () => {
  const [fontScaleValue, setFontScale] = useState(() => Dimensions.get('window').fontScale);

  useEffect(() => {
    function handleChange({ window: { fontScale } }: { window: ScaledSize }) {
      if (fontScaleValue !== fontScale) {
        setFontScale(fontScale);
      }
    }

    const subscription = Dimensions.addEventListener('change', handleChange);
    handleChange({ window: Dimensions.get('window') });

    return () => {
      subscription.remove();
    };
  }, [fontScaleValue]);

  return fontScaleValue;
};
