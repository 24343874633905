import React, { Suspense, useEffect } from 'react';

import { FullScreenTemplate, Container, LoadingIndicator } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { OlyticsService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { SubscriptionDetails } from '../components/SubscriptionDetails';

type Props = ScreenProps<Route.SubscriptionDetails>;

const SubscriptionDetailsScreenContent: React.FC = () => {
  useEffect(() => {
    OlyticsService.fire();
  }, []);

  return (
    <FullScreenTemplate title={i18n.t('screens:subscriptionDetails')} showBackButton>
      <Container verticalPadding>
        <Suspense fallback={<LoadingIndicator />}>
          <SubscriptionDetails />
        </Suspense>
      </Container>
    </FullScreenTemplate>
  );
};

export const SubscriptionDetailsScreen: React.FC<Props> = ({ navigation, route }) => {
  const { isAdmin } = useUserInfo();

  useEffect(() => {
    if (isAdmin) {
      navigation.replace(Route.AdminDashboard, { ...(route.params || {}), tab: 'subscription-details' });
    }
  }, [isAdmin, navigation, route.params]);

  return isAdmin ? null : <SubscriptionDetailsScreenContent />;
};
