import { Trans } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { Select, StyledText } from 'src/components';
import { SelectOption } from 'src/constants/types';
import { RecurlyPlan } from 'src/features/subscription/types';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { EnvService } from 'src/services';
import { palette, typography } from 'src/styles';

import { useStudentsOrganizationsSearch } from '../hooks/queries';

interface Props {
  error?: string;
  touched?: boolean;
  readOnly?: boolean;
  onChange(organizationId: number, organizationName: string): void;
  country: string;
  plan: RecurlyPlan;
  organizationId: number;
  organizationName: string;
  testID?: string;
}

const REQUEST_LINK_BASE = 'https://offers.sheerid.com/sheerid/add-school-request/?locale=en-US&pid=';

export const StudentUniversitySelect: React.FC<Props> = ({
  error,
  touched,
  readOnly,
  onChange,
  country,
  plan,
  organizationId,
  organizationName,
}) => {
  const pid = EnvService.getEnv(
    plan === RecurlyPlan.PHARMACY_BASIC_STUDENT ? 'SHEERID_PROGRAM_ID' : 'SHEERID_PROGRAM_ID_PRO',
  );
  const requestLink = pid ? REQUEST_LINK_BASE + pid : '';

  const [inputValue, setInputValue] = useDebouncedValue('', 400);
  const { organizationsOptions, isFetching } = useStudentsOrganizationsSearch(plan, inputValue, country);

  const handleChange = (option: SelectOption) => {
    onChange(parseInt(option.value!), option.label);
  };

  if (!country) {
    return null;
  }

  return (
    <>
      <Select
        value={organizationId}
        label={i18n.t('subscriptionProcess:university')}
        onChange={handleChange}
        error={error}
        touched={touched}
        readOnly={readOnly}
        options={organizationsOptions}
        searchType="external"
        onInputChange={setInputValue}
        setDisplayedValue={() => organizationName}
        isLoading={isFetching}
        testID="university-select"
      />
      {requestLink && (
        <Trans
          i18nKey="subscriptionProcess:dontSeeYourSchool"
          components={{
            p: <StyledText style={[typography.body1, styles.descriptionText]} />,
            requestLink: <Link to={requestLink} external style={styles.requestLink} wrapper="text" />,
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  descriptionText: {
    marginTop: 4,
    color: palette.grey5,
  },
  requestLink: {
    color: palette.grey5,
    textDecorationLine: 'underline',
  },
});
