import { useSuspenseQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { useMemo } from 'react';

import { fetchFirstLetters } from 'src/api/content';
import {
  AlgorithmConfig,
  AppendixConfig,
  ClinicalHandoutConfig,
  DDxConfig,
  DrugConfig,
  DrugHandoutConfig,
  DxTxConfig,
} from 'src/features/content/config';
import { ContentType, ContentListType } from 'src/features/content/constants';
import { LettersResponse } from 'src/features/content/types';
import { alphabet } from 'src/helpers';

const contentTypesMap: {
  [contentType in ContentListType]: readonly ContentType[];
} = {
  [ContentListType.Algorithms]: AlgorithmConfig.contentTypes,
  [ContentListType.Appendix]: AppendixConfig.contentTypes,
  [ContentListType.DxTx]: DxTxConfig.contentTypes,
  [ContentListType.Drugs]: DrugConfig.contentTypes,
  [ContentListType.DrugHandouts]: DrugHandoutConfig.contentTypes,
  [ContentListType.ClinicalHandouts]: ClinicalHandoutConfig.contentTypes,
  [ContentListType.DDx]: DDxConfig.contentTypes,
};

const prepareDataForContentType = (data: LettersResponse, contentListType: ContentListType) => {
  const contentTypes = contentTypesMap[contentListType];
  const allLetters = R.flatten(R.props(contentTypes, data));
  return alphabet.map((letter: string) => ({
    letter: letter.toUpperCase(),
    hasItems: allLetters.includes(letter.toUpperCase()),
  }));
};

export const useContentLetters = (contentListType: ContentListType) => {
  const { data, ...query } = useSuspenseQuery({
    queryKey: ['letters'],
    queryFn: fetchFirstLetters,
  });

  const dataForThisContentType = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }
    return prepareDataForContentType(data, contentListType);
  }, [data, contentListType]);

  return { ...query, data: dataForThisContentType };
};
