import { useState } from 'react';
import { isTablet as checkIsTablet } from 'react-native-device-info';
import { useMediaQuery } from 'react-responsive';

import { breakpoints, checkIsLandscape } from 'src/styles';

export function useDeviceInfo() {
  const [isLandscape, setIsLandscape] = useState<boolean>(checkIsLandscape());
  const isTabletApp = checkIsTablet();
  const updateOrientation = () => setIsLandscape(checkIsLandscape());

  const isSmallestMobile = useMediaQuery({ maxWidth: breakpoints.smallMobile });
  const isSmallMobile = useMediaQuery({ maxWidth: breakpoints.mobile });
  const isMobile = useMediaQuery({ minWidth: breakpoints.mobile });
  const isTablet = useMediaQuery({ minWidth: breakpoints.tablet });
  const isDesktop = useMediaQuery({ minWidth: breakpoints.desktop });
  const isLargeDesktop = useMediaQuery({ minWidth: breakpoints.desktopLarge });
  const isHugeDesktop = useMediaQuery({ minWidth: breakpoints.desktopHuge });
  const isBigTabletApp = isDesktop && isTabletApp;

  return {
    isSmallestMobile,
    isSmallMobile,
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
    isHugeDesktop,
    isBigTabletApp,
    isTabletApp,
    isLandscape,
    updateOrientation,
  };
}
