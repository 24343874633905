import React, { useEffect } from 'react';

import { FullScreenTemplate } from 'src/components';
import { TokenValidationErrorScreen } from 'src/features/auth/components/TokenValidationErrorScreen';
import { TokenValidationError } from 'src/features/auth/enums';
import { Route, ScreenProps } from 'src/navigation';

import { useEmailChangeTokenValidation } from '../hooks';

type Props = ScreenProps<Route.EmailChangeLandingScreen>;

export const EmailChangeLandingScreen: React.FC<Props> = ({ route, navigation }) => {
  const { token, userId, newEmail } = route.params;
  const { data: isTokenValidated, error } = useEmailChangeTokenValidation({ token, userId, newEmail });

  useEffect(() => {
    if (isTokenValidated) {
      navigation.navigate(Route.Home);
    }
  }, [isTokenValidated, navigation]);

  const tokenValidationError = (() => {
    if (error) {
      const status = (error as any).response?.status;
      return status === 404 ? TokenValidationError.NotFound : TokenValidationError.Expired;
    }
  })();

  if (tokenValidationError) {
    return <TokenValidationErrorScreen error={tokenValidationError} descriptor="changeEmail" />;
  }

  return <FullScreenTemplate isLoading />;
};
