import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { palette } from 'src/styles';

import { IconButton } from '../IconButton';
import { StyledText } from '../StyledText';

interface Props {
  email: string;
  handleRemoveButtonPress: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
}

export const EmailChip: React.FC<Props> = ({ email, handleRemoveButtonPress, style, testID }) => (
  <View style={[styles.wrapper, style]} testID={testID}>
    <StyledText style={styles.text}>{email}</StyledText>
    <IconButton
      name="close"
      onPress={handleRemoveButtonPress}
      containerStyle={styles.icon}
      color={palette.grey5}
      width={10}
    />
  </View>
);

const styles = StyleSheet.create({
  wrapper: {
    height: 30,
    borderRadius: 28,
    paddingLeft: 12,
    paddingRight: 4,
    borderWidth: 1,
    borderColor: palette.grey2,
    backgroundColor: palette.grey1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: palette.grey7,
    fontSize: 18,
  },
  icon: {
    padding: 8,
  },
});
