import { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import type { DropdownItem } from 'src/constants/types';
import { useOutsideClickDetector } from 'src/hooks';
import { ifWeb, palette } from 'src/styles';

import { Dropdown } from './Dropdown';
import { DropdownWithHeader as BaseComponent } from './DropdownWithHeader';
import { Icon } from './Icon/Icon';
import { MobileContentHeader } from './MobileContentHeader';
import { Pressable } from './Pressable';

interface Props<T = any> extends React.ComponentProps<typeof BaseComponent<T>> {}

export const DropdownWithHeader = <T,>({
  getTitle,
  items,
  value,
  onChange,
  disabled,
  getTitleStyle,
  style,
  dropdownStyle,
  arrowColor = (isActive) => (isActive ? palette.blue : palette.navy),
}: Props<T>) => {
  const [isOpen, setDropdown] = useState(false);
  const dropdownRef = useRef<View>(null);

  useOutsideClickDetector(dropdownRef, () => {
    setDropdown(false);
  });

  useEffect(() => {
    if (isOpen) {
      const element = dropdownRef.current as unknown as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isOpen]);

  const activeItem = items.find((item) => item.value === value);

  const handlePress = () => {
    setDropdown((state) => !state);
  };

  const handleChange = (item: DropdownItem<T>) => {
    setDropdown(false);
    onChange?.(item);
  };

  const getArrowColor = (isActive: boolean) =>
    typeof arrowColor === 'string' ? arrowColor : arrowColor(isActive);

  return (
    <View style={[styles.wrapper, style]} testID="current-filter">
      <Pressable onPress={handlePress} disabled={disabled}>
        {(isHovered, isPressed) => (
          <View style={styles.header}>
            <MobileContentHeader
              style={[
                (isHovered || isPressed || isOpen) && styles.titleHover,
                getTitleStyle && getTitleStyle(activeItem),
              ]}
            >
              {getTitle(activeItem)}
            </MobileContentHeader>
            <Icon
              name="chevron-down"
              color={getArrowColor(isHovered || isPressed || isOpen)}
              width={10}
              style={[styles.icon, isOpen && styles.iconOpen]}
            />
          </View>
        )}
      </Pressable>
      {isOpen && (
        <View ref={dropdownRef} style={styles.dropdownWrapper}>
          <Dropdown
            items={items}
            value={value}
            onChange={handleChange}
            style={[styles.dropdown, dropdownStyle]}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    zIndex: 'auto',
    justifyContent: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleHover: {
    color: palette.blue,
  },
  dropdownWrapper: {
    position: 'absolute',
    top: '100%',
    zIndex: 3,
  },
  dropdown: {
    width: '100%',
    ...ifWeb({
      boxShadow: '0 0 15px 0 rgba(23, 40, 94, 0.12)',
    }),
  },
  icon: {
    transitionProperty: 'transform',
    marginLeft: 8,
  },
  iconOpen: {
    transform: [{ rotate: '180deg' }],
  },
});
