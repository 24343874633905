import { detect } from 'detect-browser';
import { Platform } from 'react-native';

import { i18n } from 'src/locale';

import { SystemInfo } from './types';

// We can expand this helper for other browser names based on https://github.com/DamonOehlman/detect-browser/blob/59abf2506e3999398f395a5ebd214de0d6944014/src/index.ts#L131
const mapBrowser = (name: string) => {
  switch (name) {
    case 'firefox':
    case 'fxios':
      return 'Firefox';
    case 'chrome':
    case 'crios':
      return 'Chrome';
    case 'safari':
    case 'ios':
      return 'Safari';
    case 'edge':
    case 'edge-ios':
    case 'edge-chromium':
      return 'Edge';
    case 'ie':
      return 'Internet Explorer';
    case 'opera':
      return 'Opera';
    default:
      return i18n.t('sessionsLimit:unknownBrowser');
  }
};

export const getSystemInfo = (): SystemInfo => {
  if (!(Platform.OS === 'web')) {
    return { systemName: Platform.OS === 'android' ? 'Android OS' : 'iOS', browserName: 'App' };
  }
  const browser = detect();
  return {
    systemName: browser?.os || i18n.t('sessionsLimit:unknownOS'),
    browserName: mapBrowser(browser?.name || ''),
  };
};
