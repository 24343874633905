import { showMessage, hideMessage } from 'react-native-flash-message';

import { markDownToPlainText } from 'src/helpers/functions';
import { i18n } from 'src/locale';

import { isWeb } from './common';

type NotificationType = 'error' | 'success';

export interface NotificationParams {
  /** Web version allows passing message in Markdown */
  title?: string;
  type: NotificationType;
  duration?: number;
  autoHide?: boolean;
}

export const defaultTitles: Record<NotificationType, string> = {
  error: i18n.t('notifications:error'),
  success: i18n.t('notifications:success'),
};

export const showNotification = ({ type, title, duration = 2500, autoHide = true }: NotificationParams) => {
  const parsedTitle = !title ? defaultTitles[type] : !isWeb ? markDownToPlainText(title) : title;

  showMessage({
    message: parsedTitle,
    type: type === 'error' ? 'danger' : 'success',
    duration,
    autoHide,
    icon: autoHide ? 'none' : 'auto',
  });
};

export const closeNotification = hideMessage;
