import { useEffect } from 'react';

import { ContentListScreen, ProInfoBanner } from 'src/components';
import { ClinicalHandoutConfig } from 'src/features/content/config';
import { ContentListType } from 'src/features/content/constants';
import { SearchContentTypeFilter } from 'src/features/search/enums';
import { OlyticsService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { HandoutCard } from '../components';

type Props = ScreenProps<Route.ClinicalHandoutsList>;

export const ClinicalHandoutsListScreen: React.FC<Props> = ({ route, navigation }) => {
  useEffect(() => {
    OlyticsService.fire();
  }, []);

  return (
    <ContentListScreen
      route={route}
      navigation={navigation}
      title={i18n.t('clinicalHandouts:header')}
      searchFilter={SearchContentTypeFilter.Handout}
      contentListType={ContentListType.ClinicalHandouts}
      ContentCardComponent={HandoutCard}
    >
      <ProInfoBanner contentTypes={ClinicalHandoutConfig.contentTypes} />
    </ContentListScreen>
  );
};
