import React from 'react';
import { StyleSheet } from 'react-native';

import { palette } from 'src/styles';

import { BaseButton, BaseButtonProps } from './BaseButton';

const defaultColors = {
  default: { primary: palette.blue, content: palette.blue },
  active: { primary: palette.navy, content: palette.navy },
  disabled: { primary: palette.grey2, content: palette.grey2 },
};

export const SecondaryButton: React.FC<BaseButtonProps> = (props) => {
  const colors = props.colors ?? defaultColors;

  return (
    <BaseButton
      {...props}
      colors={colors}
      innerContainerStyle={[props.innerContainerStyle, props.size === 'tiny' && styles.buttonSecondaryTiny]}
      titleWrapperStyle={[props.titleWrapperStyle]}
      hoverStyle={{ borderColor: colors?.active.primary }}
      disabledSyle={{ borderColor: colors?.disabled.primary }}
      secondaryInnerContainerStyle={[styles.buttonSecondary, { borderColor: colors?.default.primary }]}
    />
  );
};
export const styles = StyleSheet.create({
  buttonSecondary: {
    borderWidth: 2,
    backgroundColor: palette.transparent,
    marginBottom: 0,
    paddingTop: 0,
  },
  buttonSecondaryTiny: {
    borderWidth: 1,
  },
});
