import { client } from 'src/api/client';

import type { DxTxImageFetchResponse } from './types';

export const fetchDxTxImage = async (id: string) => {
  const response = await client.request<DxTxImageFetchResponse>({
    method: 'GET',
    url: `/contents/clinical-brief-image/${id}/`,
  });

  return response.data;
};
