import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { queryClient } from 'src/api/queryClient';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { GroupRole } from 'src/features/auth/enums';
import { showNotification } from 'src/helpers';

import {
  cancelGroupInvitation,
  editGroupMemberRole,
  editGroupName,
  editPracticeLocation,
  fetchGroupInfo,
  fetchNumberOfLicences,
  fetchPracticeLocation,
  inviteGroupMembers,
  removeGroupMember,
  resendGroupInvitation,
} from '../api';
import {
  ADMIN_GROUP_INFO_QUERY_KEY,
  LICENCES_INFO_QUERY_KEY,
  PRACTICE_LOCATION_QUERY_KEY,
} from '../constants';
import {
  isMixedGroupLicencesResponse,
  isRegularGroupLicencesResponse,
  parsePracticeLocationResponse,
} from '../helpers';
import {
  AdminGroupInfo,
  EditGroupRolePayload,
  GroupMember,
  GroupMemberStatus,
  MixedGroupLicences,
  RegularGroupLicences,
} from '../types';

export const useLicencesInfo = () => {
  const queryData = useQuery({
    queryKey: [LICENCES_INFO_QUERY_KEY],
    queryFn: async () => {
      const data = await fetchNumberOfLicences();
      if (isRegularGroupLicencesResponse(data)) {
        return {
          isMixedGroup: false,
          licences: {
            available: data.numberOfAvailableLicences,
            total: data.numberOfLicences,
          },
          areLicencesAvailable: data.numberOfAvailableLicences > 0,
        } as RegularGroupLicences;
      }

      if (isMixedGroupLicencesResponse(data)) {
        return {
          isMixedGroup: true,
          licences: {
            basic: {
              available: data.numberOfAvailableBasicLicences,
              total: data.numberOfBasicLicences,
            },
            pro: {
              available: data.numberOfAvailableProLicences,
              total: data.numberOfProLicences,
            },
          },
          areLicencesAvailable:
            data.numberOfAvailableBasicLicences > 0 || data.numberOfAvailableProLicences > 0,
        } as MixedGroupLicences;
      }
    },
    refetchOnMount: true,
  });

  return {
    ...queryData,
  };
};

const getSupportStaffData = (members: GroupMember[]) => {
  const supportStaff = members.find((member) => member.role === GroupRole.SUPPORT_STAFF);

  return {
    hasSupportStaffRole: !!supportStaff,
    supportStaffName: supportStaff?.fullName || null,
  };
};

export const useAdminGroupInfo = () => {
  return useQuery({
    queryKey: [ADMIN_GROUP_INFO_QUERY_KEY],
    queryFn: async () => {
      const response = await fetchGroupInfo();
      const {
        members: { results },
        name,
      } = response;

      return {
        ...getSupportStaffData(results),
        name,
        members: results,
      } as AdminGroupInfo;
    },
  });
};

const updateQueriesAfterGroupMemberRemoval = (memberId: string) => {
  queryClient.invalidateQueries({ queryKey: [LICENCES_INFO_QUERY_KEY] });
  queryClient.setQueryData([ADMIN_GROUP_INFO_QUERY_KEY], (data: undefined | AdminGroupInfo) => {
    if (!data) return;

    const members = data.members.filter((member) => member.id !== memberId);

    return {
      ...data,
      ...getSupportStaffData(members),
      members,
    };
  });
};

export const useResendGroupInvitation = (memberId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => resendGroupInvitation(memberId),
    onSuccess: (data) => {
      const { id } = data;
      showNotification({ type: 'success' });
      queryClient.setQueryData([ADMIN_GROUP_INFO_QUERY_KEY], (data: AdminGroupInfo | undefined) =>
        data
          ? {
              ...data,
              members: data.members.map((member) =>
                member.id === id ? { ...member, status: GroupMemberStatus.Pending } : member,
              ),
            }
          : undefined,
      );
    },
    onError: (err: any) => {
      const { detail } = getMessagesFromErrorResponse<{ detail: string }>(err);
      showNotification({ type: 'error', title: detail });
    },
  });
};

export const useCancelGroupInvitation = (memberId: string) => {
  return useMutation({
    mutationFn: () => cancelGroupInvitation(memberId),
    onSuccess: (data) => {
      const { membershipId: id } = data;
      showNotification({ type: 'success' });
      updateQueriesAfterGroupMemberRemoval(id);
    },
    onError: (err: any) => {
      const { detail } = getMessagesFromErrorResponse<{ detail: string }>(err);
      showNotification({ type: 'error', title: detail });
    },
  });
};

export const useEditGroupRole = (memberId: string) => {
  return useMutation({
    mutationFn: (params: Omit<EditGroupRolePayload, 'memberId'>) =>
      editGroupMemberRole({ ...params, memberId }),
    onSuccess: () => {
      showNotification({ type: 'success' });
      queryClient.invalidateQueries({ queryKey: [LICENCES_INFO_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_GROUP_INFO_QUERY_KEY] });
    },
  });
};

export const useRemoveGroupMember = (memberId: string) => {
  return useMutation({
    mutationFn: () => removeGroupMember(memberId),
    onSuccess: () => {
      showNotification({ type: 'success' });
      updateQueriesAfterGroupMemberRemoval(memberId);
    },
    onError: (err: any) => {
      const { detail } = getMessagesFromErrorResponse<{ detail: string }>(err);
      showNotification({ type: 'error', title: detail });
    },
  });
};

export const useInviteGroupMembers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: inviteGroupMembers,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_GROUP_INFO_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [LICENCES_INFO_QUERY_KEY] });
    },
  });
};

export const useEditGroupName = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editGroupName,
    onSuccess: ({ name }) => {
      queryClient.setQueryData([ADMIN_GROUP_INFO_QUERY_KEY], (data: AdminGroupInfo | undefined) =>
        data
          ? {
              ...data,
              name,
            }
          : undefined,
      );
    },
  });
};

export const usePracticeLocation = () => {
  return useQuery({
    queryKey: [PRACTICE_LOCATION_QUERY_KEY],
    queryFn: async () => {
      const data = await fetchPracticeLocation();
      return parsePracticeLocationResponse(data);
    },
  });
};

export const useEditPracticeLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editPracticeLocation,
    onSuccess: (data) => {
      queryClient.setQueryData([PRACTICE_LOCATION_QUERY_KEY], parsePracticeLocationResponse(data));
      showNotification({ type: 'success' });
    },
  });
};
