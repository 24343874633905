import React from 'react';
import { StyleSheet, View } from 'react-native';

import { BoxWithHeader, Icon, StyledText } from 'src/components';
import {
  Item,
  ButtonsWrapper,
  Form,
  Row,
  SubComponents,
  MainSectionProps,
  Footer,
} from 'src/components/Forms';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { palette, typography } from 'src/styles';

interface Props extends MainSectionProps {
  active?: boolean;
  checked?: boolean;
  stepNo?: number;
  testID?: string;
}

export const SubscriptionSection: React.FC<React.PropsWithChildren<Props>> & SubComponents = ({
  title,
  isLoading,
  style,
  children,
  active,
  checked,
  stepNo,
  testID,
  subtitleContent,
}) => {
  const renderContent = () => {
    if (isLoading) return <LoadingIndicator />;
    if (active) return children;
  };

  const number = stepNo !== undefined ? `${stepNo}. ` : '';

  return (
    <BoxWithHeader
      testID={active ? testID + '-expanded' : testID + '-collapsed'}
      title={
        <View style={styles.titleWrapper}>
          <StyledText
            style={[typography.body5Bold, styles.title, !active && styles.titleDisabled]}
          >{`${number}${title}`}</StyledText>
          {checked && (
            <View style={styles.iconWrapper}>
              <Icon name="checkmark" width={16} style={styles.icon} />
            </View>
          )}
        </View>
      }
      style={[styles.box, style]}
      disabled={!active}
      subtitleContent={subtitleContent}
    >
      {renderContent()}
    </BoxWithHeader>
  );
};

SubscriptionSection.Row = Row;
SubscriptionSection.Form = Form;
SubscriptionSection.Item = Item;
SubscriptionSection.Footer = Footer;
SubscriptionSection.ButtonsWrapper = ButtonsWrapper;

const styles = StyleSheet.create({
  box: {
    marginBottom: 40,
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: palette.navy,
  },
  titleDisabled: {
    color: palette.grey2,
  },
  iconWrapper: {
    borderLeftWidth: 1,
    borderLeftColor: palette.grey2,
    marginLeft: 20,
    height: 28,
    justifyContent: 'center',
  },
  icon: {
    marginLeft: 20,
  },
});
