import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useIsSignedIn } from 'src/features/auth/hooks';

import { sendUserNotificationAction, fetchUserNotifications } from '../api';
import { userNotificationsQueryKeys } from '../constants';
import { UserNotificationAction, UserNotificationActionDetails, UserNotificationsResponse } from '../types';

export const useUserNotificationsList = () => {
  const isSignedIn = useIsSignedIn();

  return useQuery({
    queryKey: userNotificationsQueryKeys.list,
    queryFn: fetchUserNotifications,
    enabled: isSignedIn,
    refetchInterval: 60 * 60 * 1000,
  });
};

export const useUserNotificationAction = (id: number, action: UserNotificationAction) => {
  const queryClient = useQueryClient();

  const removeNotification = () => {
    queryClient.setQueryData<UserNotificationsResponse>(userNotificationsQueryKeys.list, (data) =>
      data
        ? {
            notifications: data.notifications.filter((item) => item.id !== id),
          }
        : undefined,
    );
  };

  return useMutation({
    // void instead of an optional parm to be able to call mutation function with no arguments
    mutationFn: (details: UserNotificationActionDetails | void) =>
      sendUserNotificationAction({
        id,
        action,
        details: details!,
      }),
    ...(action === 'submit'
      ? {
          onSuccess: removeNotification,
        }
      : {
          onMutate: removeNotification,
        }),
  });
};
