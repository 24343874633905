import { useQuery } from '@tanstack/react-query';

import { useContentItemQuery } from 'src/hooks/queries/contentItems';

import { findDrugByName } from '../api';
import type { DrugDetails } from '../types';

export const useDrugItem = (id: string) =>
  useContentItemQuery<DrugDetails>({
    key: ['drug', id],
    url: `/contents/monograph/${id}/`,
  });

export const useDrugByName = (name: string) => {
  return useQuery({
    queryKey: ['drug-by-name', name],
    queryFn: () => findDrugByName(name),
    enabled: !!name,
  });
};
