import axios from 'axios';
import * as R from 'ramda';

import { isServerIssueError } from 'src/api/utils';
import { ApiError } from 'src/constants/types';
import { showNotification } from 'src/helpers';
import { i18n } from 'src/locale';

export function getErrorMessageForApiError(apiError: ApiError | ApiError[], key?: string): string {
  if (Array.isArray(apiError)) {
    return apiError.map((error: ApiError) => getErrorMessageForApiError(error, key)).join('\n');
  }

  const { message, code } = apiError;
  if (key) {
    const messageKey = `validation:${key}:${code}`;
    if (i18n.exists(messageKey)) return i18n.t(messageKey);
  }
  const messageKey = `validation:${code}`;
  return i18n.exists(messageKey) ? i18n.t(messageKey) : message;
}

export const getMessagesFromErrorResponse = <T extends Record<string, any>>(
  error: any,
  nestedObjectName?: string,
): Partial<T & { detail?: string }> => {
  const base: Partial<T> = {};
  if (axios.isAxiosError(error) && error.response?.data) {
    const errors = nestedObjectName
      ? error.response.data[nestedObjectName]
      : (error.response.data as Record<string, ApiError>);
    return R.mapObjIndexed(getErrorMessageForApiError, errors) as Partial<T>;
  }

  if (isServerIssueError(error)) {
    /** 'detail' is often used as general error returned from backend
     * and therefore displayed in toast message */
    return {
      ...base,
      detail: i18n.t('errors:serverIssueMessage'),
    };
  }

  return base;
};

export const showNotificationForErrors = (error: Record<string, ApiError | ApiError[]>): void => {
  if (!error) return showNotification({ type: 'error' });
  const errorMessages = R.mapObjIndexed(getErrorMessageForApiError, error);
  return showNotification({ type: 'error', title: mergeErrorMessages(errorMessages) });
};

const mergeErrorMessages: (errorMessages: Record<string, string>) => string = R.pipe(
  R.values,
  R.join('\n'),
);
