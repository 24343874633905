import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { palette, typography } from 'src/styles';

import { StyledText } from './StyledText';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export const Li: React.FC<React.PropsWithChildren<Props>> = ({ style, children }) => {
  return (
    <View style={[styles.li, style]}>
      <View style={styles.marker} />
      <StyledText style={[typography.body2]}>{children}</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  li: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  marker: {
    width: 4,
    height: 4,
    backgroundColor: palette.blue,
    borderRadius: 2,
    top: 8,
    marginRight: 8,
  },
});
