import * as R from 'ramda';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUserInfo } from 'src/features/auth/hooks';
import { isWeb } from 'src/helpers';
import { ApplicationState } from 'src/state';

import { searchFiltersConfig } from '../constants';
import { SearchContentTypeFilter } from '../enums';
import { getContentTypeSearchParam, getPlaceholderByFilter } from '../helpers';
import * as SearchActions from '../state';
import type { FilterConfigItem, SearchFilterItem } from '../types';

export const useSearchParams = () => {
  const { canDisplayContentType, permissions, hasAccessToContentType } = useUserInfo();

  const { query, mainFilter } = useSelector((state: ApplicationState) => state.generalSearch);
  const dispatch = useDispatch();

  const contentTypes = useMemo(() => {
    const contentTypes = getContentTypeSearchParam(mainFilter);
    const filterFunction = isWeb ? hasAccessToContentType : canDisplayContentType;
    return contentTypes.filter(filterFunction);
  }, [mainFilter, canDisplayContentType, hasAccessToContentType]);

  const isSearchFilterAlwaysAvailable = useCallback(
    (item: FilterConfigItem) => {
      if (!item) return false;

      return !item.permission || [item.permission].flat().every((permission) => permissions[permission]);
    },
    [permissions],
  );

  const activeFilterData = useMemo(() => searchFiltersConfig[mainFilter], [mainFilter]);

  const availableSearchFilters = useMemo(() => {
    const filtersWithIds = R.mapObjIndexed((item: FilterConfigItem, id: SearchContentTypeFilter) => {
      const isActive = id === mainFilter;

      return {
        ...item,
        id,
        isActive,
        isVisible:
          isSearchFilterAlwaysAvailable(item) ||
          (permissions.canSearchForUnavailableContentTypes && isActive),
      };
    }, searchFiltersConfig);

    const filteredItems = R.pickBy<
      Record<SearchContentTypeFilter, SearchFilterItem>,
      Record<SearchContentTypeFilter, SearchFilterItem>
    >((item: SearchFilterItem) => {
      return isSearchFilterAlwaysAvailable(item) || permissions.canSearchForUnavailableContentTypes;
    }, filtersWithIds);

    return R.values(filteredItems);
  }, [mainFilter, isSearchFilterAlwaysAvailable, permissions]);

  const displayedSearchFilters = useMemo(() => {
    return availableSearchFilters.filter((item) => item.isVisible);
  }, [availableSearchFilters]);

  const setMainFilter = useCallback(
    (filter: SearchContentTypeFilter) => {
      if (filter !== mainFilter) {
        dispatch(SearchActions.setMainFilter(filter));
      }
    },
    [dispatch, mainFilter],
  );

  const placeholder = useMemo(
    () => getPlaceholderByFilter(mainFilter, permissions),
    [mainFilter, permissions],
  );

  return {
    availableSearchFilters,
    displayedSearchFilters,
    activeFilterData,
    mainFilter,
    setMainFilter,
    query,
    contentTypes,
    placeholder,
  };
};
