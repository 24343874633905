import { StyleSheet, View } from 'react-native';

import { Icon, StyledText } from 'src/components';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { getClassificationData } from '../../helpers';
import { DICClassificationKey, DICDrug, DrugInteraction } from '../../types';

interface DrugLinkProps {
  drug: DICDrug;
  testID: string;
}

const DrugLink: React.FC<DrugLinkProps> = ({ drug, testID }) => (
  <Link
    testID={testID}
    to={{ params: { id: drug.id, source: 'interaction-checker' }, route: Route.DrugItem }}
    wrapperStyle={styles.linkWrapper}
  >
    <StyledText style={styles.title}>{drug.entryTitle}</StyledText>
  </Link>
);

interface Props {
  interaction: DrugInteraction;
  bottomBorder?: boolean;
}

export const InteractionCard: React.FC<Props> = ({ interaction, bottomBorder }) => {
  const { drugs, summary, alerts, classificationKey } = interaction;
  const { description } = getClassificationData(classificationKey);

  const summaryToDisplay = classificationKey === DICClassificationKey.NoData ? description : summary;

  return (
    <View
      style={[styles.wrapper, bottomBorder && styles.wrapperWithBottomBorder]}
      testID="dic-interaction-box"
    >
      <View style={styles.titleWrapper} testID="dic-interaction-drugs">
        <DrugLink drug={drugs[0]} testID="dic-drug-1" />
        <View style={styles.plusWrapper}>
          <Icon name="plus" width={10} color={palette.white} />
        </View>
        <DrugLink drug={drugs[1]} testID="dic-drug-2" />
      </View>
      {!!alerts && (
        <View style={styles.alertsWrapper} testID="dic-alert">
          <Icon color={palette.red} name="alert-triangle" />
          <StyledText style={styles.alertText}>{alerts}</StyledText>
        </View>
      )}
      {!!summaryToDisplay && (
        <StyledText style={styles.summary} testID="dic-summary">
          {summaryToDisplay}
        </StyledText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    padding: 16,
  },
  wrapperWithBottomBorder: {
    borderBottomWidth: 1,
    borderColor: palette.grey2,
  },
  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  linkWrapper: {
    maxWidth: '100%',
  },
  title: {
    color: palette.blue,
    paddingRight: 0,
    ...typography.body3SemiBold,
  },
  plusWrapper: {
    width: 18,
    height: 18,
    borderRadius: 9,
    backgroundColor: palette.grey6,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 8,
  },
  alertsWrapper: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertText: {
    marginLeft: 10,
    ...typography.body2Bold,
  },
  summary: {
    marginTop: 8,
    ...typography.body2,
  },
});
