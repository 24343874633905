import { PropsWithChildren, createContext, useCallback, useContext, useEffect } from 'react';

import { useIsSignedIn, useUserInfo } from 'src/features/auth/hooks';
import { getBasePlanSetup, getRecurlyStudentPlan, isStudentRole } from 'src/features/subscription/helpers';
import { RecurlyPlan, RecurlyStudentPlan, Role, StudentRole } from 'src/features/subscription/types';
import {
  MixpanelEvent,
  MixpanelService,
  MixpanelStudentVerificationParams,
  getMixpanelStudentVerificationStatus,
} from 'src/features/tracking';
import { useRoute } from 'src/navigation/hooks';

import { useCurrentUserStudentVerification, useStudentVerificationDetails } from '../hooks/queries';
import { useStudentVerificationReducer } from '../state/studentVerificationState';
import type { StudentDataSubmitParams, StudentVerificationParams, StudentVerificationStep } from '../types';

type ProcessType = 'new-subscription' | 'reverification';

interface ContetextValue {
  setRole(role: Role): void;
  currentStep?: StudentVerificationStep;
  verificationId?: string;
  plan: RecurlyStudentPlan | null;
  studentData?: StudentVerificationParams | null;
  ssoLoginUrl?: string | null;
  isStudentVerified: boolean;
  isUserRedirectedFromEmailNotification: boolean;
  isFetching: boolean;
  processType: ProcessType;

  onSSOExternalLoginSitePress(): void;
  onSSOProcessSuccess(): void;
  onSSOProcessSkip(): void;
  onDocumentsUpload(): void;
  onDocumentsUploadSuccess(): void;
  onDocumentsUploadFailure(): void;
  onDocumentsLimitReached(): void;
  onStudentDataSubmit(params: StudentDataSubmitParams): void;
  onEmailTokenVerificationSuccess(): void;
  onEmailTokenVerificationSkip(): void;
}

type RouteParams = { verificationId?: string };

const StudentVerificationContext = createContext<ContetextValue>({} as ContetextValue);

interface Props extends PropsWithChildren {
  processType?: ProcessType;
}

export const StudentVerificationProvider: React.FC<Props> = ({
  children,
  processType = 'new-subscription',
}) => {
  const isSignedIn = useIsSignedIn();
  const { planCode } = useUserInfo();
  const routeParams = (useRoute().params || {}) as RouteParams;
  const { data: verificationDetails, isFetching: isVerificationDetailsFetching } =
    useStudentVerificationDetails(routeParams.verificationId);
  const { data: currentUserVerificationDetails, isFetching: isCurrentUserVerificationDetailsFetching } =
    useCurrentUserStudentVerification();

  const [state, dispatch] = useStudentVerificationReducer(getInitialRole(planCode));

  const getMixpanelProperties = (
    data?: StudentVerificationParams | null,
    role?: StudentRole | undefined,
  ): MixpanelStudentVerificationParams | undefined => {
    const studentRole = role !== undefined ? role : isStudentRole(state.role) ? role : null;
    const studentData =
      data !== undefined ? data : studentRole ? state.formData[studentRole].studentData : null;
    if (studentData && studentRole) {
      return {
        'Birth Date': studentData.birthDate,
        'Email Address': studentData.email,
        'Student Email Address': studentData.studentEmail,
        'First Name': studentData.firstName,
        'Last Name': studentData.lastName,
        University: studentData.organizationName,
        'Net New Subscriber': !isSignedIn,
        Country: studentData.country,
        Role: studentRole === Role.PHARMACY_STUDENT ? 'Pharmacy Student' : 'Veterinary Student',
      };
    }
  };

  useEffect(() => {
    if (currentUserVerificationDetails) {
      dispatch({
        type: 'set-verification-details-for-both-roles',
        payload: currentUserVerificationDetails,
      });
    }
  }, [dispatch, currentUserVerificationDetails]);

  useEffect(() => {
    const verificationId = routeParams.verificationId;
    if (verificationDetails && verificationId) {
      dispatch({
        type: 'set-verification-details',
        payload: {
          verificationDetails,
          verificationId,
        },
      });
      const { status, ...studentData } = verificationDetails;
      const role = getBasePlanSetup(studentData.planCode).role as StudentRole;

      MixpanelService.track(MixpanelEvent.StudentEmailNotificationRedirect, {
        ...getMixpanelProperties(studentData, role)!,
        'Verification Status': getMixpanelStudentVerificationStatus(status),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationDetails, dispatch]);

  const onDocumentsUpload = () => {
    dispatch({ type: 'doc-upload' });
    MixpanelService.track(MixpanelEvent.StudentDocumentUpload, getMixpanelProperties());
  };

  const onDocumentsUploadFailure = () => {
    dispatch({ type: 'doc-upload-failure' });
    MixpanelService.track(MixpanelEvent.StudentDocumentsVerificationFailure, getMixpanelProperties());
  };

  const onDocumentsUploadSuccess = () => {
    dispatch({ type: 'doc-upload-success' });
    MixpanelService.track(MixpanelEvent.StudentDocumentsVerificationSuccess, getMixpanelProperties());
  };

  const onDocumentsLimitReached = () => {
    dispatch({ type: 'doc-upload-limit-reached' });
    MixpanelService.track(MixpanelEvent.StudentDocumentsUploadLimitReached, getMixpanelProperties());
  };

  const onStudentDataSubmit = (params: StudentDataSubmitParams) => {
    dispatch({ type: 'student-data-submit', payload: params });
    const { status, data } = params;

    if (status === 'success') {
      MixpanelService.track(MixpanelEvent.StudentDataFormSuccess, getMixpanelProperties(data)!);
    } else {
      MixpanelService.track(MixpanelEvent.StudentDataFormFailure, {
        ...getMixpanelProperties(data)!,
        'Next Student Verification Step':
          status === 'docUpload' ? 'Documents Upload' : status === 'sso' ? 'SSO' : undefined,
      });
    }
  };

  const onSSOProcessSuccess = () => {
    dispatch({ type: 'sso-login-success' });
    MixpanelService.track(MixpanelEvent.StudentSSOProcessSuccess, getMixpanelProperties());
  };

  const onSSOExternalLoginSitePress = () => {
    dispatch({ type: 'sso-login-started' });
    MixpanelService.track(MixpanelEvent.StudentSSOProcessStarted, getMixpanelProperties());
  };

  const onSSOProcessSkip = () => {
    dispatch({ type: 'sso-login-skip' });
    MixpanelService.track(MixpanelEvent.StudentSSOProcessSkipped, getMixpanelProperties());
  };

  const onEmailTokenVerificationSuccess = () => {
    dispatch({ type: 'email-token-verification-success' });
    MixpanelService.track(MixpanelEvent.StudentEmailLoopProcessSuccess, getMixpanelProperties());
  };

  const onEmailTokenVerificationSkip = () => {
    dispatch({ type: 'email-token-verification-skip' });
    MixpanelService.track(MixpanelEvent.StudentEmailLoopProcessSkipped, getMixpanelProperties());
  };

  const setRole = useCallback(
    (role: Role) => {
      dispatch({ type: 'set-role', payload: role });
    },
    [dispatch],
  );

  const formData = isStudentRole(state.role) ? state.formData[state.role] : null;
  const studentPlan = isStudentRole(state.role) ? getRecurlyStudentPlan(state.role) : null;

  return (
    <StudentVerificationContext.Provider
      value={{
        currentStep: formData?.currentStep,
        isStudentVerified: formData?.currentStep === 'verified',
        ssoLoginUrl: formData?.ssoLoginUrl,
        isUserRedirectedFromEmailNotification: !!routeParams.verificationId,
        isFetching: isVerificationDetailsFetching || isCurrentUserVerificationDetailsFetching,
        verificationId: formData?.verificationId,
        plan: studentPlan,
        studentData: formData?.studentData,
        onDocumentsLimitReached,
        onDocumentsUpload,
        onDocumentsUploadFailure,
        onDocumentsUploadSuccess,
        onSSOProcessSuccess,
        onSSOExternalLoginSitePress,
        onSSOProcessSkip,
        onStudentDataSubmit,
        onEmailTokenVerificationSuccess,
        onEmailTokenVerificationSkip,
        setRole,
        processType,
      }}
    >
      {children}
    </StudentVerificationContext.Provider>
  );
};

export const useStudentVerificationContext = () => useContext(StudentVerificationContext);

const getInitialRole = (planCode: RecurlyPlan | null): StudentRole => {
  if (planCode) {
    const { role } = getBasePlanSetup(planCode);
    if (isStudentRole(role)) {
      return role;
    }
  }
  return Role.VETERINARY_STUDENT;
};
