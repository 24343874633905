import React from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { ErrorBannerTemplate } from './ErrorBannerTemplate';
import { StyledText } from '../../components/StyledText';

interface Props {}

export const Error404Banner: React.FC<Props> = () => {
  return (
    <ErrorBannerTemplate
      header={i18n.t('errors:header404')}
      description={
        <Trans
          i18nKey="errors:description404"
          components={{
            p: <StyledText />,
            homeLink: <Link to={{ route: Route.Home }} wrapper="text" style={styles.link} />,
          }}
        />
      }
      icon="error-404"
      iconWidth={92}
      iconHeight={82}
      testID="404-error-screen"
    />
  );
};

const styles = StyleSheet.create({
  link: {
    color: palette.blue,
    textDecorationLine: 'underline',
    textDecorationColor: palette.blue,
    ...typography.body2Bold,
  },
});
