import React from 'react';

import { palette } from 'src/styles';

import { BaseButton, BaseButtonProps } from './BaseButton';

const colors = {
  default: { primary: palette.blue, content: palette.white },
  active: { primary: palette.navy, content: palette.white },
  disabled: { primary: palette.grey2, content: palette.white },
};
export const PrimaryButton: React.FC<BaseButtonProps> = (props) => (
  <BaseButton
    {...props}
    hoverStyle={{
      backgroundColor: colors.active.primary,
    }}
    colors={colors}
  />
);
