import * as R from 'ramda';

import { i18n } from 'src/locale';

import { Route } from '../routes';
import { LinkedRoute, LinkingConfig } from '../types';

export const orderedWebRoutes: LinkedRoute[] = [
  Route.Home,
  Route.SignIn,
  Route.Auth,
  Route.DIC,
  Route.Algorithms,
  Route.AlgorithmItem,
  Route.DxTxList,
  Route.DxTxItem,
  Route.DDxList,
  Route.DDxItem,
  Route.DrugHandoutsList,
  Route.ClinicalHandoutsList,
  Route.DrugHandoutItem,
  Route.SelectedHandouts,
  Route.ShareHandout,
  Route.ClinicalHandoutItem,
  Route.SignUp,
  Route.SubscriptionInvitation,
  Route.ResetPassword,
  Route.PasswordRecovery,
  Route.SubscriptionDetails,
  Route.AccountInformation,
  Route.AdminDashboard,
  Route.FindDrugByName,
  Route.DrugsList,
  Route.DrugItem,
  Route.AppendixList,
  Route.AppendixItem,
  Route.EmailChangeLandingScreen,
  Route.GroupInvitation,
  Route.SearchWeb,
  Route.GroupConversion,
  Route.PromotionLanding,
  Route.Notes,
  Route.StudentReverification,
];

export const linkingConfig: LinkingConfig = {
  [Route.AccountInformation]: {
    path: '/account-information',
    options: {
      title: i18n.t('screens:accountInformation'),
    },
  },
  [Route.SignIn]: {
    path: '/login',
    options: {
      title: i18n.t('screens:signIn'),
    },
  },
  [Route.Auth]: {
    path: '/auth',
  },
  [Route.Home]: {
    path: '/',
    exact: true,
    options: {
      title: '',
    },
  },
  [Route.DIC]: {
    path: '/interaction-checker',
    alternativePath: '/drug-interaction-checker',
    exact: true,
    options: {
      title: i18n.t('screens:interactionChecker'),
    },
    parse: {
      classifications: R.split(','),
      drugs: R.split(','),
      all: Boolean,
    },
  },
  [Route.Algorithms]: {
    path: '/algorithms/:letter?',
    exact: true,
    options: {
      title: i18n.t('screens:algorithms'),
    },
  },
  [Route.AlgorithmItem]: {
    path: '/algorithm/:id',
    options: {
      title: i18n.t('screens:algorithm'),
    },
  },
  [Route.DDxList]: {
    path: '/ddx-main/:letter?',
    exact: true,
    options: {
      title: i18n.t('screens:ddx'),
    },
  },
  [Route.DDxItem]: {
    path: '/ddx/:id',
    options: {
      title: i18n.t('screens:ddx'),
    },
  },
  [Route.DxTxList]: {
    path: '/dx-tx-main/:letter?',
    alternativePath: '/clinical-briefs/:letter?',
    exact: true,
    options: {
      title: i18n.t('screens:dxTx'),
    },
  },
  [Route.DxTxItem]: {
    path: '/dx-tx/:id',
    alternativePath: '/clinical-brief/:id',
    options: {
      title: i18n.t('screens:dxTx'),
    },
  },
  [Route.DrugHandoutsList]: {
    path: '/drug-handouts/:letter?',
    exact: true,
    options: {
      title: i18n.t('screens:drugHandouts'),
    },
  },
  [Route.ClinicalHandoutsList]: {
    path: '/clinical-handouts/:letter?',
    exact: true,
    options: {
      title: i18n.t('screens:clinicalHandouts'),
    },
  },
  [Route.DrugHandoutItem]: {
    path: '/drug-handout/:id',
    options: {
      title: i18n.t('screens:handout'),
    },
  },
  [Route.ClinicalHandoutItem]: {
    path: '/clinical-handout/:id',
    options: {
      title: i18n.t('screens:handout'),
    },
  },
  [Route.SignUp]: {
    path: '/subscribe',
    options: {
      title: i18n.t('screens:subscription'),
    },
    parse: {
      quantity: Number,
      trial: Boolean,
    },
  },
  [Route.SubscriptionInvitation]: {
    path: '/subscribe-invitation/:invitationId',
    options: {
      title: i18n.t('screens:subscription'),
    },
  },
  [Route.ResetPassword]: {
    path: '/reset-password',
    exact: true,
    options: {
      title: i18n.t('screens:passwordRecovery'),
    },
  },
  [Route.PasswordRecovery]: {
    path: '/reset-password/verify/:token/:userId',
    options: {
      title: i18n.t('screens:passwordRecovery'),
    },
  },
  [Route.SubscriptionDetails]: {
    path: '/subscription-details',
    options: {
      title: i18n.t('screens:subscriptionDetails'),
    },
  },
  [Route.AdminDashboard]: {
    path: '/admin-dashboard',
    options: {
      title: i18n.t('screens:adminDashboard'),
    },
  },
  [Route.FindDrugByName]: {
    path: '/find-drug-by-name',
  },
  [Route.DrugsList]: {
    alternativePath: '/drug-monographs/:letter?',
    path: '/drugs/:letter?',
    exact: true,
    options: {
      title: i18n.t('screens:drugs'),
    },
  },
  [Route.DrugItem]: {
    path: '/drug/:id',
    alternativePath: '/drug-monograph/:id',
    options: {
      title: i18n.t('screens:drug'),
    },
  },
  [Route.AppendixList]: {
    path: '/appendices/:letter?',
    exact: true,
    options: {
      title: i18n.t('screens:appendix'),
    },
  },
  [Route.AppendixItem]: {
    path: '/appendix/:id',
    options: {
      title: i18n.t('screens:appendix'),
    },
  },
  [Route.EmailChangeLandingScreen]: {
    path: '/email-change/verify/:token/:userId/:newEmail',
    parse: {
      newEmail: decodeURIComponent,
    },
  },
  [Route.GroupInvitation]: {
    path: '/join-group/verify/:memberId/:encryptedEmail',
    options: {
      title: i18n.t('screens:groupInvitation'),
    },
    parse: {
      encryptedEmail: decodeURIComponent,
    },
  },
  [Route.SearchWeb]: {
    path: '/search',
    options: {
      title: i18n.t('screens:search'),
    },
  },
  [Route.GroupConversion]: {
    path: '/convert-group/:id',
    options: {
      title: i18n.t('screens:groupConversion'),
    },
  },
  [Route.PromotionLanding]: {
    path: '/promotion',
    parse: {
      isSubscribed: (value) => value === 'yes',
      firstName: decodeURIComponent,
      lastName: decodeURIComponent,
      email: decodeURIComponent,
      country: decodeURIComponent,
      userID: decodeURIComponent,
      hospital: decodeURIComponent,
    },
  },
  [Route.Features]: {
    path: '/features',
  },
  [Route.Tools]: {
    path: '/tools',
  },
  [Route.Notes]: {
    path: '/notes',
    options: {
      title: i18n.t('screens:notes'),
    },
  },
  [Route.Calculator]: {
    path: '/calculator',
  },
  [Route.SelectedHandouts]: {
    path: '/selected-handouts',
    options: {
      title: i18n.t('screens:selectedHandouts'),
    },
  },
  [Route.ShareHandout]: {
    path: '/share-handout',
    options: {
      title: i18n.t('screens:shareHandout'),
    },
  },
  [Route.StudentReverification]: {
    path: '/student-reverification',
    options: {
      title: i18n.t('screens:studentReverification'),
    },
  },
};
