import { debounce, difference } from 'lodash-es';
import { useLayoutEffect, useMemo, useState } from 'react';

import { Route } from 'src/navigation';
import { useRoute } from 'src/navigation/hooks';
import { RouteProp } from 'src/navigation/types';

/** Updates the list of selected drugs with delay if user removes them one at a time */
export const useInteractionsDrugs = () => {
  const { params = {} } = useRoute<RouteProp<Route.DIC>>();
  const { drugs: drugsFromParams = [] } = params;
  const [drugs, setDrugs] = useState(drugsFromParams);

  const setWithDelay = useMemo(() => debounce((drugs: string[]) => setDrugs(drugs), 600), []);

  useLayoutEffect(() => {
    if (drugsFromParams.length === drugs.length && difference(drugsFromParams, drugs).length === 0) {
      return;
    }

    if (drugsFromParams.length < 2 || drugsFromParams.length > drugs.length) {
      setDrugs(drugsFromParams);
      setWithDelay.cancel();
    } else {
      setWithDelay(drugsFromParams);
    }
  }, [setWithDelay, drugsFromParams, drugs]);

  return drugs;
};
