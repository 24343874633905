import type { UserPermissions } from 'src/features/auth/types';
import { ContentType } from 'src/features/content/constants';
import { i18n } from 'src/locale';

import { SearchContentTypeFilter } from './enums';
import {
  AlgorithmConfig,
  AppendixConfig,
  ClinicalHandoutConfig,
  DDxConfig,
  DrugConfig,
  DrugHandoutConfig,
  DxTxConfig,
} from '../content/config';

export const getContentTypeSearchParam = (mainFilter: SearchContentTypeFilter): readonly ContentType[] => {
  switch (mainFilter) {
    case SearchContentTypeFilter.Algorithm:
      return AlgorithmConfig.contentTypes;
    case SearchContentTypeFilter.Drug:
      return DrugConfig.contentTypes;
    case SearchContentTypeFilter.DxTx:
      return DxTxConfig.contentTypes;
    case SearchContentTypeFilter.Handout:
      return [...DrugHandoutConfig.contentTypes, ...ClinicalHandoutConfig.contentTypes];
    case SearchContentTypeFilter.Appendix:
      return AppendixConfig.contentTypes;
    case SearchContentTypeFilter.DDx:
      return DDxConfig.contentTypes;
    case SearchContentTypeFilter.All:
      return [];
  }
};

export const getPlaceholderByFilter = (
  filter: SearchContentTypeFilter,
  { hasFullAccessToClinicalHandouts }: UserPermissions,
): string => {
  switch (filter) {
    case SearchContentTypeFilter.Algorithm:
      return i18n.t('search:placeholder:algorithms');
    case SearchContentTypeFilter.Handout:
      return i18n.t(`search:placeholder:${hasFullAccessToClinicalHandouts ? 'handouts' : 'basicHandouts'}`);
    case SearchContentTypeFilter.Drug:
      return i18n.t('search:placeholder:drugs');
    case SearchContentTypeFilter.DxTx:
      return i18n.t('search:placeholder:dxtx');
    case SearchContentTypeFilter.Appendix:
      return i18n.t('search:placeholder:appendix');
    case SearchContentTypeFilter.DDx:
      return i18n.t('search:placeholder:ddx');
    case SearchContentTypeFilter.All:
      return i18n.t('search:placeholder:all');
  }
};
