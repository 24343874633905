import React, { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { FadingBox } from 'src/components/FadingBox';
import { MarkdownContent } from 'src/components/MarkdownContent';
import { palette } from 'src/styles';

interface Props {
  description?: string;
}

export const DxTxImageDescription: React.FC<Props> = ({ description }) => {
  const [overflowing, setOverflowing] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const content = (
    <View
      style={[!expanded && styles.descriptionWrapper, !overflowing && styles.shortDescription]}
      onLayout={({ nativeEvent }) => {
        if (!expanded) {
          const div = (nativeEvent as any).target as HTMLElement;
          setOverflowing(div.scrollHeight > div.clientHeight);
        }
      }}
    >
      <MarkdownContent>{description}</MarkdownContent>
    </View>
  );

  return overflowing ? (
    <Pressable onPress={() => setExpanded(!expanded)} style={styles.toggle}>
      {!expanded ? (
        <FadingBox id="figureLegend" height="50%">
          {content}
        </FadingBox>
      ) : (
        content
      )}
    </Pressable>
  ) : (
    content
  );
};

const styles = StyleSheet.create({
  descriptionWrapper: {
    maxHeight: 60,
    overflow: 'hidden',
  },
  shortDescription: {
    marginBottom: 10,
  },
  toggle: {
    borderBottomWidth: 1,
    borderBottomColor: palette.grey2,
    paddingBottom: 20,
    marginBottom: 20,
  },
});
