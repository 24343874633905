import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { Container, LoadingIndicator } from 'src/components';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { useDrugByName } from '../hooks';

interface Props extends ScreenProps<Route.FindDrugByName> {}

export const FindDrugByNameScreen: React.FC<Props> = ({ route, navigation }) => {
  const { drugName } = route.params ?? {};
  const { data, isError, isSuccess } = useDrugByName(drugName ?? '');

  useEffect(() => {
    if (data) {
      navigation.replace(Route.DrugItem, { id: data.monographId });
      return;
    } else if (isError || (!data && isSuccess) || !drugName) {
      navigation.replace(Route.DrugsList);
    }
  }, [navigation, data, isError, isSuccess, drugName]);

  return (
    <Container verticalPadding style={styles.container}>
      <LoadingIndicator />
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
