import { TabsBar } from 'src/components/TabsBar';
import { TabsBarItem } from 'src/constants/types';
import { isWeb } from 'src/helpers';

import { useSearchParams } from '../hooks';
import { SearchFilterItem } from '../types';

interface Props {}

export const SearchFiltersBar: React.FC<Props> = () => {
  const { displayedSearchFilters, setMainFilter } = useSearchParams();

  const handleFilterPress = (item: SearchFilterItem) => {
    setMainFilter(item.id);
    if (isWeb) {
      window.scrollTo({ top: 0 });
    }
  };

  const items: TabsBarItem[] = displayedSearchFilters.map((item) => ({
    key: item.id,
    isActive: item.isActive,
    text: item.text,
    onPress: () => handleFilterPress(item),
  }));

  if (items.length <= 2) {
    /** One of the filters is always 'All'.
     * If there's just one more, there's no point to
     * display them at all as both of them would work in the same way. */
    return null;
  }

  return <TabsBar items={items} testID="search-filters-bar" />;
};
