import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { PrimaryButton, StyledText } from 'src/components';
import { CLINICAL_CHALLENGE_FORM_LINK } from 'src/constants/externalLinks';
import { i18n } from 'src/locale';
import { typography } from 'src/styles';

interface Props {
  hasContent?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const ClinicalChallengesBanner: React.FC<Props> = ({ hasContent, style }) => {
  const handleSubmit = () => {
    window.open(CLINICAL_CHALLENGE_FORM_LINK, '_blank');
  };

  return (
    <View style={[styles.wrapper, style]}>
      <View style={styles.textWrapper}>
        <StyledText style={typography.body2SemiBold}>
          {i18n.t(`dxTx:${hasContent ? 'clinicalChallengesQuestion' : 'noClinicalChallengesQuestion'}`)}
        </StyledText>
        <StyledText style={typography.body2}>
          {i18n.t(`dxTx:${hasContent ? 'clinicalChallengesAction' : 'noClinicalChallengesAction'}`)}
        </StyledText>
      </View>
      <PrimaryButton onPress={handleSubmit} title={i18n.t('submit')} containerStyle={styles.button} />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textWrapper: {
    flexDirection: 'column',
    flexShrink: 1,
    paddingRight: 18,
    marginTop: 18,
  },
  button: {
    marginTop: 20,
  },
});
