import React, { useState, ReactElement, useEffect } from 'react';

import { AppcuesService, MixpanelService } from 'src/features/tracking';
import { persistor } from 'src/state/store';

type Props = {
  children: ReactElement;
};

const waitForPersist = () => {
  return new Promise<void>((resolve) => {
    const checkState = () => {
      const { bootstrapped } = persistor.getState();
      if (bootstrapped) {
        resolve();
        unsubscribe();
      }
    };

    const unsubscribe = persistor.subscribe(checkState);
    checkState();
  });
};

export const ServiceGate: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function initialize() {
      await Promise.all([AppcuesService.initialize(), MixpanelService.initialize(), waitForPersist()]);

      setLoading(false);
    }

    initialize();
  }, []);

  if (loading) {
    return null;
  }

  return children;
};
