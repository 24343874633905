import { Contents } from 'src/features/content/config';
import type { ContentType } from 'src/features/content/constants';
import type { ContentItemBase, ContentItemSection } from 'src/features/content/types';
import {
  MixpanelContentSingleAccordionActionParams,
  MixpanelEvent,
  MixpanelService,
  SentryService,
  SingleContentAllAccordionsParams,
} from 'src/features/tracking';

/** Tracks Mixpanel events related to accordion actions on single content item screen */
export const useContentItemAccordionEvents = (
  contentId: string,
  contentTitle: string,
  contentType: ContentType,
  sections: ContentItemSection[],
) => {
  const getParamsForSingleAccordion = (sectionId: string) => {
    const section = sections.find((item) => item.id === sectionId);
    if (section) {
      return getContentItemSingleAccordionParams(section.title, {
        contentType,
        id: contentId,
        title: contentTitle,
      });
    }
    throw new Error(`Couldn't find a section with ID ${sectionId}`);
  };

  const onSectionOpen = (sectionId: string) => {
    try {
      const params = getParamsForSingleAccordion(sectionId);
      if (params) {
        MixpanelService.track(MixpanelEvent.AccordionOpen, params);
      } else {
        throw Error("Couldn't get event properties");
      }
    } catch (error: any) {
      SentryService.captureException("Couldn't track Open Accordion event", {
        message: error?.message,
        section: sectionId,
        contentId,
        contentTitle,
      });
      console.error(error);
    }
  };

  const onSectionClose = (sectionId: string) => {
    try {
      const params = getParamsForSingleAccordion(sectionId);
      if (params) {
        MixpanelService.track(MixpanelEvent.AccordionClose, params);
      } else {
        throw Error("Couldn't get event properties");
      }
    } catch (error: any) {
      SentryService.captureException("Couldn't track Close Accordion event", {
        message: error?.message,
        section: sectionId,
        contentId,
        contentTitle,
      });
      console.error(error);
    }
  };

  const onOpenAllPress = () => {
    try {
      const params = getContentItemAllAccordionsParams({
        contentType,
        id: contentId,
        title: contentTitle,
      });
      if (params) {
        MixpanelService.track(MixpanelEvent.AccordionOpenAll, params);
      } else {
        throw Error("Couldn't get event properties");
      }
    } catch (error: any) {
      SentryService.captureException("Couldn't track Open All Accordions event", {
        message: error?.message,
        contentId,
        contentTitle,
      });
      console.error(error);
    }
  };

  const onCloseAllPress = () => {
    try {
      const params = getContentItemAllAccordionsParams({
        contentType,
        id: contentId,
        title: contentTitle,
      });
      if (params) {
        MixpanelService.track(MixpanelEvent.AccordionCloseAll, params);
      } else {
        throw Error("Couldn't get event properties");
      }
    } catch (error: any) {
      SentryService.captureException("Couldn't track Close All Accordions event", {
        message: error?.message,
        contentId,
        contentTitle,
      });
      console.error(error);
    }
  };

  return { onSectionClose, onSectionOpen, onCloseAllPress, onOpenAllPress };
};

const getContentItemAllAccordionsParams = (
  itemData: Pick<ContentItemBase, 'contentType' | 'id' | 'title'>,
): SingleContentAllAccordionsParams<any> => {
  switch (Contents.getSimplifiedType(itemData.contentType)) {
    case 'drug': {
      return {
        'Accordion Location': 'Drug Monograph',
        'Drug ID': itemData.id,
        'Drug Name': itemData.title,
      };
    }
    case 'dx-tx': {
      return {
        'Accordion Location': 'Dx&Tx',
        'Dx&Tx ID': itemData.id,
        'Dx&Tx Name': itemData.title,
      };
    }
    case 'clinical-handout': {
      return {
        'Accordion Location': 'Patient Guide',
        'Patient Guide ID': itemData.id,
        'Patient Guide Name': itemData.title,
      };
    }
    case 'drug-handout': {
      return {
        'Accordion Location': 'VMG',
        'VMG ID': itemData.id,
        'VMG Name': itemData.title,
      };
    }
    case 'ddx': {
      return {
        'Accordion Location': 'DDx',
        'DDx ID': itemData.id,
        'DDx Name': itemData.title,
      };
    }
    default: {
      throw new Error("Couldn't provide properties for content accordion Mixpanel event");
    }
  }
};

const getContentItemSingleAccordionParams = (
  sectionName: string,
  itemData: Pick<ContentItemBase, 'contentType' | 'id' | 'title'>,
): MixpanelContentSingleAccordionActionParams | undefined => {
  const params = getContentItemAllAccordionsParams(itemData);
  return {
    'Accordion Name': sectionName,
    ...params,
  };
};
