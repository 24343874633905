import * as R from 'ramda';
import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { BaseButton } from 'src/components';
import { getErrorMessageForApiError, getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { isWeb, showNotification } from 'src/helpers';
import { i18n } from 'src/locale';

import { AccountInformationSection as Section } from './AccountInformationSection';
import { parseOccupationDetailsErrorsFromBackend } from '../helpers';
import { useOccupationDetails, useUpdateOccupationDetails, useOccupationDetailsForm } from '../hooks';
import type { OccupationDetails as OccupationDetailsI, OccupationDetailsErrorResponse } from '../types';

export const OccupationDetails: React.FC = () => {
  const { data, error, isLoading } = useOccupationDetails();
  const { mutate: updateOccupationDetails, isPending: isEditing } = useUpdateOccupationDetails();

  const submit = (values: OccupationDetailsI) => {
    updateOccupationDetails(values, {
      onError: (err: any) => {
        if (err?.response?.status === 400) {
          const data = err.response.data as OccupationDetailsErrorResponse;
          const parsedErrors = parseOccupationDetailsErrorsFromBackend(data);
          setErrors(parsedErrors);
        } else {
          const { detail } = getMessagesFromErrorResponse(err);
          showNotification({ type: 'error', title: detail });
        }
      },
    });
  };

  const { renderField, handleSubmit, setErrors, setValues } = useOccupationDetailsForm(data, submit);

  useEffect(() => {
    if (error && typeof error !== 'boolean') {
      const errorMessages = R.mapObjIndexed(getErrorMessageForApiError, error);
      setErrors(errorMessages);
    }
  }, [error, setErrors]);

  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data, setValues]);

  const renderFormField: typeof renderField = (key) => {
    const field = renderField(key);
    if (field) return <Section.Item>{field}</Section.Item>;
    return null;
  };

  const content = (
    <>
      <Section.Form>
        <Section.Row>
          {renderFormField('activity')}
          {renderFormField('activityOther')}
          {renderFormField('pharmacy')}
          {renderFormField('pharmacyOther')}
          {renderFormField('graduationYear')}
          {renderFormField('role')}
          {renderFormField('roleOther')}
          {renderFormField('schoolGraduated')}
          {renderFormField('schoolGraduatedOther')}
        </Section.Row>
        <Section.Row>
          {renderFormField('schoolAttending')}
          {renderFormField('schoolAttendingOther')}
          {renderFormField('yearsOfPractice')}
          {renderFormField('numberOfVeterinarians')}
          {renderFormField('practiceType')}
          {renderFormField('practiceTypeOther')}
          {renderFormField('practiceOwnership')}
        </Section.Row>
      </Section.Form>
      <Section.ButtonsWrapper>
        <BaseButton
          title={i18n.t('saveChanges')}
          onPress={handleSubmit}
          loading={isEditing}
          testID="submit-btn"
          variant="gradient"
        />
      </Section.ButtonsWrapper>
    </>
  );
  return isWeb ? (
    <Section
      title={i18n.t('accountInformation:occupationDetails')}
      style={styles.section}
      isLoading={isLoading}
      testID="section-occupation-details"
    >
      {content}
    </Section>
  ) : (
    content
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 0,
  },
});
