import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  MixpanelEvent,
  MixpanelSelectHandoutParams,
  MixpanelService,
  MixpanelShareSelectedHandoutsParams,
  SentryService,
} from 'src/features/tracking';
import { isClinicalHandout, isDrugHandout } from 'src/helpers';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';

import { handoutsSelector, selectHandout, deselectHandout } from '../state';
import type { SelectedHandout } from '../types';

type SelectedHandoutBase = Omit<SelectedHandout, 'language'>;

export const useSelectedHandout = (
  item: SelectedHandoutBase,
  mixpanelParams: MixpanelSelectHandoutParams | (() => MixpanelSelectHandoutParams),
) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { selectedHandouts } = useSelector(handoutsSelector);
  const { t } = useTranslation('handouts');

  const isSelected = !!selectedHandouts.find((el) => el.id === item.id);

  const trackSelectEvent = () => {
    try {
      const params = typeof mixpanelParams === 'function' ? mixpanelParams() : mixpanelParams;
      MixpanelService.track(MixpanelEvent.SelectHantoutToShare, {
        Location: params.location,
        'Sub Location': params.subLocation,
      });
    } catch (error: any) {
      SentryService.captureException(`Couldn't track ${MixpanelEvent.SelectHantoutToShare} event`, {
        message: error?.message,
        item: item,
      });
    }
  };

  const toggleSelected = () => {
    if (!isSelected) {
      trackSelectEvent();
      dispatch(selectHandout(item));
    } else {
      dispatch(deselectHandout(item.id));
    }
  };

  const trackShareEvent = () => {
    const mixpanelParams = getShareSelectedHandoutsParams(isSelected ? selectedHandouts : [item]);
    MixpanelService.track(MixpanelEvent.ShareHandouts, mixpanelParams);
  };

  const handleSharePress = () => {
    trackShareEvent();
    if (!isSelected) {
      navigation.navigate(Route.ShareHandout, { handout: item.id });
    } else {
      navigation.navigate(Route.SelectedHandouts);
    }
  };

  const shareButtonTitle = isSelected ? t('shareSelectedHandouts') : t('common:share');

  return { isSelected, toggleSelected, handleSharePress, shareButtonTitle };
};

const getShareSelectedHandoutsParams = (
  selectedHandouts: SelectedHandoutBase[],
): MixpanelShareSelectedHandoutsParams => {
  interface HandoutsGroup {
    id: string[];
    title: string[];
  }

  const handoutsReduce = (result: HandoutsGroup, handout: SelectedHandoutBase) => {
    (result.id = result.id || []).push(handout.id);
    (result.title = result.title || []).push(handout.title);
    return result;
  };

  const { id: drugIds, title: drugTitles } = selectedHandouts
    .filter(isDrugHandout)
    .reduce(handoutsReduce, {} as HandoutsGroup);
  const { id: clinicalIds, title: clinicalTitles } = selectedHandouts
    .filter(isClinicalHandout)
    .reduce(handoutsReduce, {} as HandoutsGroup);

  return {
    'Clinical Handout IDs': clinicalIds,
    'Clinical Handout Names': clinicalTitles,
    'Drug Handout IDs': drugIds,
    'Drug Handout Names': drugTitles,
  };
};
