import React from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { ErrorBannerTemplate } from './ErrorBannerTemplate';

interface Props {}

export const ServerIssueBanner: React.FC<Props> = () => {
  return (
    <ErrorBannerTemplate
      header={i18n.t('errors:header500')}
      description={
        <Trans
          i18nKey="errors:serverIssueDescription"
          components={{
            p: <StyledText />,
            contact: <Link to={SUPPORT_LINK} external wrapper="text" style={styles.link} />,
          }}
        />
      }
      descriptionStyle={styles.description}
      icon="oops"
      iconWidth={157}
      iconHeight={82}
      testID="server-issue-banner"
    />
  );
};

const styles = StyleSheet.create({
  link: {
    color: palette.blue,
    textDecorationLine: 'underline',
    textDecorationColor: palette.blue,
    ...typography.body2Bold,
  },
  description: {
    maxWidth: 320,
  },
});
