export const palette = {
  underlay: 'rgba(0, 0, 0, 0.3)',
  shadow: '#17285e',
  opaqueBlack: 'rgba(0, 0, 0, 0.8)',
  drawerOverlay: 'rgba(255, 255, 255, 0.4)',
  semiTransparent: 'rgba(0, 0, 0, 0.5)',
  blueScrim: 'rgba(23, 40, 94, 0.8)',
  whiteTransparent: 'rgba(255, 255, 255, 0.5)',
  transparent: 'transparent',
  primaryDisabled: '#DEDEDE',
  white: '#FFFFFF',
  grey0: '#F6F8FA',
  grey1: '#F3F4F7',
  grey2: '#DCDFE7',
  grey3: '#C9CEDA',
  grey4: '#B2B8CA',
  grey5: '#8B93AE',
  grey6: '#69758C',
  grey7: '#4A5568',
  grey8: '#232C38',
  black: '#000000',
  lightBlueGrey: '#EBF0F3',
  brightBlue: '#00CAE0',
  brightBlue2: '#F5FBFF',
  brightBlue3: '#DEEBF4',
  blue: '#0590D5',
  blue2: '#0070A8',
  blue3: '#04A5D5',
  blue4: '#0E5996',
  blue5: '#4150BF',
  blue6: '#00A2E2',
  darkBlue: '#1851A8',
  darkBlue2: '#133C6D',
  navy: '#17285E',
  navy2: '#070C19',
  red: '#FF084B',
  red2: '#F20045',
  lightRed: '#FFE4EC',
  darkRed: '#CC0946',
  green: '#55983A',
  darkGreen: '#447C27',
  orange: '#FF7A3F',
  darkOrange: '#FF551A',
  yellow: '#FFB140',
  yellow2: '#FFF3E3',
  yellow3: '#FFD399',
  yellowHighlight: '#FFE852',
  selectedHighlight: '#FFB140',
  darkYellow: '#F7941E',
  violet: '#4b10ba',
  pacificBlue: '#00A0C2',
  purple: '#9141bf',
  easternBlue: '#007893',
  turquoise: '#00A4C4',
} as const;

//When changing main colors keep in mind to change also in recurly.css for consistency
